<template>
	<div class="payment-info-card">
		<swiper ref="swiper" class="payment-info-card-swiper" :options="swiperOptions" @init="changeSlide" @slideChange="changeSlide">
			<swiper-slide class="payment-info-card-swiper-slide" v-for="(card, index) in cards" :key="index">
				<div class="image-text-wrapper" @click="onClickCard(index)">
					<img :src="card.information.frontImageUrl" />
					<div class="text-wrapper">
						<div>{{ card.name }}</div>
						<div>{{ displayCardNumber(card) }}</div>
					</div>
				</div>
			</swiper-slide>
			<swiper-slide class="payment-info-card-swiper-slide">
				<div class="empty-wrapper" @click="onClickAdd">
					<div>
						<img src="@/assets/svg/payment/card-plus.svg" alt="" />
					</div>
					<div class="text">카드 등록</div>
				</div>
			</swiper-slide>
			<div class="swiper-pagination payment-info-card-swiper-pagination" slot="pagination" />
			<div class="swiper-button-prev payment-info-card-swiper-button-prev" slot="button-prev"></div>
			<div class="swiper-button-next payment-info-card-swiper-button-next" slot="button-next"></div>
		</swiper>
		<div class="swiper-ebill-container" v-if="isShowBusinessRegistration">
			<drop-down
				class="drop-down"
				:value="businessRegistration"
				:list="filterBusinessRegistrations"
				@select="selectBusinessRegistration"
				:placeholder="`결제처를 선택해주세요`"
			/>
		</div>
		<modal-easy-payment title="카드정보" @success="fetchCards" />
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ModalEasyPayment from '@/views/my-page/my-info/easy-payment/modal/ModalEasyPayment.vue'
import DropDown from '@/components/DropDown.vue'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'PaymentInfoCard',
	components: {
		ModalEasyPayment,
		DropDown,
	},
	props: {
		isShowBusinessRegistration: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			swiperOptions: {
				slidesPerView: 'auto',
				spaceBetween: 15,
				centeredSlides: true,
				navigation: {
					nextEl: '.payment-info-card-swiper-button-next',
					prevEl: '.payment-info-card-swiper-button-prev',
				},
				pagination: {
					el: '.payment-info-card-swiper-pagination',
					clickable: true,
				},
			},
			card: {},
			modalTitle: '',
			businessRegistration: {},
		}
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				this.fetchCards()
				if (this.isShowBusinessRegistration) this.fetchBusinessRegistration()
			},
			immediate: true,
		},
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('businessRegistration', ['businessRegistrations']),
		...mapState('card', ['cards']),
		swiper() {
			return this.$refs['swiper'].swiper
		},
		isEmpty() {
			return this.$_.isEmpty(this.cards)
		},
		filterBusinessRegistrations() {
			return this.businessRegistrations.filter(businessRegistration => ['APPROVE'].includes(businessRegistration.status))
		},
	},
	methods: {
		...mapActions('businessRegistration', ['lookupRegistration']),
		...mapActions('card', ['lookupCards']),
		async fetchBusinessRegistration() {
			try {
				await this.lookupRegistration(this.instituteId)
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async fetchCards() {
			try {
				await this.lookupCards(this.instituteId)
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}

			this.changeSlide()
		},
		onClickAdd() {
			this.$bvModal.show('ModalEasyPayment')
		},
		onClickCard(index) {
			this.swiper.slideTo(index)
			this.setCard(index)
		},
		changeSlide() {
			const index = this.swiper.activeIndex
			this.setCard(index)
			this.$emit('changeCard', this.card)
		},
		setCard(index) {
			this.card = this.cards[index]
		},
		getCard() {
			return this.card
		},
		getBusinessRegistration() {
			return this.businessRegistration
		},
		displayCardNumber(card) {
			const cardNumber1 = card.cardNumber?.slice(0, 4) ?? '0000'
			const cardNumber4 = card.cardNumber?.slice(12, 16) ?? '0000'
			return `${cardNumber1} - **** - **** - ${cardNumber4}`
		},
		selectBusinessRegistration(businessRegistration) {
			this.businessRegistration = businessRegistration
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-info-card {
	position: relative;
	.payment-info-card-swiper {
		width: 100%;
		height: 300px;
		background-color: $LIST_BACKGROUND;
		.payment-info-card-swiper-slide {
			width: 250px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: 30px;
			.image-text-wrapper {
				position: relative;
				cursor: default;
				img {
					width: 250px;
					height: 160px;
					border-radius: 6px;
				}
				.text-wrapper {
					position: absolute;
					bottom: 15px;
					left: 15px;
					font-size: 0.875rem;
					color: $PRIMARY_WHITE;
					font-weight: 500;
					text-align: left;
				}
			}
			.empty-wrapper {
				width: 250px;
				height: 160px;
				background-color: $PRIMARY_WHITE;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				img {
					width: 48px;
					height: 48px;
				}
				.text {
					margin-top: 15px;
					font-size: 0.875rem;
					font-weight: 700;
				}
			}
		}
		.payment-info-card-swiper-pagination {
			.swiper-pagination-bullet-active {
				background-color: #000 !important;
			}
		}

		.payment-info-card-swiper-button-prev {
			background-image: url(~@/assets/svg/payment/swiper-button-prev.svg);
		}
		.payment-info-card-swiper-button-next {
			background-image: url(~@/assets/svg/payment/swiper-button-next.svg);
		}
	}
	.swiper-ebill-container {
		position: absolute;
		bottom: 35px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		.drop-down {
			width: 480px;
			height: 40px;
		}
	}
}
</style>

<style lang="scss">
.payment-info-card {
	.payment-info-card-swiper {
		.payment-info-card-swiper-pagination {
			.swiper-pagination-bullet {
				background-color: $FONT_DISABLED;
				opacity: 0.25;
			}
			.swiper-pagination-bullet-active {
				opacity: 1;
			}
		}
	}
}
</style>
