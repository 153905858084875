<template>
	<section id="header">
		<div class="jw-header">
			<div class="wrapper" :class="{ expand: !institute?.id }">
				<div class="logo" @click="goMain">
					<!-- <img src="@/assets/images/logo.png" /> -->
					<img src="@/assets/svg/logo.svg" />
				</div>
				<header-search-box ref="header-search-box" />
				<div class="right-btn-group">
					<!-- <div class="badge-wrapper" v-if="isLoginUser">
						<div class="badge npay" @click="$refs['ModalNpay'].show()">{{ point.toLocaleString() }}원 ▸</div>
						<div class="title">출금 가능한 <img src="@/assets/svg/icon-npay-s.svg" /></div>
					</div> -->
					<!-- <div class="badge-wrapper" v-if="institute?.id">
						<div class="badge" @click="goPay" :class="{ warning: overDueNoPaidPriceWithVat > 0 }" v-b-tooltip.hover.html="tootlipText">
							<img v-if="overDueNoPaidPriceWithVat > 0" src="@/assets/svg/icon-round-warn.svg" />
							{{ limitCredit.toLocaleString() }}원 ▸
						</div>
						<div class="title">후불결제 잔여 한도</div>
					</div> -->
					<button class="user-info-btn" @mouseover="toggleUserInfo(true)" @mouseleave="toggleUserInfo(false)" @click="onClickUserInfo">
						<img src="@/assets/images/app-header-mypage.svg" />
						<p>마이페이지</p>
						<floating-menu v-if="isLoginUser" :list="userMenu" />
					</button>
					<div v-if="isLoginUser" class="notification-wrap" v-click-outside="hideNotification">
						<button @click="setIsNotification(!isNotification)">
							<img v-if="userInfo.unreadNotificationCount" src="@/assets/images/app-header-alarm-on.svg" />
							<img v-else src="@/assets/images/app-header-alarm.svg" />
							<p>알림</p>
						</button>
						<notification-list v-if="isNotification" @hide="hideNotification"></notification-list>
					</div>
					<button @click="goCart">
						<p class="cart">{{ cartCount }}</p>
						<p>장바구니</p>
					</button>
				</div>
			</div>
		</div>
		<ModalNpay ref="ModalNpay" @requestChange="$refs['ModalExchangeNpay'].show()" />

		<ModalExchangeNpay ref="ModalExchangeNpay" />
		<app-menu @toggleAllCategory="toggleAllCategory" :isShowAllCategoryList="isShowAllCategoryList" />
		<all-category-box v-show="isShowAllCategoryList" @toggleMouseOnCategory="toggleMouseOnCategory" />
	</section>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import PageName from '@/utils/define/PageName'
import AppMenu from '@/components/menu/AppMenu.vue'
import FloatingMenu from '@/components/menu/FloatingMenu.vue'
import HeaderSearchBox from '@/components/HeaderSearchBox'
import AllCategoryBox from '@/components/menu/AllCategoryBox.vue'
import { goToSSOMain, isLogin } from '@/services/api/common'
import RoutePath from '@/utils/define/RoutePath'
import ModalNpay from '@/views/main/modal/ModalNpay.vue'
import ModalExchangeNpay from '@/views/main/modal/ModalExchangeNpay.vue'
import NotificationList from '@/components/list/NotificationList.vue'
export default {
	name: 'AppHeader',
	components: {
		AppMenu,
		FloatingMenu,
		HeaderSearchBox,
		AllCategoryBox,
		ModalNpay,
		ModalExchangeNpay,
		NotificationList,
	},
	data() {
		return {
			isLoginUser: false,
			isMouseOnBtn: false,
			isMouseOnCategory: false,
			userMenu: [
				{
					name: '주문배송 내역',
					clickEv: () => {
						this.$router
							.push({
								name: PageName.MyPage.Order,
							})
							.catch(() => {})
					},
				},
				{
					name: '결제 관리',
					clickEv: () => {
						this.$router
							.push({
								name: PageName.MyPage.EasyPaymentManagement,
							})
							.catch(() => {})
					},
				},
				{
					name: '연구실 정보',
					clickEv: () => {
						this.$router
							.push({
								name: PageName.MyPage.LabInfoManagement,
							})
							.catch(() => {})
					},
				},
				{
					name: '나의 정보',
					clickEv: () => {
						this.$router
							.push({
								name: PageName.MyPage.UserInfo,
							})
							.catch(() => {})
					},
				},
				{
					name: '로그아웃',
					clickEv: async () => {
						await this.logout()
						this.$router.go()
					},
				},
			],
			showUserInfo: false,
		}
	},
	computed: {
		...mapState('institute', ['institute']),
		...mapState('user', ['myPageInfo', 'point']),
		...mapState('notification', ['isNotification']),
		...mapGetters('user', ['userInfo']),
		isShowAllCategoryList() {
			return this.isMouseOnBtn || this.isMouseOnCategory
		},
		cartCount() {
			if (this.myPageInfo.cartCount == null) return 0
			return this.myPageInfo.cartCount < 99 ? this.myPageInfo.cartCount : '99+'
		},
		// notificationCount() {
		// 	if (this.myPageInfo.notificationCount == null) return 0
		// 	return this.myPageInfo.notificationCount < 99 ? this.myPageInfo.notificationCount : '99+'
		// },
		overDueNoPaidPriceWithVat() {
			return this.institute?.overDueNoPaidPriceWithVat ?? 0
		},
		limitCredit() {
			return (this.institute?.limitCredit ?? 0) - (this.institute?.noPaidPriceWithVat ?? 0)
		},
		tootlipText() {
			if (this.overDueNoPaidPriceWithVat > 0)
				return '결제 기간이 초과된 주문이 있습니다. <br />해당 주문이 결제가 완료되어야 추가 주문이 가능합니다.'
			else return null
		},
	},
	watch: {
		showUserInfo() {
			let selector = document.querySelector('.floating-menu')
			if ([...selector.classList].includes('active')) {
				selector.classList.remove('active')
			} else {
				selector.classList.add('active')
			}
		},
		userInfo: {
			handler(newVal) {
				if (newVal.id) this.isLoginUser = true
			},
			immediate: true,
		},
	},
	methods: {
		...mapMutations('notification', ['setIsNotification']),
		...mapActions('user', ['logout']),
		goMain() {
			this.$router
				.push({
					name: PageName.Main,
				})
				.catch(() => {})
		},
		toggleUserInfo(isShow) {
			if (!this.isLoginUser) return
			this.showUserInfo = isShow
		},
		onClickUserInfo() {
			if (!this.isLoginUser) goToSSOMain()
			else this.goMyPageOrder()
		},
		goMyPageOrder() {
			this.$router
				.push({
					name: PageName.MyPage.Order,
				})
				.catch(() => {})
		},
		goCart() {
			this.$router
				.push({
					name: PageName.Cart,
				})
				.catch(() => {})
		},
		goPay() {
			this.$router.push({
				name: PageName.MyPage.UnPaymentManagement,
				params: {
					step: 1,
				},
			})
		},
		hideNotification() {
			this.setIsNotification(false)
		},
		hideSearchRecent() {
			if (this.$refs['header-search-box']) {
				this.$refs['header-search-box'].keywordFocus = false
			}
		},
		toggleAllCategory(flag) {
			this.isMouseOnBtn = flag
		},
		toggleMouseOnCategory(flag) {
			this.isMouseOnCategory = flag
		},
	},
}
</script>

<style lang="scss" scoped>
#header {
	width: 100%;
	.jw-header {
		display: flex;
		height: 80px;
		align-items: center;
		.wrapper {
			display: inline-flex;
			align-items: center;
		}
		.logo {
			width: 179px;
			height: 24px;
			cursor: pointer;
			img {
				width: inherit;
				height: inherit;
			}
			margin-right: 20px;
		}
		.right-btn-group {
			display: inline-flex;
			align-items: center;
			margin-left: auto;
			gap: 20px;
			.notification-wrap {
				position: relative;
				button:hover {
					opacity: 0.7;
				}
			}
			button {
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;
				&.login-btn {
					padding: 5px 10px;
					background-color: $PRIMARY_YELLOW;
					border-radius: 4px;
					font-size: 0.875rem;
					font-weight: 700;
					color: white;
				}
				img {
					width: 32px;
					height: 32px;
				}
				p {
					font-size: 0.75rem;
					font-weight: 400;
					color: #666;
				}
				.cart {
					padding-top: 2px;
					font-size: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-image: url(~@/assets/svg/icon-cart.svg);
					width: 32px;
					height: 32px;
					color: #ff8a00;
					font-weight: 700;
				}
			}
			.badge-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 3px;
				.badge {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 30px;
					padding: 2px 10px;
					background-color: #fff3d6;
					border-radius: 10px;
					font-size: 12px;
					gap: 5px;
					cursor: pointer;
					font-weight: 700;
					color: #ffb000;
					&.warning {
						background-color: #ffeeed;
						color: #ff3b31;
					}
					&.npay {
						background-color: #00de5a;
						color: black;
					}
				}
				.title {
					font-size: 10px;
					font-weight: 500;
					color: #666;
				}
			}
		}
		.expand {
			.search-box-wrapper {
				width: 560px;
				height: 40px;
			}
			.right-btn-group {
				gap: 30px;
			}
		}
	}
	.floating-menu {
		width: 200px;
		top: 55px;
		left: -65px;
		visibility: hidden;
		opacity: 0;
		&.active {
			visibility: visible;
			opacity: 1;
		}
	}
}
</style>
