import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'
import main from '@/store/modules/main'
import search from '@/store/modules/search'
import toast from '@/store/modules/toast'
import user from '@/store/modules/user'
import brand from '@/store/modules/brand'
import seller from '@/store/modules/seller'
import cart from '@/store/modules/cart'
import wish from '@/store/modules/wish'
import catalog from '@/store/modules/catalog'
import productInfo from '@/store/modules/productinfo'
import category from '@/store/modules/category'
import order from '@/store/modules/order'
import cs from '@/store/modules/cs'
import card from '@/store/modules/card'
import delivery from '@/store/modules/delivery'
import member from '@/store/modules/member'
import institute from '@/store/modules/institute'
import businessRegistration from '@/store/modules/business-registration'
import notice from '@/store/modules/notice'
import event from '@/store/modules/event'
import question from '@/store/modules/question'
import inquiry from '@/store/modules/inquiry'
import requestProduct from '@/store/modules/request-product'
import estimate from '@/store/modules/estimate'
import nopaid from '@/store/modules/nopaid'
import payment from '@/store/modules/payment'
import faq from '@/store/modules/faq'
import popup from '@/store/modules/popup'
import notification from '@/store/modules/notification'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'
const store = new Vuex.Store({
	namespaces: true,
	modules: {
		main,
		search,
		toast,
		user,
		brand,
		seller,
		cart,
		wish,
		catalog,
		productInfo,
		category,
		order,
		cs,
		card,
		delivery,
		member,
		institute,
		businessRegistration,
		notice,
		event,
		question,
		inquiry,
		requestProduct,
		estimate,
		nopaid,
		payment,
		faq,
		popup,
		notification,
	},
	strict: debug,
	plugins: [
		createPersistedState({
			paths: ['order.selectedProductList', 'order.estimateId'],
		}),
	],
})

export default store
