<template>
	<div class="estimate-product-info-table-wrapper">
		<div class="body">
			<table>
				<colgroup>
					<col width="auto" />
					<col width="80" />
					<col width="130" />
					<col width="130" />
				</colgroup>
				<thead>
					<th>상품 정보</th>
					<th>수량</th>
					<th>소비자가</th>
					<th>판매가</th>
				</thead>
				<tbody>
					<tr v-for="product of estimate.products" :key="product.id">
						<td>
							<estimate-product-item :item="product" />
						</td>
						<td class="count">{{ product.quantity }}</td>
						<td class="currency">{{ CommaNum(product.price) }}원</td>
						<td>
							<div class="sale-price">
								<span class="currency">{{ CommaNum(product.sellingPrice) }}원</span>
								<span v-if="product.discountPrice" class="sale">
									(<span>-{{ roundNum(product.discountPercent) }}%</span> / <span>{{ CommaNum(product.discountPrice) }}원</span>)
								</span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="footer">
				<span>주문 금액 합계</span>
				<div>
					<span class="title">판매가 합계 </span>{{ CommaNum(estimate.totalSellingPrice) }}원
					<template v-if="estimate.totalDiscountPrice">
						<span class="title"> <img src="@/assets/svg/cart/minus.svg" />&nbsp;&nbsp;할인 금액 </span>
						<span class="title sale"> {{ CommaNum(estimate.totalDiscountPrice ?? 0) }}원</span>
					</template>
					<span class="title"> <img src="@/assets/svg/cart/plus.svg" />&nbsp;&nbsp;배송비 </span> 0원
					<span class="title"> <img src="@/assets/svg/cart/plus.svg" />&nbsp;&nbsp;VAT(10%) </span>
					{{ CommaNum(estimate.totalVat) }}원
					<img src="@/assets/svg/cart/equal.svg" style="padding: 0 12px" />
					<span class="total">{{ CommaNum(estimate.totalSellingPriceWithVat) }}원</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import EstimateProductItem from '@/views/my-page/estimate/EstimateProductItem'

export default {
	name: 'EstimateProductInfoTable',
	components: {
		EstimateProductItem,
	},
	mixins: [mixin],
	props: {
		estimate: {
			type: Object,
			default: () => ({}),
		},
	},
}
</script>

<style lang="scss" scoped>
.estimate-product-info-table-wrapper {
	display: flex;
	flex-direction: column;
	.body {
		max-height: calc(60px + 757px + 100px);
		overflow-y: auto;
		border: 1px solid $COLOR_EEE;
		border-radius: 5px 5px 0px 0px;

		table {
			width: 100%;
			box-sizing: border-box;
			thead {
				height: 60px;
				background: $LIST_BACKGROUND;
				border-bottom: 1px solid $COLOR_EEE;
				box-sizing: border-box;
				font-weight: 700;
				font-size: 0.875rem;
				position: sticky;
				top: 0;
				z-index: 1;
				th {
					text-align: center;
					&:first-child {
						padding-left: 20px;
						text-align: left;
					}
				}
			}
			tbody {
				width: 100%;
				box-sizing: border-box;
				tr {
					height: 150px;
					box-sizing: border-box;
					&:not(:last-child) {
						border-bottom: 1px solid $COLOR_EEE;
						box-sizing: border-box;
					}
					td:not(:first-child) {
						border-left: 1px solid $COLOR_EEE;
						text-align: center;
					}
					td {
						padding: 0;
						box-sizing: border-box;
					}
					.sale-price {
						display: flex;
						flex-direction: column;
						.sale {
							font-weight: 700;
							font-size: 0.75rem;
							color: $PRIMARY_RED;
						}
					}
					.count {
						font-size: 0.875rem;
					}
					.currency {
						font-weight: 700;
						font-size: 1rem;
					}
				}
			}
		}
	}
	.footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 10px;
		height: 100px;
		background: $LIST_BACKGROUND;
		border: 1px solid $COLOR_EEE;
		border-top: none;
		border-radius: 0px 0px 5px 5px;
		position: sticky;
		bottom: 0;
		> span {
			font-weight: 700;
			font-size: 0.875rem;
		}
		> div {
			display: flex;
			gap: 8px;
			align-items: center;
			font-weight: 400;
			font-size: 1rem;
			.title {
				color: $COLOR_666;
			}
			.total {
				font-weight: 700;
				font-size: 1.25rem;
			}
			img {
				padding-bottom: 3px;
			}
			.sale {
				font-weight: 700;
				color: $PRIMARY_RED;
			}
		}
	}
}
</style>
