import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/modules/ParamMaker'

const endPoint = {
	lookupPaymentList: (instituteId, paramsObject) => `institute/${instituteId}/payment/list?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupPayment: (instituteId, id) => `institute/${instituteId}/payment?id=${id}`,
	requestPayment: instituteId => `institute/${instituteId}/payment`,
	cancelPayment: instituteId => `institute/${instituteId}/payment/cancel`,
	paymentCalculate: instituteId => `institute/${instituteId}/payment/calculate`,
	paymentPrint: instituteId => `institute/${instituteId}/payment/print`,
}

const paymentAPI = {
	lookupPaymentList: (instituteId, paramsObject) => axiosInstance.get(endPoint.lookupPaymentList(instituteId, paramsObject)),
	lookupPayment: (instituteId, id) => axiosInstance.get(endPoint.lookupPayment(instituteId, id)),
	requestPayment: (instituteId, body) => axiosInstance.post(endPoint.requestPayment(instituteId), body),
	cancelPayment: (instituteId, body) => axiosInstance.post(endPoint.cancelPayment(instituteId), body),
	paymentCalculate: (instituteId, body) => axiosInstance.post(endPoint.paymentCalculate(instituteId), body),
	paymentPrint: (instituteId, body) => axiosInstance.post(endPoint.paymentPrint(instituteId), body),
}

export default paymentAPI
