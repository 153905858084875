<template>
	<div class="payment-complete wrapper">
		<!-- <content-title title="주문완료" :steps="steps" :activeStep="3" /> -->
		<payment-complete-info class="payment-complete-info"></payment-complete-info>
		<payment-complete-order-info class="payment-order-complete-info"></payment-complete-order-info>
		<payment-complete-orderer-info class="payment-complete-orderer-info"></payment-complete-orderer-info>
		<payment-complete-recipient-info class="payment-complete-recipient-info"></payment-complete-recipient-info>
	</div>
</template>

<script>
import PaymentCompleteInfo from '@/views/payment/components/complete/PaymentCompleteInfo.vue'
import PaymentCompleteOrderInfo from '@/views/payment/components/complete/PaymentCompleteOrderInfo.vue'
import PaymentCompleteOrdererInfo from '@/views/payment/components/complete/PaymentCompleteOrdererInfo.vue'
import PaymentCompleteRecipientInfo from '@/views/payment/components/complete/PaymentCompleteRecipientInfo.vue'
import ContentTitle from '@/components/content/ContentTitle'
import { mapGetters, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'PaymentComplete',
	components: {
		PaymentCompleteInfo,
		PaymentCompleteOrderInfo,
		PaymentCompleteOrdererInfo,
		PaymentCompleteRecipientInfo,
		ContentTitle,
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchOrder()
			},
			immediate: true,
		},
	},
	data() {
		return {
			steps: [
				{
					value: 1,
					text: '장바구니',
				},
				{
					value: 2,
					text: '주문',
				},
				{
					value: 3,
					text: '주문 완료',
				},
			],
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
	},
	methods: {
		...mapActions('order', ['lookupOrder']),
		async fetchOrder() {
			try {
				await this.lookupOrder({ instituteId: this.instituteId, id: this.$route.params.id })
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-complete {
	/* background: #fef9ec; */
	.title {
		font-size: 1.5rem;
		font-weight: 700;
		color: black;
		margin-top: 30px;
		border-bottom: 2px solid black;
		padding-bottom: 15px;
	}

	.payment-complete-info {
		margin-top: 40px;
	}
	.payment-order-complete-info {
		margin-top: 30px;
	}
	.payment-complete-orderer-info {
		margin-top: 40px;
	}
	.payment-complete-recipient-info {
		margin-top: 40px;
	}
}
</style>
