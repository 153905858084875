<template>
	<div class="UnPaymentManagementDetail">
		<content-title title="미결제 내역" :steps="steps" :activeStep="activeStep" />
		<!--02. 결제 일 경우-->
		<div class="content-wrapper" v-if="activeStep == 2">
			<simple-input-form title="결제자 정보" :list="subscriberInfo" :isCompleted="true" isDirect @input="onInputSubscriberInfo" />
			<payment-info-table
				title="결제 상품"
				:headers="unPaymentTableHeaders"
				:list="paymentOrderList"
				:showOrderInfo="false"
				noDataText="미결제 상품이 없습니다"
			/>
			<order-price-box title="최종 결제 정보" mode="normal" :info="orderPriceInfo" />
			<payment-info-with-tax ref="payment-info-with-tax" @changePaymentMethod="changePaymentMethod" />
			<payment-privacy-policy ref="payment-privacy-policy" class="payment-privacy-policy" />
			<div class="btn-wrap bottom">
				<button class="btn btn-complete btn-lg" @click="onClickPay">결제하기</button>
			</div>
		</div>
		<!--03. 결제완료 일 경우-->
		<div class="content-wrapper" v-else-if="activeStep == 3">
			<complete-notice
				:title="completeNotice.title"
				:titleImg="completeNotice.titleImg"
				:description="completeNotice.description"
				:noticeImg="completeNotice.noticeImg"
				:leftBtnText="completeNotice.leftBtnText"
				:rightBtnText="completeNotice.rightBtnText"
				:list="completeNotice.list"
				@leftBtnEv="goList"
				@rightBtnEv="download"
			/>
			<template v-if="isPro">
				<div class="go-pro" @click="goProPage">
					<img src="@/assets/svg/logo-labmanagerpro.svg" />
					<b>로 이동</b>
					<img class="arrow" src="@/assets/svg/icon-arrow.svg" />
				</div>
				<p class="pro-desc">LAB MANAGER PRO에서 방금 구매한 상품들을 관리할 수 있어요.</p>
			</template>
		</div>
		<modal-payment-loading />
		<modal-password-confirm id="ModalPasswordConfirm3" :isPay="true" @regist="onSuccessPassword"></modal-password-confirm>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import ContentTitle from '@/components/content/ContentTitle'
import SimpleInputForm from '@/components/form-item/SimpleInputForm'
import PaymentInfoTable from '@/views/my-page/payment/components/PaymentInfoTable'
import OrderPriceBox from '@/components/list/OrderPriceBox'
import PaymentPrivacyPolicy from '@/views/payment/components/PaymentPrivacyPolicy'
import PaymentInfoWithTax from '@/views/my-page/payment/components/PaymentInfoWithTax'
import CompleteNotice from '@/components/content/CompleteNotice'
import ModalPaymentLoading from '@/components/modal/ModalPaymentLoading.vue'
import ModalPasswordConfirm from '@/components/modal/ModalPasswordConfirm'
import PageName from '@/utils/define/PageName'
import { ToastType } from '@/utils/define/ToastType'
import cookies from 'vue-cookies'
import { getSYSUrl } from '@/services/api/common.js'

const PAYMENT_METHOD = {
	CARD: 'CARD',
	EBILL: 'EBILL',
}

export default {
	name: 'UnPaymentManagementDetail',
	components: {
		ContentTitle,
		SimpleInputForm,
		PaymentInfoTable,
		OrderPriceBox,
		PaymentPrivacyPolicy,
		PaymentInfoWithTax,
		CompleteNotice,
		ModalPaymentLoading,
		ModalPasswordConfirm,
	},
	data() {
		return {
			activeStep: 2,
			steps: [
				{
					value: 1,
					text: '미결제 내역',
				},
				{
					value: 2,
					text: '결제',
				},
				{
					value: 3,
					text: '결제 완료',
				},
			],
			unPaymentTableHeaders: [
				{ text: '상품정보', width: '80%', key: 'info' },
				{ text: '미결제 금액(VAT 미포함)', width: '20%', key: 'price' },
			],
			paymentMethod: '',
			subscriberInfo: [],
		}
	},
	computed: {
		...mapGetters('payment', ['paymentOrderList', 'paymentPrice', 'paymentPriceWithVat', 'paymentVat']),
		...mapGetters('user', ['instituteId']),
		...mapState('user', ['user', 'institute', 'myPageInfo']),
		...mapState('payment', ['payment']),
		...mapState('main', ['isPro']),
		...mapState('institute', { realInstitute: 'institute' }),
		orderPriceInfo() {
			let info = {}
			info.price = {
				val: this.paymentPrice,
				text: '주문 금액 합계',
			}
			info.shippingPrice = {
				val: 0,
				text: '배송비 합계',
			}
			info.vat = {
				val: this.paymentVat,
				text: 'VAT(10%)',
			}
			info.allPrice = {
				val: this.paymentPriceWithVat,
				text: '결제 금액 합계(VAT 포함)',
			}
			return info
		},
		completeNotice() {
			let notice = {}
			notice.titleImg = require('@/assets/svg/icon-currency.svg')
			notice.noticeImg = require('@/assets/svg/content/success-image.svg')
			notice.leftBtnText = '결제 내역 보기'
			notice.rightBtnText = '서류 일괄 다운로드'
			notice.list = [`결제번호 : ${this.payment.no}`, `결제자 이메일 : ${this.payment.payer.email}`]
			if (this.paymentMethod == PAYMENT_METHOD.CARD) {
				notice.title = '결제가 완료되었습니다'
				notice.description = `등록된 이메일주소로 결제서류(카드전표, 사업자등록증 사본, 거래명세서)가 <br>
				발행되었습니다.`
			} else if (this.paymentMethod == PAYMENT_METHOD.EBILL) {
				notice.title = '전자세금계산서 발행이 완료되었습니다'
				notice.description = `등록된 이메일로 전자세금계산서가 발행되었습니다.  전자세금계산서<br>
					발행 후 입금 대기 상태가 되며, 입금이 완료되어야 미결제 금액이 차감됩니다<br>
					발행된 세금계산서 내역은 [결제내역 > 입금대기]  에서 확인할 수 있습니다`
			}
			return notice
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				await this.paymentCalculate({
					instituteId: this.instituteId,
					body: {
						orderItemIds: JSON.parse(this.$route.query.orderItemIds),
					},
				})

				this.subscriberInfo = [
					{ name: '소속', value: this.institute.name ?? '', all: true, readonly: true, required: true },
					{ name: '이름', value: this.user.name ?? '', all: true, required: true },
					{ name: '이메일', value: this.user.email ?? '', all: true, required: true },
					{ name: '연락처', value: this.user.phone ?? '', all: true, required: true },
				]
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('payment', ['paymentCalculate', 'requestPayment', 'lookupPayment', 'paymentPrint']),
		...mapActions('institute', ['lookupInstitute']),
		...mapMutations('user', ['setMyPageInfo']),
		changePaymentMethod(paymentMethod) {
			this.paymentMethod = paymentMethod
		},
		onInputSubscriberInfo(item) {
			for (const info of this.subscriberInfo) {
				if (info.name === item.name) {
					info.value = item.value
					break
				}
			}
		},
		onClickPay() {
			if (!this.validate()) return
			if (this.paymentMethod === PAYMENT_METHOD.CARD) {
				this.$bvModal.show('ModalPasswordConfirm3')
			} else {
				this.pay(this.makePayload())
			}
		},
		onSuccessPassword(password) {
			const paymentInfo = this.$refs['payment-info-with-tax'].getPaymentInfo()
			const payload = this.makePayload()
			payload.cardId = paymentInfo.cardId
			payload.cardPaymentPassword = password
			this.pay(payload)
		},
		async pay(payload) {
			try {
				this.$bvModal.show('ModalPaymentLoading')

				const response = await this.requestPayment({ instituteId: this.instituteId, body: payload })
				const paymentInfo = this.$refs['payment-info-with-tax'].getPaymentInfo()
				if (paymentInfo.isRememberPaymentMethod === true) cookies.set('unPaymentPaymentMethod', this.paymentMethod)
				await this.lookupPayment({ instituteId: this.instituteId, id: response.paymentId })
				this.activeStep = 3
				await this.lookupInstitute(this.instituteId)
				this.setMyPageInfo({
					...this.realInstitute,
					instituteName: this.institute?.name,
					name: this.user.name,
					image: this.user.image,
				})
				this.$root.darkToast('결제가 완료되었습니다.')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			} finally {
				this.$bvModal.hide('ModalPaymentLoading')
			}
		},
		makePayload() {
			const paymentInfo = this.$refs['payment-info-with-tax'].getPaymentInfo()

			const payload = {
				orderItemIds: JSON.parse(this.$route.query.orderItemIds),
				method: this.paymentMethod,
				businessRegistrationId: paymentInfo.businessRegistrationId,
			}

			this.subscriberInfo.forEach(info => {
				if (info.name === '이름') {
					payload.payerName = info.value
				} else if (info.name === '연락처') {
					payload.payerPhone = info.value.replace(/-/gi, '')
				} else if (info.name === '이메일') {
					payload.payerEmail = info.value
				}
			})

			return payload
		},
		goList() {
			this.$router.push({
				name: PageName.MyPage.PaymentHistoryDetail,
				params: {
					id: this.payment.id,
				},
			})
		},
		async download() {
			try {
				const response = await this.paymentPrint({ instituteId: this.instituteId, body: { id: this.payment.id } })
				window.open(response.url, '_blank')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		validate() {
			let isValid = true
			const paymentInfo = this.$refs['payment-info-with-tax'].getPaymentInfo()
			if (!this.$refs['payment-privacy-policy'].validate()) {
				isValid = false
			} else if (this.subscriberInfo.some(info => info.required && !info.value)) {
				isValid = false
				window.scrollTo(0, 0)
			} else if (this.paymentMethod === PAYMENT_METHOD.CARD && !paymentInfo.cardId) {
				isValid = false
				this.$root.toast('에러', '결제할 카드를 선택해주세요.', ToastType.ERROR)
			} else if (!paymentInfo.businessRegistrationId) {
				isValid = false
				this.$root.toast('에러', '결제처를 선택해주세요.', ToastType.ERROR)
			}

			return isValid
		},
		goProPage() {
			// const proId = cookies.get('instituteId')
			// if (proId) location.href = `${getSYSUrl()}/${proId}/product/pay`
			// else location.href = getSYSUrl()
			window.close()
		},
	},
}
</script>

<style lang="scss" scoped>
.UnPaymentManagementDetail {
	.simple-input-form-wrap {
		margin-top: 40px;
	}
	.payment-info-table,
	.order-price-box,
	.payment-info {
		margin-top: 64px;
	}
	.payment-privacy-policy {
		margin-top: 24px;
	}
	.btn-wrap {
		margin: 40px 0 80px;
	}
}
.go-pro {
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-top: 24px;
	padding: 16px 40px;
	width: 100%;
	background: #fff;
	border: 1px solid #eee;
	border-radius: 10px;
	b {
		font-size: 16px;
		color: #333;
		margin-left: 8px;
	}
	.arrow {
		margin-left: auto;
	}
}
.pro-desc {
	margin-top: 8px;
	font-weight: 500;
	font-size: 12px;
	line-height: 17px;
	color: #666666;
	margin-left: 10px;
	&::before {
		vertical-align: middle;
		display: inline-block;
		content: '';
		width: 4px;
		height: 4px;
		background-color: #666;
		border-radius: 50%;
		margin-right: 10px;
	}
}
</style>
