<template>
	<div class="wrapper" @click="backToPro">
		<img class="logo" src="@/assets/images/pro-rectangle.png" />
		<div class="text-wrapper">
			<div class="upper-text"><b>랩매니저 PRO</b> 로 돌아가기</div>
			<div class="lower-text">{{ proPageName }}</div>
		</div>
		<img class="icon-close" src="@/assets/images/open-new.png" />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { goToSSOMain, isLogin } from '@/services/api/common'

export default {
	name: 'BackToPro',
	components: {},
	computed: {
		...mapState('main', ['proPageName']),
	},
	methods: {
		backToPro() {
			window.close()
		},
	},
}
</script>

<style lang="scss" scoped>
.wrapper {
	position: fixed;
	left: 40px;
	bottom: 40px;
	width: 328px;
	height: 68px;
	background: #ffffff;
	border: 1px solid #eeeeee;
	box-shadow: 4px 4px 20px rgba(17, 17, 17, 0.15);
	border-radius: 8px;
	padding: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	cursor: pointer;

	&:hover {
		box-shadow: 4px 4px 35px rgba(17, 17, 17, 0.3);
	}

	.logo {
		width: 48px;
		height: 48px;
	}
	.text-wrapper {
		width: 220px;
		margin-bottom: 2px;
		.upper-text {
			display: flex;
			align-items: center;
			gap: 2px;
			font-size: 14px;
			b {
				font-size: 16px;
			}
		}
		.lower-text {
			font-size: 12px;
			color: #666;
			@include ellipse;
		}
	}
	.icon-close {
		margin-left: auto;
	}
}
</style>
