<template>
	<div class="selector-wrapper">
		<category-box
			v-for="(category, index) in categoryList"
			:key="`category_${index}`"
			:category="category"
			:selectedCategoryId="selectedCategory.id"
			@onClickCategory="onClickCategory"
		/>
	</div>
</template>

<script>
import CategoryBox from './CategoryBox.vue'
import { isLogin } from '@/services/api/common'
import { mapGetters, mapActions } from 'vuex'

export default {
	components: { CategoryBox },
	name: 'CategorySelector',
	computed: {
		...mapGetters('user', ['instituteId']),
		categoryList() {
			return [
				{ title: '화학물질', image: this.getImage(1), code: 'CAT_CHEMICAL', id: 1 },
				{ title: '분석용 칼럼', image: this.getImage(2), code: 'CAT_CHROMATOGRAPHY', id: 2 },
				{ title: '연구 장비', image: this.getImage(3), code: 'CAT_LABWARE', id: 3 },
				{ title: '실험용품', image: this.getImage(4), code: 'CAT_LABETC', id: 4 },
				{ title: '보호구', image: this.getImage(5), code: 'CAT_PROTECTION', id: 5 },
				{ title: '소모품', image: this.getImage(6), code: 'CAT_ETC', id: 6 },
				{ title: '사무용품', image: this.getImage(7), code: 'CAT_OFFICE', id: 7 },
			]
		},
		isLoginUser() {
			return isLogin()
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return
				await this.lookupBestByCategory({
					instituteId: this.instituteId,
					categoryCode: this.selectedCategory.code,
					offset: 0,
					length: 4,
				})
			},
			immediate: true,
		},
	},
	data() {
		return {
			selectedCategory: {
				code: 'CAT_CHEMICAL',
				id: 1,
			},
		}
	},

	created() {
		this.onCreatedCategorySelector()
	},
	methods: {
		...mapActions('main', ['lookupBestByCategory']),
		onCreatedCategorySelector() {
			this.loadData()
		},
		async loadData() {
			await this.lookupBestByCategory({
				instituteId: this.instituteId,
				categoryCode: this.selectedCategory.code,
				offset: 0,
				length: 4,
			})
		},
		onClickCategory(category) {
			this.selectedCategory.id = category.id
			this.selectedCategory.code = category.code
			this.loadData()
		},
		getImage(id) {
			let result
			if (id === this.selectedCategory.id) {
				result = {
					1: require('@/assets/svg/main/chemical_active.svg'),
					2: require('@/assets/svg/main/chromatography_active.svg'),
					3: require('@/assets/svg/main/equipment_active.svg'),
					4: require('@/assets/svg/main/item_active.svg'),
					5: require('@/assets/svg/main/protection_active.svg'),
					6: require('@/assets/svg/main/etc_active.svg'),
					7: require('@/assets/svg/main/tool_active.svg'),
				}
			} else {
				result = {
					1: require('@/assets/svg/main/chemical.svg'),
					2: require('@/assets/svg/main/chromatography.svg'),
					3: require('@/assets/svg/main/equipment.svg'),
					4: require('@/assets/svg/main/item.svg'),
					5: require('@/assets/svg/main/protection.svg'),
					6: require('@/assets/svg/main/etc.svg'),
					7: require('@/assets/svg/main/tool.svg'),
				}
			}
			return result[id]
		},
	},
}
</script>

<style lang="scss" scoped>
.selector-wrapper {
	height: 80px;
	display: flex;
	justify-content: space-between;
	padding: 20px 0 0 0;
}
</style>
