<template>
	<div class="product-list">
		<div class="product-title" v-if="title">
			<h5>{{ title }}</h5>
		</div>
		<search-sort-tab v-if="tabList.length > 0" :tabList="tabList" @sortProduct="sortProduct" />
		<div v-if="isShowProductList">
			<product-list-item
				v-for="(item, index) in list"
				:key="`product_${index}`"
				:item="item"
				:highlightValue="highlightValue"
				:showWish="true"
				@changeWishValue="$emit('changeWishValue')"
				@searchSuccess="searchSuccess"
			/>
		</div>
		<loading v-else keyword="상품 불러오는 중" />
	</div>
</template>

<script>
import SearchSortTab from '@/components/tab/SearchSortTab.vue'
import ProductListItem from '@/components/item/ProductListItem.vue'
import Loading from '@/components/Loading.vue'

export default {
	name: 'ProductList',
	components: {
		SearchSortTab,
		ProductListItem,
		Loading,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: '',
		},
		tabList: {
			type: Array,
			default: () => [],
		},
		highlightValue: {
			type: String,
			default: '',
		},
		isShowProductList: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		sortProduct(tab) {
			this.$emit('sortProduct', tab)
		},
		searchSuccess(obj) {
			this.$emit('searchSuccess', obj)
		}
	},
}
</script>

<style lang="scss" scoped>
.product-list {
	width: 100%;
	.product-title {
		margin-bottom: 20px;
	}
}
</style>
