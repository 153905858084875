import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/modules/ParamMaker'

const endPoint = {
	lookupMemberList: (instituteId, paramsObject) => `institute/${instituteId}/member/list?${ParamMaker.setParamUsingObject(paramsObject)}`,
	updateMember: instituteId => `institute/${instituteId}/member`,
	lookupInvitationLink: instituteId => `institute/${instituteId}/invitation/link`,
	inviteMember: instituteId => `institute/${instituteId}/invitation/email`,
	acceptInvitation: () => `invitation/accept`,
}

const memberAPI = {
	lookupMemberList: (instituteId, paramsObject) => axiosInstance.get(endPoint.lookupMemberList(instituteId, paramsObject)),
	updateMember: (instituteId, body) => axiosInstance.patch(endPoint.updateMember(instituteId), body),
	lookupInvitationLink: instituteId => axiosInstance.post(endPoint.lookupInvitationLink(instituteId)),
	inviteMember: (instituteId, body) => axiosInstance.post(endPoint.inviteMember(instituteId), body),
	acceptInvitation: body => axiosInstance.post(endPoint.acceptInvitation(), body),
}

export default memberAPI
