import { getSYSUrl } from '@/services/api/common'

export default {
	// computed: {
	// 	parentWindow() {
	// 		if( window.parent.name.includes('JW MALL') )
	// 			return window.parent
	// 		return null
	// 	},
	// },
	data() {
		return {
			parentWindow: null,
		}
	},
	created() {
		window.addEventListener('message', this.receiveMessage, false)
	},
	beforeDestroy() {
		window.removeEventListener('message', this.receiveMessage)
	},
	methods: {
		cartToDraft(data) {
			try {
				if (this.parentWindow) {
					this.parentWindow.postMessage({ code: 'CART_TO_DRAFT', cartIds: data }, '*')
					window.close()
				} else {
					window.open(`${getSYSUrl()}/mall/draft?cartIds=${data}`, '_blank')
				}
			} catch (e) {
				window.open(`${getSYSUrl()}/mall/draft?cartIds=${data}`, '_blank')
			}
		},
		productToDraft(data) {
			try {
				if (this.parentWindow) {
					this.parentWindow.postMessage({ code: 'CART_TO_DRAFT', item: data }, '*')
					window.close()
				} else {
					window.open(`${getSYSUrl()}/mall/draft?item=${data}`, '_blank')
				}
			} catch (e) {
				window.open(`${getSYSUrl()}/mall/draft?cartIds=${data}`, '_blank')
			}
		},
		receiveMessage(event) {
			if (event.data.code == 'CART_TO_DRAFT') {
				this.parentWindow = event.source
				window.removeEventListener('message', this.receiveMessage)
			}
		},
	},
}
