<template>
	<div class="payment wrapper">
		<content-title title="주문" :steps="steps" :activeStep="2" />
		<payment-orderer-info ref="payment-orderer-info" class="payment-order-info" @input="getPhone" />
		<payment-recipient-info ref="payment-recipient-info" class="payment-recipient-info" :ordererPhone="ordererPhone" />
		<payment-product-info-table class="payment-product-info-table" />
		<payment-receive-email ref="payment-receive-email" class="payment-receive-email" />
		<payment-info ref="payment-info" class="payment-info" />
		<payment-privacy-policy
			ref="payment-privacy-policy"
			checkboxLabel="위 안내사항 및 주문 내용을 확인했으며, 주문을 진행합니다."
			class="payment-privacy-policy"
		/>
		<div class="button-wrapper">
			<button class="btn lg btn-primary" @click="onClickPay">주문하기</button>
		</div>
		<payment-inicis ref="payment-inicis" />

		<modal-payment-loading />
		<modal-password-confirm id="ModalPasswordConfirm2" :isPay="true" @regist="onSuccessPassword" />
	</div>
</template>

<script>
import PaymentOrdererInfo from '@/views/payment/components/PaymentOrdererInfo.vue'
import PaymentRecipientInfo from '@/views/payment/components/PaymentRecipientInfo.vue'
import PaymentProductInfoTable from '@/views/payment/components/PaymentProductInfoTable.vue'
import PaymentReceiveEmail from '@/views/payment/components/PaymentReceiveEmail.vue'
import PaymentInfo from '@/views/payment/components/PaymentInfo.vue'
import PaymentPrivacyPolicy from '@/views/payment/components/PaymentPrivacyPolicy.vue'
import PaymentInicis from '@/views/payment/components/PaymentInicis.vue'
import ModalPaymentLoading from '@/components/modal/ModalPaymentLoading.vue'
import ContentTitle from '@/components/content/ContentTitle'
import ModalPasswordConfirm from '@/components/modal/ModalPasswordConfirm'
import PageName from '@/utils/define/PageName'
import { mapState, mapGetters, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import cookies from 'vue-cookies'
import { requestGaEventPurchase } from '@/utils/ga'

const PAYMENT_METHOD = {
	CARD: 'CARD',
	DIRECT_CARD: 'DIRECT_CARD',
	LATER: 'LATER',
}

const INSTITUTE_TYPE = {
	PERSON: 'PERSON',
	UNIVERSITY: 'UNIVERSITY',
	ENTERPRISE: 'ENTERPRISE',
	SELLER: 'SELLER',
}

export default {
	name: 'Payment',
	components: {
		PaymentOrdererInfo,
		PaymentRecipientInfo,
		PaymentProductInfoTable,
		PaymentReceiveEmail,
		PaymentInfo,
		PaymentPrivacyPolicy,
		ContentTitle,
		ModalPasswordConfirm,
		PaymentInicis,
		ModalPaymentLoading,
	},
	data() {
		return {
			steps: [
				{
					value: 1,
					text: '장바구니',
				},
				{
					value: 2,
					text: '주문',
				},
				{
					value: 3,
					text: '주문 완료',
				},
			],
			ordererPhone: '',
		}
	},
	computed: {
		...mapState('delivery', ['basicAddress']),
		...mapState('order', ['estimateId', 'selectedProductList']),
		...mapState('user', ['user', 'institute']),
		...mapGetters('user', ['instituteId']),
		isPerson() {
			if (this.$_.isEmpty(this.institute)) return
			return this.institute.type.code === INSTITUTE_TYPE.PERSON
		},
		currentAddress() {
			return cookies.get('currentAddress2') ?? this.basicAddress
		},
	},
	methods: {
		...mapActions('order', ['order']),
		onClickPay() {
			if (!this.validate()) return
			const paymentInfo = this.$refs['payment-info'].getPaymentInfo()
			if (paymentInfo.paymentMethod === PAYMENT_METHOD.CARD) {
				this.$bvModal.show('ModalPasswordConfirm2')
			} else {
				this.pay(this.makePayload())
			}
		},
		async pay(payload) {
			try {
				this.$bvModal.show('ModalPaymentLoading')

				const response = await this.order({ instituteId: this.instituteId, body: payload })
				const paymentInfo = this.$refs['payment-info'].getPaymentInfo()
				if (paymentInfo.isRememberPaymentMethod === true) cookies.set('paymentMethod', paymentInfo.paymentMethod)
				if (paymentInfo.paymentMethod === PAYMENT_METHOD.DIRECT_CARD) {
					this.addPostMSGEvent()
					this.$refs['payment-inicis'].pay({
						orderId: response.orderId,
						payerName: payload.ordererName,
						payerPhone: payload.ordererPhone,
						email: payload.ordererEmail,
					})
				} else {
					await requestGaEventPurchase(this.instituteId, response.orderId)
					this.goPaymentComplete(response.orderId)
				}
				this.$root.darkToast('주문이 정상적으로 접수되었습니다.')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			} finally {
				this.$bvModal.hide('ModalPaymentLoading')
			}
		},
		addPostMSGEvent() {
			const self = this
			window.onmessage = async function (e) {
				const { type, data } = e.data
				const { result, orderId, errorMessage } = data
				if (type === 'inicisCallback') {
					if (result == 'true' || result == true) {
						await requestGaEventPurchase(self.instituteId, orderId)
						self.goPaymentComplete(orderId)
					} else {
						self.$toast(errorMessage)
					}
				}
			}
		},
		goPaymentComplete(orderId) {
			this.$router.replace({
				name: PageName.PaymentComplete,
				params: {
					id: orderId,
				},
			})
		},
		onSuccessPassword(password) {
			const paymentInfo = this.$refs['payment-info'].getPaymentInfo()
			const payload = this.makePayload()
			payload.cardId = paymentInfo.cardId
			payload.cardPaymentPassword = password
			this.pay(payload)
		},
		makePayload() {
			const recipient = this.$refs['payment-recipient-info'].getRecipient()
			const ordererPhone = this.$refs['payment-orderer-info'].getPhone()
			const paymentInfo = this.$refs['payment-info'].getPaymentInfo()
			const products = this.selectedProductList.map(({ productId, quantity, cartId }) => {
				return { id: productId, quantity: quantity, cartId: cartId }
			})
			const payload = {
				ordererName: this.user.name,
				ordererPhone: ordererPhone.replace(/-/gi, ''),
				ordererEmail: this.user.email,
				recipientName: recipient.name,
				recipientPhone: recipient.phone.replace(/-/gi, ''),
				deliveryRequest: recipient.requestedTerm,
				deliveryId: this.currentAddress.id,
				products: products,
				paymentMethod: paymentInfo.paymentMethod,
				requestId: this.$route.query?.requestId ?? null,
			}
			if (this.estimateId) payload.estimateId = this.estimateId

			if (!this.isPerson) {
				const email = this.$refs['payment-receive-email'].getEmail()
				payload.documentReceiveEmail = email
			}

			return payload
		},
		validate() {
			let isValid = true
			const paymentInfo = this.$refs['payment-info'].getPaymentInfo()
			if (!this.$refs['payment-orderer-info'].validate()) {
				isValid = false
			} else if (!this.$refs['payment-recipient-info'].validate()) {
				isValid = false
			} else if (paymentInfo.paymentMethod === PAYMENT_METHOD.CARD && !paymentInfo.cardId) {
				isValid = false
				this.$root.toast('에러', '결제할 카드를 선택해주세요.', ToastType.ERROR)
			} else if (!this.$refs['payment-privacy-policy'].validate()) {
				isValid = false
			}

			return isValid
		},
		getPhone(val) {
			this.ordererPhone = val
		},
	},
}
</script>

<style lang="scss" scoped>
.payment {
	.payment-order-info {
		margin-top: 30px;
	}
	.payment-recipient-info {
		margin-top: 40px;
	}
	.payment-product-info-table {
		margin-top: 20px;
	}
	.payment-receive-email {
		margin-top: 40px;
	}
	.payment-info {
		margin-top: 30px;
	}
	.payment-privacy-policy {
		margin-top: 30px;
	}

	.button-wrapper {
		display: flex;
		justify-content: center;
		margin-top: 40px;
		button {
			width: 400px;
			height: 48px;
			font-size: 1rem;
			&.btn-primary {
				background-color: $PRIMARY_YELLOW;
			}
		}
	}
}
</style>
