<template>
	<div @click="gotoDetail">
		<div class="box-wrapper" v-if="size === 'large'">
			<img v-if="hasRank" class="rank" :src="require(`@/assets/svg/main/best_${index + 1}.svg`)" />
			<img class="product" :src="item.images.length > 0 ? item.images[0].url : require('@/assets/svg/empty_product.svg')" />
			<p class="brand">{{ item.brand.name }}</p>
			<p class="title">{{ item.name }}</p>
			<p class="price">
				<span v-if="item.discountPercent" class="discount">{{ roundNum(item.discountPercent) }}%</span>
				{{ CommaNum(item.minSellingUnitPrice) }}
				<span style="margin: 8px 0 0 5px">원~</span>
			</p>
			<img v-if="item.shippingDayPolicy === 'SHAK'" src="@/assets/svg/main/express.svg" />
		</div>
		<div class="box-wrapper-small" v-else>
			<img class="product" :src="item.images.length > 0 ? item.images[0].url : require('@/assets/svg/empty_product.svg')" />
			<p class="brand">{{ item.brand.name }}</p>
			<p class="title">{{ item.name }}</p>
			<p class="price">
				<span v-if="item.discountPercent" class="discount">{{ roundNum(item.discountPercent) }}%</span>
				{{ CommaNum(item.minSellingUnitPrice) }}
				<span style="margin: 8px 0 0 5px">원~</span>
			</p>
			<div class="item-bottom">
				<img v-if="item.shippingDayPolicy === 'SHAK'" src="@/assets/svg/main/express.svg" />
				<p class="order-count" v-if="item.orderCount">{{ item.orderCount }}번 주문</p>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import PageName from '@/utils/define/PageName'

export default {
	name: 'ProductBox',
	mixins: [mixin],
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		index: {
			type: Number,
			default: 0,
		},
		hasRank: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: 'large',
		},
	},
	methods: {
		gotoDetail() {
			this.$router.push({
				name: PageName.Detail,
				params: {
					category: this.$route.params.category ? this.$route.params.category : 'list',
					productInfoId: this.item.productInfoId,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.box-wrapper {
	position: relative;
	cursor: pointer;
	border-radius: 7px;
	height: 100%;
	p {
		padding-top: 7px;
		width: 255px;
	}
	.rank {
		position: absolute;
		top: -5px;
		left: 8px;
	}
	.product {
		width: 255px;
		height: 255px;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 7px;
		background: $PRIMARY_WHITE;
		object-fit: contain;
	}
	.brand {
		@include ellipse;
		color: #666666;
		font-weight: 700;
		font-size: 13px;
	}
	.title {
		min-height: 55px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-weight: 500;
		font-size: 16px;
	}
	.price {
		font-weight: 700;
		font-size: 22px;
		display: flex;
	}
	.discount {
		padding-right: 10px;
		font-weight: 900;
		font-size: 22px;
		color: $PRIMARY_RED;
	}
	span {
		font-weight: 700;
		font-size: 13px;
	}
	&:hover {
		outline: solid 8px #fff5dd;
		background: #fff5dd;
	}
}
.box-wrapper-small {
	position: relative;
	cursor: pointer;
	border-radius: 7px;
	height: 100%;
	p {
		padding-top: 7px;
		width: 165px;
	}
	.product {
		width: 165px;
		height: 165px;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 7px;
		background: $PRIMARY_WHITE;
		object-fit: contain;
	}
	.brand {
		@include ellipse;
		font-weight: 500;
		font-size: 12px;
	}
	.title {
		min-height: 52px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-weight: 500;
		font-size: 15px;
	}
	.price {
		font-weight: 700;
		font-size: 21px;
		display: flex;
	}
	.discount {
		padding-right: 10px;
		font-weight: 900;
		font-size: 21px;
		color: $PRIMARY_RED;
	}
	span {
		font-weight: 700;
		font-size: 13px;
	}
	&:hover {
		outline: solid 8px #fff5dd;
		background: #fff5dd;
	}
	.item-bottom {
		padding-top: 8px;
		display: flex;
		img {
			padding-right: 10px;
		}
		.order-count {
			width: 100%;
			color: #ff8248;
			font-weight: 900;
			font-size: 11px;
			padding: 0;
		}
	}
}
</style>
