<template>
	<div class="payment-product-info-table">
		<div class="header">
			<div class="title">상품정보</div>
		</div>
		<div class="content">
			<table class="product-info-table">
				<thead>
					<tr>
						<th style="width: 650px"><span>상품 정보</span></th>
						<th style="width: 80px"><span>수량</span></th>
						<th style="width: 110px"><span>판매가</span></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(product, index) in products" :key="`row_${product.id}_${index}`">
						<td>
							<div>
								<payment-product-info ref="payment-product-info" :index="index" :product="product"></payment-product-info>
							</div>
						</td>
						<td>
							<div>
								{{ product.quantity }}
							</div>
						</td>
						<td>
							<div class="selling-price">{{ CommaNum(product.sellingPrice) }}원</div>
							<div class="price" v-if="!checkSamePrice(product)">{{ CommaNum(product.price) }}원</div>
						</td>
					</tr>
				</tbody>
			</table>
			<cart-product-total-price
				ref="cart-product-total-price"
				class="cart-product-total-price"
				:estimateId="estimateId"
				:requestId="requestId"
			></cart-product-total-price>
		</div>
	</div>
</template>

<script>
import PaymentProductInfo from '@/views/payment/components/PaymentProductInfo.vue'
import CartProductTotalPrice from '@/views/cart/components/CartProductTotalPrice.vue'
import mixin from '@/mixins'
import { mapState, mapGetters } from 'vuex'

export default {
	name: 'PaymentProductInfoTable',
	components: {
		PaymentProductInfo,
		CartProductTotalPrice,
	},
	mixins: [mixin],
	computed: {
		...mapState('order', ['estimateId', 'selectedProductList']),
		...mapGetters('order', ['products']),
		requestId() {
			return this.$route.query?.requestId
		},
	},
	mounted() {
		this.setProductTotalPrice()
	},
	methods: {
		setProductTotalPrice() {
			const productList = this.selectedProductList.map(({ productId, quantity }) => {
				return { productId, quantity }
			})

			if (this.$_.isEmpty(this.$refs['cart-product-total-price'])) return
			this.$refs['cart-product-total-price'].setProductList(productList)
		},
		checkSamePrice({ sellingPrice, price }) {
			return sellingPrice === price
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-product-info-table {
	.header {
		.title {
			font-weight: bold;
			font-size: 1.25rem;
		}
	}
	.content {
		max-height: calc(60px + 750px + 90px);
		overflow-y: auto;
		margin-top: 10px;
		border: 1px solid $COLOR_DDD;
		border-radius: 5px;
		.product-info-table {
			width: 100%;
			thead {
				height: 60px;
				background: $LIST_BACKGROUND;
				border-radius: 5px;
				position: sticky;
				top: 0;
				z-index: 1;
				tr {
					th {
						background-color: $LIST_BACKGROUND;
						text-align: center;
						font-size: 0.875rem;
						&:first-child {
							padding-left: 20px;
							text-align: left;
						}
					}
				}
			}
			tbody {
				border-top: 1px solid $COLOR_DDD;
				border-bottom: 1px solid $COLOR_DDD;
				tr {
					height: 150px;
					&:not(:last-child) {
						border-bottom: 1px solid $COLOR_DDD;
					}
					td {
						&:first-child div {
							padding: 10px;
						}
						&:not(:first-child) div {
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.selling-price {
							font-weight: 700;
						}
						.price {
							text-decoration: line-through;
							font-size: 0.875rem;
							color: $FONT_PLACEHOLDER;
						}
					}
					td + td {
						border-left: 1px solid $COLOR_DDD;
					}
				}
			}
		}
		.cart-product-total-price {
			position: sticky;
			bottom: 0;
		}
	}
}
</style>
