<template>
	<thead class="table-header">
		<tr>
			<th width="52">
				<div>
					<input type="checkbox" v-model="allCheck" :disabled="disabled" class="checkbox" />
				</div>
			</th>
			<th v-for="(header, index) in headers" :key="`header_${index}`" :width="header.width">{{ header.name }}</th>
		</tr>
	</thead>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
	name: 'TableHeader',
	props: {
		headers: {
			type: Array,
			default: () => [],
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		mode: {
			type: String,
			default: '',
		},
	},
	mounted() {
		if (this.disabled) {
			this.allCheck = true
		}
		if (this.mode == 'cart') {
			this.allCheck = true
		}
	},
	computed: {
		...mapState('product', ['selectedList']),
	},
	watch: {
		allCheck(val) {
			this.$emit('change', val)
		},
		disabled(val) {
			if (val) {
				this.allCheck = true
			}
		},
	},
	data() {
		return {
			allCheck: false,
		}
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.table-header {
	background-color: $HOVER_BACKGROUND;
	border-bottom: 1px solid $LINE_DIVIDER;
	th {
		height: 40px;
		position: sticky;
		top: 0;
		padding-left: 8px;
		color: $COLOR_666;
		background-color: $HOVER_BACKGROUND;
		z-index: 1;
		&:last-child {
			&:after {
				display: none;
			}
		}
		&:after {
			width: 1px;
			height: 20px;
			content: '';
			background-color: $COLOR_DDD;
			top: 10px;
			right: 0;
			position: absolute;
		}
		div {
			display: flex;
			.checkbox {
				margin-left: auto;
				margin-right: 10px;
				&:before {
					background-color: $HOVER_BACKGROUND;
				}
			}
		}
	}
}
</style>
