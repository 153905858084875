import notificationAPI from '@/services/api/notification'

const notification = {
	namespaced: true,
	state: {
		isNotification: false,
		notificationList: [],
		count: 0,
		loadParams: {
			offset: 0,
			length: 10,
			unreadOnly: false,
		},
	},
	mutations: {
		setIsNotification: (state, payload) => {
			state.isNotification = payload
		},
		setNotificationList: (state, payload) => {
			state.notificationList = payload
		},
		setCount: (state, payload) => {
			state.count = payload
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
	},
	actions: {
		getNotificationList: async ({ state, commit }) => {
			const response = await notificationAPI.getNotificationList(state.loadParams)
			commit('setNotificationList', response.data.list)
			commit('setCount', response.data.count)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			await dispatch('getNotificationList')
		},
		notificationRead: async ({ dispatch }, body) => {
			await notificationAPI.notificationRead(body)
		},
		notificationReadAll: async ({ dispatch }) => {
			await notificationAPI.notificationReadAll()
			await dispatch('setLoadParams', { offset: 0, length: 10, unreadOnly: false })
		},
	},
}

export default notification
