<template>
	<div class="wrapper">
		<div class="mylist-header">
			<div>다른 연구원들이 <span>요청한 상품</span>들이에요</div>
		</div>
		<div class="list-wrapper">
			<div class="item" v-for="item in list" :key="item.id" @click="goOtherDetail(item.id)">
				<div class="image">
					<img v-if="item.product.images?.length > 0" :src="item.product.images[0]" />
					<img v-else src="@/assets/svg/no-image.png" />
				</div>
				<div class="content">
					<div class="name">{{ item.product.name }}</div>
					<div class="description">
						<span v-if="item.product.brand">브랜드: {{ item.product.brand?.name }}</span>
						<span v-if="item.product.code">제품번호: {{ item.product.code }}</span>
						<span v-if="item.product.casno">CAS No: {{ item.product.casno }}</span>
						<span v-if="item.product.options?.length > 0">사이즈(용량): {{ item.product.options.map(it => it.value).join(',') }}</span>
					</div>
					<div class="request-info">
						<div>
							<span>소속분류</span>
							<span>{{ item.instituteTempType }}</span>
						</div>
						<div>
							<span>소속</span>
							<span>{{ item.instituteName }}</span>
						</div>
						<div>
							<span>이름</span>
							<span>{{ item.userName }}</span>
						</div>
						<div>
							<span>요청번호</span>
							<span>{{ item.no }}</span>
						</div>
						<div>
							<span>요청일</span>
							<span>{{ dateToString(item.createDatetime) }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Pagniation :isPerPage="false" @page="loadData" :defaultLength="10" :total="count" />
	</div>
</template>
<script>
import PageName from '@/utils/define/PageName'
import MixnDate from '@/mixins/date'
import Pagniation from '@/components/pagenation/CommonPagenation.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
	mixins: [MixnDate],
	components: {
		Pagniation,
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('requestProduct/other', ['list', 'count']),
	},
	watch: {
		instituteId() {
			if (this.instituteId) {
				this.loadData({})
			}
		},
	},
	created() {
		if (this.instituteId) {
			this.loadData({})
		}
	},

	methods: {
		...mapActions('requestProduct/other', ['getOtherList']),
		loadData({ offset = 0, length = 10 }) {
			this.getOtherList({
				instituteId: this.instituteId,
				params: {
					offset,
					length,
				},
			})
		},
		goOtherDetail(id) {
			this.$router.push({
				name: PageName.Sadadream.OtherDetail,
				params: {
					id,
				},
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.wrapper {
	padding-top: 60px;
	padding-bottom: 60px;
	.mylist-header {
		height: 36px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;
		div {
			font-size: 20px;
			font-weight: 700;

			span {
				color: #ffb000;
			}
		}
	}
	.list-wrapper {
		.item {
			display: flex;
			gap: 20px;
			align-items: center;
			width: 100%;
			height: 140px;
			padding: 20px 0px;
			border-bottom: 1px solid #eee;
			&:hover {
				background-color: #f5f5f5;
				cursor: pointer;
			}
			.image {
				width: 80px;
				height: 80px;
				border: 1px solid #eee;
				border-radius: 4px;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
			.content {
				display: flex;
				gap: 10px;
				flex-direction: column;
				.name {
					width: 80%;
					@include ellipse2;
					font-size: 16px;
					font-weight: 600;
				}
				.description {
					width: 640px;
					display: inline-flex;
					flex-wrap: wrap;
					column-gap: 8px;
					font-size: 12px;
					color: #666;
					span {
						&::before {
							margin-right: 8px;
							vertical-align: middle;
							display: inline-block;
							content: '';
							width: 1px;
							height: 12px;
							background-color: #ddd;
						}
						&:first-child {
							&::before {
								display: none;
							}
						}
					}
				}
				.request-info {
					display: flex;
					gap: 16px;
					& > div {
						font-size: 12px;
						display: flex;
						span {
							&:first-child {
								color: #666;
								margin-right: 5px;
							}
							&:last-child {
								color: #333;
							}
						}
					}
				}
			}
		}
	}
}
</style>
