<template>
	<div class="startStore">
		<start-store-header title="랩매니저 스토어 시작하기" :profile="userInfo" :progressStep="progressStep" />
		<div class="content-wrapper">
			<start-icon :progressStep="progressStep" />
			<h2 class="title" v-html="stepTitle"></h2>
			<start-form
				:progressStep="progressStep"
				:startDefine="startDefine"
				:info="info"
				:valid="valid"
				:infoKey="startDefine.infoKey(progressStep)"
				:errorMessage="startDefine.errorMessage(progressStep, info.type)"
				:createdInstituteId="createdInstituteId"
				@change="getInfo"
			/>
			<div class="btn-wrap bottom">
				<button class="btn btn-complete" v-text="nextBtnText" @click="nextStep"></button>
				<button class="btn btn-white" v-if="progressStep > 1" @click="prevStep">이전</button>
				<button class="skip" v-if="progressStep === 4 && progressStep === 5" @click="$bvModal.show('modal-submit')">SKIP</button>
			</div>
		</div>

		<modal-submit
			id="modal-submit"
			title="주문 필수정보 건너뛰기"
			content="랩매니저 스토어에서 상품을 주문하기 위해<br>필수적으로 입력해야 하는 정보가 있습니다.<br>정말 이 단계를 건너뛰시겠습니까?"
			@ok="goMain"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import StartStoreHeader from '@/views/start/components/StartStoreHeader'
import StartIcon from '@/views/start/components/StartIcon'
import StartForm from '@/views/start/components/StartForm'
import PageName from '@/utils/define/PageName'
import startDefine from '@/utils/define/start'
import ModalSubmit from '@/components/modal/ModalSubmit'

export default {
	components: {
		StartStoreHeader,
		StartIcon,
		StartForm,
		ModalSubmit,
	},
	created() {
		this.loadUserInfo()
	},
	data() {
		return {
			progressStep: 1,
			userInfo: {},
			isAlreadyCreateInstitute: false,
			createdInstituteId: null,
			info: {
				type: '',
				name: '',
				managerName: '',
				certificationName: '',
				certificationFiles: [],
				shippingInfo: {
					shippingName: '',
					departmentName: '',
					address: '',
					addressDetail: '',
				},
			},
			valid: {
				type: true,
				name: true,
				managerName: true,
				certificationName: true,
				certificationFiles: true,
				shippingInfo: true,
			},
		}
	},
	computed: {
		...mapState('user', ['user']),
		nextBtnText() {
			if (this.progressStep == 6) {
				return '랩매니저 스토어 시작하기'
			} else {
				return '다음'
			}
		},
		startDefine() {
			return startDefine
		},
		stepTitle() {
			let title = ''

			title = this.startDefine.stepTitle(this.progressStep, this.info.type)
			if (this.progressStep == 6) {
				title = `<b class="yellow">${this.info.name}</b>` + title
			}
			return title
		},
	},
	methods: {
		...mapActions('user', ['getUserInfo']),
		...mapActions('institute', ['createInstitute', 'updateInstitute']),
		...mapActions('businessRegistration', ['addRegistration']),
		...mapActions('delivery', ['addAddress']),
		async loadUserInfo() {
			await this.getUserInfo()
			this.userInfo = this.$_.cloneDeep(this.user)
		},
		initInfo() {
			this.info = {
				type: '',
				name: '',
				managerName: '',
				certificationName: '',
				certificationFiles: [],
				shippingInfo: {
					shippingName: '',
					departmentName: '',
					address: '',
					addressDetail: '',
				},
			}
		},
		nextStep() {
			if (this.progressStep === 3) this.create()
			if (this.progressStep === 1 && this.info.type === 'PERSON') {
				this.create()
				this.progressStep = 5
			} else if (this.progressStep < 6) {
				if (!this.validation()) return false
				if (this.info.type === 'PERSON') this.goMain()
				else this.progressStep += 1
			} else if (this.progressStep === 6) this.goMain()
		},
		prevStep() {
			if (this.progressStep === 5 && this.info.type === 'PERSON') this.progressStep = 1
			else this.progressStep -= 1
		},
		async create() {
			try {
				if (!this.isAlreadyCreateInstitute) {
					const response = await this.createInstitute(this.info)
					this.createdInstituteId = response.data.instituteId
				} else {
					await this.updateInstitute({
						instituteId: this.createdInstituteId,
						body: {
							...this.info,
						},
					})
				}
				this.isAlreadyCreateInstitute = true
			} catch (error) {
				this.$root.toast('에러', error.response.data.msg, 'error')
				return false
			}
		},
		async goMain() {
			if (this.progressStep > 4 && this.info.type !== 'PERSON') {
				try {
					const formData = new FormData()
					formData.append('name', this.info.certificationName)
					this.info.certificationFiles.forEach((businessRegistration, index) => {
						formData.append(`businessRegistrations`, businessRegistration)
					})
					await this.addRegistration({
						instituteId: this.createdInstituteId,
						formData,
					})
				} catch (e) {
					this.$root.toast('사업자등록증 등록 실패', e.response.data.msg, 'error')
				}
			}
			if (this.progressStep === 6 || (this.progressStep === 5 && this.info.type === 'PERSON')) {
				try {
					await this.addAddress({
						instituteId: this.createdInstituteId,
						body: {
							name: '기본 배송지',
							instituteName: this.info.shippingInfo.shippingName,
							groupName: this.info.shippingInfo.departmentName,
							postcode: this.info.shippingInfo.postcode,
							address: this.info.shippingInfo.address,
							addressDetail: this.info.shippingInfo.addressDetail,
							isDefault: true,
						},
					})
				} catch (e) {
					this.$root.toast('배송지 등록 실패', e.response.data.msg, 'error')
				}
			}

			this.$router.push({
				name: PageName.Main,
				params: {
					isFromStart: true,
				},
			})
		},
		validation() {
			let validKeys = Object.keys(this.valid)
			let result = true
			let stepKey = this.startDefine.infoKey(this.progressStep)
			validKeys.forEach(key => {
				this.valid[key] = true
				if (stepKey && !this.info[stepKey]) {
					this.valid[stepKey] = false
					result = false
				} else if (this.progressStep == 4 && this.info.certificationFiles.length == 0) {
					//사업자 등록증 파일 업로드 확인
					this.valid.certificationFiles = false
					result = false
				} else if (this.progressStep == 5) {
					//배송지 정보 확인
					//소속 유형이 개인일 경우 [명]을 입력하지 않아도 됨
					let subKeys = Object.keys(this.info[key])
					subKeys.forEach(sub => {
						if (this.info.type == 'PERSON') {
							if (!this.info.shippingInfo.address) {
								this.valid.shippingInfo = false
								result = false
							}
						} else {
							if (sub != 'addressDetail' && !this.info[key][sub]) {
								this.valid.shippingInfo = false
								result = false
							}
						}
					})
				}
			})
			return result
		},
		getInfo(val, key) {
			if (key === 'type') this.initInfo()
			this.valid[key] = true
			if (key === 'shippingInfo') {
				this.info['shippingInfo'].postcode = val.postcode
				this.info['shippingInfo'].address = val.address
				this.info['shippingInfo'].addressDetail = val.addressDetail
			} else {
				this.info[key] = val
			}
		},
	},
}
</script>

<style lang="scss">
.startStore {
	.content-wrapper {
		width: 700px;
		height: calc(100vh - 128px);
		position: absolute;
		top: calc(50%);
		left: 50%;
		transform: translate(-50%, -50%);
		margin-top: 64px;
		margin-bottom: 0 !important;
		.title {
			margin: 1rem 0 40px;
			font-size: 2rem;
			font-weight: 400;
			b {
				font-weight: 700;
				display: inline-block;
				&.yellow {
					background: -webkit-linear-gradient(180deg, #fec741 0%, #ffa90b 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
		}
		.round-tab {
			margin-bottom: 30px;
		}
	}
	.btn-wrap {
		justify-content: flex-start;
		padding-top: 20px;
		.btn {
			width: 200px;
			height: 48px;
			font-size: 1rem;
			margin: 0 10px 0 0;
		}
		.skip {
			width: 60px;
			height: 24px;
			margin-left: auto;
			font-size: 1rem;
			font-weight: 700;
			color: $PRIMARY_BLUE;
			padding-right: 24px;
			background-image: url(~@/assets/svg/arrow/skip-arrow.svg);
			background-position: center right 0;
		}
	}
}
</style>
