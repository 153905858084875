<template>
	<b-modal
		id="ModalPaymentRequirement"
		class="modal"
		title="주문 필수 정보 등록"
		ok-title="확인"
		cancel-title="닫기"
		cancel-variant="default"
		ok-variant="primary"
		ref="modal"
		size="md"
		centered
		scrollable
		@ok="complete"
		@show="init"
		@close="hide"
		@cancel="hide"
	>
		<div class="modal-payment-requirement">
			<div class="content">
				랩매니저 스토어에서 판매하는 제품들이 연구목적으로 사용되는 것을 확인 <br />
				하기위해 회원님이 소속된 학교/기업등을 확인합니다
			</div>
			<div class="business">
				<div class="title" @click="active(ACTIVE_TYPE.BUSINESS)">
					<div>
						<img v-show="!hasBusinessRegistration && valid.business" src="@/assets/svg/payment/requirement/circle_num_1.svg" alt="" />
						<img
							v-show="!hasBusinessRegistration && !valid.business"
							src="@/assets/svg/payment/requirement/circle_num_1_red.svg"
							alt=""
						/>
						<img v-show="hasBusinessRegistration" src="@/assets/svg/payment/requirement/circle_num_1_green.svg" alt="" />
						<span :class="!hasBusinessRegistration && !valid.business ? 'invalid' : null" style="margin-left: 5px"
							>사업자등록증(결제처) 정보</span
						>
						<span class="required">*</span>
						<img v-show="hasBusinessRegistration" src="@/assets/svg/payment/requirement/check_green.svg" alt="" />
					</div>
					<div>
						<img v-show="isShowBusiness" src="@/assets/svg/arrow_up_b.svg" />
						<img v-show="!isShowBusiness" src="@/assets/svg/arrow_down_g.svg" />
					</div>
				</div>
				<payment-requirement-business
					ref="payment-requirement-business"
					v-show="isShowBusiness"
					class="payment-requirement-business"
					@next="next"
				></payment-requirement-business>
			</div>
			<div class="shipping">
				<div class="title" @click="active(ACTIVE_TYPE.SHIPPING)">
					<div>
						<img v-show="!hasDelivery && valid.shipping" src="@/assets/svg/payment/requirement/circle_num_2.svg" alt="" />
						<img v-show="!hasDelivery && !valid.shipping" src="@/assets/svg/payment/requirement/circle_num_2_red.svg" alt="" />
						<img v-show="hasDelivery" src="@/assets/svg/payment/requirement/circle_num_2_green.svg" alt="" />
						<span :class="!hasDelivery && !valid.shipping ? 'invalid' : null" style="margin-left: 5px">배송지 정보</span>
						<span class="required">*</span>
						<img v-show="hasDelivery" src="@/assets/svg/payment/requirement/check_green.svg" alt="" />
					</div>
					<div>
						<img v-show="isShowShipping" src="@/assets/svg/arrow_up_b.svg" />
						<img v-show="!isShowShipping" src="@/assets/svg/arrow_down_g.svg" />
					</div>
				</div>
				<payment-requirement-shipping
					ref="payment-requirement-shipping"
					v-show="isShowShipping"
					class="payment-requirement-shipping"
					@next="next"
				></payment-requirement-shipping>
			</div>
			<div class="authentication">
				<div class="title" @click="active(ACTIVE_TYPE.AUTHENTICATION)">
					<div>
						<img v-show="!isVerifiedUser && valid.authentication" src="@/assets/svg/payment/requirement/circle_num_3.svg" alt="" />
						<img v-show="!isVerifiedUser && !valid.authentication" src="@/assets/svg/payment/requirement/circle_num_3_red.svg" alt="" />
						<img v-show="isVerifiedUser" src="@/assets/svg/payment/requirement/circle_num_3_green.svg" alt="" />
						<span :class="!isVerifiedUser && !valid.authentication ? 'invalid' : null" style="margin-left: 5px">본인 인증 정보</span>
						<span class="required">*</span>
						<img v-show="isVerifiedUser" src="@/assets/svg/payment/requirement/check_green.svg" alt="" />
					</div>
					<div>
						<img v-show="isShowAuthentication" src="@/assets/svg/arrow_up_b.svg" />
						<img v-show="!isShowAuthentication" src="@/assets/svg/arrow_down_g.svg" />
					</div>
				</div>
				<payment-requirement-authentication
					ref="payment-requirement-authentication"
					v-show="isShowAuthentication"
					class="payment-requirement-authentication"
					@next="next"
				></payment-requirement-authentication>
			</div>
			<div class="footer">
				<ul>
					<li>시험용, 연구용, 감시용 시약은 해당용도로만 사용되어야합니다.</li>
					<li>취급 시 유해화학물질 취급기준을 준수 하여야합니다.</li>
				</ul>
			</div>
		</div>
	</b-modal>
</template>

<script>
import PaymentRequirementBusiness from '@/views/payment/components/requirement/PaymentRequirementBusiness.vue'
import PaymentRequirementShipping from '@/views/payment/components/requirement/PaymentRequirementShipping.vue'
import PaymentRequirementAuthentication from '@/views/payment/components/requirement/PaymentRequirementAuthentication.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'

const ACTIVE_TYPE = {
	BUSINESS: 'business',
	SHIPPING: 'shipping',
	AUTHENTICATION: 'authentication',
}

export default {
	name: 'ModalPaymentRequirement',
	components: {
		PaymentRequirementBusiness,
		PaymentRequirementShipping,
		PaymentRequirementAuthentication,
	},
	data() {
		return {
			ACTIVE_TYPE,
			activeItem: {
				type: '',
				isShow: false,
			},
			valid: {
				business: true,
				shipping: true,
				authentication: true,
			},
		}
	},
	computed: {
		...mapState('institute', ['requirement']),
		...mapGetters('user', ['instituteId']),
		isShowBusiness() {
			return this.activeItem.type === ACTIVE_TYPE.BUSINESS && this.activeItem.isShow === true
		},
		isShowShipping() {
			return this.activeItem.type === ACTIVE_TYPE.SHIPPING && this.activeItem.isShow === true
		},
		isShowAuthentication() {
			return this.activeItem.type === ACTIVE_TYPE.AUTHENTICATION && this.activeItem.isShow === true
		},
		hasBusinessRegistration() {
			return this.requirement.hasBusinessRegistration
		},
		hasDelivery() {
			return this.requirement.hasDelivery
		},
		isVerifiedUser() {
			return this.requirement.isVerifiedUser
		},
		validation() {
			return this.hasBusinessRegistration && this.hasDelivery && this.isVerifiedUser
		},
	},
	methods: {
		...mapActions('institute', ['lookupRequirement']),
		hide() {
			this.$router.replace({ hash: '' })
			this.$refs.modal.hide()
		},
		init() {
			this.activeItem = {
				type: '',
				isShow: false,
			}
			this.valid = {
				business: true,
				shipping: true,
				authentication: true,
			}

			this.loadRequirement()
		},
		async loadRequirement() {
			try {
				await this.lookupRequirement(this.instituteId)
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		active(type) {
			if (this.activeItem.type === type) {
				this.activeItem.isShow = !this.activeItem.isShow
			} else {
				this.activeItem.type = type
				this.activeItem.isShow = true
			}
		},
		async next() {
			await this.loadRequirement()
			this.activeItem.isShow = true
			if (!this.hasBusinessRegistration) {
				this.activeItem.type = ACTIVE_TYPE.BUSINESS
			} else if (!this.hasDelivery) {
				this.activeItem.type = ACTIVE_TYPE.SHIPPING
			} else if (!this.isVerifiedUser) {
				this.activeItem.type = ACTIVE_TYPE.AUTHENTICATION
			} else {
				this.complete()
			}
		},
		async complete(e) {
			if (e) e.preventDefault()
			this.valid.business = true
			this.valid.shipping = true
			this.valid.authentication = true

			if (!this.hasBusinessRegistration) {
				this.valid.business = false
				this.$refs['payment-requirement-business'].validate()
			}
			if (!this.hasDelivery) {
				this.valid.shipping = false
				this.$refs['payment-requirement-shipping'].validate()
			}
			if (!this.isVerifiedUser) {
				this.valid.authentication = false
				this.$refs['payment-requirement-authentication'].validate()
			}
			if (!this.validation) {
				this.next()
			} else {
				this.$root.toast('주문 필수 정보 인증 완료', '주문 필수 정보 인증이 완료되었습니다!', ToastType.SUCCESS)
				this.$emit('ok')
				await this.$nextTick()
				this.$refs['modal'].hide()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-payment-requirement {
	> div:not(:first-child) {
		border-top: 1px solid $COLOR_DDD;
		margin-top: 20px;
	}
	.title {
		font-weight: 500;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 20px 0;
		cursor: pointer;
		> div {
			display: flex;
			align-items: center;
			> span {
				&.invalid {
					color: $PRIMARY_RED;
				}
			}
			span + img {
				margin-left: 10px;
			}
		}
	}
	.content {
		height: 40px;
		font-size: 0.875rem;
		color: $COLOR_333;
	}
	.required {
		margin-left: 0;
	}
	.footer {
		height: 60px;
		display: flex;
		align-items: center;
		padding-top: 20px;
		padding-left: 20px;
		ul {
			li {
				list-style-type: disc;
				color: $COLOR_666;
				font-size: 0.875rem;
				font-weight: 500;
			}
		}
	}
}
</style>
<style lang="scss">
#ModalPaymentRequirement___BV_modal_outer_ {
	.modal-footer {
		justify-content: center;
		button {
			width: 200px;
			height: 48px;
		}
	}
}
</style>
