<template>
	<div class="productInfo-header-wrapper" :class="{ isSticky: isHeaderSticky }">
		<div class="productInfo-header-item" :class="{ active: isProductTab }" @click="goScroll('product-tab')">
			<div>상품 정보</div>
		</div>
		<div class="productInfo-header-item" :class="{ active: isDeliveryTab }" @click="goScroll('delivery-tab')">
			<div>배송 정보</div>
		</div>
		<div class="productInfo-header-item" :class="{ active: isReturnTab }" @click="goScroll('return-tab')">
			<div>취소/반품/교환 정보</div>
		</div>
		<div class="productInfo-header-item" :class="{ active: isCsTab }" @click="goScroll('cs-tab')">
			<div>고객상담 정보</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		activeProductInfoNav: {
			default: 'product-tab',
			type: String,
		},
		isHeaderSticky: {
			default: false,
			type: Boolean,
		},
	},
	computed: {
		isProductTab() {
			return this.activeProductInfoNav == 'product-tab'
		},
		isDeliveryTab() {
			return this.activeProductInfoNav == 'delivery-tab'
		},
		isReturnTab() {
			return this.activeProductInfoNav == 'return-tab'
		},
		isCsTab() {
			return this.activeProductInfoNav == 'cs-tab'
		},
	},
	methods: {
		goScroll(href) {
			this.$emit('goScroll', href)
		},
	},
}
</script>
<style lang="scss" scoped>
.productInfo-header-wrapper {
	display: flex;
	position: sticky;
	// top : 100px;
	margin-top: 50px;
	width: 100%;
	height: 48px;
	border-bottom: 1px solid #191414;
	z-index: 1;
	justify-content: center;
	&.isSticky {
		// width : 1080px;
		position: fixed;
		top: 100px;

		left: 0px;
	}
	.productInfo-header-item {
		display: flex;
		width: 270px;
		font-size: 16px;
		font-weight: 700;
		color: rgba(0, 0, 0, 0.6);
		background-color: #fafafc;
		align-items: center;
		cursor: pointer;
		div {
			width: 100%;
			height: 24px;
			text-align: center;
			border-right: 1px solid #ddd;
		}
		&:last-of-type > div {
			border-right: 0px;
		}
		&.active {
			background: #191914;
			color: white;
			& > div {
				border-right: 0px;
			}
		}
	}
}
</style>
