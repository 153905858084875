<template>
	<div class="return-history-list">
		<div class="title-wrapper">
			<span class="title">{{ title }}</span>
			<span class="sub-title">하단 상품목록에 없는 상품은 1:1문의 또는 고객센터(1533-3061)에 문의주세요</span>
		</div>
		<template v-if="count">
			<return-history :list="csList" :searchKeyword="searchKeyword" />
			<common-pagenation ref="CommonPagenation" :total="count" :isPerPage="false" @page="$emit('page', $event)"></common-pagenation>
		</template>
		<template v-else>
			<div class="empty-list">
				<img src="@/assets/svg/empty-order.svg" />
				<p>주문 내역이 없습니다</p>
			</div>
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import ReturnHistory from '@/views/my-page/order-delivery/return/components/ReturnHistory.vue'
import CommonPagenation from '@/components/pagenation/CommonPagenation.vue'

export default {
	name: 'ReturnHistoryList',
	components: {
		ReturnHistory,
		CommonPagenation,
	},
	props: {
		searchKeyword: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapState('cs', ['csList', 'count']),
		title() {
			return `취소 / 반품 / 교환 내역 (${this.count})`
		},
	},
	methods: {
		setPage(page) {
			if (!this.count) return

			this.$refs.CommonPagenation.changeLength(5)
			if (page > 1) {
				this.$nextTick(() => {
					for (let i = 1; i < page; i++) {
						this.$refs.CommonPagenation.nextPage()
					}
				})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.return-history-list {
	display: flex;
	flex-direction: column;
	gap: 24px;
	.title-wrapper {
		display: flex;
		align-items: center;
		gap: 16px;
		padding-bottom: 16px;
		border-bottom: 1px solid #d9d9d9;
		.title {
			font-weight: 700;
			font-size: 0.875rem;
		}
		.sub-title {
			font-weight: 500;
			font-size: 0.75rem;
		}
	}
	.empty-list {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-items: center;
		gap: 24px;
		margin-top: 80px;
		> p {
			font-size: 1rem;
			font-weight: 500;
			color: $COLOR_999;
		}
	}
}
</style>
