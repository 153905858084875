import { getToken, setToken, goToSSOMain } from '@/services/api/common'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import { isLogin } from '@/services/api/common'
import CartMixin from '@/mixins/cart'
import axiosInstance from '@/services/axios'
import PageName from '@/utils/define/PageName'
import cookies from 'vue-cookies'
import { gaEventSendPageView } from '@/utils/ga'
import { setPageNameInLocal } from '@/utils/pages'
import loggerAPI from '@/services/api/logger'

export default {
	mixins: [CartMixin],
	computed: {
		...mapGetters('user', ['instituteId', 'userInfo']),
		...mapState('user', ['user', { userInstitute: 'institute' }, 'institutes']),
		...mapState('institute', ['institute']),
		queryParams() {
			const queryParams = {}

			if (location.href.split('?').length >= 2) {
				location.href
					.split('?')[1]
					.split('&')
					.map(paramText => {
						paramText = paramText.split('=')
						queryParams[paramText[0]] = paramText[1]
					})
			}

			return queryParams
		},
		queryInviteCode() {
			if (location.href.indexOf('code') < 0) {
				return ''
			}

			return this.queryParams.code ? this.queryParams.code : ''
		},
		isRouteInvitation() {
			return location.href.indexOf('invitation') >= 0
		},
	},
	watch: {
		$route(to) {
			setPageNameInLocal(to)
			gaEventSendPageView(to)
		},
	},
	async created() {
		await this.checkSession()
	},
	methods: {
		...mapActions('user', ['getUserInfo', 'getInstitute', 'access']),
		...mapActions('institute', ['lookupInstitute']),
		...mapActions('cart', ['addCart']),
		...mapActions('member', ['acceptInvitation']),
		...mapMutations('notification', ['setIsNotification']),
		...mapMutations('user', ['setInstitute', 'setMyPageInfo']),
		async checkSession() {
			try {
				const token = getToken()

				try {
					loggerAPI.getIp().then(response => {
						cookies.set('currentIP', response.data.remote_addr)
					})
				} catch (e) {
					console.log(e)
				}
				// const loggerRemoteResponse = await loggerAPI.getIP()

				if (token) {
					axiosInstance.setAuth(token)
				} else {
					if (this.isRouteInvitation) {
						goToSSOMain(location.href)
						return
					} else {
						this.bootChannelTalk()
						throw 'NO_TOKEN'
					}
				}
				setToken(token)

				await this.access()
				await this.getUserInfo()
				if (this.userInfo.unreadNotificationCount > 0) {
					this.$root.darkToast(`읽지 않은 알림이 ${this.userInfo.unreadNotificationCount}건 있습니다.`, '확인하기', () => {
						this.setIsNotification(true)
					})
				}
				if (this.isRouteInvitation) {
					// 초대 수락한 후에 access API 호출 해야 됨
					try {
						await this.acceptInvitation({ body: { code: this.queryInviteCode } })
					} catch (e) {
						this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
					}
				}
				await this.getInstitute()

				const currentInstituteId = cookies.get('currentInstituteId') ?? cookies.get('currentInstitute2')?.id
				cookies.remove('currentInstituteId')
				const findInstitute = this.institutes.find(institute => institute.id === currentInstituteId)
				if (findInstitute) {
					cookies.set('currentInstitute2', JSON.stringify(findInstitute))
					this.setInstitute(findInstitute)
				} else {
					cookies.set('currentInstitute2', JSON.stringify(this.institutes[0]))
					this.setInstitute(this.institutes[0])
				}

				if (!this.instituteId)
					return this.$router
						.replace({
							name: PageName.StartStore,
						})
						.catch(() => {})
				await this.refreshCurrentInstitute()
				await this.registCart()
				this.bootChannelTalk()
				if (this.isRouteInvitation) {
					this.$router
						.replace({
							name: PageName.Main,
						})
						.catch(() => {})
				}
			} catch (e) {
				console.error(e)
			}
		},
		async registCart() {
			if (!this.isNonMemberCartProductList()) return
			try {
				await this.addCart({ instituteId: this.instituteId, body: { products: this.getNonMemberCartProductList() } })
				this.clearNonMemberCartProductList()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async fetchInstitute() {
			await this.lookupInstitute(this.instituteId)
		},
		async refreshCurrentInstitute() {
			if (!this.instituteId) return
			await this.fetchInstitute()
			this.setMyPageInfo({
				...this.institute,
				instituteName: this.institute?.name,
				name: this.user.name,
				image: this.user.image,
			})
		},
		bootChannelTalk() {
			if (!this.$_.isEmpty(this.user.id)) {
				ChannelIO('boot', {
					pluginKey: 'd7020dc0-62b8-421a-9982-44e791be9f41',
					profile: {
						userId: this.user?.id || null,
						name: this.user?.name,
						mobileNumber: this.user?.phone || null,
						email: this.user?.email || null,
						instituteId: this.userInstitute?.id || null,
						instituteName: this.userInstitute?.name || null,
						// departmentName: this.labInfo?.departmentName || null,
						// labName: this.labInfo?.name || null,
						// labId: this.labInfo?.id || null,
					},
				})
			} else {
				ChannelIO('boot', { pluginKey: 'd7020dc0-62b8-421a-9982-44e791be9f41' })
			}
		},
	},
}
