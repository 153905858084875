<template>
	<div class="cart-product-info">
		<div class="checkbox-wrapper">
			<span v-b-tooltip.hover.top :title="tooltipTitle" :disabled="!disabled">
				<input type="checkbox" v-model="checked" class="common" :disabled="disabled" />
			</span>
		</div>
		<div class="image">
			<img v-if="!isEmptyImages" :src="product.images[0]" />
			<img v-else-if="!isEmptyBrandImage" :src="product.brand.image" />
			<img v-else src="@/assets/svg/empty_product.svg" alt="empty" />
		</div>
		<div class="content">
			<div class="seller-name">
				{{ `[${product.seller.name}]` }}
			</div>
			<div class="name">
				{{ product.name }}
			</div>
			<div class="data">
				<span>{{ `카테고리: ${isData(product.categoryPathKorName)}` }}</span>
				<span>{{ `브랜드: ${isData(product.brand && product.brand.name ? product.brand.name : null)}` }}</span>
				<span>
					{{ `제품번호: ${isData(product.code)}` }}
				</span>
				<span>{{ `CAS No: ${isData(product.casno)}` }}</span>
			</div>
			<div class="options">
				<span v-for="(option, index) in product.options" :key="`option_${index}`">
					{{ `${option.name}: ${option.value}` }}
				</span>
			</div>
			<div class="info">
				<span class="price">
					<span class="selling-unit-price">{{ CommaNum(product.sellingUnitPrice) }}원</span>
					<span class="unit-price" v-if="!checkSamePrice(product)">{{ CommaNum(product.unitPrice) }}원</span>
				</span>
				<span class="stock" v-if="product.stock">
					<img src="@/assets/svg/stock-gray.svg" alt="stock" />
					재고 {{ product.stock }}
				</span>
				<span class="delivery">
					<img src="@/assets/svg/time-gray.svg" alt="time" />
					{{ product.shippingDayText }}
				</span>
			</div>
		</div>
		<div class="remove">
			<img src="@/assets/svg/cart/delete.svg" alt="" @click="onDelete" />
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'

const BLOCK_REASON = {
	PRODUCT_STOP: 'PRODUCT_STOP',
	PRODUCT_DELETE: 'PRODUCT_DELETE',
	SELLER_STOP: 'SELLER_STOP',
	PERSON_NOT_ALLOW: 'PERSON_NOT_ALLOW',
	INSTITUTE_BLOCK: 'INSTITUTE_BLOCK',
	SHAK_CANT_DELIVERY: 'SHAK_CANT_DELIVERY',
}

export default {
	name: 'CartProductInfo',
	props: {
		index: {
			type: Number,
			default: 0,
		},
		product: {
			type: Object,
			default: () => {},
		},
	},
	mixins: [mixin],
	data() {
		return {
			checked: true,
		}
	},
	watch: {
		checked() {
			this.$emit('changeChecked')
		},
	},
	computed: {
		isEmptyImages() {
			return this.$_.isEmpty(this.product.images)
		},
		isEmptyBrandImage() {
			return this.$_.isEmpty(this.product.brand.image)
		},
		disabled() {
			return this.product.isBlock
		},
		tooltipTitle() {
			let title = ''
			const { blockReason } = this.product
			switch (blockReason) {
				case BLOCK_REASON.PRODUCT_STOP:
				case BLOCK_REASON.PRODUCT_DELETE:
				case BLOCK_REASON.SELLER_STOP:
					title = '판매자에 의해 판매 중지된 상품입니다'
					break
				case BLOCK_REASON.PERSON_NOT_ALLOW:
					title = '유해화학물질 / 시약제품은 개인에게 판매하지 않습니다'
					break
				case BLOCK_REASON.SHAK_CANT_DELIVERY:
					title = '샥샥배송 가능한 지역이 아닙니다'
					break
			}

			return title
		},
	},
	mounted() {
		if (this.disabled) this.checked = false
	},
	methods: {
		onDelete() {
			this.$emit('onDelete', this.index)
		},
		check(isCheck) {
			if (this.disabled) return
			this.checked = isCheck
		},
		getCheck() {
			return this.checked
		},
		getProduct() {
			return this.product
		},
		getIndex() {
			return this.index
		},
		checkSamePrice({ sellingUnitPrice, unitPrice }) {
			return sellingUnitPrice === unitPrice
		},
	},
}
</script>

<style lang="scss" scoped>
.cart-product-info {
	display: flex;
	.checkbox-wrapper {
		display: flex;
		align-items: center;
		gap: 11px;
	}
	.image {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 20px;
		img {
			border: 1px solid #eee;
			border-radius: 4px;
			width: 80px;
			height: 80px;
			object-fit: contain;
		}
	}
	.content {
		margin-left: 16px;
		font-size: 0.75rem;
		line-height: 18px;
		color: $COLOR_666;
		width: calc(100% - 100px);
		.seller-name {
			color: $COLOR_111;
			font-weight: 500;
		}
		.name {
			height: 20px;
			max-width: 524px;
			font-size: 1rem;
			font-weight: 700;
			color: $COLOR_111;
			margin-bottom: 3px;
			@include ellipse;
		}
		.data,
		.options {
			font-size: 0.75rem;
			color: $COLOR_666;
			display: flex;
			align-items: center;
			@include ellipse;
			span + span::before {
				vertical-align: middle;
				margin-left: 6px;
				margin-right: 6px;
				display: inline-block;
				height: 12px;
				content: '';
				border-left: 1px solid #ddd;
			}
		}
		.info {
			font-size: 0.875rem;
			font-weight: 500;
			color: $COLOR_111;
			margin-top: 5px;
			@include ellipse;
			span {
				display: inline-flex;
				align-items: center;
				img {
					margin-right: 5px;
				}
			}
			& > span + span::before {
				vertical-align: middle;
				margin-left: 6px;
				margin-right: 6px;
				display: inline-block;
				height: 12px;
				content: '';
				border-left: 1px solid #ddd;
			}
			.price {
				.unit-price {
					text-decoration: line-through;
					color: $FONT_PLACEHOLDER;
					margin-left: 5px;
				}
			}
		}
	}
	.remove {
		display: grid;
		img {
			cursor: pointer;
		}
	}
}
</style>
