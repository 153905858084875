<template>
	<div class="select-item-with-btn-form">
		<div class="title" v-if="title" :class="{ hidden: !required }">
			<h6>{{ title }}</h6>
			<span class="required">*</span>
			<p class="subname" v-if="subname">{{ subname }}</p>
		</div>
		<div class="flex btn-form">
			<input type="text" readonly v-model="selectItem.name" :placeholder="placeholder" />
			<img v-if="selectItem.name" src="@/assets/svg/icon/icon-close.svg" @click="$emit('delete')" />
			<button class="btn btn-complete" v-if="btnText" @click="$emit('clickEv')">{{ btnText }}</button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '',
		},
		subname: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: '',
		},
		btnText: {
			type: String,
			default: '',
		},
		selectItem: {
			type: Object,
			default: () => ({}),
		},
	},
}
</script>

<style lang="scss" scoped>
.select-item-with-btn-form {
	width: 100%;
	.title {
		display: inline-flex;
		align-items: center;
		margin-bottom: 1rem;
		&.hidden {
			.required {
				visibility: hidden;
			}
		}
		h6 {
			font-size: 1rem;
			font-weight: 700;
		}
		p {
			margin-left: 18px;
			font-size: 0.875rem;
			font-weight: 500;
			color: $COLOR_999;
		}
	}
	.btn-form {
		display: flex;
		align-items: center;
		input[type='text'] {
			width: 100%;
			height: 40px;
			border: 1px solid $LINE_DIVIDER;
			border-radius: 5px;
			padding: 0 8px;
			font-size: 0.875rem;
		}
		img {
			margin-left: -40px;
			padding-right: 20px;
			cursor: pointer;
		}
		button {
			white-space: nowrap;
			margin-left: 8px;
			height: 40px;
			min-width: 120px;
		}
	}
}
</style>
