<template>
	<div class="search-title">
		<span class="title">{{ title }}</span>
		<span class="sub-title">{{ subTitle }}</span>
	</div>
</template>

<script>
export default {
	name: 'SearchTitle',
	props: {
		title: {
			type: String,
			default: '',
		},
		subTitle: {
			type: String,
			default: '',
		},
	},
}
</script>

<style lang="scss" scoped>
.search-title {
	display: flex;
	align-items: center;
	gap: 10px;
	.title {
		font-weight: 700;
		font-size: 1rem;
	}
	.sub-title {
		font-weight: 500;
		font-size: 0.875rem;
		color: $COLOR_333;
	}
}
</style>
