<template>
	<div class="common-date-picker">
		<p v-if="name">{{ name }}:</p>
		<date-picker
			v-model="date"
			valueType="format"
			:range="isRange"
			:lang="lang"
			:placeholder="datePlaceholder"
			:clearable="false"
			:ref="`date-picker_${index}`"
			:editable="false"
			:style="datePickerStyle"
		></date-picker>
	</div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default {
	name: 'jwDatePicker',
	props: {
		name: {
			type: String,
			default: '',
		},
		isRange: {
			type: Boolean,
			default: true,
		},
		datePlaceholder: {
			type: String,
			default: '',
		},
		filtered: {
			type: Array,
			default: () => [],
		},
		index: {
			type: Number,
			default: 0,
		},
		//필터 내 삭제된 기간 아이템
		deleteDateItem: {
			type: Array,
			default: () => [],
		},
		datePickerStyle: {
			type: Object,
			default: () => ({}),
		},
	},
	components: {
		DatePicker,
	},
	data() {
		return {
			date: [],
			lang: {
				formatLocale: {
					// MMMM
					months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', ' 12월'],
					// MMM
					monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', ' 12월'],
					// dddd
					weekdays: ['일', '월', '화', '수', '목', '금', '토'],
					// ddd
					weekdaysShort: ['일', '월', '화', '수', '목', '금', '토'],
					// dd
					weekdaysMin: ['일', '월', '화', '수', '목', '금', '토'],
				},
				yearFormat: 'YYYY년',
				monthBeforeYear: false,
			},
		}
	},
	computed: {},
	watch: {
		date(val) {
			if (val.length > 0) {
				this.$emit('changeDate', this.date, this.name)
			}
		},
		deleteDateItem(val) {
			//필터 내 기간 필터 리스트가 삭제될 떄, inputValue 도 reset
			if (val == this.date) {
				this.date = []
			}
		},
		filtered(val) {
			if (val.length == 0) {
				this.date = []
			}
		},
	},
	methods: {
		resetDate() {
			this.filtered.forEach(item => {
				if (item.name != this.name) {
					this.$refs[`date-picker_${this.index}`].clear()
				}
			})
		},
		reset() {
			this.date = []
		},
		changeDate(date) {
			this.date = date
		},
	},
}
</script>

<style lang="scss">
.common-date-picker {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	.title {
		font-weight: 400;
		margin-right: 8px;
	}
	.mx-datepicker-range {
		height: 30px;
		.mx-input-wrapper {
			height: inherit;
		}
		.mx-input {
			height: inherit;
			border: 1px solid $LINE_DIVIDER;
			border-radius: 4px;
			box-shadow: unset;
		}
		.mx-icon-calendar {
			width: 30px;
			height: 28px;
			background-color: $COMMON_BACKGROUND;
			right: 0;
			border-radius: 0 4px 4px 0;
			background-image: url(~@/assets/svg/icon/icon-calendar.svg);
			svg {
				display: none;
			}
		}
	}
	.content {
		margin-left: 20px;
		font-weight: 500;
	}
}
</style>
