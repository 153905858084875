<template>
	<div class="Toast__list" :ref="`toast-wrapper`">
		<div v-for="data in getMessages" :key="`toast-${data.toastId}`">
			<toast
				v-if="data.toastType === 'normal'"
				:title="data.title"
				:content="data.content"
				:toastId="String(data.toastId)"
				:type="data.type"
			></toast>
			<dark-toast
				v-else
				:toastId="String(data.toastId)"
				:content="data.content"
				:clickText="data.clickText"
				:clickFunc="data.clickFunc"
			></dark-toast>
		</div>
	</div>
</template>

<script>
import Toast from '@/components/toast/Toast.vue'
import DarkToast from '@/components/toast/DarkToast.vue'
import { mapGetters } from 'vuex'
export default {
	components: {
		Toast,
		DarkToast,
	},
	data() {
		return {
			dataList: null,
		}
	},
	computed: {
		...mapGetters('toast', ['getMessages']),
	},
}
</script>

<style lang="scss" scoped>
.Toast__list {
	position: fixed;
	top: 50px;
	right: 10px;
	z-index: 999999 !important;
}
</style>
