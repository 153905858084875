<template>
	<div class="payment-inicis">
		<form id="payment_inicis_form_id" name="" method="POST" accept-chartset="UTF-8">
			<input name="version" value="1.0" />
			<input name="mid" :value="formData.mid" />
			<input ng-model="oid" name="oid" :value="formData.oid" />
			<input name="goodname" :value="formData.goodname" />
			<input ng-model="price" name="price" :value="formData.price" />
			<input name="currency" :value="formData.currency" />
			<input name="buyername" :value="formData.buyername" />
			<input name="buyertel" :value="formData.buyertel" />
			<input name="buyeremail" :value="formData.buyeremail" />
			<input type="text" name="timestamp" :value="formData.timestamp" />
			<input type="hidden" name="signature" :value="formData.signature" />
			<input name="returnUrl" :value="formData.returnUrl" />
			<input type="hidden" name="mKey" :value="formData.mKey" />
			<input type="hidden" name="gopaymethod" :value="`Card`" />
			<input type="hidden" name="payViewType" :value="`popup`" />
			<input type="hidden" name="popupUrl" :value="formData.popupUrl" />
		</form>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'PaymentInicis',
	data() {
		return {
			formData: {
				mid: '',
				goodname: '',
				oid: '',
				price: 0,
				currency: '',
				buyername: '',
				buyertel: '',
				buyeremail: '',
				timestamp: '',
				signature: '',
				returnUrl: `${location.protocol}//${location.host}/payment/inicis/return`,
				mKey: '',
				closeUrl: `${location.protocol}//${location.host}/payment/inicis/close`,
				popupUrl: `${location.protocol}//${location.host}/payment/inicis/popup`,
			},
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
	},
	methods: {
		...mapActions('order', ['lookupInicisCard']),
		async pay(paymentInfo) {
			try {
				const data = await this.lookupInicisCard({ instituteId: this.instituteId, body: paymentInfo })
					this.formData = { ...this.formData, ...data }
					INIStdPay.pay('payment_inicis_form_id')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-inicis {
	visibility: hidden;
}
</style>
