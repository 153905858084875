<template>
	<div class="payment-complete-orderer-info">
		<div class="info-item">
			<div class="info-title">
				<div>주문자 정보</div>
			</div>
			<div class="info-content">
				<div>
					<div class="title">소속</div>
					<div>{{ isData(order.instituteName) }}</div>
				</div>
				<div>
					<div class="title">이름</div>
					<div>{{ order.orderer ? isData(order.orderer.name) : '-' }}</div>
				</div>
				<div>
					<div class="title">이메일</div>
					<div>{{ order.orderer ? isData(order.orderer.email) : '-' }}</div>
				</div>
				<div>
					<div class="title">연락처</div>
					<div>{{ order.orderer ? isData(convertTel(order.orderer.phone)) : '-' }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import mixin from '@/mixins'

export default {
	name: 'PaymentCompleteOrdererInfo',
	mixins: [mixin],
	computed: {
		...mapState('order', ['order']),
	},
	methods: {
		convertTel(value) {
			if (!value) return
			return value.replace(/\D+/g, '').replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3')
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-complete-orderer-info {
	display: flex;
	flex-direction: column;
	.info-item {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.info-title {
			div {
				font-weight: bold;
				font-size: 1.25rem;
			}
		}
		.info-content {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 20px;
			border: 1px solid $COLOR_DDD;
			border-radius: 26px;
			> div {
				display: flex;
				> div {
					font-size: 0.875rem;
					font-weight: 500;
				}
				.title {
					width: 124px;
				}
			}
		}
	}
}
</style>
