<template>
	<base-management title="소속정보 관리">
		<div class="lab-info-management-content">
			<lab-info />
			<member-management />
		</div>
	</base-management>
</template>

<script>
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import LabInfo from '@/views/my-page/my-info/lab/LabInfo.vue'
import MemberManagement from '@/views/my-page/my-info/lab/MemberManagement.vue'

export default {
	name: 'LabInfoManagement',
	components: {
		BaseManagement,
		LabInfo,
		MemberManagement,
	},
}
</script>

<style lang="scss" scoped>
.lab-info-management-content {
	display: flex;
	flex-direction: column;
	gap: 50px;
	margin-bottom: 64px;
}
</style>
