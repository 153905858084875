<template>
	<div class="order-history-list">
		<div class="title">{{ title }}</div>
		<template v-if="orderItemCount">
			<order-history :list="orders" :searchKeyword="searchKeyword" @openModal="openModal" />
			<common-pagenation
				v-show="orderCount"
				ref="CommonPagenation"
				:total="orderCount"
				:isPerPage="false"
				@page="$emit('page', $event)"
			></common-pagenation
		></template>
		<template v-else>
			<div class="empty-list">
				<img src="@/assets/svg/empty-order.svg" />
				<p>주문 내역이 없습니다</p>
			</div>
		</template>

		<modal-order-cancel :title="modalTitle" :content="modalContent" :itemId="orderItemId" @complete="$emit('complete')" />
		<modal-order-return :orderItem="orderItem" />
		<modal-shipping-tracking :orderItem="orderItem" :orderer="orderer" :seller="seller" :address="address" :type="type" />
		<modal-seller :seller="seller" />
		<modal-info :title="modalInfoTitle" :rows="modalInfoRows" />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import SearchTitle from '@/views/my-page/order-delivery/order/components/search/SearchTitle.vue'
import OrderHistory from '@/views/my-page/order-delivery/order/components/OrderHistory.vue'
import CommonPagenation from '@/components/pagenation/CommonPagenation.vue'
import ModalOrderCancel from '@/components/modal/ModalOrderCancel.vue'
import ModalOrderReturn from '@/components/modal/ModalOrderReturn.vue'
import ModalShippingTracking from '@/components/modal/ModalShippingTracking.vue'
import ModalSeller from '@/views/seller/modal/ModalSeller.vue'
import ModalInfo from '@/components/modal/ModalInfo.vue'

export default {
	name: 'OrderHistoryList',
	components: {
		SearchTitle,
		OrderHistory,
		CommonPagenation,
		ModalOrderCancel,
		ModalOrderReturn,
		ModalShippingTracking,
		ModalSeller,
		ModalInfo,
	},
	props: {
		searchKeyword: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			orderItemId: 0,
			modalTitle: '',
			modalContent: '',
			seller: {},
			modalInfoTitle: '',
			modalInfoRows: [],
			orderItem: {},
			orderer: {},
			address: '',
			type: '',
		}
	},
	computed: {
		...mapState('order', ['orders', 'orderCount', 'orderItemCount']),
		title() {
			return `주문배송 내역 (${this.orderItemCount})`
		},
	},
	methods: {
		setPage(page) {
			if (!this.orderItemCount) return

			this.$refs.CommonPagenation.changeLength(5)
			if (page > 1) {
				this.$nextTick(() => {
					for (let i = 1; i < page; i++) {
						this.$refs.CommonPagenation.nextPage()
					}
				})
			}
		},
		openModal(data) {
			if (data.title) this.modalTitle = data.title
			if (data.content) this.modalContent = data.content
			if (data.orderItemId) this.orderItemId = data.orderItemId
			if (data.seller) this.seller = data.seller
			if (data.modalInfoTitle) this.modalInfoTitle = data.modalInfoTitle
			if (data.modalInfoRows) this.modalInfoRows = data.modalInfoRows
			if (data.orderItem) this.orderItem = data.orderItem
			if (data.orderer) this.orderer = data.orderer
			if (data.address) this.address = data.address
			if (data.type) this.type = data.type

			this.$bvModal.show(data.modalName)
		},
	},
}
</script>

<style lang="scss" scoped>
.order-history-list {
	display: flex;
	flex-direction: column;
	gap: 24px;
	.title {
		font-weight: 700;
		font-size: 0.875rem;
		padding-bottom: 16px;
		border-bottom: 1px solid #d9d9d9;
	}
	.empty-list {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-items: center;
		gap: 24px;
		margin-top: 80px;
		> p {
			font-size: 1rem;
			font-weight: 500;
			color: $COLOR_999;
		}
	}
}
</style>
