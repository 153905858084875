import requestAPI from '@/services/api/request-product'
import other from './other'
const requestProducts = {
	namespaced: true,
	state: {
		requestProducts: [],
		count: 0,
		requestProduct: {},
	},
	mutations: {
		setRequestProducts: (state, payload) => {
			state.requestProducts = payload
		},
		setCount: (state, payload) => {
			state.count = payload
		},
		setRequestProduct: (state, payload) => {
			state.requestProduct = payload
		},
	},
	actions: {
		lookupRequestProductList: async ({ commit }, { instituteId, params }) => {
			const response = await requestAPI.lookupRequestProductList(instituteId, params)
			commit('setRequestProducts', response.data.list)
			commit('setCount', response.data.count)
		},
		lookupRequestProduct: async ({ commit }, { instituteId, id }) => {
			const response = await requestAPI.lookupRequestProduct(instituteId, id)
			commit('setRequestProduct', response.data)
		},
		addRequestProduct: async (_, { instituteId, formData }) => {
			return await requestAPI.addRequestProduct(instituteId, formData)
		},
		printEstimate: async (_, { instituteId, requestId }) => {
			return await requestAPI.printEstimate(instituteId, { id: requestId })
		},
	},
	modules: { other },
}

export default requestProducts
