import productAPI from '@/services/api/productinfo'
import { BIconChevronCompactLeft } from 'bootstrap-vue'

const productInfo = {
	namespaced: true,
	state: {
		productInfo: {
			category: {},
			brand: {},
			seller: {},
			images: [],
			products: [],
			msds: {},
		},
		recentlyProductInfoList: [],
		frequentlyProductInfoList: [],
	},
	getters: {
		category: state => state.productInfo.category,
		brand: state => state.productInfo.brand,
		seller: state => state.productInfo.seller,
		products: state => state.productInfo.products,
		thumbnails: state =>
			state.productInfo.images.length > 0 ? state.productInfo.images.map(image => image.url) : [require('@/assets/svg/empty_product.svg')],
		minPrice: state =>
			state.productInfo.products.reduce((acc, cur, index) => {
				if (index === 0 || acc > cur.unitPrice) return cur.unitPrice
				return acc
			}, 0),
		shippingStatus: state => {
			if (state.productInfo.shippingDay === 0) return '당일출고'
			else if (state.productInfo.shippingDay) return `약 ${state.productInfo.shippingDay}일 이내 출고예정`
			else return '판매자 문의필요'
		},
		shippingStatusHTML: state => {
			if (state.productInfo.shippingDay === 0) return '<b>당일출고</b>'
			else if (state.productInfo.shippingDay) return `약 <b>${state.productInfo.shippingDay}일 이내</b> 출고예정`
			else return '판매자 문의필요'
		},
		brandMsds: state => {
			return state.msds ? state.msds.brand : null
		},
		sellerMsds: state => {
			return state.msds ? state.msds.seller : null
		},
	},
	mutations: {
		setProductInfo: (state, payload) => {
			state.productInfo = payload
		},
		setIsWish: (state, bool) => {
			state.productInfo.seller.isWish = bool
		},
		setRecentlyProductInfoList: (state, payload) => {
			state.recentlyProductInfoList = payload
		},
		setFrequentlyProductInfoList: (state, payload) => {
			state.frequentlyProductInfoList = payload
		},
	},
	actions: {
		lookupProductInfo: async ({ commit }, paramsObject) => {
			const response = await productAPI.lookupProductInfo(paramsObject)
			commit('setProductInfo', response.data)
		},
		lookupRecentlyProductInfoList: async ({ commit }, { paramsObject }) => {
			const response = await productAPI.lookupRecentlyProductInfoList(paramsObject)
			commit('setRecentlyProductInfoList', response.data.list)
		},
		lookupFrequentlyProductInfoList: async ({ commit }, { instituteId, paramsObject }) => {
			const response = await productAPI.lookupFrequentlyProductInfoList(instituteId, paramsObject)
			commit('setFrequentlyProductInfoList', response.data.list)
		},
	},
}

export default productInfo
