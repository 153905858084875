<template>
	<b-modal ref="modal" size="lg" centered hide-footer hide-header-close hide-header :style="'z-index: 1100'">
		<div class="modal-wrapper">
			<div class="close-wrap">
				<div class="check-wrapper" v-if="!isHideCheckBox">
					<input id="noMsg" class="checkbox" type="checkbox" v-model="checked" />
					<label for="noMsg">더 이상 메시지를 표시하지 않음</label>
				</div>
				<img class="close" src="@/assets/svg/main/icon-close-black.svg" @click="hide" />
			</div>
			<div>
				<swiper :options="swiperOption" class="slide-wrapper">
					<swiper-slide class="slide-box">
						<div class="text-wrap">
							<div class="title">
								<span>랩매니저 PRO 장점 ①</span>
								<h4><b>물품관리</b>: AI 기반 자동등록</h4>
							</div>
							<p class="content">
								국내 최초 특허로 출원한 '화학약품 인식 인공지능' 기술을 통해 시약 라벨을 스마트폰으로 촬영하면 시약의 정보가 랩매니저
								PRO에 자동으로 등록됩니다.
							</p>
							<div class="button-wrap">
								<button class="btn btn-primary" @click="goInventory">물품관리 시작하기</button>
								<button class="btn btn-white" @click="goDetailIntro">상세보기</button>
							</div>
						</div>
						<img src="@/assets/images/swiper/swiper-pro.png" />
					</swiper-slide>
					<swiper-slide class="slide-box">
						<div class="text-wrap">
							<div class="title">
								<span>랩매니저 PRO 장점 ②</span>
								<h4><b>안전관리</b>: 약 81만종 MSDS 자동 제공</h4>
							</div>
							<p class="content">
								시약을 등록하기만 하면 해당 시약의 MSDS가 자동으로 연동됩니다. 주기적으로 업데이트 되므로 가장 최신화된 자료로 확인할
								수 있으며 언제 어디서나 열람하고 출력할 수 있습니다.
							</p>
							<div class="button-wrap">
								<button class="btn btn-primary" @click="goMsds">안전관리 시작하기</button>
								<button class="btn btn-white" @click="goDetailIntro">상세보기</button>
							</div>
						</div>
						<img src="@/assets/images/swiper/swiper-msds.png" />
					</swiper-slide>
					<swiper-slide class="slide-box">
						<div class="text-wrap">
							<div class="title">
								<span>랩매니저 PRO 장점 ③</span>
								<h4><b>추가할인</b>: 시약 최대 20% 할인</h4>
							</div>
							<p class="content">
								인기 브랜드의 시약과 연구물품을 최대 20% 할인받아 구매할 수 있으며, 구매한 상품은 몇 번의 클릭만으로 랩매니저 PRO에
								쉽게 등록할 수 있습니다.
							</p>
							<div class="button-wrap">
								<button class="btn btn-primary" @click="goOrder">추가할인 받기</button>
								<button class="btn btn-white" @click="goDetailIntro">상세보기</button>
							</div>
						</div>
						<img src="@/assets/images/swiper/swiper-buy.png" />
					</swiper-slide>
					<swiper-slide class="slide-box">
						<div class="text-wrap">
							<div class="title">
								<span>랩매니저 PRO 장점 ④</span>
								<h4><b>자동입고</b>: 구매 후 자동입고 등록</h4>
							</div>
							<p class="content">
								랩매니저에서 구매한 물품은 랩매니저 PRO에 자동으로 입고되어 간단한 등록 후 물품목록에 추가할 수 있습니다. 다른
								구매처에서 구매했더라도 정보 입력 후 물품 등록을 할 수 있습니다.
							</p>
							<div class="button-wrap">
								<button class="btn btn-primary" @click="goCargo">자동입고 확인하기</button>
								<button class="btn btn-white" @click="goDetailIntro">상세보기</button>
							</div>
						</div>
						<img src="@/assets/images/swiper/swiper-cargo.png" />
					</swiper-slide>
					<div class="swiper-pagination" slot="pagination" />
					<div class="prev" slot="button-prev"><img src="@/assets/svg/swiper-left-btn2.svg" /></div>
					<div class="next" slot="button-next"><img src="@/assets/svg/swiper-right-btn2.svg" /></div>
				</swiper>
			</div>
			<!-- <div class="footer">
				<div class="button-wrapper">
					<button class="btn btn-primary" @click="goPro()">랩매니저 PRO 이동하기</button>
					<button class="btn btn-gray" @click="goDetailIntro()">랩매니저 PRO 상세설명 보기</button>
				</div>
			</div> -->
		</div>
	</b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import cookies from 'vue-cookies'
import { isLogin, getSYSUrl } from '@/services/api/common'
export default {
	name: 'ModalIntroPro',
	components: {},
	props: {
		detailId: {
			type: Number,
			default: null,
		},
		initPage: {
			type: Number,
			default: 0,
		},
		isHideCheckBox: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			swiperOption: {
				observer: true,
				observeParents: true,
				slidesPerView: 1,
				centeredSlides: true,
				spaceBetween: 10,
				navigation: {
					nextEl: '.next',
					prevEl: '.prev',
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				initialSlide: this.initPage,
			},
			checked: false,
		}
	},
	computed: {
		...mapState('institute', ['institute']),
	},
	watch: {
		checked() {
			if (this.checked) cookies.set('introProModal_hide', this.checked)
			else cookies.remove('introProModal_hide')
		},
	},
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		goDetailIntro() {
			location.href = 'https://store.lab-manager.com/cscenter/event/15#scroll'
		},
		goInventory() {
			let url = getSYSUrl()
			if (isLogin() && this.institute && this.institute.proInstituteId) {
				url += `/${this.institute.proInstituteId}/inventory/list?isMenuOpen=true`
			}
			location.href = url
		},
		goMsds() {
			let url = getSYSUrl()
			if (isLogin() && this.institute && this.institute.proInstituteId) {
				url += `/${this.institute.proInstituteId}/safety/msds?isMenuOpen=true`
			}
			location.href = url
		},
		goOrder() {
			let url = getSYSUrl()
			if (isLogin() && this.institute && this.institute.proInstituteId) {
				url += `/${this.institute.proInstituteId}/product/order?isMenuOpen=true`
			}
			location.href = url
		},
		goCargo() {
			let url = getSYSUrl()
			if (isLogin() && this.institute && this.institute.proInstituteId) {
				const gubun = this.detailId ? `/${this.detailId}?isMenuOpen=true` : `?isStore=true&isMenuOpen=true`
				url += `/${this.institute.proInstituteId}/purchase/cargo${gubun}`
			}
			location.href = url
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .modal-lg {
	width: 900px !important;
	max-width: 900px !important;
}
::v-deep .modal-header {
	padding: 30px;
	h5 {
		font-size: 24px;
		margin: auto;
	}
}
::v-deep .modal-body {
	padding: 30px;
	position: initial;
}

::v-deep .modal-content {
	background: #fef9ec;
	border-radius: 24px;
}

::v-deep .swiper-pagination-bullets {
	bottom: -5px !important;
}
::v-deep .swiper-pagination-bullet {
	opacity: 1;
	width: 10px;
	height: 10px;
}
::v-deep .swiper-pagination-bullet {
	background: #eaeaea !important;
}
::v-deep .swiper-pagination-bullet-active {
	background: #000 !important;
}

.modal-wrapper {
	margin-top: -55px;
	/* .header-subtext {
		text-align: center;
	} */
	.close-wrap {
		position: absolute;
		top: 20px;
		right: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;
		.check-wrapper {
			display: inline-flex;
			height: 20px;
			align-items: center;
			label {
				cursor: pointer;
				font-size: 14px;
				color: #111;
			}
		}
		.close {
			cursor: pointer;
		}
	}

	.slide-wrapper {
		margin-top: 100px;
		.slide-box {
			padding: 35px 50px 80px;
			/* width: auto; */
			justify-content: center;
			overflow: hidden;
			cursor: pointer;
			display: flex;
			gap: 20px;
			img {
				width: 50%;
				height: 100%;
			}
			.text-wrap {
				margin-top: 20px;
				margin-right: 20px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				.title {
					& > span {
						background: #333;
						color: #fff;
						border-radius: 30px;
						padding: 3.5px 12px;
						font-size: 14px;
						font-weight: 500;
					}
					& > h4 {
						font-size: 22px;
						font-weight: 600;
						letter-spacing: -0.32px;
						line-height: 26px;
						margin-top: 5px;
					}
				}
				.content {
				}
				.button-wrap {
					display: flex;
					gap: 10px;
					align-items: center;
					& > button {
						border-radius: 10px;
						width: 140px;
						height: 36px;
						font-size: 14px;
					}
				}
			}
		}
		.prev,
		.next {
			cursor: pointer;
			position: absolute;
			height: 60px;
			width: 36px;
			top: 50%;
			transform: translateY(-50%);
			z-index: 5;
		}
		.prev {
			&:hover {
				filter: brightness(90%);
			}
		}
		.next {
			&:hover {
				filter: brightness(90%);
			}
		}
		.next {
			right: 0;
		}
	}
	/* .footer {
		display: flex;
		flex-direction: column;
		gap: 32px;
		align-items: center;
		justify-content: center;
		margin-top: 45px;
		.button-wrapper {
			display: flex;
			gap: 20px;
			button {
				width: 280px;
				height: 48px;
			}
		}
	} */
}
</style>
