export const setPageNameInLocal = route => {
	const routeNanme = findPageNames(route)
	setPreviousPageNameInLocal(localStorage.getItem('LMS_PAGENAME') || routeNanme)
	localStorage.setItem('LMS_PAGENAME', routeNanme)
}
export const getPageNameInLocal = () => {
	return localStorage.getItem('LMS_PAGENAME')
}
export const setPreviousPageNameInLocal = name => {
	localStorage.setItem('LMS_PREVIOUS_PAGENAME', name)
}
export const getPreviousPageNameInLocal = () => {
	return localStorage.getItem('LMS_PREVIOUS_PAGENAME')
}

export const findPageNames = route => {
	switch (route.name) {
		case 'main':
			return pageNames.HOME
		case 'Search':
			return pageNames.SEARCH_LIST
		case 'Detail':
			return pageNames.PRODUCT_DETAIL
		case 'Payment':
			return pageNames.ORDER
		case 'PaymentComplete':
			return pageNames.ORDER_RESULT
		case 'Cart':
			return pageNames.CART
		case 'Order':
			return pageNames.ORDER_LIST
		case 'Return':
			return pageNames.ORDER_CANCEL_LIST
		case 'UnPaymentManagement':
			return pageNames.PAYMENT_NEED_LIST
		case 'UnPaymentManagementDetail':
			return pageNames.PAYMENT
		case 'CompletePayment':
			return pageNames.PAYMENT_RESULT
		case 'PaymentHistory':
			return pageNames.PAYMENT_LIST
		case 'EstimateManagement':
			return pageNames.ESTIMATE_LIST
		case 'RequestProductList':
			return pageNames.REQUEST_LIST
		case 'ShippingAddressManagement':
			return pageNames.DELIVERY_LIST
		case 'BusinessRegistrationManagement':
			return pageNames.EBILL_LIST
		case 'EasyPaymentManagement':
			return pageNames.CARD_LIST
		case 'UserInfo':
			return pageNames.USER_INFO
		case 'Inquiry':
			return pageNames.INQUIRY
		case 'QuestionManagement':
			return pageNames.FAQ
		case 'NoticeManagement':
			return pageNames.NOTICE
		case 'EventManagement':
			return pageNames.EVENT
		case 'Wish':
			return pageNames.WISH_LIST
		case 'RecentView':
			return pageNames.RECENT_VIEW_LIST
		case 'LabInfoManagement':
			return pageNames.INST_INFO
		case 'FrequentlyOrderList':
			return pageNames.FREQ_LIST
		case 'List':
			let params = route.params
			if (params?.category == 'brand') {
				return pageNames.BRAND_LIST
			} else if (params?.category == 'seller') {
				return pageNames.SELLER_LIST
			} else {
				return ''
			}
		// case "Repurchase" :
		//     return pageNames.REPURCHASE_LIST;
		// case "QuickDeliverySearch" :
		//     return pageNames.QUICK_LIST;
		default:
			return route.name
	}
}

export const pageNames = {
	HOME: '홈',
	SEARCH_LIST: '검색결과',
	PRODUCT_DETAIL: '상품상세',
	ORDER: '주문하기',
	ORDER_RESULT: '주문완료',
	CART: '장바구니',
	ORDER_LIST: '주문배송내역',
	ORDER_CANCEL_LIST: '주문/배송-취소/교환/반품',
	PAYMENT_NEED_LIST: '결제-미결제금액',
	PAYMENT: '결제하기',
	PAYMENT_RESULT: '결제완료',
	PAYMENT_LIST: '결제-결제내역',
	ESTIMATE_LIST: '견적-견적함',
	REQUEST_LIST: '견적-상품요청목록',
	WISH_LIST: '활동정보-찜목록',
	RECENT_VIEW_LIST: '활동정보-최근본상품',
	INST_INFO: '나의정보-소속정보관리',
	USER_INFO: '나의정보-회원정보관리',
	DELIVERY_LIST: '나의정보-배송지관리',
	EBILL_LIST: '나의정보-사업자등록증정보관리',
	CARD_LIST: '나의정보-간편결제정보관리',
	INQUIRY: '고객센터-1:1문의',
	FAQ: '고객센터-자주묻는질문',
	NOTICE: '고객센터-공지사항',
	EVENT: '고객센터-이벤트',
	// RECENT_LIST: "최근주문한상품",
	FREQ_LIST: '자주주문한상품',
	BRAND_LIST: '브랜드관',
	SELLER_LIST: '판매자관',
	// REPURCHASE_LIST: "주문이필요한상품",
	// QUICK_LIST: "샥샥배송상품"
}
