<template>
	<div id="Search">
		<div class="wrapper" ref="scroll-container">
			<search-item-title v-if="mode != 'list'" :item="info" :placeholder="placeholder" @search="search" :mode="mode" />
			<loading v-if="isLoading" :keyword="getLoadingKeyword" />
			<filter-box
				v-show="!isLoading && showFilter"
				:keyword="keyword"
				:list="filterList"
				:selected="selectedCategory()"
				:listLength="catalogList.count"
				:mode="mode"
				@setSelectedFilter="setSelectedFilter"
			/>
			<product-list
				v-show="!isLoading && list.length > 0"
				:list="list"
				:tabList="sortTabList"
				@sortProduct="sortProduct"
				@searchSuccess="searchSuccess"
			/>
			<no-product-list v-if="isNoData" title="검색결과" />
		</div>
	</div>
</template>

<script>
import ProductList from '@/components/list/ProductList.vue'
import SearchItemTitle from '@/views/search/components/SearchItemTitle.vue'
import FilterBox from '@/components/search/FilterBox.vue'
import Loading from '@/components/Loading.vue'
import NoProductList from '@/components/list/NoProductList.vue'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import MixinScrolling from '@/mixins/scrolling'
import cookies from 'vue-cookies'
import gaAPI from '@/utils/ga/api'
import loggerAPI from '@/services/api/logger'

export default {
	name: 'Search',
	mixins: [MixinScrolling],
	components: {
		ProductList,
		SearchItemTitle,
		FilterBox,
		Loading,
		NoProductList,
	},
	computed: {
		...mapState('catalog', ['catalogList']),
		...mapState('seller', ['seller']),
		...mapState('brand', ['brand']),
		...mapState('delivery', ['basicAddress']),
		...mapState('user', ['user']),
		...mapGetters('user', ['instituteId']),
		...mapGetters('catalog', ['getSellerFilterList', 'getBrandFilterList']),
		...mapGetters('category', ['getParentCategoryList']),
		info() {
			if (this.$route.path.indexOf('brand') >= 0) {
				return JSON.parse(JSON.stringify(this.brand))
			} else {
				return JSON.parse(JSON.stringify(this.seller))
			}
		},
		sortTabList() {
			let list = []

			list.push({ id: 1, value: 'FAVORITE', direction: 'DESC', name: '구매 많은순' })
			list.push({ id: 2, value: 'PRICE', direction: 'ASC', name: '낮은 가격순' })
			list.push({ id: 3, value: 'PRICE', direction: 'DESC', name: '높은 가격순' })

			return list
		},
		placeholder() {
			if (this.mode == 'brand') {
				return '이 브랜드의 상품 검색'
			} else if (this.mode == 'seller') {
				return '이 판매자의 상품 검색'
			}
		},
		filterList() {
			let filters = {}
			filters.filterCategoryList = this.getParentCategoryList
			filters.filterBrandList = this.getBrandFilterList
			filters.filterSellerList = this.getSellerFilterList
			return filters
		},
		isNoData() {
			return !this.isLoading && this.list.length === 0
		},
		getLoadingKeyword() {
			let result = ''
			if (this.$route.params.category === 'brand' || this.$route.params.category === 'seller')
				result += `"${this.$route.params.category === 'brand' ? this.brand.name : this.seller.name}"으로 검색 중`
			else if (this.keyword && this.keyword !== '') result += `"${this.keyword}"으로 검색 중 `
			else result += '카테고리 검색 중'

			return result
		},
		currentAddressId() {
			return cookies.get('currentAddress2')?.id ?? this.basicAddress?.id
		},
		currentInstituteId() {
			return cookies.get('currentInstitute2')?.id ?? this.instituteId
		},
		showFilter() {
			if (this.$route.query.keyword) {
				if (this.list.length > 0) {
					return true
				} else {
					if (this.tags) {
						return true
					} else {
						return false
					}
				}
			} else {
				return true
			}
		},
	},
	watch: {
		'$route.params.category': {
			deep: true,
			handler(val) {
				this.$router.go()
			},
		},
		'$route.query.category': {
			deep: true,
			handler(val) {
				this.$router.go()
			},
		},
		'$route.query.keyword': {
			deep: true,
			handler(val) {
				this.$router.go()
			},
		},
		async selectTab() {
			this.offset = 0
			await this.setList()
		},
		async isBottom(val) {
			if (val === true) {
				this.offset = this.offset + this.length
				const params = {
					keyword: this.keyword,
					keywordType: this.keywordType,
					keywordTarget: this.keywordTarget,
					offset: this.offset,
					length: this.length,
					order: this.order,
					orderDirection: [this.orderDirection],
					sellerIds: this.sellerIds,
					brandIds: this.brandIds,
					categoryKorName: this.categoryKorName,
					tags: this.tags,
				}

				if (this.currentAddressId) params.deliveryId = this.currentAddressId
				if (this.currentInstituteId) params.instituteId = this.currentInstituteId

				await this.lookupCatalogList(params)
				if (this.catalogList.list.length > 0) {
					this.list = [...this.list, ...this.catalogList.list]
				}
			}
		},
		async selectedFilter(filter) {
			this.offset = 0
			if (this.$route.path.indexOf('brand') >= 0) this.sellerIds = []
			else if (this.$route.path.indexOf('seller') >= 0) this.brandIds = []
			else {
				this.sellerIds = []
				this.brandIds = []
			}
			this.tags = []
			this.categoryCode = ''
			this.categoryKorName = ''
			filter.forEach(item => {
				if (item.type === 'seller') this.sellerIds.push(item.value)
				else if (item.type === 'brand') this.brandIds.push(item.value)
				else if (item.type === 'category') {
					this.categoryCode = item.value
					this.categoryKorName = item.name
				} else if (item.type === 'tag') this.tags.push(item.value)
			})
			await this.setList()
		},
	},
	data() {
		return {
			mode: null,
			classification: null,
			category: null,
			selectedFilter: [],
			isLoading: true,
			list: [],
			selectTab: 'FAVORITE',
			keyword: '',
			keywordType: 'TEXT',
			keywordTarget: 'ALL',
			offset: 0,
			length: 10,
			order: [],
			orderDirection: ['DESC'],
			categoryCode: '',
			sellerIds: [],
			brandIds: [],
			tags: [],
			categoryKorName: '',
		}
	},
	created() {
		this.mode = this.$route.params.category ? this.$route.params.category : 'list'
	},
	mounted() {
		this.tags = this.$route.query.tags ? [this.$route.query.tags] : []
		this.classification = this.$route.query.classification
		this.category = this.$route.query.category
		if (this.$route.query.keyword) {
			this.keyword = this.$route.query.keyword
			this.keywordTarget = this.$route.query.type
		}
		this.loadList()
	},
	beforeDestroy() {
		this.clearFilter()
	},
	methods: {
		...mapActions('catalog', ['lookupCatalogList']),
		...mapActions('seller', ['lookupSeller']),
		...mapActions('brand', ['lookupBrand']),
		...mapActions('category', ['lookupParentCategoryList']),
		...mapMutations('catalog', ['clearFilter']),
		async loadList() {
			await this.lookupParentCategoryList()
			this.order = [this.selectTab]
			await this.setInfo()
			await this.setList()
		},
		async setList() {
			if (this.category && this.classification && (this.categoryCode != this.category || !this.categoryCode)) return
			else if (!this.category && this.classification && (this.categoryCode != this.classification || !this.categoryCode)) return
			this.classification = null
			this.category = null
			const params = {
				keyword: this.keyword,
				keywordType: this.keywordType,
				keywordTarget: this.keywordTarget,
				offset: this.offset,
				length: this.length,
				order: this.order,
				orderDirection: [this.orderDirection],
				sellerIds: this.sellerIds,
				brandIds: this.brandIds,
				categoryKorName: this.categoryKorName,
				tags: this.tags,
			}

			if (this.currentAddressId) params.deliveryId = this.currentAddressId
			if (this.currentInstituteId) params.instituteId = this.currentInstituteId

			this.isLoading = true
			await this.lookupCatalogList(params)
			this.list = JSON.parse(JSON.stringify(this.catalogList.list))
			this.isLoading = false
			gaAPI.gaEventSearch({
				keyword: params.keyword,
				type: params.keywordType,
				category_id: params.categoryCode,
			})
		},
		async setInfo() {
			if (this.$route.path.indexOf('brand') >= 0) {
				await this.lookupBrand(this.$route.query.id)
				this.brandIds = [this.$route.query.id]
			} else if (this.$route.path.indexOf('seller') >= 0) {
				await this.lookupSeller(this.$route.query.id)
				this.sellerIds = [this.$route.query.id]
			}
		},
		sortProduct(tab) {
			this.orderDirection = tab.direction
			this.selectTab = tab
			this.order = [this.selectTab.value]
		},
		async searchSuccess({ itemType, itemValue }) {
			await loggerAPI.searchSuccess({
				user_id: this.user.id,
				session_id: '',
				keyword: this.keyword,
				type: this.keywordType,
				itemType,
				itemValue,
			})
		},
		search(value) {
			this.keyword = value
			this.offset = 0
			this.setList()
		},
		setSelectedFilter(list) {
			this.selectedFilter = list
		},
		selectedCategory() {
			let selectedCategory = {}
			this.getParentCategoryList.forEach(item => {
				if (item.value == this.classification) {
					selectedCategory = item
				}
			})
			if (this.category) {
				selectedCategory.category = this.category
			}
			return selectedCategory
		},
	},
}
</script>
