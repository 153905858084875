<template>
	<b-modal id="ModalSeller" centered hide-footer ref="modal">
		<div class="seller-wrapper">
			<div class="seller-image">
				<img :src="image" />
			</div>
			<div class="seller-info">
				<div class="header">판매자 정보</div>
				<div class="info-table">
					<div class="info-row">
						<div class="label">판매자</div>
						<div class="content">{{ seller.name }}</div>
					</div>
					<div class="info-row">
						<div class="label">담당자</div>
						<div class="content">{{ seller.contactName }}</div>
					</div>
					<div class="info-row">
						<div class="label">연락처</div>
						<div class="content">{{ seller.contactPhone }}</div>
					</div>
					<div class="info-row">
						<div class="label">이메일</div>
						<div class="content">{{ seller.email }}</div>
					</div>
				</div>
				<div class="buttons">
					<input :checked="seller.isWish" class="wish lg block" type="checkbox" @change="onChangeWish" />
					<button class="btn btn-primary lg" @click="goPage()">판매자 상품 보기</button>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import PageName from '@/utils/define/PageName'
import mixin from '@/mixins'
import { mapMutations } from 'vuex'

export default {
	mixins: [mixin],
	props: {
		seller: Object,
	},
	data() {
		return {
			isWish: false,
		}
	},
	computed: {
		image() {
			return this.seller.image ?? require('@/assets/svg/empty_product.svg')
		},
	},
	methods: {
		...mapMutations('productInfo', ['setIsWish']),
		onChangeWish(e) {
			const isWish = e.target.checked
			this.changeWish(this.seller.id, isWish, 'seller')
			this.setIsWish(isWish)
		},
		goPage() {
			this.$router.push({
				name: PageName.Search,
				params: {
					category: 'seller',
				},
				query: {
					id: this.seller.id,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.seller-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0px 30px;
	width: 100%;
	.seller-image {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 400px;
		height: 130px;
		border: 1px solid #ddd;
		padding: 20px 30px;
		border-radius: 4px;
		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
	.seller-info {
		margin: 30px 0px;
		width: 100%;
		.header {
			width: 100%;
			font-weight: 700;
			font-size: 16px;
			line-height: 23px;
		}
		.info-table {
			margin-top: 10px;
			border-top: 1px solid #ddd;
			.info-row {
				width: 100%;
				border-bottom: 1px solid #eee;
				height: 40px;
				display: flex;
				flex-direction: row;
				& > * {
					display: flex;
					align-items: center;
					height: 100%;
					font-size: 14px;
					padding-left: 12px;
					padding-right: 12px;
				}
				.label {
					width: 140px;
					background: #f9f9f9;
					color: #333;
				}
				.content {
					width: 260px;
					color: #111;
					word-break: keep-all;
				}
			}
		}
	}
	.buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
		.btn-primary {
			width: 200px;
			margin-left: 10px;
		}
	}
}
</style>
