<template>
	<div class="return-history-wrapper">
		<div v-for="(cs, index) of list" :key="index" class="return-history">
			<div v-if="showHeader" class="return-history-header">
				<div class="header-wrapper">
					<div>
						<span>접수일</span>
						<b>{{ getDatetime(cs) }}</b>
					</div>
					<span class="separator">|</span>
					<div>
						<span>주문일자</span>
						<b>{{ formatDate(cs.createDatetime) }}</b>
					</div>
					<span class="separator">|</span>
					<div>
						<span>주문번호</span>
						<b>{{ cs.orderNo }}</b>
					</div>
				</div>
			</div>
			<div class="return-history-body" :style="!showHeader ? { borderTop: '1px solid #eee', borderRadius: '5px' } : null">
				<order-item :item="cs" :orderer="cs.orderer" :searchKeyword="searchKeyword" :isReturn="true" />
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import OrderItem from '@/views/my-page/order-delivery/order/components/OrderItem.vue'

export default {
	name: 'ReturnHistory',
	components: {
		OrderItem,
	},
	mixins: [mixin],
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		searchKeyword: {
			type: String,
			default: '',
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		getDatetime(cs) {
			if (cs.csType.code === 'CANCEL') {
				if (cs.cancelRequestDatetime) return this.formatDate(cs.cancelRequestDatetime)
				else return this.formatDate(cs.cancelDatetime)
			} else if (cs.csType.code === 'RETURN') return this.formatDate(cs.returnRequestDatetime)
		},
	},
}
</script>

<style lang="scss" scoped>
.return-history-wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	.return-history {
		&:first-child {
			.return-history-header {
				border-radius: 5px 5px 0 0;
			}
		}
		&:last-child {
			.return-history-body {
				.order-item {
					&:last-child {
						border-radius: 0 0 5px 5px;
					}
				}
			}
		}
		.return-history-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
			min-height: 40px;
			padding: 20px;
			border: 1px solid $COLOR_EEE;
			background: $LIST_BACKGROUND;
			font-size: 0.875rem;
			.separator {
				color: $COLOR_DDD;
			}
			.header-wrapper {
				display: flex;
				align-items: center;
				gap: 16px;
				> div {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					gap: 8px;
					> span {
						font-weight: 500;
					}
				}
			}
		}
	}
}
</style>
