<template>
	<div class="start-icon">
		<img :src="icon" />
	</div>
</template>

<script>
export default {
	name: 'StartIcon',
	props: {
		progressStep: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		icon() {
			if (this.progressStep == 1) {
				return require('@/assets/svg/icon/icon-type-profile.svg')
			} else if (this.progressStep <= 4) {
				return require('@/assets/svg/icon/icon-institution.svg')
			} else {
				return require('@/assets/svg/icon/icon-group.svg')
			}
		},
	},
}
</script>
