<template>
	<b-modal ref="modal" id="ModalImagePreview" centered title="이미지 미리보기">
		<div class="image-preview-wrapper">
			<img :src="imageUrl" />
		</div>
		<template #modal-footer>
			<div class="image-preview-footer">
				<button class="btn btn-white" @click="$refs.modal.hide">닫기</button>
			</div>
		</template>
	</b-modal>
</template>

<script>
export default {
	name: 'ModalImagePreview',
	props: {
		imageUrl: {
			type: String,
			required: true,
		},
	},
}
</script>

<style lang="scss">
#ModalImagePreview___BV_modal_outer_ {
	.modal-dialog {
		max-width: 700px;
	}
	#ModalImagePreview___BV_modal_body_ {
		padding: 0;
	}
	#ModalImagePreview___BV_modal_footer_ {
		&.modal-footer {
			padding: 20px;
		}
	}
}
</style>

<style lang="scss" scoped>
.image-preview-wrapper {
	position: relative;
	text-align: center;
	> img {
		width: 660px;
		height: 660px;
		border: 1px solid $COLOR_EEE;
		border-radius: 5px;
	}
}
.image-preview-footer {
	width: 100%;
	text-align: right;
}
</style>
