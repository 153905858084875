import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/modules/ParamMaker'

const endPoint = {
	lookupInquiryList: (instituteId, paramsObject) => `institute/${instituteId}/inquiry/list?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupInquiryType: instituteId => `institute/${instituteId}/inquiry/type`,
	lookupInquiry: (instituteId, id) => `institute/${instituteId}/inquiry?id=${id}`,
	addInquiry: instituteId => `institute/${instituteId}/inquiry`,
}

const inquiryAPI = {
	lookupInquiryList: (instituteId, paramsObject) => axiosInstance.get(endPoint.lookupInquiryList(instituteId, paramsObject)),
	lookupInquiryType: instituteId => axiosInstance.get(endPoint.lookupInquiryType(instituteId)),
	lookupInquiry: (instituteId, id) => axiosInstance.get(endPoint.lookupInquiry(instituteId, id)),
	addInquiry: (instituteId, formData) =>
		axiosInstance.post(endPoint.addInquiry(instituteId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
}

export default inquiryAPI
