import deliveryAPI from '@/services/api/delivery'

const deliveries = {
	namespaced: true,
	state: {
		addressList: [],
		basicAddress: {},
	},
	mutations: {
		setAddressList: (state, payload) => {
			state.addressList = payload
			state.basicAddress = payload.find(address => address.isDefault === 1)
		},
	},
	actions: {
		lookupAddressList: async ({ commit }, instituteId) => {
			const response = await deliveryAPI.lookupAddressList(instituteId)
			commit('setAddressList', response.data)
		},
		addAddress: async (_, { instituteId, body }) => {
			return await deliveryAPI.addAddress(instituteId, body)
		},
		updateAddress: async (_, { instituteId, body }) => {
			return await deliveryAPI.updateAddress(instituteId, body)
		},
		deleteAddress: async (_, { instituteId, id }) => {
			return await deliveryAPI.deleteAddress(instituteId, id)
		},
	},
}

export default deliveries
