<template>
	<div v-if="isLogin" class="cart-banner">
		<div>
			<img src="@/assets/svg/cart/shiny-card.svg" alt="" />
		</div>
		<div class="content">
			<img src="@/assets/svg/cart/exclamation-mark-red.svg" alt="" />
			<div v-if="hasnoPaid">
				총 미결제 금액은<span class="price">{{ CommaNum(myPageInfo.noPaidPriceWithVat) }}</span
				>원입니다
			</div>
			<div v-else style="margin-right: 80px">미결제 금액이 없습니다.</div>
			<div v-if="hasnoPaid" class="payment" @click="goPay">
				결제 하기
				<img src="@/assets/svg/arrow_up_s.svg" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
import ModalShippingAddress from '@/views/my-page/my-info/shipping-address/modal/ModalShippingAddress.vue'
import mixin from '@/mixins'
import PageName from '@/utils/define/PageName'
import { isLogin } from '@/services/api/common'
import { mapState } from 'vuex'

export default {
	name: 'CartBanner',
	components: {
		ModalShippingAddress,
	},
	mixins: [mixin],
	computed: {
		...mapState('user', ['myPageInfo']),
		isLogin() {
			return isLogin()
		},
		hasnoPaid() {
			return this.myPageInfo.noPaidPriceWithVat > 0
		},
	},
	methods: {
		goPay() {
			this.$router.push({
				name: PageName.MyPage.UnPaymentManagement,
				params: {
					step: 1,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.cart-banner {
	height: 80px;
	display: flex;
	justify-content: center;
	background-color: #fef9ec;

	.content {
		display: flex;
		align-items: center;
		font-weight: 500;
		margin-left: 60px;

		img + div {
			display: flex;
			align-items: center;
			margin-left: 7px;
		}
		.price {
			font-size: 1.25rem;
			font-weight: 700;
			color: $PRIMARY_RED;
			margin: 0 3px;
		}
		.payment {
			display: flex;
			align-items: center;
			font-size: 0.75rem;
			font-weight: 700;
			margin-left: 20px;
			color: #666666;
			cursor: pointer;
			img {
				margin-left: 10px;
			}
		}
	}
}
</style>
