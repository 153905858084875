<template>
	<b-modal id="ModalPopup" class="modal" ref="modal" centered no-close-on-esc no-close-on-backdrop hide-header hide-footer @show="init">
		<template v-if="list.length > 1">
			<swiper ref="swiper" class="modal-popup-swiper" :options="swiperOptions" @init="changeSlide" @slideChange="changeSlide">
				<swiper-slide class="modal-popup-swiper-slide" v-for="(popup, index) in list" :key="index">
					<div class="image-wrapper" @click="goToLink(popup.link)">
						<img :src="popup.image" />
					</div>
				</swiper-slide>
				<div class="swiper-pagination modal-popup-swiper-pagination" slot="pagination" />
				<div class="swiper-button-prev modal-popup-swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-button-next modal-popup-swiper-button-next" slot="button-next"></div>
			</swiper>
		</template>
		<template v-else-if="list.length === 1">
			<div class="image-wrapper" @click="goToLink(list[0].link)">
				<img :src="list[0].image" alt="" />
			</div>
		</template>
		<div class="footer">
			<button @click="hideWeek">일주일간 보지 않기</button>
			<button @click="$refs['modal'].hide()">닫기</button>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import cookies from 'vue-cookies'

export default {
	name: 'ModalPopup',
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			swiperOptions: {
				slidesPerView: 1,
				centeredSlides: true,
				navigation: {
					nextEl: '.modal-popup-swiper-button-next',
					prevEl: '.modal-popup-swiper-button-prev',
				},
				pagination: {
					el: '.modal-popup-swiper-pagination',
					clickable: true,
				},
			},
			popup: {},
		}
	},
	computed: {
		...mapState('popup', ['popupList']),
		swiper() {
			return this.$refs['swiper'].swiper
		},
	},
	methods: {
		...mapActions('popup', ['lookupPopupList']),
		async init() {
			await this.$nextTick()
			if (this.list.length > 1) this.changeSlide()
		},
		goToLink(link) {
			window.open(link, '_blank')
		},
		hideWeek() {
			if (this.list.length === 1) cookies.set(`popup_${this.list[0].id}`, this.list[0].id, '7d')
			else cookies.set(`popup_${this.popup.id}`, this.popup.id, '7d')
			this.$refs['modal'].hide()
		},
		changeSlide() {
			const index = this.swiper.activeIndex
			this.popup = this.list[index]
		},
	},
}
</script>

<style lang="scss">
#ModalPopup___BV_modal_outer_ {
	.modal-dialog {
		max-width: 360px;
		height: 510px;
	}
	#ModalPopup___BV_modal_content_ {
		border: 0;
		border-radius: 10px;
		#ModalPopup___BV_modal_body_ {
			padding: 0;
		}
	}
}
</style>

<style lang="scss" scoped>
.image-wrapper {
	height: 510px;
	cursor: pointer;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		border-radius: 8px 8px 0 0;
	}
}
.footer {
	height: 56px;
	display: flex;
	button {
		flex: 1;
		width: 100%;
		height: 100%;
		font-size: 0.875rem;
		font-weight: 500;
	}
	button ~ button {
		border-left: 1px solid #eeeeee;
	}
}

.modal-popup-swiper {
	width: 100%;
	height: 510px;
	.modal-popup-swiper-slide {
		width: 100%;
	}
	.modal-popup-swiper-button-prev {
		background-image: url(~@/assets/svg/banner-left-btn.svg);
	}
	.modal-popup-swiper-button-next {
		background-image: url(~@/assets/svg/banner-right-btn.svg);
	}
}
</style>

<style lang="scss">
#ModalPopup {
	.modal-popup-swiper {
		.modal-popup-swiper-pagination {
			.swiper-pagination-bullet {
				background-color: $PRIMARY_WHITE;
				opacity: 1;
			}
			.swiper-pagination-bullet-active {
				background-color: $PRIMARY_YELLOW;
				opacity: 1;
			}
		}

		.modal-popup-swiper-button-prev,
		.modal-popup-swiper-button-next {
			height: 40px;
			width: 40px;
		}
	}
}
</style>
