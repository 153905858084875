<template>
	<div>
		<base-management title="회원정보 관리">
			<div class="user-info-management-wrapper">
				<image-form-item title="프로필 사진" :src="info.image" @change="onChangeImage" />
				<input-form-item v-model="info.email" type="email" title="이메일" disabled />
				<input-form-item v-model="info.name" title="이름" disabled />
				<input-form-item
					v-model="info.phone"
					title="휴대폰"
					type="tel"
					:button="buttonTitle"
					:buttonDisabled="isVerified"
					disabled
					@click="onClickAuthentication"
				/>
				<input-form-item v-model="dummyPassword" type="password" title="비밀번호" button="변경" disabled @click="onClickChangePassword" />
				<hr />
				<checkbox-form-item
					v-model="info.noti.marketing"
					title="마케팅정보수신"
					content="상품 및 이벤트 정보 수신에 동의합니다"
					@input="onInputMarketing"
				/>
			</div>
		</base-management>
	</div>
</template>

<script>
import { isLogin } from '@/services/api/common'
import { mapActions, mapState } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
const { IMP } = window
import PageName from '@/utils/define/PageName'
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import ImageFormItem from '@/components/form-item/ImageFormItem.vue'
import InputFormItem from '@/components/form-item/InputFormItem.vue'
import CheckboxFormItem from '@/components/form-item/CheckboxFormItem.vue'

export default {
	name: 'UserInfoManagement',
	components: {
		BaseManagement,
		ImageFormItem,
		InputFormItem,
		CheckboxFormItem,
	},
	data() {
		return {
			info: {
				noti: {
					marketing: false,
				},
			},
			profileImage: {},
			dummyPassword: '12345678',
			isFirst: true,
		}
	},
	computed: {
		...mapState('user', ['user']),
		isVerified() {
			return this.user.isVerified
		},
		buttonTitle() {
			return this.isVerified ? '본인인증 완료' : '본인인증'
		},
	},
	created() {
		if (!isLogin()) return

		this.fetchUser()
	},
	methods: {
		...mapActions('user', ['updateUser', 'getUserInfo', 'verification']),
		async fetchUser() {
			await this.getUserInfo()

			this.info = this.$_.cloneDeep(this.user)
			this.info.noti.marketing = this.info.noti.push && this.info.noti.email
		},
		onChangeImage(image) {
			this.profileImage = image
			this.update('프로필 사진')
		},
		onInputMarketing(val) {
			this.info.noti.push = val
			this.info.noti.sms = val
			this.info.noti.email = val
			this.update('마케팅정보수신')
		},
		onClickChangePassword() {
			this.$router.push({
				name: PageName.MyPage.ChangePassword,
			})
		},
		async update(name) {
			try {
				const formData = new FormData()
				formData.append('profileImage', this.profileImage)
				formData.append('isNotiPush', this.info.noti.marketing ?? false)
				formData.append('isNotiSMS', this.info.noti.marketing ?? false)
				formData.append('isNotiEmail', this.info.noti.marketing ?? false)

				await this.updateUser(formData)
				this.$root.toast('수정 완료', `${name}을(를) 수정 완료했습니다.`, ToastType.SUCCESS)
			} catch (e) {
				this.$root.toast('수정 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
		onClickAuthentication(e) {
			const data = {
				merchant_uid: `merchant_${new Date().getTime()}`,
				min_age: 14,
			}

			IMP.init('imp45147695')
			IMP.certification(data, this.callback)
		},
		callback(response) {
			const { success, imp_uid, error_msg } = response
			if (success) {
				this.authentication(imp_uid)
			} else {
				this.$root.toast('에러', error_msg, ToastType.ERROR)
			}
		},
		async authentication(imp_uid) {
			try {
				await this.verification({
					impUid: imp_uid,
				})
				this.$root.toast('본인인증 완료', '본인인증이 완료되었습니다', ToastType.SUCCESS)
				await this.fetchUser()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.user-info-management-wrapper {
	border: 1px solid $COLOR_DDD;
	border-radius: 5px;
	padding: 20px;
	hr {
		color: #bbb;
	}
}
</style>
