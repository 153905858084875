<template>
	<b-modal id="ModalShippingTracking" centered title="배송 조회" ok-only ok-title="닫기" ok-variant="default" ref="modal"
		><div class="boxwrap">
			<div class="Popup__content">
				<div>
					<h5>
						<template v-if="shippingDate && status === '배송완료'">
							<span>{{ shippingDate }}</span>
						</template>
						{{ status }}
						<img v-if="status === '배송중'" src="@/assets/svg/ing-delivery-m.svg" />
						<img v-else-if="status === '배송완료'" src="@/assets/svg/success-delivery-m.svg" />
						<br />
					</h5>
					<div v-if="orderItem.invoiceNumber" class="area">
						<p>
							{{ orderItem.shippingCompany }}
						</p>
						<p>
							{{ orderItem.invoiceNumber }}
						</p>
						<button @click="copyToClipboardLink">복사</button>
					</div>
					<div v-else-if="type" class="area">
						<p v-if="type === 'quick'">퀵 서비스</p>
						<p v-else-if="type === 'seller_direct'">판매자 직접배송</p>
						<p v-else-if="type === 'company_direct'">제조사 직접배송</p>
						<p v-else-if="type === 'parcel'">택배 배송</p>
					</div>
				</div>
				<div v-if="orderItem.invoiceNumber || status === '배송완료'">
					<p>배송정보</p>
					<div>
						<p>배송지</p>
						<p>{{ address }}</p>
					</div>
					<div>
						<p>받는분</p>
						<p>{{ orderer.name }}</p>
					</div>
					<div class="area">
						<div class="imgbox">
							<img :src="image" />
						</div>
						<div>
							<p>{{ product.name }}</p>
							<p>
								<template v-for="option of product.options"> {{ option.name }}: {{ option.value }} </template>
							</p>
						</div>
					</div>
				</div>
				<div>
					<p>판매자 정보</p>
					<div>
						<p>판매자</p>
						<p>{{ seller.name }}</p>
					</div>
					<div>
						<p>연락처</p>
						<p>{{ seller.contactPhone }}</p>
					</div>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { ITEM_STATUS_TYPE } from '@/utils/define/order'

export default {
	name: 'ModalShippingTracking',
	props: {
		orderItem: {
			type: Object,
			default: () => ({}),
		},
		orderer: {
			type: Object,
			default: () => ({}),
		},
		seller: {
			type: Object,
			default: () => ({}),
		},
		address: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			week: ['일', '월', '화', '수', '목', '금', '토'],
		}
	},
	computed: {
		status() {
			return ITEM_STATUS_TYPE[this.orderItem.status] ?? ''
		},
		shippingDate() {
			return this.dateFormat(this.orderItem.shippingDatetime)
		},
		product() {
			return this.orderItem.product ?? {}
		},
		image() {
			return this.product?.image ?? require('@/assets/svg/empty_product.svg')
		},
	},
	methods: {
		copyToClipboardLink() {
			navigator.clipboard.writeText(this.orderItem.invoiceNumber)

			this.$root.toast('클립보드에 복사되었습니다', '운송장번호가 클립보드에 복사되었습니다', 'success')
		},
		dateFormat(date) {
			const d = new Date(date)
			const month = '' + (d.getMonth() + 1)
			const day = '' + d.getDate()
			return `${month}/${day}(${this.week[d.getDay()]})`
		},
	},
}
</script>

<style lang="scss" scoped>
.boxwrap {
	margin-top: -15px;
	margin-bottom: -15px;
	.Popup__content {
		display: flex;
		flex-direction: column;
		gap: 15px;
		h5 {
			display: flex;
			align-items: center;
			gap: 8px;
			font-size: 1.5rem;
			> img {
				margin-left: 11px;
			}
		}
		p {
			display: inline-block;
			font-size: 0.75rem;
			color: #000;
			font-weight: 400;
		}
		p:nth-child(2) {
			font-weight: 700;
		}
		button {
			font-size: 0.75rem;
			color: #0b6cff;
			margin-left: auto;
		}
		& > div {
			display: flex;
			flex-direction: column;
			gap: 5px;
			border-bottom: 1px solid $COLOR_DDD;
			padding-bottom: 15px;
			.area {
				margin-top: 10px;
				padding: 10px;
				background: #f9f9f9;
				border-radius: 4px;
				div {
					display: flex;
					flex-direction: column;
					gap: 0;
					justify-content: center;
					p {
						font-weight: 700;
						font-size: 0.875rem;
					}
					p:nth-child(2) {
						font-weight: 400;
						font-size: 0.75rem;
						color: #666;
					}
				}

				.imgbox {
					width: 60px;
					height: 60px;
					border-radius: 4px;
					border: 1px solid $LINE_DIVIDER;
					overflow: hidden;
					// padding: 0 10px;
					img {
						width: 100%;
						height: inherit;
						object-fit: contain;
					}
				}
			}
			& > p {
				padding-bottom: 5px;
				color: #999;
			}
			div {
				display: flex;
				gap: 15px;
			}
			&:last-child {
				border-bottom: none;
			}
		}
	}
}
</style>
