<template>
	<div class="simple-textarea-form" :class="{ readonly: readonly }">
		<div class="title" v-if="title">
			<h6>{{ title }}</h6>
			<p class="content" v-if="content">{{ content }}</p>
			<div class="right-btn-group" v-if="!isCompleted">
				<button class="btn" v-if="!isEdit" @click="isEdit = true">수정</button>
				<button class="btn btn-complete" v-if="isEdit" @click="save">저장</button>
			</div>
		</div>
		<textarea v-model="textarea" class="form-textarea" :class="{ disabled: isCompleted || !isEdit }" :disabled="isCompleted || !isEdit" />
	</div>
</template>

<script>
export default {
	name: 'SimpleTextareaForm',
	props: {
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
		isCompleted: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isEdit: true,
			textarea: '',
		}
	},
	watch: {
		value(val) {
			if (!val) return

			this.init()
		},
	},
	methods: {
		init() {
			this.textarea = JSON.parse(JSON.stringify(this.value))
		},
		async cancel() {
			this.isEdit = false
			await this.init()
		},
		async save() {
			await this.$emit('save', this.textarea)
			this.isEdit = false
		},
	},
}
</script>

<style lang="scss" scoped>
.simple-textarea-form {
	&.readonly {
		.title {
			h6 {
				font-size: 0.875rem;
			}
			.right-btn-group {
				display: none;
			}
		}
	}
	.title {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		h6 {
			font-size: 1.25rem;
			font-weight: 700;
		}
		p {
			margin-left: 1rem;
		}
		.right-btn-group {
			margin-left: auto;
			button {
				margin-left: 10px;
			}
		}
	}
	textarea {
		height: 80px;
	}
}
</style>
