<template>
	<div class="start-store-header">
		<div class="title">
			<h2>{{ title }}</h2>
			<div class="profile" @mouseover="toggleUserInfo(true)" @mouseleave="toggleUserInfo(false)">
				<img :src="profile.image ? profile.image : require('@/assets/svg/icon/icon-store-profile.svg')" />
				<p>{{ profile.name }}님</p>
				<floating-menu :list="userMenu" />
			</div>
		</div>
		<progress-indicator :progressStep="progressStep" />
	</div>
</template>

<script>
import ProgressIndicator from '@/views/start/components/ProgressIndicator'
import FloatingMenu from '@/components/menu/FloatingMenu.vue'
import { ToastType } from '@/utils/define/ToastType'
import { mapActions } from 'vuex'

export default {
	name: 'StartStoreHeader',
	components: {
		FloatingMenu,
		ProgressIndicator,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		profile: {
			type: Object,
			default: () => {},
		},
		progressStep: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			userMenu: [
				{
					name: '로그아웃',
					clickEv: async () => {
						this.$root.toast('로그아웃 완료', '로그아웃이 정상적으로 완료 되었습니다', ToastType.SUCCESS)
						await this.logout()
						location.href = '/'
					},
				},
			],
			showUserInfo: false,
		}
	},
	watch: {
		showUserInfo() {
			let selector = document.querySelector('.start-store-header .floating-menu')
			if ([...selector.classList].includes('active')) {
				selector.classList.remove('active')
			} else {
				selector.classList.add('active')
			}
		},
	},
	methods: {
		...mapActions('user', ['logout']),
		toggleUserInfo(isShow) {
			this.showUserInfo = isShow
		},
	},
}
</script>

<style lang="scss" scoped>
.start-store-header {
	width: 100%;
	margin: 0 0 !important;
	position: sticky;
	top: 0;
	left: 0;
	.title {
		width: 100%;
		height: 58px;
		display: flex;
		align-items: center;
		text-align: center;
		position: relative;
		h2 {
			font-size: 1rem;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		.profile {
			height: 34px;
			display: inline-flex;
			margin-left: auto;
			align-items: center;
			padding-right: 10px;
			img {
				width: 24px;
				height: 24px;
				border-radius: 24px;
				border: 1px solid $LINE_DIVIDER;
				margin-right: 5px;
			}
			p {
				font-size: 0.875rem;
				font-weight: 700;
			}
			.floating-menu {
				width: 200px;
				top: 45px;
				visibility: hidden;
				opacity: 0;
				&.active {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
}
</style>
