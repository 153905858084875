<template>
	<div class="InquiryManagement">
		<content-title title="1:1 문의" />
		<div class="content-wrapper">
			<detail-search-box
				class="search-box"
				title="상세검색"
				subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
				searchPlaceholder="상태, 문의 유형, 작성자, 문의 내용으로 검색"
				searchText="문의 일시"
				:datePlaceholder="datePlaceholder"
				@searchTextList="searchTextList"
			/>
			<common-table
				title="문의 내역"
				:count="count"
				:headers="inquiryHeaders"
				:list="list"
				btnText="문의 작성하기"
				@clickEv="goWrite"
				@go="goDetail"
				:noData="noData"
				@page="getCurrentPage"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { isLogin } from '@/services/api/common'
import { Inquiry } from '@/utils/define/ItemCode'
import PageName from '@/utils/define/PageName'
import date from '@/mixins/date'
import ContentTitle from '@/components/content/ContentTitle'
import DetailSearchBox from '@/views/my-page/order-delivery/order/components/search/DetailSearchBox'
import CommonTable from '@/components/table/CommonTable'

export default {
	name: 'InquiryManagement',
	components: {
		ContentTitle,
		DetailSearchBox,
		CommonTable,
	},
	mixins: [date],
	data() {
		return {
			offset: 0,
			length: 50,
			searchKeyword: '',
			searchDate: [],
			list: [],
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('inquiry', ['inquiryList', 'count']),
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
		inquiryHeaders() {
			let list = []
			list.push({ key: 'status', name: '상태', width: 100 })
			list.push({ key: 'type', name: '문의 유형', width: 150 })
			list.push({ key: 'content', name: '문의 내용', width: 600 })
			list.push({ key: 'writer', name: '작성자', width: 150 })
			list.push({ key: 'createDatetime', name: '문의 일시', width: 150 })
			return list
		},
		noData() {
			let obj = {}
			obj.image = require('@/assets/svg/content/no-inquiry.svg')
			obj.text = '작성한 문의 내용이 없습니다'
			obj.btnText = '문의 작성하기'
			return obj
		},
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchInquiryList()
			},
			immediate: true,
		},
	},
	mounted() {
		this.$nextTick(() => {
			if (!isLogin()) this.goMain()
		})
	},
	methods: {
		...mapActions('inquiry', ['lookupInquiryList']),
		async fetchInquiryList() {
			await this.lookupInquiryList({
				instituteId: this.instituteId,
				params: {
					offset: this.offset,
					length: this.length,
					keyword: this.searchKeyword,
					startDatetime: this.searchDate.length > 0 ? this.searchDate[0] : '',
					endDatetime: this.searchDate.length > 0 ? this.searchDate[1] : '',
				},
			})

			this.list = this.$_.cloneDeep(this.inquiryList).map(item => {
				item.status = { class: this.setClass(item.status), text: item.status }
				item.type = item.type.name
				item.writer = item.user.name
				item.createDatetime = this.datetimeToString(item.createDatetime)
				return item
			})
		},
		setClass(status) {
			return Inquiry.statusToClass(status)
		},
		searchTextList(filter) {
			if (this.$_.isEmpty(filter)) {
				this.searchKeyword = ''
				this.searchDate = []
			} else {
				Object.entries(filter).forEach(([key, value]) => {
					if (key === '검색') this.searchKeyword = value.length > 0 ? value[0].value : ''
					else if (key === '기간 조회') this.searchDate = value.length > 0 ? value[0].value : []
				})
			}
			this.fetchInquiryList()
		},
		goWrite() {
			this.$router.push({
				name: PageName.CsCenter.InquiryEdit,
				params: {
					mode: 'write',
				},
			})
		},
		goDetail(id) {
			this.$router.push({
				name: PageName.CsCenter.InquiryManagementDetail,
				params: {
					id: id,
				},
			})
		},
		getCurrentPage({ offset, length }) {
			this.offset = offset
			this.length = length
			this.fetchInquiryList()
		},
		goMain() {
			this.$router
				.push({
					name: PageName.Main,
				})
				.catch(() => {})
		},
	},
}
</script>

<style lang="scss" scoped>
.InquiryManagement {
	margin-bottom: 64px;
	.content-wrapper {
		.common-table {
			margin-top: 48px;
		}
	}
}
</style>
