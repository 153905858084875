<template>
	<base-management title="공지사항">
		<detail-search-box
			ref="detailSearchBox"
			class="search-box"
			title="상세검색"
			subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
			searchPlaceholder="제목으로 검색"
			:datePlaceholder="datePlaceholder"
			:searchText="null"
			@searchTextList="searchTextList"
		/>
		<common-table :headers="headers" :list="list" :count="count" :isCheck="false" :noData="noData" @go="goDetail" @page="getCurrentPage" />
	</base-management>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import mixin from '@/mixins'
import PageName from '@/utils/define/PageName'
import BaseManagement from '@/views/cs-center/components/BaseManagement.vue'
import DetailSearchBox from '@/views/my-page/order-delivery/order/components/search/DetailSearchBox.vue'
import CommonTable from '@/components/table/CommonTable'

export default {
	name: 'NoticeManagement',
	components: {
		BaseManagement,
		DetailSearchBox,
		CommonTable,
	},
	mixins: [mixin],
	data() {
		return {
			headers: [
				{ key: 'title', name: '제목', width: 'auto' },
				{ key: 'startDatetime', name: '등록일', width: 150 },
			],
			noData: {
				image: require('@/assets/svg/content/no-inquiry.svg'),
				text: '등록된 공지사항이 없습니다',
			},
			offset: 0,
			length: 50,
			searchKeyword: '',
			searchDate: [],
			list: [],
		}
	},
	mounted() {
		this.fetchNoticeList()
	},
	computed: {
		...mapState('notice', ['noticeList', 'count']),
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
	},
	methods: {
		...mapActions('notice', ['lookupNoticeList']),
		async fetchNoticeList() {
			const params = {
				offset: this.offset,
				length: this.length,
				keyword: this.searchKeyword,
			}
			if (this.searchDate.length > 0) {
				params.startDatetime = this.searchDate[0]
				params.endDatetime = this.searchDate[1]
			} else {
				const date = new Date()
				const today = date.toISOString().substring(0, 10)
				const [year] = today.split('-')
				params.startDatetime = `${year}-01-01`
				params.endDatetime = today
			}

			await this.lookupNoticeList(params)

			this.list = this.$_.cloneDeep(this.noticeList).map(item => {
				item.startDatetime = this.formatDate(item.startDatetime)
				return item
			})
		},
		searchTextList(filter) {
			if (this.$_.isEmpty(filter)) {
				this.searchKeyword = ''
				this.searchDate = []
			} else {
				Object.entries(filter).forEach(([key, value]) => {
					if (key === '검색') this.searchKeyword = value.length > 0 ? value[0].value : ''
					else if (key === '기간 조회') this.searchDate = value.length > 0 ? value[0].value : []
				})

				if (this.searchDate.length > 0) {
					const startDate = new Date(this.searchDate[0])
					const endDate = new Date(this.searchDate[1])
					startDate.setFullYear(startDate.getFullYear() + 1)
					if (startDate < endDate) {
						this.searchDate = []
						this.$root.toast('기간 조회', '한 번에 최대 1년동안의 주문만 조회 가능합니다.', 'error')
						return
					}
				}
			}
			this.fetchNoticeList()
		},
		goDetail(id) {
			this.$router.push({
				name: PageName.CsCenter.NoticeManagementDetail,
				params: {
					id: id,
				},
			})
		},
		getCurrentPage({ offset, length }) {
			this.offset = offset
			this.length = length
			this.fetchNoticeList()
		},
	},
}
</script>

<style lang="scss" scoped>
.search-box {
	margin-bottom: 40px;
}
</style>
