import { getAccountUrl } from '@/services/api/common'

const RoutePath = {
	sso: `${getAccountUrl}/SignIn?redirect=${window.location.origin}&pro=true`,
	Main: '/',
	Error: '/error',
	RequiredOrderAllList: '/requiredOrderAllList',
	FrequentlyOrderList: '/frequentlyOrderList',
	Detail: '/product/:category/detail/:productInfoId',
	Inquiry: '/inquiry',
	Service: '/service',
	Product: '/product/:category',
	ProductSale: '/product/:category/sale/:id',
	List: '/list/:category',
	Search: '/search/:category',
	SearchList: '/search',
	Cart: '/cart',
	Payment: '/payment',
	PaymentInicisCallback: '/payment/inicis/callback',
	PaymentComplete: '/payment/complete/:id',
	Provision: '/provision/:mode',
	MyPage: {
		Main: '/mypage',
		Order: 'order',
		OrderDetail: 'order/:id',
		Return: 'return',
		ReturnDetail: 'return/:id',
		UserInfo: 'user',
		ChangePassword: 'password',
		ChangePasswordComplete: 'password/complete',
		ShippingAddressManagement: 'address',
		BusinessRegistrationManagement: 'businessregistration',
		EasyPaymentManagement: 'easypayment',
		EstimateManagement: 'estimate',
		EstimateDetail: 'estimate/:id',
		RequestProductList: 'request',
		RequestProductDetail: 'request/:id',
		PaymentHistory: 'payment',
		PaymentHistoryDetail: 'payment/:id',
		CompletePayment: 'payment/complete/:mode',
		UnPaymentManagement: 'unpayment',
		UnPaymentManagementDetail: 'unpayment/pay',
		LabInfoManagement: 'lab',
		LabInfoEdit: 'lab/edit',
		Wish: 'wish',
		RecentView: 'recentview',
	},
	CsCenter: {
		Main: '/cscenter',
		InquiryManagement: 'inquiry',
		InquiryManagementDetail: 'inquiry/detail/:id',
		InquiryEdit: 'inquiry/:mode',
		NoticeManagement: 'notice',
		NoticeManagementDetail: 'notice/:id',
		EventManagement: 'event',
		EventManagementDetail: 'event/:id',
		QuestionManagement: 'question',
	},
	Sadadream: {
		Main: '/sadadream',
		Detail: '/sadadream/:id',
		OtherDetail: '/sadadream/other/:id',
	},
	EstimateSheet: '/estimate/sheet/:id',
	EstimateExport: '/estimate/export',
	StartStore: '/startStore',
	Invitation: '/invitation',
}

export default RoutePath
