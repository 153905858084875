import instituteAPI from '@/services/api/institute'

const institute = {
	namespaced: true,
	state: {
		institute: {},
		requirement: {},
	},
	mutations: {
		setInstitute: (state, payload) => {
			state.institute = payload
		},
		setRequirement: (state, payload) => {
			state.requirement = payload
		},
	},
	actions: {
		lookupInstitute: async ({ commit }, instituteId) => {
			const response = await instituteAPI.lookupInstitute(instituteId)
			commit('setInstitute', response.data)
		},
		createInstitute: async ({ commit }, body) => {
			return await instituteAPI.createInstitute(body)
		},
		updateInstitute: async (_, { instituteId, body }) => {
			const response = await instituteAPI.updateInstitute(instituteId, body)
			return response
		},
		lookupRequirement: async ({ commit }, instituteId) => {
			const response = await instituteAPI.lookupRequirement(instituteId)
			commit('setRequirement', response.data)
		},
	},
}

export default institute
