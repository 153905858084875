<template>
	<base-management title="이벤트">
		<detail-search-box
			ref="detailSearchBox"
			class="search-box"
			title="상세검색"
			subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
			searchPlaceholder="제목으로 검색"
			:datePlaceholder="datePlaceholder"
			:searchText="null"
			:isSearchKeyword="false"
			@searchTextList="searchTextList"
		/>
		<template v-if="eventList.length > 0">
			<div class="event-management-list">
				<img v-for="event of eventList" :key="event.id" class="event-management-item" :src="event.imageUrl" @click="goDetail(event.id)" />
			</div>
			<common-pagenation class="common-pagenation" ref="common-pagenation" :total="count" :isPerPage="false" @page="getCurrentPage" />
		</template>
		<template v-else>
			<div class="empty-list">
				<img src="@/assets/svg/content/no-inquiry.svg" />
				<p>진행중인 이벤트가 없습니다.</p>
			</div>
		</template>
	</base-management>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PageName from '@/utils/define/PageName'
import BaseManagement from '@/views/cs-center/components/BaseManagement.vue'
import DetailSearchBox from '@/views/my-page/order-delivery/order/components/search/DetailSearchBox.vue'
import CommonPagenation from '@/components/pagenation/CommonPagenation.vue'

export default {
	name: 'EventManagement',
	components: {
		BaseManagement,
		DetailSearchBox,
		CommonPagenation,
	},
	data() {
		return {
			offset: 0,
			length: 50,
			searchDate: [],
		}
	},
	computed: {
		...mapState('event', ['eventList', 'count']),
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
	},
	mounted() {
		this.fetchEventList()
	},
	methods: {
		...mapActions('event', ['lookupEventList']),
		async fetchEventList() {
			const params = {
				offset: this.offset,
				length: this.length,
			}
			if (this.searchDate.length > 0) {
				params.startDatetime = this.searchDate[0]
				params.endDatetime = this.searchDate[1]
			} else {
				const date = new Date()
				const today = date.toISOString().substring(0, 10)
				const [year] = today.split('-')
				params.startDatetime = `${year}-01-01`
				params.endDatetime = today
			}

			await this.lookupEventList(params)
		},
		searchTextList(filter) {
			if (this.$_.isEmpty(filter)) {
				this.searchDate = []
			} else {
				Object.entries(filter).forEach(([key, value]) => {
					if (key === '기간 조회') this.searchDate = value.length > 0 ? value[0].value : []
				})

				if (this.searchDate.length > 0) {
					const startDate = new Date(this.searchDate[0])
					const endDate = new Date(this.searchDate[1])
					startDate.setFullYear(startDate.getFullYear() + 1)
					if (startDate < endDate) {
						this.searchDate = []
						this.$root.toast('기간 조회', '한 번에 최대 1년동안의 주문만 조회 가능합니다.', 'error')
						return
					}
				}
			}
			this.fetchEventList()
		},
		goDetail(id) {
			this.$router.push({
				name: PageName.CsCenter.EventManagementDetail,
				params: {
					id: id,
				},
			})
		},
		getCurrentPage({ offset, length }) {
			this.offset = offset
			this.length = length
			this.fetchEventList()
		},
	},
}
</script>

<style lang="scss" scoped>
.search-box {
	margin-bottom: 40px;
}
.event-management-list {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 81px;
	.event-management-item {
		height: 112px;
		border-radius: 5px;
		cursor: pointer;
	}
}
.empty-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	gap: 24px;
	> p {
		font-size: 1rem;
		font-weight: 500;
		color: $COLOR_999;
	}
}
</style>
