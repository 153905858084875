import { COOKIES_DOMAIN } from '@/services/api/common'
import userAPI from '@/services/api/user'
import _ from 'lodash'

const User = {
	namespaced: true,
	state: () => ({
		user: {
			name: undefined,
			id: undefined,
			phone: undefined,
			email: undefined,
			type: undefined,
			image: undefined,
			snsId: undefined,
			isMaster: undefined,
			isPush: undefined,
			language: undefined,
			isCertificated: undefined,
			isLegacyUser: undefined,
			unreadNotificationCount: undefined,
		},
		point: 0,
		myPageInfo: {},
		institute: {},
		institutes: [],
	}),
	getters: {
		instituteId: state => (!_.isEmpty(state.institute) ? state.institute.id : null),
		proInstituteId: state => (!_.isEmpty(state.institute) ? state.institute.proInstituteId : null),
		instituteName: state => (!_.isEmpty(state.institute) ? state.institute.name : null),
		userInfo: state => state.user,
	},
	mutations: {
		setUser: (state, payload) => {
			state.user = payload
		},
		setMyPageInfo: (state, payload) => {
			state.myPageInfo = payload
		},
		setInstitute: (state, payload) => {
			state.institute = payload
		},
		setInstitutes: (state, payload) => {
			state.institutes = payload
		},
		setPoint: (state, point) => {
			state.point = point
		},
	},
	actions: {
		login: async (_, deviceToken) => {
			return await userAPI.login({ deviceToken })
		},
		logout: async () => {
			return await userAPI.logout()
		},
		getUserInfo: async ({ commit, dispatch }) => {
			const response = await userAPI.getUserInfo()
			commit('setUser', { ...response.data })
			// await dispatch('getPoint')
			return response
		},
		getInstitute: async ({ commit }) => {
			const response = await userAPI.getInstitute()
			commit('setInstitutes', response.data)
			return response
		},
		updateUser: async (_, formData) => {
			return await userAPI.updateUser(formData)
		},
		changePassword: async (_, body) => {
			return await userAPI.changePassword(body)
		},
		checkPassword: async (_, body) => {
			const response = await userAPI.checkPassword(body)
			return response.data
		},
		verification: async (_, body) => {
			const response = await userAPI.verification(body)
			return response.data
		},
		access: async (_, body) => {
			return await userAPI.access(body)
		},
		getPoint: async ({ commit }) => {
			const response = await userAPI.point()
			commit('setPoint', response?.data?.point ?? 0)
		},
		getPointLog: async ({ dispatch }, { offset, length }) => {
			await dispatch('getPoint')
			const response = await userAPI.pointLog({ offset, length })
			return (
				response?.data ?? {
					count: 0,
					list: [],
				}
			)
		},
		usePoint: async ({ dispatch }, body) => {
			const response = await userAPI.usePoint(body)
			await dispatch('getPoint')
			return response
		},
	},
}

export default User
