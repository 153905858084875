<template>
	<div class="wrapper">
		<div class="top-wrapper">
			<div class="left-contents">
				<b class="title">주문이 필요한 물품</b>
				<p>랩매니저 Pro에서 관리하는 물품 중 유효기간이 지나거나, 설정한 안전재고량 이하로 보유하거나, 보유량이 없는 물품입니다.</p>
			</div>
			<div class="right-contents">
				<button>랩매니저 바로가기</button>
			</div>
		</div>
		<div class="contents-wrapper">
			<required-product
				v-for="(product, index) in list.products"
				:key="`product_${index}`"
				:product="product"
				:index="index"
				@showModalDelete="showModalDelete"
			/>
		</div>
		<modal-delete-required-product @ok="deleteRequiredProduct" />
	</div>
</template>

<script>
import RequiredProduct from '@/views/main/components/RequiredProduct.vue'
import ModalDeleteRequiredProduct from '@/views/main/modal/ModalDeleteRequiredProduct.vue'

export default {
	name: 'RequiredOrderList',
	components: {
		RequiredProduct,
		ModalDeleteRequiredProduct,
	},
	data() {
		return {
			list: {
				products: [
					{
						title: 'Acephate-(acetyl-d3)',
						category: '화학물질',
						size: '25mg',
						image: require('@/assets/svg/main/chemical.svg'),
						expiredDate: 2,
						isUnderThreshold: true,
					},
					{
						title: 'Acephate-(acetyl-d3)',
						category: '화학물질',
						size: '25mg',
						image: require('@/assets/svg/main/chemical.svg'),
						expiredDate: 0,
						isUnderThreshold: false,
					},
					{
						title: 'Acephate-(acetyl-d3)',
						category: '화학물질',
						size: '25mg',
						image: require('@/assets/svg/main/chemical.svg'),
						expiredDate: 0,
						isUnderThreshold: false,
					},
					{
						title: 'Acephate-(acetyl-d3)',
						category: '화학물질',
						size: '25mg',
						image: require('@/assets/svg/main/chemical.svg'),
						expiredDate: 2,
						isUnderThreshold: true,
					},
					{
						title: 'Acephate-(acetyl-d3)',
						category: '화학물질',
						size: '25mg',
						image: require('@/assets/svg/main/chemical.svg'),
						expiredDate: 0,
						isUnderThreshold: false,
					},
					{
						title: 'Acephate-(acetyl-d3)',
						category: '화학물질',
						size: '25mg',
						image: require('@/assets/svg/main/chemical.svg'),
						expiredDate: 0,
						isUnderThreshold: false,
					},
				],
			},
		}
	},
	methods: {
		showModalDelete(product) {
			this.$bvModal.show('ModalDeleteRequiredProduct')
		},
		deleteRequiredProduct() {},
	},
}
</script>

<style lang="scss" scoped>
.wrapper {
	.top-wrapper {
		height: 140px;
		padding-top: 30px;
		display: flex;
		justify-content: space-between;
		border-bottom: solid 1px $LINE_DIVIDER;
		.left-contents {
			.title {
				font-weight: 700;
				font-size: 27px;
			}
			p {
				font-weight: 400;
				font-size: 14px;
			}
		}
		.right-contents {
			margin: 40px 0 0 0;
			display: flex;
			button {
				font-weight: 700;
				font-size: 14px;
				width: 139px;
				height: 36px;
				background: $COMMON_BACKGROUND;
				border-radius: 4px;
			}
		}
	}
	.contents-wrapper {
		padding-top: 10px;
	}
}
</style>
