<template>
	<div class="cart-notice">
		<div class="title">유의사항</div>
		<div class="content">
			<ul>
				<!-- <li>장바구니에 담긴 상품은(옵션단위)는 최대 180일까지 보관됩니다.</li> -->
				<li>동일 연구실 소속이라면 장바구니에 담긴 상품이 연동됩니다. 다만 상품 연동에 약간의 지연이 있을 수 있습니다.</li>
				<li>장바구니 내의 상품 가격은 판매사의 사정에 의해 언제든 변동될 수 있습니다.</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CartNotice',
}
</script>

<style lang="scss" scoped>
.cart-notice {
	.title {
		font-weight: 700;
	}
	.content {
		margin-top: 15px;
		height: 85px;
		display: flex;
		align-items: center;
		font-size: 0.875rem;
		font-weight: 500;
		background-color: $LIST_BACKGROUND;
		padding: 20px;
		ul {
			li:before {
				content: '•';
				font-size: 15px;
				vertical-align: bottom;
				padding-right: 7px;
			}
		}
	}
}
</style>
