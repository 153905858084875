import axiosInstance from '@/services/axios'

const endPoint = {
	lookupSellerList: () => `seller/all`,
	lookupPopularSellerList: (offset, length) => `seller/popular?offset=${offset}&length=${length}`,
	lookupSeller: id => `seller?sellerId=${id}`,
}

const sellerAPI = {
	lookupSellerList: () => axiosInstance.get(endPoint.lookupSellerList()),
	lookupPopularSellerList: (offset, length) => axiosInstance.get(endPoint.lookupPopularSellerList(offset, length)),
	lookupSeller: id => axiosInstance.get(endPoint.lookupSeller(id)),
}

export default sellerAPI
