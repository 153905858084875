<template>
	<div class="switch-box">
		<b>{{ title }}</b>
		<label class="switch">
			<input type="checkbox" v-model="value" @change="changeValue" />
			<span class="slider round"></span>
		</label>
	</div>
</template>

<script>
export default {
	name: 'SwitchBox',
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			value: false,
		}
	},
	methods: {
		changeValue() {
			this.$emit('change', this.value)
		},
	},
}
</script>

<style lang="scss" scoped>
.switch-box {
	display: inline-flex;
	align-items: center;
	b {
		font-size: 0.875rem;
		font-weight: 700;
		margin-right: 10px;
	}
}
</style>
