<template>
	<div class="cart-product-total-price">
		<div class="title">{{ '주문 금액 합계:' }}</div>
		<div class="label">{{ '소비자가 합계' }}</div>
		<div class="value">{{ `${CommaNum(orderTotal.totalPrice)}원` }}</div>
		<template v-if="orderTotal.totalDiscountPrice > 0">
			<img src="@/assets/svg/cart/minus.svg" />
			<div class="label">{{ '할인금액' }}</div>
			<div class="value red">{{ `${CommaNum(orderTotal.totalDiscountPrice)}원` }}</div>
		</template>
		<img src="@/assets/svg/cart/plus.svg" />
		<div class="label">{{ '배송비' }}</div>
		<div class="value">{{ `${CommaNum(totalShippingPrice)}원` }}</div>
		<img src="@/assets/svg/cart/plus.svg" />
		<div class="label">{{ 'VAT(10%)' }}</div>
		<div class="value">{{ `${CommaNum(orderTotal.totalVat)}원` }}</div>
		<img src="@/assets/svg/cart/equal.svg" />
		<div class="value lg bold">{{ `${CommaNum(orderTotal.totalSellingPriceWithVat)}원` }}</div>
	</div>
</template>

<script>
import _ from 'lodash'
import mixin from '@/mixins'

export default {
	name: 'TotalPrice',
	props: {
		orderTotal: {
			type: Object,
		},
		totalShippingPrice: {
			type: Number,
			default: 0,
		},
	},
	mixins: [mixin],
}
</script>
<style lang="scss" scoped>
.cart-product-total-price {
	width: 100%;
	height: 87px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $LIST_BACKGROUND;
	gap: 10px;
	.title {
		font-weight: 700;
		margin-right: 5px;
	}
	.label {
		color: $COLOR_666;
	}
	.value {
		&.red {
			color: #ff5746;
		}
		&.lg {
			font-size: 1.25rem;
		}
		&.bold {
			font-weight: 700;
		}
	}
}
</style>
