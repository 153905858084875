<template>
	<div class="search-item-title">
		<search-item-info :info="item" :mode="mode" />
		<search-item-box :placeholder="placeholder" @search="search" />
	</div>
</template>

<script>
import SearchItemInfo from '@/views/search/components/SearchItemInfo.vue'
import SearchItemBox from '@/views/search/components/SearchItemBox.vue'

export default {
	name: 'SearchItemTitle',
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		placeholder: {
			type: String,
			default: '',
		},
		mode: {
			type: String,
			default: '',
		},
	},
	components: {
		SearchItemInfo,
		SearchItemBox,
	},
	methods: {
		search(value) {
			this.$emit('search', value)
		},
	},
}
</script>

<style lang="scss" scoped>
.search-item-title {
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	.search-item-box {
		margin-left: auto;
	}
}
</style>
