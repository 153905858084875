export default {
	main: [
		{
			id: 1,
			name: '화학 물질',
			classification: 'CAT_CHEMICAL',
			image: require('@/assets/images/category-chemical.png'),
		},
		{
			id: 2,
			name: '바이오 물질',
			classification: 'CAT_BIO',
			image: require('@/assets/images/category-bio.png'),
		},
		{
			id: 3,
			name: '분석용 칼럼',
			classification: 'CAT_CHROMATOGRAPHY',
			image: require('@/assets/images/category-chromatography.png'),
		},
		{
			id: 4,
			name: '연구 장비',
			classification: 'CAT_LABWARE',
			image: require('@/assets/images/category-labware.png'),
		},
		{
			id: 5,
			name: '실험용품',
			classification: 'CAT_ITEM',
			image: require('@/assets/images/category-item.png'),
		},
		{
			id: 6,
			name: '보호구',
			classification: 'CAT_PROTECTION',
			image: require('@/assets/images/category-protection.png'),
		},
		{
			id: 7,
			name: '소모품',
			classification: 'CAT_ETC',
			image: require('@/assets/images/category-etc.png'),
		},
	],
	classification: [
		{
			name: '화학 물질',
			classification: 'CAT_CHEMICAL',
			image: require('@/assets/svg/category-chemical.svg'),
		},
		{
			name: '바이오 물질',
			classification: 'CAT_BIO',
			image: require('@/assets/svg/category-bio.svg'),
		},
		{
			name: '분석용 칼럼',
			classification: 'CAT_CHROMATOGRAPHY',
			image: require('@/assets/svg/category-chromatography.svg'),
		},
		{
			name: '연구 장비',
			classification: 'CAT_LABWARE',
			image: require('@/assets/svg/category-labware.svg'),
		},
		{
			name: '실험용품',
			classification: 'CAT_ITEM',
			image: require('@/assets/svg/category-item.svg'),
		},
		{
			name: '보호구',
			classification: 'CAT_PROTECTION',
			image: require('@/assets/svg/category-protection.svg'),
		},
		{
			name: '소모품',
			classification: 'CAT_ETC',
			image: require('@/assets/svg/category-etc.svg'),
		},
	],
	detailCategory: [
		{
			name: '시약',
			classification: 'CAT_CHEMICAL',
			category: 'CAT_CHEMICAL_REAGENT',
		},
		{
			name: '가스',
			classification: 'CAT_CHEMICAL',
			category: 'CAT_CHEMICAL_GAS',
		},
		{
			name: '바이오시약',
			classification: 'CAT_BIO',
			category: 'CAT_BIO_PCR_NGS',
		},
		{
			name: '항체 및 세럼',
			classification: 'CAT_BIO',
			category: 'CAT_BIO_ANTIBODY_CERUM',
		},
		{
			name: '세포주',
			classification: 'CAT_BIO',
			category: 'CAT_BIO_CELL_LINE',
		},
		{
			name: '분석용 칼럼 1-1',
			classification: 'CAT_CHROMATOGRAPHY',
			category: 'CAT_CHROMATOGRAPHY1-1',
		},
		{
			name: '분석용 칼럼 1-2',
			classification: 'CAT_CHROMATOGRAPHY',
			category: 'CAT_CHROMATOGRAPHY1-2',
		},
		{
			name: '분석용 칼럼 1-3',
			classification: 'CAT_CHROMATOGRAPHY',
			category: 'CAT_CHROMATOGRAPHY1-3',
		},
		{
			name: '분석용 칼럼 1-4',
			classification: 'CAT_CHROMATOGRAPHY',
			category: 'CAT_CHROMATOGRAPHY1-4',
		},
		{
			name: '분석용 칼럼 1-5',
			classification: 'CAT_CHROMATOGRAPHY',
			category: 'CAT_CHROMATOGRAPHY1-5',
		},
		{
			name: '분석용 칼럼 1-6',
			classification: 'CAT_CHROMATOGRAPHY',
			category: 'CAT_CHROMATOGRAPHY1-6',
		},
		{
			name: '분석용 칼럼 1-7',
			classification: 'CAT_CHROMATOGRAPHY',
			category: 'CAT_CHROMATOGRAPHY1-7',
		},
		{
			name: '분석용 칼럼 1-8',
			classification: 'CAT_CHROMATOGRAPHY',
			category: 'CAT_CHROMATOGRAPHY1-8',
		},
		{
			name: '분석용 칼럼 1-9',
			classification: 'CAT_CHROMATOGRAPHY',
			category: 'CAT_CHEMICAL1-9',
		},
		{
			name: '연구 장비 1-1',
			classification: 'CAT_LABWARE',
			category: 'CAT_LABWARE1-1',
		},
		{
			name: '연구 장비 1-2',
			classification: 'CAT_LABWARE',
			category: 'CAT_LABWARE1-2',
		},
		{
			name: '연구 장비 1-3',
			classification: 'CAT_LABWARE',
			category: 'CAT_LABWARE1-3',
		},
		{
			name: '실험용품 1-1',
			classification: 'CAT_ITEM',
			category: 'CAT_ITEM1-1',
		},
		{
			name: '실험용품 1-2',
			classification: 'CAT_ITEM',
			category: 'CAT_ITEM1-2',
		},
		{
			name: '실험용품 1-3',
			classification: 'CAT_ITEM',
			category: 'CAT_ITEM1-3',
		},
		{
			name: '소모품 1-1',
			classification: 'CAT_ETC',
			category: 'CAT_ETC1-1',
		},
		{
			name: '소모품 1-2',
			classification: 'CAT_ETC',
			category: 'CAT_ETC1-2',
		},
		{
			name: '소모품 1-3',
			classification: 'CAT_ETC',
			category: 'CAT_ETC1-3',
		},
	],
	mainImg: {
		CAT_CHEMICAL: require('@/assets/images/category-chemical.png'),
		CAT_BIO: require('@/assets/images/category-bio.png'),
		CAT_CHROMATOGRAPHY: require('@/assets/images/category-chromatography.png'),
		CAT_LABWARE: require('@/assets/images/category-labware.png'),
		CAT_LABETC: require('@/assets/images/category-item.png'),
		CAT_PROTECTION: require('@/assets/images/category-protection.png'),
		CAT_ETC: require('@/assets/images/category-etc.png'),
	},
	menuImg: {
		CAT_CHEMICAL: require('@/assets/svg/category-chemical.svg'),
		CAT_BIO: require('@/assets/svg/category-bio.svg'),
		CAT_CHROMATOGRAPHY: require('@/assets/svg/category-chromatography.svg'),
		CAT_LABWARE: require('@/assets/svg/category-labware.svg'),
		CAT_LABETC: require('@/assets/svg/category-item.svg'),
		CAT_PROTECTION: require('@/assets/svg/category-protection.svg'),
		CAT_ETC: require('@/assets/svg/category-etc.svg'),
	},
}
