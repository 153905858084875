<template>
	<div class="filter-detail-box">
		<div class="header">
			<b>{{ title }}</b>
			<button @click="isOpen = !isOpen" :class="isOpen ? 'minus' : 'plus'" v-if="clientHeight >= 78"></button>
		</div>
		<div class="content">
			<category-filter-list
				v-if="type == 'category'"
				:list="list"
				:filtered="filterList"
				:selected="selected"
				:isClassification="isClassification"
				@selectCategory="selectCategory"
				@checkCategory="checkCategory"
			/>
			<!--체크박스 필터 리스트-->
			<checkbox-list
				ref="checkbox-list"
				v-else-if="list.length > 0"
				:class="({ filtered: filterList.length > 0 }, { isScroll: clientHeight >= 78 })"
				:list="list"
				:filtered="filterList"
				:type="type"
				:isOpen="isOpen"
				@checkedList="checkedList"
			/>
		</div>
	</div>
</template>

<script>
import CategoryFilterList from '@/components/search/CategoryFilterList.vue'
import CheckboxList from '@/components/search/CheckboxList.vue'

export default {
	name: 'FilterDetailBox',
	props: {
		title: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => {},
		},
		//필터로 checked 된 리스트
		filtered: {
			type: Array,
			default: () => [],
		},
		//선택된 카테고리 / 분류
		selected: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		CheckboxList,
		CategoryFilterList,
	},
	computed: {
		filterList() {
			return this.filtered
		},
	},
	mounted() {
		this.$nextTick(() => {
			if (this.$refs['checkbox-list']) {
				this.clientHeight = this.$refs['checkbox-list'].$el.firstChild.clientHeight
			}
		})
	},
	data() {
		return {
			isOpen: false,
			clientHeight: 0,
			isClassification: false,
		}
	},
	methods: {
		//checked list emit
		checkedList(list) {
			this.$emit('checkedList', list, this.type)
		},
		selectCategory(item) {
			if (item) this.isClassification = true
			this.$emit('selectCategory', item)
		},
		checkCategory(list) {
			this.$emit('checkCategory', list)
		},
	},
}
</script>

<style lang="scss" scoped>
.filter-detail-box {
	width: 100%;
	display: flex;
	align-items: flex-start;
	border-bottom: 1px solid $COLOR_EEE;
	.header {
		width: 200px;
		font-size: 0.875rem;
		font-weight: 700;
		color: $COLOR_666;
		padding: 13px 12px 0;
		display: flex;
		align-items: flex-start;
		b {
			font-weight: 700;
		}
		button {
			margin-left: auto;
			width: 24px;
			height: 24px;
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center;
			border-radius: 4px;
			margin-top: -2px;
			// transition: all 0.3s;
			// &:hover {
			// 	background-color: $LIST_BACKGROUND;
			// }
			&.plus {
				background-image: url(~@/assets/svg/list-show.svg);
			}
			&.minus {
				background-image: url(~@/assets/svg/list-hide.svg);
			}
		}
	}
	.content {
		width: calc(100% - 200px);
		border-left: 1px solid $COLOR_EEE;
	}
}
</style>
