<template>
	<div class="simple-input-form-wrap">
		<div class="title" v-if="title">
			<h6>{{ title }}</h6>
			<div class="right-btn-group" v-if="!isCompleted">
				<button class="btn btn-common" v-if="!isEdit" @click="isEdit = true">수정</button>
				<button class="btn btn-common" v-if="isEdit" @click="cancel">취소</button>
				<button class="btn btn-complete" v-if="isEdit" @click="save">저장</button>
			</div>
		</div>
		<div class="input-box">
			<ul>
				<li v-for="(item, index) in form" :key="`form-input${index}`" :class="{ all: item.all }">
					<b>
						{{ item.name }}
						<span v-if="item.required" class="required">*</span>
					</b>
					<input type="text" class="form-input" v-model="item.value" :disabled="!isEdit || item.readonly" @input="$emit('input', item)" />
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SimpleInputForm',
	props: {
		title: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
		isCompleted: {
			type: Boolean,
			default: false,
		},
		isDirect: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isEdit: false,
			form: [],
		}
	},
	watch: {
		list: {
			handler(val) {
				this.init()
			},
			immediate: true,
			deep: true,
		},
	},
	methods: {
		init() {
			this.form = JSON.parse(JSON.stringify(this.list))
			if (this.isDirect) {
				this.isEdit = true
			}
		},
		async cancel() {
			this.isEdit = false
			await this.init()
		},
		async save() {
			await this.$emit('save', this.form)
			this.isEdit = false
		},
	},
}
</script>

<style lang="scss" scoped>
.simple-input-form-wrap {
	.title {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		h6 {
			font-size: 1.25rem;
			font-weight: 700;
		}
		.right-btn-group {
			margin-left: auto;
			button {
				margin-left: 10px;
			}
		}
	}
	.input-box {
		width: 100%;
		border-radius: 5px;
		border: 1px solid $LINE_DIVIDER;
		padding: 1rem 20px;
		ul {
			width: 100%;
			display: block;
			li {
				display: inline-flex;
				align-items: center;
				font-size: 0.875rem;
				font-weight: 500;
				width: 50%;
				padding: 8px 0;
				&.all {
					width: 100%;
					input[type='text'] {
						width: 320px;
					}
				}
				b {
					display: block;
					font-weight: inherit;
					width: 116px;
					span {
						margin-left: 0;
					}
				}
				input[type='text'] {
					width: calc(100% - 136px);
				}
			}
		}
	}
}
</style>
