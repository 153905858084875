<template>
	<div class="payment-recipient-info">
		<div class="info-item">
			<div class="info-title">
				<div class="title">수령자 정보</div>
			</div>
			<div class="info-content">
				<div>
					<div class="title">연락처</div>
					<div class="content">
						{{ order.recipient ? isData(convertTel(order.recipient.phone)) : '-' }}
					</div>
				</div>
				<div>
					<div class="title">이름</div>
					<div class="content">
						{{ order.recipient ? isData(order.recipient.name) : '-' }}
					</div>
				</div>
				<div>
					<div class="title">수령지 주소</div>
					<div class="content">
						{{ isData(address) }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import mixin from '@/mixins'

export default {
	name: 'PaymentCompleteRecipientInfo',
	mixins: [mixin],
	computed: {
		...mapState('order', ['order']),
		address() {
			if (this.$_.isEmpty(this.order)) return ''
			const { postcode, address, addressDetail } = this.order
			return `(${postcode}) ${address} ${addressDetail}`
		},
	},
	methods: {
		convertTel(value) {
			if (!value) return
			return value.replace(/\D+/g, '').replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3')
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-recipient-info {
	display: flex;
	flex-direction: column;
	.info-item {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.info-title {
			display: flex;
			.title {
				font-weight: bold;
				font-size: 1.25rem;
			}
		}
		.info-content {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 20px;
			border: 1px solid $COLOR_DDD;
			border-radius: 26px;
			> div {
				display: flex;
				align-items: center;
				> div {
					font-size: 0.875rem;
					font-weight: 500;
				}
				.title {
					width: 124px;
				}
				.content {
					width: calc(100% - 124px);
					.text-input {
						width: 100%;
					}
				}
			}
		}
	}
}
</style>
