<template>
	<div class="PaymentHistory">
		<content-title title="결제 내역" />
		<div class="content-wrapper">
			<content-banner class="unpayment" :hasNopaidPrice="myPageInfo.noDepositPrice > 0" :leftImg="banner.leftImg" :text="banner.text" />
			<div class="noPaid-explain-box">
				<p>• 주문 시점으로 부터 익월 말일까지 결제가 되지않을 경우 결제 기한이 초과되어 더 이상 물품을 구매할 수 없습니다.</p>
			</div>
			<detail-search-box
				class="search-box"
				title="상세검색"
				subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
				searchPlaceholder="결제번호로 검색"
				searchText="결제요청일"
				:datePlaceholder="datePlaceholder"
				@searchTextList="searchTextList"
			/>
			<common-table
				title="결제 내역"
				:count="count"
				:headers="paymentHistoryHeaders"
				:list="list"
				:isCheck="false"
				:noData="noData"
				@go="goDetail"
				@page="getCurrentPage"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import mixin from '@/mixins'
import date from '@/mixins/date'
import PageName from '@/utils/define/PageName'
import { Payment } from '@/utils/define/ItemCode'
import ContentTitle from '@/components/content/ContentTitle'
import ContentBanner from '@/components/content/ContentBanner'
import DetailSearchBox from '@/views/my-page/order-delivery/order/components/search/DetailSearchBox'
import CommonTable from '@/components/table/CommonTable'

export default {
	name: 'PaymentHistory',
	components: {
		ContentTitle,
		ContentBanner,
		DetailSearchBox,
		CommonTable,
	},
	mixins: [mixin, date],
	data() {
		return {
			offset: 0,
			length: 50,
			searchKeyword: '',
			searchDate: [],
			list: [],
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('payment', ['payments', 'count']),
		...mapState('user', ['myPageInfo']),
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
		paymentHistoryHeaders() {
			let list = []
			list.push({ key: 'status', name: '상태', width: 100 })
			list.push({ key: 'no', name: '결제번호', width: 150 })
			list.push({ key: 'orderItemName', name: '상품정보', width: 300 })
			list.push({ key: 'paymentPriceWithVat', name: '결제 금액 합계', width: 150 })
			list.push({ key: 'method', name: '결제 방식', width: 130 })
			list.push({ key: 'createDatetime', name: '결제 요청일', width: 100 })
			return list
		},
		noData() {
			let obj = {}
			obj.image = require('@/assets/svg/content/no-estimate.svg')
			obj.text = '결제한 내역이 없습니다'
			return obj
		},
		banner() {
			return {
				leftImg: require('@/assets/svg/content/left-unpayment-image.svg'),
				text: this.noDepositPriceText,
			}
		},
		noDepositPriceText() {
			if (this.myPageInfo.noDepositPriceWithVat > 0)
				return `총 미입금 금액은 <b style="color: #FF5746;">${this.CommaNum(this.myPageInfo.noDepositPriceWithVat)}</b> 원입니다`
			else return '미입금 금액이 없습니다.'
		},
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchPaymentList()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('payment', ['lookupPaymentList']),
		async fetchPaymentList() {
			await this.lookupPaymentList({
				instituteId: this.instituteId,
				params: {
					offset: this.offset,
					length: this.length,
					keyword: this.searchKeyword,
					startDatetime: this.searchDate.length > 0 ? this.searchDate[0] : '',
					endDatetime: this.searchDate.length > 0 ? this.searchDate[1] : '',
				},
			})

			this.list = this.$_.cloneDeep(this.payments).map(item => {
				item.status = { class: this.setClass(item.status), text: this.setStatusText(item.status) }
				item.method = this.setMethodText(item.method)
				item.paymentPriceWithVat = `${this.CommaNum(item.paymentPriceWithVat)}원`
				item.createDatetime = this.dateToString(item.createDatetime)
				return item
			})
		},
		setClass(status) {
			return Payment.statusToClass(status)
		},
		setStatusText(status) {
			return Payment.statusToString(status)
		},
		setMethodText(method) {
			return Payment.methodToString(method)
		},
		searchTextList(filter) {
			if (this.$_.isEmpty(filter)) {
				this.searchKeyword = ''
				this.searchDate = []
			} else {
				Object.entries(filter).forEach(([key, value]) => {
					if (key === '검색') this.searchKeyword = value.length > 0 ? value[0].value : ''
					else if (key === '기간 조회') this.searchDate = value.length > 0 ? value[0].value : []
				})
			}
			this.fetchPaymentList()
		},
		getCurrentPage({ offset, length }) {
			this.offset = offset
			this.length = length
			this.fetchPaymentList()
		},
		goDetail(id) {
			this.$router.push({
				name: PageName.MyPage.PaymentHistoryDetail,
				params: {
					id: id,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.detail-search-box {
	margin-top: 60px;
}
.PaymentHistory {
	.common-table {
		margin-top: 48px;
	}
}
.noPaid-explain-box {
	background-color: #fef9ec;
	font-size: 15px;
	color: #333;
	padding: 16px;
	margin-top: 10px;
	border-radius: 12px;
}
</style>
