<template>
	<div class="payment-recipient-info">
		<div class="info-item">
			<div class="info-title">
				<div class="title">수령자 정보</div>
				<div class="checkbox-wrapper">
					<input type="checkbox" v-model="isCheck" id="paymentRecipientInfoCheckbox" class="common" />
					<label for="paymentRecipientInfoCheckbox">주문자 정보와 동일</label>
				</div>
			</div>
			<div class="info-content">
				<div>
					<div class="title">이름<span class="required">*</span></div>
					<div class="content">
						<text-input ref="name" v-model="name" :inputStyle="{ width: '320px' }" @input="onInput"></text-input>
					</div>
				</div>
				<div>
					<div class="title">연락처<span class="required">*</span></div>
					<div class="content">
						<text-input ref="phone" v-model="phone" :inputStyle="{ width: '320px' }" @input="onInput"></text-input>
					</div>
				</div>
				<div>
					<div class="title">수령지 주소</div>
					<div class="content">
						{{ address }}
					</div>
				</div>
				<div>
					<div class="title">배송요청사항</div>
					<div class="content">
						<text-input
							v-model="requestedTerm"
							placeholder="최대 50자 입력이 가능합니다."
							:inputStyle="{ width: '100%' }"
							class="text-input"
						></text-input>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TextInput from '@/components/input/TextInput.vue'
import { mapState } from 'vuex'
import mixin from '@/mixins'
import cookies from 'vue-cookies'

export default {
	name: 'PaymentRecipientInfo',
	props: {
		ordererPhone: {
			type: String,
			default: '',
		},
	},
	components: {
		TextInput,
	},
	mixins: [mixin],
	data() {
		return {
			name: '',
			phone: '',
			requestedTerm: '',
			isCheck: true,
			isValid: true,
		}
	},
	watch: {
		isCheck: {
			handler(newVal) {
				if (newVal === true) {
					this.name = this.user.name
					this.phone = this.ordererPhone
				}
			},
			immediate: true,
		},
		user: {
			handler() {
				if (this.isCheck) {
					this.name = this.user.name
					this.phone = this.user.phone
				}
			},
			immediate: true,
		},
		ordererPhone: {
			handler(newVal) {
				if (this.phone !== newVal) {
					this.isCheck = false
				}
			},
		},
	},
	computed: {
		...mapState('user', ['user']),
		...mapState('delivery', ['basicAddress']),
		address() {
			if (this.$_.isEmpty(this.currentAddress)) return ''
			const { postcode, address, addressDetail } = this.currentAddress
			return `[${postcode}] ${address} ${addressDetail}`
		},
		currentAddress() {
			return cookies.get('currentAddress2') ?? this.basicAddress
		},
	},
	methods: {
		getRecipient() {
			return {
				name: this.name,
				phone: this.phone,
				requestedTerm: this.requestedTerm,
			}
		},
		validate() {
			this.isValid = true
			if (!this.$refs['name'].validate()) {
				this.isValid = false
				this.$refs['name'].focus()
			}
			if (!this.$refs['phone'].validate()) {
				this.isValid = false
				this.$refs['phone'].focus()
			}
			return this.isValid
		},
		onInput() {
			if (this.user.name !== this.name) {
				this.isCheck = false
			}
			if (this.phone !== this.ordererPhone) {
				this.isCheck = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-recipient-info {
	display: flex;
	flex-direction: column;
	.info-item {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.info-title {
			display: flex;
			.title {
				font-weight: bold;
				font-size: 1.25rem;
			}
			.checkbox-wrapper {
				display: flex;
				align-items: center;
				margin-left: 15px;
				label {
					font-size: 0.875rem;
					font-weight: 700;
					cursor: pointer;
				}
			}
		}
		.info-content {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 20px;
			border: 1px solid $COLOR_DDD;
			border-radius: 5px;
			> div {
				height: 40px;
				display: flex;
				align-items: center;
				> div {
					font-size: 0.875rem;
					font-weight: 500;
				}
				.title {
					width: 124px;
				}
				.content {
					width: calc(100% - 124px);
					.text-input {
						width: 100%;
					}
				}
			}
		}
	}
}
</style>
