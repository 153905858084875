<template>
	<div class="seller-list">
		<search-sort-tab
			v-if="tabList.length > 0"
			:tabList="tabList"
			@change="$emit('changeStock', $event)"
			@sortProduct="sortProduct"
			:isSwitch="true"
		/>
		<seller-table :list="list" />
	</div>
</template>

<script>
import SearchSortTab from '@/components/tab/SearchSortTab.vue'
import SellerTable from '@/views/product/components/SellerTable.vue'

export default {
	name: 'SellerList',
	props: {
		tabList: {
			type: Array,
			default: () => [],
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
	components: {
		SearchSortTab,
		SellerTable,
	},
	methods: {
		sortProduct(id) {
			this.$emit('sortProduct', id)
		},
	},
}
</script>
