<template>
	<base-management title="공지사항">
		<div class="notice-management-detail-wrapper">
			<div class="notice-management-detail-header">
				<h4>{{ notice.title }}</h4>
				<span>{{ formatDate(notice.startDatetime) }}</span>
			</div>
			<div class="notice-management-detail-content">
				<div v-if="notice.html" v-html="html"></div>
				<div>{{ notice.content }}</div>
			</div>
			<div class="notice-management-detail-footer">
				<button class="btn btn-gray lg" @click="$router.go(-1)">목록 보기</button>
			</div>
		</div>
	</base-management>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import mixin from '@/mixins/date'
import BaseManagement from '@/views/cs-center/components/BaseManagement.vue'

export default {
	name: 'NoticeManagementDetail',
	components: {
		BaseManagement,
	},
	mixins: [mixin],
	computed: {
		...mapState('notice', ['notice']),
		html() {
			return typeof this.notice.html == 'string' ? this.notice.html.replace(/\<p\>\<\/p\>/g, "<br>") : this.notice.html
		}
	},
	mounted() {
		this.fetchNotice()
	},
	methods: {
		...mapActions('notice', ['lookupNotice']),
		async fetchNotice() {
			await this.lookupNotice(this.$route.params.id)
		},
	},
}
</script>

<style lang="scss" scoped>
.notice-management-detail-wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	.notice-management-detail-header {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding-bottom: 16px;
		border-bottom: 1px solid $COLOR_EEE;
		> span {
			font-weight: 500;
			font-size: 0.875rem;
		}
	}
	.notice-management-detail-content {
		display: flex;
		flex-direction: column;
		gap: 40px;
		padding-bottom: 40px;
		border-bottom: 1px solid $COLOR_EEE;
	}
	.notice-management-detail-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 40px;
		.btn {
			width: 240px;
			height: 48px;
		}
	}
}
</style>
