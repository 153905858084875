export default {
	data() {
		return {}
	},
	methods: {
		setInfoList(category, item) {
			let list = []

			//화학물질 - 시약
			if (category == 'CAT_CHEMICAL_REAGENT') {
				list.push({ title: '농도(밀도)', value: item.density ? `${item.density}${item.densityUnit ?? ''}` : '' })
				list.push({ title: '분자량', value: item.molecularWeight ? `${item.molecularWeight}${item.molecularWeightUnit ?? ''}` : '' })
				list.push({ title: 'MOL', value: item.molarMass ? `${item.molarMass}${item.molarMassUnit ?? ''}` : '' })
				list.push({ title: '순도', value: item.assay ? `${item.assay}${item.assayUnit ?? ''}` : '' })
				list.push({
					title: '보관온도',
					value: item.storageTemperature ? `${item.storageTemperature}${item.storageTemperatureUnit ?? ''}` : '',
				})
				list.push({ title: '용해도', value: item.solubility })
				list.push({ title: '분자식', value: item.molecularFormula })
				list.push({ title: '발화점', value: item.flashPoint ? `${item.flashPoint}${item.flashPointUnit ?? ''}` : '' })
				list.push({ title: '끓는점', value: item.boilingPoint ? `${item.boilingPoint}${item.boilingPointUnit ?? ''}` : '' })
				list.push({ title: '녹는점', value: item.meltingPoint ? `${item.meltingPoint}${item.meltingPointUnit ?? ''}` : '' })
			}

			//화학물질 - 가스
			else if (category == 'CAT_CHEMICAL_GAS') {
				list.push({ title: '분자량', value: item.molecularWeight ? `${item.molecularWeight}${item.molecularWeightUnit ?? ''}` : '' })
				list.push({ title: '순도', value: item.assay ? `${item.assay}${item.assayUnit ?? ''}` : '' })
				list.push({ title: '분자식', value: item.molecularFormula })

				//바이오물질 - 바이오시약
			} else if (category == 'CAT_BIO_PCR_NGS') {
				list.push({ title: '규격', value: item.spec })
				list.push({ title: '형태', value: item.shape })
				list.push({ title: '농도(밀도)', value: item.density ? `${item.density}${item.densityUnit ?? ''}` : '' })
				list.push({ title: '순도', value: item.assay ? `${item.assay}${item.assayUnit ?? ''}` : '' })
				list.push({
					title: '보관온도',
					value: item.storageTemperature ? `${item.storageTemperature}${item.storageTemperatureUnit ?? ''}` : '',
				})

				//바이오물질 - 항체/세럼
				// } else if (category == 'CAT_BIO_PCR_NGS') {
				// 	list.push({ title: '규격', value: item.spec })
				// 	list.push({ title: '소스', value: item.origin })
				// 	list.push({ title: '형태', value: item.shape })
				// 	list.push({ title: '농도(밀도)', value: `${item.density}${item.densityUnit}` })
				// 	list.push({ title: '분자량', value: `${item.molecularWeight}${item.molecularWeightUnit}` })
				// 	list.push({ title: 'MOL', value: `${item.molarMass}${item.molarMassUnit}` })
				// 	list.push({ title: 'DNA 정보', value: item.dna })
				// 	list.push({ title: '순도', value: `${item.assay}${item.assayUnit}` })
				// 	list.push({ title: '보관온도', value: `${item.storageTemperature}${item.storageTemperatureUnit}` })

				//바이오물질 - 세포주
			} else if (category == 'CAT_BIO_CELL_LINE') {
				list.push({ title: '규격', value: item.spec })
				list.push({ title: '소스', value: item.origin })
				list.push({ title: '형태', value: item.shape })
				list.push({ title: 'DNA 정보', value: item.dna })
				list.push({
					title: '보관온도',
					value: item.storageTemperature ? `${item.storageTemperature}${item.storageTemperatureUnit ?? ''}` : '',
				})
			}

			return list
		},
		isData(data) {
			if (data) {
				return data
			} else {
				return '-'
			}
		},
	},
}
