import axiosInstance from '@/services/axios'

const endPoint = {
	lookupParentCategoryList: () => `category/list/child`,
	lookupChildCategoryList: parentCode => `category/tags?code=${parentCode}`,
	lookupCategoryList: () => `category/root/list`,
}

const categoryAPI = {
	lookupParentCategoryList: () => axiosInstance.get(endPoint.lookupParentCategoryList()),
	lookupChildCategoryList: parentCode => axiosInstance.get(endPoint.lookupChildCategoryList(parentCode)),
	lookupCategoryList: () => axiosInstance.get(endPoint.lookupCategoryList()),
}

export default categoryAPI
