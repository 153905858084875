import catalogAPI from '@/services/api/catalog/index.js'

const RECENT_KEY = 'RECENT_KEY'
const PRODUCT_RECENT_KEY = 'PRODUCT_RECENT_KEY'

const state = {
	keywordList: [],
	keyword: '',
	productKeywordList: [],
	proudctKeyword: '',
	searchFilter: {
		filtered: [],
		filterList: {},
	},
}

const getters = {}

const mutations = {
	setKeywordList(state, list) {
		state.keywordList = []
		for (let key in list) {
			state.keywordList = state.keywordList.concat(
				list[key].map(item => {
					return {
						type: key,
						value: item,
					}
				})
			)
		}
	},
	setProductKeywordList(state, list) {
		state.productKeywordList = []
		for (let key in list) {
			state.productKeywordList = state.productKeywordList.concat(
				list[key].map(item => {
					return {
						value: item,
					}
				})
			)
		}
	},
	setKeywordListType(state, { list, type }) {
		state.keywordList = list.map(item => {
			return {
				type: type,
				value: item,
			}
		})
	},
	setRecentList() {
		state.keywordList = []
		const recent = localStorage.getItem(RECENT_KEY)
		if (recent) {
			const arr = JSON.parse(recent)
			state.keywordList = state.keywordList.concat(
				arr.map(item => {
					return {
						type: item.type,
						value: item.value,
						isRecent: true,
					}
				})
			)
		}
	},
	setProductRecentList() {
		state.productKeywordList = []
		const recent = localStorage.getItem(PRODUCT_RECENT_KEY)
		if (recent) {
			const arr = JSON.parse(recent)
			state.productKeywordList = state.productKeywordList.concat(
				arr.map(item => {
					return {
						value: item.value,
						isRecent: true,
					}
				})
			)
		}
	},
	setKeyword(state, keyword) {
		state.keyword = keyword
	},
	setProductKeyword(state, keyword) {
		state.productKeywordList = keyword
	},
	setType(state, type) {
		state.type = type
	},
	setSearchFilter(state, filtered) {
		state.searchFilter = filtered
	},
}

const actions = {
	async getKeyword({ state, commit }, { keyword, type }) {
		if (keyword.length == 0) {
			commit('setRecentList')
		} else {
			// if(type == "ALL") {
			// let response = await ProductAPI.getSuggestionsKeyword(keyword)
			// if(response.data.code == 0) {
			//     commit('setKeywordList',response.data.data)
			// }
			// }
			// else {
			// let response = await ProductAPI.getSuggestionsKeywordType({keyword,type})
			// if(response.data.code == 0)
			//     commit('setKeywordListType',{
			//         list : response.data.data.list,
			//         type : type
			//     })
			// }
			// commit('setKeywordList', SearchKeyword)
		}
	},
	setRecentKeyword(_, keyword) {
		if (keyword && !keyword.value) return;
		const recent = localStorage.getItem(RECENT_KEY)
		let newArr = [keyword]
		if (recent) {
			const arr = JSON.parse(recent)
			newArr = newArr.concat(arr.filter(item => item.value != keyword.value || item.type != keyword.type))
		}
		if (newArr.length > 10) newArr = newArr.slice(0, 10)
		localStorage.setItem(RECENT_KEY, JSON.stringify(newArr))
	},
	removeRecentKeyword(_, keyword) {
		const recent = localStorage.getItem(RECENT_KEY)
		let newArr = []
		if (recent) {
			const arr = JSON.parse(recent)
			newArr = newArr.concat(arr.filter(item => item.value != keyword.value || item.type != keyword.type))
		}
		localStorage.setItem(RECENT_KEY, JSON.stringify(newArr))
	},
	removeRecentAllKeyword() {
		localStorage.setItem(RECENT_KEY, '')
	},
	async getProductKeyword({ state, commit }, keyword) {
		if (keyword.length == 0) commit('setProductRecentList')
		else {
			const response = await catalogAPI.lookupRecommendedKeywords({ keyword: keyword, length: 10, keywordTarget: 'ALL' })
			commit('setProductKeywordList', response.data)
		}
	},
	setProductRecentKeyword(_, keyword) {
		const recent = localStorage.getItem(PRODUCT_RECENT_KEY)
		let newArr = [keyword]
		if (recent) {
			const arr = JSON.parse(recent)
			newArr = newArr.concat(arr.filter(item => item.value != keyword.value))
		}
		if (newArr.length > 10) newArr = newArr.slice(0, 10)
		localStorage.setItem(PRODUCT_RECENT_KEY, JSON.stringify(newArr))
	},
	removeProductRecentKeyword(_, keyword) {
		const recent = localStorage.getItem(PRODUCT_RECENT_KEY)
		let newArr = []
		if (recent) {
			const arr = JSON.parse(recent)
			newArr = newArr.concat(arr.filter(item => item.value != keyword.value))
		}
		localStorage.setItem(PRODUCT_RECENT_KEY, JSON.stringify(newArr))
	},
	removeProductRecentAllKeyword() {
		console.log('dd')
		localStorage.removeItem(PRODUCT_RECENT_KEY)
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
