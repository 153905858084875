<template>
	<div class="InquiryManagementDetail">
		<content-title :title="title" :statusText="inquiry.status" :statusClass="setClass(inquiry.status)" />
		<div class="content-wrapper">
			<div class="inquiry-info">
				<h6>문의 정보</h6>
				<div class="box">
					<simple-info-box :list="requestInfo" />
					<div class="product-info" v-if="product">
						<p class="subtitle">선택한 상품</p>
						<selectable-product-item :item="product" />
					</div>
				</div>
			</div>
			<div class="answer-info">
				<h6>답변</h6>
				<div class="answer-box" :class="{ 'no-answer': !inquiry.comment }">
					<div class="flex">
						<img src="@/assets/svg/icon/icon-store-profile.svg" />
						<h4>랩매니저 스토어</h4>
					</div>
					<p v-html="requestAnswer"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import date from '@/mixins/date'
import ContentTitle from '@/components/content/ContentTitle'
import SimpleInfoBox from '@/components/list/SimpleInfoBox'
import SelectableProductItem from '@/components/item/SelectableProductItem'
import { Inquiry } from '@/utils/define/ItemCode'

export default {
	name: 'InquiryManagementDetail',
	components: {
		SelectableProductItem,
		SimpleInfoBox,
		ContentTitle,
	},
	mixins: [date],
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('inquiry', ['inquiry']),
		requestInfo() {
			//문의 정보
			let list = []
			list.push({ name: '문의 유형', value: this.inquiry.type?.name })
			list.push({ name: '문의 일시', value: this.datetimeToString(this.inquiry.createDatetime) })
			list.push({ name: '작성자', value: this.inquiry.user?.name, all: true })
			list.push({ name: '문의내용', value: this.inquiry.content, all: true })
			list.push({ name: '첨부파일', list: this.inquiry.images, all: true })
			return list
		},
		title() {
			return `${this.inquiry.type?.name} 정보`
		},
		requestAnswer() {
			//문의 답변
			if (this.inquiry.comment) {
				return this.inquiry.comment
			} else {
				return '답변이 아직 등록되지 않았어요'
			}
		},
		product() {
			if (!this.$_.isEmpty(this.inquiry.orderItem)) {
				return {
					...this.inquiry.orderItem.product,
					orderItemId: this.inquiry.orderItem.orderItemId,
					price: this.inquiry.orderItem.orderItemPrice,
					quantity: this.inquiry.orderItem.quantity,
				}
			}
			return null
		},
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchInquiry()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('inquiry', ['lookupInquiry']),
		async fetchInquiry() {
			await this.lookupInquiry({ instituteId: this.instituteId, id: this.$route.params.id })
		},
		setClass(status) {
			return Inquiry.statusToClass(status)
		},
	},
}
</script>

<style lang="scss">
.InquiryManagementDetail {
	.content-wrapper {
		.inquiry-info {
			> h6 {
				font-size: 1.25rem;
				margin-bottom: 1rem;
			}
			.box {
				padding: 20px;
				border-radius: 5px;
				border: 1px solid $COLOR_DDD;
				.info-box {
					border: 0;
					padding: 0;
					border-radius: 0;
				}
			}
			.product-info {
				padding-top: 20px;
				border-top: 1px solid $LINE_DIVIDER;
				margin-top: 10px;
				> .subtitle {
					font-size: 0.875rem;
					font-weight: 500;
					margin-bottom: 1rem;
				}
			}
		}
		.answer-info {
			margin-top: 64px;
			> h6 {
				font-size: 1.25rem;
				margin-bottom: 1rem;
			}
			.answer-box {
				width: 100%;
				background-color: $LIGHT_YELLOW;
				padding: 20px;
				&.no-answer {
					background-color: $TABLE_HEADER;
					.flex {
						display: none;
					}
				}
				.flex {
					align-items: center;
					margin-bottom: 1rem;
					img {
						width: 48px;
						height: 48px;
						margin-right: 1rem;
					}
					h4 {
						font-size: 1.25rem;
					}
				}
			}
		}
	}
}
</style>
