<template>
	<div id="Main">
		<banners />
		<div class="wrapper">
			<main-title title="카테고리별 베스트" imageType="best" style="padding-top: 60px" />
			<category-selector />
			<main-product-list :hasRank="true" size="large" :list="bestByCategoryList" />
		</div>
		<div class="image-wrapper">
			<div />
			<img class="background-image" src="@/assets/svg/main/main-thunder.svg" />
		</div>
		<div class="wrapper bottom-wrapper">
			<main-title title="MD's Pick" imageType="check" />
			<main-product-list style="margin-bottom: 500px" :hasRank="false" size="large" :list="mdPickList" />

			<div v-if="isLoginUser">
				<div v-if="frequentlyProductList.length">
					<main-title title="자주 주문한 상품" :hasAllBtn="true" type="frequently" />
					<main-product-list :hasRank="false" size="samll" :list="frequentlyProductList" />
				</div>

				<div v-if="recentlyProductInfoList.length">
					<main-title title="최근 본 상품" :hasAllBtn="true" type="recently" />
					<main-product-list :hasRank="false" size="samll" :list="recentlyProductList" />
				</div>

				<!-- todo: 주문이 필요한 물풀 : 연동 필요 -->
				<!-- <main-title title="주문이 필요한 물품" imageType="caution" />
				<required-order-list @showModalDelete="showModalDelete" :list="requiredOrderProducts" /> -->
			</div>

			<main-title title="추천 브랜드" :hasAllBtn="true" type="brand" />
			<box-list :list="recommandBrandList" type="brand" />

			<main-title title="판매자 관" />
			<box-list :list="sellerList" type="seller" />
		</div>

		<modal-popup :list="popupListFilter" />
		<modal-cancel
			title="주문이 필요한 상품 삭제"
			content="삭제된 상품은 다시 볼 수 없습니다.<br>주문이 필요한 상품을 삭제 하시겠습니까?"
			@ok="deleteRequiredProduct"
		/>
	</div>
</template>

<script>
import Banners from '@/views/main/components/Banners.vue'
import MainTitle from '@/views/main/components/MainTitle.vue'
import CategorySelector from '@/views/main/components/CategorySelector.vue'
import MainProductList from '@/views/main/components/MainProductList.vue'
import RequiredOrderList from '@/views/main/components/RequiredOrderList.vue'
import ModalCancel from '@/components/modal/ModalCancel'
import ModalPopup from '@/views/main/modal/ModalPopup.vue'
import cookies from 'vue-cookies'
import { isLogin } from '@/services/api/common'
import BoxList from '@/views/main/components/BoxList.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import gaAPI from '@/utils/ga/api'

export default {
	name: 'Main',
	components: {
		Banners,
		MainTitle,
		CategorySelector,
		MainProductList,
		RequiredOrderList,
		BoxList,
		ModalCancel,
		ModalPopup,
	},
	data() {
		return {
			recentlyViewProductIds: [],
			deleteId: 0,
			requiredOrderProducts: {
				expiredCnt: 25,
				thresholdCnt: 1,
				products: [
					{
						id: 1,
						title: 'Acephate-(acetyl-d3)',
						category: '화학물질',
						size: '25mg',
						image: require('@/assets/svg/main/chemical.svg'),
						expiredDate: 2,
						isUnderThreshold: true,
					},
				],
			},
		}
	},
	computed: {
		...mapState('main', ['bestByCategory', 'mdPicks']),
		...mapState('seller', ['popularSellerList']),
		...mapState('brand', ['popularBrandList']),
		...mapGetters('user', ['instituteId', 'instituteName', 'userInfo']),
		...mapState('productInfo', ['frequentlyProductInfoList', 'recentlyProductInfoList']),
		...mapState('popup', ['popupList']),
		isLoginUser() {
			return isLogin()
		},
		bestByCategoryList() {
			return this.bestByCategory
		},
		mdPickList() {
			return this.mdPicks
		},
		recommandBrandList() {
			return this.popularBrandList
		},
		sellerList() {
			return this.popularSellerList
		},
		frequentlyProductList() {
			return this.frequentlyProductInfoList
		},
		recentlyProductList() {
			return this.recentlyProductInfoList
		},
		popupListFilter() {
			return this.popupList.filter(popup => !cookies.isKey(`popup_${popup.id}`))
		},
		isEmptyPopupListFilter() {
			return this.$_.isEmpty(this.popupListFilter)
		},
	},
	watch: {
		instituteId: {
			async handler(newVal, oldVal) {
				if (!newVal) return
				await this.lookupFrequentlyProductInfoList({ instituteId: this.instituteId, paramsObject: { offset: 0, length: 6 } })
				await this.lookupMdPicks({ offset: 0, length: 8, instituteId: this.instituteId })
				this.setRecentlyViewProducts()

				if (!oldVal) {
					gaAPI.gaEventLogin({
						institute_id: this.instituteId,
						institute_name: this.instituteName,
						lab_id: this.instituteId,
						lab_name: this.instituteName,
						user_id: this.userInfo?.id,
					})
				}
			},
			immediate: true,
		},
	},
	created() {
		this.fetchPopupList()
	},
	async mounted() {
		this.loadList()

		if (this.$route.params.isFromStart) await this.getInstitute()
	},
	methods: {
		...mapActions('user', ['getInstitute']),
		...mapActions('main', ['lookupBanners', 'lookupMdPicks']),
		...mapActions('productInfo', ['lookupRecentlyProductInfoList', 'lookupFrequentlyProductInfoList']),
		...mapActions('brand', ['lookupPopularBrandList']),
		...mapActions('seller', ['lookupPopularSellerList']),
		...mapActions('popup', ['lookupPopupList']),
		loadList() {
			this.lookupBanners()
			this.lookupPopularBrandList({ offset: 0, length: 12 })
			this.lookupPopularSellerList({ offset: 0, length: 12 })
			if (!this.isLoginUser) {
				this.lookupMdPicks({ offset: 0, length: 8 })
			}
		},
		async fetchPopupList() {
			try {
				await this.lookupPopupList()
				if (!this.isEmptyPopupListFilter) this.$bvModal.show('ModalPopup')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		showModalDelete(product) {
			this.deleteId = product.id
			this.$bvModal.show('ModalCancel')
		},
		deleteRequiredProduct() {
			console.log(this.deleteId)
		},
		setRecentlyViewProducts() {
			if (!cookies.get('recentlyViewProducts')) return
			this.recentlyViewProductIds = cookies
				.get('recentlyViewProducts')
				.split(',')
				.filter(id => id)

			if (this.recentlyViewProductIds.length) {
				this.lookupRecentlyProductInfoList({
					paramsObject: { ids: this.recentlyViewProductIds.reverse(), offset: 0, length: 6, instituteId: this.instituteId },
				})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
#Main {
	div:first-child {
		margin-top: 0;
	}
	.boxwrap {
		margin-top: 40px;
		border-bottom: 1px solid #eee;
		padding-bottom: 30px;
		&.select-category {
			margin-top: 30px;
			padding-bottom: 40px;
		}
	}
	.image-wrapper {
		z-index: -1;
		position: absolute;
		width: 100%;
		padding-top: 150px;
		div {
			position: absolute;
			z-index: -2;
			background-color: #ffb621 !important;
			width: 100%;
			height: 300px;
		}
		.background-image {
			width: 100%;
			height: 400px;
		}
	}
	.bottom-wrapper {
		padding-top: 150px;
		height: 100%;
	}
}
</style>
