<template>
	<div>
		<template v-if="list && list.length > 0">
			<div v-if="headers.length > 0" class="management-list-header">
				<span v-for="(header, index) of headers" :key="index"><b v-if="index !== 0">|</b>{{ header }}</span>
			</div>
			<div class="management-list-wrapper">
				<div v-for="(item, index) of list" :key="index">
					<slot :item="item"></slot>
					<hr v-if="index < list.length - 1" />
				</div>
			</div>
		</template>
		<template v-else>
			<div class="empty-list">
				<img :src="require(`@/assets/svg/${emptyImageName}.svg`)" />
				<p>{{ emptyMessage }}</p>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: 'BaseManagementList',
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		emptyImageName: {
			type: String,
			default: '',
		},
		emptyMessage: {
			type: String,
			default: '',
		},
		headers: {
			type: Array,
			default: () => [],
		},
	},
	mounted() {
		if (this.list.length > 0 && this.headers.length > 0) {
			const el = document.querySelector('.management-list-wrapper')
			el.style.borderRadius = '0 0 5px 5px'
		}
	},
}
</script>

<style lang="scss" scoped>
.management-list-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0;
	height: 60px;
	background: $HOVER_BACKGROUND;
	border: 1px solid $COLOR_DDD;
	border-bottom: none;
	border-radius: 5px 5px 0px 0px;
	> span {
		position: relative;
		min-width: 180px;
		text-align: center;
		font-size: 0.875rem;
		font-weight: 500;
		color: $COLOR_333;
		> b {
			position: absolute;
			left: 0;
			color: #d9d9d9;
			font-weight: 400;
		}
	}
}
.management-list-wrapper {
	border: 1px solid $COLOR_DDD;
	border-radius: 5px;
	padding: 20px;
	hr {
		color: #bbb;
		margin: 1.5rem 0;
	}
}
.empty-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	gap: 24px;
	> p {
		font-size: 1rem;
		font-weight: 500;
		color: $COLOR_999;
	}
}
</style>
