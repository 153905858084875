<template>
	<div class="wrapper">
		<div class="top-wrapper">
			<b class="title">자주 주문한 상품</b>
		</div>
		<div class="contents-wrapper">
			<product-list-item v-for="(item, index) in frequentlyProductList" :key="`product_${index}`" :item="item" :isWish="false" />
		</div>
	</div>
</template>

<script>
import ProductListItem from '@/components/item/ProductListItem.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
	name: 'RequiredOrderList',
	components: {
		ProductListItem,
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('productInfo', ['frequentlyProductInfoList']),
		frequentlyProductList() {
			return this.frequentlyProductInfoList
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return
				await this.lookupFrequentlyProductInfoList({ instituteId: this.instituteId, paramsObject: { offset: 0, length: 20 } })
			},
			immediate: true,
		},
	},
	data() {
		return {}
	},
	created() {},
	methods: {
		...mapActions('productInfo', ['lookupFrequentlyProductInfoList']),
		sortProduct() {},
	},
}
</script>

<style lang="scss" scoped>
.wrapper {
	.top-wrapper {
		height: 110px;
		padding-top: 30px;
		display: flex;
		justify-content: space-between;
		.title {
			font-weight: 700;
			font-size: 27px;
		}
	}
	.contents-wrapper {
		padding-top: 10px;
	}
}
</style>
