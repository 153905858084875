import RoutePath from '@/utils/define/RoutePath'
import PageName from '@/utils/define/PageName'

export default [
	{
		name: '브랜드 관',
		path: RoutePath.Brand,
		pageName: PageName.List,
		category: 'brand',
	},
	{
		name: '판매자 관',
		path: RoutePath.Seller,
		pageName: PageName.List,
		category: 'seller',
	},
	{
		name: '찜 목록',
		path: RoutePath.MyPage.Wish,
		pageName: PageName.MyPage.Wish,
		category: 'wish',
	},
	{
		name: '이벤트',
		path: RoutePath.CsCenter.EventManagement,
		pageName: PageName.CsCenter.EventManagement,
		category: 'event',
	},
	{
		name: '고객센터',
		path: RoutePath.CsCenter.QuestionManagement,
		pageName: PageName.CsCenter.QuestionManagement,
		category: 'service',
	},
]
