<template>
	<b-modal
		id="ModalShippingRequirement"
		class="modal"
		title="기본배송지 설정*"
		ok-title="확인"
		cancel-title="닫기"
		cancel-variant="default"
		ok-variant="primary"
		ref="modal"
		size="md"
		centered
		@ok="onSave"
	>
		<div class="modal-shipping-requirement">
			<div class="content">주문을 통해 제품을 수령하실 배송지 정보를 입력해 주세요</div>
			<div class="address-regist-form">
				<input-form-item
					ref="name"
					v-model="info.name"
					direction="column"
					title="배송지명"
					placeholder="배송지명을 입력해주세요"
					:inputStyle="inputStyle"
					style="padding: 0"
				/>
				<input-form-item
					ref="address"
					:postcode="info.postcode"
					:address="info.address"
					:addressDetail="info.addressDetail"
					direction="column"
					type="address"
					title="주소"
					:inputStyle="inputStyle"
					@searchAddress="onSearchAddress"
				/>
				<div class="checkbox-wrapper">
					<input v-model="info.isDefault" type="checkbox" id="save" name="save" class="checkbox" disabled />
					<label for="save">기본 배송지로 저장</label>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import InputFormItem from '@/components/form-item/InputFormItem.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'ModalShippingRequirement',
	components: {
		InputFormItem,
	},
	data() {
		return {
			inputStyle: {
				width: '466px',
			},
			info: {
				id: 0,
				name: '',
				postcode: '',
				address: '',
				addressDetail: '',
				isDefault: 1,
			},
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
	},
	methods: {
		...mapActions('delivery', ['addAddress']),
		onSearchAddress({ postcode, address, addressDetail }) {
			this.info.postcode = postcode
			this.info.address = address
			this.info.addressDetail = addressDetail
			this.info = this.$_.cloneDeep(this.info)
		},
		async onSave(e) {
			e.preventDefault()
			if (!this.validate()) return

			try {
				await this.addAddress({
					instituteId: this.instituteId,
					body: { ...this.info, isDefault: !!this.info.isDefault },
				})
				this.$root.toast('주문 필수 정보 인증 완료', '주문 필수 정보 인증이 완료되었습니다!', ToastType.SUCCESS)
				this.$emit('ok')
				await this.$nextTick()
				this.$refs['modal'].hide()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		validate() {
			let valid = true
			if (!this.$refs['name'].validate()) valid = false
			if (!this.$refs['address'].validate()) valid = false
			return valid
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-shipping-requirement {
	> div:not(:first-child) {
		border-top: 1px solid $COLOR_DDD;
		margin-top: 20px;
	}

	.content {
		height: 20px;
		font-size: 0.875rem;
		color: $COLOR_333;
	}
	.address-regist-form {
		margin-top: 10px;
		.checkbox-wrapper {
			display: flex;
			align-items: center;
			gap: 11px;
			label {
				font-size: 0.875rem;
				font-weight: 500;
				color: $COLOR_999;
			}
		}
	}
}
</style>
<style lang="scss">
#ModalShippingRequirement___BV_modal_outer_ {
	.modal-footer {
		justify-content: center;
		button {
			width: 200px;
			height: 48px;
		}
	}
}
</style>
