<template>
	<div class="wrapper">
		<div class="mylist-header">
			<div><span>내가 요청한 상품,</span> 지금 확인하세요</div>
			<button class="btn btn-primary lg" @click="$refs['ModalSadadreamRequest'].show()">상품 요청하기</button>
		</div>
		<div class="list-wrapper">
			<div class="l-row">
				<div class="l-col">상태</div>
				<div class="l-col">상품요청번호</div>
				<div class="l-col">상품정보</div>
				<div class="l-col">요청일시</div>
			</div>
			<template v-if="count > 0">
				<div class="l-row item" v-for="item in requestProducts" :key="item.id" @click="goDetail(item.id)">
					<div class="l-col">
						<span class="status" :class="[item.status.code]">{{ item.status.name }}</span>
					</div>
					<div class="l-col">{{ item.no }}</div>
					<div class="l-col name">{{ item.name }}</div>
					<div class="l-col">{{ datetimeToString(item.createDatetime) }}</div>
				</div>
			</template>
			<div class="l-row" v-else>
				<div class="no-data">아직 요청한 상품이 없습니다.</div>
			</div>
		</div>
		<Pagniation :isPerPage="false" @page="loadData" :defaultLength="5" :total="count" />
		<ModalSadadreamRequest ref="ModalSadadreamRequest" @success="onSuccessRequest" />
		<ModalCommon ref="ModalRequestComplete" title="상품 요청 완료" content="상품 요청이 완료되었습니다." />
	</div>
</template>
<script>
import PageName from '@/utils/define/PageName'
import MixnDate from '@/mixins/date'
import Pagniation from '@/components/pagenation/CommonPagenation.vue'
import ModalSadadreamRequest from '../modal/ModalSadadreamRequest'
import { mapGetters, mapState, mapActions } from 'vuex'
import ModalCommon from '../../../components/modal/ModalCommon.vue'
export default {
	mixins: [MixnDate],
	components: {
		Pagniation,
		ModalSadadreamRequest,
		ModalCommon,
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('requestProduct', ['requestProducts', 'count']),
	},
	watch: {
		instituteId() {
			if (this.instituteId) {
				this.loadData({})
			}
		},
	},
	created() {
		if (this.instituteId) {
			this.loadData({})
		}
	},
	methods: {
		...mapActions('requestProduct', ['lookupRequestProductList']),
		goDetail(id) {
			this.$router.push({ name: PageName.Sadadream.Detail, params: { id } })
		},
		loadData({ offset = 0, length = 5 }) {
			this.lookupRequestProductList({
				instituteId: this.instituteId,
				params: {
					offset,
					length,
				},
			})
		},
		onSuccessRequest() {
			this.loadData({})
			this.$refs['ModalRequestComplete'].show()
		},
	},
}
</script>
<style lang="scss" scoped>
.wrapper {
	padding-top: 60px;
	.mylist-header {
		height: 36px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;
		div {
			font-size: 20px;
			font-weight: 700;

			span {
				color: #ffb000;
			}
		}
		button {
			font-weight: 700;
			padding: 5px 10px;
			font-size: 14px;
			width: fit-content;
			height: 100%;
			&.btn-primary {
				background-color: $PRIMARY_YELLOW;
			}
		}
	}
	.list-wrapper {
		border-top: 1px solid #eee;
		.l-row {
			width: 100%;
			align-items: center;
			display: flex;
			height: 50px;
			border-bottom: 1px solid #eee;
			font-size: 14px;
			&.item:hover {
				background-color: #f5f5f5;
				cursor: pointer;
			}
			&:first-child {
				color: #666;
			}
			.l-col {
				padding-left: 16px;
				padding-right: 16px;
			}
			.l-col:nth-child(1) {
				width: 105px;
			}
			.l-col:nth-child(2) {
				width: 156px;
			}
			.l-col:nth-child(3) {
				width: calc(100% - 411px);
			}
			.l-col:nth-child(4) {
				width: 150px;
			}
			.no-data {
				width: 100%;
				text-align: center;
			}
		}
	}
}
</style>
