<template>
	<div class="filter-item">
		<div class="header">
			<button @click="$emit('reset')">전체해제</button>
		</div>
		<div class="filter-content">
			<span v-for="(item, index) in filterList" :key="`filter_${index}`"
				>{{ item.name }}<button class="delete-btn" @click="$emit('delete', { item, index })"></button
			></span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FilterItemList',
	props: {
		filterList: {
			type: Array,
			default: () => [],
		},
	},
}
</script>

<style lang="scss" scoped>
.filter-item {
	display: flex;
	align-items: flex-start;
	padding: 15px 0 5px;
	border: 1px solid $LINE_DIVIDER;
	.header {
		height: 20px;
		border-right: 1px solid $COLOR_DDD;
		padding-left: 12px;
		width: 100px;
		display: inline-flex;
		align-items: center;
		button {
			height: 20px;
			background-image: url(~@/assets/svg/reset.svg);
			background-size: 16px;
			background-position: center right 0;
			background-repeat: no-repeat;
			font-size: 0.875rem;
			font-weight: 700;
			padding-right: 20px;
			margin-right: 10px;
		}
	}
	.filter-content {
		width: calc(100% - 100px);
		padding: 0 15px;
		text-align: left;
		margin-top: -5px;
		margin-bottom: 5px;
		overflow-y: auto;
		span {
			display: inline-flex;
			align-items: center;
			height: 30px;
			font-size: 0.875rem;
			color: $PRIMARY_YELLOW;
			font-weight: 700;
			margin-right: 15px;
			button {
				margin-left: 4px;
				width: 16px;
				height: 16px;
				background-image: url(~@/assets/svg/close.svg);
				background-size: 16px;
				background-position: center right 0;
				background-repeat: no-repeat;
				cursor: pointer;
			}
		}
	}
}
</style>
