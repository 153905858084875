<template>
	<div class="simple-info-table">
		<div class="title" v-if="title">{{ title }}</div>

		<ul class="simple-table">
			<li v-for="(item, index) in list" :key="`table-item_${index}`" :class="{ all: item.all }" :style="item.style">
				<b>{{ item.name }}</b>
				<p>{{ item.value }}</p>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'SimpleInfoTable',
	props: {
		title: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
}
</script>

<style lang="scss" scoped>
.simple-info-table {
	width: 100%;
	border: 1px solid $LINE_DIVIDER;
	border-radius: 4px;
	overflow: hidden;
	.title {
		width: 100%;
		height: 36px;
		background-color: $LIST_BACKGROUND;
		padding: 0 1rem;
		font-size: 0.875rem;
		font-weight: 700;
		border-bottom: 1px solid $LINE_DIVIDER;
		display: flex;
		align-items: center;
	}
	.simple-table {
		width: 100%;
		display: block;
		li {
			width: 50%;
			height: 36px;
			display: inline-flex;
			align-items: center;
			&.all {
				width: 100%;
			}
			&:last-child {
				b {
					border-bottom: 0;
				}
				p {
					border-bottom: 0;
				}
			}
			&:nth-last-child(2) {
				&:not(.all) {
					b {
						border-bottom: 0;
					}
					p {
						border-bottom: 0;
					}
				}
			}
			b {
				width: 120px;
				height: inherit;
				background-color: $LIST_BACKGROUND;
				padding-left: 1rem;
				font-size: 0.875rem;
				font-weight: 500;
				color: #111;
				display: inline-flex;
				align-items: center;
				border-bottom: 1px solid $LINE_DIVIDER;
			}
			p {
				padding-left: 1rem;
				height: inherit;
				width: calc(100% - 120px);
				display: inline-flex;
				align-items: center;
				border-bottom: 1px solid $LINE_DIVIDER;
			}
		}
	}
}
</style>
