<template>
	<div class="payment-orderer-info">
		<div class="info-item">
			<div class="info-title">
				<div>주문자 정보</div>
			</div>
			<div class="info-content">
				<div>
					<div class="title">소속</div>
					<div>{{ isData(institute.name) }}</div>
				</div>
				<div>
					<div class="title">이름</div>
					<div>{{ isData(user.name) }}</div>
				</div>
				<div>
					<div class="title">이메일</div>
					<div>{{ isData(user.email) }}</div>
				</div>
				<div>
					<div class="title">연락처<span class="required">*</span></div>
					<div>
						<text-input ref="phone" v-model="phone" @input="$emit('input', $event)"></text-input>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import mixin from '@/mixins'
import TextInput from '@/components/input/TextInput.vue'

export default {
	name: 'PaymentOrdererInfo',
	components: {
		TextInput,
	},
	mixins: [mixin],
	data() {
		return {
			isValid: true,
			phone: '',
		}
	},
	watch: {
		user: {
			async handler() {
				this.phone = this.user.phone
				this.$emit('input', this.phone)
			},
			deep: true,
			immediate: true,
		},
	},
	computed: {
		...mapState('user', ['user', 'institute']),
	},
	methods: {
		getPhone() {
			return this.phone
		},
		validate() {
			this.isValid = true
			if (!this.$refs['phone'].validate()) {
				this.isValid = false
				this.$refs['phone'].focus()
			}
			return this.isValid
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-orderer-info {
	display: flex;
	flex-direction: column;
	.info-item {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.info-title {
			div {
				font-weight: bold;
				font-size: 1.25rem;
			}
		}
		.info-content {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 20px;
			border: 1px solid $COLOR_DDD;
			border-radius: 5px;
			> div {
				height: 40px;
				display: flex;
				align-items: center;
				> div {
					font-size: 0.875rem;
					font-weight: 500;
				}
				.title {
					width: 124px;
				}
			}
		}
	}
}
</style>
