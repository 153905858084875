import catalogAPI from '@/services/api/catalog'
import _ from 'lodash'

const catalogs = {
	namespaced: true,
	state: {
		catalogList: {},
		catalog: {},
		productList: {},
		currentCategoryCode: '',
		filter: {},
		productFilter: {},
	},
	getters: {
		getSellerFilterList: state => {
			return state.filter?.seller?.map(item => {
				return {
					name: item.name,
					value: item.id,
				}
			})
		},
		getBrandFilterList: state => {
			return state.filter?.brand?.map(item => {
				return {
					name: item.name,
					value: item.id,
				}
			})
		},
	},
	mutations: {
		setCatalogList: (state, payload) => {
			state.catalogList = payload
		},
		setCurrentCategory: (state, payload) => {
			state.currentCategoryCode = payload
		},
		setFilter: (state, payload) => {
			state.filter = payload
		},
		setCatalog: (state, payload) => {
			state.catalog = payload
		},
		setProductList: (state, payload) => {
			state.productList = payload
		},
		clearFilter: state => {
			state.filter = {}
		},
		setProductFilter: (state, payload) => {
			state.productFilter = payload
		},
	},
	actions: {
		lookupCatalogList: async ({ state, commit, dispatch }, params) => {
			const response = await catalogAPI.lookupCatalogList(params)
			commit('setCatalogList', response.data)
			if (params.categoryCode !== state.currentCategoryCode || _.isEmpty(state.filter)) {
				commit('setCurrentCategory', params.categoryCode)
				commit('setFilter', response.data.filter)
			}
		},
		lookupCatalog: async ({ commit }, { id, params }) => {
			const response = await catalogAPI.lookupCatalog(id, params)
			commit('setCatalog', response.data)
		},
		lookupProductList: async ({ commit }, params) => {
			const response = await catalogAPI.lookupProductList(params)
			commit('setProductList', response.data)
		},
		lookupProductListFilter: async ({ commit }, params) => {
			const response = await catalogAPI.lookupProductListFilter(params)
			commit('setProductFilter', response.data)
		},
	},
}

export default catalogs
