import axiosInstance from '@/services/axios'

const endPoint = {
	lookupCards: instituteId => `institute/${instituteId}/card/list`,
	lookupCardInfo: (instituteId, bin) => `institute/${instituteId}/card/information?bin=${bin}`,
	addCard: instituteId => `institute/${instituteId}/card`,
	updateCard: instituteId => `institute/${instituteId}/card`,
	deleteCard: (instituteId, id) => `institute/${instituteId}/card?cardId=${id}`,
}

const cardAPI = {
	lookupCards: instituteId => axiosInstance.get(endPoint.lookupCards(instituteId)),
	lookupCardInfo: (instituteId, bin) => axiosInstance.get(endPoint.lookupCardInfo(instituteId, bin)),
	addCard: (instituteId, body) => axiosInstance.post(endPoint.addCard(instituteId), body),
	updateCard: (instituteId, body) => axiosInstance.patch(endPoint.updateCard(instituteId), body),
	deleteCard: (instituteId, id) => axiosInstance.delete(endPoint.deleteCard(instituteId, id)),
}

export default cardAPI
