import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueCarousel from 'vue-carousel'
import VueRouter from 'vue-router'
import App from './App.vue'
import PortalVue from 'portal-vue'
import _ from 'lodash'
import router from '@/router'
import store from './store'
import MixinDraft from '@/mixins/draft'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueCookies from 'vue-cookies'
import { COOKIES_DOMAIN } from '@/services/api/common'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'swiper/dist/css/swiper.css'

import '@/assets/css/app.scss'
import '@/assets/css/modal.scss'
import '@/assets/css/_reverse-bootstrap.scss'
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(PortalVue)
Vue.use(VueCarousel)
Vue.use(VueAwesomeSwiper)
VueCookies.config('365d', null, COOKIES_DOMAIN)
Vue.use(VueCookies)

Vue.prototype.$_ = _

Array.prototype.remove = function (data, key) {
	const idx = this.findIndex(function (item) {
		if (key == null || key == undefined) {
			return item == data
		} else {
			return item[key] === data
		}
	}) // findIndex = find + indexOf
	if (idx > -1) this.splice(idx, 1)
}
Array.prototype.removeAt = function (idx) {
	if (idx > -1) this.splice(idx, 1)
}
Array.prototype.removeAll = function (list, key) {
	this.forEach(x => this.remove(x, key))
}

Vue.directive('click-outside', {
	bind: function (el, binding, vnode) {
		el.clickOutsideEvent = function (event) {
			// here I check that click was outside the el and his children
			if (!(el == event.target || el.contains(event.target))) {
				// and if it did, call method provided in attribute value
				vnode.context[binding.expression](event)
			}
		}
		document.body.addEventListener('mouseup', el.clickOutsideEvent)
	},
	unbind: function (el) {
		document.body.removeEventListener('mouseup', el.clickOutsideEvent)
	},
})

const resolve = VueRouter.prototype.resolve
VueRouter.prototype.open = function open(obj) {
	const route = resolve.call(this, obj)
	// window.open(route.href, '_blank')
	location.href = route.href
}

new Vue({
	render: h => h(App),
	router,
	store,
	mixins: [MixinDraft],
}).$mount('#app')
