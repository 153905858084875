<template>
	<div class="cart wrapper">
		<content-title title="장바구니" :steps="steps" :activeStep="1" />
		<cart-shipping-info class="cart-shipping-info"></cart-shipping-info>
		<cart-banner class="cart-banner"></cart-banner>
		<cart-product-info-table
			ref="cart-product-info-table"
			class="cart-product-info-table"
			@changeCartList="loadCartList"
			@changeChecked="changeChecked"
		></cart-product-info-table>
		<cart-notice class="cart-notice"></cart-notice>
		<div class="button-wrapper">
			<button class="btn btn-gray lg" @click="estimate">{{ `총 ${checkedCount}건 견적 ${isLogin ? '작성하기' : '출력하기'}` }}</button>
			<button class="btn btn-primary lg" @click="order">{{ `총 ${checkedCount}건 주문하기` }}</button>
			<div v-if="isShowEstimateTooltip" class="estimate-tooltip">
				<div>
					<p>견적<span>이 작성되었습니다.</span></p>
					<img src="@/assets/svg/main/icon-close.svg" @click="isShowEstimateTooltip = false" />
				</div>
				<button class="btn btn-primary lg" @click="goEstimate">견적함으로 이동하기</button>
			</div>
		</div>

		<modal-cancel :title="modalTitle" :content="modalContent" checkboxLabel="장바구니에서 상품을 삭제하지 않고 진행" @ok="okEventListener" />
		<modal-payment-requirement @ok="$router.go()" />
		<modal-shipping-requirement @ok="$router.go()" />
	</div>
</template>

<script>
import CartShippingInfo from '@/views/cart/components/CartShippingInfo'
import CartBanner from '@/views/cart/components/CartBanner'
import CartProductInfoTable from '@/views/cart/components/CartProductInfoTable.vue'
import CartNotice from '@/views/cart/components/CartNotice.vue'
import ContentTitle from '@/components/content/ContentTitle'
import ModalCancel from '@/components/modal/ModalCancel'
import ModalPaymentRequirement from '@/views/payment/modal/ModalPaymentRequirement.vue'
import ModalShippingRequirement from '@/views/payment/modal/ModalShippingRequirement.vue'
import CartMixin from '@/mixins/cart'
import { isLogin, goToSSOMain } from '@/services/api/common'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'
import cookies from 'vue-cookies'

const INSTITUTE_TYPE = {
	PERSON: 'PERSON',
	UNIVERSITY: 'UNIVERSITY',
	ENTERPRISE: 'ENTERPRISE',
	SELLER: 'SELLER',
}

export default {
	name: 'Cart',
	components: {
		CartShippingInfo,
		CartBanner,
		CartProductInfoTable,
		CartNotice,
		ContentTitle,
		ModalCancel,
		ModalPaymentRequirement,
		ModalShippingRequirement,
	},
	mixins: [CartMixin],
	data() {
		return {
			modalTitle: '',
			modalContent: '',
			checkedCount: 0,
			isShowEstimateTooltip: false,
			estimateId: 0,
			steps: [
				{
					value: 1,
					text: '장바구니',
				},
				{
					value: 2,
					text: '주문',
				},
				{
					value: 3,
					text: '주문 완료',
				},
			],
		}
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.loadCartList()
			},
			immediate: true,
		},
	},
	computed: {
		...mapState('delivery', ['basicAddress']),
		...mapState('institute', ['requirement']),
		...mapState('user', ['institute']),
		...mapGetters('user', ['instituteId']),
		isLogin() {
			return isLogin()
		},
		isPerson() {
			if (this.$_.isEmpty(this.institute)) return
			return this.institute.type.code === INSTITUTE_TYPE.PERSON
		},
		currentAddress() {
			return cookies.get('currentAddress2') ?? this.basicAddress
		},
	},
	created() {
		if (!isLogin()) {
			this.loadCartList()
		}
	},
	methods: {
		...mapMutations('order', ['setEstimateId', 'setSelectedProductList']),
		...mapMutations('cart', ['initCartList']),
		...mapActions('cart', ['lookupNonMemberCartList', 'lookupCartList']),
		...mapActions('institute', ['lookupRequirement']),
		...mapActions('estimate', ['addEstimateByCart', 'addEstimateToNonMember']),
		loadCartList() {
			const params = {}
			if (this.currentAddress?.id) params.deliveryId = this.currentAddress?.id
			try {
				if (!isLogin()) {
					if (!this.isNonMemberCartProductList()) {
						this.initCartList()
					} else {
						this.lookupNonMemberCartList({ carts: this.getNonMemberCartProductList() })
					}
				} else {
					this.lookupCartList({ instituteId: this.instituteId, params })
				}
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async estimate() {
			if (!this.$refs['cart-product-info-table'].validate()) return

			if (!isLogin()) {
				try {
					const response = await this.addEstimateToNonMember({
						carts: this.getCheckedProductList(),
					})

					window.open(`${RoutePath.EstimateExport}?url=${response.url}`)
				} catch (e) {
					this.$root.toast('출력 실패', e.response.data.msg, ToastType.ERROR)
				}
			} else {
				this.modalTitle = '견적서 작성'
				this.modalContent = `장바구니에서 견적서를 작성하게되면 장바구니에서 사라지게 됩니다.<br />
                                    견적서 작성을 진행하시겠습니까?`
				this.$bvModal.show('ModalCancel')
			}
		},
		async order() {
			if (!isLogin()) {
				goToSSOMain(location.href)
				return
			}
			if (!this.$refs['cart-product-info-table'].validate()) return
			await this.loadRequirement()

			if (this.requirement.canOrder) {
				const productList = this.getCheckedProductList().map(product => {
					return { ...product, cartId: product.id }
				})
				this.setEstimateId()
				this.setSelectedProductList(productList)

				this.$router.push({
					name: PageName.Payment,
				})
			} else {
				if (this.isPerson) {
					this.$bvModal.show('ModalShippingRequirement')
				} else {
					this.$router.replace({
						hash: 'authenticationcart',
					})
					this.$bvModal.show('ModalPaymentRequirement')
				}
			}
		},
		getCheckedProductList() {
			return this.$refs['cart-product-info-table'].getCheckedProductList()
		},
		async loadRequirement() {
			try {
				await this.lookupRequirement(this.instituteId)
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async okEventListener(isCheck) {
			try {
				const response = await this.addEstimateByCart({
					instituteId: this.instituteId,
					body: {
						deleteSelectedCarts: !isCheck,
						cartIds: this.getCheckedProductList().map(item => item.id),
					},
				})

				this.estimateId = response.estimateId
				this.loadCartList()
				if (!isCheck) this.$root.toast('장바구니 상품 삭제 완료', '정상적으로 장바구니에서 삭제되었습니다', ToastType.SUCCESS)
				this.isShowEstimateTooltip = true
			} catch (e) {
				this.$root.toast('작성 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
		changeChecked(checkedCount) {
			this.checkedCount = checkedCount
		},
		goEstimate() {
			this.$router.push({
				name: PageName.MyPage.EstimateDetail,
				params: {
					id: this.estimateId,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.cart {
	.cart-shipping-info {
		margin-top: 20px;
	}
	.cart-banner {
		margin-top: 20px;
	}
	.cart-product-info-table {
		margin-top: 30px;
	}
	.cart-notice {
		margin-top: 30px;
	}
	.button-wrapper {
		margin-top: 70px;
		display: flex;
		justify-content: center;
		position: relative;
		gap: 10px;
		button {
			width: 240px;
			height: 48px;
			&.btn-primary {
				background-color: $PRIMARY_YELLOW;
			}
		}
		.estimate-tooltip {
			width: 350px;
			height: 113px;
			position: absolute;
			top: -130px;
			left: 250px;
			padding: 20px 15px 15px 15px;
			border: solid 1px $COLOR_DDD;
			border-radius: 5px;
			background: $PRIMARY_WHITE;
			div {
				display: flex;
				justify-content: space-between;
				p {
					font-weight: 700;
					font-size: 16px;
					span {
						font-weight: 400;
					}
				}
				img {
					cursor: pointer;
				}
			}
			button {
				&.btn-primary {
					width: 100%;
					height: 40px;
					margin-top: 10px;
					background: $PRIMARY_YELLOW;
				}
			}
		}
		.estimate-tooltip:after {
			border-bottom: 0px solid transparent;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 8px solid $PRIMARY_WHITE;
			content: '';
			position: absolute;
			top: 110px;
			left: 160px;
			z-index: 1004;
		}
		.estimate-tooltip:before {
			border-bottom: 0px solid transparent;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 8px solid #ddd;
			content: '';
			position: absolute;
			top: 111px;
			left: 160px;
			z-index: 1003;
		}
	}
}
</style>
