import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/modules/ParamMaker'

const endPoint = {
	lookupRequestProductList: (instituteId, paramsObject) => `institute/${instituteId}/request/list?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupRequestProduct: (instituteId, id) => `institute/${instituteId}/request?id=${id}`,
	addRequestProduct: instituteId => `institute/${instituteId}/request`,
	printEstimate: instituteId => `institute/${instituteId}/request/estimate/print`,
	otherList: instituteId => `institute/${instituteId}/request/recommend/list`,
	otherDetail: instituteId => `institute/${instituteId}/request/recommend`,
}

const requestAPI = {
	lookupRequestProductList: (instituteId, paramsObject) => axiosInstance.get(endPoint.lookupRequestProductList(instituteId, paramsObject)),
	lookupRequestProduct: (instituteId, id) => axiosInstance.get(endPoint.lookupRequestProduct(instituteId, id)),
	addRequestProduct: (instituteId, formData) =>
		axiosInstance.post(endPoint.addRequestProduct(instituteId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	printEstimate: (instituteId, body) => axiosInstance.post(endPoint.printEstimate(instituteId), body),
	getOtherList: (instituteId, params) => axiosInstance.get(endPoint.otherList(instituteId), { params }),
	getOtherDetail: (instituteId, params) => axiosInstance.get(endPoint.otherDetail(instituteId), { params }),
}

export default requestAPI
