<template>
	<div class="category-filter-list">
		<div class="category-filter">
			<div class="selected-category" v-if="statusNo > 1">
				<button class="prev-btn btn-common" @click="prev">
					<img src="@/assets/svg/prev-arrow_l.svg" />
				</button>
				<h6 @click="selectStep(1)">전체 카테고리</h6>
				<h6 @click="selectStep(2)">
					{{ activeCategory.name }}
				</h6>
			</div>
			<b v-else>총 {{ list.length }}개 카테고리</b>
		</div>
		<ul v-if="list.length > 0 && detailList.length === 0 && !activeCategory">
			<li v-for="(item, index) in list" :key="`category_${index}`" @click="selectParentCategory(item)">
				{{ item.name }}
			</li>
		</ul>
		<ul class="check-list" v-if="detailList.length > 0">
			<li v-for="(item, index) in detailList" :key="`item_${index}`">
				<input class="checkbox" type="checkbox" :id="`item_${index}`" :value="item.value" v-model="checkedList" @change="checked" />
				<label :for="`item_${index}`" v-b-tooltip.hover.top :title="item.name.length > 6 ? item.name : ''">
					{{ item.name }}
				</label>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CategoryFilterList',
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		filtered: {
			type: Array,
			default: () => [],
		},
		selected: {
			type: Object,
			default: () => {},
		},
		isClassification: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.checkedList = this.$route.query.tags ? [this.$route.query.tags] : []
		})
	},
	data() {
		return {
			activeCategory: null,
			detailList: [],
			statusNo: 1,
			checkedList: [],
			isInit: true,
		}
	},
	computed: {
		...mapGetters('category', ['getChildCategoryList']),
		filterList() {
			return this.filtered
		},
	},
	watch: {
		checkedList(val) {
			if (this.$_.isEmpty(val) && this.isInit && this.$route.query.tags) {
				this.isInit = false
				this.checkedList = [this.$route.query.tags]
				this.checked()
			}
		},
		filterList(val) {
			let values = []
			val.filter(filter => filter.type === 'tag').forEach(item => {
				values.push(item.value)
			})
			this.checkedList = values

			if (val.length == 0) {
				this.checkedList = []
			}

			let filteredParentCategory = this.filterList.filter(el => el.type === 'category' && el.node === 'parent')
			let filteredChildCategory = this.filterList.filter(el => el.type === 'category' && el.node === 'child')

			if (filteredParentCategory.length === 0) {
				this.statusNo = 1
			} else if (filteredChildCategory.length === 0) {
				this.statusNo = 2
			}
		},
		selected(newVal, oldVal) {
			if (newVal.value !== oldVal.value) this.selectParentCategory(newVal)
		},
		statusNo(val) {
			if (val === 2) {
				this.$emit('selectCategory', this.activeCategory)
			} else {
				this.$emit('selectCategory')
				this.activeCategory = null
				this.detailList = []
				this.checkedList = []
			}
		},
	},
	methods: {
		...mapActions('category', ['lookupChildCategoryList']),
		async selectParentCategory(item) {
			this.activeCategory = item
			if (this.activeCategory.value) {
				this.statusNo = 2
				await this.setDetailList()
			}
		},
		async setDetailList() {
			let copyClassifcation = JSON.parse(JSON.stringify(this.activeCategory))
			await this.lookupChildCategoryList(this.activeCategory.value)
			this.activeCategory = copyClassifcation
			this.detailList = JSON.parse(JSON.stringify(this.getChildCategoryList))
		},
		prev() {
			if (this.statusNo > 0) {
				this.statusNo -= 1
			}
		},
		selectStep(step) {
			this.statusNo = step
		},
		checked() {
			this.$emit('checkCategory', this.checkedList)
		},
	},
}
</script>

<style lang="scss" scoped>
.category-filter-list {
	padding: 0 18px;
	.category-filter {
		width: 100%;
		height: 50px;
		border-bottom: 1px solid $COLOR_DDD;
		display: flex;
		align-items: center;
		&.allSelected {
			border-bottom: 0;
		}
		b {
			font-size: 0.875rem;
			font-weight: 700;
		}
		.selected-category {
			width: 100%;
			display: flex;
			align-items: center;
			button {
				width: 30px;
				height: 30px;
				border-radius: 4px;
				margin-right: 10px;
				img {
					width: 24px;
					height: 24px;
				}
			}
			h6 {
				display: inline-block;
				padding-right: 22px;
				background-image: url(~@/assets/svg/arrow_up_s.svg);
				background-repeat: no-repeat;
				background-size: 7px;
				background-position: center right 6px;
				cursor: pointer;
				&:last-child {
					background-image: none;
					cursor: default;
					&:hover {
						text-decoration: unset;
					}
				}
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	ul {
		display: block;
		width: 100%;
		padding-bottom: 15px;
		li {
			width: calc(100% / 6);
			font-size: 0.875rem;
			font-weight: 400;
			display: inline-block;
			margin-top: 10px;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.check-list {
		width: calc(100% + 10px);
		text-align: left;
		padding-bottom: 10px;
		li {
			display: inline-flex;
			width: 138px;
			margin-right: 10px;
			position: relative;
			align-items: center;
			height: 20px;
			label {
				@include ellipse;
				display: inline-block;
				font-size: 0.875rem;
				font-weight: 400;
				margin-left: 10px;
				max-width: calc(100% - 30px);
				cursor: pointer;
				position: relative;
				cursor: pointer;
			}
			label:hover ~ .tooltip {
				display: block;
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
</style>
