<template>
	<div class="common-table" :class="{ 'no-list': list.length == 0 }">
		<div class="flex title">
			<h4 v-if="title">{{ title }} ({{ count }})</h4>
			<button v-if="btnText" class="btn btn-common" @click="clickEv">{{ btnText }}</button>
		</div>
		<div class="table-wrapper" v-if="list.length > 0">
			<table>
				<thead>
					<tr>
						<th v-if="isCheck">
							<input type="checkbox" class="common" v-model="allChecked" />
						</th>
						<th v-for="(header, index) in headers" :key="`common-header${index}`" :width="header.width">{{ header.name }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in list" :key="`common-item${index}`" @click="goPage($event, item.id)">
						<td v-if="isCheck">
							<input type="checkbox" class="common" v-model="checkList" :value="item.id" />
						</td>
						<td v-for="(column, idx) in headers" :key="`column_${index}${idx}`">
							<!--상태-->
							<div v-if="column.key == 'status'" class="status" :class="item.status.class">{{ item.status.text }}</div>
							<!--상품정보-->
							<div v-else-if="column.key == 'info'" class="info">
								<b>{{ item.name }}</b>
								<p>{{ item.subname }}</p>
							</div>
							<!--그 외-->
							<div v-else class="common" v-html="item[column.key]"></div>
						</td>
					</tr>
				</tbody>
			</table>

			<common-pagenation class="common-pagenation" ref="common-pagenation" :total="count" :isPerPage="false" @page="$emit('page', $event)" />
		</div>
		<div v-else class="no-list">
			<img :src="noData.image" />
			<p v-html="noData.text"></p>
			<button class="btn btn-lg btn-white" v-if="noData.btnText" v-text="noData.btnText" @click="clickEv"></button>
		</div>
	</div>
</template>

<script>
import CommonPagenation from '@/components/pagenation/CommonPagenation.vue'

export default {
	name: 'CommonTable',
	props: {
		title: {
			type: String,
			default: '',
		},
		count: {
			type: Number,
			default: 0,
		},
		headers: {
			type: Array,
			default: () => [],
		},
		list: {
			type: Array,
			default: () => [],
		},
		btnText: {
			type: String,
			default: '',
		},
		noData: {
			type: Object,
			default: () => {},
		},
		isCheck: {
			type: Boolean,
			default: true,
		},
	},
	components: {
		CommonPagenation,
	},
	data() {
		return {
			allChecked: false,
			checkList: [],
		}
	},
	watch: {
		allChecked(val) {
			if (val) {
				this.list.forEach(item => {
					this.checkList.push(item.id)
				})
			} else {
				this.checkList = []
			}
		},
		checkList(val) {
			this.$emit('checked', val)
		},
	},
	methods: {
		clickEv() {
			this.$emit('clickEv')
		},
		goPage(e, id) {
			if (e.target.nodeName === 'INPUT') return

			this.$emit('go', id)
		},
	},
}
</script>

<style lang="scss" scoped>
.common-table {
	width: 100%;
	&.no-list {
		.title {
			button {
				display: none;
			}
		}
	}
	.title {
		width: 100%;
		align-items: center;
		margin-bottom: 1rem;
		h4 {
			font-size: 1rem;
			font-weight: 700;
		}
		button {
			margin-left: auto;
		}
	}
	table {
		width: 100%;
		input[type='checkbox'].common {
			margin-bottom: -5px;
		}
		thead {
			border-top: 1px solid $LINE_DIVIDER;
			border-bottom: 1px solid $LINE_DIVIDER;
			th {
				height: 40px;
				font-size: 0.875rem;
				font-weight: 700;
				color: $COLOR_666;
				padding: 0 8px;
				&:last-child {
					text-align: right;
					padding-right: 8px;
				}
			}
		}
		tbody {
			tr {
				border-bottom: 1px solid $LINE_DIVIDER;
				cursor: pointer;
				td {
					height: 66px;
					padding: 0 8px;
					vertical-align: middle;
					&:last-child {
						text-align: right;
					}
					div {
						font-size: 0.75rem;
						font-weight: 500;
						&.info {
							b {
								font-size: 0.875rem;
								font-weight: 700;
							}
							p {
								font-size: 0.75rem;
								font-weight: 400;
								color: $COLOR_666;
							}
						}
					}
				}
			}
		}
	}
	.no-list {
		width: 100%;
		text-align: center;
		img {
			width: 200px;
			height: 200px;
			margin: 0 auto;
		}
		p {
			font-size: 1rem;
			font-weight: 500;
			color: $COLOR_999;
			margin-bottom: 3rem;
		}
	}
	.common-pagenation {
		margin-top: 24px;
		border-top: 0;
	}
}
</style>
