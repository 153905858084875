import { DateTime } from 'luxon'

export default {
	computed: {
		today() {
			return DateTime.now()
		},
		yesterday() {
			return DateTime.now().minus({ day: 1 })
		},
		tommorrow() {
			return DateTime.now().plus({ day: 1 })
		},
		datepickerLang() {
			return {
				formatLocale: {
					// MMMM
					months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
					// MMM
					monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
					// dddd
					weekdays: ['일', '월', '화', '수', '목', '금', '토'],
					// ddd
					weekdaysShort: ['일', '월', '화', '수', '목', '금', '토'],
					// dd
					weekdaysMin: ['일', '월', '화', '수', '목', '금', '토'],
				},
				yearFormat: 'YYYY년',
				monthBeforeYear: false,
			}
		},
	},
	methods: {
		objectToDateTime(obj) {
			if (obj instanceof Date) {
				return DateTime.fromJSDate(obj)
			} else if (obj instanceof String || typeof obj == 'string') {
				return DateTime.fromISO(obj)
			} else if (obj instanceof DateTime) {
				return obj
			}
			return null
		},
		getTimeToJSDate(params) {
			return this.getTime(params)?.toJSDate()
		},
		dateToLocalString(date) {
			return this.objectToDateTime(date)?.toFormat('MM월 dd일')
		},
		timeToString(date) {
			return this.objectToDateTime(date)?.toFormat('T')
		},
		formatDate(date) {
			return this.objectToDateTime(date)?.toFormat('yyyy년 MM월 dd일')
		},
		dateToString(date, deli = '-') {
			return this.objectToDateTime(date)?.toFormat(`yyyy${deli}MM${deli}dd`)
		},
		datetimeToString(date) {
			return this.objectToDateTime(date)?.toFormat('yyyy-MM-dd T')
		},
	},
}
