<template>
	<div class="order-info">
		<div class="info-item">
			<div class="info-title">
				<span>주문자 정보</span>
			</div>
			<div class="info-content">
				<div>
					<span class="title">소속</span>
					<span>{{ order.instituteName }}</span>
				</div>
				<div>
					<span class="title">이름</span>
					<span>{{ orderer.name }}</span>
				</div>
				<div>
					<span class="title">연락처</span>
					<span>{{ orderer.phone ? convertTel(orderer.phone) : '' }}</span>
				</div>
			</div>
		</div>
		<div class="info-item">
			<div class="info-title">
				<span>수령자 정보</span>
			</div>
			<div class="info-content">
				<div>
					<span class="title">이름</span>
					<span>{{ recipient.name }}</span>
				</div>
				<div>
					<span class="title">연락처</span>
					<span>{{ recipient.phone ? convertTel(recipient.phone) : '' }}</span>
				</div>
				<div>
					<span class="title">수령지 주소</span>
					<span>
						<span v-if="order.postcode">({{ order.postcode }})</span> {{ order.address }}
						{{ order.addressDetail ? order.addressDetail : '' }}
					</span>
				</div>
			</div>
		</div>
		<div v-if="order.draft && order.draft.subject" class="info-item">
			<div class="info-title">
				<span>기안 정보</span>
			</div>
			<div class="info-content">
				<div>
					<span class="title">기안제목</span>
					<span class="open-new" @click="openDraft">{{ order.draft.subject }}
						<img class="open-new-icon" src="@/assets/images/open-new.png">
					</span>
				</div>
				<div>
					<span class="title">기안일시</span>
					<span>{{ order.draft.createDatetime ? formatDateTime(order.draft.createDatetime) : "-" }}</span>
				</div>
				<div>
					<span class="title">예산이름</span>
					<span>{{ order.draft.budget && order.draft.budget.name ? order.draft.budget.name : "-"}}</span>
				</div>
			</div>
		</div>
		<div class="info-item">
			<div class="info-title">
				<span>최종 주문 정보</span>
				<button v-if="order.documentUrl" class="btn btn-gray" @click="download">주문서류 다운로드</button>
			</div>
			<div class="info-content">
				<div class="between">
					<span class="title">주문 금액 합계</span>
					<span>{{ order.totalOrderItemPrice ? CommaNum(order.totalOrderItemPrice) : 0 }}원</span>
				</div>
				<div class="between">
					<span class="title">주문 취소 금액 합계</span>
					<span>{{ order.totalCancelOrderItemPrice ? CommaNum(order.totalCancelOrderItemPrice) : 0 }}원</span>
				</div>
				<div class="between">
					<span class="title">배송비 합계</span>
					<span>0원</span>
				</div>
				<div class="between" style="padding-bottom: 1rem; border-bottom: 1px solid #111">
					<span class="title">VAT(10%)</span>
					<span>{{ order.totalOrderItemVat ? CommaNum(order.totalOrderItemVat) : 0 }}원</span>
				</div>
				<div class="between">
					<span class="title total">총 주문 금액</span>
					<span class="total">{{ order.totalOrderItemPriceWithVat ? CommaNum(order.totalOrderItemPriceWithVat) : 0 }}원</span>
				</div>
			</div>
		</div>
		<!-- <estimate-table :list="list" /> -->
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import mixin from '@/mixins'
import { ToastType } from '@/utils/define/ToastType'
import { getSYSUrl } from '@/services/api/common'
// import EstimateTable from '@/components/table/EstimateTable.vue'

export default {
	name: 'OrderInfo',
	mixins: [mixin],
	components: {
		// EstimateTable,
	},
	data() {
		return {
			// list: [
			// 	{
			// 		status: '미확인',
			// 		date: '2022-02-01',
			// 		writer: '박찬우',
			// 		number: 'YYMMDD-123456',
			// 		info: '더미데이터입니다... 외 3건',
			// 		price: 480000,
			// 		manager: {
			// 			name: '홍길동',
			// 			department: '고려대학교 김이박교수님연구실',
			// 		},
			// 	},
			// ],
		}
	},
	computed: {
		...mapState('order', ['order']),
		...mapState('seller', ['seller']),
		...mapGetters('user', ['instituteId', 'proInstituteId']),
		orderer() {
			return this.order.orderer ?? {}
		},
		recipient() {
			return this.order.recipient ?? {}
		},
	},
	methods: {
		...mapActions('order', ['orderPrint']),
		async download() {
			try {
				const response = await this.orderPrint({ instituteId: this.instituteId, body: { id: this.order.orderId } })
				window.open(response.url, '_blank')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		convertTel(value) {
			return value.replace(/\D+/g, '').replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3')
		},
		openDraft() {
			window.open(`${getSYSUrl()}/${this.proInstituteId}/purchase/draft/${this.order.draft.id}`)
		}
	},
}
</script>

<style lang="scss" scoped>
.order-info {
	display: flex;
	flex-direction: column;
	gap: 64px;
	.info-item {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.info-title {
			display: flex;
			justify-content: space-between;
			span {
				font-weight: bold;
				font-size: 1.25rem;
			}
			button {
				display: flex;
				align-items: center;
				font-weight: 700;
				font-size: 0.75rem;
			}
		}
		.info-content {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 20px;
			border: 1px solid $COLOR_DDD;
			border-radius: 5px;
			> div {
				display: flex;
				> span {
					font-size: 0.875rem;
					font-weight: 500;
				}
				.title {
					width: 124px;
				}
			}
			.between {
				justify-content: space-between;
				.total {
					font-size: 1rem;
					font-weight: 700;
				}
			}
			.open-new {
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}
				.open-new-icon {
					width: 16px;
					height: 16px;
					margin-left: 3px;
					margin-top: -3px;
				}
			}
		}
	}
}
</style>
