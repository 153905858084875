<template>
	<div class="EstimateSheet">
		<div class="flex">
			<div class="right-btn-group">
				<button class="btn btn-complete" @click="printSheet">출력하기</button>
			</div>
		</div>
		<div class="content-wrapper">
			<div class="title">
				<h4>견적서</h4>
				<h6>{{ info.subname }}</h6>
				<img src="@/assets/svg/logo.svg" />
			</div>
			<div class="flex info">
				<simple-info-table title="수신자 정보" :list="recipientInfo" />
				<simple-info-table title="공급자 정보" :list="sellerInfo" />
			</div>
			<simple-info-table title="담당자 정보" :list="assigneeInfo" />
			<div class="flex notice">
				<p class="notice-txt">아래와 같이 주문이 완료되었습니다</p>
				<span>견적유효기간 : 견적일로부터 30일</span>
			</div>
			<order-table :headers="orderHeaders" :list="orderList" />
			<simple-textarea-form title="비고" :value="info.memo" readonly />
			<order-price-box :info="orderPriceInfo" />
			<!-- <p class="notice-txt">연구비 지출증빙은 거래명세서를 이용해주세요</p> -->
		</div>
	</div>
</template>

<script>
import SimpleInfoTable from '@/components/list/SimpleInfoTable'
import SimpleTextareaForm from '@/components/form-item/SimpleTextareaForm'
import OrderTable from '@/components/list/OrderTable'
import OrderPriceBox from '@/components/list/OrderPriceBox'

import estimateDummay from '@/utils/dummy/EstimateInfo'
import productDummay from '@/utils/dummy/ProductList'
import date from '@/mixins/date'

export default {
	name: 'EstimateSheet',
	components: {
		SimpleInfoTable,
		SimpleTextareaForm,
		OrderTable,
		OrderPriceBox,
	},
	mixins: [date],
	created() {
		this.info = estimateDummay.info
		this.orderList = productDummay.list
	},
	data() {
		return {
			info: {},
			orderList: [],
		}
	},
	computed: {
		sellerInfo() {
			//공급자 정보
			let list = []
			list.push({
				name: '사업자 등록번호',
				value: this.info?.seller.corpNo,
				all: true,
			})
			list.push({ name: '상호', value: this.info?.seller.corpName })
			list.push({ name: '대표자', value: this.info?.seller.ceoName })
			list.push({
				name: '연락처',
				value: this.info?.seller.phone,
				all: true,
			})
			list.push({
				name: '사업장주소',
				value: this.info?.seller.address,
				style: `height: 72px`,
				all: true,
			})

			return list
		},
		recipientInfo() {
			//수신자 정보
			let list = []
			list.push({
				name: '소속',
				value: this.info?.recipient.groupName,
				all: true,
			})
			list.push({
				name: '이름',
				value: this.info?.recipient.name,
				all: true,
			})
			list.push({
				name: '연락처',
				value: this.info?.recipient.phone,
				all: true,
			})
			list.push({
				name: '이메일',
				value: this.info?.recipient.email,
				all: true,
			})
			list.push({
				name: '견적일',
				value: this.dateToString(this.info?.create_datetime),
				all: true,
			})

			return list
		},
		assigneeInfo() {
			//담당자 정보
			let list = []
			list.push({ name: '소속', value: this.info?.assignee.group.name })
			list.push({ name: '이름', value: this.info?.assignee.name })
			list.push({ name: '연락처', value: this.info?.assignee.phone })
			list.push({ name: '이메일', value: this.info?.assignee.email })

			return list
		},
		orderHeaders() {
			//주문정보 header
			let list = []
			list.push({ key: 'seller', name: '판매자 정보', width: 100 })
			list.push({ key: 'product', name: '제품 정보', width: 480 })
			list.push({ key: 'stock', name: '수량', width: 80 })
			list.push({ key: 'price', name: '소비자가', width: 100 })
			list.push({ key: 'saleprice', name: '판매가', width: 140 })

			return list
		},
		orderPriceInfo() {
			//주문 금액 info
			let info = {}
			info.price = {
				// 상품 금액
				val: this.sumPrice('price'),
				text: '총 상품 금액',
			}
			info.shippingPrice = {
				val: 0,
				text: '총 배송비',
			}
			info.discount = {
				val: 5000,
				text: '총 할인금액',
			}
			info.vat = {
				val: info.price.val / 10,
				text: 'VAT(10%)',
			}
			info.allPrice = {
				val: info.price.val + info.shippingPrice.val - info.discount.val + info.vat.val,
				text: '총 주문 금액',
			}
			return info
		},
	},
	methods: {
		printSheet() {
			//
		},
		sumPrice(key) {
			let result = 0

			this.orderList.forEach(item => {
				result = result + item[key]
			})

			return result
		},
	},
}
</script>

<style lang="scss" scoped>
.EstimateSheet {
	width: 960px;
	margin: 40px auto;
	border-bottom: 8px solid $PRIMARY_YELLOW;
	.flex {
		button {
			width: 114px;
		}
	}
	.title {
		width: 100%;
		border-top: 8px solid $PRIMARY_YELLOW;
		margin-top: 40px;
		padding: 24px;
		height: 84px;
		display: flex;
		align-items: center;
		h4 {
			font-size: 1.5rem;
			font-weight: 700;
		}
		h6 {
			font-size: 1.125rem;
			font-weight: 500;
			color: $COLOR_666;
			margin-left: 1rem;
		}
		img {
			margin-left: auto;
		}
	}
	.info {
		margin-bottom: 1rem;
		.simple-info-table {
			&:nth-child(1) {
				width: 328px;
			}
			&:nth-child(2) {
				width: calc(100% - 344px);
				margin-left: auto;
			}
		}
	}
	.notice {
		width: 100%;
		display: flex;
		align-items: center;
		height: 36px;
		margin-top: 1rem;
		span {
			margin-left: auto;
			display: inline-block;
			font-size: 0.875rem;
			font-weight: 500;
			color: $COLOR_666;
		}
	}
	.simple-textarea-form {
		margin-top: 1.5rem;
	}
}
</style>
