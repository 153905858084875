<template>
	<div id="List">
		<div class="wrapper">
			<brand-list class="boxwrap" :title="listTitle" :subtext="subtext" :list="popularList" :isShowBtn="false" :type="$route.params.category" />
			<sort-list class="boxwrap" :title="sortListTitle" :list="list" :isShowBtn="false" />
		</div>
	</div>
</template>

<script>
import BrandList from '@/components/list/BrandList.vue'
import SortList from '@/components/list/SortList.vue'
import { mapActions, mapState } from 'vuex'

export default {
	name: 'Brand',
	components: {
		BrandList,
		SortList,
	},
	data() {
		return {}
	},
	computed: {
		...mapState('brand', ['brandList', 'popularBrandList']),
		...mapState('seller', ['sellerList', 'popularSellerList']),
		listTitle() {
			if (this.$route.params.category == 'brand') {
				return '추천 브랜드'
			} else {
				return '판매자 관'
			}
		},
		subtext() {
			if (this.$route.params.category == 'brand') {
				return '랩매니저 스토어에서 가장 많이 판매된 브랜드'
			} else {
				return ''
			}
		},
		sortListTitle() {
			if (this.$route.params.category == 'brand') {
				return '브랜드 전체'
			} else {
				return '판매자 전체'
			}
		},
		list() {
			if (this.$route.path.indexOf('brand') >= 0) {
				return JSON.parse(JSON.stringify(this.brandList))
			} else {
				return JSON.parse(JSON.stringify(this.sellerList))
			}
		},
		popularList() {
			if (this.$route.path.indexOf('brand') >= 0) {
				return JSON.parse(JSON.stringify(this.popularBrandList))
			} else {
				return JSON.parse(JSON.stringify(this.popularSellerList))
			}
		},
	},
	watch: {
		'$route.params.category': {
			deep: true,
			async handler(oldVal) {
				if (oldVal === 'brand') {
					await this.lookupBrandList()
					this.lookupPopularBrandList({ offset: 0, length: 12 })
				} else {
					await this.lookupSellerList()
					this.lookupPopularSellerList({ offset: 0, length: 12 })
				}
			},
		},
	},
	async created() {
		await this.loadList()
	},
	methods: {
		...mapActions('brand', ['lookupBrandList', 'lookupPopularBrandList']),
		...mapActions('seller', ['lookupSellerList', 'lookupPopularSellerList']),
		async loadList() {
			if (this.$route.path.indexOf('brand') >= 0) {
				await this.lookupBrandList()
				this.lookupPopularBrandList({ offset: 0, length: 12 })
			} else {
				await this.lookupSellerList()
				this.lookupPopularSellerList({ offset: 0, length: 12 })
			}
		},
	},
}
</script>

<style lang="scss" scoped>
#List {
	.boxwrap {
		margin-top: 40px;
		border-bottom: 1px solid #eee;
		padding-bottom: 30px;
		&.brand-list {
			margin-top: 30px;
		}
	}
}
</style>
