import axiosInstance from '@/services/loggerAxios'
import { getToken } from '@/services/api/common'
import cookies from 'vue-cookies'

const endPoint = {
	search: () => '/topics/lms-search-v2',
	searchSuccess: () => '/topics/lms-search-v2',
	getIP: () => '/lms-remote',
}

const API = {
	search: ({ keyword, type, recent, user_id }) => {
		try {
			axiosInstance.post(
				endPoint.search(),
				{
					records: [
						{
							value: {
								log_type: 'lms-search',
								token: getToken(),
								user_id: user_id || null,
								// session_id: session_id,
								client_ip: cookies.get('currentIP'),
								keyword,
								type,
								recent,
								timestamp: new Date().getTime(),
							},
						},
					],
				},
				{
					headers: {
						'Content-Type': 'application/vnd.kafka.json.v2+json',
						Accept: 'application/vnd.kafka.v2+json',
					},
				}
			)
		} catch (e) {
			console.log(e)
		}
	},
	searchSuccess: ({ keyword, type, filter, itemType, itemValue, offset, user_id }) => {
		try {
			axiosInstance.post(
				endPoint.searchSuccess(),
				{
					records: [
						{
							value: {
								log_type: 'lms-search-success',
								token: getToken(),
								user_id: user_id || null,
								// session_id: session_id,
								client_ip: cookies.get('currentIP'),
								keyword,
								type,
								filter,
								item: {
									type: itemType,
									id: itemValue,
								},
								offset,
								timestamp: new Date().getTime(),
							},
						},
					],
				},
				{
					headers: {
						'Content-Type': 'application/vnd.kafka.json.v2+json',
						Accept: 'application/vnd.kafka.v2+json',
					},
				}
			)
		} catch (e) {
			console.log(e)
		}
	},
	getIP: () => axiosInstance.get(endPoint.getIP()),
}

export default API
