<template>
	<div class="payment-info-table">
		<div class="title" v-if="title" :class="{ small: checkable }">
			<h6>{{ title }}</h6>
		</div>
		<div class="check-all" v-if="showCheckAll">
			<input id="checkAll" type="checkbox" class="common" v-model="allChecked" />
			<label for="checkAll">전체선택</label>
		</div>
		<div class="content">
			<div class="product-info-table">
				<div class="headers">
					<div v-for="(header, index) in headers" :key="`header_${index}`" :style="`width: ${header.width}`">{{ header.text }}</div>
				</div>
				<div class="body">
					<div class="row" v-for="(item, index) in list" :key="`product_${index}`">
						<div class="column" :style="`width: ${headers[0].width}`">
							<div v-if="checkable" v-b-tooltip.hover.top="!item.canPay ? disabledTooltip : ''">
								<input v-if="!item.canPay" type="checkbox" class="common" disabled />
								<input v-else type="checkbox" v-model="item.isChecked" class="common" @change="onCheckedItem($event, item)" />
							</div>
							<selectable-product-item :item="item" :showOrderInfo="showOrderInfo" />
						</div>
						<div class="column price" :style="`width: ${headers[1].width}`" style="display: grid">
							<b :class="setStatus(item)">{{ CommaNum(item.orderItemPrice) }}원</b>
							<div class="status" v-if="setStatus(item)" :class="setStatus(item)">부분 결제취소 완료</div>
						</div>
					</div>
					<div class="no-list" v-if="list.length == 0">
						<div class="no-content">
							<img src="@/assets/svg/content/no-payment.svg" />
							<p>{{ noDataText }}</p>
						</div>
					</div>
				</div>
				<div class="footer" v-if="checkable">
					<span>결제 금액 합계</span>
					<div>
						<span class="currency-title">주문 금액 합계 </span>{{ CommaNum(price) }}원
						<span class="currency-title"> <img src="@/assets/svg/cart/plus.svg" />&nbsp;&nbsp;배송비 </span> 0원
						<span class="currency-title"> <img src="@/assets/svg/cart/plus.svg" />&nbsp;&nbsp;VAT(10%) </span>
						{{ CommaNum(vat) }}원
						<img src="@/assets/svg/cart/equal.svg" style="padding: 0 12px" />
						<span class="total">{{ CommaNum(totalPrice) }}원</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import SelectableProductItem from '@/components/item/SelectableProductItem'

export default {
	name: 'PaymentInfoTable',
	components: {
		SelectableProductItem,
	},
	mixins: [mixin],
	props: {
		title: {
			type: String,
			default: '',
		},
		headers: {
			type: Array,
			default: () => [],
		},
		list: {
			type: Array,
			default: () => [],
		},
		checkable: {
			type: Boolean,
			default: false,
		},
		showCheckAll: {
			type: Boolean,
			default: false,
		},
		disabledTooltip: {
			type: String,
			default: '',
		},
		showOrderInfo: {
			type: Boolean,
			default: false,
		},
		noDataText: {
			type: String,
			default: '',
		},
		paymentStatus: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			checkList: [],
		}
	},
	computed: {
		price() {
			return this.checkList.reduce((acc, cur) => {
				return acc + cur.orderItemPrice
			}, 0)
		},
		vat() {
			return this.checkList.reduce((acc, cur) => {
				return acc + cur.orderItemVat
			}, 0)
		},
		totalPrice() {
			return this.checkList.reduce((acc, cur) => {
				return acc + cur.orderItemPriceWithVat
			}, 0)
		},
		allChecked: {
			get() {
				return !this.list.filter(item => item.canPay).some(it => it.isChecked === false)
			},
			set(val) {
				this.$emit('checkAll', val)
			},
		},
	},
	watch: {
		list(val) {
			if (!val) return

			this.checkList = val.filter(item => item.canPay).filter(item => item.isChecked)
			this.$emit('check', this.checkList)
		},
	},
	methods: {
		// onCheckAll(e) {
		// 	this.$emit('checkAll', e.target.checked)
		// },
		onCheckedItem(e, item) {
			if (e.target.checked) {
				this.checkList.push(item)
			} else {
				const index = this.checkList.findIndex(checkItem => checkItem.orderItemId === item.orderItemId)
				if (index < 0) return

				this.checkList.splice(index, 1)
			}
			this.$emit('check', this.checkList)
		},
		setStatus(item) {
			if (this.paymentStatus === 'CANCEL') return
			if (item.status === 'CANCEL') {
				return 'fail'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-info-table {
	width: 100%;
	.title {
		width: 100%;
		margin-bottom: 1rem;
		display: flex;
		align-items: center;
		&.small {
			border-bottom: 1px solid #d9d9d9;
			padding-bottom: 1rem;
			margin-bottom: 22px;
			h6 {
				font-size: 0.875rem;
			}
		}
		h6 {
			font-size: 1.25rem;
		}
	}
	.check-all {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		> label {
			font-weight: 700;
			font-size: 1rem;
		}
	}
	.content {
		width: 100%;
		border-radius: 5px;
		border: 1px solid $COLOR_DDD;
		overflow: hidden;
		.headers {
			width: 100%;
			height: 60px;
			background-color: #f6f6f6;
			display: flex;
			align-items: center;
			padding: 0 20px;
			border-bottom: 1px solid $LINE_DIVIDER;
			> div {
				font-weight: 700;
				text-align: center;
				font-size: 0.875rem;
				&:first-child {
					text-align: left;
				}
			}
		}
		.body {
			max-height: 882px;
			overflow-y: auto;
			.row {
				display: flex;
				align-items: center;
				border-bottom: 1px solid $LINE_DIVIDER;
				margin: 0 0;
				&:last-child {
					border-bottom: 0;
				}
				.column {
					border-right: 1px solid $LINE_DIVIDER;
					display: inline-flex;
					align-items: center;
					padding: 0 0;
					&:last-child {
						border-right: 0;
						flex-wrap: wrap;
					}
					&:first-child {
						padding-left: 20px;
					}
					.selectable-product-item {
						border: 0;
						border-radius: 0;
						box-sizing: border-box;
						width: unset;
					}
					&.price {
						text-align: center;
						justify-content: center;
						span {
							margin-top: 10px;
						}
					}
					b.fail {
						color: $COLOR_999;
						text-decoration: line-through;
					}
					.status {
						margin-top: 10px;
					}
				}
			}
			.no-list {
				height: 320px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				text-align: center;
				img {
					width: 200px;
					height: 200px;
					display: block;
					margin: 0 auto;
				}
				p {
					font-size: 1rem;
					font-weight: 500;
					color: $COLOR_999;
					display: block;
					margin: 24px auto 32px;
				}
			}
		}
	}
	.footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 10px;
		height: 100px;
		background: $LIST_BACKGROUND;
		border-top: 1px solid $COLOR_EEE;
		> span {
			font-weight: 700;
			font-size: 0.875rem;
		}
		> div {
			display: flex;
			gap: 8px;
			align-items: center;
			font-weight: 400;
			font-size: 1rem;
			.currency-title {
				color: $COLOR_666;
			}
			.total {
				font-weight: 700;
				font-size: 1.25rem;
			}
			img {
				padding-bottom: 3px;
			}
		}
	}
}
</style>
