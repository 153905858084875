<template>
	<div class="RequestProductDetail" v-if="id == requestProduct?.id">
		<content-title :title="title" :datetime="infoDatetime" />
		<div class="content-wrapper">
			<ProductInfoTable class="payment-product-info-table" :requestProduct="requestProduct" />
			<div class="sadadream-explain" v-if="requestProduct.type == 'PURCHASE_AGENT' && requestProduct.status == 'COMPLETE'">
				구매대행은 제한된 상품을 쉽게 구매하는 서비스입니다. 본 상품의 경우 상품 확보, 가격 변동의 위험 감소 및 배송 과정 진행 등 상품을
				안정적으로 구매하기 위해 <b>선결제</b>가 반드시 이루어져야만 합니다.
			</div>
			<div class="button-groups">
				<button class="btn btn-primary lg" @click="addRequest">상품 요청하기</button>
			</div>
		</div>
		<ModalSadadreamRequest ref="ModalSadadreamRequest" @success="onSuccessRequest" />
		<ModalConfirm ref="ModalRequestComplete" :noCancel="false" title="상품 요청 완료" content="상품 요청이 완료되었습니다." />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import mixin from '@/mixins'
import date from '@/mixins/date'
import ContentTitle from '@/components/content/ContentTitle'
import ContentNotice from '@/components/content/ContentNotice'
import SimpleInfoBox from '@/components/list/SimpleInfoBox'
import ProductInfoTable from './components/OtherProductInfoTable'
import ModalSadadreamRequest from './modal/ModalSadadreamRequest'
import { gaEventCart } from '@/utils/ga'
import { ToastType } from '@/utils/define/ToastType'
import ModalConfirm from '../../components/modal/ModalConfirm.vue'
import PageName from '@/utils/define/PageName'
export default {
	name: 'SadadreamDetail',
	props: {
		id: [Number, String],
	},
	components: {
		ContentTitle,
		ContentNotice,
		SimpleInfoBox,
		ProductInfoTable,
		ModalSadadreamRequest,
		ModalConfirm,
	},
	mixins: [mixin, date],
	data() {
		return {
			requestProduct: null,
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		title() {
			return this.requestProduct?.no
		},
		infoDatetime() {
			return `요청일: ${this.dateToString(this.requestProduct?.createDatetime)}`
		},
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return
				this.fetchRequestProduct()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('requestProduct/other', ['getOtherDetail']),
		async fetchRequestProduct() {
			this.requestProduct = await this.getOtherDetail({
				instituteId: this.instituteId,
				id: this.id,
			})
			this.requestProduct.product.quantity = this.requestProduct.quantity
		},
		addRequest() {
			this.$refs['ModalSadadreamRequest'].show(this.requestProduct.product)
		},
		onSuccessRequest() {
			this.$refs['ModalRequestComplete'].show()
		},
	},
}
</script>

<style lang="scss" scoped>
.RequestProductDetail {
	width: 1080px;
	margin: 0px auto;
	.content-wrapper {
		> div {
			margin-bottom: 50px;
		}
		.content-notice {
			margin-bottom: 24px;
		}
		.sadadream-explain {
			padding: 10px 20px;
			border-radius: 5px;
			font-size: 14px;
			line-height: 160%;
			background-color: #fff3d6;
		}
		.button-groups {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;
			button {
				width: 240px;
				height: 48px;
				font-size: 16px;
				&.btn-primary {
					background-color: $PRIMARY_YELLOW;
				}
			}
			.cart-tooltip {
				padding: 20px 15px 15px 15px;
				width: 350px;
				height: 113px;
				top: -130px;
				left: calc(50% - 300px);
				border-radius: 5px;
				border: solid 1px #ddd;
				position: absolute;
				background: $PRIMARY_WHITE;
				div {
					display: flex;
					justify-content: space-between;
					img {
						cursor: pointer;
					}
				}
				p {
					font-weight: 700;
					font-size: 16px;
					span {
						font-weight: 400;
					}
				}
				.btn-view-cart {
					width: 100%;
					height: 40px;
					margin-top: 10px;
					background: $PRIMARY_YELLOW;
					&:hover {
						background: #fec741 linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
						color: black;
					}
				}
			}
			.cart-tooltip:after {
				border-bottom: 0px solid transparent;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-top: 8px solid $PRIMARY_WHITE;
				content: '';
				position: absolute;
				top: 110px;
				left: 160px;
				z-index: 1004;
			}
			.cart-tooltip:before {
				border-bottom: 0px solid transparent;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-top: 8px solid #ddd;
				content: '';
				position: absolute;
				top: 111px;
				left: 160px;
				z-index: 1003;
			}
		}
	}
}
</style>
