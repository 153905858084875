<template>
	<b-modal id="ModalInviteMember" ref="modal" title="멤버 초대" centered hide-footer @show="onShow">
		<div class="modal-invite-member-wrapper">
			<div class="link">
				<p>링크로 초대하기</p>
				<div class="input-area">
					<div>
						<lottie-animation
							v-show="isShowLottie"
							ref="lottie-animation"
							:animationData="require('@/assets/json/lm_loading_lottie.json')"
							:autoPlay="false"
							:loop="true"
							class="lottie-animation"
						></lottie-animation>
						<input :value="invitationLink" ref="invitationLinkInput" type="text" readonly />
					</div>
					<button class="Btn__link" @click="copyToClipboardLink">
						<img src="@/assets/svg/duplicate.svg" />
						링크 복사
					</button>
				</div>
			</div>
			<div>
				<email-text-area ref="emailTextArea" />
			</div>
			<div class="footer">
				<button class="btn btn-gray lg" @click="onSend">이메일 전송</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import EmailTextArea from '@/components/input/EmailTextArea.vue'
import LottieAnimation from 'lottie-web-vue'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'ModalInviteMember',
	components: {
		EmailTextArea,
		LottieAnimation,
	},
	data() {
		return {
			isShowLottie: false,
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('member', ['invitationLink']),
	},
	methods: {
		...mapMutations('member', ['setInvitationLink']),
		...mapActions('member', ['lookupInvitationLink', 'inviteMember']),
		async onShow() {
			await this.$nextTick()
			this.fetchInvitationLink()
		},
		playLoading() {
			this.isShowLottie = true
			this.$refs['lottie-animation'].anim.play()
		},
		stopLoading() {
			this.isShowLottie = false
			this.$refs['lottie-animation'].anim.stop()
		},
		async fetchInvitationLink() {
			try {
				this.playLoading()
				this.setInvitationLink('링크 생성중...')
				await this.lookupInvitationLink(this.instituteId)
				this.stopLoading()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		copyToClipboardLink() {
			navigator.clipboard
				.writeText(this.invitationLink)
				.then(() => {
					this.$root.toast('초대 링크 복사', '초대 링크가 복사되었습니다.', ToastType.SUCCESS)
				})
				.catch(() => {
					this.$root.toast('초대 링크 복사', '초대 링크 복사를 실패하였습니다.', ToastType.ERROR)
				})
		},
		async onSend() {
			const emails = this.$refs['emailTextArea'].getEmailList()
			if (emails.length <= 0) return

			try {
				await this.inviteMember({
					instituteId: this.instituteId,
					body: {
						emails: emails,
					},
				})

				this.$root.toast('초대 이메일 전송 완료', '초대 이메일을 전송했습니다.', ToastType.SUCCESS)
				this.$refs.modal.hide()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss">
#ModalInviteMember___BV_modal_outer_ {
	.modal-dialog {
		max-width: 500px;
	}
	#ModalInviteMember___BV_modal_header_ {
		&.modal-header {
			border-bottom: 1px solid $COLOR_EEE;
		}
	}
}
</style>

<style lang="scss" scoped>
.modal-invite-member-wrapper {
	display: flex;
	flex-direction: column;
	gap: 15px;
	.link {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-top: 14px;
		margin-bottom: 15px;
		> p {
			font-weight: 700;
			font-size: 0.75rem;
		}
		.input-area {
			display: flex;
			> div {
				display: flex;
				width: 330px;
				margin-right: 10px;
				height: 30px;
				border: 1px solid #ccc;
				border-radius: 5px;
				background-color: #f5f5f5;
			}
			.lottie-animation {
				width: 76px;
				height: 76px;
				margin: -24px -24px 0 -24px;
			}
			input {
				width: 100%;
				border: 0;
				background-color: transparent;
				padding-left: 8px;
				font-size: 0.875rem;
			}
			button {
				width: calc(100% - 340px);
				border: 1px solid #000;
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 0.875rem;
				font-weight: 700;
				height: 30px;
				img {
					width: 20px;
				}
			}
		}
	}
	.footer {
		text-align: center;
		.btn {
			width: 240px;
			height: 40px;
			background: $PRIMARY_YELLOW;
		}
	}
}
</style>
