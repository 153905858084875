<template>
	<div></div>
</template>

<script>
export default {
	name: 'Invitation',
}
</script>

<style lang="scss" scoped></style>
