export default {
	autoHypenTel(str) {
		// ref: https://hgko1207.github.io/2019/01/18/javascript-autoHypenTel/
		str = str.replace(/[^0-9]/g, '')
		let tmp = ''

		if (str.substring(0, 2) == '02') {
			// 서울 전화번호일 경우 10자리까지만 나타나고 그 이상의 자리수는 자동삭제
			if (str.length < 3) {
				return str
			} else if (str.length < 6) {
				tmp += str.substr(0, 2)
				tmp += '-'
				tmp += str.substr(2)
				return tmp
			} else if (str.length < 10) {
				tmp += str.substr(0, 2)
				tmp += '-'
				tmp += str.substr(2, 3)
				tmp += '-'
				tmp += str.substr(5)
				return tmp
			} else {
				tmp += str.substr(0, 2)
				tmp += '-'
				tmp += str.substr(2, 4)
				tmp += '-'
				tmp += str.substr(6, 4)
				return tmp
			}
		} else {
			// 핸드폰 및 다른 지역 전화번호 일 경우
			if (str.length < 4) {
				return str
			} else if (str.length < 7) {
				tmp += str.substr(0, 3)
				tmp += '-'
				tmp += str.substr(3)
				return tmp
			} else if (str.length < 11) {
				tmp += str.substr(0, 3)
				tmp += '-'
				tmp += str.substr(3, 3)
				tmp += '-'
				tmp += str.substr(6)
				return tmp
			} else {
				tmp += str.substr(0, 3)
				tmp += '-'
				tmp += str.substr(3, 4)
				tmp += '-'
				tmp += str.substr(7)
				return tmp
			}
		}
	},
	validateTel(value) {
		this.isValid = !!value.match(/^[0-9]{3}[-]+[0-9]{3,4}[-]+[0-9]{4}$/)
		return this.isValid
	},
}
