<template>
	<div class="cart-product-info-table">
		<div class="header">
			<div class="checkbox-wrapper">
				<input type="checkbox" v-model="checkAll" id="checkAll" class="common" />
				<label for="checkAll">전체선택</label>
			</div>
			<div class="button-wrapper">
				<button class="btn btn-common sm" @click="onDelete()">선택 삭제</button>
			</div>
		</div>
		<div class="content" :class="{ error: isError }">
			<table class="product-info-table">
				<thead>
					<tr>
						<th style="width: 650px"><span>상품 정보</span></th>
						<th style="width: 80px"><span>수량</span></th>
						<th style="width: 110px"><span>판매가</span></th>
						<th style="width: 90px"><span>담은사람</span></th>
					</tr>
				</thead>
				<tbody :class="{ error: isError }">
					<template v-if="isEmpty">
						<tr>
							<td colspan="4">
								<div class="no-product-list">
									<div class="image">
										<img :src="require(`@/assets/svg/cart/${isError ? 'cart-empty-error.svg' : 'cart-empty.svg'}`)" alt="" />
									</div>
									<div class="text" :class="{ error: isError }">장바구니에 상품이 없습니다</div>
								</div>
							</td>
						</tr>
					</template>
					<template v-else>
						<tr v-for="(product, index) in products" :key="`row_${product.id}_${index}`" class="product-info-table-list">
							<td>
								<div>
									<cart-product-info
										ref="cart-product-info"
										:index="index"
										:product="product"
										@onDelete="onDelete"
										@changeChecked="changeChecked"
									></cart-product-info>
								</div>
							</td>
							<td>
								<div class="quantity">
									<input type="number" :value="product.quantity" @input="onInput($event, index)" />
								</div>
							</td>
							<td>
								<div class="selling-price">{{ CommaNum(product.sellingPrice) }}원</div>
							</td>
							<td>
								<div class="cart-user">{{ product.userName }}</div>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
			<cart-product-total-price ref="cart-product-total-price" class="cart-product-total-price"></cart-product-total-price>
		</div>
		<div v-if="isErrorMessage" class="error-wrapper">
			<img class="error-image" src="@/assets/svg/icon-login-error.svg" />
			<span class="error-content">주문,견적 상품을 선택해 주세요</span>
		</div>
		<modal-cancel id="ModalCancel2" title="선택한 상품 삭제" content="선택할 상품을 삭제하시겠습니까?" @ok="onDeleteCart" />
	</div>
</template>

<script>
import CartProductInfo from '@/views/cart/components/CartProductInfo.vue'
import CartProductTotalPrice from '@/views/cart/components/CartProductTotalPrice.vue'
import ModalCancel from '@/components/modal/ModalCancel'
import { mapGetters, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import { isLogin } from '@/services/api/common'
import mixin from '@/mixins'
import CartMixin from '@/mixins/cart'
import _ from 'lodash'

export default {
	name: 'CartProductInfoTable',
	components: {
		CartProductInfo,
		CartProductTotalPrice,
		ModalCancel,
	},
	mixins: [mixin, CartMixin],
	data() {
		return {
			isValid: true,
			checkAll: false,
			cartIds: [],
			indexes: [],
			products: [],
		}
	},
	watch: {
		checkAll() {
			if (this.isEmpty || !this.$refs['cart-product-info']) return
			this.$refs['cart-product-info'].forEach(component => {
				component.check(this.checkAll)
			})
		},
		cartList: {
			async handler(newVal) {
				this.products = this.$_.cloneDeep(newVal)
				await this.$nextTick()
				this.changeChecked()
			},
			deep: true,
		},
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapGetters('cart', ['cartList']),
		isError() {
			return !this.isValid
		},
		isErrorMessage() {
			return !this.isValid && !this.isEmpty
		},
		isEmpty() {
			return this.$_.isEmpty(this.products)
		},
	},
	mounted() {
		this.checkAll = true
	},
	methods: {
		...mapActions('cart', ['updateCart', 'deleteCart']),
		getChecked() {
			if (this.isEmpty || !this.$refs['cart-product-info']) return []
			return this.$refs['cart-product-info'].filter(component => component.getCheck() === true)
		},
		getCheckedProductList() {
			return this.getChecked().map(component => component.getProduct())
		},
		getCheckedIndexes() {
			return this.getChecked().map(component => component.getIndex())
		},
		onDelete(index) {
			if (index != null) {
				this.cartIds = [this.products[index].id]
				this.indexes = [index]
			} else {
				if (!this.validate()) return
				this.cartIds = this.getCheckedProductList().map(({ id }) => id)
				this.indexes = this.getCheckedIndexes()
			}
			this.$bvModal.show('ModalCancel2')
		},
		async onDeleteCart() {
			try {
				if (!isLogin()) {
					this.removeNonMemberCartProductList(this.indexes)
				} else {
					await this.deleteCart({ instituteId: this.instituteId, params: { cartIds: this.cartIds } })
				}

				this.$emit('changeCartList')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		validate() {
			this.isValid = !this.$_.isEmpty(this.getCheckedProductList())
			return this.isValid
		},
		onInput: _.debounce(function (event, index) {
			let value = event.target.value
			if (!event.target.value || event.target.value <= 0) value = 1
			const product = this.products[index]
			product.quantity = value
			if (!isLogin()) {
				this.updateNonMemberCartProductList(index, value)
				this.$emit('changeCartList')
			} else {
				this.onUpdateCart(product)
			}

			this.setProductTotalPrice()
		}, 300),
		async onUpdateCart({ id, quantity }) {
			try {
				await this.updateCart({ instituteId: this.instituteId, body: { cartId: id, quantity } })
				this.$emit('changeCartList')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		changeChecked() {
			this.setProductTotalPrice()
			this.$emit('changeChecked', this.getCheckedProductList().length)
		},
		setProductTotalPrice() {
			const productList = this.getCheckedProductList().map(({ productId, quantity }) => {
				return { productId, quantity }
			})

			if (this.$_.isEmpty(this.$refs['cart-product-total-price'])) return
			this.$refs['cart-product-total-price'].setProductList(productList)
		},
	},
}
</script>

<style lang="scss" scoped>
.cart-product-info-table {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		.checkbox-wrapper {
			display: flex;
			align-items: center;
			label {
				font-size: 0.875rem;
				font-weight: 700;
				cursor: pointer;
			}
		}
		.button-wrapper {
			button {
				font-weight: 700;
			}
		}
	}
	.content {
		max-height: calc(60px + 750px + 90px);
		overflow-y: auto;
		margin-top: 10px;
		border: 1px solid $COLOR_DDD;
		border-radius: 5px;
		&.error {
			border: 1px solid $PRIMARY_RED;
		}
		.product-info-table {
			width: 100%;
			thead {
				height: 60px;
				background: $LIST_BACKGROUND;
				border-radius: 5px;
				position: sticky;
				top: 0;
				z-index: 1;
				tr {
					th {
						background-color: $LIST_BACKGROUND;
						text-align: center;
						font-size: 0.875rem;
						&:first-child {
							padding-left: 20px;
							text-align: left;
						}
					}
				}
			}
			tbody {
				border-top: 1px solid $COLOR_DDD;
				border-bottom: 1px solid $COLOR_DDD;
				&.error {
					border-top: 1px solid $PRIMARY_RED;
					border-bottom: 1px solid $PRIMARY_RED;
				}
				.no-product-list {
					height: 400px;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					.image {
						justify-content: center;
						align-items: center;
					}
					.text {
						margin-top: 30px;
						color: $FONT_PLACEHOLDER;
						font-weight: 500;
						&.error {
							color: $PRIMARY_RED;
						}
					}
				}
				tr {
					height: 150px;
					&:not(:last-child) {
						border-bottom: 1px solid $COLOR_DDD;
					}
					td {
						&:first-child div {
							padding: 10px;
						}
						&:not(:first-child) div {
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.quantity {
							input[type='number'] {
								width: 48px;
								height: 30px;
								border: 1px solid $COLOR_DDD;
								border-radius: 5px;
								text-align: center;
								font-size: 0.875rem;
								padding: 10px;
							}
						}
						.selling-price {
							font-weight: 700;
						}
						.cart-user {
							font-size: 0.75rem;
							color: $COLOR_333;
							font-weight: 500l;
						}
					}
					td + td {
						border-left: 1px solid $COLOR_DDD;
					}
				}
			}
		}

		.cart-product-total-price {
			position: sticky;
			bottom: 0;
		}
	}

	.error-wrapper {
		display: flex;
		align-items: center;
		margin-top: 5px;
		.error-image {
			width: 16px;
			height: 16px;
		}
		.error-content {
			margin-left: 6px;
			font-size: 0.875rem;
			font-weight: 500;
			color: $PRIMARY_RED;
		}
	}
}
</style>
