<template>
	<b-modal ref="modal" centered no-close-on-esc no-close-on-backdrop hide-footer>
		<template #modal-title>
			<div class="flex">출금 가능한 <img src="@/assets/svg/icon-npay-m.svg" /></div>
		</template>
		<div class="content">
			<div class="explain">
				<li>랩매니저 스토어에서 주문한 금액의 3%를 적립해 드립니다. (VAT 포함)</li>
				<li>적립금은 Npay로 출금 가능합니다.</li>
				<li>출금 요청한 Npay는 기프티콘 형태로 문자 발송됩니다.</li>
				<li>기프티콘 발송에는 최장 7일까지 소요될 수 있습니다.</li>
				<li>랩매니저 PRO에서 주문한 금액은 적립금으로 적립되지 않습니다.</li>
			</div>
			<table class="pay-table">
				<thead>
					<tr>
						<th>일시</th>
						<th>주문번호</th>
						<th>구매금액</th>
						<th>적립된 포인트</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in list" :key="item.id">
						<td>{{ new Date(item.createDatetime).toLocaleDateString() }}</td>
						<td :class="{ 'blue-text': !item.no }">{{ item.no ?? '출금' }}</td>
						<td class="warning-text" v-if="item.comment">첫 구매 이벤트</td>
						<td :class="{ 'blue-text': !item.orderAmount }" v-else>
							{{ item.orderAmount?.toLocaleString() ? item.orderAmount?.toLocaleString() + '원' : '출금' }}
						</td>
						<td>{{ item.pointAmount?.toLocaleString() }}원</td>
					</tr>
				</tbody>
			</table>
			<Pagination :isPerPage="false" :defaultLength="10" :total="count" @page="getList" />
			<div class="total-price">
				출금 가능한 <img src="@/assets/svg/icon-npay-m.svg" /> 금액: <span class="error-text">{{ point.toLocaleString() }}원</span>
			</div>
			<button class="btn-npay" @click="rquestChange">적립금을 Npay로 출금</button>
		</div>
	</b-modal>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Pagination from '@/components/pagenation/CommonPagenation.vue'
export default {
	components: {
		Pagination,
	},
	data() {
		return {
			count: 0,
			list: [],
		}
	},
	computed: {
		...mapState('user', ['point']),
	},
	methods: {
		...mapActions('user', ['getPointLog']),
		async show() {
			this.count = 0
			this.list = []
			await this.getList({ offset: 0, length: 10 })
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		rquestChange() {
			this.$emit('requestChange')
			this.hide()
		},
		async getList({ offset, length }) {
			try {
				const { count, list } = await this.getPointLog({ offset, length })
				this.count = count
				this.list = list
				// this.count = 0
				// this.list = []
			} catch (e) {
				this.count = 0
				this.list = []
			}
		},
	},
}
</script>
<style scoped lang="scss">
::v-deep {
	.modal-title {
		display: flex;
		align-items: center;
		gap: 20px;
		.flex {
			align-items: center;
			gap: 10px;
		}
	}
	.modal-dialog {
		width: 600px;
		max-width: 600px;
	}
	.modal-body {
		padding-top: 0px;
	}
	.content {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: center;
		.explain {
			width: 100%;
			font-size: 14px;
			color: #666;
			font-weight: 500;
			padding-left: 10px;
			li {
				list-style: inside;
			}
		}
		.btn-npay {
			width: 240px;
			padding: 10px;
			border: 1px solid #ddd;
			border-radius: 5px;
			font-size: 16px;
			font-weight: bold;
		}
		.pay-table {
			width: 100%;
			text-align: center;
			th {
				font-size: 14px;
				color: #333;
				font-weight: 500;
			}
			td {
				font-size: 14px;
				color: #666;
			}
			tr {
				height: 40px;
				border-bottom: 1px solid #eee;
			}
		}
		.total-price {
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 16px;
			font-weight: 700;
		}
	}
}
</style>
