import gaAPI from './api'
import { pageNames } from '@/utils/pages'
import orderAPI from '@/services/api/order'

//!!!!!!!!!
export const gaEventViewItem = item => {
	try {
		const form = {
			items: [
				{
					item_id: item.productInfoId,
					item_name: item.name,
					item_brand: item.brand?.name || '',
					item_category: item.category?.korName,
				},
			],
		}
		gaAPI.gaEventViewItem(form)
	} catch (e) {
		console.log(e)
	}
}
const makeGaItems = order => {
	const items = order.items.reduce((items, item) => {
		let option = '-'
		if (item.product?.options?.length) {
			option = item.product.options[0].value
		}
		return [
			...items,
			{
				item_id: item.product?.productId,
				item_name: item.product?.name,
				item_variant: option,
				item_brand: item.product?.brand?.name,
				price: item.orderItemPrice / item.quantity,
				currency: 'KRW',
				quantity: item.quantity,
			},
		]
	}, [])
	return items
}
//!!!!!!!!!
export const requestGaEventPurchase = async (instituteId, orderId) => {
	try {
		const response = await orderAPI.lookupOrder(instituteId, orderId)
		if (response.data) {
			const order = response.data
			const purchaseForm = {
				currency: 'KRW',
				items: makeGaItems(order),
				value: order.totalOrderItemPrice,
				tax: order.totalOrderItemVat,
				transaction_id: order.orderNo,
			}
			console.log('purchaseForm : ', purchaseForm)
			gaAPI.gaEventPurchase(purchaseForm)
		}
	} catch (e) {
		console.log(e)
	}
}
//!!!!!!!!!
export const gaEventCart = products => {
	try {
		const formItems = products.reduce((arg, cur) => {
			let option = '-'
			if (cur.options?.length) {
				option = cur.options[0].value
			}
			return [
				...arg,
				{
					item_id: cur.id,
					item_name: cur.name,
					item_variant: option,
					item_brand: cur.brand?.name,
					quantity: cur.quantity,
				},
			]
		}, [])
		const gaForm = {
			items: formItems,
		}
		gaAPI.gaEventCart(gaForm)
	} catch (e) {
		console.log(e)
	}
}

export const gaEventWish = item => {
	try {
		const gaForm = {
			items: [
				{
					item_id: item.productInfoId,
					item_name: item.productInfoName,
					item_brand: item.productInfoBrand.brandName,
				},
			],
		}
		gaAPI.gaEventWish(gaForm)
	} catch (e) {
		console.log(e)
	}
}

export const gaEventSendPageView = to => {
	let pageName = to.name
	let pageTitle
	switch (pageName) {
		case 'main':
			pageTitle = pageNames.HOME
			break
		case 'Search':
			pageTitle = pageNames.SEARCH_LIST
			break
		case 'Detail':
			pageTitle = pageNames.PRODUCT_DETAIL
			break
		case 'Payment':
			pageTitle = pageNames.ORDER
			break
		case 'PaymentComplete':
			pageTitle = pageNames.ORDER_RESULT
			break
		case 'Cart':
			pageTitle = pageNames.CART
			break
		case 'Order':
			pageTitle = pageNames.ORDER_LIST
			break
		case 'Return':
			pageTitle = pageNames.ORDER_CANCEL_LIST
			break
		case 'UnPaymentManagement':
			pageTitle = pageNames.PAYMENT_NEED_LIST
			break
		case 'UnPaymentManagementDetail':
			pageTitle = pageNames.PAYMENT
			break
		case 'CompletePayment':
			pageTitle = pageNames.PAYMENT_RESULT
			break
		case 'PaymentHistory':
			pageTitle = pageNames.PAYMENT_LIST
			break
		case 'EstimateManagement':
			pageTitle = pageNames.ESTIMATE_LIST
			break
		case 'RequestProductList':
			pageTitle = pageNames.REQUEST_LIST
			break
		case 'ShippingAddressManagement':
			pageTitle = pageNames.DELIVERY_LIST
			break
		case 'BusinessRegistrationManagement':
			pageTitle = pageNames.EBILL_LIST
			break
		case 'EasyPaymentManagement':
			pageTitle = pageNames.CARD_LIST
			break
		case 'UserInfo':
			pageTitle = pageNames.USER_INFO
			break
		case 'Inquiry':
			pageTitle = pageNames.INQUIRY
			break
		case 'QuestionManagement':
			pageTitle = pageNames.FAQ
			break
		case 'NoticeManagement':
			pageTitle = pageNames.NOTICE
			break
		case 'EventManagement':
			pageTitle = pageNames.EVENT
			break
		case 'Wish':
			pageTitle = pageNames.WISH_LIST
			break
		case 'RecentView':
			pageTitle = pageNames.RECENT_VIEW_LIST
			break
		case 'LabInfoManagement':
			pageTitle = pageNames.INST_INFO
			break
		case 'FrequentlyOrderList':
			pageTitle = pageNames.FREQ_LIST
			break
		case 'List':
			let params = to.params
			if (params?.category == 'brand') {
				pageTitle = pageNames.BRAND_LIST
			} else if (params?.category == 'seller') {
				pageTitle = pageNames.SELLER_LIST
			}
			break
	}
	const form = {
		page_title: pageTitle,
	}
	gaAPI.gaEventSendPageView(form)
}
