<template>
	<div class="stack-wrapper">
		<span @click="goClassification()">전체 카테고리</span>
		<span v-for="parentCategory of category.parents" :key="parentCategory.code" @click="goClassification(parentCategory.code)">
			<img src="@/assets/svg/arrow_up_s.svg" />{{ parentCategory.korName }}
		</span>
		<span class="category"><img src="@/assets/svg/arrow_up_s.svg" />{{ category.korName }}</span>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import mixin from '@/mixins'
import { mapGetters } from 'vuex'

export default {
	mixins: [mixin],
	computed: {
		...mapGetters('productInfo', ['category']),
	},
	methods: {
		goClassification(classification) {
			this.$router.push({
				name: PageName.SearchList,
				query: {
					classification: classification ? classification : null,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.stack-wrapper {
	display: flex;
	margin-top: 30.5px;
	margin-bottom: 20.5px;
	span {
		font-size: 0.875rem;
		font-weight: 400;
		display: inline-block;
		color: $COLOR_666;
		margin-right: 8.5px;
		cursor: pointer;
		img {
			margin-right: 8.5px;
			margin-top: -2px;
		}
		&.category {
			font-size: 700;
			color: black;
			font-weight: 700;
		}
	}
}
</style>
