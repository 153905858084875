<template>
	<div>
		<base-management title="연구실 정보 관리">
			<div class="lab-info-edit-wrapper">
				<div class="lab-info-edit-content">
					<div>
						<span class="title">소속</span>
						<text-input v-model="item.name" />
					</div>
					<div v-if="item.type">
						<span class="title">소속 유형</span>
						<select-input v-model="item.type.name" :items="types" :disabledFunc="item => item === '일반 개인'" />
					</div>
					<div>
						<span class="title">{{ managerTitle }}</span>
						<text-input v-model="item.managerName" />
					</div>
				</div>
				<div class="lab-info-edit-footer">
					<button class="btn btn-gray lg" @click="$router.go(-1)">취소</button>
					<button class="btn btn-yellow lg" @click="onClickEdit">수정완료</button>
				</div>
			</div>
		</base-management>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PageName from '@/utils/define/PageName'
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import TextInput from '@/components/input/TextInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'

export default {
	name: 'LabInfoEdit',
	components: {
		BaseManagement,
		TextInput,
		SelectInput,
	},
	data() {
		return {
			item: {},
			types: ['학교 소속 연구실', '기업 소속 연구실', '재판매업체(법인)', '일반 개인'],
			convertType: {
				'학교 소속 연구실': 'UNIVERSITY',
				'기업 소속 연구실': 'ENTERPRISE',
				'재판매업체(법인)': 'SELLER',
				'일반 개인': 'PERSON',
			},
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('institute', ['institute']),
		managerTitle() {
			if (this.convertType[this.item.type?.name] == 'ENTERPRISE') {
				return '구매 담당자'
			} else if (this.convertType[this.item?.type?.name] == 'SELLER') {
				return '재판매업체(법인) 이름'
			} else {
				return '교수님'
			}
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				await this.fetchInstitute()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('institute', ['lookupInstitute', 'updateInstitute']),
		async fetchInstitute() {
			await this.lookupInstitute(this.instituteId)
			this.item = this.$_.cloneDeep(this.institute)
		},
		async onClickEdit() {
			try {
				await this.updateInstitute({
					instituteId: this.instituteId,
					body: {
						...this.item,
						type: this.convertType[this.item.type.name],
					},
				})

				this.$root.toast('수정 완료', '소속 정보 수정을 완료했습니다.', 'success')
				this.$router.push({
					name: PageName.MyPage.LabInfoManagement,
				})
			} catch (e) {
				this.$root.toast('수정 실패', e.response.data.msg, 'error')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.lab-info-edit-wrapper {
	display: flex;
	flex-direction: column;
	gap: 64px;
	.lab-info-edit-content {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 20px;
		border: 1px solid $COLOR_DDD;
		border-radius: 5px;
		> div {
			display: flex;
			align-items: center;
			gap: 48px;
			> span {
				font-size: 0.875rem;
				font-weight: 500;
			}
			.title {
				width: 76px;
			}
		}
	}
	.lab-info-edit-footer {
		display: flex;
		justify-content: center;
		gap: 24px;
		.btn {
			width: 240px;
			height: 48px;
			&.btn-yellow {
				background: $PRIMARY_YELLOW;
			}
		}
	}
}
</style>
