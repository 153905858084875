<template>
	<div class="status-item">
		<div>
			<img :src="image" />
			<span>{{ title }}</span>
		</div>
		<p v-html="content"></p>
	</div>
</template>

<script>
export default {
	name: 'StatusItem',
	props: {
		title: {
			type: String,
			required: true,
		},
		content: {
			type: String,
			required: true,
		},
		image: {
			type: String,
			default: '',
		},
	},
}
</script>

<style lang="scss" scoped>
.status-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 8px;
	> div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 6px;
		width: 120px;
		height: 120px;
		background: $LIST_BACKGROUND;
		border-radius: 3px;
		> img {
			width: 40px;
			height: 40px;
		}
		> span {
			font-weight: 700;
			font-size: 0.875rem;
			color: $COLOR_666;
		}
	}
	> p {
		text-align: center;
		font-weight: 500;
		font-size: 0.688rem;
		color: $COLOR_666;
		> b {
			color: $PRIMARY_BLACK;
		}
	}
}
</style>
