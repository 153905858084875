<template>
	<div class="product-wrapper">
		<product-box v-for="(item, index) in list" :key="`product_${index}`" :item="item" :index="index" :hasRank="hasRank" :size="size" />
	</div>
</template>

<script>
import ProductBox from '@/views/main/components/ProductBox.vue'
export default {
	name: 'ProductsByCategory',
	components: { ProductBox },
	props: {
		hasRank: {
			type: Boolean,
			default: false,
		},
		list: {
			type: Array,
			default: () => [],
		},
		size: {
			type: String,
			default: 'large',
		},
	},
	computed: {},
	data() {
		return {}
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.product-wrapper {
	gap: 18px;
	row-gap: 40px;
	height: 320px;
	display: flex;
	padding: 20px 0 0 0;
	flex-wrap: wrap;
}
</style>
