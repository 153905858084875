import cartAPI from '@/services/api/cart'

const carts = {
	namespaced: true,
	state: {
		cartList: [],
	},
	getters: {
		cartList: (state, payload) => {
			return state.cartList.map(product => {
				const { category, shippingDay, user } = product
				const parents = category.parents
				product.categoryPathKorName = parents && parents.length > 0 ? `${parents[0]?.korName} / ${category.korName}` : category.korName
				product.userName = user?.name ? user.name : '게스트'

				let shippingDayText = ''
				if (shippingDay === 0) shippingDayText = '당일출고'
				else if (shippingDay) shippingDayText = `약 ${shippingDay}일 이내 출고예정`
				else shippingDayText = '판매자 문의필요'
				product.shippingDayText = shippingDayText

				return product
			})
		},
	},
	mutations: {
		setCartList: (state, payload) => {
			state.cartList = payload
		},
		initCartList: (state, _) => {
			state.cartList = []
		},
	},
	actions: {
		lookupCartList: async ({ commit }, { instituteId, params }) => {
			const response = await cartAPI.lookupCartList(instituteId, params)
			commit('setCartList', response.data)
		},
		lookupNonMemberCartList: async ({ commit }, body) => {
			const response = await cartAPI.lookupNonMemberCartList(body)
			commit('setCartList', response.data)
		},
		addCart: async ({ commit }, { instituteId, body }) => {
			await cartAPI.addCart(instituteId, body)
		},
		updateCart: async ({ commit }, { instituteId, body }) => {
			await cartAPI.updateCart(instituteId, body)
		},
		deleteCart: async ({ commit }, { instituteId, params }) => {
			await cartAPI.deleteCart(instituteId, params)
		},
	},
}

export default carts
