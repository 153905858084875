<template>
	<div id="Product">
		<div class="wrapper" ref="wrapper">
			<product-list v-if="list.length > 0" :title="title" :list="list" :tabList="sortTabList" @sortProduct="sortProduct" />
			<no-product-list v-else :title="title" />
		</div>
	</div>
</template>

<script>
import ProductList from '@/components/list/ProductList.vue'
import NoProductList from '@/components/list/NoProductList.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import MixinScrolling from '@/mixins/scrolling'
// import throttle from 'lodash/throttle'

export default {
	name: 'Product',
	components: {
		ProductList,
		NoProductList,
	},
	mixins: [MixinScrolling],
	data() {
		return {
			category: this.$route.params.category,
			list: [],
			offset: 0,
			length: 30,
		}
	},
	computed: {
		...mapState('productInfo', ['recentlyProductInfoList', 'frequentlyProductInfoList']),
		...mapGetters('user', ['instituteId']),
		title() {
			if (this.category == 'frequently') {
				return '자주 구매한 상품'
			} else if (this.category == 'current') {
				return '최근 구매한 상품'
			}
		},
		sortTabList() {
			let list = []

			if (this.category == 'frequently') {
				list.push({ id: 1, value: 'RECOMMEND', name: '자주 구매순' })
			} else if (this.category == 'current') {
				list.push({ id: 2, value: 'RECOMMEND', name: '최근 구매순' })
			}

			return list
		},
	},
	watch: {
		'$route.params.category': {
			async handler(val) {
				this.category = val
				this.offset = 0

				if (this.instituteId) {
					await this.getProductList()

					if (this.category === 'frequently') this.list = JSON.parse(JSON.stringify(this.frequentlyProductInfoList))
					else if (this.category === 'current') this.list = JSON.parse(JSON.stringify(this.recentlyProductInfoList))
				}
			},
			deep: true,
		},
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				await this.getProductList()
				if (this.category === 'frequently') this.list = JSON.parse(JSON.stringify(this.frequentlyProductInfoList))
				else if (this.category === 'current') this.list = JSON.parse(JSON.stringify(this.recentlyProductInfoList))
			},
			immediate: true,
		},
		async isBottom(val) {
			if (!val) return

			if (this.offset < this.list.length) {
				this.offset = this.offset + this.length

				await this.getProductList()
				if (this.category === 'frequently') {
					if (this.frequentlyProductInfoList.length > 0) {
						this.list = [...this.list, ...this.frequentlyProductInfoList]
					}
				} else if (this.category === 'current') {
					if (this.recentlyProductInfoList.length > 0) {
						this.list = [...this.list, ...this.recentlyProductInfoList]
					}
				}
			}
		},
	},
	methods: {
		...mapActions('productInfo', ['lookupRecentlyProductInfoList', 'lookupFrequentlyProductInfoList']),
		sortProduct(tab) {
			console.log(tab)
		},
		async getProductList() {
			if (this.category === 'frequently') await this.fetchFrequentlyProductInfoList()
			else if (this.category === 'current') await this.fetchRecentlyProductInfoList()
		},
		async fetchRecentlyProductInfoList() {
			await this.lookupRecentlyProductInfoList({ instituteId: this.instituteId, paramsObject: { offset: this.offset, length: this.length } })
		},
		async fetchFrequentlyProductInfoList() {
			await this.lookupFrequentlyProductInfoList({ instituteId: this.instituteId, paramsObject: { offset: this.offset, length: this.length } })
		},
	},
}
</script>
