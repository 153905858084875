import wishAPI from '@/services/api/wish'

const wish = {
	namespaced: true,
	state: {
		wishBrandList: [],
		wishSellerList: [],
		wishCatalogList: {},
		count: 0,
	},
	getters: {
		getWishBrandList: state => {
			return state?.wishBrandList?.map(item => {
				return { ...item, isWish: true }
			})
		},
		getWishSellerList: state => {
			return state?.wishSellerList?.map(item => {
				return { ...item, isWish: true }
			})
		},
		getWishCatalogList: state => {
			return state?.wishCatalogList?.list?.map(item => {
				return { ...item, isWish: true }
			})
		},
	},
	mutations: {
		setWishBrandList: (state, payload) => {
			state.wishBrandList = payload
		},
		setWishSellerList: (state, payload) => {
			state.wishSellerList = payload
		},
		setWishCatalogList: (state, payload) => {
			state.wishCatalogList = payload
		},
		setCount: (state, payload) => {
			state.count = payload
		},
	},
	actions: {
		lookupWishBrandList: async ({ commit }) => {
			const response = await wishAPI.lookupWishBrandList()
			commit('setWishBrandList', response.data)
		},
		lookupWishSellerList: async ({ commit }) => {
			const response = await wishAPI.lookupWishSellerList()
			commit('setWishSellerList', response.data)
		},
		lookupWishCatalogList: async ({ commit }, params) => {
			const { offset, length } = params
			const response = await wishAPI.lookupWishCatalogList(offset, length)
			commit('setWishCatalogList', response.data)
			commit('setCount', response.data.count)
		},
		updateWish: async (_, payload) => {
			const { type, body } = payload
			return await wishAPI.updateWish(type, body)
		},
	},
}

export default wish
