import businessRegistrationAPI from '@/services/api/business-registration'

const businessRegistration = {
	namespaced: true,
	state: {
		businessRegistrations: [],
	},
	mutations: {
		setBusinessRegistrations: (state, payload) => {
			state.businessRegistrations = payload
		},
	},
	actions: {
		lookupRegistration: async ({ commit }, instituteId) => {
			const response = await businessRegistrationAPI.lookupRegistration(instituteId)
			commit('setBusinessRegistrations', response.data)
		},
		addRegistration: async (_, { instituteId, formData }) => {
			return await businessRegistrationAPI.addRegistration(instituteId, formData)
		},
		deleteRegistration: async (_, { instituteId, id }) => {
			return await businessRegistrationAPI.deleteRegistration(instituteId, id)
		},
	},
}

export default businessRegistration
