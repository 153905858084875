<template>
	<base-management-item>
		<template #info>
			<div class="info">
				<div class="image-text-wrapper">
					<img :src="item.information.frontImageUrl" />
					<div class="text-wrapper">
						<div>{{ item.name }}</div>
						<div>{{ displayCardNumber }}</div>
					</div>
				</div>
				<span class="name">{{ issuer }}</span>
				<b>|</b>
				<span class="nickname">{{ item.name }}</span>
			</div>
		</template>
		<template #button>
			<button class="btn btn-gray sm" @click="$emit('edit', item)">수정</button>
			<button class="btn btn-gray sm" @click="$emit('delete', item.id)">삭제</button>
		</template>
	</base-management-item>
</template>

<script>
import BaseManagementItem from '@/views/my-page/components/BaseManagementItem.vue'

export default {
	name: 'EasyPaymentItem',
	components: {
		BaseManagementItem,
	},
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		issuer() {
			return `${this.item.information.issuer ?? '카드'} (${this.item.cardNumber ? this.item.cardNumber.substr(0, 4) : ''})`
		},
		displayCardNumber() {
			const cardNumber1 = this.item.cardNumber?.slice(0, 4) ?? '0000'
			const cardNumber4 = this.item.cardNumber?.slice(12, 16) ?? '0000'
			return `${cardNumber1} - **** - **** - ${cardNumber4}`
		},
	},
}
</script>

<style lang="scss" scoped>
.info {
	display: flex;
	align-items: center;
	gap: 16px;
	.image-text-wrapper {
		position: relative;
		cursor: default;
		img {
			width: 125px;
			height: 81px;
			border-radius: 6px;
		}
		.text-wrapper {
			position: absolute;
			bottom: 5px;
			left: 5px;
			font-size: 0.625rem;
			color: $PRIMARY_WHITE;
			font-weight: 500;
			text-align: left;
		}
	}
	> b {
		color: #d9d9d9;
		font-weight: 400;
	}
	.name {
		font-size: 0.875rem;
		font-weight: 500;
		color: $COLOR_666;
	}
	.nickname {
		font-size: 0.875rem;
		font-weight: 700;
	}
}
</style>
