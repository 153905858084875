import paymentAPI from '@/services/api/payment'

const payment = {
	namespaced: true,
	state: {
		payments: [],
		count: 0,
		payment: {},
		calculate: {},
	},
	getters: {
		paymentOrderList: state => state.calculate.list,
		paymentPrice: state => state.calculate.paymentPrice,
		paymentPriceWithVat: state => state.calculate.paymentPriceWithVat,
		paymentVat: state => state.calculate.paymentVat,
	},
	mutations: {
		setPayments: (state, payload) => {
			state.payments = payload
		},
		setCount: (state, payload) => {
			state.count = payload
		},
		setPayment: (state, payload) => {
			state.payment = payload
		},
		setCalculate: (state, payload) => {
			state.calculate = payload
		},
	},
	actions: {
		lookupPaymentList: async ({ commit }, { instituteId, params }) => {
			const response = await paymentAPI.lookupPaymentList(instituteId, params)
			commit('setPayments', response.data.list)
			commit('setCount', response.data.count)
		},
		lookupPayment: async ({ commit }, { instituteId, id }) => {
			const response = await paymentAPI.lookupPayment(instituteId, id)
			commit('setPayment', response.data)
		},
		requestPayment: async ({ commit }, { instituteId, body }) => {
			const response = await paymentAPI.requestPayment(instituteId, body)
			return response.data
		},
		cancelPayment: async ({ commit }, { instituteId, body }) => {
			const response = await paymentAPI.cancelPayment(instituteId, body)
			return response.data
		},
		paymentCalculate: async ({ commit }, { instituteId, body }) => {
			const response = await paymentAPI.paymentCalculate(instituteId, body)
			commit('setCalculate', response.data)
		},
		paymentPrint: async ({ commit }, { instituteId, body }) => {
			const response = await paymentAPI.paymentPrint(instituteId, body)
			return response.data
		},
	},
}

export default payment
