export const SUMMARY_TITLE = {
	ALL: '전체',
	WAIT: '승인 대기',
	APPROVE: '승인 완료',
	SHIPPING_ING: '배송중',
	SHIPPING_COMPLETE: '배송완료',
	CANCEL: '주문취소',
	CONFIRM: '주문확정',
}

export const ITEM_STATUS = {
	WAIT: 'WAIT',
	APPROVE: 'APPROVE',
	SHIPPING_ING: 'SHIPPING_ING',
	SHIPPING_COMPLETE: 'SHIPPING_COMPLETE',
	CONFIRM: 'CONFIRM',
	CANCEL: 'CANCEL',
}

export const ITEM_STATUS_TYPE = {
	WAIT: '승인대기',
	APPROVE: '승인완료',
	SHIPPING_ING: '배송중',
	SHIPPING_COMPLETE: '배송완료',
	CONFIRM: '주문확정',
	WAIT_CANCEL: '취소대기중',
	CANCEL: '주문취소',
	SELLER_CANCEL: '판매자취소',
	REJECT: '취소요청거절',
	COMPANY_DIRECT_SHIPPING: '제조사직접배송',
	SELLER_DIRECT_SHIPPING: '판매자직접배송',
	PARCEL_SHIPPING: '택배배송',
	QUICK: '퀵서비스',
	RETURN_RECEIPT: '교환/반품접수',
	RETURN_COMPLETE: '교환/반품완료',
	RETURN_REJECT: '교환/반품거절',
}

export const ITEM_CANCEL_STATUS = {
	DONE: 'DONE',
	ING: 'ING',
	REJECT: 'REJECT',
}

export const ITEM_CANCEL_SOURCE = {
	USER: 'USER',
	SELLER: 'SELLER',
}

export const ITEM_SHIPPING_METHOD = {
	COMPANY_DIRECT_SHIPPING: '제조사 직접배송',
	SELLER_DIRECT_SHIPPING: '판매자 직접배송',
	PARCEL_SHIPPING: '택배 배송',
	QUICK: '퀵 서비스',
}

export const CANCEL_REASON = {
	BUY_INTENTION: '구매의사 취소',
	CHANGE_SIZE: '사이즈 변경',
	DIFF_PRODUCT_INFO: '상품정보 상이',
	ETC: '기타',
}

export const CANCEL_TYPE = {
	CANCEL: '주문 취소',
	CANCEL_REQUEST: '취소 요청',
}
