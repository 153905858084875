import PageName from '@/utils/define/PageName'

export const menuList = [
	{
		name: '주문 / 배송 관리',
		list: [
			{
				name: '주문 / 배송',
				component: PageName.MyPage.Order,
				active: false,
			},
			{
				name: '취소 / 반품 / 교환',
				component: PageName.MyPage.Return,
				active: false,
			},
		],
	},
	{
		name: '결제 관리',
		list: [
			{
				name: '미결제 내역',
				component: PageName.MyPage.UnPaymentManagement,
				active: false,
			},
			{
				name: '결제 내역',
				component: PageName.MyPage.PaymentHistory,
				active: false,
			},
		],
	},
	{
		name: '견적',
		list: [
			{
				name: '견적함',
				component: PageName.MyPage.EstimateManagement,
				active: false,
			},
			// {
			// 	name: '상품요청목록',
			// 	component: PageName.MyPage.RequestProductList,
			// 	active: false,
			// },
		],
	},
	{
		name: '활동정보',
		list: [
			{
				name: '찜 목록',
				component: PageName.MyPage.Wish,
				active: false,
			},
			{
				name: '최근 본 상품',
				component: PageName.MyPage.RecentView,
				active: false,
			},
		],
	},
	{
		name: '나의정보',
		list: [
			{
				name: '소속정보 관리',
				component: PageName.MyPage.LabInfoManagement,
				active: false,
			},
			{
				name: '회원정보 관리',
				component: PageName.MyPage.UserInfo,
				active: false,
			},
			{
				name: '배송지 관리',
				component: PageName.MyPage.ShippingAddressManagement,
				active: false,
			},
			{
				name: '간편결제정보 관리',
				component: PageName.MyPage.EasyPaymentManagement,
				active: false,
			},
			{
				name: '사업자등록정보 관리',
				component: PageName.MyPage.BusinessRegistrationManagement,
				active: false,
			},
		],
	},
]
