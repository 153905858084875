<template>
	<div class="order-item">
		<div class="item-info" style="cursor: pointer" @click="gotoDetail">
			<div class="image-box">
				<img :src="image" alt="item" />
			</div>
			<div class="content-box">
				<div style="display: flex; gap: 5px; align-items: center">
					<div class="item-seller" v-html="highlightKeyword(`[${item.product.seller.name}]`)"></div>
					<div class="item-sadadream" v-if="item.product.productInfoType == 'PURCHASE_AGENT'">
						<img src="@/assets/svg/icon-sadadream.svg" />
						구매대행
					</div>
				</div>
				<div class="item-name" v-html="highlightKeyword(item.product.name)"></div>
				<div class="item-sub-info">
					<span>카테고리: {{ categoryValue }}</span>
					<hr class="line small" />
					<span>브랜드: <span v-html="highlightKeyword(item.product.brand.name)"></span></span>
					<hr class="line small" />
					<span>제품번호: {{ item.product.sku }}</span>
					<hr v-if="item.product.casno" class="line small" />
					<span v-if="item.product.casno">CAS No: {{ item.product.casno }}</span>
					<template v-for="(option, idx) of item.product.options">
						<hr class="line small" :key="idx" />
						<span :key="`${idx}_span`">{{ option.name }}: {{ option.value }}</span>
					</template>
				</div>
				<div class="item-price">
					<span>{{ price }}</span>
					<hr class="line small" />
					<span>수량: {{ item.quantity }}개</span>
				</div>
			</div>
		</div>
		<div class="status-info">
			<template v-if="status === ITEM_STATUS_TYPE.WAIT">
				<span class="info-name">승인 대기</span>
				<button v-if="showButton" class="btn btn-gray" @click="onCancelOrder">주문취소</button>
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.APPROVE">
				<span class="info-name">승인 완료</span>
				<span class="info-date">{{ formatDate(item.estimatedReleaseDatetime) }} 출고 예정</span>
				<!-- TODO: 1:1 문의 미구현 주석 처리 by gangmin -->
				<!-- <button v-if="showButton" class="btn btn-gray" @click="onRequestCancel">취소 요청</button> -->
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.REJECT">
				<template v-if="isReturn">
					<span class="info-name">취소 거절</span>
					<button v-if="showButton" class="btn btn-gray" @click="onRouteDetail">상세보기</button>
				</template>
				<template v-else>
					<span class="info-name">승인 완료</span>
					<span class="info-date">{{ formatDate(item.estimatedReleaseDatetime) }} 출고 예정</span>
					<!-- TODO: 1:1 문의 미구현 주석 처리 by gangmin -->
					<!-- <button v-if="showButton" class="btn btn-gray reject" @click="onRejectToRequestCancel">취소 요청 거절</button> -->
				</template>
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.SHIPPING_ING">
				<span class="info-name">배송중</span>
				<button v-if="showButton" class="btn btn-gray" @click="onDeliveryTracking">배송 조회</button>
				<button v-if="showButton" class="btn btn-primary" @click="goPro()">입고하기</button>
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.QUICK">
				<span class="info-name">배송중</span>
				<button v-if="showButton" class="btn btn-gray" @click="onDeliveryTracking('quick')">배송 조회</button>
				<button v-if="showButton" class="btn btn-primary" @click="goPro()">입고하기</button>
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.SELLER_DIRECT_SHIPPING">
				<span class="info-name">배송중</span>
				<button v-if="showButton" class="btn btn-gray" @click="onDeliveryTracking('seller_direct')">배송 조회</button>
				<button v-if="showButton" class="btn btn-primary" @click="goPro()">입고하기</button>
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.COMPANY_DIRECT_SHIPPING">
				<span class="info-name">배송중</span>
				<button v-if="showButton" class="btn btn-gray" @click="onDeliveryTracking('company_direct')">배송 조회</button>
				<button v-if="showButton" class="btn btn-primary" @click="goPro()">입고하기</button>
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.PARCEL_SHIPPING">
				<span class="info-name">배송중</span>
				<button v-if="showButton" class="btn btn-gray" @click="onDeliveryTracking('parcel')">배송 조회</button>
				<button v-if="showButton" class="btn btn-primary" @click="goPro()">입고하기</button>
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.SHIPPING_COMPLETE">
				<span class="info-name">배송완료</span>
				<button v-if="showButton" class="btn btn-gray" @click="onDeliveryTracking">배송 조회</button>
				<button v-if="showButton" class="btn btn-primary" @click="goPro()">입고하기</button>
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.RETURN_REJECT">
				<template v-if="isReturn">
					<span class="info-name">교환 / 반품 거절</span>
					<button v-if="showButton" class="btn btn-gray" @click="onRouteDetail">상세보기</button>
				</template>
				<template v-else>
					<span class="info-name">배송완료</span>
					<button v-if="showButton" class="btn btn-gray" @click="onDeliveryTracking">배송 조회</button>
					<button v-if="showButton" class="btn btn-primary" @click="goPro()">입고하기</button>
				</template>
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.CONFIRM">
				<span class="info-name">구매확정</span>
				<button v-if="showButton" class="btn btn-gray" @click="onDeliveryTracking">배송조회</button>
				<button v-if="showButton" class="btn btn-primary" @click="goPro()">입고하기</button>
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.WAIT_CANCEL">
				<span class="info-name">취소 접수</span>
				<button v-if="showButton" class="btn btn-gray" @click="onRouteDetail">상세보기</button>
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.CANCEL">
				<span class="info-name">취소 완료</span>
				<button v-if="showButton" class="btn btn-gray" @click="onRouteDetail">상세보기</button>
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.RETURN_RECEIPT">
				<span class="info-name">교환 / 반품 접수</span>
				<button v-if="showButton" class="btn btn-gray" @click="onRouteDetail">상세보기</button>
			</template>
			<template v-else-if="status === ITEM_STATUS_TYPE.RETURN_COMPLETE">
				<span class="info-name">교환 / 반품 완료</span>
				<button v-if="showButton" class="btn btn-gray" @click="onRouteDetail">상세보기</button>
			</template>
		</div>
		<ModalSadadreamRequest ref="ModalSadadreamRequest" @success="onSuccessRequest" />
		<ModalCommon ref="ModalRequestComplete" title="상품 요청 완료" content="상품 요청이 완료되었습니다." />
		<ModalIntroPro :detailId="item.cargoId" :initPage="3" ref="modal-intro-pro" />
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import mixin from '@/mixins'
import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'
import { ITEM_STATUS, ITEM_CANCEL_STATUS, ITEM_STATUS_TYPE, ITEM_SHIPPING_METHOD, CANCEL_TYPE } from '@/utils/define/order'
import cookies from 'vue-cookies'
import ModalSadadreamRequest from '@/views/sadadream/modal/ModalSadadreamRequest'
import ModalCommon from '@/components/modal/ModalCommon.vue'
import ModalIntroPro from '@/components/modal/ModalIntroPro.vue'
import { isLogin, getSYSUrl } from '@/services/api/common'
// import { ProTooltipText } from '@/utils/Common'
export default {
	name: 'OrderItem',
	components: {
		ModalSadadreamRequest,
		ModalCommon,
		ModalIntroPro,
	},
	mixins: [mixin],
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
		orderer: {
			type: Object,
			default: () => ({}),
		},
		searchKeyword: {
			type: String,
			default: '',
		},
		address: {
			type: String,
			default: '',
		},
		showButton: {
			type: Boolean,
			default: true,
		},
		isReturn: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			ITEM_STATUS_TYPE,
			modalInfoTitle: '',
			modalInfoRows: [],
			modalOrderCancelTitle: '',
		}
	},
	computed: {
		...mapState('seller', ['seller']),
		...mapState('institute', ['institute']),
		highlightKeyword() {
			return value => {
				if (this.searchKeyword) {
					const name = value
					const startIndex = value.indexOf(this.searchKeyword)
					const endIndex = startIndex + this.searchKeyword.length
					if (startIndex >= 0) {
						return [
							name.slice(0, startIndex),
							`<b style="background: rgba(255, 87, 70, 0.5)">${this.searchKeyword}</b>`,
							name.slice(endIndex),
						].join('')
					}
				}
				return value
			}
		},
		image() {
			return this.item.product.image ?? require('@/assets/svg/empty_product.svg')
		},
		categoryValue() {
			let str = ''
			if (this.item.product.category.parents) {
				this.item.product.category.parents.forEach(item => {
					str += item.korName.concat('/')
				})
			}
			return str.concat(this.item.product.category.korName)
		},
		price() {
			return `${this.CommaNum(this.item.orderItemPrice)}원`
		},
		status() {
			if (this.item.returnStatus === ITEM_CANCEL_STATUS.ING) return ITEM_STATUS_TYPE.RETURN_RECEIPT
			if (this.item.returnStatus === ITEM_CANCEL_STATUS.DONE) return ITEM_STATUS_TYPE.RETURN_COMPLETE
			if (this.item.cancelStatus === ITEM_CANCEL_STATUS.ING) return ITEM_STATUS_TYPE.WAIT_CANCEL
			if (this.item.cancelStatus === ITEM_CANCEL_STATUS.DONE) return ITEM_STATUS_TYPE.CANCEL

			switch (this.item.status) {
				case ITEM_STATUS.WAIT:
					if (!this.item.cancelStatus) return ITEM_STATUS_TYPE.WAIT
					break
				case ITEM_STATUS.APPROVE:
					if (!this.item.cancelStatus) return ITEM_STATUS_TYPE.APPROVE
					else if (this.item.cancelStatus === ITEM_CANCEL_STATUS.REJECT) return ITEM_STATUS_TYPE.REJECT
					break
				case ITEM_STATUS.SHIPPING_ING:
					if (this.item.shippingMethod === ITEM_SHIPPING_METHOD.QUICK) return ITEM_STATUS_TYPE.QUICK
					if (this.item.shippingMethod === ITEM_SHIPPING_METHOD.SELLER_DIRECT_SHIPPING) return ITEM_STATUS_TYPE.SELLER_DIRECT_SHIPPING
					if (this.item.shippingMethod === ITEM_SHIPPING_METHOD.COMPANY_DIRECT_SHIPPING) return ITEM_STATUS_TYPE.COMPANY_DIRECT_SHIPPING
					if (this.item.shippingMethod === ITEM_SHIPPING_METHOD.PARCEL_SHIPPING) return ITEM_STATUS_TYPE.PARCEL_SHIPPING
					return ITEM_STATUS_TYPE.SHIPPING_ING
				case ITEM_STATUS.SHIPPING_COMPLETE:
					if (!this.item.returnStatus) return ITEM_STATUS_TYPE.SHIPPING_COMPLETE
					else if (this.item.returnStatus === ITEM_CANCEL_STATUS.REJECT) return ITEM_STATUS_TYPE.RETURN_REJECT
					break
				case ITEM_STATUS.CONFIRM:
					return ITEM_STATUS_TYPE.CONFIRM
			}
		},
	},
	methods: {
		...mapActions('seller', ['lookupSeller']),
		onRejectToReturnInfo() {
			this.$emit('openModal', {
				modalName: 'ModalInfo',
				modalInfoTitle: '교환 / 반품 거절',
				modalInfoRows: [
					{
						title: '교환 / 반품 거절 사유',
						content: this.item.returnResponse,
					},
					{
						title: '취소요청 거절 상세사유',
						content: this.item.cancelRequestRejectReasonDetail,
					},
				],
			})
		},
		onRejectToRequestCancel() {
			this.$emit('openModal', {
				modalName: 'ModalInfo',
				modalInfoTitle: '취소요청 거절',
				modalInfoRows: [
					{
						title: '취소요청 거절 사유',
						content: this.item.cancelRequestRejectReason,
					},
					{
						title: '취소요청 거절 상세사유',
						content: this.item.cancelRequestRejectReasonDetail,
					},
					{
						type: 'toggle',
						title: '주문자 취소요청 정보',
						content: [
							{
								title: '주문 취소금액',
								content: this.price,
							},
							{
								title: '취소사유',
								content: this.item.cancelReason,
							},
							{
								title: '취소 상세사유',
								content: this.item.cancelReasonDetail,
							},
						],
					},
				],
			})
		},
		onCancelOrder() {
			this.$emit('openModal', {
				modalName: 'ModalOrderCancel',
				title: CANCEL_TYPE.CANCEL,
				content: '정말로 주문을 취소하시겠습니까?<br/>한번 취소한 주문은 되돌릴 수 없습니다.',
				orderItemId: this.item.orderItemId,
			})
		},
		onRequestCancel() {
			this.$emit('openModal', {
				modalName: 'ModalOrderCancel',
				title: CANCEL_TYPE.CANCEL_REQUEST,
				content: '판매자가 이미 상품을 출고하거나, 발주요청을 완료한 상태일 수 있습니다.<br/>해당 사유의 경우 주문취소가 거절될 수 있습니다',
				orderItemId: this.item.orderItemId,
			})
		},
		onRequestReturn() {
			this.$emit('openModal', {
				modalName: 'ModalOrderReturn',
				orderItem: this.item,
			})
		},
		async onDeliveryTracking(type) {
			let tempType = ''
			if (typeof type === 'string') tempType = type

			await this.lookupSeller(this.item.product.seller.id)

			this.$emit('openModal', {
				modalName: 'ModalShippingTracking',
				orderItem: this.item,
				orderer: this.orderer,
				seller: this.seller,
				address: this.address,
				type: tempType,
			})
		},
		onRouteDetail() {
			this.$router
				.push({
					name: PageName.MyPage.ReturnDetail,
					params: {
						id: this.item.orderId,
					},
					query: {
						status: this.status,
						itemId: this.item.orderItemId,
					},
				})
				.catch(() => {})
		},
		gotoDetail() {
			if (this.item.product.productInfoType == 'NORMAL') {
				this.windowOpen({
					PageName: PageName.Detail,
					RoutePath: RoutePath.Detail,
					params: {
						category: 'list',
						productInfoId: this.item.product.productInfoId,
					},
				})
			} else {
				this.$refs['ModalSadadreamRequest'].show(this.item.product)
			}
		},
		onSuccessRequest() {
			this.$refs['ModalRequestComplete'].show()
		},
		goPro() {
			if (isLogin() && cookies.get('introProModal_hide')) {
				let url = getSYSUrl()
				if (this.institute && this.institute.proInstituteId) {
					url += `/${this.institute.proInstituteId}/purchase/cargo/${this.item.cargoId}`
				}
				location.href = url
			} else this.$refs['modal-intro-pro'].show()
		},
	},
}
</script>

<style lang="scss" scoped>
.tooltip {
	&.bs-tooltip-left {
		::v-deep .arrow::before {
			border-left-color: #fec741 !important;
		}
		::v-deep .arrow::after {
			border-left-color: #fff3d6 !important;
		}
	}
	&.bs-tooltip-right {
		::v-deep .arrow::before {
			border-right-color: #fec741 !important;
		}
		::v-deep .arrow::after {
			border-right-color: #fff3d6 !important;
		}
	}
}
::v-deep .tooltip-inner {
	background-color: #fff3d6 !important;
	color: #ff8a00;
	border: 1px solid #fec741;
	display: flex;
	gap: 5px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
}

.order-item {
	display: flex;
	align-items: center;
	// gap: 10px;
	min-height: 153px;
	border: 1px solid $COLOR_EEE;
	border-top: none;
	.item-info {
		display: flex;
		align-items: center;
		gap: 16px;
		width: 100%;
		height: 100%;
		padding: 20px 15px;
		border-right: 1px solid $COLOR_EEE;
		&:hover {
			// background-color: #fafafa;

			.item-name {
				text-decoration: underline;
			}
		}
		.image-box {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 80px;
			height: 80px;
			border: 1px solid $COLOR_EEE;
			border-radius: 4px;
			img {
				max-width: 78px;
				max-height: 78px;
			}
		}
		.content-box {
			.item-seller {
				font-size: 0.75rem;
				font-weight: 500;
			}
			.item-sadadream {
				display: flex;
				align-items: center;
				font-size: 12px;
				color: #ff8a00;
			}
			.item-name {
				font-size: 1rem;
				font-weight: 700;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
			.item-sub-info {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				font-size: 0.75rem;
				color: $COLOR_666;
			}
			.item-price {
				display: flex;
				align-items: center;
				font-weight: 500;
				font-size: 0.875rem;
			}
		}
	}
	.status-info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
		// TODO: 1:1 문의 미구현 주석 처리 by gangmin
		// start 구현되면 삭제
		width: 200px;
		// end
		height: 100%;
		padding: 0 24px;
		white-space: nowrap;
		.info-name {
			font-weight: bold;
			font-size: 1rem;
		}
		.info-date {
			font-weight: 500;
			font-size: 0.75rem;
			color: $COLOR_666;
		}
		.link {
			text-decoration-line: underline;
			cursor: pointer;
		}
		.cancel-font {
			font-size: 0.875rem;
			color: $PRIMARY_RED;
		}
		.info-font {
			font-size: 0.875rem;
			color: $COLOR_666;
		}
		button {
			width: 160px;
			font-size: 0.813rem;
			font-weight: 700;
			&.reject {
				opacity: 0.3;
			}
		}
	}
	.line {
		color: $COLOR_666;
		transform: rotate(90deg);
		&.large {
			width: 89px;
		}
		&.small {
			width: 12px;
		}
	}
}
</style>
