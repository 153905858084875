import { render, staticRenderFns } from "./ModalIntroPro.vue?vue&type=template&id=790f8be0&scoped=true&"
import script from "./ModalIntroPro.vue?vue&type=script&lang=js&"
export * from "./ModalIntroPro.vue?vue&type=script&lang=js&"
import style0 from "./ModalIntroPro.vue?vue&type=style&index=0&id=790f8be0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790f8be0",
  null
  
)

export default component.exports