<template>
	<div class="option-select">
		<b-dropdown variant="transparent" class="hint-button" block menu-class="option-menu">
			<template v-slot:button-content>
				<div class="hint">옵션을 선택해주세요</div>
			</template>
			<b-dropdown-item
				v-for="(option, i) in options"
				:key="i"
				@click="select(option)"
				:disabled="selectedOptionsIdList.includes(option.productId)"
				style="height: auto; border-bottom: 1px solid #eee"
			>
				<div class="option-dropdown-item">
					<div class="option-name">{{ option.sku }}</div>
					<div class="option-size">
						<span v-for="(opt, idx) of option.options" :key="opt.key">{{ idx !== 0 ? ' | ' : '' }}{{ opt.name }}: {{ opt.value }}</span>
					</div>
					<div class="option-info">
						<div class="info-wrapper">
							<div v-if="option.stock" class="info-item">
								<img src="@/assets/svg/stock-gray.svg" alt="stock" />
								<span>재고 {{ option.stock }}</span>
							</div>
							<div class="info-item">
								<img src="@/assets/svg/time-gray.svg" alt="time" />
								<span>{{ shippingStatus }}</span>
							</div>
						</div>
						<div class="info-price">{{ CommaNum(option.sellingUnitPrice) }}원</div>
					</div>
				</div>
			</b-dropdown-item>
		</b-dropdown>
		<div class="selected-options" v-if="instanceOptions.length > 0">
			<div class="option-container" v-for="(option, i) in instanceOptions" :key="i">
				<div class="option-dropdown-item">
					<div class="option-name">{{ option.sku }}</div>
					<div class="option-size">
						<span v-for="(opt, idx) of option.options" :key="opt.key">{{ idx !== 0 ? ' | ' : '' }}{{ opt.name }}: {{ opt.value }}</span>
					</div>
					<div class="option-info">
						<div class="info-wrapper">
							<div v-if="option.stock" class="info-item">
								<img src="@/assets/svg/stock-gray.svg" alt="stock" />
								<span>재고 {{ option.stock }}</span>
							</div>
							<div class="info-item">
								<img src="@/assets/svg/time-gray.svg" alt="time" />
								<span>{{ shippingStatus }}</span>
							</div>
						</div>
					</div>
					<div style="display: flex; align-items: center; justify-content: space-between; margin: 17px 0 12px 0">
						<count-input :optionCount="option.quantity" :stock="option.stock" :index="i" @change="changeCount" :isReadOnly="true" />
						<div style="display: flex; align-items: center; gap: 4px">
							<div class="info-price">{{ CommaNum(option.sellingUnitPrice) }}원</div>
							<button class="delete" @click="deleteOption(option)">
								<img src="@/assets/svg/delete.svg" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import CountInput from '@/components/CountInput'
import mixin from '@/mixins'

export default {
	components: {
		CountInput,
	},
	mixins: [mixin],
	props: {
		options: {
			type: Array,
			default: () => [],
		},
		selectedOptions: {
			type: Array,
			default: () => [],
		},
	},
	mounted() {
		setTimeout(() => {
			this.options.forEach(option => {
				if (option.productId === parseInt(this.$route.query.productId)) this.select(option)
			})
		}, 1000)
	},
	data() {
		return {
			instanceOptions: [],
		}
	},
	computed: {
		...mapGetters('productInfo', ['shippingStatus']),
		selectedOptionsIdList() {
			let list = []

			this.selectedOptions.forEach(item => {
				list.push(item.productId)
			})
			return list
		},
	},
	watch: {
		selectedOptions: {
			handler(val) {
				this.instanceOptions = val
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		select(option) {
			this.selectOption(option)
		},
		selectOption(option) {
			option.quantity = 1
			let options = [option]
			let list = [...this.instanceOptions, ...options]
			list = list.filter((element, index) => {
				return list.indexOf(element) === index
			})
			this.instanceOptions = list
			this.$emit('changeList', this.instanceOptions)
		},
		async changeCount(quantity, index) {
			this.instanceOptions[index].quantity = quantity
			this.$emit('changeList', this.instanceOptions)
		},
		deleteOption(option) {
			this.instanceOptions.remove(option)
			this.$emit('changeList', this.instanceOptions)
		},
	},
}
</script>

<style lang="scss" scoped>
.hint-button {
	margin-top: 10px;
}
.hint,
.value {
	width: 100%;
	height: 36px;
	text-align: left;
	border-radius: 4px;
	border: 1px solid #ddd;
	display: flex;
	align-items: center;
	padding-left: 12px;
	color: #999;
	font-size: 14px;
}
.value {
	color: black;
}
.option-dropdown-item {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: auto;
}
.option-name {
	@include ellipse;
	max-width: 80%;
	font-size: 0.875rem;
	font-weight: bold;
	margin-top: 10px;
}
.option-size {
	font-size: 0.875rem;
	@include ellipse;
}
.option-info {
	display: flex;
	justify-content: space-between;
	padding-top: 13px;
	.info-wrapper {
		display: flex;
		gap: 16px;
		font-size: 0.75rem;
		color: $COLOR_666;
		.info-item {
			display: flex;
			align-items: center;
			gap: 3px;
		}
	}
}
.info-price {
	font-size: 1rem;
	font-weight: bold;
}
.selected-options {
	margin-top: 20px;
	border: 1px solid #ddd;
	border-radius: 5px;
	background: #f9f9f9;
	font-size: 14px;
	.option-container {
		border-bottom: 1px solid #eee;
		&:last-of-type {
			border-bottom: none;
		}
		& > * {
			padding-left: 15px;
			padding-right: 15px;
		}
		.option {
			position: relative;
			display: flex;
			align-items: center;
			height: 55px;
			.name {
				width: 50%;
			}
			.price {
				position: absolute;
				right: 15px;
			}
			.delete {
				padding: 0px;
				width: 16px;
				height: 16px;
			}
		}
	}
}
</style>

<style lang="scss">
.option-select {
	.option-menu {
		will-change: unset !important;
		transform: none !important;
		top: 36px !important;
		z-index: 2;
		.dropdown-item {
			height: 100%;
		}
		.dropdown-item:active {
			background: $PRIMARY_YELLOW;
		}
	}
}
</style>
