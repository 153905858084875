<template>
	<search-row :title="title">
		<div class="input-box">
			<input type="number" v-model="price" @keyup.enter="$emit('search', price)" />
			<b>{{ priceUnit }}</b>
		</div>
		<button class="btn btn-complete" @click="$emit('search', price)">적용</button>
	</search-row>
</template>

<script>
import SearchRow from '@/views/my-page/order-delivery/order/components/search/SearchRow.vue'

export default {
	name: 'SearchPrice',
	props: {
		title: {
			type: String,
			default: '',
		},
		priceUnit: {
			type: String,
			default: '',
		},
	},
	components: {
		SearchRow,
	},
	data() {
		return {
			price: null,
		}
	},
}
</script>

<style lang="scss" scoped>
.search-row {
	.input-box {
		width: 230px;
		height: 30px;
		border: 1px solid $INPUT_BORDER;
		border-radius: 4px;
		position: relative;
		input {
			border: 0;
			width: calc(100% - 40px);
			height: 28px;
			border-radius: 4px;
			padding-left: 10px;
		}
		b {
			position: absolute;
			top: 50%;
			right: 6px;
			transform: translateY(-50%);
			font-size: 0.875rem;
			font-weight: 700;
			color: $COLOR_666;
		}
	}
	.btn {
		height: 30px;
		margin-left: 8px;
	}
}
</style>
