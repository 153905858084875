<template>
	<b-dropdown class="drop-down-wrapper" variant="transparent" menu-class="option-menu" no-caret>
		<template v-slot:button-content>
			<div class="display" @click="isShowList = !isShowList">
				<b v-if="value && value.name">{{ value.name }}</b>
				<b v-else>{{ placeholder }}</b>
				<img src="@/assets/svg/down-arrow.svg" />
			</div>
		</template>
		<b-dropdown-item v-for="(item, index) of list" :key="index" @click="onClickMenu(item)">
			<b>{{ item.name }}</b>
		</b-dropdown-item>
	</b-dropdown>
</template>

<script>
export default {
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		value: {
			type: Object,
			default: () => ({}),
		},
		placeholder: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			isShowList: false,
		}
	},
	methods: {
		onClickMenu(item) {
			this.$emit('select', item)
			this.isShowList = false
		},
	},
}
</script>

<style lang="scss" scoped>
.drop-down-wrapper {
	width: 80%;
	border-radius: 5px;
	align-self: center;
	text-align: left;
	.display {
		display: flex;
		align-items: center;
		height: 40px;
		width: 480px;
		background: $PRIMARY_WHITE;
		border: 1px solid #eeeeee;
		border-radius: 4px;
		position: relative;
		cursor: pointer;
		b {
			font-size: 14px;
			font-weight: 600;
			padding: 10px;
			line-height: 38px;
		}
		img {
			position: absolute;
			right: 10px;
			top: 15px;
		}
	}
}
</style>
