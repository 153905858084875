<template>
	<div class="cs-center-wrapper">
		<cs-center-header />
		<main class="cs-center-content">
			<cs-center-nav @active="onActive" />
			<section class="cs-center-section">
				<router-view />
			</section>
		</main>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import CsCenterHeader from '@/views/cs-center/components/CsCenterHeader.vue'
import CsCenterNav from '@/views/cs-center/components/CsCenterNav.vue'

export default {
	name: 'CsCenter',
	components: {
		CsCenterHeader,
		CsCenterNav,
	},
	methods: {
		onActive(menu) {
			this.$router
				.push({
					name: menu.component,
				})
				.catch(() => {})
		},
		goMain() {
			this.$router
				.push({
					name: PageName.Main,
				})
				.catch(() => {})
		},
	},
}
</script>

<style lang="scss" scoped>
.cs-center-wrapper {
	display: flex;
	flex-direction: column;
	gap: 40px;
	width: 100%;
	height: 100%;
	.cs-center-content {
		display: flex;
		align-items: baseline;
		justify-content: center;
		gap: 24px;
		.cs-center-section {
			width: 896px;
		}
	}
}
</style>
