<template>
	<div class="ProductSale">
		<div class="wrapper" ref="scroll-container">
			<product-list-item :item="catalog" mode="sale" />
			<filter-box :list="filterList" @setSelectedFilter="setSelectedFilter" />
			<seller-list :list="list" :tabList="sortTabList" @sortProduct="sortProduct" @changeStock="changeStock" />
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import MixinScrolling from '@/mixins/scrolling'
import ProductListItem from '@/components/item/ProductListItem.vue'
import FilterBox from '@/components/search/FilterBox.vue'
import SellerList from '@/views/product/components/SellerList.vue'
import cookies from 'vue-cookies'

export default {
	name: 'ProductSale',
	components: {
		ProductListItem,
		FilterBox,
		SellerList,
	},
	mixins: [MixinScrolling],
	data() {
		return {
			loadingHandler: null,
			list: [],
			catalogId: this.$route.params.id,
			hasStock: false,
			offset: 0,
			length: 30,
			order: ['FAVORITE'],
			orderDirection: ['DESC'],
			sellerIds: [],
		}
	},
	computed: {
		...mapState('catalog', ['catalog', 'productList', 'productFilter']),
		...mapState('delivery', ['basicAddress']),
		...mapGetters('user', ['instituteId']),
		sellerList() {
			const list = []
			this.productFilter.seller?.forEach(seller => {
				list.push({ name: seller.name, value: seller.id })
			})
			return list
		},
		filterList() {
			const filters = {}
			filters.filterSellerList = this.sellerList
			return filters
		},
		sortTabList() {
			const list = []
			list.push({ id: 1, value: 'FAVORITE', direction: 'DESC', name: '구매 많은순' })
			list.push({ id: 2, value: 'PRICE', direction: 'ASC', name: '낮은 가격순' })
			list.push({ id: 3, value: 'PRICE', direction: 'DESC', name: '높은 가격순' })
			return list
		},
		currentAddressId() {
			return cookies.get('currentAddress2')?.id ?? this.basicAddress?.id
		},
		currentInstituteId() {
			return cookies.get('currentInstitute2')?.id ?? this.instituteId
		},
	},
	watch: {
		async isBottom(val) {
			if (!val) return

			if (this.offset < this.list.length && this.productList.count > this.list.length) {
				this.offset = this.offset + this.length

				await this.fetchProductList()
				if (this.productList.list.length > 0) {
					this.list = [...this.list, ...this.productList.list]
				}
			}
		},
		instituteId: {
			async handler(newVal) {
				await this.fetchCatalog()
				await this.fetchProductList()
				await this.fetchProductListFilter()

				this.list = this.productList.list
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('catalog', ['lookupCatalog', 'lookupProductList', 'lookupProductListFilter']),
		async fetchCatalog() {
			const params = {}
			if (this.currentAddressId) params.deliveryId = this.currentAddressId
			if (this.currentInstituteId) params.instituteId = this.currentInstituteId
			await this.lookupCatalog({ id: this.catalogId, params })
		},
		async fetchProductList() {
			const params = {
				catalogId: this.catalogId,
				hasStock: this.hasStock,
				offset: this.offset,
				sellerIds: this.sellerIds,
				length: this.length,
				order: [this.order],
				orderDirection: this.orderDirection,
			}

			if (this.currentAddressId) params.deliveryId = this.currentAddressId
			if (this.currentInstituteId) params.instituteId = this.currentInstituteId
			await this.lookupProductList(params)
		},
		async fetchProductListFilter() {
			const params = {
				catalogId: this.catalogId,
				hasStock: this.hasStock,
			}
			await this.lookupProductListFilter(params)
		},
		//select filter list 가져오기
		setSelectedFilter(list) {
			this.offset = 0
			this.sellerIds = []
			list.forEach(item => {
				this.sellerIds.push(item.value)
			})
			this.fetchProductList()
		},
		async sortProduct(key) {
			this.order = key.value
			this.orderDirection = [key.direction]
			await this.fetchProductList()

			this.list = this.productList.list
		},
		async changeStock(bool) {
			this.hasStock = bool
			await this.fetchProductList()

			this.list = this.productList.list
		},
	},
}
</script>

<style lang="scss">
.ProductSale {
	.product-list-item {
		border-bottom: 0;
	}
}
</style>
