<template>
	<base-management-item>
		<template #info>
			<span class="tag" :class="item.status">{{ STATUS[item.status] }}</span>
			<p class="title">{{ item.name }}</p>
		</template>
		<template #button>
			<button class="btn btn-gray sm preview" @click="$emit('preview', item.images)">미리보기</button>
			<button class="btn btn-gray sm" @click="$emit('delete', item.id)">삭제</button>
		</template>
	</base-management-item>
</template>

<script>
import BaseManagementItem from '@/views/my-page/components/BaseManagementItem.vue'

const STATUS = {
	WAIT: '인증대기',
	APPROVE: '인증완료',
	REJECT: '인증거절',
}

export default {
	name: 'BusinessRegistrationItem',
	components: {
		BaseManagementItem,
	},
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			STATUS,
		}
	},
}
</script>

<style lang="scss" scoped>
.tag {
	padding: 1px 5px;
	background: $COLOR_EEE;
	border-radius: 5px;
	font-size: 0.75rem;
	font-weight: 700;
	&.APPROVE {
		background: rgba(13, 197, 119, 0.1);
		color: $PRIMARY_GREEN;
	}
	&.REJECT {
		background: #ffeeed;
		color: $PRIMARY_RED;
	}
}
.title {
	font-size: 1rem;
	font-weight: 700;
}
.preview {
	width: auto !important;
}
</style>
