<template>
	<base-management-list
		v-slot="{ item }"
		:list="list"
		emptyImageName="empty-card"
		emptyMessage="등록된 간편결제 카드가 없습니다."
		:headers="['카드/카드번호', '관리']"
	>
		<easy-payment-item :item="item" @edit="$emit('edit', $event)" @delete="$emit('delete', $event)" />
	</base-management-list>
</template>

<script>
import BaseManagementList from '@/views/my-page/components/BaseManagementList.vue'
import EasyPaymentItem from '@/views/my-page/my-info/easy-payment/EasyPaymentItem.vue'

export default {
	name: 'EasyPaymentList',
	components: {
		BaseManagementList,
		EasyPaymentItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
}
</script>

<style lang="scss" scoped></style>
