<template>
	<div>
		<div v-for="(order, index) of list" :key="index" class="order-history">
			<div v-if="showHeader" class="order-history-header" @click="routeDetail(order.orderId)">
				<div class="header-wrapper">
					<div>
						<span>주문일자</span>
						<b>{{ formatDate(order.createDatetime) }}</b>
					</div>
					<span class="separator">|</span>
					<div>
						<span>주문번호</span>
						<b>{{ order.orderNo }}</b>
					</div>
				</div>
				<div class="route-wrapper">
					<span>주문 상세</span>
					<img src="@/assets/svg/arrow_right.svg" />
				</div>
			</div>
			<div class="order-history-body" :style="!showHeader ? { borderTop: '1px solid #eee', borderRadius: '5px' } : null">
				<order-item
					v-for="item of order.items"
					:key="item.orderItemId"
					:item="item"
					:orderer="order.orderer"
					:searchKeyword="searchKeyword"
					:address="getAddress(order)"
					@openModal="$emit('openModal', $event)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import PageName from '@/utils/define/PageName'
import OrderItem from '@/views/my-page/order-delivery/order/components/OrderItem.vue'

export default {
	name: 'OrderHistory',
	components: {
		OrderItem,
	},
	mixins: [mixin],
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		searchKeyword: {
			type: String,
			default: '',
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		getAddress(order) {
			if (!order || !order.address) return ''
			else return `${order.address} ${order.addressDetail ?? ''}`
		},
		routeDetail(id) {
			this.$router
				.push({
					name: PageName.MyPage.OrderDetail,
					params: {
						id: id,
					},
				})
				.catch(() => {})
		},
	},
}
</script>

<style lang="scss" scoped>
.order-history {
	.order-history-header {
		border-radius: 5px 5px 0 0;
	}
	.order-history-body {
		.order-item {
			&:last-child {
				border-radius: 0 0 5px 5px !important;
			}
		}
	}
	&:not(:first-child) {
		margin-top: 20px;
	}
	.order-history-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		min-height: 40px;
		padding: 20px;
		border: 1px solid $COLOR_EEE;
		background: $LIST_BACKGROUND;
		font-size: 0.875rem;
		cursor: pointer;
		.separator {
			color: $COLOR_DDD;
		}
		.header-wrapper {
			display: flex;
			align-items: center;
			gap: 16px;
			> div {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				gap: 8px;
				> span {
					font-weight: 500;
				}
			}
		}
		.route-wrapper {
			display: flex;
			align-items: center;
			gap: 8px;
			span {
				font-weight: 500;
			}
		}
	}
}
</style>
