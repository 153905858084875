<template>
	<b-modal
		:id="id"
		centered
		:title="title"
		ok-title="확인"
		ok-variant="warning"
		:ok-only="hideCancelBtn"
		cancel-title="취소"
		cancel-variant="default"
		ref="modal-common"
		:size="size"
		button-size="lg"
		hide-header-close
		@ok="$emit('ok', checkboxLabel ? checked : $event)"
	>
		<div class="msg-wrapper">
			<p v-html="content"></p>
			<div v-if="checkboxLabel">
				<input v-model="checked" type="checkbox" id="check" name="check" class="checkbox" />
				<label for="check">{{ checkboxLabel }}</label>
			</div>
		</div>
	</b-modal>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			default: 'ModalCancel',
		},
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: 'sm',
		},
		checkboxLabel: {
			type: String,
			default: '',
		},
		hideCancelBtn: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			checked: false,
		}
	},
}
</script>

<style lang="scss">
#ModalCancel___BV_modal_outer_,
#ModalCancel2___BV_modal_outer_ {
	.modal-dialog {
		max-width: 480px;
	}
	#ModalCancel___BV_modal_header_,
	#ModalCancel___BV_modal_footer_,
	#ModalCancel2___BV_modal_header_,
	#ModalCancel2___BV_modal_footer_ {
		justify-content: center;
		.btn {
			width: 160px;
			height: 40px;
			&.btn-warning {
				background: $PRIMARY_YELLOW;
			}
		}
	}
	#ModalCancel___BV_modal_content_,
	#ModalCancel2___BV_modal_content_ {
		padding: 28px;
		border-radius: 10px;
		#ModalCancel___BV_modal_body_,
		#ModalCancel2___BV_modal_body_ {
			padding: 1rem 0;
			text-align: center;
		}
		.notice {
			margin-top: 24px;
			width: 100%;
			padding: 18px 0;
			text-align: center;
			font-size: 0.75rem;
			font-weight: 400;
			border-radius: 10px;
			background-color: $TABLE_HEADER;
		}
	}
	.modal-footer {
		justify-content: center;
		flex-wrap: unset;
		padding-bottom: 20px;
		button {
			width: 160px;
			height: 40px;
			&.btn-warning {
				background-color: $PRIMARY_YELLOW;
				&:hover {
					background-color: $PRIMARY_YELLOW;
				}
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.msg-wrapper {
	display: flex;
	flex-direction: column;
	gap: 18px;
	margin-top: -15px;
	font-size: 0.875rem;
	> p {
		font-weight: 500;
		color: $COLOR_333;
	}
	> div {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
