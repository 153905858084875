<template>
	<div class="brand-list">
		<div class="brand-item-title">
			<div>
				<h5>{{ title }}</h5>
				<p>{{ subtext }}</p>
			</div>
			<div v-if="isShowBtn" class="right-contents" @click="$emit('showAll')">
				<p>{{ buttonText }}</p>
				<img src="@/assets/svg/main/viewAllBtn.svg" />
			</div>
		</div>
		<div class="brand-item-list">
			<main-box-item v-for="(item, index) in list" :key="`box_${index}`" :item="item" :type="type" />
		</div>
	</div>
</template>

<script>
import MainBoxItem from '@/views/main/components/MainBoxItem.vue'

export default {
	name: 'BrandList',
	components: {
		MainBoxItem,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		subtext: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
		buttonText: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
		isShowBtn: {
			type: Boolean,
			default: true,
		},
	},
}
</script>

<style lang="scss" scoped>
.brand-list {
	p {
		padding-top: 5px;
		font-weight: 400;
		font-size: 0.875rem;
	}
	.brand-item-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.right-contents {
			display: flex;
			align-items: center;
			cursor: pointer;
			p {
				font-weight: 500;
				font-size: 0.875rem;
				padding-right: 5px;
				padding-top: 0;
			}
			img {
				width: 15px;
				height: 15px;
			}
		}
	}
	.brand-item-list {
		margin-top: 15px;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: 10px;
		row-gap: 10px;
	}
}
</style>
