<template>
	<div class="payment-requirement-business">
		<div class="content">
			사업자등록증은 연구실별로 한번 등록하면, 모든 멤버에게 인증됩니다. <br />
			전자세금 계산시 발행처 및 연구기관 인증에 사용됩니다.
		</div>
		<div v-if="isEmpty" class="business-registration-regist-form">
			<input-form-item
				ref="name"
				v-model="info.name"
				direction="column"
				title="사업자등록증(결제처) 별칭"
				placeholder="사업자등록증 별칭을 입력해주세요"
				:inputStyle="{ width: '466px' }"
			/>
			<upload-form-item ref="upload" direction="column" title="사업자등록증 업로드" @upload="onUploadFile" @delete="onDeleteFile" />
			<div class="button-wrapper">
				<button class="btn btn-primary lg" @click="onSave">다음</button>
			</div>
		</div>
		<div v-else>
			<div v-for="businessRegistration in businessRegistrations" :key="businessRegistration.id" class="business-registration-list">
				<div class="business-registration-item">
					<div>
						<span class="status" :class="businessRegistration.status">{{ STATUS[businessRegistration.status] }}</span>
					</div>
					<div class="name">
						{{ businessRegistration.name }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import InputFormItem from '@/components/form-item/InputFormItem.vue'
import UploadFormItem from '@/components/form-item/UploadFormItem.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'

const STATUS = {
	WAIT: '인증대기',
	APPROVE: '인증완료',
	REJECT: '인증거절',
}

export default {
	name: 'PaymentRequirementBusiness',
	components: {
		InputFormItem,
		UploadFormItem,
	},
	data() {
		return {
			STATUS,
			info: { name: '', businessRegistrations: [] },
		}
	},
	computed: {
		...mapState('businessRegistration', ['businessRegistrations']),
		...mapGetters('user', ['instituteId']),
		isEmpty() {
			return this.$_.isEmpty(this.businessRegistrations)
		},
	},
	created() {
		this.fetchBusinessRegistration()
	},
	methods: {
		...mapActions('businessRegistration', ['lookupRegistration', 'addRegistration']),
		async fetchBusinessRegistration() {
			await this.lookupRegistration(this.instituteId)
		},
		async onSave(e) {
			e.preventDefault()
			if (!this.validate()) return

			try {
				const formData = new FormData()
				formData.append('name', this.info.name)
				this.info.businessRegistrations.forEach((businessRegistration, index) => {
					formData.append(`businessRegistrations`, businessRegistration)
				})

				await this.addRegistration({
					instituteId: this.instituteId,
					formData,
				})
				await this.fetchBusinessRegistration()
				this.$emit('next')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		onUploadFile(files) {
			this.info.businessRegistrations = files
		},
		onDeleteFile(files) {
			this.info.businessRegistrations = files
		},
		validate() {
			let valid = true
			if (!this.$refs['name'].validate()) valid = false
			return valid
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-requirement-business {
	.content {
		font-size: 0.875rem;
	}

	.business-registration-regist-form {
		.button-wrapper {
			margin-top: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			button {
				width: 400px;
				height: 48px;
				&.btn-primary {
					background-color: $PRIMARY_YELLOW;
				}
			}
		}
	}

	.business-registration-list {
		margin-top: 20px;
		&:not(:first-child) {
			margin-top: 5px;
		}
		.business-registration-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-left: 20px;
			border: 1px solid #dddddd;
			border-radius: 5px;
			background-color: $HOVER_BACKGROUND;
			font-weight: 700;
			padding: 15px;

			.status {
				background: $COLOR_EEE;
				border-radius: 5px;
				font-size: 0.75rem;
				&.APPROVE {
					background: rgba(13, 197, 119, 0.1);
					color: $PRIMARY_GREEN;
				}
				&.REJECT {
					background: #ffeeed;
					color: $PRIMARY_RED;
				}
			}
			.name {
				margin-top: 5px;
			}
		}
	}
}
</style>
