import gaAPI from '@/utils/ga/api.js'
import _axios from 'axios'
import { getPreviousPageNameInLocal, getPageNameInLocal } from '@/utils/pages'
const _baseURL = process.env.VUE_APP_LOGGER_BASE_URL
const axios = _axios.create({
	baseURL: _baseURL,
	headers: {
		'X-platform': 'pc-web',
		'Accept-Language': 'ko',
	},
	timeout: 30000,
})

const LOGGER_BASE_PATH = '/research/'
let clientId
const getClientId = async () => {
	if (!clientId) {
		const _clientId = await gaAPI.getGaClientId()
		clientId = _clientId
	}
	return clientId
}

const getLoggerEvent = (event_name, form) => {
	return {
		store_login: postStoreLogin,
		store_search: postStoreSearch,
		store_search_success: postStoreSearchSuccess,
		store_search_fail: postStoreSearchFail,
		store_search_exit: postStoreSearchExit,
		store_begin_search_action: postStoreBeginSearchAction,
		view_item: postViewItem,
		store_order: postStoreOrder,
		store_order_success: postStoreOrderSuccess,
		purchase: postPurchase,
		store_order_fail: postStoreOrderFail,
		store_order_exit: postStoreOrderExit,
		store_price: postStorePrice,
		store_price_success: postStorePriceSuccess,
		store_price_fail: postStorePriceFail,
		store_price_exit: postStorePriceExit,
		add_to_cart: postSddToCart,
		add_to_wishlist: postAddToWishlist,
		store_product: postStoreProduct,
	}[event_name]
}

export const requestLogger = async (event_name, form) => {
	const logger = getLoggerEvent(event_name)
	if (!logger) console.error('Not Found Logger')
	else {
		const session_id = await getClientId()
		logger({
			...form,
			session_id,
			event_name,
		})
	}
}

const postStoreLogin = async form => {
	const path = `${LOGGER_BASE_PATH}incoming/store_login`
	const store_begin_from = getPageNameInLocal()
	axios.post(path, {
		...form,
		store_begin_from,
	})
}
const postStoreSearch = async form => {
	const path = `${LOGGER_BASE_PATH}seek/store_search`
	axios.post(path, form)
}
const postStoreSearchSuccess = async form => {
	const path = `${LOGGER_BASE_PATH}seek/store_search_success`
	axios.post(path, form)
}
const postStoreSearchFail = async form => {
	const path = `${LOGGER_BASE_PATH}seek/store_search_fail`
	axios.post(path, form)
}
const postStoreSearchExit = async form => {
	const path = `${LOGGER_BASE_PATH}seek/store_search_exit`
	axios.post(path, form)
}
const postStoreBeginSearchAction = async form => {
	const path = `${LOGGER_BASE_PATH}seek2/store_begin_search_action`
	axios.post(path, form)
}
const postViewItem = async form => {
	const path = `${LOGGER_BASE_PATH}seek2/view_item`
	const view_item_from = getPreviousPageNameInLocal()
	axios.post(path, {
		...form,
		view_item_from,
	})
}
const postStoreOrder = async form => {
	const path = `${LOGGER_BASE_PATH}order/store_order`
	axios.post(path, form)
}
const postStoreOrderSuccess = async form => {
	const path = `${LOGGER_BASE_PATH}order/store_order_success`
	axios.post(path, form)
}
const postPurchase = async form => {
	const path = `${LOGGER_BASE_PATH}order/purchase`
	axios.post(path, form)
}
const postStoreOrderFail = async form => {
	const path = `${LOGGER_BASE_PATH}order/store_order_fail`
	axios.post(path, form)
}
const postStoreOrderExit = async form => {
	const path = `${LOGGER_BASE_PATH}order/store_order_exit`
	axios.post(path, form)
}
const postStorePrice = async form => {
	const path = `${LOGGER_BASE_PATH}checkout/store_price`
	axios.post(path, form)
}
const postStorePriceSuccess = async form => {
	const path = `${LOGGER_BASE_PATH}checkout/store_price_success`
	axios.post(path, form)
}
const postStorePriceFail = async form => {
	const path = `${LOGGER_BASE_PATH}checkout/store_price_fail`
	axios.post(path, form)
}
const postStorePriceExit = async form => {
	const path = `${LOGGER_BASE_PATH}checkout/store_price_exit`
	axios.post(path, form)
}
const postSddToCart = async form => {
	const path = `${LOGGER_BASE_PATH}event/add_to_cart`
	axios.post(path, form)
}
const postAddToWishlist = async form => {
	const path = `${LOGGER_BASE_PATH}event/add_to_wishlist`
	axios.post(path, form)
}
const postStoreProduct = async form => {
	const path = `${LOGGER_BASE_PATH}event/store_product`
	axios.post(path, form)
}
