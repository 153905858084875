const ParamMaker = {
	setArrayString: (key, paramsList) => paramsList.map(item => `${key}[]=${item}`).join('&'),
	filterMultiSelect: (filterList, returnTrue, returnFalse) => {
		const allCanUse = filterList.every(item => item) || filterList.every(item => !item)
		return allCanUse ? undefined : filterList[0] ? returnTrue : returnFalse
	},
	filterInventoryHolding: filterList => {
		// refs : https://stackoverflow.com/questions/35296676/convert-boolean-bit-array-to-number-in-typescript
		const result = filterList.reduce((res, x) => (res << 1) | x)
		switch (result) {
			case 0:
				return undefined
			case 1:
				return 'OTHERS'
			case 2:
				return 'MINE'
			case 3:
				return 'ALL'
		}
	},
	setParamUsingObject: paramObject => {
		if (!paramObject) return
		const keyList = Object.keys(paramObject)
		const paramStrList = []
		for (let idx = 0; idx < keyList.length; idx++) {
			const key = keyList[idx]
			const value = paramObject[key]
			if (Array.isArray(value)) {
				for (let i = 0; i < value.length; i++) {
					paramStrList.push(`${key}[]=${paramObject[key][i]}`)
				}
			} else {
				paramStrList.push(`${key}=${paramObject[key]}`)
			}
		}
		return paramStrList.join('&')
	},
}

export default ParamMaker
