<template>
	<b-modal id="ModalInfo" centered :title="title" ok-title="확인" ok-only ok-variant="white" hide-footer @hide="onHideModal">
		<div class="msg-wrapper">
			<div v-for="(row, index) of rows" :key="index" class="msg-row">
				<template v-if="!row.type || row.type === 'text'">
					<span class="title">{{ row.title }}</span>
					<div class="content">{{ isData(row.content) }}</div>
				</template>
				<template v-else-if="row.type === 'toggle'">
					<div>
						<span class="title">{{ row.title }}</span>
						<img class="toggle-btn" src="@/assets/svg/arrow-down.svg" @click="onToggleContent" />
					</div>
					<div v-if="isToggle" class="toggle-wrapper">
						<div v-for="(item, idx) of row.content" :key="`${index}|${idx}`" class="msg-row">
							<span class="title">{{ item.title }}</span>
							<div class="content">{{ isData(item.content) }}</div>
						</div>
					</div>
				</template>
			</div>
			<div class="custom-footer">
				<button class="btn btn-default" @click="onHideModal">확인</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import mixin from '@/mixins'

export default {
	name: 'ModalInfo',
	mixins: [mixin],
	props: {
		title: {
			type: String,
			default: '',
		},
		rows: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isToggle: false,
		}
	},
	methods: {
		onToggleContent(e) {
			const classList = e.target.classList
			classList.toggle('active')

			this.isToggle = [...classList].includes('active')
		},
		onHideModal() {
			if (this.isToggle) this.isToggle = false

			this.$bvModal.hide('ModalInfo')
		},
	},
}
</script>

<style lang="scss" scoped>
.msg-wrapper {
	margin-top: -12px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	.msg-row {
		display: flex;
		flex-direction: column;
		gap: 8px;
		.title {
			font-size: 0.75rem;
			font-weight: 500;
			color: $COLOR_666;
		}
		.content {
			font-size: 0.875rem;
		}
		.toggle-btn {
			cursor: pointer;
			&.active {
				transform: rotate(180deg);
			}
		}
		.toggle-wrapper {
			display: flex;
			flex-direction: column;
			gap: 20px;
			background: #f9f9f9;
			border-radius: 10px;
			padding: 16px;
		}
	}
	.custom-footer {
		display: flex;
		justify-content: flex-end;
		.btn {
			width: 100px;
			font-weight: bold;
			font-size: 0.875rem;
		}
	}
}
</style>
