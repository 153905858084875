import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/modules/ParamMaker'

const endPoint = {
	lookupEstimateList: (instituteId, paramsObject) => `institute/${instituteId}/estimate/list?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupEstimate: (instituteId, id) => `institute/${instituteId}/estimate?id=${id}`,
	addEstimateByCart: instituteId => `institute/${instituteId}/estimate/cart`,
	addEstimateByOrderItem: instituteId => `institute/${instituteId}/estimate/orderItem`,
	addEstimateToNonMember: () => 'estimate',
	updateEstimate: instituteId => `institute/${instituteId}/estimate`,
	deleteEstimate: (instituteId, id) => `institute/${instituteId}/estimate?id=${id}`,
	printEstimate: instituteId => `institute/${instituteId}/estimate/print`,
}

const estimateAPI = {
	lookupEstimateList: (instituteId, paramsObject) => axiosInstance.get(endPoint.lookupEstimateList(instituteId, paramsObject)),
	lookupEstimate: (instituteId, id) => axiosInstance.get(endPoint.lookupEstimate(instituteId, id)),
	addEstimateByCart: (instituteId, body) => axiosInstance.post(endPoint.addEstimateByCart(instituteId), body),
	addEstimateByOrderItem: (instituteId, body) => axiosInstance.post(endPoint.addEstimateByOrderItem(instituteId), body),
	addEstimateToNonMember: body => axiosInstance.post(endPoint.addEstimateToNonMember(), body),
	updateEstimate: (instituteId, body) => axiosInstance.patch(endPoint.updateEstimate(instituteId), body),
	deleteEstimate: (instituteId, id) => axiosInstance.delete(endPoint.deleteEstimate(instituteId, id)),
	printEstimate: (instituteId, body) => axiosInstance.post(endPoint.printEstimate(instituteId), body),
}

export default estimateAPI
