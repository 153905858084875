<template>
	<div class="payment-info">
		<div class="info-item">
			<div class="info-title">
				<div class="title">결제 수단</div>
				<div class="checkbox-wrapper" v-if="isPerson">
					<input type="checkbox" v-model="isCheck" id="paymentInfoCheckbox" class="common" />
					<label for="paymentInfoCheckbox">선택한 결제수단을 다음에도 사용</label>
				</div>
			</div>
			<div class="info-content">
				<div class="content">
					<template v-if="isPerson || isSadadream">
						<div class="card-wrapper">
							<div class="card-input-text">
								<div class="card-input">
									<input
										type="radio"
										id="card"
										name="paymentMethodList"
										class="common"
										:value="PAYMENT_METHOD.CARD"
										v-model="paymentMethod"
									/>
									<label for="card">카드 간편결제</label>
								</div>
								<div class="card-text">선택하신 <span class="black">카드정보 변경은 마이페이지 메뉴</span>에서 하실 수 있습니다.</div>
							</div>
							<payment-info-card ref="payment-info-card" v-if="paymentMethod === PAYMENT_METHOD.CARD"></payment-info-card>
						</div>
						<template v-if="paymentMethod === PAYMENT_METHOD.DIRECT_CARD">
							<hr />
						</template>
						<div class="direct-card-wrapper" v-if="isPerson">
							<div class="direct-card-input">
								<input
									type="radio"
									id="direct-card"
									name="paymentMethodList"
									class="common"
									:value="PAYMENT_METHOD.DIRECT_CARD"
									v-model="paymentMethod"
								/>
								<label for="direct-card">일반 결제</label>
							</div>
						</div>
					</template>
					<template v-else>
						<div class="later-wrapper">
							<!-- <div class="later-input">
								<input
									type="radio"
									id="later"
									name="paymentMethodList"
									class="common"
									:value="PAYMENT_METHOD.LATER"
									v-model="paymentMethod"
								/>
								<label for="later"
									>랩매니저 스토어는 주문이 끝나면 상품을 먼저 받아보실 수 있도록 후불결제 서비스를 제공합니다.
								</label>
							</div> -->
							<!-- <hr /> -->
							<div class="later-text">
								랩매니저 스토어는 주문이 끝나면 상품을 먼저 받아보실 수 있도록 <b>후불결제</b> 서비스를 제공합니다. <br />주문건이
								"주문 승인" 단계가 되면 결제가 가능해지고, 카드결제와 계좌이체결제(세금계산서 발행)를 통해 결제가 가능합니다.
								<br />주문건별로 결제하는 대신 여러 주문건을 모아 연구실/회사의 결제 주기에 맞춰 결제해보세요. <br />스토어를 통해
								후불결제 서비스를 이용하는 연구실의 미결제금액이 300만원을 초과한 경우, 후불결제 서비스 이용이 제한됩니다.
								<br />스토어를 통해 후불결제 서비스를 이용하는 연구실이 당월 주문금액을 익월 말일까지 결제하지 않는 경우, 익익월
								1일부터 후불결제 서비스 이용이 제한됩니다.
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import PaymentInfoCard from '@/views/payment/components/PaymentInfoCard.vue'
import cookies from 'vue-cookies'

const PAYMENT_METHOD = {
	CARD: 'CARD',
	DIRECT_CARD: 'DIRECT_CARD',
	LATER: 'LATER',
}

const INSTITUTE_TYPE = {
	PERSON: 'PERSON',
	UNIVERSITY: 'UNIVERSITY',
	ENTERPRISE: 'ENTERPRISE',
	SELLER: 'SELLER',
}

export default {
	name: 'PaymentInfo',
	components: {
		PaymentInfoCard,
	},
	data() {
		return {
			PAYMENT_METHOD,
			isCheck: false,
			paymentMethod: '',
		}
	},
	watch: {
		institute: {
			handler(newVal) {
				if (!newVal) return

				const paymentMethod = cookies.get('paymentMethod')
				if (paymentMethod && this.isPerson) this.paymentMethod = paymentMethod
				else this.paymentMethod = this.isPerson || this.isSadadream ? PAYMENT_METHOD.CARD : PAYMENT_METHOD.LATER
			},
			immediate: true,
		},
	},
	computed: {
		...mapState('user', ['institute']),
		isPerson() {
			if (this.$_.isEmpty(this.institute)) return
			return this.institute.type.code === INSTITUTE_TYPE.PERSON
		},
		isSadadream() {
			return this.$route.query.isSadadream == true || this.$route.query.isSadadream == 'true'
		},
	},
	methods: {
		getPaymentInfo() {
			const paymentInfo = {
				paymentMethod: this.paymentMethod,
				isRememberPaymentMethod: this.isCheck,
			}
			if (!this.$_.isEmpty(this.$refs['payment-info-card'])) {
				const card = this.$refs['payment-info-card'].getCard()
				paymentInfo.cardId = !this.$_.isEmpty(card) ? card.id : null
			}
			return paymentInfo
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-info {
	display: flex;
	flex-direction: column;
	.info-item {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.info-title {
			display: flex;
			.title {
				font-weight: bold;
				font-size: 1.25rem;
			}
			.checkbox-wrapper {
				display: flex;
				align-items: center;
				margin-left: 15px;
				label {
					font-size: 0.875rem;
					font-weight: 700;
					cursor: pointer;
				}
			}
		}
		.info-content {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 20px;
			border: 1px solid $COLOR_DDD;
			border-radius: 5px;
			.content {
				div + div {
					margin-top: 10px;
				}
				.card-wrapper {
					.card-input-text {
						display: flex;
						justify-content: space-between;
						font-weight: 500;
						.card-text {
							color: $COLOR_666;
							font-size: 0.813rem;
							.black {
								color: $COLOR_111;
							}
						}
					}
				}

				.later-wrapper {
					.later-text {
						font-size: 0.875rem;
						font-weight: 500;
					}
					hr {
						color: $LINE_DIVIDER;
					}
				}

				.card-input,
				.direct-card-input,
				.later-input {
					height: 20px;
					display: flex;
					align-items: center;
					font-weight: 500;
					label {
						margin-left: 10px;
					}
				}

				hr {
					border: 1px solid $LINE_DIVIDER;
				}
			}
		}
	}
}
</style>
