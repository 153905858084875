<template>
	<b-modal ref="modal" :id="id" centered hide-footer :title="title" @hide="closeModal">
		<div class="modal-wrapper">
			<p class="header-subtext">배송지에 따라 주문가능한 상품이 달라질 수 있습니다.</p>
			<div class="item-wrapper">
				<div class="drop-down">
					<div class="text">
						<b>소속</b>
					</div>
					<drop-down :value="currentInstitute" :list="instituteList" @select="selectInstitute" />
				</div>
				<div style="height: 420px; overflow: auto">
					<div class="address-item" v-for="(address, index) in addressList" :key="`address_${index}`" @click="onClickAddress(address)">
						<label>
							<input type="radio" name="address" v-model="currentAddress.id" :value="address.id" />
						</label>
						<div class="info">
							<p v-if="address.isDefault" class="basic">기본 배송지</p>
							<b>{{ address.name }}</b>
							<p style="line-height: 30px">{{ `[${address.postcode}] ${address.address}, ${address.addressDetail}` }}</p>
							<p style="color: #999999">
								{{ `${address.instituteName ? address.instituteName : '-'} / ${address.groupName ? address.groupName : '-'}` }}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="addAddress" @click="$bvModal.show('ModalAddAddress')">
					<b>+ 새 배송지 등록</b>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import cookies from 'vue-cookies'
import DropDown from '@/components/DropDown.vue'

export default {
	name: 'ModalSelectCurrentAddress',
	components: {
		DropDown,
	},
	props: {
		id: {
			type: String,
			default: 'ModalSelectCurrentAddress',
		},
		title: {
			type: String,
			default: '배송지 목록',
		},
		propsCurrentAddress: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			selectedAddress: null,
			isChangeCurrentAddress: false,
			selectedInstitute: null,
		}
	},
	computed: {
		...mapState('delivery', ['addressList', 'basicAddress']),
		...mapState('user', ['institute', 'institutes']),
		...mapState('delivery', ['basicAddress']),
		currentAddress() {
			if (!this.selectedAddress) {
				return this.propsCurrentAddress
			} else return this.selectedAddress
		},
		instituteList() {
			return this.institutes
		},
		currentInstitute() {
			return this.selectedInstitute ? this.selectedInstitute : this.institute
		},
	},
	methods: {
		...mapMutations('user', ['setInstitute']),
		...mapMutations('delivery', ['setAddressList']),
		...mapActions('delivery', ['lookupAddressList']),
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		onClickAddress(address) {
			if (this.currentAddress === address) return
			this.selectedAddress = address
			cookies.set('currentAddress2', JSON.stringify(address))
			this.isChangeCurrentAddress = true
			this.$router.go()
			cookies.set('isShowAddressToast', true)
		},
		closeModal() {
			if (this.isChangeCurrentAddress) {
				this.$router.go()
				cookies.set('isShowAddressToast', true)
			}
		},
		async selectInstitute(item) {
			if (this.currentInstitute.id === item.id) return
			this.setInstitute(item)
			this.selectedInstitute = item
			try {
				await this.lookupAddressList(item.id)
				this.$root.toast('소속 변경 완료', '소속이 변경 되었습니다.', 'success')
				this.isChangeCurrentAddress = true
				cookies.set('currentInstitute2', JSON.stringify(item))
				cookies.set('currentAddress2', this.basicAddress)
				this.selectedAddress = this.basicAddress
			} catch (e) {
				this.$root.toast('소속 변경 실패', e.response.data.msg, 'error')
			}
		},
	},
}
</script>

<style lang="scss">
#ModalSelectCurrentAddress___BV_modal_outer_,
#ModalSelectCurrentAddress2___BV_modal_outer_ {
	.modal-dialog {
		max-width: 600px;
	}
	#ModalSelectCurrentAddress___BV_modal_header_,
	#ModalSelectCurrentAddress2___BV_modal_header_ {
		&.modal-header {
			border-bottom: solid 1px $LINE_DIVIDER;
		}
	}
	#ModalSelectCurrentAddress___BV_modal_body_,
	#ModalSelectCurrentAddress2___BV_modal_body_ {
		padding: 0;
	}
	#ModalSelectCurrentAddress___BV_modal_footer_,
	#ModalSelectCurrentAddress2___BV_modal_footer_ {
		&.modal-footer {
			background: $LIST_BACKGROUND;
			justify-content: right;
			padding-top: 0;
			.btn {
				width: 100px;
				height: 30px;
				background: $PRIMARY_YELLOW;
				&:first-child {
					background: #fff;
					border: solid 1px $LINE_DIVIDER;
					color: $PRIMARY_BLACK;
				}
				&.disabled {
					background: $LIST_BACKGROUND;
					color: $COLOR_999;
				}
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.modal-wrapper {
	.header-subtext {
		color: #666666;
		position: absolute;
		top: -38px;
		left: 120px;
	}
	.item-wrapper {
		// height: 402px;
		// overflow: auto;
		.drop-down {
			height: 80px;
			display: flex;
			border-bottom: solid 1px $LINE_DIVIDER;
			.text {
				width: 80px;
				align-self: center;
				display: flex;
				justify-content: center;
			}
		}
		.address-item {
			cursor: pointer;
			display: flex;
			height: 134px;
			border-bottom: solid 1px $LINE_DIVIDER;
			&:hover {
				background: #eeeeee;
			}
			&:last-child {
				border-bottom: 0;
			}
		}
		label {
			align-self: center;
			width: 70px;
			padding: 20px;
		}
		input[type='radio'],
		input[type='radio']:checked {
			appearance: none;
		}
		input[type='radio'] {
			width: 22px;
			height: 22px;
			border-radius: 100%;
			margin-right: 0.1rem;
			background-color: $PRIMARY_WHITE;
			border: 2px solid #eeeeee;
		}
		input[type='radio']:checked {
			background-image: url(~@/assets/svg/main/radio_check.svg);
			border: 1px solid $PRIMARY_YELLOW;
		}
		.info {
			padding: 20px 10px;
			align-self: center;
			.basic {
				text-align: center;
				width: 68px;
				height: 20px;
				background-color: #eeeeee;
				border-radius: 5px;
				font-weight: 700;
				font-size: 12px;
				color: #333333;
			}
			p {
				font-weight: 500;
				font-size: 14px;
				color: #666666;
			}
		}
	}
	.footer {
		border-top: solid 1px $LINE_DIVIDER;
		height: 88px;
		display: flex;
		justify-content: center;
		align-items: center;
		.addAddress {
			cursor: pointer;
			width: 240px;
			height: 48px;
			border: 1px solid #dddddd;
			border-radius: 5px;
			text-align: center;
			&:hover {
				background: #eeeeee;
			}
			b {
				font-weight: 700;
				font-size: 16px;
				display: flex;
				justify-content: center;
				line-height: 46px;
			}
		}
	}
}
</style>
