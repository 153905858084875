import axiosInstance from '@/services/axios'

const endPoint = {
	lookupCsList: instituteId => `institute/${instituteId}/cs/list`,
}

const csAPI = {
	lookupCsList: (instituteId, params) => axiosInstance.get(endPoint.lookupCsList(instituteId), { params }),
}

export default csAPI
