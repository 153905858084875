<template>
	<base-management title="주문 배송 내역">
		<status-counter-list class="status-list" :list="counterList" @active="onChangeActiveItem" />
		<detail-search-box
			ref="detailSearchBox"
			class="search-box"
			title="상세검색"
			subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
			searchPlaceholder="상품명, 브랜드, 판매자 검색"
			:datePlaceholder="datePlaceholder"
			@searchTextList="searchTextList"
		/>
		<order-history-list ref="orderHistoryList" class="history-list" :searchKeyword="keyword" @page="getCurrentPage" @complete="fetchOrderList" />
		<delivery-status-information class="delivery-status-info" />
	</base-management>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { SUMMARY_TITLE } from '@/utils/define/order'
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import StatusCounterList from '@/views/my-page/order-delivery/order/components/StatusCounterList.vue'
import DetailSearchBox from '@/views/my-page/order-delivery/order/components/search/DetailSearchBox.vue'
import OrderHistoryList from '@/views/my-page/order-delivery/order/components/OrderHistoryList.vue'
import DeliveryStatusInformation from '@/views/my-page/order-delivery/order/components/DeliveryStatusInformation.vue'

export default {
	name: 'Order',
	components: {
		BaseManagement,
		StatusCounterList,
		DetailSearchBox,
		OrderHistoryList,
		DeliveryStatusInformation,
	},
	data() {
		return {
			counterList: [],
			activeStatus: '',
			searchKeyword: '',
			searchDate: [],
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapGetters('order', ['keyword', 'currentPage']),
		...mapState('order', ['summary', 'params']),
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				this.clearSearchText()
				await this.fetchSummary()
				this.$refs.orderHistoryList.setPage(this.currentPage)
			},
			immediate: true,
		},
		$route: {
			handler(to) {
				if (to.query.status && to.query.status !== this.activeStatus) this.onChangeActiveItem(to.query.status)
			},
			immediate: true,
		},
	},
	mounted() {
		if (this.params.keyword) this.$refs.detailSearchBox.changeKeyword(this.params.keyword)
		if (this.params.orderStartDatetime && this.params.orderEndDatetime)
			this.$refs.detailSearchBox.changeDate([this.params.orderStartDatetime, this.params.orderEndDatetime])
	},
	methods: {
		...mapActions('order', ['lookupOrderSummary', 'lookupOrderList']),
		...mapMutations('order', ['setParamsStatus', 'setParamsText', 'setParamsPaging']),
		async fetchSummary() {
			await this.lookupOrderSummary(this.instituteId)
			this.setCounterList()
		},
		setCounterList() {
			this.counterList = Object.entries(this.summary).map(([key, value]) => {
				let active = key === 'ALL'
				if (this.params.status) active = key === this.params.status

				return {
					id: key,
					title: SUMMARY_TITLE[key],
					count: value,
					active,
				}
			})
		},
		async onChangeActiveItem(id) {
			this.counterList.forEach(item => {
				item.active = item.id === id
			})
			this.activeStatus = id
			this.setParamsStatus({ status: this.activeStatus })

			await this.fetchOrderList()
		},
		async fetchOrderList() {
			const params = {
				offset: this.params.offset,
				length: this.params.length,
				status: this.params.status,
			}
			if (this.params.keyword) params.keyword = this.params.keyword
			if (this.params.orderStartDatetime) params.orderStartDatetime = this.params.orderStartDatetime
			if (this.params.orderEndDatetime) params.orderEndDatetime = this.params.orderEndDatetime

			await this.lookupOrderList({
				instituteId: this.instituteId,
				params,
			})
		},
		async searchTextList(filter) {
			if (this.$_.isEmpty(filter)) {
				this.clearSearchText()
			} else {
				Object.entries(filter).forEach(([key, value]) => {
					if (key === '검색') {
						this.searchKeyword = value.length > 0 ? value[0].value : ''
						this.setParamsText({
							keyword: this.searchKeyword,
							orderStartDatetime: this.params.orderStartDatetime,
							orderEndDatetime: this.params.orderEndDatetime,
						})
					} else if (key === '기간 조회') {
						this.searchDate = value.length > 0 ? value[0].value : []
						this.setParamsText({
							keyword: this.params.keyword,
							orderStartDatetime: this.searchDate.length > 0 ? this.searchDate[0] : '',
							orderEndDatetime: this.searchDate.length > 0 ? this.searchDate[1] : '',
						})
					}
				})
			}
			await this.fetchOrderList()
		},
		clearSearchText() {
			this.searchKeyword = ''
			this.searchDate = []
			this.setParamsText({ keyword: '', orderStartDatetime: '', orderEndDatetime: '' })
		},
		async getCurrentPage({ offset, length }) {
			this.setParamsPaging({ offset, length })
			await this.fetchOrderList()

			window.scrollTo(0, document.querySelector('.order-history-list').offsetTop)
		},
	},
}
</script>

<style lang="scss" scoped>
.status-list {
	margin-top: 30px;
}
.search-box {
	margin-top: 33px;
}
.history-list {
	margin-top: 50px;
	margin-bottom: 74px;
}
.delivery-status-info {
	margin-bottom: 70px;
}
</style>
