import cookies from 'vue-cookies'

const CART_PRODUCT_LIST_KEY = 'CART_PRODUCT_LIST_KEY'

export default {
	methods: {
		isNonMemberCartProductList() {
			if (!cookies.isKey(CART_PRODUCT_LIST_KEY)) return false
			if (this.$_.isEmpty(JSON.parse(cookies.get(CART_PRODUCT_LIST_KEY)))) return false
			return true
		},
		setNonMemberCartProductList(productList) {
			if (this.$_.isEmpty(productList)) this.clearNonMemberCartProductList()
			cookies.set(CART_PRODUCT_LIST_KEY, JSON.stringify(productList))
		},
		getNonMemberCartProductList() {
			if (!this.isNonMemberCartProductList()) return []
			return JSON.parse(cookies.get(CART_PRODUCT_LIST_KEY))
		},
		clearNonMemberCartProductList() {
			cookies.remove(CART_PRODUCT_LIST_KEY)
		},
		updateNonMemberCartProductList(index, quantity) {
			const productList = this.getNonMemberCartProductList()
			productList[index].quantity = quantity
			this.setNonMemberCartProductList(productList)
		},
		removeNonMemberCartProductList(indexes) {
			if (!this.isNonMemberCartProductList()) return
			let productList = this.getNonMemberCartProductList()
			productList = productList.filter((_, index) => !indexes.includes(index))
			this.setNonMemberCartProductList(productList)
		},
	},
}
