<template>
	<div class="RequestProductDetail" v-if="id == requestProduct?.id">
		<content-title :title="title" :statusClass="statusClass" :statusText="statusText" :datetime="infoDatetime" />
		<div class="content-wrapper">
			<content-notice :status="requestProduct.isExpired ? 'FAIL' : requestProduct.status" :list="statusNotice(requestProduct.status)" />
			<simple-info-box title="요청 정보" :list="productRequestInfo" @preview="onPreview" />
			<simple-info-box title="요청자 정보" :list="requesterInfo" />
			<ProductInfoTable
				v-if="requestProduct?.products?.length > 0 && requestProduct.status == 'COMPLETE'"
				class="payment-product-info-table"
				:requestId="requestProduct.id"
				:products="requestProduct.products"
			/>
			<div class="sadadream-explain" v-if="requestProduct.type == 'PURCHASE_AGENT' && requestProduct.status == 'COMPLETE'">
				구매대행은 제한된 상품을 쉽게 구매하는 서비스입니다. 본 상품의 경우 상품 확보, 가격 변동의 위험 감소 및 배송 과정 진행 등 상품을
				안정적으로 구매하기 위해 <b>선결제</b>가 반드시 이루어져야만 합니다.
			</div>
			<div class="button-groups" v-if="requestProduct.status == 'COMPLETE'">
				<template v-if="requestProduct.type == 'PURCHASE_AGENT'">
					<button class="btn lg" @click="downloadEstimate">견적서 다운로드</button>
					<button class="btn lg btn-primary" v-if="requestProduct.orderId == null" @click="order">선결제 주문하기</button>
					<button class="btn lg btn-primary" v-else @click="goOrderDetail">주문 상세가기</button>
				</template>
				<template v-else>
					<button class="btn lg" @click="setCart">장바구니 담기</button>
					<button class="btn lg btn-primary" @click="order">주문하기</button>
					<div v-if="isShowCartTooltip" class="cart-tooltip">
						<div>
							<p>장바구니<span>에 담겼습니다.</span></p>
							<img src="@/assets/svg/main/icon-close.svg" @click="isShowCartTooltip = false" />
						</div>
						<button class="btn btn-primary btn-view-cart lg" @click="gotoCart">장바구니 보러가기</button>
					</div>
				</template>
			</div>
		</div>
		<ModalSadadreamRequest ref="ModalSadadreamRequest" @success="onSuccessRequest" />
		<ModalConfirm
			ref="ModalRequestExpired"
			title="상품 주문기간 만료"
			content="상품 주문기간이 만료되었습니다.<br/>다시 요청해주세요."
			confirmText="요청하러 가기"
			cancelText="닫기"
			@confirm="addRequest"
		/>
		<ModalConfirm ref="ModalRequestComplete" title="상품 요청 완료" content="상품 요청이 완료되었습니다." @ok="$router.go(-1)" />
		<modal-payment-requirement @ok="$router.go()" />
		<modal-shipping-requirement @ok="$router.go()" />
		<modal-image-preview :imageUrl="previewImageUrl" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import mixin from '@/mixins'
import date from '@/mixins/date'
import { Request } from '@/utils/define/ItemCode'
import ContentTitle from '@/components/content/ContentTitle'
import ContentNotice from '@/components/content/ContentNotice'
import SimpleInfoBox from '@/components/list/SimpleInfoBox'
import EstimateTable from '@/components/table/EstimateTable.vue'
import ModalImagePreview from '@/components/modal/ModalImagePreview.vue'
import ProductInfoTable from './components/ProductInfoTable'
import ModalPaymentRequirement from '@/views/payment/modal/ModalPaymentRequirement.vue'
import ModalShippingRequirement from '@/views/payment/modal/ModalShippingRequirement.vue'
import { gaEventCart } from '@/utils/ga'
import { ToastType } from '@/utils/define/ToastType'
import PageName from '@/utils/define/PageName'
import ModalConfirm from '../../components/modal/ModalConfirm.vue'
import ModalSadadreamRequest from './modal/ModalSadadreamRequest'
export default {
	name: 'SadadreamDetail',
	props: {
		id: [Number, String],
	},
	components: {
		ContentTitle,
		ContentNotice,
		SimpleInfoBox,
		EstimateTable,
		ModalImagePreview,
		ProductInfoTable,
		ModalPaymentRequirement,
		ModalShippingRequirement,
		ModalConfirm,
		ModalSadadreamRequest,
	},
	mixins: [mixin, date],
	data() {
		return {
			previewImageUrl: '',
			isShowCartTooltip: false,
		}
	},
	computed: {
		...mapState('institute', ['requirement']),
		...mapGetters('user', ['instituteId']),
		...mapState('requestProduct', ['requestProduct']),
		title() {
			return this.requestProduct?.data?.name
		},
		infoDatetime() {
			return `요청일: ${this.dateToString(this.requestProduct?.createDatetime)}`
		},
		productRequestInfo() {
			//상품요청 정보
			let list = []
			list.push({ name: '상품요청 번호', value: this.requestProduct?.no })
			list.push({ name: '상품요청 일시', value: this.datetimeToString(this.requestProduct?.createDatetime) })
			list.push({ name: '상품명', value: this.requestProduct?.data?.name, all: true })
			list.push({ name: '브랜드', value: this.isData(this.requestProduct?.data?.brandName) })
			list.push({ name: '제품번호', value: this.isData(this.requestProduct?.data?.productCode) })
			list.push({ name: '사이즈(용량)', value: this.requestProduct?.data?.volume })
			list.push({ name: '수량', value: this.isData(this.requestProduct?.data?.quantity) })
			list.push({ name: '추가요청사항', value: this.isData(this.requestProduct?.comment), all: true })
			list.push({ name: '이미지', list: this.requestProduct.attachments, type: 'preview', all: true })

			return list
		},
		requesterInfo() {
			//요청자 정보
			let list = []
			list.push({ name: '소속', value: this.requestProduct?.institute?.name, key: 'groupName' })
			list.push({ name: '이름', value: this.requestProduct?.user?.name, key: 'name' })
			list.push({ name: '연락처', value: this.isData(this.requestProduct?.user?.phone), key: 'phone' })
			list.push({ name: '이메일', value: this.isData(this.requestProduct?.user?.email), key: 'email' })

			return list
		},
		statusClass() {
			return Request.statusToClass(this.requestProduct.status)
		},
		statusText() {
			return Request.statusToString(this.requestProduct.status)
		},
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchRequestProduct()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('institute', ['lookupRequirement']),
		...mapMutations('order', ['setEstimateId', 'setSelectedProductList']),
		...mapActions('cart', ['addCart']),
		...mapActions('requestProduct', ['lookupRequestProduct', 'printEstimate']),
		async fetchRequestProduct() {
			await this.lookupRequestProduct({
				instituteId: this.instituteId,
				id: this.id,
			})
			if (this.requestProduct.isExpired && this.requestProduct.orderId == null) {
				this.$refs['ModalRequestExpired'].show()
			}
		},
		statusNotice(status) {
			if (this.requestProduct.orderId != null) {
				return ['주문이 완료된 요청입니다.']
			}
			if (this.requestProduct.isExpired) {
				return ['만료된 요청입니다.']
			}
			switch (status) {
				case Request.WAIT:
					return ['상품 요청을 <span>접수중</span>입니다. 접수완료 후 상품을 찾아드릴게요']
				case Request.ING:
					return ['<span>상품을 찾는 중<span>입니다. 요청하신 상품을 찾게 되면 알림으로 알려드려요']
				case Request.COMPLETE:
					return ['소싱 완료! 상품을 주문해주세요.']
				case Request.FAIL:
					return ['요청하신 상품을 찾지 못했어요. 더 나은 랩매니저 스토어가 되겠습니다.']
			}
		},
		onPreview(url) {
			this.previewImageUrl = url
			this.$bvModal.show('ModalImagePreview')
		},
		onClickPay() {},
		async setCart() {
			await this.addCart({
				instituteId: this.instituteId,
				body: {
					products: this.requestProduct.products.map(p => ({
						productId: p.productId,
						quantity: p.quantity,
					})),
				},
			})
			try {
				gaEventCart(this.requestProduct.products)
			} catch (e) {}
			this.isShowCartTooltip = true
		},
		gotoCart() {
			this.$router.push({
				name: 'Cart',
			})
		},
		async loadRequirement() {
			try {
				await this.lookupRequirement(this.instituteId)
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async order() {
			await this.loadRequirement()
			if (this.requestProduct.isExpired) {
				this.$refs['ModalRequestExpired'].show()
				return
			}
			if (this.requirement.canOrder) {
				const productList = this.requestProduct.products
				this.setEstimateId()
				this.setSelectedProductList(productList)

				this.$router.push({
					name: PageName.Payment,
					query: {
						isSadadream: this.requestProduct.type == 'PURCHASE_AGENT',
						requestId: this.requestProduct.id,
					},
				})
			} else {
				if (this.isPerson) {
					this.$bvModal.show('ModalShippingRequirement')
				} else {
					this.$bvModal.show('ModalPaymentRequirement')
				}
			}
		},
		addRequest() {
			this.$refs['ModalSadadreamRequest'].show(this.requestProduct.products[0])
		},
		onSuccessRequest() {
			this.$refs['ModalRequestComplete'].show()
		},
		async downloadEstimate() {
			try {
				const { data } = await this.printEstimate({
					instituteId: this.instituteId,
					requestId: this.requestProduct.id,
				})
				window.open(data.url, '_blank')
			} catch (e) {
				console.log(e)
				this.$root.toast('견적서 다운로드', '견적서를 다운로드 할 수 없습니다. 고객센터를 통해 문의해주세요.', 'error')
			}
		},
		goOrderDetail() {
			this.$router.push({
				name: PageName.MyPage.OrderDetail,
				params: {
					id: this.requestProduct.orderId,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.RequestProductDetail {
	width: 1080px;
	margin: 0px auto;
	.content-wrapper {
		> div {
			margin-bottom: 50px;
		}
		.content-notice {
			margin-bottom: 24px;
		}
		.sadadream-explain {
			padding: 10px 20px;
			border-radius: 5px;
			font-size: 14px;
			line-height: 160%;
			background-color: #fff3d6;
		}
		.button-groups {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;
			button {
				width: 240px;
				height: 48px;
				font-size: 16px;
				&.btn-primary {
					background-color: $PRIMARY_YELLOW;
				}
			}
			.cart-tooltip {
				padding: 20px 15px 15px 15px;
				width: 350px;
				height: 113px;
				top: -130px;
				left: calc(50% - 300px);
				border-radius: 5px;
				border: solid 1px #ddd;
				position: absolute;
				background: $PRIMARY_WHITE;
				div {
					display: flex;
					justify-content: space-between;
					img {
						cursor: pointer;
					}
				}
				p {
					font-weight: 700;
					font-size: 16px;
					span {
						font-weight: 400;
					}
				}
				.btn-view-cart {
					width: 100%;
					height: 40px;
					margin-top: 10px;
					background: $PRIMARY_YELLOW;
					&:hover {
						background: #fec741 linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
						color: black;
					}
				}
			}
			.cart-tooltip:after {
				border-bottom: 0px solid transparent;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-top: 8px solid $PRIMARY_WHITE;
				content: '';
				position: absolute;
				top: 110px;
				left: 160px;
				z-index: 1004;
			}
			.cart-tooltip:before {
				border-bottom: 0px solid transparent;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-top: 8px solid #ddd;
				content: '';
				position: absolute;
				top: 111px;
				left: 160px;
				z-index: 1003;
			}
		}
	}
}
</style>
