<template>
	<div class="content-form">
		<div class="title" v-if="title">
			<h6>{{ title }}</h6>
			<span class="required" v-if="required">*</span>
		</div>
		<div class="content-box">
			<textarea class="form-textarea" @input="changeByte" />
			<p v-if="initByte" :class="{ error: byte > initByte }">{{ byte }}/{{ initByte }}byte</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ContentForm',
	props: {
		title: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
		initByte: {
			type: Number,
			default: 1000,
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			byte: 0,
		}
	},
	watch: {},
	methods: {
		changeByte(e) {
			let stringByteLength = e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2').length
			this.byte = stringByteLength
			this.$emit('change', e.target.value)
			this.$emit('byte', this.byte)
		},
	},
}
</script>

<style lang="scss" scoped>
.content-form {
	width: 100%;
	position: relative;
	.title {
		display: inline-flex;
		align-items: center;
		margin-bottom: 1rem;
		h6 {
			font-size: 1rem;
			font-weight: 700;
		}
	}
	.content-box {
		width: 100%;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 5px;
		height: 440px;
		.form-textarea {
			border: 0;
			height: calc(100% - 40px);
		}
		p {
			width: 100%;
			text-align: right;
			padding-right: 1rem;
			font-size: 0.875rem;
			font-weight: 500;
			color: $COLOR_999;
			&.error {
				color: $PRIMARY_RED;
			}
		}
	}
}
</style>
