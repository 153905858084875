<template>
	<div class="productInfo-wrapper">
		<div class="left-wrapper">
			<brand />
			<thumbnail />
		</div>
		<div class="right-wrapper">
			<important-info
				:selectedList="selectedList"
				@changeList="$emit('changeList', $event)"
				@initList="$emit('initList', $event)"
				@draftWriting="$emit('draftWriting')"
				@order="$emit('order')"
				@showModalCancel="(title, content) => $emit('showModalCancel', title, content)"
			/>
		</div>
	</div>
</template>

<script>
import Brand from './ProductInfoBrand'
import Thumbnail from './ProductInfoThumbnail'
import ImportantInfo from './ProductInfoImportant'

export default {
	components: {
		Brand,
		Thumbnail,
		ImportantInfo,
	},
	props: {
		selectedList: {
			type: Array,
			default: () => [],
		},
	},
}
</script>

<style lang="scss" scoped>
.productInfo-wrapper {
	display: flex;
	& > * {
		width: 520px;
		&.left-wrapper {
			margin-right: 40px;
		}
	}
}
</style>
