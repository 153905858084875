<template>
	<div class="management-item">
		<div class="item-info">
			<slot name="info"></slot>
		</div>
		<div class="btn-wrapper">
			<slot name="button"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseManagementItem',
}
</script>

<style lang="scss" scoped>
.management-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.item-info {
		> p {
			margin-top: 8px;
		}
	}
	.btn-wrapper {
		display: flex;
		gap: 8px;
		.btn {
			width: 64px;
		}
	}
}
</style>
