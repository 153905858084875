<template>
	<div class="shipping-info-form">
		<div v-if="info.type != 'PERSON'">
			<text-input v-model="info.shippingInfo.shippingName" :placeholder="startDefine.placeholder('shippingName', info.type)" :error="error" />
			<text-input
				v-model="info.shippingInfo.departmentName"
				:placeholder="startDefine.placeholder('departmentName', info.type)"
				:error="error"
			/>
		</div>
		<address-input
			:address="info.shippingInfo.address"
			addressPlaceholder="주소를 검색해주세요"
			:addressDetail="info.shippingInfo.addressDetail"
			@search="$emit('change', $event)"
		/>
		<p class="error-msg" v-if="error" v-text="errorMessage"></p>
	</div>
</template>

<script>
import TextInput from '@/components/input/TextInput.vue'
import AddressInput from '@/components/input/AddressInput'

export default {
	name: 'ShippingInfoForm',
	props: {
		info: {
			type: Object,
			default: () => {},
		},
		startDefine: {
			type: Object,
			default: () => {},
		},
		error: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: '',
		},
	},
	components: {
		TextInput,
		AddressInput,
	},
	methods: {},
}
</script>

<style lang="scss">
.shipping-info-form {
	.input-form-item {
		padding: 0 0;
		margin-bottom: 10px;
		font-weight: 400;
	}
	.base-input-wrapper {
		width: 100%;
		.base-input {
			width: 100%;
			height: 48px;
			margin-bottom: 10px;
			font-weight: 400;
			font-size: 1rem;
		}
	}
	.address-input-wrapper {
		width: 100%;
		gap: 10px;
		.address-search {
			.base-input {
				width: calc(100% - 10px) !important;
			}
		}
		.base-input-wrapper {
			.base-input {
				height: 48px;
				font-weight: 400;
				font-size: 1rem;
				margin-bottom: 0;
			}
		}
		.btn {
			height: 48px;
			width: 200px;
			background-color: white;
			border: 1px solid $COLOR_333;
			color: $COLOR_333;
			font-size: 1rem;
		}
	}
	.error-msg {
		width: 100%;
		font-size: 0.75rem;
		font-weight: 400;
		padding-left: 18px;
		display: block;
		background-image: url(~@/assets/svg/icon-login-error.svg);
		background-position: center left 0;
		color: $PRIMARY_RED;
		margin-top: 10px;
	}
}
</style>
