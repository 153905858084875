<template>
	<div class="productInfo-data-wrapper">
		<info-list :informationList="informationList" />
		<more-info-button
			v-if="productInfo.extraInfoUrl"
			:link="productInfo.extraInfoUrl"
			title="제조사 제공 정보"
			subTitle="제조사 홈페이지에서 상세정보를 확인하세요."
		>
			<img src="@/assets/svg/book.svg" width="24px" height="24px" />
		</more-info-button>
		<div v-if="brandMsds || sellerMsds" class="data-sub-header">MSDS</div>
		<div class="msds-button-wrapper">
			<more-info-button v-if="brandMsds" :link="brandMsds.url" title="제조사 MSDS" :hasBorder="true" :subTitle="`출처: ${brand.name}`">
				<img :src="seller.image" width="36px" />
			</more-info-button>
			<more-info-button
				v-if="sellerMsds"
				:link="sellerMsds.url"
				title="판매자 MSDS"
				:hasBorder="true"
				:subTitle="`출처: ${seller.name} (${sellerMsds.updateDatetime}업로드됨)`"
			>
				<img src="@/assets/svg/seller-msds.svg" width="24px" height="24px" />
			</more-info-button>
		</div>
		<div class="html-wrapper" v-if="productInfo.detailHtml" v-html="productInfo.detailHtml" />
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import MoreInfoButton from './MoreInfoButton'
import InfoList from '@/components/list/InfoList'

export default {
	props: {
		informationList: {
			type: Array,
			default: () => [],
		},
	},
	components: {
		MoreInfoButton,
		InfoList,
	},
	computed: {
		...mapState('productInfo', ['productInfo']),
		...mapGetters('productInfo', ['seller', 'brand', 'sellerMsds', 'brandMsds']),
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/detail-table.scss';
.productInfo-data-wrapper {
	width: 100%;
	.data-sub-header {
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		margin-top: 30px;
	}
	.msds-button-wrapper {
		display: flex;
		padding-bottom: 30px;
		& > *:last-child {
			margin-left: auto;
		}
	}
}
</style>
<style lang="scss">
.html-wrapper {
	max-width: 1080px;
	img {
		max-width: inherit;
	}
}
</style>
