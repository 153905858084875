import axiosInstance from '@/services/axios'

const endPoint = {
	lookupPopupList: () => `/popup/list`,
}

const popupAPI = {
	lookupPopupList: () => axiosInstance.get(endPoint.lookupPopupList()),
}

export default popupAPI
