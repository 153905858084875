<template>
	<div class="PaymentHistoryDetail">
		<content-title title="결제내역 상세" :statusClass="statusClass" :statusText="statusText" />
		<div class="summary">
			<div>
				<p>결제 요청일</p>
				<b>{{ dateToString(payment.createDatetime) }}</b>
			</div>
			<div>
				<p>결제번호</p>
				<b>{{ payment.no }}</b>
			</div>
			<div v-if="payment.status == 'CANCEL'">
				<p>결제 취소일</p>
				<b>{{ dateToString(payment.cancelDatetime) }}</b>
			</div>
		</div>
		<payment-info-table
			title="결제 상품"
			:headers="paymentTableHeaders"
			:list="productList"
			:paymentStatus="payment.status"
			noDataText="결제 내역이 없습니다"
		/>
		<order-price-box title="최종 결제 정보" mode="normal" :info="orderPriceInfo" />
		<simple-info-box
			title="결제 정보"
			subtitle="전자세금계산서 결제취소는 결제완료일 익월 10일 18:00시까지 취소가능합니다."
			:list="paymentInfo"
			:btnGroup="paymentBtnGroup"
			@download="download"
			@cancel="onCancelPayment"
		/>
		<div class="btn-wrap bottom">
			<button class="btn btn-lg" @click="goList">목록 보기</button>
		</div>

		<modal-submit
			id="modal-submit"
			title="결제 취소"
			@ok="submit"
			size="md"
			okBtnText="결제 취소 하기"
			:content="modal.content"
			:notice="modal.notice"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import mixin from '@/mixins'
import date from '@/mixins/date'
import PageName from '@/utils/define/PageName'
import { Payment } from '@/utils/define/ItemCode'
import ContentTitle from '@/components/content/ContentTitle'
import SimpleInfoBox from '@/components/list/SimpleInfoBox'
import OrderPriceBox from '@/components/list/OrderPriceBox'
import ModalSubmit from '@/components/modal/ModalSubmit'
import PaymentInfoTable from '@/views/my-page/payment/components/PaymentInfoTable'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'PaymentHistoryDetail',
	components: {
		ContentTitle,
		SimpleInfoBox,
		OrderPriceBox,
		ModalSubmit,
		PaymentInfoTable,
	},
	mixins: [mixin, date],
	data() {
		return {
			modal: {
				content: '',
				notice: '',
			},
			paymentTableHeaders: [
				{ text: '상품정보', width: '80%', key: 'info' },
				{ text: '결제 금액(VAT 미포함)', width: '20%', key: 'price' },
			],
			list: [],
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('payment', ['payment']),
		orderPriceInfo() {
			//주문 금액 info
			let info = {}
			info.price = {
				val: this.payment.paymentPrice,
				text: '주문 금액 합계',
			}
			info.shippingPrice = {
				val: 0,
				text: '배송비 합계',
			}
			info.vat = {
				val: this.payment.paymentVat,
				text: 'VAT(10%)',
			}
			info.allPrice = {
				val: this.payment.paymentPriceWithVat,
				text: '결제 금액 합계 (VAT 포함)',
			}
			return info
		},
		paymentInfo() {
			//결제 정보
			let list = []
			list.push({ name: '결제 요청일', value: this.dateToString(this.payment.createDatetime), all: true })
			list.push({ name: '결제 완료일', value: this.dateToString(this.payment.paymentDatetime), all: true })
			list.push({
				name: '결제 상태',
				value: Payment.statusToString(this.payment.status),
				class: Payment.statusToClass(this.payment.status),
				tooltip: this.payment.status == 'WAIT' ? '결제처에서 입금이 완료되면 결제상태가 변경됩니다.' : '',
				all: true,
			})
			list.push({ name: '결제 수단', value: Payment.methodToString(this.payment.method), all: true })
			list.push({ name: '결제처', value: this.isData(this.payment.businessRegistration?.name), all: true })
			list.push({ name: '결제자 이메일', value: this.isData(this.payment.payer?.email), all: true })
			list.push({ name: '결제자', value: this.isData(this.payment.payer?.name), all: true })
			return list
		},
		productList() {
			return this.payment.items ?? []
		},
		paymentBtnGroup() {
			let btnList = []
			if (this.payment.status != 'CANCEL') {
				btnList.push({ key: 'download', text: '결제서류 다운로드' })
				if (this.payment.canCancel === true) btnList.push({ key: 'cancel', text: '결제 취소' })
			}
			return btnList
		},
		statusClass() {
			return Payment.statusToClass(this.payment.status)
		},
		statusText() {
			return Payment.statusToString(this.payment.status)
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				await this.fetchPayment()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('payment', ['lookupPayment', 'paymentPrint', 'cancelPayment']),
		async fetchPayment() {
			await this.lookupPayment({
				instituteId: this.instituteId,
				id: this.$route.params.id,
			})
		},
		async download() {
			try {
				const response = await this.paymentPrint({ instituteId: this.instituteId, body: { id: this.payment.id } })
				window.open(response.url, '_blank')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		onCancelPayment() {
			if (this.payment.method === 'EBILL') {
				this.modal.content = '결제취소된 항목은 미결제금액으로 이동되며,취소서류가 이메일로 재발행됩니다.정말로 취소하시겠습니까?'
			} else {
				this.modal = {
					content: '결제취소된 항목은 미결제금액으로 이동됩니다.<br>정말로 취소하시겠습니까?',
					notice: '카드 청구작업 완료 후 결제 취소 요청 시<br> 카드대금은 그대로 청구되고 익월에 카드사에서 환불됩니다.',
				}
			}
			this.$bvModal.show('modal-submit')
		},
		async submit() {
			try {
				await this.cancelPayment({ instituteId: this.instituteId, body: { id: this.payment.id } })

				this.$router.push({
					name: PageName.MyPage.CompletePayment,
					query: {
						method: this.payment.method,
						no: this.payment.no,
						email: this.payment.payer.email,
					},
				})
				this.$root.darkToast('결제가 취소되었습니다.')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		goList() {
			this.$router.push({
				name: PageName.MyPage.PaymentHistory,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.PaymentHistoryDetail {
	.summary {
		margin-bottom: 40px;
		padding-bottom: 1rem;
		border-bottom: 2px solid $LINE_DIVIDER;
		display: flex;
		align-items: center;
		font-size: 1rem;
		> div {
			display: inline-flex;
			align-items: center;
			position: relative;
			padding-right: 32px;
			&:last-child {
				&:before {
					display: none;
				}
			}
			&:before {
				position: absolute;
				top: calc(50% - 7px);
				right: 1rem;
				width: 1px;
				height: 14px;
				background-color: $COLOR_DDD;
				content: '';
			}
		}
		p {
			font-weight: 500;
		}
		b {
			font-weight: 700;
			margin-left: 10px;
		}
	}
	.order-price-box {
		margin-top: 60px;
	}
	.info-box-wrapper {
		margin-top: 60px;
	}
	.bottom {
		margin: 48px 0 80px;
		button {
			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #f2f3f4;
				color: black;
			}
		}
	}
}
</style>
