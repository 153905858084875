<template>
	<div class="EstimateManagement">
		<content-title title="견적함" />
		<div class="content-wrapper">
			<detail-search-box
				class="search-box"
				title="상세검색"
				subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
				searchPlaceholder="견적번호, 상품정보로 검색"
				searchText="견적생성일"
				:datePlaceholder="datePlaceholder"
				@searchTextList="searchTextList"
			/>
			<common-table
				title="견적 목록"
				isCheck
				:count="count"
				:headers="estimateHeaders"
				:list="list"
				btnText="선택 삭제"
				:noData="noData"
				@clickEv="onClickDelete"
				@go="goDetail"
				@checked="setChecked"
				@page="getCurrentPage"
			/>
			<content-banner
				v-if="!count"
				text="견적서는 장바구니에서 작성하거나, 판매자에게 전달받으실수 있습니다"
				:leftImg="leftImg"
				:rightImg="rightImg"
			/>
		</div>

		<!--modal-->
		<modal-cancel id="ModalCancel" size="md" title="선택한 견적 삭제" content="선택한 견적을 삭제하시겠습니까?" tip="TIP!" @ok="onDelete" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import date from '@/mixins/date'
import ContentTitle from '@/components/content/ContentTitle'
import ContentBanner from '@/components/content/ContentBanner'
import DetailSearchBox from '@/views/my-page/order-delivery/order/components/search/DetailSearchBox'
import CommonTable from '@/components/table/CommonTable'
import PageName from '@/utils/define/PageName'
import ModalCancel from '@/components/modal/ModalCancel'
import { Estimate } from '@/utils/define/ItemCode'

export default {
	name: 'EstimateManagement',
	components: {
		ContentTitle,
		ContentBanner,
		DetailSearchBox,
		CommonTable,
		ModalCancel,
	},
	mixins: [date],
	data() {
		return {
			offset: 0,
			length: 50,
			searchKeyword: '',
			searchDate: [],
			list: [],
			checkList: [],
			leftImg: require('@/assets/svg/content/tip.svg'),
			rightImg: require('@/assets/svg/content/notice-image.svg'),
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('estimate', ['estimates', 'count']),
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
		estimateHeaders() {
			let list = []
			list.push({ key: 'status', name: '견적상태', width: 100 })
			list.push({ key: 'no', name: '견적번호', width: 150 })
			list.push({ key: 'productName', name: '상품정보', width: 400 })
			list.push({ key: 'writer', name: '작성자', width: 100 })
			list.push({ key: 'createDatetime', name: '견적생성일', width: 100 })
			list.push({ key: 'updateDatetime', name: '최종수정일', width: 150 })
			return list
		},
		noData() {
			let obj = {}
			obj.image = require('@/assets/svg/content/no-estimate.svg')
			obj.text = '작성하거나, 전달받은 견적이 없습니다.'
			return obj
		},
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchEstimateList()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('estimate', ['lookupEstimateList', 'deleteEstimate']),
		async fetchEstimateList() {
			await this.lookupEstimateList({
				instituteId: this.instituteId,
				params: {
					offset: this.offset,
					length: this.length,
					keyword: this.searchKeyword,
					startDatetime: this.searchDate.length > 0 ? this.searchDate[0] : '',
					endDatetime: this.searchDate.length > 0 ? this.searchDate[1] : '',
				},
			})

			this.list = this.$_.cloneDeep(this.estimates).map(item => {
				item.status = { class: this.setClass(item.status.code), text: item.status.name }
				item.writer = item.writer.name
				item.createDatetime = this.dateToString(item.createDatetime)
				item.updateDatetime = this.dateToString(item.updateDatetime)
				return item
			})
		},
		setClass(status) {
			return Estimate.statusToClass(status)
		},
		searchTextList(filter) {
			if (this.$_.isEmpty(filter)) {
				this.searchKeyword = ''
				this.searchDate = []
			} else {
				Object.entries(filter).forEach(([key, value]) => {
					if (key === '검색') this.searchKeyword = value.length > 0 ? value[0].value : ''
					else if (key === '기간 조회') this.searchDate = value.length > 0 ? value[0].value : []
				})
			}
			this.fetchEstimateList()
		},
		setChecked(val) {
			this.checkList = val
		},
		onClickDelete() {
			if (!this.checkList.length) return

			this.$bvModal.show('ModalCancel')
		},
		async onDelete() {
			try {
				await Promise.all(
					this.checkList.map(id =>
						this.deleteEstimate({
							instituteId: this.instituteId,
							id,
						})
					)
				)
				this.checkList = []
				this.fetchEstimateList()
				this.$root.toast('견적서 삭제', '견적서가 삭제 되었습니다.', 'success')
			} catch (e) {
				this.$root.toast('삭제 실패', e.response.data.msg, 'error')
			}
		},
		goDetail(id) {
			this.$router.push({
				name: PageName.MyPage.EstimateDetail,
				params: {
					id,
				},
			})
		},
		getCurrentPage({ offset, length }) {
			this.offset = offset
			this.length = length
			this.fetchEstimateList()
		},
	},
}
</script>

<style lang="scss" scoped>
.EstimateManagement {
	.content-wrapper {
		margin-bottom: 64px;
	}
	.common-table {
		margin-top: 48px;
	}
}
</style>
