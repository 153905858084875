<template>
	<div class="search-sort-tab">
		<div class="tab-inner">
			<span v-for="(item, index) in tabList" :key="index">
				<input
					type="radio"
					:id="'tab' + index + 1"
					name="TabList"
					:value="item.id == null ? index + 1 : item.id"
					v-model="selectTab"
					@change="sortProduct(item)"
				/>
				<label :for="'tab' + index + 1">
					{{ item.name }}
				</label>
			</span>
		</div>
		<switch-box v-if="isSwitch" title="재고 있는 상품만 보기" @change="changeSwitch" />
	</div>
</template>

<script>
import SwitchBox from '@/components/button/SwitchBox.vue'

export default {
	name: 'SearchSortTab',
	components: {
		SwitchBox,
	},
	props: {
		tabList: {
			type: Array,
			default: () => {},
		},
		isSwitch: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectTab: {},
			switch: false,
		}
	},
	created() {
		this.initTabActive()
	},
	watch: {
		tabList(val) {
			this.initTabActive()
		},
		switch(val) {
			this.$emit('change', val)
		},
	},
	methods: {
		initTabActive() {
			this.selectTab = this.tabList[0].id
		},
		sortProduct(value) {
			this.$emit('sortProduct', value)
		},
		changeSwitch(val) {
			this.switch = val
		},
	},
}
</script>

<style lang="scss" scoped>
.search-sort-tab {
	width: 100%;
	border-bottom: 1px solid $LINE_DIVIDER;
	display: flex;
	align-items: center;
	height: 40px;
	.tab-inner {
		display: flex;
		align-items: center;
		span {
			cursor: pointer;
			input[type='radio'] {
				width: 0;
				height: 0;
				opacity: 0;
				visibility: hidden;
			}
			input[type='radio']:checked ~ label {
				font-weight: 700;
				color: black;
				background-image: url(~@/assets/svg/sort-checked.svg);
				background-position: center left 0;
				background-size: 14px;
				background-repeat: no-repeat;
				padding-left: 18px;
			}
			label {
				font-size: 0.875rem;
				display: inline-block;
				color: $COLOR_666;
				font-weight: 400;
				margin-right: 20px;
				cursor: pointer;
			}
		}
	}
	.switch-box {
		margin-left: auto;
	}
}
</style>
