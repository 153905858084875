<template>
	<div class="my-page-header">
		<div class="address-wrapper">
			<div class="profile">
				<img
					:src="myPageInfo.image ? myPageInfo.image : require('@/assets/svg/empty-profile.svg')"
					:class="{ round: !$_.isEmpty(myPageInfo.image) }"
				/>

				<span>{{ myPageInfo.name }} 님 반갑습니다.</span>
			</div>
			<div class="address" @click="openChangeAddressModal">
				<div>{{ subtext }}</div>
				<img src="@/assets/svg/dropdown.svg" />
			</div>
		</div>
		<div class="no-paid-wrapper">
			<img src="@/assets/svg/icon-money-line.svg" />
			<p>결제해야할 금액</p>
			<div>{{ CommaNum(myPageInfo.noPaidPriceWithVat) }}원</div>
		</div>
		<div class="limit-wrapper">
			<img src="@/assets/svg/icon-card-line.svg" />
			<p>후불결제 구매 한도</p>
			<div>{{ CommaNum(myPageInfo.limitCredit) }}원</div>
		</div>
		<div class="saving-wrapper">
			<img src="@/assets/svg/icon-saving-line.svg" />
			<p>절약한 총 금액</p>
			<div>{{ CommaNum(myPageInfo.totalDiscountPrice) }}원</div>
		</div>
		<modal-select-current-address ref="ModalSelectCurrentAddress" :propsCurrentAddress="currentAddress" />
		<modal-shipping-address :id="'ModalAddAddress'" title="배송지 등록" @success="lookupAddressList(instituteId)" />
	</div>
</template>
<script>
import ModalSelectCurrentAddress from '@/components/modal/ModalSelectCurrentAddress.vue'
import ModalShippingAddress from '@/views/my-page/my-info/shipping-address/modal/ModalShippingAddress.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import mixins from '@/mixins'
import cookies from 'vue-cookies'
export default {
	components: {
		ModalSelectCurrentAddress,
		ModalShippingAddress,
	},
	name: 'MyPageHeader',
	mixins: [mixins],
	data() {
		return {
			cookieCurrentAddress: cookies.get('currentAddress2'),
		}
	},
	computed: {
		...mapState('user', ['myPageInfo']),
		...mapGetters('user', ['instituteId']),
		...mapState('delivery', ['basicAddress']),
		...mapState('nopaid', ['nopaidPrice']),
		currentAddress() {
			return this.cookieCurrentAddress ?? this.basicAddress
		},
		subtext() {
			if (this.currentAddress && !this.$_.isEmpty(this.currentAddress)) {
				return `${this.currentAddress.address}, ${this.currentAddress.addressDetail}`
			} else {
				return '배송지를 등록하고 16만종의 시약을 주문해보세요'
			}
		},
	},
	mounted() {
		if (cookies.get('isShowAddressToast') === 'true') {
			this.$root.toast('배송지 변경 완료', '배송지가 변경 되었습니다.', 'success')
			cookies.remove('isShowAddressToast')
		}
	},
	watch: {
		instituteId: {
			async handler(newVal, oldVal) {
				if (!newVal) return
				if (newVal != oldVal && oldVal != null) cookies.remove('currentAddress2')
				await this.lookupAddressList(this.instituteId)
				// currentAddress의 시점에러 때문에, 로직 수정
				cookies.set('currentAddress2', this.basicAddress)
				this.cookieCurrentAddress = this.basicAddress
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('delivery', ['lookupAddressList']),
		openChangeAddressModal() {
			this.$refs['ModalSelectCurrentAddress'].show()
		},
	},
}
</script>
<style lang="scss" scoped>
.my-page-header {
	height: 120px;
	width: 100%;
	display: flex;
	gap: 20px;
	& > div {
		background-color: #fef9ec;
		border-radius: 16px;
		height: 100%;
		justify-content: center;
		display: flex;
		flex-direction: column;
		img {
			width: 40px;
			height: 40px;
		}
	}
	.address-wrapper {
		width: 420px;
		padding: 20px 30px;
		gap: 5px;
		flex: 1 0 0;
		.profile {
			font-size: 20px;
			line-height: 25px;
			color: #191414;
			align-items: center;
			display: flex;
			gap: 10px;
			img {
				width: 30px;
				height: 30px;
				border-radius: 50%;
				object-fit: cover;
			}
		}
		.address {
			width: 100%;
			display: flex;
			align-items: center;
			gap: 10px;
			cursor: pointer;
			font-size: 20px;
			font-weight: 600;
			color: #191414;
			&:hover {
				text-decoration: underline;
			}
			& > div {
				width: 100%;
				@include ellipse;
				line-height: 24px;
			}
			img {
				width: 16px;
				height: 16px;
			}
		}
	}
	.no-paid-wrapper,
	.limit-wrapper,
	.saving-wrapper {
		width: 200px;
		padding: 20px;
		padding-top: 20px;
		color: #191414;
		p {
			font-size: 12px;
			font-weight: 500;
			line-height: 18px;
		}
		div {
			font-size: 20px;
			font-weight: 600;
			line-height: 25px;
		}
	}
}
</style>
