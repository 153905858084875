export default {
	count: 27,
	list: [
		{
			isWishProduct: false,
			id: 15797,
			name: 'Acephate-(acetyl-d3)',
			mngcode: '보관함A-시약-97',
			incompanyMngcode: '333',
			createDatetime: '2022-06-28T06:21:41.000Z',
			updateDatetime: '2022-06-28T06:23:15.647Z',
			managementMethod: 'CENTER',
			registerMethod: 'MANUAL_DIRECT',
			price: 798000,
			priceUnit: '원',
			buyDatetime: '2022-06-25T15:00:00.000Z',
			stickerType: 'NONE',
			volume: 100,
			currentVolume: 90,
			volumeUnit: 'L',
			normalizeVolume: 100000,
			normalizeCurrentVolume: 90000,
			subname: 'PESTANAL(R), analytical standard',
			casno: '71-43-2',
			productCode: '401765',
			threshold: 1,
			thresholdUnit: 'kg',
			openDatetime: '2022-06-25T15:00:00.000Z',
			expireDatetime: null,
			kind: null,
			density: '3.75',
			densityUnit: 'g/mL at 25℃',
			molecularWeight: '3.75',
			molecularWeightUnit: 'g/mol',
			molarMass: '3.75',
			molarMassUnit: 'g.mol',
			assay: '3.75',
			assayUnit: '%',
			storageTemperature: '36.5',
			storageTemperatureUnit: '℃',
			solubility: '3.75',
			molecularFormula: '분자식은string',
			flashPoint: '1000.1',
			boilingPoint: '100.1',
			meltingPoint: '0.01',
			spec: null,
			useCount: null,
			usp: null,
			innerDiameter: null,
			innerDiameterUnit: null,
			particleSize: null,
			particleSizeUnit: null,
			length: null,
			lengthUnit: null,
			poreSize: null,
			poreSizeUnit: null,
			phRangeFrom: null,
			phRangeTo: null,
			carbonLoad: null,
			endcapped: null,
			category: {
				id: '4oMdBS',
				name: '시약',
				classification: 'CAT_CHEMICAL',
				type: 'CAT_CHEMICAL_REAGENT',
			},
			storage: {
				id: 'SwiIln',
				name: '보관함A',
				pathName: '보관함A',
				assigneeGroup: {
					id: 'kfsU3m',
					name: '이상헌JW기관',
				},
			},
			location: {
				id: 'tCs9aT',
				name: '이상헌JW기관',
				pathName: '이상헌JW기관',
			},
			assignee: {
				id: '11ece2e843b39ddf',
				name: '배재원',
				image: null,
				phone: '',
			},
			register: {
				id: '11ece2e843b39ddf',
				name: '배재원',
				image: null,
				phone: '',
			},
			holder: null,
			buyer: {
				id: '11ece2e843b39ddf',
				name: '배재원',
				image: null,
				phone: '',
			},
			brand: {
				id: 1,
				name: 'Sigma-Aldrich',
				image: 'https://cloud.lab-manager.com/maker_logo/SigmaAldrich.png',
			},
			status: 'WAIT',
			isNew: false,
			images: ['https://cloud.lab-manager.com/store/assets/product_thumbnail/smartjack/1620578510116.jpg'],
			options: [
				{
					id: 1,
					name: '100mg',
				},
			],
			volumeList: [
				{
					volume: 100,
					volumeUnit: 'mg',
				},
			],
			seller: [
				{
					id: 1,
					name: '스마트잭',
					minPrice: 170800,
					image: 'https://cloud.lab-manager.com/maker_logo/SigmaAldrich.png',
				},
				{
					id: 2,
					name: '서브원',
					minPrice: 227000,
					image: 'https://cloud.lab-manager.com/maker_logo/SigmaAldrich.png',
				},
			],
			minPrice: 10000,
			stock: 10,
			count: 2,
			shipping: {
				type: 'SHIPPING_AVAILABLE',
				shippingDate: '2022-06-30T05:41:42.000Z',
			},
		},
		{
			id: 15796,
			name: 'Acephate-(acetyl-d3)',
			mngcode: '보관함A-시약-96',
			incompanyMngcode: '333',
			createDatetime: '2022-06-28T06:21:31.000Z',
			updateDatetime: '2022-06-28T06:23:15.647Z',
			managementMethod: 'CENTER',
			registerMethod: 'MANUAL_DIRECT',
			price: 170800,
			priceUnit: '원',
			buyDatetime: '2022-06-25T15:00:00.000Z',
			stickerType: 'NONE',
			volume: 100,
			currentVolume: 90,
			volumeUnit: 'L',
			normalizeVolume: 100000,
			normalizeCurrentVolume: 90000,
			subname: 'PESTANAL(R), analytical standard',
			casno: '71-43-2',
			productCode: '401765',
			threshold: 1,
			thresholdUnit: 'kg',
			openDatetime: '2022-06-25T15:00:00.000Z',
			expireDatetime: null,
			kind: null,
			density: '3.75',
			densityUnit: 'g/mL at 25℃',
			molecularWeight: '3.75',
			molecularWeightUnit: 'g/mol',
			molarMass: '3.75',
			molarMassUnit: 'g.mol',
			assay: '3.75',
			assayUnit: '%',
			storageTemperature: '36.5',
			storageTemperatureUnit: '℃',
			solubility: '3.75',
			molecularFormula: '분자식은string',
			flashPoint: '1000.1',
			boilingPoint: '100.1',
			meltingPoint: '0.01',
			spec: null,
			useCount: null,
			usp: null,
			innerDiameter: null,
			innerDiameterUnit: null,
			particleSize: null,
			particleSizeUnit: null,
			length: null,
			lengthUnit: null,
			poreSize: null,
			poreSizeUnit: null,
			phRangeFrom: null,
			phRangeTo: null,
			carbonLoad: null,
			endcapped: null,
			category: {
				id: '4oMdBS',
				name: '시약',
				classification: 'CAT_CHEMICAL',
				type: 'CAT_CHEMICAL_REAGENT',
			},
			storage: {
				id: 'SwiIln',
				name: '보관함A',
				pathName: '보관함A',
				assigneeGroup: {
					id: 'kfsU3m',
					name: '이상헌JW기관',
				},
			},
			location: {
				id: 'tCs9aT',
				name: '이상헌JW기관',
				pathName: '이상헌JW기관',
			},
			assignee: {
				id: '11ece2e843b39ddf',
				name: '배재원',
				image: null,
				phone: '',
			},
			register: {
				id: '11ece2e843b39ddf',
				name: '배재원',
				image: null,
				phone: '',
			},
			holder: null,
			buyer: {
				id: '11ece2e843b39ddf',
				name: '배재원',
				image: null,
				phone: '',
			},
			brand: {
				id: 1,
				name: 'Sigma-Aldrich',
				image: 'https://cloud.lab-manager.com/maker_logo/SigmaAldrich.png',
			},
			status: 'COMPLETE',
			isNew: false,
			images: ['https://cloud.lab-manager.com/store/assets/product_thumbnail/smartjack/1620579481393.jpg'],
			options: [
				{
					id: 100,
					name: '100mg',
				},
				{
					id: 200,
					name: '200mg',
				},
				{
					id: 300,
					name: '300mg',
				},
			],
			volumeList: [
				{
					volume: 100,
					volumeUnit: 'mg',
				},
				{
					volume: 200,
					volumeUnit: 'mg',
				},
				{
					volume: 300,
					volumeUnit: 'mg',
				},
			],
			seller: [
				{
					id: 1,
					name: '스마트잭',
					minPrice: 500000,
					image: 'https://cloud.lab-manager.com/maker_logo/SigmaAldrich.png',
				},
				{
					id: 2,
					name: '서브원',
					minPrice: 594000,
					image: 'https://cloud.lab-manager.com/maker_logo/SigmaAldrich.png',
				},
				{
					id: 3,
					name: '세진씨아이',
					minPrice: 680000,
				},
			],
			minPrice: 12000,
			stock: 10,
			count: 1,
			shipping: {
				type: 'SHIPPING_AVAILABLE',
				shippingDate: '2022-06-30T05:41:42.000Z',
			},
		},
		{
			isWishProduct: true,
			id: 15791,
			name: 'Acephate',
			mngcode: '즉시출고-시약-92',
			incompanyMngcode: null,
			createDatetime: '2022-06-28T05:42:32.000Z',
			updateDatetime: '2022-06-28T06:23:15.647Z',
			managementMethod: 'CENTER',
			registerMethod: 'MANUAL_DIRECT',
			price: 13500,
			priceUnit: '원',
			buyDatetime: null,
			stickerType: 'NONE',
			volume: 100,
			currentVolume: 90,
			volumeUnit: 'L',
			normalizeVolume: 100000,
			normalizeCurrentVolume: 90000,
			subname: 'PESTANAL(R), analytical standard',
			casno: '71-43-2',
			productCode: '401765',
			threshold: null,
			thresholdUnit: null,
			openDatetime: null,
			expireDatetime: null,
			kind: null,
			density: '3.75',
			densityUnit: 'g/mL at 25℃',
			molecularWeight: '3.75',
			molecularWeightUnit: 'g/mol',
			molarMass: '3.75',
			molarMassUnit: 'g.mol',
			assay: '3.75',
			assayUnit: '%',
			storageTemperature: '36.5',
			storageTemperatureUnit: '℃',
			solubility: '3.75',
			molecularFormula: '분자식은string',
			flashPoint: '1000.1',
			boilingPoint: '100.1',
			meltingPoint: '0.01',
			spec: null,
			useCount: null,
			usp: null,
			innerDiameter: null,
			innerDiameterUnit: null,
			particleSize: null,
			particleSizeUnit: null,
			length: null,
			lengthUnit: null,
			poreSize: null,
			poreSizeUnit: null,
			phRangeFrom: null,
			phRangeTo: null,
			carbonLoad: null,
			endcapped: null,
			category: {
				id: '4oMdBS',
				name: '시약',
				classification: 'CAT_CHEMICAL',
				type: 'CAT_CHEMICAL_REAGENT',
			},
			storage: {
				id: 'SLXi5c',
				name: '즉시출고보관함',
				pathName: '즉시출고보관함',
				assigneeGroup: {
					id: 'kfsU3m',
					name: '이상헌JW기관',
				},
			},
			location: {
				id: 'tCs9aT',
				name: '이상헌JW기관',
				pathName: '이상헌JW기관',
			},
			assignee: {
				id: '11ece2e85e73da5c',
				name: '박찬우',
				image: null,
				phone: '',
			},
			register: {
				id: '11ece2e843b39ddf',
				name: '배재원',
				image: null,
				phone: '',
			},
			holder: null,
			buyer: null,
			brand: {
				id: 1,
				name: 'Sigma-Aldrich',
				image: 'https://cloud.lab-manager.com/maker_logo/SigmaAldrich.png',
			},
			status: 'FAIL',
			isNew: false,
			images: ['https://cloud.lab-manager.com/store/assets/product_thumbnail/smartjack/1620578946048.jpg'],
			options: [
				{
					id: 10,
					volumeUnit: '1L',
				},
			],
			volumeList: [
				{
					volume: 1,
					volumeUnit: 'L',
				},
			],
			seller: [
				{
					id: 1,
					name: 'Sigma-Aldrich',
					minPrice: 56000,
				},
				{
					id: 2,
					name: '서브원',
					minPrice: 87000,
				},
			],
			minPrice: 5000,
			stock: 50,
			count: 5,
			shipping: {
				type: 'SHIPPING_AVAILABLE',
				shippingDate: '2022-06-30T05:41:42.000Z',
			},
		},
		{
			isWishProduct: true,
			id: 15790,
			name: 'Acephate-(acetyl-d3)',
			mngcode: '즉시출고-시약-91',
			incompanyMngcode: null,
			createDatetime: '2022-06-28T05:41:42.000Z',
			updateDatetime: '2022-06-28T06:23:15.647Z',
			managementMethod: 'CENTER',
			registerMethod: 'MANUAL_DIRECT',
			price: 158000,
			priceUnit: '원',
			buyDatetime: null,
			stickerType: 'NONE',
			volume: 100,
			currentVolume: 90,
			volumeUnit: 'L',
			normalizeVolume: 100000,
			normalizeCurrentVolume: 90000,
			subname: 'PESTANAL(R), analytical standard',
			casno: '71-43-2',
			productCode: '401765',
			threshold: null,
			thresholdUnit: null,
			openDatetime: null,
			expireDatetime: null,
			kind: null,
			density: '3.75',
			densityUnit: 'g/mL at 25℃',
			molecularWeight: '3.75',
			molecularWeightUnit: 'g/mol',
			molarMass: '3.75',
			molarMassUnit: 'g.mol',
			assay: '3.75',
			assayUnit: '%',
			storageTemperature: '36.5',
			storageTemperatureUnit: '℃',
			solubility: '3.75',
			molecularFormula: '분자식은string',
			flashPoint: '1000.1',
			boilingPoint: '100.1',
			meltingPoint: '0.01',
			spec: null,
			useCount: null,
			usp: null,
			innerDiameter: null,
			innerDiameterUnit: null,
			particleSize: null,
			particleSizeUnit: null,
			length: null,
			lengthUnit: null,
			poreSize: null,
			poreSizeUnit: null,
			phRangeFrom: null,
			phRangeTo: null,
			carbonLoad: null,
			endcapped: null,
			category: {
				id: '4oMdBS',
				name: '시약',
				classification: 'CAT_CHEMICAL',
				type: 'CAT_CHEMICAL_REAGENT',
			},
			storage: {
				id: 'SLXi5c',
				name: '즉시출고보관함',
				pathName: '즉시출고보관함',
				assigneeGroup: {
					id: 'kfsU3m',
					name: '이상헌JW기관',
				},
			},
			location: {
				id: 'tCs9aT',
				name: '이상헌JW기관',
				pathName: '이상헌JW기관',
			},
			assignee: {
				id: '11ece2e85e73da5c',
				name: '박찬우',
				image: null,
				phone: '',
			},
			register: {
				id: '11ece2e85e73da5c',
				name: '박찬우',
				image: null,
				phone: '',
			},
			holder: null,
			buyer: null,
			brand: {
				id: 1,
				name: 'Sigma-Aldrich',
				image: 'https://cloud.lab-manager.com/maker_logo/SigmaAldrich.png',
			},
			status: 'FIND',
			isNew: false,
			images: ['https://cloud.lab-manager.com/store/assets/product_thumbnail/smartjack/1620578974165.jpg'],
			options: [
				{
					id: 100,
					name: '100mg',
				},
				{
					id: 200,
					name: '200mg',
				},
				{
					id: 300,
					name: '300mg',
				},
			],
			volumeList: [
				{
					volume: 100,
					volumeUnit: 'mg',
				},
				{
					volume: 200,
					volumeUnit: 'mg',
				},
				{
					volume: 300,
					volumeUnit: 'mg',
				},
			],
			seller: [
				{
					id: 1,
					name: '세진씨아이',
					minPrice: 224000,
				},
				{
					id: 2,
					name: '머크 코리아',
					minPrice: 239000,
				},
				{
					id: 3,
					name: '성호씨그마',
					minPrice: 242000,
				},
				{
					id: 4,
					name: '서브원',
					minPrice: 254000,
				},
			],
			minPrice: 10000,
			stock: 10,
			count: 1,
			shipping: {
				type: 'SHIPPING_AVAILABLE',
				shippingDate: '2022-06-30T05:41:42.000Z',
			},
		},
	],
	saleList: [
		{
			id: 15797,
			price: 860000,
			priceUnit: '원',
			seller: {
				id: 1,
				name: 'Sigma-Aldrich',
				image: 'https://cloud.lab-manager.com/maker_logo/SigmaAldrich.png',
			},
			category: {
				id: '4oMdBS',
				name: '시약',
				classification: 'CAT_CHEMICAL',
				type: 'CAT_CHEMICAL_REAGENT',
			},
			volume: 100,
			volumeUnit: 'mg',
			stock: 100,
			shipping: {
				type: 'STOCK_CONFIRM',
				shippingDate: null,
			},
			subname: '라텍스 실험장갑',
			casno: '71-43-2',
			productCode: '401765',
			name: '“High-Five” Latex Exam Glove, Textured, Powder-Free, Medical/Exam Grade',
		},
		{
			id: 17090,
			price: 1167800,
			priceUnit: '원',
			seller: {
				id: 2,
				name: '유원인텍',
				image: 'https://cloud.lab-manager.com/maker_logo/유원인텍.png',
			},
			shipping: {
				type: 'SHIPPING_AVAILABLE',
				shippingDate: '2022-06-30T05:41:42.000Z',
			},
			subname: 'PESTANAL(R), analytical standard',
			casno: '30560-19-1',
			name: 'Acephate-(Acetyl-d3)',
			volume: 200,
			volumeUnit: 'mg',
			stock: 50,
		},
		{
			id: 23148,
			price: 986400,
			priceUnit: '원',
			seller: {
				id: 715,
				name: 'Advanced ChemTech',
				image: 'https://cloud.lab-manager.com/maker_logo/AdvancedChemTech.png',
			},
			shipping: {
				type: 'SHIPPING_AVAILABLE',
				shippingDate: '2022-07-02T05:41:42.000Z',
			},
			subname: 'InfinityLab Poroshell 120 EC-C18, 3.0 x 50 mm, 2.7 µm, solvent saver LC column',
			casno: '061-23-1',
			name: 'Agilent Poroshell 120 EC-C18, 3.0x50, 2.7µm',
			volume: 5,
			volumeUnit: 'L',
			stock: 10,
		},
	],
}
