<template>
	<div class="CompletePayment">
		<content-title title="결제 취소 완료" />
		<complete-notice
			title="결제 취소 완료되었습니다"
			:titleImg="titleImg"
			:description="description"
			:list="paymentInfo"
			:noticeImg="noticeImg"
			leftBtnText="결제 내역 보기"
			@leftBtnEv="goList"
		/>
	</div>
</template>

<script>
import ContentTitle from '@/components/content/ContentTitle'
import CompleteNotice from '@/components/content/CompleteNotice'
import PageName from '@/utils/define/PageName'

export default {
	name: 'CompletePayment',
	components: {
		ContentTitle,
		CompleteNotice,
	},
	data() {
		return {
			titleImg: require('@/assets/svg/icon-currency.svg'),
			noticeImg: require('@/assets/svg/content/success-image.svg'),
			leftBtnText: '결제 내역 보기',
		}
	},
	computed: {
		description() {
			if (this.$route.query.method === 'EBILL')
				return '고객님이 취소하신 내역은 [결제 내역] 에서 다시 조회가능합니다<br>취소에 필요한 전자세금계산서가 이메일로 다시 발행되고,<br>결제 취소된 항목은 [미결제 금액] 목록에서 다시 결제되어야 합니다.'
			else
				return '고객님이 취소하신 내역은 [결제 내역] 에서 다시 조회가능합니다<br>카드 청구작업 완료 후 결제 취소 요청시, 카드대금은 그대로 청구되고<br>익월에 카드사에서 환불됩니다.'
		},
		paymentInfo() {
			let list = []
			list.push(`결제번호 : ${this.$route.query.no}`)
			list.push(`결제자 이메일 : ${this.$route.query.email}`)
			return list
		},
	},
	methods: {
		goList() {
			this.$router.push({
				name: PageName.MyPage.PaymentHistory,
			})
		},
	},
}
</script>
