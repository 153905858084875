<template>
	<div class="title-wrapper">
		<div class="left-contents">
			<b class="title">{{ title }}</b>
			<img :src="image" />
		</div>
		<div v-if="hasAllBtn" class="right-contents" @click="onClickViewAllBtn">
			<p>전체보기</p>
			<img src="@/assets/svg/main/viewAllBtn.svg" />
		</div>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'

export default {
	name: 'MainTitle',
	props: {
		title: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
		imageType: {
			type: String,
			default: '',
		},
		hasAllBtn: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		image() {
			if (this.imageType === 'best') return require('@/assets/svg/main/icon-best.svg')
			else if (this.imageType === 'check') return require('@/assets/svg/main/icon-check.svg')
			else if (this.imageType === 'caution') return require('@/assets/svg/main/icon-caution.svg')
		},
	},
	methods: {
		onClickViewAllBtn() {
			if (this.type === 'frequently')
				this.$router.push({
					name: PageName.FrequentlyOrderList,
				})
			else if (this.type === 'recently')
				this.$router.push({
					name: PageName.MyPage.RecentView,
				})
			else if (this.type === 'brand')
				this.$router.push({
					name: PageName.List,
					params: {
						category: this.type,
					},
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.title-wrapper {
	padding-top: 100px;
	display: flex;
	justify-content: space-between;
	.left-contents {
		display: flex;
		.title {
			font-weight: 700;
			font-size: 27px;
		}
		img {
			padding-left: 10px;
		}
	}
	.right-contents {
		display: flex;
		align-items: center;
		cursor: pointer;
		p {
			font-weight: 500;
			font-size: 14px;
			padding-right: 5px;
		}
		img {
			width: 15px;
			height: 15px;
		}
	}
}
</style>
