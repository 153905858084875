<template>
	<b-modal id="ModalRegistrationPreview" centered title="사업자등록증 미리보기" @show="onShow">
		<div class="registration-preview-wrapper">
			<embed
				v-if="currentImageExtension === '.pdf'"
				type="application/pdf"
				frameborder="0"
				:src="`https://drive.google.com/viewerng/viewer?embedded=true&toolbar=1&url=${currentImage}`"
			/>
			<img v-else :src="currentImage" />
			<template v-if="images.length > 1">
				<div class="left" @click="onClickLeft">
					<img src="@/assets/svg/prev-arrow-w.svg" />
				</div>
				<div class="right" @click="onClickRight">
					<img src="@/assets/svg/next-arrow-w.svg" />
				</div>
			</template>
		</div>
		<template #modal-footer>
			<div class="registration-preview-footer">
				<span>{{ currentIndex }}</span
				>/{{ totalIndex }}
			</div>
		</template>
	</b-modal>
</template>

<script>
export default {
	name: 'ModalRegistrationPreview',
	props: {
		images: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			index: 0,
		}
	},
	computed: {
		totalIndex() {
			return this.images.length
		},
		currentIndex() {
			return this.index + 1
		},
		currentImageExtension() {
			if (this.images.length <= 0) return
			const url = this.images[this.index].url
			var dot = url.lastIndexOf('.')
			var extension = url.substring(dot, url.length).toLowerCase()
			return extension
		},
		currentImage() {
			return this.images.length > 0 ? this.images[this.index].url : ''
		},
	},
	methods: {
		onShow() {
			this.index = 0
		},
		onClickLeft() {
			if (this.currentIndex <= 1) return

			this.index--
		},
		onClickRight() {
			if (this.currentIndex >= this.totalIndex) return

			this.index++
		},
	},
}
</script>

<style lang="scss">
#ModalRegistrationPreview___BV_modal_outer_ {
	.modal-dialog {
		max-width: 600px;
	}
	#ModalRegistrationPreview___BV_modal_header_ {
		&.modal-header {
			border-bottom: 1px solid $COLOR_EEE;
		}
	}
	#ModalRegistrationPreview___BV_modal_footer_ {
		&.modal-footer {
			border-top: 1px solid $COLOR_EEE;
			padding: 20px;
		}
	}
}
</style>

<style lang="scss" scoped>
.registration-preview-wrapper {
	position: relative;
	text-align: center;
	> img,
	embed {
		width: 510px;
		height: 680px;
	}
	> div {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 50%;
		width: 40px;
		height: 40px;
		background: #fec741;
		border-radius: 100px;
		color: $PRIMARY_WHITE;
		cursor: pointer;
		&.left {
			left: 0;
			> img {
				margin-left: 5px;
			}
		}
		&.right {
			right: 0;
			> img {
				margin-right: 5px;
			}
		}
	}
}
.registration-preview-footer {
	width: 100%;
	text-align: center;
	font-weight: 500;
	font-size: 0.875rem;
	color: $COLOR_666;
	> span {
		color: $COLOR_333;
	}
}
</style>
