<template>
	<b-modal
		:id="id"
		centered
		:title="title"
		:ok-title="title"
		cancel-title="취소"
		cancel-variant="default"
		ok-variant="danger"
		@ok="cancel"
		ref="modal"
		@hidden="onHidden"
		><div class="boxwrap">
			<div class="Popup__content">
				<p class="Popup__plain-text" v-html="content"></p>
				<!-- 취소 사유 선택 -->
				<div class="Popup__checkbox-field">
					<p style="padding-bottom: 9px">{{ '취소 사유 선택' }}</p>
					<ul>
						<li>
							<input type="radio" id="buy" name="reason" v-model="cancelReason" :value="CANCEL_REASON.BUY_INTENTION" />
							<label for="buy">{{ CANCEL_REASON.BUY_INTENTION }}</label>
						</li>
						<li>
							<input type="radio" id="size" name="reason" v-model="cancelReason" :value="CANCEL_REASON.CHANGE_SIZE" />
							<label for="size">{{ CANCEL_REASON.CHANGE_SIZE }}</label>
						</li>
						<li>
							<input type="radio" id="diif" name="reason" v-model="cancelReason" :value="CANCEL_REASON.DIFF_PRODUCT_INFO" />
							<label for="diif">{{ CANCEL_REASON.DIFF_PRODUCT_INFO }}</label>
						</li>
						<li>
							<input type="radio" id="etc" name="reason" v-model="cancelReason" :value="CANCEL_REASON.ETC" />
							<label for="etc">{{ CANCEL_REASON.ETC }}</label>
						</li>
					</ul>
				</div>
				<div class="Popup__textarea-field">
					<div class="flex">
						<p>{{ '취소 상세사유 입력' }}</p>
						<p style="cursor: pointer" @click="cancelReasonDetail = ''">{{ '모두 지우기' }}</p>
					</div>
					<div>
						<textarea :placeholder="'취소사유를 입력해주세요'" rows="6" v-model="cancelReasonDetail"></textarea>
					</div>
					<!-- <span class="notice"><img src="@/assets/svg/side-warning.svg" alt="경고icon" />{{ tt('글자수를 초과하였습니다') }}</span> -->
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { CANCEL_REASON, CANCEL_TYPE } from '@/utils/define/order'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'ModalOrderCancel',
	props: {
		id: {
			type: String,
			default: 'ModalOrderCancel',
		},
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		itemId: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			CANCEL_REASON,
			cancelReason: CANCEL_REASON.BUY_INTENTION,
			cancelReasonDetail: '',
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
	},
	methods: {
		...mapActions('order', ['orderCancelRequest', 'orderCancel']),
		onHidden() {
			this.cancelReason = CANCEL_REASON.BUY_INTENTION
			this.cancelReasonDetail = ''
		},
		async cancel() {
			if (this.title === CANCEL_TYPE.CANCEL) {
				// 주문 취소
				try {
					await this.orderCancel({
						instituteId: this.instituteId,
						body: {
							orderItemIds: [this.itemId],
							cancelReason: this.cancelReason,
							cancelReasonDetail: this.cancelReasonDetail,
						},
					})
					this.$root.darkToast('취소 요청하신 상품의 주문이 취소되었습니다.')
					// this.$root.toast('주문 취소 완료', '상품의 주문을 취소했습니다.', 'success')
					this.$emit('complete')
				} catch (e) {
					this.$root.toast('주문 취소 실패', e.response.data.msg, 'error')
				}
			} else if (this.title === CANCEL_TYPE.CANCEL_REQUEST) {
				// 취소 요청
				try {
					await this.orderCancelRequest({
						instituteId: this.instituteId,
						body: {
							orderItemIds: [this.itemId],
							cancelReason: this.cancelReason,
							cancelReasonDetail: this.cancelReasonDetail,
						},
					})
					this.$root.toast('취소 요청 완료', '상품의 주문을 취소 요청했습니다.', 'success')
					this.$emit('complete')
				} catch (e) {
					this.$root.toast('취소 요청 실패', e.response.data.msg, 'error')
				}
			}
		},
	},
}
</script>

<style lang="scss">
#ModalOrderCancel___BV_modal_footer_ {
	.btn-danger {
		background: $PRIMARY_RED;
	}
}
</style>

<style lang="scss" scoped>
.boxwrap {
	margin-top: -15px;
	margin-bottom: -15px;
	.Popup__content {
		p {
			display: inline-block;
			font-size: 0.75rem;
			color: #000;
			font-weight: 400;
		}
		.Popup__plain-text {
			font-size: 0.875rem;
			padding-bottom: 15px;
		}
		.Popup__checkbox-field {
			padding-bottom: 23px;
			label {
				font-size: 1rem;
				color: #000;
				font-weight: 700;
				cursor: pointer;
			}
			ul {
				display: flex;
				flex-direction: column;
				gap: 10px;
				li {
					padding-left: 5px;
					line-height: 18px;
					display: flex;
					flex-direction: row;
					gap: 10px;

					input[type='radio'],
					input[type='radio']:checked {
						appearance: none;
					}
					input[type='radio'] {
						width: 18px;
						height: 18px;
						border-radius: 100%;
						margin-right: 0.1rem;
						background-color: $PRIMARY_WHITE;
						border: 2px solid #eeeeee;
					}
					input[type='radio']:checked {
						background-image: url(~@/assets/svg/main/radio_check.svg);
						border: 1px solid $PRIMARY_YELLOW;
					}
				}
			}
		}
		.Popup__textarea-field {
			.flex {
				justify-content: space-between;
				margin-bottom: 8px;
				p:last-child {
					float: right;
					color: #999;
				}
			}
			textarea {
				width: 100%;
				border: 1px solid #ccc;
				border-radius: 5px;
				box-sizing: border-box;
				font-size: 0.875rem;
				padding: 10px;
				resize: none;
				transition: 0.5s all ease;
				&:hover {
					border: 1px solid #000;
				}
				&:focus {
					border: 1px solid #000;
				}
			}
		}
	}
}
</style>
