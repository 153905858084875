const toastTime = 4000
const Toast = {
	namespaced: true,
	state: () => ({
		_messages: [],
	}),
	getters: {
		getMessages: state => state._messages,
	},
	mutations: {
		addToast: (state, payload) => {
			const { message, type, toastType } = payload
			if (type) {
				message.type = type
			}
			message.toastId = String(new Date().getUTCMilliseconds() * Math.random())
			message.toastType = toastType
			state._messages = [message, ...state._messages]
		},
		removeToast: state => {
			state._messages.pop()
		},
		closeToast: (state, toastId) => {
			state._messages = state._messages.filter(obj => obj.toastId != toastId)
		},
	},
	actions: {
		showToast: ({ commit }, payload) => {
			commit('addToast', payload)
			setTimeout(() => commit('removeToast'), toastTime)
		},
	},
}

export default Toast
