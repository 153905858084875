import csAPI from '@/services/api/cs'

const cs = {
	namespaced: true,
	state: {
		params: {
			offset: 0,
			length: 5,
			keyword: '',
			orderStartDatetime: '',
			orderEndDatetime: '',
		},
		csList: [],
		count: 0,
	},
	getters: {
		currentPage: state => {
			return (state.params.offset + state.params.length) / state.params.length
		},
		keyword: state => state.params.keyword,
		hasNextPage: state => {
			return state.params.length < state.count && state.params.offset + state.params.length < state.count
		},
	},
	mutations: {
		setParamsText: (state, { keyword, orderStartDatetime, orderEndDatetime }) => {
			state.params.keyword = keyword
			state.params.orderStartDatetime = orderStartDatetime
			state.params.orderEndDatetime = orderEndDatetime
		},
		setParamsPaging: (state, { offset, length }) => {
			state.params.offset = offset
			state.params.length = length
		},
		setCsList: (state, payload) => {
			state.csList = payload
		},
		setCount: (state, payload) => {
			state.count = payload
		},
	},
	actions: {
		lookupCsList: async ({ commit }, { instituteId, params }) => {
			const response = await csAPI.lookupCsList(instituteId, params)
			commit('setCsList', response.data.list)
			commit('setCount', response.data.count)
		},
	},
}

export default cs
