<template>
	<div>
		<base-management title="비밀번호 변경">
			<div class="change-password-wrapper">
				<template v-if="step === 1">
					<input-form-item
						ref="current"
						v-model="currentPassword"
						type="password"
						title="현재 비밀번호"
						placeholder="현재 비밀번호를 입력해 주세요"
						:error="currentPasswordError"
						:errorMessage="currentPasswordErrorMsg"
						required
						autofocus
						@keyup.enter="onClickConfirm"
					/>
				</template>
				<template v-if="step === 2">
					<input-form-item
						ref="new"
						v-model="newPassword"
						type="password"
						title="새 비밀번호"
						placeholder="변경할 비밀번호를 입력해 주세요"
						:error="newPasswordError"
						:errorMessage="newPasswordErrorMsg"
						required
						autofocus
						@keyup.enter="onClickConfirm"
					/>
					<input-form-item
						ref="confirm"
						v-model="confirmPassword"
						type="password"
						title="새 비밀번호 확인"
						placeholder="변경할 비밀번호를 한번 더 입력해 주세요"
						:error="newPasswordError"
						required
						@keyup.enter="onClickConfirm"
					/>
				</template>
			</div>
			<div class="change-password-footer">
				<button class="btn btn-gray lg" @click="$router.go(-1)">취소</button>
				<button class="btn btn-yellow lg" @click="onClickConfirm">확인</button>
			</div>
		</base-management>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import InputFormItem from '@/components/form-item/InputFormItem.vue'
import { mapActions } from 'vuex'

export default {
	name: 'ChangePassword',
	components: {
		BaseManagement,
		InputFormItem,
	},
	data() {
		return {
			step: 1,
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
			currentPasswordError: false,
			currentPasswordErrorMsg: '올바르지 않은 비밀번호입니다',
			newPasswordError: false,
			newPasswordErrorMsg: '새 비밀번호와 확인 비밀번호가 일치하지 않습니다',
		}
	},
	methods: {
		...mapActions('user', ['checkPassword', 'changePassword']),
		async onClickConfirm() {
			if (this.step === 1) {
				if (!this.currentPassword) {
					this.currentPasswordError = true
					this.currentPasswordErrorMsg = '비밀번호를 입력해주세요'
					return
				}
				if (!this.$refs.current.validate()) {
					this.currentPasswordError = true
					this.currentPasswordErrorMsg = '올바르지 않은 비밀번호입니다'
					return
				}

				if (this.currentPassword.length < 4 || this.currentPassword.match(/\s/g)) {
					this.currentPasswordError = true
					this.currentPasswordErrorMsg = '올바르지 않은 비밀번호입니다'
				} else {
					const response = await this.checkPassword({
						password: this.currentPassword,
					})

					if (response.isSamePassword) {
						this.step++
					} else {
						this.currentPasswordError = true
						this.currentPasswordErrorMsg = '현재 비밀번호를 확인해 주세요'
					}
				}
			} else if (this.step === 2) {
				const isValidNew = this.$refs.new.validate()
				const isValidConfirm = this.$refs.confirm.validate()
				if (!this.newPassword || !this.confirmPassword) {
					this.newPasswordError = true
					this.newPasswordErrorMsg = '새 비밀번호와 확인비밀번호를 입력해 주세요'
					return
				}
				if (isValidNew || isValidConfirm) {
					if (this.newPassword !== this.confirmPassword) {
						this.newPasswordError = true
						this.newPasswordErrorMsg = '새 비밀번호와 확인 비밀번호가 일치하지 않습니다'
						return
					} else {
						this.newPasswordError = false

						try {
							await this.changePassword({
								password: this.currentPassword,
								newPassword: this.newPassword,
							})

							this.$root.toast('비밀번호 변경이 완료되었습니다', '상품을 찜 목록에서 제외했습니다', 'success')
							this.$router.push({
								name: PageName.MyPage.ChangePasswordComplete,
							})
						} catch (e) {
							this.$root.toast('변경 실패', e.response.data.msg, 'error')
						}
					}
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.change-password-wrapper {
	border: 1px solid $COLOR_DDD;
	border-radius: 5px;
	padding: 20px;
}
.change-password-footer {
	display: flex;
	justify-content: center;
	gap: 24px;
	margin-top: 40px;
	.btn {
		width: 240px;
		height: 48px;
		&.btn-yellow {
			background: $PRIMARY_YELLOW;
		}
	}
}
</style>
