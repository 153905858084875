<template>
	<base-form-item :title="title" :required="required" :direction="direction" :description="description">
		<div class="checkbox-form-item-wrapper">
			<div class="checkbox-wrapper">
				<input
					:checked="value"
					type="checkbox"
					:id="content"
					:name="content"
					class="checkbox"
					@input="$emit('input', $event.target.checked)"
				/>
				<label :for="content">{{ content }}</label>
				<span>(선택)</span>
			</div>
			<div v-if="items && items.length > 0" class="checkbox-form-sub-item">
				<div v-for="(item, index) of items" :key="`sub-item-${index}`" class="checkbox-wrapper">
					<input v-model="item.value" type="checkbox" :id="item.name" :name="item.name" class="checkbox" @change="$emit('change', items)" />
					<label :for="item.name">{{ item.name }}</label>
				</div>
			</div>
		</div>
	</base-form-item>
</template>

<script>
import BaseFormItem from '@/components/form-item/BaseFormItem.vue'

export default {
	name: 'CheckboxFormItem',
	components: {
		BaseFormItem,
	},
	extends: BaseFormItem,
	props: {
		value: {
			type: [Boolean, Number],
			default: false,
		},
		content: {
			type: String,
			default: '',
		},
		items: {
			type: Array,
			default: () => [],
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.item-content {
		align-items: center;
	}
}
.checkbox-form-item-wrapper {
	display: flex;
	flex-direction: column;
	gap: 30px;
	.checkbox-wrapper {
		display: flex;
		align-items: center;
		gap: 11px;
		label {
			font-size: 0.875rem;
			font-weight: 500;
		}
		span {
			color: $COLOR_999;
		}
	}
	.checkbox-form-sub-item {
		display: flex;
		gap: 27px;
	}
}
</style>
