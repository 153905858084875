<template>
	<div class="sort-list">
		<h5>{{ title }}</h5>
		<div class="initial-sound-list">
			<div>
				<a
					v-for="(word, index) in englishInitialList"
					:key="`word-english-${index}`"
					:href="`#${word}`"
					@click="selectWord(word)"
					:class="{ active: word == activeWord }"
					>{{ word }}</a
				>
			</div>
			<div>
				<a
					v-for="(word, index) in notEnglishInitialList"
					:key="`word${index}`"
					:href="`#${word}`"
					@click="selectWord(word)"
					:class="{ active: word == activeWord }"
					>{{ word }}</a
				>
			</div>
		</div>
		<div class="list">
			<sort-item
				:id="`${index}`"
				v-for="(item, index) in list.map"
				:key="`item-key-${index}`"
				:title="index"
				:list="item"
				:activeWord="activeWord"
			></sort-item>
		</div>
	</div>
</template>

<script>
import SortItem from '@/components/item/SortItem.vue'

export default {
	name: 'SortList',
	props: {
		title: {
			type: String,
			default: '',
		},
		list: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		SortItem,
	},
	computed: {
		englishInitialList() {
			let pattern2 = /[a-zA-Z]/ //영어

			return this.list?.keys?.filter(word => pattern2.test(word))
		},
		notEnglishInitialList() {
			let pattern2 = /[a-zA-Z]/ //영어

			return this.list?.keys?.filter(word => !pattern2.test(word))
		},
	},
	data() {
		return {
			activeWord: null,
		}
	},
	methods: {
		selectWord(word) {
			this.activeWord = word
		},
	},
}
</script>

<style lang="scss" scoped>
.sort-list {
	.initial-sound-list {
		margin: 15px 0 5px;
		width: 100%;
		> div {
			margin-bottom: 10px;
		}
		a {
			display: inline-block;
			font-size: 2rem;
			font-weight: 400;
			color: $FONT_DISABLED;
			margin-right: 10px;
			&.active {
				color: black;
				font-weight: 700;
			}
		}
	}
	.list {
		width: 100%;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		.sort-item {
			width: calc(100% / 3);
			padding: 10px 0 40px 20px;
			&:nth-child(3n-2) {
				padding-left: 0;
			}
			&:nth-child(3n) {
				border-right: 0;
			}
		}
	}
}
</style>
