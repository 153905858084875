<template>
	<div class="payment-complete-order-info">
		<div class="banner-wrap" @click="$refs['modal-intro-pro'].show()">
			<img :src="OrderCompleteBannerImageObj.image" />
		</div>
		<div class="info-item">
			<div class="info-title">
				<div>주문정보</div>
			</div>
			<div class="info-content">
				<div>
					<div class="title">주문번호</div>
					<div>{{ order.orderNo }}</div>
				</div>
				<div>
					<div class="title">결제수단</div>
					<div>{{ paymentMethodName }}</div>
				</div>
				<div>
					<div class="title">상품 금액</div>
					<div>{{ CommaNum(order.totalOrderItemOriginPrice) }}원</div>
				</div>
				<div>
					<div class="title">할인 금액</div>
					<div class="red">{{ CommaNum(order.totalOrderItemDiscountPrice) }}원</div>
				</div>
				<div>
					<div class="title">결제 금액 합계</div>
					<div class="bold">{{ CommaNum(order.totalOrderItemPriceWithVat) }}원</div>
				</div>
			</div>
		</div>
		<div class="content">
			<ul>
				<template v-if="isPerson">
					<li>주문이 승인되면 결제가 완료됩니다.</li>
					<li>
						결제 관련 서류 (거래명세서, 스마트잭 사업자등록증, 스마트잭 통장사본, 매출전표)는 결제가 완료된 뒤 [결제내역]에서 언제든지
						출력할 수 있습니다.
					</li>
				</template>
				<template v-else>
					<li>주문이 승인되면 미결제상품에서 여러 상품 건을 모아 결제 주기에 맞춰 결제할 수 있습니다.</li>
					<li>
						결제 관련 서류 (견적서, 거래명세서, 스마트잭 사업자등록증, 스마트잭 통장사본, 전자세금계산서)는 결제가 완료된 뒤
						[결제내역]에서 언제든지 출력할 수 있습니다.
					</li>
				</template>
			</ul>
		</div>
		<ModalIntroPro isHideCheckBox :initPage="OrderCompleteBannerImageObj.id" ref="modal-intro-pro" />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import mixin from '@/mixins'
import { getSYSUrl } from '@/services/api/common'
import { OrderCompleteBannerImageObj } from '@/utils/Common'
import ModalIntroPro from '@/components/modal/ModalIntroPro.vue'

const INSTITUTE_TYPE = {
	PERSON: 'PERSON',
	UNIVERSITY: 'UNIVERSITY',
	ENTERPRISE: 'ENTERPRISE',
	SELLER: 'SELLER',
}

const PAYMENT_METHOD_TEXT = {
	CARD: '카드 간편결제',
	DIRECT_CARD: '일반결제',
	LATER: '후불결제',
}

export default {
	name: 'PaymentCompleteOrderInfo',
	mixins: [mixin],
	components: {
		ModalIntroPro,
	},
	data() {
		return {
			OrderCompleteBannerImageObj: OrderCompleteBannerImageObj,
		}
	},
	computed: {
		...mapState('order', ['order']),
		...mapState('user', ['institute']),
		isPerson() {
			if (this.$_.isEmpty(this.institute)) return
			return this.institute.type.code === INSTITUTE_TYPE.PERSON
		},
		paymentMethodName() {
			return PAYMENT_METHOD_TEXT[this.order.paymentMethod]
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-complete-order-info {
	display: flex;
	flex-direction: column;
	.info-item {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.info-title {
			display: flex;
			justify-content: space-between;

			div {
				font-weight: bold;
				font-size: 1.25rem;
			}
		}
		.info-content {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 20px;
			border: 1px solid $COLOR_DDD;
			border-radius: 26px;
			> div {
				display: flex;
				> div {
					font-size: 0.875rem;
					font-weight: 500;
				}
				.title {
					width: 124px;
				}
				.bold {
					font-weight: 700;
				}
				.red {
					color: #ff3b31;
				}
			}
		}
	}
	.banner-wrap {
		margin-bottom: 30px;
		cursor: pointer;
		img {
			width: 100%;
			border-radius: 26px;
		}
	}
	.content {
		margin-top: 20px;
		height: 80px;
		/* background-color: $LIST_BACKGROUND; */
		border-radius: 26px;
		display: flex;
		align-items: center;
		padding-left: 40px;
		border: 1px solid $COLOR_DDD;
		ul {
			li {
				list-style-type: disc;
				color: $COLOR_666;
				font-size: 14px;
				font-weight: 500;
			}
		}
	}
}
</style>
