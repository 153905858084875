import memberAPI from '@/services/api/member'

const members = {
	namespaced: true,
	state: {
		members: [],
		count: 0,
		invitationLink: '',
	},
	mutations: {
		setMembers: (state, payload) => {
			state.members = payload
		},
		setCount: (state, payload) => {
			state.count = payload
		},
		setInvitationLink: (state, payload) => {
			state.invitationLink = payload
		},
	},
	actions: {
		lookupMemberList: async ({ commit }, { instituteId, params }) => {
			const response = await memberAPI.lookupMemberList(instituteId, params)
			commit('setMembers', response.data.list)
			commit('setCount', response.data.count)
		},
		updateMember: async (_, { instituteId, body }) => {
			return await memberAPI.updateMember(instituteId, body)
		},
		lookupInvitationLink: async ({ commit }, instituteId) => {
			const response = await memberAPI.lookupInvitationLink(instituteId)
			commit('setInvitationLink', response.data.link)
		},
		inviteMember: async (_, { instituteId, body }) => {
			return await memberAPI.inviteMember(instituteId, body)
		},
		acceptInvitation: async (_, { body }) => {
			return await memberAPI.acceptInvitation(body)
		},
	},
}

export default members
