<template>
	<div>
		<base-management
			title="배송지 관리"
			subTitleName="배송지 목록"
			subTitleContent="배송지에 따라 주문가능한 상품이 달라질 수 있습니다."
			buttonText="+ 새 배송지 등록"
			@click="onClickAdd"
		>
			<shipping-address-list :list="addressList" @edit="onEditAddress" @delete="onDeleteAddress" />
		</base-management>

		<modal-shipping-address :title="modalTitle" :item="updateItem" @success="fetchAddressList" />
		<modal-cancel title="배송지 삭제" content="선택하신 배송지를 삭제하시겠습니까?" @ok="onDelete" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import cookies from 'vue-cookies'
import { ToastType } from '@/utils/define/ToastType'
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import ShippingAddressList from '@/views/my-page/my-info/shipping-address/ShippingAddressList.vue'
import ModalShippingAddress from '@/views/my-page/my-info/shipping-address/modal/ModalShippingAddress.vue'
import ModalCancel from '@/components/modal/ModalCancel'

const MODAL_TITLE = {
	ADD: '배송지 등록',
	UPDATE: '배송지 수정',
}

export default {
	name: 'ShippingAddressManagement',
	components: {
		BaseManagement,
		ShippingAddressList,
		ModalShippingAddress,
		ModalCancel,
	},
	data() {
		return {
			modalTitle: '',
			deleteId: 0,
			updateItem: {},
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('delivery', ['addressList']),
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchAddressList()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('delivery', ['lookupAddressList', 'deleteAddress']),
		async fetchAddressList(updateAddress) {
			await this.lookupAddressList(this.instituteId)

			let currentAddress = cookies.get('currentAddress2')
			if (updateAddress && currentAddress?.id === updateAddress?.id) {
				cookies.set('currentAddress2', updateAddress)
				this.$router.go()
			}
		},
		onClickAdd() {
			this.modalTitle = MODAL_TITLE.ADD
			this.updateItem = {}
			this.$bvModal.show('ModalShippingAddress')
		},
		onEditAddress(item) {
			this.modalTitle = MODAL_TITLE.UPDATE
			this.updateItem = this.$_.cloneDeep(item)
			this.$bvModal.show('ModalShippingAddress')
		},
		onDeleteAddress(id) {
			this.deleteId = id
			this.$bvModal.show('ModalCancel')
		},
		async onDelete() {
			try {
				await this.deleteAddress({
					instituteId: this.instituteId,
					id: this.deleteId,
				})

				this.$root.toast('배송지 삭제 성공', '해당 배송지를 삭제했습니다.', ToastType.SUCCESS)

				let currentAddress = cookies.get('currentAddress2')
				if (currentAddress?.id === this.deleteId) {
					cookies.remove('currentAddress2')
					this.$router.go()
				} else this.fetchAddressList()
			} catch (e) {
				this.$root.toast('배송지 삭제 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
