<template>
	<div class="payment-complete-info">
		<div class="content">
			<img src="@/assets/images/smile.png" />
			<p class="title">주문 완료!</p>
			<div class="info-wrapper">
				<p class="info">주문해주셔서 감사합니다.</p>
				<p class="info" v-if="isOrdererEmail">{{ order.orderer.email }}로 주문서류를 전송드렸어요.</p>
			</div>
			<div class="footer">
				<button class="btn btn-gray sm" @click="onClickOrderHistory">주문내역 보기</button>
				<button class="btn btn-gray sm" @click="onClickMain">스토어 홈으로</button>
				<button class="btn btn-yellow" @click="download" id="proButton">주문서류 다운로드</button>
			</div>
		</div>
		<modal-intro-pro ref="modal-intro-pro" />
		<!-- <ModalConfirm
			ref="ModalHideTooltip"
			title="랩매니저 Pro 팁 보지않기"
			content="랩매니저 Pro 관련 TIP을 숨기시겠습니까?"
			cancelText="닫기"
			confirmText="30일간 보지 않기"
			@confirm="hideTooltip"
		/> -->
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import { mapState, mapGetters, mapActions } from 'vuex'
import cookies from 'vue-cookies'
import { ProTooltipText } from '@/utils/Common'
import ModalIntroPro from '@/components/modal/ModalIntroPro.vue'
import ModalConfirm from '@/components/modal/ModalConfirm.vue'
import { isLogin, getSYSUrl } from '@/services/api/common'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'PaymentCompleteInfo',
	components: {
		ModalIntroPro,
		ModalConfirm,
	},
	computed: {
		...mapState('order', ['order']),
		...mapGetters('user', ['instituteId']),
		isOrdererEmail() {
			return this.order?.orderer?.email
		},
		proImage() {
			const list = [
				require('@/assets/images/complete/complete_01.png'),
				require('@/assets/images/complete/complete_02.png'),
				require('@/assets/images/complete/complete_03.png'),
				require('@/assets/images/complete/complete_04.png'),
			]
			return list[Math.floor(Math.random() * list.length)]
		},
	},
	data() {
		return {
			showTooltip: !cookies.get('isGoProTooltipHide'),
			proTooltipText: ProTooltipText,
		}
	},
	methods: {
		...mapActions('order', ['orderPrint']),
		onClickOrderHistory() {
			this.$router.push({
				name: PageName.MyPage.Order,
			})
		},
		onClickMain() {
			this.$router.push({
				name: PageName.Main,
			})
		},
		async download() {
			try {
				const response = await this.orderPrint({ instituteId: this.instituteId, body: { id: this.order.orderId } })
				window.open(response.url, '_blank')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		hideTooltip() {
			cookies.set('isGoProTooltipHide', true, '30d')
			this.showTooltip = false
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .tooltip-inner {
	background-color: #fff3d6 !important;
	color: #ff8a00;
	border: 1px solid #fec741;
	display: flex;
	gap: 5px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	/* & > img {
		width: 16px;
		height: 16px;
		cursor: pointer;
	} */
}

::v-deep .arrow::before {
	border-top-color: #fec741 !important;
}
::v-deep .arrow::after {
	border-top-color: #fff3d6 !important;
}

.payment-complete-info {
	display: flex;
	justify-content: space-between;
	border: 1px solid $COLOR_DDD;
	border-radius: 26px;
	padding: 40px;
	.content {
		display: flex;
		flex-direction: column;
		gap: 20px;
		justify-content: center;
		align-items: center;
		margin: auto;
		text-align: center;
		.title {
			font-size: 36px;
			font-weight: 700;
		}
		.info-wrapper {
			.info {
				font-size: 14px;
				font-weight: 400;
			}
		}
		.footer {
			display: flex;
			justify-content: flex-start;
			gap: 10px;
			margin-top: auto;
			.btn {
				font-size: 14px;
				width: 140px;
				height: 36px;
				border-radius: 10px;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #f2f3f4;
					color: black;
				}
			}
			.btn-yellow {
				background: #fec741;
				&:hover {
					background: #fec741 linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
					color: black;
				}
			}
		}
	}
	.icon {
		align-self: flex-end;
		width: 320px;
		height: 320px;
		img {
			width: 100%;
		}
	}
}
</style>
