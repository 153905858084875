import axiosInstance from '@/services/axios'

const endPoint = {
	lookupInstitute: instituteId => `institute/${instituteId}`,
	updateInstitute: instituteId => `institute/${instituteId}`,
	createInstitute: () => 'institute',
	lookupRequirement: instituteId => `institute/${instituteId}/requirement`,
}

const instituteAPI = {
	lookupInstitute: instituteId => axiosInstance.get(endPoint.lookupInstitute(instituteId)),
	createInstitute: body => axiosInstance.post(endPoint.createInstitute(), body),
	updateInstitute: (instituteId, body) => axiosInstance.patch(endPoint.updateInstitute(instituteId), body),
	lookupRequirement: instituteId => axiosInstance.get(endPoint.lookupRequirement(instituteId)),
}

export default instituteAPI
