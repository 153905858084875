<template>
	<div></div>
</template>

<script>
export default {
	name: 'PaymentInicisCallback',
	props: {
		result: {
			type: String,
			required: true,
		},
		orderId: {
			type: String,
			required: true,
		},
		errorMessage: {
			type: String,
			required: true,
		},
	},
	created() {
		opener.postMessage(
			eval(
				`({"type":"inicisCallback", "data": {"result": "${this.result}","orderId": "${this.orderId}","errorMessage": "${this.errorMessage}"}})`
			)
		)
		window.close()
	},
}
</script>

<style lang="scss" scoped></style>
