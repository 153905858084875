<template>
	<div class="Table__pagination">
		<ul class="pagination">
			<li class="first" @click="firstPage" :class="firstDisabled ? 'disabled' : ''">
				<img :src="`${pagenationArrow}`" />
				<img :src="`${pagenationArrow}`" />
			</li>
			<li class="prev" @click="prevPage" :class="prevDisabled ? 'disabled' : ''">
				<img :src="`${pagenationArrow}`" />
			</li>
			<li @click="currentPage(page)" v-for="page in pagination" :key="page" :class="page == current ? 'active' : ''">
				{{ page }}
			</li>
			<li class="next" @click="nextPage" :class="{ disabled: nextDisabled || maxPage == 0 }">
				<img :src="`${pagenationArrow}`" />
			</li>
			<li class="last" @click="lastPage" :class="{ disabled: lastDisabled || maxPage == 0 }">
				<img :src="`${pagenationArrow}`" />
				<img :src="`${pagenationArrow}`" />
			</li>
		</ul>
		<div class="Table__per-page" v-if="isPerPage">
			<p>
				{{ total }}개 중 {{ offset + 1 }} -
				{{ offset + length > total ? total : offset + length }}
			</p>
			<button
				class="btn btn-common Btn__select"
				:class="selectOption == true ? 'active' : ''"
				@blur="selectOption = false"
				@click="selectOption = !selectOption"
			>
				<span>{{ length }}개</span>
				<ul class="Form__select-option-list scroll">
					<li class="Form__select-option-item" v-for="item in pageOptionList" :key="item" @click.stop="selectPageOption(item)">
						{{ item }}개
					</li>
				</ul>
			</button>
		</div>
	</div>
</template>

<script>
import PagenationArrow from '@/assets/svg/pagination-arrow.svg'
export default {
	name: 'CommonPagination',
	components: { PagenationArrow },
	props: {
		total: {
			type: Number,
			default: 0,
		},
		isPerPage: {
			type: Boolean,
			default: true,
		},
		defaultLength: {
			type: Number,
			default: 50,
		},
	},
	data() {
		return {
			pagination: [],
			current: 1,
			totalPage: 0,
			selectOption: false,
			pageOptionList: [50, 100, 150, 200, 250, 300],
			offset: 0,
			length: 0,
			maxPage: 1,
			fixedCount: 5,
			firstDisabled: true,
			prevDisabled: true,
			nextDisabled: false,
			lastDisabled: false,
			pagenationArrow: PagenationArrow,
		}
	},
	created() {
		this.length = this.defaultLength
	},
	watch: {
		total: function () {
			this.current = 1
			this.counting()
		},
		current: function () {
			this.offset = (this.current - 1) * this.length
			if (this.current <= this.fixedCount) {
				this.firstDisabled = true
			} else {
				this.firstDisabled = false
			}
			if (this.current > 1) {
				this.prevDisabled = false
				this.firstDisabled = false
			} else {
				this.prevDisabled = true
				this.firstDisabled = true
			}
			if (this.current > Math.floor(this.totalPage / this.fixedCount) * this.fixedCount) {
				this.lastDisabled = true
			} else {
				this.lastDisabled = false
			}
			if (this.current == this.totalPage) {
				this.nextDisabled = true
				this.lastDisabled = true
			} else {
				this.nextDisabled = false
				this.lastDisabled = false
			}
			const page = {
				length: this.length,
				offset: this.offset,
			}
			this.$emit('page', page)
		},
		length: function () {
			this.counting()
		},
		totalPage: function () {
			if (this.totalPage == 1) {
				this.firstDisabled = true
				this.prevDisabled = true
				this.nextDisabled = true
				this.lastDisabled = true
				this.current = 1
			}
		},
	},
	methods: {
		counting() {
			// this.current = 1
			this.totalPage = Math.ceil(this.total / this.length)
			if (this.totalPage <= this.fixedCount) {
				this.maxPage = this.totalPage
			} else {
				this.maxPage = this.fixedCount
			}
			this.pagination = []
			if (this.maxPage > 0) {
				for (let i = 0; i < this.maxPage; i++) {
					this.pagination.push(i + 1)
				}
			} else {
				this.pagination = [1]
			}
			if (this.current > Math.floor(this.totalPage / this.fixedCount) * this.fixedCount) {
				this.lastDisabled = true
			} else {
				this.lastDisabled = false
			}
			if (this.current == this.totalPage) {
				this.nextDisabled = true
				this.lastDisabled = true
			} else {
				this.nextDisabled = false
				this.lastDisabled = false
			}
		},
		currentPage(page) {
			this.current = page
		},
		firstPage() {
			if (this.firstDisabled) return
			this.current = 1
			this.pagination = []
			const count = this.maxPage > this.fixedCount ? this.fixedCount : this.maxPage
			for (let i = 0; i < count; i++) {
				this.pagination.push(i + 1)
			}
		},
		prevPage() {
			if (this.prevDisabled) return
			this.current = this.current - 1
			if (this.current == 0) {
				this.current = 1
			}
			if (this.current % this.fixedCount == 0) {
				this.pagination = []
				this.maxPage = this.current
				for (let i = this.maxPage - this.fixedCount; i < this.maxPage; i++) {
					this.pagination.push(i + 1)
				}
			}
		},
		nextPage() {
			if (this.nextDisabled || this.maxPage == 0) return
			if (this.current != this.totalPage) {
				if (this.current % this.fixedCount == 0) {
					this.pagination = []
					this.current = this.current + 1

					if (this.maxPage + this.fixedCount < this.totalPage) {
						this.maxPage = this.maxPage + this.fixedCount
					} else {
						this.maxPage = this.totalPage
					}

					for (let i = this.current; i <= this.maxPage; i++) {
						this.pagination.push(i)
					}
				} else {
					this.current = this.current + 1
					if (this.current > this.totalPage) {
						this.current = this.totalPage
					}
				}
			}
		},
		lastPage() {
			if (this.lastDisabled || this.maxPage == 0) return
			this.current = this.totalPage
			this.pagination = []
			const totalCount = Math.floor(this.totalPage / this.fixedCount) * this.fixedCount
			if (this.current % 5 == 0) {
				for (let i = totalCount - 5; i < this.current; i++) {
					this.pagination.push(i + 1)
				}
			} else {
				for (let i = totalCount; i < this.current; i++) {
					this.pagination.push(i + 1)
				}
			}
		},
		selectMainOption() {
			if (this.selectOption) {
				this.selectOption = false
			} else {
				this.selectOption = true
			}
		},
		selectPageOption(item) {
			this.selectOption = false
			this.length = item
			this.offset = 0
			const page = {
				length: this.length,
				offset: 0,
			}
			this.$emit('page', page)
		},
		changeLength(length) {
			this.length = length
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_pagination.scss';

.Table__pagination {
	display: flex;
	justify-content: center;
	position: relative !important;
	.Table__per-page {
		.Btn__select {
			.Form__select-option-list {
			}
			&:focus {
				.Form__select-option-list {
					display: none;
				}
			}
			&.active {
				.Form__select-option-list {
					display: block;
					max-height: unset;
				}
			}
		}
	}
}
</style>
