import { render, staticRenderFns } from "./CategoryBox.vue?vue&type=template&id=407114b6&scoped=true&"
import script from "./CategoryBox.vue?vue&type=script&lang=js&"
export * from "./CategoryBox.vue?vue&type=script&lang=js&"
import style0 from "./CategoryBox.vue?vue&type=style&index=0&id=407114b6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "407114b6",
  null
  
)

export default component.exports