<template>
	<div class="payment-product-info-table">
		<div class="header">
			<div class="title">상품정보</div>
		</div>
		<div class="content">
			<table class="product-info-table">
				<thead>
					<tr>
						<th style="width: 650px"><span>상품 정보</span></th>
						<th style="width: 80px"><span>수량</span></th>
						<th style="width: 110px"><span>판매가</span></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(product, index) in products" :key="`row_${product.id}_${index}`">
						<td>
							<div class="info">
								<payment-product-info ref="payment-product-info" :index="index" :product="product"></payment-product-info>
								<div v-if="product.productInfoType == 'PURCHASE_AGENT'" class="type-badge">구매대행</div>
							</div>
						</td>
						<td>
							<div>
								{{ product.quantity }}
							</div>
						</td>
						<td>
							<div class="selling-price">{{ CommaNum(product.sellingUnitPrice) }}원</div>
							<div class="price" v-if="!checkSamePrice(product)">{{ CommaNum(product.unitPrice) }}원</div>
							<div>
								<span class="sale-percent" v-if="product.discountPercent">{{ product.discountPercent }}% 할인</span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<TotalPrice :productList="products" :orderTotal="orderTotal" />
		</div>
	</div>
</template>

<script>
import orderAPI from '@/services/api/order'
import PaymentProductInfo from '@/views/payment/components/PaymentProductInfo.vue'
import TotalPrice from './TotalPrice.vue'
import mixin from '@/mixins'
import { mapGetters } from 'vuex'

export default {
	name: 'ProductInfoTable',
	props: {
		products: {
			type: Array,
			default: () => [],
		},
		requestId: {
			type: Number,
			default: null,
		},
	},
	components: {
		PaymentProductInfo,
		TotalPrice,
	},
	computed: {
		...mapGetters('user', ['instituteId']),
	},
	data() {
		return {
			orderTotal: {
				totalPrice: 0,
				totalDiscountPrice: 0,
				totalVat: 0,
				totalSellingPriceWithVat: 0,
			},
		}
	},
	mixins: [mixin],
	created() {
		this.getTotalPrice()
	},
	mounted() {},
	methods: {
		checkSamePrice({ sellingUnitPrice, unitPrice }) {
			return sellingUnitPrice === unitPrice
		},
		async getTotalPrice() {
			if (this.products?.length > 0) {
				const { data } = await orderAPI.orderCalculate(this.instituteId, {
					requestId: this.requestId,
					list: this.products.map(p => ({
						productId: p.productId,
						quantity: p.quantity,
					})),
				})
				Object.assign(this.orderTotal, data)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-product-info-table {
	.header {
		.title {
			font-weight: bold;
			font-size: 1.25rem;
		}
	}
	.content {
		max-height: calc(60px + 750px + 90px);
		overflow-y: auto;
		margin-top: 10px;
		border: 1px solid $COLOR_DDD;
		border-radius: 5px;
		.product-info-table {
			width: 100%;
			thead {
				height: 60px;
				background: $LIST_BACKGROUND;
				border-radius: 5px;
				position: sticky;
				top: 0;
				z-index: 1;
				tr {
					th {
						background-color: $LIST_BACKGROUND;
						text-align: center;
						font-size: 0.875rem;
						&:first-child {
							padding-left: 20px;
							text-align: left;
						}
					}
				}
			}
			tbody {
				border-top: 1px solid $COLOR_DDD;
				border-bottom: 1px solid $COLOR_DDD;
				tr {
					height: 150px;
					&:not(:last-child) {
						border-bottom: 1px solid $COLOR_DDD;
					}
					td {
						.info {
							display: flex;
							align-items: center;
							justify-content: space-between;
							& > *:first-child {
								min-width: 90%;
							}
							& > * {
								flex-grow: 0;
							}
							.type-badge {
								font-size: 12px;
								color: #ff8a00;
								background-color: #fff3d6;
								border-radius: 5px;
								padding: 2px 5px;
								word-break: keep-all;
							}
						}
						&:first-child div {
							padding: 10px;
						}
						&:not(:first-child) div {
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.selling-price {
							font-weight: 700;
						}
						.price {
							text-decoration: line-through;
							font-size: 0.875rem;
							color: $FONT_PLACEHOLDER;
						}
						.sale-percent {
							margin-top: 4px;
							font-size: 12px;
							font-weight: 700;
							padding: 2px 6px;
							border-radius: 4px;
							background-color: #ff3b31;
							color: white;
						}
					}
					td + td {
						border-left: 1px solid $COLOR_DDD;
					}
				}
			}
		}
		.cart-product-total-price {
			position: sticky;
			bottom: 0;
		}
	}
}
</style>
