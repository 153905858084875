<template>
	<div class="return-info">
		<div class="info-item">
			<div class="info-title">
				<span>상세정보</span>
			</div>
			<div class="info-content">
				<div v-for="(info, index) of detailInfo" :key="index" :class="{ 'division-line': info.border }">
					<span class="title">{{ info.title }}</span>
					<span class="content">{{ info.content }}</span>
				</div>
			</div>
		</div>
		<div class="info-item">
			<div class="sub-title">환불 정보는 고객센터로 문의주세요</div>
			<div class="service-center-info">
				<div class="image-wrapper">
					<img src="@/assets/svg/icon-cs.svg" />
				</div>
				<div class="info-wrapper">
					<div class="title">랩매니저 스토어 고객센터</div>
					<div class="tel">1533-3061</div>
					<div class="working-time">상담시간 평일 10:00 ~ 17:00<br />Off-time 12:00 ~ 13:00 (토/일/공휴일 휴무)</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import { ITEM_STATUS_TYPE } from '@/utils/define/order'

export default {
	name: 'ReturnInfo',
	mixins: [mixin],
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
		orderer: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		detailInfo() {
			let info = []
			switch (this.$route.query.status) {
				case ITEM_STATUS_TYPE.WAIT_CANCEL: // 취소 접수
				case ITEM_STATUS_TYPE.CANCEL: // 취소 완료
					if (this.item.cancelRequestDatetime)
						info.push({ title: '취소 접수 일시', content: this.isData(this.formatDateTime(this.item.cancelRequestDatetime, true)) })
					return [
						...info,
						{ title: '취소 완료 일시', content: this.isData(this.formatDateTime(this.item.cancelDatetime, true)) },
						{ title: '취소 사유', content: this.isData(this.item.cancelReason) },
						{
							title: '취소 상세 사유',
							content: this.isData(this.item.cancelReasonDetail),
						},
					]
				case ITEM_STATUS_TYPE.REJECT: // 취소 거절
					if (this.item.cancelRequestDatetime)
						info.push({ title: '취소 접수 일시', content: this.isData(this.formatDateTime(this.item.cancelRequestDatetime, true)) })
					return [
						...info,
						{ title: '취소 거절 일시', content: this.isData(this.formatDateTime(this.item.cancelRequestRejectDatetime, true)) },
						{ title: '취소 사유', content: this.isData(this.item.cancelReason) },
						{
							title: '취소 거절 사유',
							content: this.isData(this.item.cancelRequestRejectReason),
							border: true,
						},
					]
				case ITEM_STATUS_TYPE.RETURN_RECEIPT: // 교환/반품 접수
				case ITEM_STATUS_TYPE.RETURN_COMPLETE: // 교환/반품 완료
					if (this.item.returnRequestDatetime)
						info.push({
							title: '교환 / 반품 접수 일시',
							content: this.isData(this.formatDateTime(this.item.returnRequestDatetime, true)),
						})
					return [
						...info,
						{ title: '교환 / 반품 완료 일시', content: this.isData(this.formatDateTime(this.item.returnDatetime, true)) },
						{
							title: '문의 내용',
							content: this.isData(this.item.returnRequestReason),
						},
					]
				case ITEM_STATUS_TYPE.RETURN_REJECT: // 교환/반품 거절
					if (this.item.returnRequestDatetime)
						info.push({
							title: '교환 / 반품 접수 일시',
							content: this.isData(this.formatDateTime(this.item.returnRequestDatetime, true)),
						})
					return [
						...info,
						{ title: '교환 / 반품 거절일', content: this.isData(this.formatDateTime(this.item.returnRejectDatetime, true)) },
						{
							title: '문의 내용',
							content: this.isData(this.item.returnRequestReason),
						},
						{
							title: '교환 / 반품 거절 사유',
							content: this.isData(this.item.returnResponse),
							border: true,
						},
					]
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.return-info {
	display: flex;
	flex-direction: column;
	gap: 64px;
	.info-item {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.info-title {
			display: flex;
			justify-content: space-between;
			span {
				font-weight: bold;
				font-size: 1.25rem;
			}
			button {
				display: flex;
				align-items: center;
				font-weight: 700;
				font-size: 0.75rem;
			}
		}
		.info-content {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 20px;
			border: 1px solid $COLOR_DDD;
			border-radius: 5px;
			> div {
				display: flex;
				gap: 24px;
				> span {
					font-size: 0.875rem;
					font-weight: 500;
				}
				.title {
					width: 110px;
				}
				.content {
					width: 100%;
				}
				&.division-line {
					border-top: 1px solid $COLOR_EEE !important;
					padding-top: 16px;
				}
			}
		}
		.sub-title {
			font-size: 0.875rem;
			font-weight: 500;
		}
		.service-center-info {
			display: flex;
			gap: 24px;
			align-items: center;
			padding: 20px;
			background: $PRIMARY_WHITE;
			border: 1px solid $COLOR_EEE;
			border-radius: 5px;
			.image-wrapper {
				width: 100px;
				height: 100px;
			}
			.info-wrapper {
				display: flex;
				flex-direction: column;
				gap: 8px;
				.title {
					font-weight: 500;
					font-size: 0.875rem;
				}
				.tel {
					font-weight: 700;
					font-size: 1.5rem;
				}
				.working-time {
					font-weight: 500;
					font-size: 0.75rem;
					color: $COLOR_999;
				}
			}
		}
	}
}
</style>
