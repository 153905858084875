import axios from 'axios'
import qs from 'qs'

const axiosInstance = axios.create({
	// baseURL: process.env.VUE_APP_BASE_URL,
	baseURL: "https://dev-data-science.lab-manager.com/",
	timeout: 100000,
})

// 각 요청에서 config로 처리
// axiosInstance.defaults.headers.common["Content-Type"] = "application/vnd.kafka.json.v2+json"
// axiosInstance.defaults.headers.common["Accept"] = "application/vnd.kafka.v2+json"

axiosInstance.defaults.paramsSerializer = params => {
	return qs.stringify(params, { encode: false, arrayFormat: 'brackets' })
}

export default axiosInstance
