import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/modules/ParamMaker'

const endPoint = {
	lookupNoticeList: paramsObject => `notice/list?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupNotice: id => `notice?noticeId=${id}`,
}

const noticeAPI = {
	lookupNoticeList: paramsObject => axiosInstance.get(endPoint.lookupNoticeList(paramsObject)),
	lookupNotice: id => axiosInstance.get(endPoint.lookupNotice(id)),
}

export default noticeAPI
