<template>
	<b-modal ref="modal" centered no-close-on-esc no-close-on-backdrop>
		<template #modal-title>
			<div class="flex"><img src="@/assets/svg/icon-npay-m.svg" /> 출금</div>
		</template>
		<div class="content">
			<div class="total-point">
				<img src="@/assets/svg/icon-npay-m.svg" />로 출금 가능한 적립금: <span class="error-text">{{ point.toLocaleString() }}원</span>
			</div>
			<div class="explain">Npay 전환은 아래와 같은 단위로 가능합니다. (1,000원 / 5,000원 / 10,000원 / 15,000원...)</div>
			<div class="input-form">
				<input-form-item
					required
					v-model="info.phone"
					:error="error.phone"
					type="tel"
					direction="column"
					title="연락처"
					placeholder="연락처 입력"
					:errorMessage="errorMsg.phone"
				/>
				<input-form-item
					required
					v-model="info.point"
					type="number"
					direction="column"
					title="출금할 금액"
					placeholder="금액 입력"
					:error="error.point"
					:errorMessage="errorMsg.point"
				/>
			</div>
		</div>
		<template #modal-footer>
			<button class="btn-primary" @click="change">출금하기</button> <button class="btn-white" @click="hide">취소</button>
		</template>
	</b-modal>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Validation from '../../../utils/Validation'
import InputFormItem from '@/components/form-item/InputFormItem.vue'
export default {
	components: {
		InputFormItem,
	},
	data() {
		return {
			info: {
				phone: '',
				point: '',
			},
			error: {
				phone: false,
				point: false,
			},
			errorMsg: {
				phone: '연락처를 확인해주세요.',
				point: '금액을 입력해주세요.',
			},
		}
	},
	watch: {
		'info.phone'(val) {
			this.info.phone = Validation.autoHypenTel(val)
		},
	},
	computed: {
		...mapState('user', ['user', 'point']),
	},
	methods: {
		...mapActions('user', ['usePoint']),
		show() {
			this.info.phone = this.user.phone ?? ''
			this.info.point = ''
			this.error = {
				phone: false,
				point: false,
			}
			this.errorMsg = {
				phone: '연락처를 확인해주세요.',
				point: '금액을 입력해주세요.',
			}
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		async change() {
			this.error = {
				phone: false,
				point: false,
			}
			if (!Validation.validateTel(this.info.phone)) {
				this.errorMsg.phone = '연락처를 확인해주세요.'
				this.error.phone = true
			}
			if (this.info.point % 5000 != 0 && this.info.point != 1000) {
				this.errorMsg.point = 'Npay 전환은 아래와 같은 단위로 가능합니다. (1,000원 / 5,000원 / 10,000원 / 15,000원...)'
				this.error.point = true
			}
			if (this.info.point <= 0) {
				this.errorMsg.point = '금액을 입력해 주세요.'
				this.error.point = true
			}
			if (this.info.point > this.point) {
				this.errorMsg.point = '출금 가능한 적립금이 부족합니다.'
				this.error.point = true
			}
			if (this.error.phone || this.error.point) return
			try {
				await this.usePoint(this.info)
				this.$root.toast('N페이 출금 신청 완료', '정상적으로 접수되었습니다.', 'success')
				this.hide()
			} catch (e) {
				this.$root.toast('N페이 출금 신청 실패', e.response?.data?.msg ?? '고객센터로 문의해주세요', 'error')
			}
		},
	},
}
</script>
<style scoped lang="scss">
::v-deep {
	.modal-title {
		display: flex;
		align-items: center;
		gap: 20px;
		.flex {
			align-items: center;
			gap: 10px;
		}
		.explain {
			font-size: 14px;
			color: #666;
			font-weight: normal;
		}
	}
	.modal-header {
		border-bottom: 1px solid #eee !important;
	}
	.modal-dialog {
		width: 600px;
		max-width: 600px;
	}
	.modal-body {
		padding: 20px;
	}
	.content {
		display: flex;
		flex-direction: column;
		gap: 20px;
		.total-point {
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 16px;
			font-weight: 700;
		}
		.explain {
			font-size: 14px;
		}
	}
	.modal-footer {
		padding: 20px;
		justify-content: space-between;
		gap: 10px;
		button {
			border-radius: 5px;
			height: 48px;
			margin: 0px;
			width: calc(50% - 10px);
			color: black;
			font-weight: 700;
			&.btn-primary {
				background-color: #fec741;
			}
		}
	}
	.item-wrapper {
		padding: 0px;
	}
	.input-form {
		display: flex;
		flex-direction: column;
		gap: 10px;
		input {
			width: 558px !important;
		}
	}
}
</style>
