<template>
	<div class="my-page-wrapper">
		<my-page-header />
		<div class="banner-wrap" @click="$refs['modal-intro-pro'].show()">
			<img :src="MypageBannerImageObj.image" />
		</div>
		<main class="my-page-content">
			<my-page-nav @active="onActive" />
			<section class="my-page-section">
				<router-view />
			</section>
		</main>
		<ModalIntroPro isHideCheckBox :initPage="MypageBannerImageObj.id" ref="modal-intro-pro" />
	</div>
</template>

<script>
import { isLogin } from '@/services/api/common'
import PageName from '@/utils/define/PageName'
import MyPageHeader from '@/views/my-page/components/MyPageHeader2.vue'
import MyPageNav from '@/views/my-page/components/MyPageNav.vue'
import { MypageBannerImageObj } from '@/utils/Common'
import ModalIntroPro from '@/components/modal/ModalIntroPro.vue'

export default {
	name: 'MyPage',
	components: {
		MyPageHeader,
		MyPageNav,
		ModalIntroPro,
	},
	mounted() {
		this.$nextTick(() => {
			if (!isLogin()) this.goMain()
		})
	},
	data() {
		return {
			MypageBannerImageObj: MypageBannerImageObj,
		}
	},
	methods: {
		onActive(menu) {
			this.$router
				.push({
					name: menu.component,
				})
				.catch(() => {})
		},
		goMain() {
			this.$router
				.push({
					name: PageName.Main,
				})
				.catch(() => {})
		},
	},
}
</script>

<style lang="scss" scoped>
.my-page-wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin: auto;
	width: 1080px;
	height: 100%;
	.banner-wrap {
		cursor: pointer;
		margin-bottom: 20px;
		img {
			width: 100%;
			border-radius: 16px;
		}
	}
	.my-page-content {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		gap: 24px;
		.my-page-section {
			width: 896px;
		}
	}
}
</style>
