<template>
	<div class="box-list-wrapper">
		<main-box-item v-for="(item, index) in list" :key="`box_${index}`" :item="item" :type="type" />
	</div>
</template>

<script>
import MainBoxItem from '@/views/main/components/MainBoxItem.vue'

export default {
	name: 'BoxList',
	components: {
		MainBoxItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		type: {
			type: String,
			default: '',
		},
	},
}
</script>

<style lang="scss" scoped>
.box-list-wrapper {
	margin-top: 15px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	row-gap: 10px;
	img {
		max-width: 100%;
	}
}
</style>
