<template>
	<b-modal
		id="ModalDeleteRequiredProduct"
		class="modal"
		title="주문이 필요한 상품 삭제"
		ok-title="확인"
		cancel-title="취소"
		cancel-variant="default"
		ok-variant="primary"
		ref="modal"
		size="sm"
		centered
		hide-header-close
		@ok="$emit('ok')"
	>
		<div class="msg-wrapper">
			<p>삭제된 상품은 다시 볼 수 없습니다.</p>
			<p>주문이 필요한 상품을 삭제 하시겠습니까?</p>
		</div>
	</b-modal>
</template>
<script>
export default {
	name: 'ModalDeleteRequiredProduct',
	props: {},
}
</script>

<style lang="scss" scoped>
.msg-wrapper {
	margin-top: -15px;
	text-align: center;
	p {
		font-size: 0.875rem;
		font-weight: 400;
	}
}
</style>
