import throttle from 'lodash/throttle'
import categoryList from '@/utils/define/Category'
import PageName from '@/utils/define/PageName'
import { mapActions, mapState } from 'vuex'
import { RoutePath } from '@/utils/define/RoutePath'

const CATEGORY = {
	brand: '브랜드',
	seller: '판매자',
	productInfo: '상품',
	catalog: '상품목록',
}

export default {
	data() {
		return {
			loadingEvent: null,
			activeCategoryCode: '',
		}
	},
	computed: {
		...mapState('main', ['isPro']),
	},
	methods: {
		...mapActions('toast', ['showToast']),
		...mapActions('wish', ['updateWish']),
		//숫자에 comma 추가
		CommaNum(num) {
			return (num ?? 0).toLocaleString('ko-KR')
		},
		roundNum(num) {
			if (typeof num == 'number') {
				return Math.round(num)
			} else {
				return num
			}
		},
		hide(event) {
			this.hideCategoryMenu(event)
			this.hideTooltip(event)
			this.hideMyMenu(event)
			this.hideSearchRecent(event)
		},
		hideSearchRecent(event) {
			if (event.target.className !== 'searchbox-keyword') this.$refs['app-header'].hideSearchRecent()
		},
		//hide header -> category menu
		hideCategoryMenu(event) {
			let list = []
			let target = event.target
			let button = document.querySelectorAll('.all-category *')

			for (let j = 0; j < button.length; j++) {
				if (button[j] == target) {
					return
				}
			}

			if (document.querySelector('.category-menu.active')) {
				list = document.querySelectorAll('.category-menu *')
				for (let i = 0; i < list.length; i++) {
					if (list[i] == target) {
						return
					}
				}
				document.querySelector('.category-menu').classList.remove('active')
				document.querySelector('.all-category').classList.remove('active')
			}
		},
		//hide home product tooltip
		hideTooltip(event) {
			let list = []
			let target = event.target

			if (document.querySelector('.items')) {
				list = event.currentTarget.querySelectorAll('.items *')
				for (let i = 0; i < list.length; i++) {
					if (list[i] == target) {
						return
					}
				}

				let activeList = document.querySelectorAll('.product-tooltip.active')
				for (let j = 0; j < activeList.length; j++) {
					activeList[j].style.display = 'none'
				}
			}
		},
		hideMyMenu(event) {
			let target = event.target
			let button = document.querySelector('.user-info-btn')
			if (button !== target.closest('.user-info-btn') && document.querySelector('.floating-menu.active')) {
				document.querySelector('.floating-menu').classList.remove('active')
			}
		},
		goSearch(word) {
			this.$router.push({
				name: PageName.Search,
				params: {
					category: this.$route.params.category ? this.$route.params.category : 'list',
				},
				query: {
					keyword: word,
				},
			})
		},
		//상품 상세 정보 페이지로 이동
		goProductInfoPage(id) {
			this.$router.push({
				name: PageName.Detail,
				params: {
					productInfoId: id,
				},
			})
		},
		//한글 받침 유무 검사 - 이/가
		checkBatchimEnding(word) {
			if (typeof word !== 'string') return null

			let lastLetter = word[word.length - 1]
			let uni = lastLetter.charCodeAt(0)

			if (uni < 44032 || uni > 55203) return null

			return (uni - 44032) % 28 != 0 ? '이' : '가'
		},
		checkConsonantEnding(word) {
			if (typeof word !== 'string') return null

			let lastLetter = word[word.length - 1]
			let uni = lastLetter.charCodeAt(0)

			if (uni < 44032 || uni > 55203) return null

			return (uni - 44032) % 28 != 0 ? '을' : '를'
		},
		formatDate(date) {
			if (date) {
				var d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear()
				if (month.length < 2) {
					month = '0' + month
				}
				if (day.length < 2) {
					day = '0' + day
				}
				return [year, month, day].join('-')
			} else {
				return null
			}
		},
		formatDateTime(date, isSecond) {
			if (date) {
				var d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear(),
					hour = d.getHours(),
					minute = d.getMinutes(),
					second = d.getSeconds()
				if (month.length < 2) {
					month = '0' + month
				}
				if (day.length < 2) {
					day = '0' + day
				}
				if (minute.toString().length < 2) {
					minute = '0' + minute
				}
				return [year, month, day].join('-') + ` ${hour}:${minute}${isSecond ? `:${second}` : ''}`
			} else {
				return null
			}
		},
		async changeWish(id, isWish, mode) {
			let isAction = isWish ? 'subscribe' : 'unsubscribe'
			const params = {
				[mode.concat('Id')]: id,
				action: isAction,
			}
			await this.updateWish({ type: mode, body: params })

			if (isWish == false) {
				let title = '찜 해제 완료'
				let word = mode === 'productInfo' || mode === 'catalog' ? '을' : '를'
				let content = `${CATEGORY[mode]}${word} 찜 목록에 상품을 제외했습니다.`
				this.$root.toast(title, content, 'success')
				this.$emit('changeWishValue')
			} else {
				let title = '찜 완료'
				let word = mode === 'productInfo' || mode === 'catalog' ? '을' : '를'
				let content = `${CATEGORY[mode]}${word} 찜 목록에 상품을 담았습니다.`

				this.$root.toast(title, content, 'success')
			}
		},
		setToast() {
			this.$root.toast = (title, content, type) => {
				const payload = {
					type: type,
					toastType: 'normal',
					message: {
						title: title,
						content: content,
					},
				}
				this.showToast(payload)
			}
			this.$root.darkToast = (content, clickText, clickFunc) => {
				const payload = {
					toastType: 'dark',
					message: {
						content: content,
						clickText: clickText,
						clickFunc: clickFunc,
					},
				}
				this.showToast(payload)
			}
		},
		routePath(link) {
			let path = this.$route.path

			return path.indexOf(link) >= 0
		},
		//match category name
		matchClassificationToString(classification) {
			let name = null

			categoryList.classification.forEach(item => {
				if (item.classification == classification) {
					name = item.name
				}
			})

			return name
		},
		matchCategoryToString(category) {
			let name = null

			categoryList.detailCategory.forEach(item => {
				if (item.category == category) {
					name = item.name
				}
			})

			return name
		},
		isData(data) {
			if (data) {
				return data
			} else {
				return '-'
			}
		},
		windowOpen({ PageName, RoutePath, params, query }) {
			if (this.isPro) {
				this.$router.push({
					name: PageName,
					params: params,
					query: query,
				})
			} else {
				for (let key in params) {
					RoutePath = RoutePath.replace(`:${key}`, params[key])
				}

				let queryStrings = []
				for (let key in query) {
					queryStrings.push(`${key}=${query[key]}`)
				}

				let queryString = ''
				if (queryStrings.length) {
					queryString = `?${queryStrings.join('&')}`
				}

				window.open(`${window.origin}${RoutePath}${queryString}`, '_blank')
			}
		},
	},
}
