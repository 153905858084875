<template>
	<div class="sort-item" v-if="list.length > 0">
		<div>
			<h2 :class="{ active: title == activeWord }">{{ title }}</h2>
			<ul class="list">
				<li v-for="(item, index) in list" :key="`item_${index}`">
					<p @click="goPage(item.id)">
						{{ item.name }}
						<span>({{ item.productInfoCount ? CommaNum(item.productInfoCount) : 0 }})</span>
					</p>
					<input class="wish2" type="checkbox" v-model="item.isWish" @change="changeWish(item.id, item.isWish, $route.params.category)" />
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import PageName from '@/utils/define/PageName'

export default {
	name: 'SortItem',
	mixins: [mixin],
	props: {
		title: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
		activeWord: {
			type: String,
			default: '',
		},
	},
	data() {
		return {}
	},
	methods: {
		goPage(id) {
			this.$router.push({
				name: PageName.Search,
				params: {
					category: this.$route.params.category,
				},
				query: {
					id: id,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.sort-item {
	width: calc(100% / 3);
	padding: 0;
	border-right: 1px solid $LINE_DIVIDER;
	margin: 0;
	display: inline-block;
	float: left;
	&:after {
		display: block;
		clear: both;
		content: '';
	}
	h2 {
		display: block;
		width: 100%;
		font-size: 2rem;
		font-weight: 700;
		&.active {
			color: $PRIMARY_YELLOW;
		}
	}
	ul {
		width: 100%;
		li {
			width: 100%;
			display: flex;
			align-items: center;
			margin-top: 20px;
			cursor: pointer;
			input[type='checkbox'] {
				margin-left: auto;
				margin-right: 32px;
			}
		}
	}
}
</style>
