<template>
	<div class="select-form" @wheel="showOption = false" @mouseleave="showOption = false">
		<div class="title" v-if="title">
			<h6>{{ title }}</h6>
			<span class="required" v-if="required">*</span>
		</div>
		<input class="select-box" type="text" readonly v-model="selected.name" :placeholder="placeholder" @click="showOption = true" />
		<ul class="option-box" v-if="showOption" :class="{ 'no-title': !title }">
			<li v-for="(option, index) in options" :key="`option_${index}`" @click="selectOption(option)">
				{{ option.name }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'SelectForm',
	props: {
		title: {
			type: String,
			default: '',
		},
		options: {
			type: Array,
			default: () => [],
		},
		required: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: '',
		},
		selectId: {
			type: [String, Number],
			default: '',
		},
	},
	data() {
		return {
			showOption: false,
			selected: {},
		}
	},
	watch: {
		selectId() {
			this.options.forEach(option => {
				if (option.id == this.selectId) {
					this.selected = option
				}
			})
		},
	},
	methods: {
		selectOption(option) {
			this.selected = option
			this.$emit('change', option)
			this.showOption = false
		},
	},
}
</script>

<style lang="scss" scoped>
.select-form {
	width: 100%;
	position: relative;
	.title {
		display: inline-flex;
		align-items: center;
		margin-bottom: 1rem;
		h6 {
			font-size: 1rem;
			font-weight: 700;
		}
	}
	.select-box {
		width: 100%;
		height: 40px;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 5px;
		padding: 0 1rem;
		background-image: url(~@/assets/svg/arrow/select-arrow.svg);
		background-position: center right 1rem;
		font-size: 0.875rem;
		font-weight: normal;
	}
	.option-box {
		position: absolute;
		width: 100%;
		top: 82px;
		left: 0;
		padding: 5px;
		background-color: white;
		border-radius: 5px;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
		z-index: 1;
		&.no-title {
			top: 42px;
		}
		li {
			display: flex;
			align-items: center;
			padding: 0 10px;
			height: 30px;
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 0.875rem;
			font-weight: 400;
			transition: all 0.3s;
			border-radius: 5px;
			cursor: pointer;
			&:hover {
				background-color: #f2f2f2;
			}
		}
	}
}
</style>
