import popupAPI from '@/services/api/popup'

const popup = {
	namespaced: true,
	state: {
		popupList: [],
	},
	mutations: {
		setPopupList: (state, payload) => {
			state.popupList = payload
		},
	},
	actions: {
		lookupPopupList: async ({ commit }, params) => {
			const response = await popupAPI.lookupPopupList(params)
			commit('setPopupList', response.data)
		},
	},
}

export default popup
