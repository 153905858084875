import orderAPI from '@/services/api/order'

const orders = {
	namespaced: true,
	state: {
		params: {
			offset: 0,
			length: 50,
			status: '',
			keyword: '',
			orderStartDatetime: '',
			orderEndDatetime: '',
			sellerIds: [],
			brandIds: [],
		},
		orders: [],
		order: {},
		orderTotal: {
			productList: [],
			totalPrice: 0,
			totalDiscountPrice: 0,
			totalVat: 0,
			totalSellingPriceWithVat: 0,
		},
		orderCount: 0,
		orderItemCount: 0,
		summary: {},
		filter: {},

		// 주문하기
		selectedProductList: [],
		estimateId: 0,
	},
	getters: {
		brandFilter: state => {
			return state.filter.brands?.map(brand => {
				return {
					name: brand.name,
					value: brand.id,
				}
			})
		},
		sellerFilter: state => {
			return state.filter.sellers?.map(seller => {
				return {
					name: seller.name,
					value: seller.id,
				}
			})
		},
		currentPage: state => {
			return (state.params.offset + state.params.length) / state.params.length
		},
		keyword: state => state.params.keyword,
		products: state => state.orderTotal.productList,
	},
	mutations: {
		setParamsStatus: (state, { status }) => {
			state.params.status = status
		},
		setParamsText: (state, { keyword, orderStartDatetime, orderEndDatetime }) => {
			state.params.keyword = keyword
			state.params.orderStartDatetime = orderStartDatetime
			state.params.orderEndDatetime = orderEndDatetime
		},
		setParamsFilter: (state, { sellerIds, brandIds }) => {
			state.params.sellerIds = sellerIds
			state.params.brandIds = brandIds
		},
		setParamsPaging: (state, { offset, length }) => {
			state.params.offset = offset
			state.params.length = length
		},
		setOrders: (state, payload) => {
			state.orders = payload
		},
		setOrder: (state, payload) => {
			state.order = payload
		},
		setOrderTotal: (state, payload) => {
			state.orderTotal = payload
		},
		setOrderCount: (state, payload) => {
			state.orderCount = payload
		},
		setOrderItemCount: (state, payload) => {
			state.orderItemCount = payload
		},
		setSummary: (state, payload) => {
			state.summary = payload
		},
		setFilter: (state, payload) => {
			state.filter = payload
		},

		// 주문하기
		setSelectedProductList: (state, payload) => {
			state.selectedProductList = payload
		},
		setEstimateId: (state, payload = 0) => {
			state.estimateId = payload
		},
	},
	actions: {
		lookupOrderList: async ({ commit }, { instituteId, params }) => {
			const response = await orderAPI.lookupOrderList(instituteId, params)
			commit('setOrders', response.data.list)
			commit('setOrderCount', response.data.orderCount)
			commit('setOrderItemCount', response.data.orderItemCount)
		},
		lookupOrderSummary: async ({ commit }, instituteId) => {
			const response = await orderAPI.lookupOrderSummary(instituteId)
			commit('setSummary', response.data.summary)
		},
		lookupOrderFilter: async ({ commit }, { instituteId, status }) => {
			const response = await orderAPI.lookupOrderFilter(instituteId, status)
			commit('setFilter', response.data.filter)
		},
		lookupOrder: async ({ commit }, { instituteId, id }) => {
			const response = await orderAPI.lookupOrder(instituteId, id)
			commit('setOrder', response.data)
		},
		order: async (_, { instituteId, body }) => {
			const response = await orderAPI.order(instituteId, body)
			return response.data
		},
		orderCalculate: async ({ commit }, { instituteId, body }) => {
			const response = await orderAPI.orderCalculate(instituteId, body)
			commit('setOrderTotal', response.data)
		},
		nonMemberOrderCalculate: async ({ commit }, { body }) => {
			const response = await orderAPI.nonMemberOrderCalculate(body)
			commit('setOrderTotal', response.data)
		},
		orderCancelRequest: async (_, { instituteId, body }) => {
			return await orderAPI.orderCancelRequest(instituteId, body)
		},
		orderCancel: async (_, { instituteId, body }) => {
			return await orderAPI.orderCancel(instituteId, body)
		},
		orderPrint: async (_, { instituteId, body }) => {
			const response = await orderAPI.orderPrint(instituteId, body)
			return response.data
		},
		lookupInicisCard: async (_, { instituteId, body }) => {
			const response = await orderAPI.lookupInicisCard(instituteId, body)
			return response.data
		},
	},
}

export default orders
