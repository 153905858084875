<template>
	<base-management title="주문 상세">
		<div class="header-wrapper">
			<div>
				<span>주문일자</span>
				<b>{{ formatDate(order.createDatetime) }}</b>
			</div>
			<span class="separator">|</span>
			<div>
				<span>주문번호</span>
				<b>{{ order.orderNo }}</b>
			</div>
		</div>

		<order-history class="history" :list="orderList" :showHeader="false" @openModal="openModal" />
		<order-info class="info" />

		<modal-order-cancel :title="modalTitle" :content="modalContent" :itemId="orderItemId" @complete="fetchOrder" />
		<modal-order-return :orderItem="orderItem" />
		<modal-shipping-tracking :orderItem="orderItem" :orderer="orderer" :seller="seller" :address="address" :type="type" />
		<modal-seller :seller="seller" />
		<modal-info :title="modalInfoTitle" :rows="modalInfoRows" />
	</base-management>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import mixin from '@/mixins'
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import StatusCounterList from '@/views/my-page/order-delivery/order/components/StatusCounterList.vue'
import DetailSearchBox from '@/views/my-page/order-delivery/order/components/search/DetailSearchBox.vue'
import OrderHistory from '@/views/my-page/order-delivery/order/components/OrderHistory.vue'
import OrderInfo from '@/views/my-page/order-delivery/order/components/OrderInfo.vue'
import ModalOrderCancel from '@/components/modal/ModalOrderCancel.vue'
import ModalOrderReturn from '@/components/modal/ModalOrderReturn.vue'
import ModalShippingTracking from '@/components/modal/ModalShippingTracking.vue'
import ModalSeller from '@/views/seller/modal/ModalSeller.vue'
import ModalInfo from '@/components/modal/ModalInfo.vue'

export default {
	name: 'OrderDetail',
	components: {
		BaseManagement,
		StatusCounterList,
		DetailSearchBox,
		OrderHistory,
		OrderInfo,
		ModalOrderCancel,
		ModalOrderReturn,
		ModalShippingTracking,
		ModalSeller,
		ModalInfo,
	},
	mixins: [mixin],
	data() {
		return {
			orderId: this.$route.params.id,
			orderItemId: 0,
			modalTitle: '',
			modalContent: '',
			seller: {},
			modalInfoTitle: '',
			modalInfoRows: [],
			orderItem: {},
			orderer: {},
			address: '',
			type: '',
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('order', ['order']),
		orderList() {
			return [
				{
					...this.order,
					address: this.order.orderer?.address,
					items: this.order.items,
				},
			]
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				await this.fetchOrder()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('order', ['lookupOrder']),
		async fetchOrder() {
			await this.lookupOrder({ instituteId: this.instituteId, id: this.orderId })
		},
		openModal(data) {
			if (data.title) this.modalTitle = data.title
			if (data.content) this.modalContent = data.content
			if (data.orderItemId) this.orderItemId = data.orderItemId
			if (data.seller) this.seller = data.seller
			if (data.modalInfoTitle) this.modalInfoTitle = data.modalInfoTitle
			if (data.modalInfoRows) this.modalInfoRows = data.modalInfoRows
			if (data.orderItem) this.orderItem = data.orderItem
			if (data.orderer) this.orderer = data.orderer
			if (data.address) this.address = data.address
			if (data.type) this.type = data.type

			this.$bvModal.show(data.modalName)
		},
	},
}
</script>

<style lang="scss" scoped>
.header-wrapper {
	display: flex;
	align-items: center;
	gap: 16px;
	padding-bottom: 1rem;
	border-bottom: 1px solid $COLOR_EEE;
	.separator {
		color: $COLOR_DDD;
	}
	> div {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 8px;
		> span {
			font-weight: 500;
		}
		> span,
		> b {
			font-size: 1rem;
		}
	}
}
.history {
	margin: 24px 0 64px 0;
}
.info {
	margin-bottom: 80px;
}
</style>
