import ParamMaker from '@/utils/modules/ParamMaker'
import axiosInstance from '@/services/axios'

const endPoint = {
	lookupCatalogList: paramsObject => `catalog/search?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupCatalog: (id, paramsObject) => `catalog?catalogId=${id}&${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupProductList: paramsObject => `catalog/product/list?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupProductListFilter: paramsObject => `catalog/product/list/filter?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupRecommendedKeywords: paramsObject => `catalog/search/suggest?${ParamMaker.setParamUsingObject(paramsObject)}`,
}

const catalogAPI = {
	lookupCatalogList: paramsObject => axiosInstance.get(endPoint.lookupCatalogList(paramsObject)), // 상품 목록
	lookupCatalog: (id, paramsObject) => axiosInstance.get(endPoint.lookupCatalog(id, paramsObject)), // 상품 상세
	lookupProductList: paramsObject => axiosInstance.get(endPoint.lookupProductList(paramsObject)),
	lookupProductListFilter: paramsObject => axiosInstance.get(endPoint.lookupProductListFilter(paramsObject)),
	lookupRecommendedKeywords: paramsObject => axiosInstance.get(endPoint.lookupRecommendedKeywords(paramsObject)),
}

export default catalogAPI
