<template>
	<div class="cart-shipping-info">
		<div class="description">
			<template v-if="!isLogin">
				<div class="text-wrapper">
					<div class="text">배송지 등록 정보에 따라 주문가능한 수량 및 출고가능여부가 달라질 수 있습니다</div>
					<div class="sub-text">
						<img src="@/assets/svg/main/icon-location.svg" />
						<span>배송지 등록은 회원가입 이후에 가능합니다</span>
						<span class="click-text" @click="goToSSOMain()">회원가입</span>
					</div>
				</div>
			</template>
			<template v-else>
				<template v-if="!isEmpty">
					<div class="text-wrapper">
						<div class="text">
							배송지: {{ currentAddress.name }} <span v-if="currentAddress.isDefault" class="status">기본 배송지</span>
						</div>
						<div class="sub-text">
							<img src="@/assets/svg/main/icon-location.svg" />
							<span>{{ address }}</span>
							<span class="click-text" @click="onEditAddress">변경</span>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="text-wrapper">
						<div class="text">배송지</div>
						<div class="sub-text">
							<img src="@/assets/svg/main/icon-location.svg" />
							<span>배송지 정보가 없습니다</span>
							<span class="click-text" @click="onClickAdd">배송지 등록</span>
						</div>
					</div>
				</template>
			</template>
		</div>
		<modal-select-current-address ref="ModalSelectCurrentAddress" />
		<modal-shipping-address :id="'ModalAddAddress'" title="배송지 등록" @success="lookupAddressList(instituteId)" />
	</div>
</template>

<script>
import { goToSSOMain, isLogin } from '@/services/api/common'
import { mapState, mapGetters, mapActions } from 'vuex'
import cookies from 'vue-cookies'
import ModalShippingAddress from '@/views/my-page/my-info/shipping-address/modal/ModalShippingAddress.vue'
import ModalSelectCurrentAddress from '@/components/modal/ModalSelectCurrentAddress.vue'

export default {
	name: 'CartShippingInfo',
	components: {
		ModalShippingAddress,
		ModalSelectCurrentAddress,
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchAddressList()
			},
			immediate: true,
		},
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('delivery', ['basicAddress']),
		isLogin() {
			return isLogin()
		},
		isEmpty() {
			return this.$_.isEmpty(this.currentAddress)
		},
		address() {
			if (this.isEmpty) return ''
			const { postcode, address, addressDetail } = this.currentAddress
			return `[${postcode}] ${address} ${addressDetail}`
		},
		currentAddress() {
			return cookies.get('currentAddress2') ?? this.basicAddress
		},
	},
	methods: {
		...mapActions('delivery', ['lookupAddressList']),
		goToSSOMain() {
			goToSSOMain(location.href)
		},
		async fetchAddressList() {
			await this.lookupAddressList(this.instituteId)
		},
		onClickAdd() {
			this.$bvModal.show('ModalShippingAddress')
		},
		onEditAddress() {
			this.$refs['ModalSelectCurrentAddress'].show()
		},
	},
}
</script>

<style lang="scss" scoped>
.cart-shipping-info {
	.description {
		height: 92px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $LIST_BACKGROUND;
		border-radius: 5px;
		padding: 20px;

		.text-wrapper {
			.text {
				font-weight: 700;
				display: flex;
				align-items: center;
				.status {
					height: 100%;
					color: $COLOR_333;
					font-size: 0.75rem;
					background-color: $LINE_DIVIDER;
					border-radius: 5px;
					padding: 3px 5px;
					margin-left: 15px;
				}
			}
			.sub-text {
				margin-top: 5px;
				font-size: 0.875rem;
				display: flex;
				align-items: center;
				img + span {
					margin-left: 5px;
				}
				.click-text {
					color: $DEEP_YELLOW;
					font-weight: 700;
					font-size: 0.875rem;
					text-decoration: underline;
					cursor: pointer;
					margin-left: 15px;
				}
			}
		}
	}
}
</style>
