<template>
	<b-modal
		ref="modal"
		id="ModalBusinessRegistration"
		centered
		title="사업자등록증 등록신청"
		ok-title="등록신청"
		ok-variant="warning"
		ok-only
		:ok-disabled="isDisabledBtn"
		button-size="lg"
		@show="onShow"
		@ok="onSave"
	>
		<div>
			<input-form-item
				v-model="info.name"
				direction="column"
				title="사업자등록증 별칭"
				placeholder="사업자등록증 별칭을 입력해주세요"
				:inputStyle="inputStyle"
				style="padding: 0"
				autofocus
				required
			/>
			<upload-form-item direction="column" title="사업자등록증 업로드" @upload="onUploadFile" @delete="onDeleteFile" required />
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import InputFormItem from '@/components/form-item/InputFormItem.vue'
import UploadFormItem from '@/components/form-item/UploadFormItem.vue'

export default {
	name: 'ModalBusinessRegistration',
	components: {
		InputFormItem,
		UploadFormItem,
	},
	data() {
		return {
			inputStyle: {
				width: '566px',
			},
			info: {},
			isDisabledBtn: true,
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
	},
	watch: {
		info: {
			handler() {
				this.isDisabledBtn = !this.info.name || this.info.businessRegistrations.length <= 0
			},
			deep: true,
		},
	},
	methods: {
		...mapActions('businessRegistration', ['addRegistration']),
		init() {
			this.info = {
				name: '',
				businessRegistrations: [],
			}
			this.isDisabledBtn = true
		},
		onShow() {
			this.init()
		},
		async onSave(e) {
			e.preventDefault()

			try {
				const formData = new FormData()
				formData.append('name', this.info.name)
				this.info.businessRegistrations.forEach(businessRegistration => {
					formData.append(`businessRegistrations`, businessRegistration)
				})

				await this.addRegistration({
					instituteId: this.instituteId,
					formData,
				})
				this.$emit('success')
				this.$refs.modal.hide()
				this.$root.toast('사업자등록증 등록 성공', '새 사업자등록증을 등록했습니다.', ToastType.SUCCESS)
			} catch (e) {
				this.$root.toast('사업자등록증 등록 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
		onUploadFile(files) {
			this.info.businessRegistrations = files
		},
		onDeleteFile(files) {
			this.info.businessRegistrations = files
		},
	},
}
</script>

<style lang="scss">
#ModalBusinessRegistration___BV_modal_outer_ {
	.modal-dialog {
		max-width: 600px;
	}
	#ModalBusinessRegistration___BV_modal_header_ {
		&.modal-header {
			border-bottom: 1px solid $COLOR_EEE;
		}
	}
	#ModalBusinessRegistration___BV_modal_footer_ {
		&.modal-footer {
			border-top: 1px solid $COLOR_EEE;
			justify-content: center;
			.btn {
				width: 400px;
				height: 48px;
				background: $PRIMARY_YELLOW;
				&.disabled {
					background: $LIST_BACKGROUND;
					color: $COLOR_999;
					border: none;
				}
			}
		}
	}
}
</style>

<style lang="scss" scoped></style>
