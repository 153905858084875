<template>
	<div class="order-price-box" :class="mode">
		<div class="title" v-if="title">
			<h4>{{ title }}</h4>
			<div class="right-btn-group" v-if="btnText">
				<button class="btn">{{ btnText }}</button>
			</div>
		</div>
		<div class="box">
			<ul>
				<li>
					<b>{{ info.price.text }}</b>
					<p>{{ setPrice(info.price.val) }}</p>
				</li>
				<li>
					<b>{{ info.shippingPrice.text }}</b>
					<p>{{ setPrice(info.shippingPrice.val) }}</p>
				</li>
				<li class="discount" v-if="info.discount">
					<b>{{ info.discount.text }}</b>
					<p>-{{ setPrice(info.discount.val) }}</p>
				</li>
				<li>
					<b>{{ info.vat.text }}</b>
					<p>{{ setPrice(info.vat.val) }}</p>
				</li>
			</ul>
			<div class="all">
				<b>{{ info.allPrice.text }}</b>
				<p>{{ setPrice(info.allPrice.val) }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'OrderPriceBox',
	props: {
		title: {
			type: String,
			default: '',
		},
		btn: {
			type: Object,
			default: () => {},
		},
		info: {
			type: Object,
			default: () => {},
		},
		mode: {
			type: String,
			default: '',
		},
	},
	computed: {
		btnText() {
			return this.btn?.text
		},
	},
	methods: {
		setPrice(price) {
			if (!price) return '0원'
      
			return `${price.toLocaleString()}원`
		},
	},
}
</script>

<style lang="scss" scoped>
.order-price-box {
	margin-top: 24px;
	&.normal {
		.box {
			background-color: white;
			li {
				margin-bottom: 1rem;
				b {
					font-weight: 500;
				}
				p {
					font-weight: 500;
				}
			}
		}
	}
	.title {
		margin-bottom: 1rem;
		h4 {
			font-size: 1.25rem;
			font-weight: 700;
		}
	}
	.box {
		width: 100%;
		padding: 20px;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 4px;
		background-color: $LIST_BACKGROUND;
		li {
			display: flex;
			align-items: center;
			margin-bottom: 8px;
			&.discount {
				p {
					color: $PRIMARY_RED;
				}
			}
		}

		b {
			font-size: 0.875rem;
			font-weight: 700;
		}
		p {
			font-size: 0.875rem;
			font-weight: 700;
			margin-left: auto;
		}
		.all {
			width: 100%;
			display: flex;
			align-items: center;
			padding-top: 1rem;
			border-top: 1px solid black;
			margin-top: 8px;
		}
	}
}
</style>
