<template>
	<div class="progress-indicator">
		<div class="indicator" :style="`width: ${step}%`"></div>
	</div>
</template>

<script>
export default {
	props: {
		progressStep: {
			type: Number,
			default: 1,
		},
	},
	computed: {
		step() {
			return (100 / 6) * this.progressStep
		},
	},
}
</script>

<style lang="scss" scoped>
.progress-indicator {
	width: 100%;
	height: 6px;
	background-color: #eee;
	.indicator {
		height: inherit;
		background-color: #fec741;
	}
}
</style>
