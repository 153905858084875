<template>
	<b-modal
		ref="modal"
		id="ModalOrderReturn"
		centered
		title="교환 및 반품 절차"
		ok-title="1:1 문의"
		cancel-title="닫기"
		cancel-variant="default"
		ok-variant="warning"
		size="lg"
		@ok="onInquiry"
	>
		<div class="modal-order-return-wrapper">
			<div class="return-procedure-area">
				<div class="return-procedure-item">
					<img src="@/assets/svg/icon-cs.svg" />
					<div class="number">
						<span>1</span>
					</div>
					<p>고객센터/ 1:1문의로<br />교환/반품 접수합니다</p>
				</div>
				<div class="return-procedure-item">
					<img src="@/assets/svg/icon-truck.svg" />
					<div class="number">
						<span>2</span>
					</div>
					<p>반품해야할 상품은<br />반품지로 배송합니다</p>
				</div>
				<div class="return-procedure-item">
					<img src="@/assets/svg/icon-box.svg" />
					<div class="number">
						<span>3</span>
					</div>
					<p>
						제품 검수 후 안내받은 방식으로<br />
						환불/교환 진행됩니다
					</p>
				</div>
				<div class="return-procedure-item">
					<img src="@/assets/svg/icon-card.svg" />
					<div class="number">
						<span>4</span>
					</div>
					<p>안내받은 취소수수료,<br />반품 배송비를 청구받습니다</p>
				</div>
			</div>
			<div class="return-info-area">
				<div class="title">
					<img src="@/assets/svg/icon_warning_black.svg" />
					<span>교환 및 반품 안내사항</span>
				</div>
				<div class="content">
					<div class="list">
						<ul>
							<li>
								제품 또는 배송문제의 경우 물품 수령 후 영업일 기준 7일 이내 교환 및 반품이 가능합니다.<br />
								* 빠른 시일 내에 랩매니저 고객센터(1533-3061)으로 연락 부탁드립니다.
							</li>
							<li>
								단순 변심으로 인한 교환 미 반품의 경우 물품 수령 후 영업일 기준 5일 이내 교환 및 반품이 가능합니다.<br />
								* 단, 상품에 따라 교환 및 반품이 불가할 수 있으며, 취소 수수료가 발생할 수 있습니다.
							</li>
							<li>
								냉장/냉동 상품, 해외 발주 상품의 경우 교환 및 반품이 불가할 수 있습니다.<br />
								랩매니저 스토어 고객센터(1533-3061)에서 가능 여부 확인이 필요합니다.
							</li>
							<li>
								고객님의 변심으로 인한 교환 및 반품의 경우, 상품 반환에 필요한 추가비용이 발생할 수 있습니다.<br />
								(왕복 택배비, 지역별 배송비, 상품별로 달라질 수 있습니다. 단, 상품 포장이 미개봉인 경우에 한함)
							</li>
							<li>상품 개봉, 훼손된 경우 교환 및 반품이 불가할 수 있습니다.</li>
						</ul>
					</div>
					<div class="service-center-info">
						<div class="image-wrapper">
							<img src="@/assets/svg/icon-cs.svg" />
						</div>
						<div class="info-wrapper">
							<div class="title">랩매니저 스토어 고객센터</div>
							<div class="tel">1533-3061</div>
							<div class="working-time">상담시간 평일 10:00 ~ 17:00<br />Off-time 12:00 ~ 13:00 (토/일/공휴일 휴무)</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import PageName from '@/utils/define/PageName'

export default {
	name: 'ModalOrderReturn',
	props: {
		orderItem: {
			type: Object,
			required: true,
		},
	},
	methods: {
		onInquiry() {
			this.$refs['modal'].hide()

			this.$router.push({
				name: PageName.CsCenter.InquiryEdit,
				params: {
					mode: 'write',
				},
				query: {
					product: JSON.stringify({
						...this.orderItem.product,
						orderItemId: this.orderItem.orderItemId,
					}),
				},
			})
		},
	},
}
</script>

<style lang="scss">
#ModalOrderReturn___BV_modal_outer_ {
	.modal-dialog {
		max-width: 779px;
	}
	#ModalOrderReturn___BV_modal_title_ {
		font-size: 1.5rem;
	}
	#ModalOrderReturn___BV_modal_footer_ {
		&.modal-footer {
			justify-content: center;
			.btn {
				width: 200px;
				height: 48px;
				&.btn-warning {
					background: $PRIMARY_YELLOW;
				}
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.modal-order-return-wrapper {
	display: flex;
	flex-direction: column;
	gap: 40px;
	.return-procedure-area {
		display: flex;
		padding: 20px;
		justify-content: space-between;
		border: 1px solid $COLOR_EEE;
		border-radius: 10px;
		.return-procedure-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 16px;
			> img {
				width: 100px;
				height: 100px;
			}
			> p {
				font-weight: 500;
				font-size: 0.813rem;
				color: $COLOR_666;
			}
			.number {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 24px;
				height: 24px;
				background: $PRIMARY_YELLOW;
				border-radius: 100px;
				text-align: center;
				> span {
					font-size: 0.875rem;
					font-weight: 700;
				}
			}
		}
	}
	.return-info-area {
		display: flex;
		flex-direction: column;
		gap: 18px;
		.title {
			display: flex;
			align-items: center;
			gap: 10px;
			font-weight: 700;
			font-size: 1.25rem;
		}
		.content {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 20px;
			background: $LIST_BACKGROUND;
			border-radius: 5px;
			.list {
				ul {
					margin-left: 20px;
					display: flex;
					flex-direction: column;
					gap: 16px;
					li {
						font-weight: 500;
						font-size: 0.75rem;
						color: $COLOR_666;
						list-style-type: disc;
					}
				}
			}
			.service-center-info {
				display: flex;
				gap: 24px;
				align-items: center;
				padding: 20px;
				background: $PRIMARY_WHITE;
				border: 1px solid $COLOR_EEE;
				border-radius: 5px;
				.image-wrapper {
					width: 100px;
					height: 100px;
					border: 1px solid $COLOR_EEE;
					border-radius: 100px;
				}
				.info-wrapper {
					display: flex;
					flex-direction: column;
					gap: 8px;
					.title {
						font-weight: 500;
						font-size: 0.875rem;
					}
					.tel {
						font-weight: 700;
						font-size: 1.5rem;
					}
					.working-time {
						font-weight: 500;
						font-size: 0.75rem;
						color: $COLOR_999;
					}
				}
			}
		}
	}
}
</style>
