<template>
	<div class="loading-wrap">
		<div class="loading">
			<div class="loader"></div>
			<b>{{ keyword }}</b>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Loading',
	props: {
		keyword: {
			type: String,
			default: '',
		},
	},
}
</script>

<style lang="scss" scoped>
.loader,
.loader:after {
	border-radius: 50%;
	width: 50px;
	height: 50px;
}
.loader {
	font-size: 10px;
	margin: 0 auto 15px;
	position: relative;
	text-indent: -9999em;
	border-top: 4px solid #f5f5f5;
	border-right: 4px solid #f5f5f5;
	border-bottom: 4px solid #f5f5f5;
	border-left: 4px solid #fec741;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.loading-wrap {
	width: 100%;
	height: 480px;
	position: relative;
	.loading {
		position: absolute;
		width: 100%;
		top: calc(50% - 42px);
		left: 50%;
		transform: translateX(-50%) translateZ(0);
		text-align: center;
	}
	b {
		font-size: 1rem;
		font-weight: 700;
		color: $COLOR_999;
	}
}
</style>
