<template>
	<div class="brand-wrapper">
		<div class="brand-image">
			<img :src="image" />
		</div>
		<div class="brand-name">{{ brand.name }}</div>
		<div class="brand-info-text" @click="gotoBrandDetail">브랜드 관 보기</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageName from '@/utils/define/PageName'

export default {
	computed: {
		...mapGetters('productInfo', ['brand']),
		image() {
			return this.brand.image ?? require('@/assets/images/no-image-small.jpg')
		},
	},
	methods: {
		gotoBrandDetail() {
			this.$router.push({
				name: PageName.Search,
				params: {
					category: 'brand',
				},
				query: {
					id: this.brand.id,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.brand-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 100%;
	border: 1px solid #ddd;
	border-radius: 5px;
	cursor: pointer;
	.brand-image {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 120px;
		height: 40px;
		border-right: 1px solid #ddd;
		padding: 1px 10px;
		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
	.brand-name {
		font-size: 14px;
		color: #111;
		font-weight: 700;
		padding: 0px 15px;
		white-space: nowrap;
	}
	.brand-info-text {
		margin-left: auto;
		margin-right: 10px;
		white-space: nowrap;
		right: 10px;
		font-size: 14px;
		font-weight: 500;
		color: #666;
		padding-right: 12px;
		background-image: url(~@/assets/svg/arrow_up_s.svg);
		background-repeat: no-repeat;
		background-size: 6px;
		background-position: center right 0;
	}
}
</style>
