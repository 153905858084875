<template>
	<base-management title="취소 / 반품 / 교환 상세">
		<div class="header-wrapper">
			<div>
				<span>취소 접수일</span>
				<b>{{ formatDate(item.cancelRequestDatetime) }}</b>
			</div>
			<span class="separator">|</span>
			<div>
				<span>주문일자</span>
				<b>{{ formatDate(order.createDatetime) }}</b>
			</div>
			<span class="separator">|</span>
			<div>
				<span>주문번호</span>
				<b>{{ order.orderNo }}</b>
			</div>
		</div>

		<order-item class="item" :item="item" :orderer="order.orderer" :showButton="false" :isReturn="true" />
		<return-info :item="item" :orderer="order.orderer" />
	</base-management>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import mixin from '@/mixins'
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import StatusCounterList from '@/views/my-page/order-delivery/order/components/StatusCounterList.vue'
import DetailSearchBox from '@/views/my-page/order-delivery/order/components/search/DetailSearchBox.vue'
import OrderItem from '@/views/my-page/order-delivery/order/components/OrderItem.vue'
import ReturnInfo from '@/views/my-page/order-delivery/return/components/ReturnInfo.vue'

export default {
	name: 'ReturnDetail',
	components: {
		BaseManagement,
		StatusCounterList,
		DetailSearchBox,
		OrderItem,
		ReturnInfo,
	},
	mixins: [mixin],
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('order', ['order']),
		item() {
			const empty = { product: { seller: {}, brand: {}, category: {} } }
			return this.$_.isEmpty(this.order) || this.order.items.length <= 0
				? empty
				: this.order.items.find(item => item.orderItemId == this.$route.query.itemId) ?? empty
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				await this.fetchOrder()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('order', ['lookupOrder']),
		async fetchOrder() {
			await this.lookupOrder({ instituteId: this.instituteId, id: this.$route.params.id })
		},
	},
}
</script>

<style lang="scss" scoped>
.header-wrapper {
	display: flex;
	align-items: center;
	gap: 16px;
	padding-bottom: 1rem;
	border-bottom: 1px solid $COLOR_EEE;
	.separator {
		color: $COLOR_DDD;
	}
	> div {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 8px;
		> span {
			font-weight: 500;
		}
		> span,
		> b {
			font-size: 1rem;
		}
	}
}
.item {
	margin: 24px 0 64px 0;
	border-top: 1px solid $COLOR_EEE;
}
</style>
