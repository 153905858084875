<template>
	<div class="RequestProductList">
		<content-title title="상품요청목록" />
		<div class="content-wrapper">
			<detail-search-box
				class="search-box"
				title="상세검색"
				subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
				searchPlaceholder="요청번호, 상품정보로 검색"
				searchText="요청일"
				:datePlaceholder="datePlaceholder"
				@searchTextList="searchTextList"
			/>
			<common-table
				title="상품요청 목록"
				:isCheck="false"
				:count="count"
				:headers="requestHeaders"
				:list="list"
				btnText="상품 요청하기"
				@clickEv="onClickRequest"
				@go="goDetail"
				:noData="noData"
				@page="getCurrentPage"
			/>
			<content-notice v-if="count" :list="noticeList" />
		</div>

		<modal-propose-product title="상품 요청하기" @success="fetchRequestProductList" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import date from '@/mixins/date'
import { Request } from '@/utils/define/ItemCode'
import ContentTitle from '@/components/content/ContentTitle'
import ContentNotice from '@/components/content/ContentNotice'
import DetailSearchBox from '@/views/my-page/order-delivery/order/components/search/DetailSearchBox'
import CommonTable from '@/components/table/CommonTable'
import PageName from '@/utils/define/PageName'
import ModalProposeProduct from '@/views/search/modal/ModalProposeProduct.vue'

export default {
	name: 'RequestProductList',
	components: {
		ContentTitle,
		ContentNotice,
		DetailSearchBox,
		CommonTable,
		ModalProposeProduct,
	},
	mixins: [date],
	data() {
		return {
			offset: 0,
			length: 50,
			searchKeyword: '',
			searchDate: [],
			list: [],
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('requestProduct', ['requestProducts', 'count']),
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
		requestHeaders() {
			let list = []
			list.push({ key: 'status', name: '요청 상태', width: 100 })
			list.push({ key: 'no', name: '상품요청번호', width: 150 })
			list.push({ key: 'name', name: '상품정보', width: 600 })
			list.push({ key: 'createDatetime', name: '요청일', width: 150 })
			return list
		},
		noData() {
			let obj = {}
			obj.image = require('@/assets/svg/content/no-docs.svg')
			obj.text = '요청한 상품이 없습니다'
			obj.btnText = '상품 요청하기'
			return obj
		},
		noticeList() {
			return ['상품 소싱완료 후 견적서를 보내드려요']
		},
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchRequestProductList()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('requestProduct', ['lookupRequestProductList']),
		async fetchRequestProductList() {
			await this.lookupRequestProductList({
				instituteId: this.instituteId,
				params: {
					offset: this.offset,
					length: this.length,
					keyword: this.searchKeyword,
					startDatetime: this.searchDate.length > 0 ? this.searchDate[0] : '',
					endDatetime: this.searchDate.length > 0 ? this.searchDate[1] : '',
				},
			})

			this.list = this.$_.cloneDeep(this.requestProducts).map(item => {
				item.status = { class: this.setClass(item.status.code), text: item.status.name }
				item.createDatetime = this.datetimeToString(item.createDatetime)
				return item
			})
		},
		setClass(status) {
			return Request.statusToClass(status)
		},
		searchTextList(filter) {
			if (this.$_.isEmpty(filter)) {
				this.searchKeyword = ''
				this.searchDate = []
			} else {
				Object.entries(filter).forEach(([key, value]) => {
					if (key === '검색') this.searchKeyword = value.length > 0 ? value[0].value : ''
					else if (key === '기간 조회') this.searchDate = value.length > 0 ? value[0].value : []
				})
			}
			this.fetchRequestProductList()
		},
		onClickRequest() {
			this.$bvModal.show('ModalProposeProduct')
		},
		goDetail(id) {
			this.$router.push({
				name: PageName.MyPage.RequestProductDetail,
				params: {
					id: id,
				},
			})
		},
		getCurrentPage({ offset, length }) {
			this.offset = offset
			this.length = length
			this.fetchRequestProductList()
		},
	},
}
</script>

<style lang="scss" scoped>
.RequestProductList {
	.common-table {
		margin-top: 48px;
	}
	.content-notice {
		margin: 64px 0;
	}
}
</style>
