<template>
	<div class="invite-member-form">
		<div class="link">
			<p>링크로 초대하기</p>
			<div class="input-area">
				<div>
					<lottie-animation
						v-show="isShowLottie"
						ref="lottie-animation"
						:animationData="require('@/assets/json/lm_loading_lottie.json')"
						:autoPlay="false"
						:loop="true"
						class="lottie-animation"
					></lottie-animation>
					<input v-model="invitationLink" ref="invitationLinkInput" type="text" readonly />
				</div>
				<button class="btn btn-border" @click="copyToClipboardLink">
					<img src="@/assets/svg/duplicate.svg" />
					링크 복사
				</button>
			</div>
		</div>
		<div>
			<email-text-area ref="emailTextArea" />
		</div>
		<div class="footer">
			<button class="btn btn-border" @click="onSend">이메일 전송</button>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import EmailTextArea from '@/components/input/EmailTextArea.vue'
import LottieAnimation from 'lottie-web-vue'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'InviteMemberForm',
	props: {
		createdInstituteId: {
			type: String,
			default: '',
		},
	},
	components: {
		EmailTextArea,
		LottieAnimation,
	},
	data() {
		return {
			isShowLottie: false,
		}
	},
	computed: {
		...mapState('member', ['invitationLink']),
	},
	async created() {
		await this.$nextTick()
		this.fetchInvitationLink()
	},
	methods: {
		...mapMutations('member', ['setInvitationLink']),
		...mapActions('member', ['lookupInvitationLink', 'inviteMember']),
		playLoading() {
			this.isShowLottie = true
			this.$refs['lottie-animation'].anim.play()
		},
		stopLoading() {
			this.isShowLottie = false
			this.$refs['lottie-animation'].anim.stop()
		},
		async fetchInvitationLink() {
			try {
				this.playLoading()
				this.setInvitationLink('링크 생성중...')
				await this.lookupInvitationLink(this.createdInstituteId)
				this.stopLoading()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		copyToClipboardLink() {
			navigator.clipboard
				.writeText(this.invitationLink)
				.then(() => {
					this.$root.toast('초대 링크 복사', '초대 링크가 복사되었습니다.', ToastType.SUCCESS)
				})
				.catch(() => {
					this.$root.toast('초대 링크 복사', '초대 링크 복사를 실패하였습니다.', ToastType.ERROR)
				})
		},
		async onSend() {
			const emails = this.$refs['emailTextArea'].getEmailList()
			if (emails.length <= 0) return

			try {
				await this.inviteMember({
					instituteId: this.createdInstituteId,
					body: {
						emails: emails,
					},
				})

				this.$root.toast('초대 이메일 전송 완료', '초대 이메일을 전송했습니다.', ToastType.SUCCESS)
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss">
.invite-member-form {
	.link {
		p {
			font-size: 1.125rem;
			font-weight: 700;
			display: block;
			margin-bottom: 10px;
		}
		.input-area {
			width: 100%;
			display: flex;
			align-items: center;
			height: 48px;
			> div {
				display: inline-flex;
				width: calc(100% - 210px);
				margin-right: 10px;
				height: inherit;
				border: 1px solid #ccc;
				border-radius: 5px;
				background-color: #f5f5f5;
				input {
					border: 0;
					background-color: transparent;
					width: 100%;
					padding: 0 10px;
				}
			}
			.lottie-animation {
				width: 76px;
				height: 76px;
				margin: -15px -30px 0 -20px;
			}
		}
	}
	.container {
		margin-top: 40px;
		.top {
			align-items: flex-end;
			.title-text {
				font-size: 1.125rem;
				font-weight: 700;
			}
			.delete-text {
				font-size: 0.875rem;
			}
		}
		.contents {
			height: 250px;
			margin-bottom: 1rem;
		}
	}
	.footer {
		margin-bottom: 20px;
	}
	.btn-border {
		width: 200px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-left: auto;
		height: 48px;
		border-radius: 5px;
		font-size: 1rem;
		font-weight: 500;
		img {
			width: 24px;
		}
	}
}
</style>
