import brandAPI from '@/services/api/brand'

const brands = {
	namespaced: true,
	state: {
		brandList: {},
		popularBrandList: [],
		brand: {},
	},
	getters: {
		getBrandList: state => state.brandList,
		getPopularBrandList: state => state.popularBrandList,
	},
	mutations: {
		setBrandList: (state, payload) => {
			state.brandList = payload
		},
		setPopularBrandList: (state, payload) => {
			state.popularBrandList = payload
		},
		setBarnd: (state, payload) => {
			state.brand = payload
		},
	},
	actions: {
		lookupBrandList: async ({ commit }) => {
			const response = await brandAPI.lookupBrandList()
			commit('setBrandList', response.data)
		},
		lookupPopularBrandList: async ({ commit }, params) => {
			const { offset, length } = params
			const response = await brandAPI.lookupPopularBrandList(offset, length)
			commit('setPopularBrandList', response.data)
		},
		lookupBrand: async ({ commit }, id) => {
			const response = await brandAPI.lookupBrand(id)
			commit('setBarnd', response.data)
		},
	},
}

export default brands
