<template>
	<ul class="info-table" :class="{ odd: idOdd }" :style="containerStyle">
		<li v-for="(item, index) in informationList" :key="`info-item_${index}`" :class="{ whole: item.whole }">
			<div class="header">{{ item.title }}</div>
			<div class="content" v-if="item.whole">
				<div
					v-show="text.length > 0"
					v-for="(text, index) in splitText(item.value)"
					:class="{ one: splitText(item.value).length == 1 }"
					:key="index"
					:style="item.fontStyle"
				>
					<img v-if="item.img" :src="item.img" alt="image" />
					{{ text }}
				</div>
				<!-- <ul class="inner-list">
					<li v-for="sub in item.value">{{ sub }}</li>
				</ul> -->
			</div>
			<div class="content" v-else :style="item.fontStyle">
				<img v-if="item.img" :src="item.img" alt="image" />
				{{ item.value }}
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		informationList: {
			type: Array,
			default: () => [],
		},
		containerStyle: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		idOdd() {
			if (this.informationList.length % 2 == 0) {
				return false
			} else {
				return true
			}
		},
	},
	methods: {
		splitText(text) {
			if (!text) return ''
			const splitted = text.split('\n');
			return splitted.map((v) => {
				if (!v) {
					return "ㅤ";
				} else {
					while(v.indexOf(" ") == 0) {
						v = v.replace(/\s/, "ㅤ");
					}
					return v;
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.info-table {
	width: 100%;
	border-top: 1px solid #333;
	margin-top: 15px;
	display: block;
	// display: grid;
	// grid-template-columns: 1fr 1fr;
	&.odd {
		li:last-child {
			width: 100%;
		}
	}
	li {
		display: inline-flex;
		width: 50%;
		min-height: 40px;
		align-items: flex-start;
		border-bottom: 1px solid $LINE_DIVIDER;
		padding: 0;
		font-size: 0.875rem;
		overflow: hidden;
		background-color: $LIST_HOVER;
		box-sizing: border-box;
		&.whole {
			width: 100%;
		}
		.header {
			width: 160px;
			background-color: $LIST_HOVER;
			padding: 10px 12px;
		}
		.content {
			width: calc(100% - 160px);
			padding: 9px 12px;
			background-color: white;
			min-height: 41px;
			// height: 100%;
			img {
				width: 22px;
				height: 22px;
			}
		}
		p {
			padding-left: 16px;
			position: relative;
			&:after {
				width: 4px;
				height: 4px;
				background-color: black;
				border-radius: 50%;
				position: absolute;
				top: calc(50% - 2px);
				left: 0;
				content: '';
			}
			&.one {
				padding-left: 0;
				&:after {
					display: none;
				}
			}
		}
	}
}
</style>
