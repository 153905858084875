<template>
	<div class="payment-privacy-policy">
		<div class="content-wrapper">
			<div>
				<div class="title">개인정보처리방침 (<span class="detail" @click="openPrivacy">보기</span>)</div>
			</div>
			<div>
				<div class="title">이용약관 (<span class="detail" @click="openTerms">보기</span>)</div>
			</div>
			<div>
				<div class="title">유해화학물질 시약 판매관련 안내 (<span class="detail" @click="openSale">보기</span>)</div>
			</div>
			<hr />
			<div>
				<div class="content">
					<div>
						냉장, 냉동 상품, 해외 발주 상품 경우 교환 및 반품이 불가할 수 있습니다. 랩매니저 스토어 고객센터 1533-3061에서 가능 여부
						확인이 필요합니다.
					</div>
				</div>
			</div>
		</div>
		<div class="checkbox-wrapper" :class="{ error: isError }">
			<input type="checkbox" v-model="checked" id="checked" class="common" />
			<label for="checked">{{ checkboxLabel }}</label>
		</div>
		<div v-if="isError" class="error-wrapper">
			<img class="error-image" src="@/assets/svg/icon-login-error.svg" />
			<span class="error-content">구매 조건을 확인 후 동의해주세요</span>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'

export default {
	name: 'PaymentPrivacyPolicy',
	mixins: [mixin],
	props: {
		checkboxLabel: {
			type: String,
			default: '위 결제정보를 확인했으며, 결제를 진행합니다.',
		},
	},
	data() {
		return {
			checked: false,
			isValid: true,
		}
	},
	computed: {
		isError() {
			return !this.isValid
		},
	},
	methods: {
		getChecked() {
			return this.checked
		},
		validate() {
			this.isValid = true
			if (this.checked === false) {
				this.isValid = false
			}
			return this.isValid
		},
		openPrivacy() {
			window.open('https://smartjack.notion.site/d41a74be81124ea3a9fb5f91409d490f')
		},
		openTerms() {
			window.open('https://smartjack.notion.site/15d6730042364ea1a511ab382192d3f7')
		},
		openSale() {
			window.open('https://www.notion.so/smartjack/7f2e15ce7159494392d23cd574229533')
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-privacy-policy {
	.content-wrapper {
		height: 170px;
		background-color: $LIST_BACKGROUND;
		border-radius: 5px;
		padding: 20px;
		font-size: 0.875rem;
		font-weight: 500;

		> div {
			display: flex;
			justify-content: space-between;
			+ div {
				margin-top: 5px;
			}
			.title {
				color: $COLOR_333;
			}
			.detail {
				text-decoration: underline;
				cursor: pointer;
				color: $COLOR_666;
			}
			.content {
				font-size: 0.75rem;
				color: $COLOR_666;
				div + div {
					margin-top: 8px;
				}
			}
		}
		hr {
			border: 1px solid $LINE_DIVIDER;
		}
	}
	.checkbox-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 40px;
		label {
			font-weight: 500;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
		&.error {
			color: $PRIMARY_RED;
			input {
				&:after {
					content: url(~@/assets/svg/checkbox/check-error.svg);
				}
				&:hover {
					&:after {
						content: url(~@/assets/svg/checkbox/check-off-hover.svg);
					}
				}
				&:checked {
					&:after {
						content: url(~@/assets/svg/checkbox/check-on.svg);
					}
				}
			}
		}
	}
	.error-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 17px;
		.error-image {
			width: 16px;
			height: 16px;
		}
		.error-content {
			margin-left: 6px;
			font-size: 0.875rem;
			font-weight: 500;
			color: $PRIMARY_RED;
		}
	}
}
</style>
