<template>
	<ul class="content-notice" :class="statusClass">
		<li v-for="(item, index) in list" :key="`notice-item${index}`" v-html="item"></li>
	</ul>
</template>

<script>
import { Request } from '@/utils/define/ItemCode'

export default {
	name: 'ContentNotice',
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		status: {
			type: String,
			default: '',
		},
	},
	computed: {
		statusClass() {
			let listClass = ''
			if (this.status) {
				listClass = 'notice-status'
				listClass += ` ${Request.statusToClass(this.status)}`
			}
			return listClass
		},
	},
}
</script>

<style lang="scss">
.content-notice {
	width: 100%;
	padding: 20px;
	background-color: $LIST_BACKGROUND;
	border: 1px solid $LIST_BACKGROUND;
	border-radius: 5px;
	&.notice-status {
		background-color: $LIGHT_YELLOW;
		span {
			display: inline-block;
			color: $PRIMARY_YELLOW;
			font-weight: 700;
			margin-left: 3px;
		}
	}
	&.fail {
		border-color: $COLOR_DDD;
		background-color: $LIST_BACKGROUND;
	}
	&.complete {
		background: rgba(231, 249, 241, 0.5);
	}
	li {
		width: 100%;
		display: flex;
		position: relative;
		padding-left: 20px;
		font-size: 0.875rem;
		font-weight: 500;
		color: $COLOR_666;
		&:after {
			width: 4px;
			height: 4px;
			border-radius: 4px;
			background-color: $COLOR_666;
			content: '';
			position: absolute;
			top: calc(50% - 2px);
			left: 8px;
		}
	}
}
</style>
