<template>
	<base-input
		ref="base-input"
		:value="value"
		:placeholder="placeholder"
		:maxlength="maxlength"
		:disabled="disabled"
		:readonly="readonly"
		:autofocus="autofocus"
		:error="!isValid || error"
		:errorMessage="errorMessage"
		:inputStyle="inputStyle"
		@input="$emit('input', $event)"
		@keypress="$emit('keypress', $event)"
		@keydown="$emit('keydown', $event)"
		@keyup="$emit('keyup', $event)"
		@click="$emit('click', $event)"
		@focus="$emit('focus', $event)"
		@blur="$emit('blur', $event)"
	/>
</template>

<script>
import BaseInput from '@/components/input/BaseInput.vue'

export default {
	name: 'EmailInput',
	components: {
		BaseInput,
	},
	extends: BaseInput,
	methods: {
		validate() {
			this.isValid = !!this.value.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)
			return this.isValid
		},
		focus() {
			this.$refs['base-input'].focus()
		},
		blur() {
			this.$refs['base-input'].blur()
		},
	},
}
</script>

<style lang="scss" scoped></style>
