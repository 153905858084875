<template>
	<div class="dark-toast-wrapper">
		<div class="dark-toast-body">
			<p>{{ content }}</p>
			<span v-if="clickText" @click="handleClick">{{ clickText }}</span>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		toastId: {
			type: String,
		},
		content: {
			type: String,
			default: '',
		},
		clickText: {
			type: String,
			default: '',
		},
		clickFunc: {
			type: Function,
			default: () => {},
		},
	},
	methods: {
		handleClick() {
			this.clickFunc()
		},
	},
}
</script>

<style lang="scss" scoped>
$initPosition: -360px;
$showPosition: $initPosition + 390px;

.dark-toast-wrapper {
	position: relative;
	z-index: 99999;
	right: $initPosition;
	animation-name: slide;
	animation-duration: 4s;
	border-radius: 10px;
	background: #333;
	box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.25);
	padding: 9px 12px;
	.dark-toast-body {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		& > p {
			color: #fff;
		}
		& > span {
			color: #fec741;
			text-decoration: underline;
			font-size: 14px;
			font-weight: 700;
			cursor: pointer;
		}
	}
	@keyframes slide {
		from {
			right: $initPosition;
			opacity: 0;
		}
		10% {
			right: $showPosition;
			opacity: 1;
		}
		90% {
			right: $showPosition;
			opacity: 1;
		}
		to {
			right: $initPosition;
			opacity: 0;
		}
	}
}
</style>
