import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/modules/ParamMaker'

const endPoint = {
	lookupProductInfo: paramsObject => `productInfo?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupRecentlyProductInfoList: paramsObject => `productInfo/recentView?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupFrequentlyProductInfoList: (instituteId, paramsObject) =>
		`institute/${instituteId}/productInfo/list/frequently-purchased?${ParamMaker.setParamUsingObject(paramsObject)}`,
}

const productAPI = {
	lookupProductInfo: paramsObject => axiosInstance.get(endPoint.lookupProductInfo(paramsObject)),
	lookupRecentlyProductInfoList: paramsObject => axiosInstance.get(endPoint.lookupRecentlyProductInfoList(paramsObject)),
	lookupFrequentlyProductInfoList: (instituteId, paramsObject) =>
		axiosInstance.get(endPoint.lookupFrequentlyProductInfoList(instituteId, paramsObject)),
}

export default productAPI
