import nopaidAPI from '@/services/api/nopaid'

const nopaid = {
	namespaced: true,
	state: {
		nopaidList: [],
		nopaidPrice: 0,
	},
	mutations: {
		setNopaidList: (state, payload) => {
			state.nopaidList = payload
		},
		setNopaidPrice: (state, payload) => {
			state.nopaidPrice = payload
		},
	},
	actions: {
		lookupNopaidList: async ({ commit }, { instituteId, params }) => {
			const response = await nopaidAPI.lookupNopaidList(instituteId, params)
			commit('setNopaidList', response.data)
		},
		lookupNopaidSummary: async ({ commit }, instituteId) => {
			const response = await nopaidAPI.lookupNopaidSummary(instituteId)
			commit('setNopaidPrice', response.data.noPaidPriceWithVat)
		},
	},
}

export default nopaid
