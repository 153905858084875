<template>
	<div class="content-title">
		<h4>{{ title }}</h4>
		<h6 v-if="subtitle">{{ subtitle }}</h6>
		<span v-if="statusText" class="status" :class="statusClass">{{ statusText }}</span>
		<p v-if="datetime">{{ datetime }}</p>
		<div class="steps" v-if="steps && steps.length > 0">
			<div class="step" v-for="(step, index) in steps" :key="`step_${index}`" :class="{ active: step.value == activeStep }">
				{{ setStep(step.value) }} {{ step.text }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ContentTitle',
	props: {
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
		statusClass: {
			type: String,
			default: '',
		},
		statusText: {
			type: String,
			default: '',
		},
		datetime: {
			type: String,
			default: '',
		},
		steps: {
			type: Array,
			default: () => [],
		},
		activeStep: {
			type: Number,
			default: 0,
		},
	},
	computed: {},
	methods: {
		setStep(num) {
			return `0${num}.`
		},
	},
}
</script>

<style lang="scss" scoped>
.content-title {
	width: 100%;
	display: flex;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid black;
	align-items: flex-end;
	justify-content: flex-start;
	h4 {
		max-width: 440px;
		font-size: 1.5rem;
		font-weight: 700;
		height: 32px;
		@include ellipse;
	}
	h6 {
		margin-left: 8px;
		display: inline-block;
		font-size: 1.5rem;
		font-weight: 700;
		color: $COLOR_999;
	}
	p {
		margin-left: auto;
		display: inline-block;
		font-size: 0.875rem;
		font-weight: normal;
	}
	span {
		font-size: 0.75rem;
		font-weight: 700;
		color: $COLOR_666;
		margin-left: 15px;
		margin-bottom: 6px;
		height: 20px;
		padding: 0 5px;
		display: inline-block;
		border-radius: 5px;
		background-color: $LINE_DIVIDER;
		&.complete {
			background-color: rgba(13, 197, 119, 0.15);
			color: $PRIMARY_GREEN;
		}
		&.fail {
			background-color: #ffeeed;
			color: $PRIMARY_RED;
		}
	}
	.steps {
		margin-left: auto;
		display: inline-flex;
		div {
			font-size: 0.875rem;
			font-weight: 700;
			color: black;
			padding-left: 32px;
			background-image: url(~@/assets/svg/arrow_right.svg);
			background-position: center left 8px;
			opacity: 0.4;
			&:first-child {
				background-image: unset;
			}
			&.active {
				opacity: 1;
			}
		}
	}
}
</style>
