<template>
	<div class="product-list-item" :class="[{ sale: isSale }, { soldOut: item.minUnitPrice === 0 }]">
		<div class="imgbox">
			<img :src="image" />
			<div class="wish-background"></div>
			<input
				v-if="isWish"
				class="wish"
				type="checkbox"
				v-model="item.isWish"
				@change="
					!isHistory && !item.isWishProductInfo
						? changeWish(item.catalogId, item.isWish, 'catalog')
						: changeWish(item.isWishProductInfo ? item.productInfos[0].productInfoId : item.productInfoId, item.isWish, 'productInfo')
				"
			/>
		</div>
		<div class="product-info" style="height: 140px" @click="!isHistory && !item.isWishProductInfo ? goSearchResult() : goProductDetail()">
			<div class="info">
				<h6 class="name" v-html="replaceHighlight(item.name)"></h6>
				<p class="subname" v-if="item.subname" v-html="replaceHighlight(item.subname)"></p>
				<div class="price-wrapper">
					<h5 v-if="item.discountPercent > 0" class="dicount">{{ roundNum(item.discountPercent) }}%</h5>
					<h5 class="price" v-if="item.minSellingUnitPrice">
						<span v-html="replaceHighlight(CommaNum(item.minSellingUnitPrice))"></span>원<span
							v-if="productInfos && productInfos.length > 0"
							>~</span
						>
					</h5>
					<h6 class="original-price" v-if="item.discountPercent">{{ CommaNum(item.minUnitPrice) }}원</h6>
				</div>
			</div>
			<div class="brand-info">
				<p v-if="item.category" @click="goDetail(item.category, 'category')">
					카테고리:
					<span
						v-html="
							replaceHighlight(`${item.category.korName}${item.category.parents.length ? '/' + item.category.parents[0].korName : ''}`)
						"
					></span>
				</p>
				<p v-if="brand && brand.name" @click.stop="goDetail(brand.id, 'brand')">
					브랜드: <span v-html="replaceHighlight(brand.name)"></span>
				</p>
				<p v-if="item.code" @click.stop="goDetail(item.code, 'code')">제품번호: <span v-html="replaceHighlight(item.code)"></span></p>
				<p v-if="item.casno" @click.stop="goDetail(item.casno, 'casno')">CAS No: <span v-html="replaceHighlight(item.casno)"></span></p>
				<p v-if="productOptions.length > 0" class="product-option">
					<span v-html="replaceHighlight(productOptions)"></span>
				</p>
			</div>
			<div class="count-info" v-if="isShowOrderCount">
				<p>{{ item.orderCount }}<span>번 주문</span></p>
			</div>
		</div>
		<div class="price-box" v-if="!isSale">
			<ul v-if="!isHistory">
				<li v-for="(product, index) in productInfos.slice(0, 4)" :key="index" @click="goProductDetail(product.productInfoId)">
					<b>{{ product.seller ? product.seller.name : '판매자' }}</b>
					<img v-if="item.shippingDayPolicy === 'SHAK'" src="@/assets/svg/shackshack-text.svg" />
					<p class="detail-price">{{ CommaNum(product.minSellingUnitPrice) }}원</p>
				</li>
				<button v-if="productInfos.length > 4" @click="!isHistory ? goSearchResult() : goProductDetail()">더보기</button>
			</ul>
			<ul v-else>
				<li>
					<b @click="goSellerInfo(item.seller.id)">{{ item.seller ? item.seller.name : '판매자' }}</b>
					<p class="detail-price">{{ CommaNum(item.minSellingUnitPrice) }}원</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'
import { getBaseURL } from '@/services/api/common'

export default {
	name: 'ProductListItem',
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		isWish: {
			type: Boolean,
			default: true,
		},
		highlightValue: {
			type: String,
			default: '',
		},
		mode: {
			type: String,
			default: '',
		},
	},
	mixins: [mixin],
	computed: {
		image() {
			return this.item.images && this.item.images[0]
				? this.item.images[0].url ?? this.item.images[0]
				: require('@/assets/svg/empty_product.svg')
		},
		isSale() {
			return this.mode == 'sale'
		},
		isHistory() {
			return !this.item.productInfos
		},
		productInfos() {
			return this.item.productInfos
		},
		brand() {
			return this.item.brand
		},
		productOptions() {
			if (this.$_.isEmpty(this.item) || !this.item.categoryDisplayOptions) return []

			let result = ''
			this.item.categoryDisplayOptions.forEach((option, index) => {
				result += (index > 0 ? ' | ' : '') + option.name + ': '
				option.values.forEach((value, innerIndex) => {
					result += (innerIndex > 0 ? ', ' : '') + value
				})
			})
			return result
		},
		isShowOrderCount() {
			return this.$route.path === '/frequentlyOrderList'
		},
	},
	methods: {
		async goSearchResult() {
			if (!this.isSale) {
				this.$emit('searchSuccess', {
					itemType: 'catalog_id',
					itemValue: this.item.catalogId,
				})
				this.windowOpen({
					PageName: PageName.ProductSale,
					RoutePath: RoutePath.ProductSale,
					params: {
						category: 'list',
						id: this.item.catalogId,
					},
				})
			}
		},
		goProductDetail(id) {
			const productInfoId = id ?? (this.item.isWishProductInfo ? this.item.productInfos[0].productInfoId : this.item.productInfoId)
			this.$emit('searchSuccess', {
				itemType: 'product_info_id',
				itemValue: productInfoId,
			})
			this.windowOpen({
				PageName: PageName.Detail,
				RoutePath: RoutePath.Detail,
				params: {
					category: 'list',
					productInfoId: productInfoId,
				},
			})
		},
		goSellerInfo(id) {
			this.$router.push({
				name: PageName.Search,
				params: {
					category: 'seller',
				},
				query: {
					id: id,
				},
			})
		},
		replaceHighlight(value) {
			let regex = new RegExp(this.highlightValue, 'gi')
			return this.highlightValue ? value.replace(regex, match => `<mark class="hightlight">${match}</mark>`) : value
		},
		goDetail(keyword, type) {
			if (type === 'brand') {
				this.$router
					.push({
						name: PageName.Search,
						params: {
							category: type,
						},
						query: {
							id: keyword,
						},
					})
					.catch(() => {})
			} else if (type === 'code') {
				this.$router
					.push({
						name: PageName.SearchList,
						query: {
							keyword,
							type: 'ALL',
						},
					})
					.catch(() => {})
			} else if (type === 'casno') {
				this.$emit('searchSuccess', {
					itemType: 'casno',
					itemValue: keyword,
				})
				this.$router
					.push({
						name: PageName.SearchList,
						query: {
							keyword,
							type: 'CASNO',
						},
					})
					.catch(() => {})
			} else if (type === 'category') {
				this.$router
					.push({
						name: PageName.SearchList,
						query: {
							classification: keyword.parents.length ? keyword.parents[0].code : keyword.code,
							tags: keyword.parents.length ? keyword.parents[0].korName : keyword.korName,
						},
					})
					.catch(() => {})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.product-list-item {
	width: 100%;
	display: flex;
	align-items: flex-start;
	border-bottom: 1px solid $LINE_DIVIDER;
	padding: 20px 0;
	cursor: pointer;
	&:hover {
		.wish-background {
			display: inline-block;
		}
		.wish {
			&:not(:checked):not(:hover):after {
				background-image: url(~@/assets/svg/unchecked-favor-hover.svg);
			}
		}
	}
	&.sale {
		cursor: default;
		padding: 0 0 20px 0;
	}
	&.soldOut {
		position: relative;
		&:after {
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.5);
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			cursor: default;
		}
	}
	.wish-background {
		display: none;
		position: absolute;
		bottom: 8px;
		right: 8px;
		width: 32px;
		height: 32px;
		background: $LIST_BACKGROUND;
		border-radius: 32px;
	}
	.imgbox {
		width: 140px;
		height: 140px;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 4px;
		position: relative;
		overflow: hidden;
		.wish {
			position: absolute;
			bottom: 15px;
			right: 15px;
		}
	}
	.product-info {
		width: calc(100% - 400px);
		.name {
			line-height: 21px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		&:hover {
			.subname,
			.name {
				text-decoration: underline;
			}
		}
		.info {
			max-width: 580px;
			padding-left: 20px;
			h6 {
				font-size: 1rem;
				font-weight: 700;
				margin-bottom: 2px;
			}
			.subname {
				margin-bottom: 5px;
				font-size: 0.75rem;
				font-weight: 400;
				color: $COLOR_666;
			}
			.price-wrapper {
				display: flex;
				.dicount {
					color: $PRIMARY_RED;
					margin-right: 10px;
				}
				.price {
					font-size: 1.25rem;
					font-weight: 700;
					margin-bottom: 15px;
					margin-right: 10px;
				}
				.original-price {
					padding-top: 5px;
					font-weight: 400;
					font-size: 16px;
					color: #666;
					text-decoration-line: line-through;
				}
			}
		}
	}
	.brand-info {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		padding-left: 20px;
		> p {
			cursor: pointer;
			display: inline-block;
			position: relative;
			margin-right: 16px;
			font-size: 0.75rem;
			font-weight: 400;
			color: $COLOR_666;
			&:hover {
				text-decoration: underline;
			}
			&:before {
				position: absolute;
				width: 1px;
				height: 12px;
				background-color: $COLOR_DDD;
				content: '';
				right: -8px;
				top: 3px;
			}
			&:last-child {
				&:before {
					display: none;
				}
			}
		}
		.product-option {
			text-decoration: none !important;
			@include ellipse;
		}
	}
	.count-info {
		width: 100%;
		padding-left: 20px;
		color: #ff8248;
		p {
			font-weight: 700;
			font-size: 14px;
		}
		span {
			font-weight: 900;
			font-size: 11px;
		}
	}
	.price-box {
		width: 260px;
		border-left: 1px solid $LINE_DIVIDER;
		box-sizing: border-box;
		margin-left: auto;
		padding-left: 20px;
		height: 140px;
		position: relative;
		ul {
			width: 100%;
			li {
				font-size: 0.75rem;
				display: flex;
				margin-bottom: 8px;
				b {
					font-weight: 400;
				}
				img {
					padding: 0 0 3px 5px;
				}
				.detail-price {
					margin-left: auto;
				}
				&:first-child {
					b {
						font-weight: 700;
					}
					.detail-price {
						color: $PRIMARY_RED;
						font-weight: 700;
					}
				}
				&:hover {
					text-decoration: underline;
				}
			}
		}
		button {
			position: absolute;
			bottom: -8px;
			left: 20px;
			width: calc(100% - 20px);
			height: 36px;
			border: 1px solid #f0f0f0;
			border-radius: 4px;
			font-size: 0.75rem;
			font-weight: 400;
		}
	}
}
</style>
