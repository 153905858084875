<template>
	<div class="order-item">
		<div class="item-info">
			<div class="image-box">
				<img :src="image" alt="item" />
			</div>
			<div class="content-box">
				<div class="item-seller">[{{ item.seller.name }}]</div>
				<div class="item-name">{{ item.name }}</div>
				<div class="item-sub-info">
					<span>카테고리: {{ categoryValue }}</span>
					<span> 브랜드: {{ item.brand.name }} </span>
					<span>제품번호: {{ item.sku }}</span>
					<span v-if="item.casno">CAS No: {{ item.casno }}</span>
					<span v-for="option of item.options">{{ option.name }}: {{ option.value }}</span>
				</div>
				<div class="item-price">
					<span>{{ price }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'

export default {
	name: 'EstimateProductItem',
	mixins: [mixin],
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		image() {
			return this.item.thumbnailUrl ?? require('@/assets/svg/empty_product.svg')
		},
		categoryValue() {
			let str = ''
			if (this.item.category.parents) {
				this.item.category.parents.forEach(item => {
					str += item.korName.concat('/')
				})
			}
			return str.concat(this.item.category.korName)
		},
		price() {
			return `${this.CommaNum(this.item.unitPrice)}원`
		},
	},
}
</script>

<style lang="scss" scoped>
.order-item {
	display: flex;
	align-items: center;
	gap: 10px;
	width: 550px;
	height: 150px;
	.item-info {
		display: flex;
		align-items: center;
		gap: 16px;
		width: 100%;
		height: 100%;
		padding: 20px 15px;
		.image-box {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 80px;
			height: 80px;
			border: 1px solid $COLOR_EEE;
			border-radius: 4px;
			img {
				width: 80px;
				height: 80px;
			}
		}
		.content-box {
			.item-seller {
				font-size: 0.75rem;
				font-weight: 500;
			}
			.item-name {
				@include ellipse;
				max-width: 440px;
				font-size: 1rem;
				font-weight: 700;
				padding: 2px 0;
			}
			.item-sub-info {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				overflow: hidden;
				max-height: 73px;
				font-size: 0.75rem;
				color: $COLOR_666;
				span + span::before {
					vertical-align: middle;
					margin-left: 6px;
					margin-right: 6px;
					display: inline-block;
					height: 12px;
					content: '';
					border-left: 1px solid #ddd;
				}
			}
			.item-price {
				display: flex;
				align-items: center;
				font-weight: 500;
				font-size: 0.875rem;
				margin-top: 8px;
			}
		}
	}
}
</style>
