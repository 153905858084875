<template>
	<base-management-item>
		<template #info>
			<span v-if="item.isDefault" class="tag">기본 배송지</span>
			<p class="title">{{ item.name }}</p>
			<p class="address">{{ address }}</p>
			<p class="sub">{{ item.instituteName }} / {{ item.groupName }}</p>
		</template>
		<template #button>
			<button class="btn btn-gray sm" @click="$emit('edit', item)">수정</button>
			<button class="btn btn-gray sm" @click="$emit('delete', item.id)">삭제</button>
		</template>
	</base-management-item>
</template>

<script>
import BaseManagementItem from '@/views/my-page/components/BaseManagementItem.vue'

export default {
	name: 'ShippingAddressItem',
	components: {
		BaseManagementItem,
	},
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		address() {
			return `[${this.item.postcode}] ${this.item.address} ${this.item.addressDetail ?? ''}`
		},
	},
}
</script>

<style lang="scss" scoped>
.tag {
	padding: 1px 5px;
	background: $COLOR_EEE;
	border-radius: 5px;
	font-size: 0.75rem;
	font-weight: 700;
}
.title {
	font-size: 1rem;
	font-weight: 700;
}
.address {
	font-size: 0.875rem;
	font-weight: 500;
	color: $COLOR_666;
}
.sub {
	font-size: 0.875rem;
	font-weight: 500;
	color: $COLOR_999;
}
</style>
