<template>
	<div class="search-item-info" v-if="info">
		<div class="imgbox">
			<img :src="image" />
		</div>
		<div class="txtbox">
			<div class="title">
				<h4>{{ info.name }}</h4>
				<input class="wish2" type="checkbox" v-model="info.isWish" @change.stop="changeWish(info.id, info.isWish, mode)" />
			</div>
			<!-- brand or seller 구분 -->
			<div class="description" v-if="mode === 'seller' && info.contactName">
				<span v-if="info.contactName">담당자: {{ info.contactName }}</span>
				<span v-if="info.contactPhone && showPhoneSellerList.includes(info.name)">연락처: {{ info.contactPhone }}</span>
				<span v-if="info.email">이메일: {{ info.email }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'

export default {
	name: 'SearchItemInfo',
	props: {
		info: {
			type: Object,
			default: () => {},
		},
		mode: {
			type: String,
			default: '',
		},
	},
	mixins: [mixin],
	data() {
		return {
			showPhoneSellerList: ['성호씨그마'],
		}
	},
	computed: {
		image() {
			return this.info?.image ? this.info.image : require('@/assets/svg/empty_product.svg')
		},
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.search-item-info {
	width: calc(100% - 300px);
	display: inline-flex;
	align-items: center;
	.imgbox {
		width: 170px;
		height: 60px;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 5px;
		margin-right: 15px;
		overflow: hidden;
		padding: 0 7px;
		img {
			width: 100%;
			object-fit: contain;
		}
	}
	.txtbox {
		.title {
			display: flex;
			align-items: center;
			h4 {
				font-size: 1.25rem;
				font-weight: 700;
				margin-right: 5px;
			}
			input {
				margin-top: 5px;
				margin-left: 10px;
			}
		}
		.description {
			margin-top: 5px;
			span {
				font-size: 0.75rem;
				font-weight: 400;
				display: inline-block;
				color: $COLOR_666;
				margin-right: 16px;
				position: relative;
				&:before {
					width: 1px;
					height: 12px;
					background-color: $COLOR_DDD;
					content: '';
					position: absolute;
					top: 4px;
					right: -8px;
				}
				&:last-child {
					&:before {
						display: none;
					}
				}
			}
		}
	}
}
</style>
