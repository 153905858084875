<template>
	<div class="content-banner">
		<img v-if="leftImg" :src="leftImg" />
		<p v-html="text" :style="{ 'margin-right': hasNopaidPrice ? '' : '100px' }"></p>
		<img v-if="rightImg" :src="rightImg" />
		<button v-if="btnText && hasNopaidPrice" @click="$emit('clickEv')">{{ btnText }}</button>
	</div>
</template>

<script>
export default {
	name: 'ContentBanner',
	props: {
		text: {
			type: String,
			default: '',
		},
		leftImg: {
			type: String,
			default: '',
		},
		rightImg: {
			type: String,
			default: '',
		},
		btnText: {
			type: String,
			default: '',
		},
		hasNopaidPrice: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="scss">
.content-banner {
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $LIGHT_YELLOW;
	border-radius: 12px;
	&.unpayment {
		p {
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 1rem;
			background-image: url(~@/assets/svg/icon/icon-caution.svg);
			background-position: center left 0;
			padding-left: 30px;
			b {
				font-size: 1.25rem;
			}
		}
	}
	p {
		font-size: 0.875rem;
		font-weight: 500;
		margin: 0 30px 0 1rem;
	}
	button {
		display: inline-flex;
		align-items: center;
		font-size: 0.75rem;
		font-weight: 700;
		color: #333;
		cursor: pointer;
		background-image: url(~@/assets/svg/arrow/stroke-arrow.svg);
		background-position: center right 0;
		padding-right: 22px;
	}
}
</style>
