import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/modules/ParamMaker'

const endPoint = {
	lookupFAQList: paramsObject => `faq/list?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupFAQFilter: () => `faq/filter`,
}

const inquiryAPI = {
	lookupFAQList: paramsObject => axiosInstance.get(endPoint.lookupFAQList(paramsObject)),
	lookupFAQFilter: () => axiosInstance.get(endPoint.lookupFAQFilter()),
}

export default inquiryAPI
