import eventAPI from '@/services/api/event'

const eventList = {
	namespaced: true,
	state: {
		eventList: [],
		count: 0,
		event: {},
	},
	mutations: {
		setEventList: (state, payload) => {
			state.eventList = payload
		},
		setCount: (state, payload) => {
			state.count = payload
		},
		setEvent: (state, payload) => {
			state.event = payload
		},
	},
	actions: {
		lookupEventList: async ({ commit }, paramsObject) => {
			const response = await eventAPI.lookupEventList(paramsObject)
			commit('setEventList', response.data.list)
			commit('setCount', response.data.count)
		},
		lookupEvent: async ({ commit }, id) => {
			const response = await eventAPI.lookupEvent(id)
			commit('setEvent', response.data)
		},
	},
}

export default eventList
