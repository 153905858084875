<template>
	<swiper ref="banner" class="banner-container" :pagination="true" :options="swiperOption">
		<swiper-slide class="banner-image" v-for="(banner, index) in banners" :key="`banner_${index}`">
			<img :src="banner.webMainUrl" @click="bannerAction(banner)" />
		</swiper-slide>
		<div class="swiper-pagination" slot="pagination" />
		<div class="prev" slot="button-prev"><img src="@/assets/svg/banner-left-btn.svg" /></div>
		<div class="next" slot="button-next"><img src="@/assets/svg/banner-right-btn.svg" /></div>
	</swiper>
</template>

<script>
import { mapState } from 'vuex'
import PageName from '@/utils/define/PageName'

export default {
	name: 'Banners',
	computed: {
		...mapState('main', ['banners']),
	},
	data() {
		return {
			swiperOption: {
				init: false,
				loop: true,
				slidesPerView: 1,
				autoplay: {
					delay: 5000,
				},
				centeredSlides: true,
				navigation: {
					nextEl: '.next',
					prevEl: '.prev',
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			},
		}
	},
	mounted() {
		setTimeout(() => {
			this.$refs.banner.swiper.init()
		}, 300)
	},
	methods: {
		bannerAction(banner) {
			const { eventId, link } = banner
			if (link) {
				window.open(link)
			} else {
				this.$router.push({
					name: PageName.CsCenter.EventManagementDetail,
					params: {
						id: eventId,
					},
				})
			}
		},
	},
}
</script>

<style lang="scss">
.banner-container {
	width: 100%;
	height: 400px;
	position: relative;
	margin-bottom: 50px;
	.banner-image {
		width: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		cursor: pointer;
		img {
			width: auto;
			height: 100%;
		}
	}
	.prev,
	.next {
		cursor: pointer;
		position: absolute;
		height: 60px;
		width: 36px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 5;
	}
	&:hover .prev,
	&:hover .next {
		display: block;
	}
	.prev {
		left: calc((100% - 1080px) / 3);
	}
	.next {
		right: calc((100% - 1080px) / 3);
	}
	.swiper-pagination-bullet {
		opacity: 1;
	}
	.swiper-pagination > .swiper-pagination-bullet {
		background: #ffedc1 !important;
	}
	.swiper-pagination > .swiper-pagination-bullet-active {
		background: $PRIMARY_YELLOW !important;
	}
}
</style>
