<template>
	<div class="box-wrapper" :class="{ active: category.id === selectedCategoryId }" @click="onClickCategory">
		<img :src="category.image" />
		<p>{{ category.title }}</p>
	</div>
</template>

<script>
export default {
	name: 'CategoryBox',
	props: {
		category: {
			type: Object,
			default: () => {},
		},
		selectedCategoryId: {
			type: Number,
			default: '',
		},
	},
	methods: {
		onClickCategory() {
			this.$emit('onClickCategory', this.category)
		},
	},
}
</script>

<style lang="scss" scoped>
.box-wrapper {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 48px;
	width: 140px;
	border-radius: 10px;
	border: 1px solid $LINE_DIVIDER;
	padding: 0 15px 0 15px;
	p {
		font-weight: 500;
		font-size: 14px;
	}
	&:hover {
		outline: solid 8px #fff5dd;
	}
	&.active {
		background: #333;
		color: $PRIMARY_WHITE;
	}
}
</style>
