<template>
	<header class="cs-center-header-wrapper"></header>
</template>

<script>
import mixins from '@/mixins'

export default {
	name: 'CsCenterHeader',
	mixins: [mixins],
}
</script>

<style lang="scss" scoped>
.cs-center-header-wrapper {
	background: #fef9ec;
	background-image: url(~@/assets/svg/cs_bg.svg);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	width: 100%;
	height: 160px;
}
</style>
