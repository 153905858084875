<template>
	<div class="footer-wrap">
		<section id="footer">
			<div class="wrapper">
				<div class="top-wrapper">
					<img src="@/assets/svg/main/dummy_image_seller.svg" />
					<p @click="onRouteCompany">회사소개</p>
					<p @click="onRouteNotice">공지사항</p>
					<p @click="onRouteFAQ">자주묻는질문</p>
					<p @click="onRouteTerms">이용약관</p>
					<p @click="onRoutePrivacy">개인정보처리방침</p>
					<p @click="onRouteService">고객센터</p>
					<p @click="onRouteEntry">입점제안</p>
				</div>
				<div class="middle-wrapper">
					<div class="contact-info">
						<p>고객센터 ></p>
						<p class="number">1533-3061</p>
					</div>
					<div class="counsel-info">
						<p>상담시간 평일 10:00 ~ 17:00</p>
						<p>Off-time 12:00 ~ 13:00 (토/일/공휴일 휴무)</p>
					</div>
				</div>
				<div class="bottom-wrapper">
					<p>주식회사 스마트 잭 | 대표자 : 김 건 우 | 사업자등록번호: 630-86-00974 | 통신판매업신고: 제 2018-서울성동-0924 호</p>
					<p>팩스 : 02-6280-9045 | 주소 : 서울특별시 성동구 성수일로4가길 10, 3층 | 이메일 : cs@smartjackwp.com</p>
					<p style="padding-top: 10px">COPYRIGHT © 2022 Smartjack Co., Ltd. All rights reserved.</p>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'

export default {
	name: 'Footer',
	methods: {
		onRouteCompany() {
			window.location.href = 'https://smartjackwp.com'
		},
		onRouteNotice() {
			this.$router
				.push({
					name: PageName.CsCenter.NoticeManagement,
				})
				.catch(() => {})
		},
		onRouteFAQ() {
			this.$router
				.push({
					name: PageName.CsCenter.QuestionManagement,
				})
				.catch(() => {})
		},
		onRouteTerms() {
			window.location.href = 'https://smartjack.notion.site/15d6730042364ea1a511ab382192d3f7'
		},
		onRoutePrivacy() {
			window.location.href = 'https://smartjack.notion.site/d41a74be81124ea3a9fb5f91409d490f'
		},
		onRouteService() {
			this.$router
				.push({
					name: PageName.CsCenter.QuestionManagement,
				})
				.catch(() => {})
		},
		onRouteEntry() {
			window.location.href = 'https://forms.gle/tkwZEfcpncDhc1Ww9'
		},
	},
}
</script>

<style lang="scss" scoped>
#footer {
	width: 100%;
	height: 300px;
	align-items: center;
	border-top: solid 1px #f5f5f5;
	.wrapper {
		padding-top: 30px;
		.top-wrapper {
			display: flex;
			align-items: center;
			gap: 30px;
			padding-bottom: 20px;
			p {
				font-weight: 500;
				font-size: 14px;
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.middle-wrapper {
			border-top: solid 1px #f5f5f5;
			padding-top: 20px;
			display: flex;
			.contact-info {
				p {
					font-weight: 700;
					font-size: 12px;
				}
				.number {
					font-size: 16px;
				}
			}
			.counsel-info {
				padding-left: 50px;
				font-weight: 500;
				font-size: 12px;
				color: #999999;
			}
		}
		.bottom-wrapper {
			padding-top: 15px;
			font-weight: 500;
			font-size: 12px;
			color: #999999;
		}
	}
}
</style>
