<template>
	<div class="seller-table">
		<table>
			<thead>
				<th v-for="(header, index) in sellerHeader" :key="`header_${index}`">
					{{ header.name }}
				</th>
			</thead>
			<tbody>
				<table-item
					v-for="(item, index) in list"
					:key="`list_${index}`"
					:headers="sellerHeader"
					:item="item"
					:lowest="lowest"
					:quick="quick"
				/>
			</tbody>
		</table>
	</div>
</template>

<script>
import TableItem from '@/components/table/TableItem.vue'

export default {
	name: 'SellerTable',
	components: {
		TableItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			sellerHeader: [],
			lowest: 0,
			quick: 0,
		}
	},
	watch: {
		list(newVal) {
			if (!newVal || newVal.length <= 0) return

			this.setPoint()
			this.setHeader()
		},
	},
	methods: {
		setPoint() {
			let priceList = []
			let shippingList = []

			this.list.forEach(item => {
				priceList.push(item.sellingUnitPrice)
				shippingList.push(item.shippingDay)
			})

			this.lowest = priceList.reduce((prev, curr) => {
				return prev <= curr ? prev : curr
			})

			this.quick = shippingList.reduce((prev, curr) => {
				return prev <= curr ? prev : curr
			})
		},
		setHeader() {
			this.sellerHeader = [
				{
					name: '판매자',
					value: 'seller',
				},
				{
					name: 'SKU',
					value: 'sku',
				},
				{
					name: '옵션',
					value: 'sellerOption',
				},
				{
					name: '재고',
					value: 'sellerStock',
				},
				{
					name: '가격',
					value: 'price',
				},
				{
					name: '출고정보',
					value: 'sellerShipping',
				},
			]
		},
	},
}
</script>

<style lang="scss" scoped>
.seller-table {
	width: 100%;
	table {
		width: 100%;
		border: 0;
		thead {
			border-top: 1px solid $LINE_DIVIDER;
			border-bottom: 1px solid $LINE_DIVIDER;
			padding: 10px 0;
			box-sizing: initial;
			height: 40px;
			th {
				font-size: 0.875rem;
				font-weight: 700;
				color: $COLOR_666;
				position: relative;
				padding: 0 8px;
				&:before {
					position: absolute;
					width: 1px;
					height: 20px;
					content: '';
					top: 10px;
					right: 0px;
					background-color: $COLOR_DDD;
				}
				&:first-child {
					padding-left: 28px;
					&:after {
						position: absolute;
						width: 1px;
						height: 20px;
						content: '';
						top: 10px;
						left: 20px;
						background-color: $COLOR_DDD;
					}
				}
				&:last-child {
					&:before {
						right: 20px;
					}
				}
			}
		}
	}
}
</style>
