import PageName from '@/utils/define/PageName'
import { isLogin } from '@/services/api/common'

export const menuList = [
	{
		name: '고객센터',
		list: [
			{
				name: '공지사항',
				component: PageName.CsCenter.NoticeManagement,
				active: false,
				disabled: false,
			},
			{
				name: '이벤트',
				component: PageName.CsCenter.EventManagement,
				active: false,
				disabled: false,
			},
			// TODO: 1:1 문의 미구현 주석 처리 by gangmin
			// {
			// 	name: '1:1 문의',
			// 	component: PageName.CsCenter.InquiryManagement,
			// 	active: false,
			// 	disabled: !isLogin(),
			// },
			{
				name: '자주 묻는 질문',
				component: PageName.CsCenter.QuestionManagement,
				active: false,
				disabled: false,
			},
		],
	},
]
