<template>
	<div class="no-product-list">
		<div>
			<img v-if="routePath('search')" src="@/assets/svg/no-search.svg" />
		</div>
		<b>{{ noDataTitle }}</b>
		<p>{{ noDataDescription }}</p>
		<p v-if="isNoDataSearch">{{ noDataDescriptionSub }}</p>
		<button v-if="isNoDataSearch" @click="onClickProposeProduct">상품 요청하기</button>
		<modal-propose-product title="상품 요청하기" @success="proposeProduct" />
	</div>
</template>

<script>
import mixin from '@/mixins'
import { ToastType } from '@/utils/define/ToastType'
import ModalProposeProduct from '@/views/search/modal/ModalProposeProduct.vue'

export default {
	name: 'NoProductList',
	components: {
		ModalProposeProduct,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	mixins: [mixin],
	computed: {
		noDataTitle() {
			return `${this.title}${this.checkBatchimEnding(this.title)} 없습니다.`
		},
		noDataDescription() {
			if (this.routePath('product')) {
				return '물품을 구매해보세요'
			} else if (this.routePath('wish')) {
				return `${this.title}${this.checkConsonantEnding(this.title)} 찜해보세요`
			} else if (this.routePath('cart')) {
				return '원하는 상품을 장바구니에 담아보세요'
			} else {
				return '찾으시는 상품을 요청해주세요.'
			}
		},
		noDataDescriptionSub() {
			return '요청하신 상품을 빠르게 찾아 견적서를 작성해드립니다.'
		},
		isNoDataSearch() {
			return !this.routePath('product') && !this.routePath('wish') && !this.routePath('cart')
		},
	},
	methods: {
		onClickProposeProduct() {
			this.$bvModal.show('ModalProposeProduct')
		},
		proposeProduct() {
			this.$root.toast('상품 요청 성공', '상품 요청을 완료했습니다.', ToastType.SUCCESS)
		},
	},
}
</script>

<style lang="scss" scoped>
.no-product-list {
	width: 100%;
	margin-top: 15px;
	padding-bottom: 40px;
	text-align: center;
	display: block;
	height: 500px;
	border: 1px solid #eeeeee;
	border-radius: 10px;
	img {
		margin: 130px 0 0 0;
		width: 60px;
		height: 60px;
	}
	b {
		font-size: 1rem;
		font-weight: 700;
		line-height: 80px;
	}
	p {
		font-size: 0.875rem;
		font-weight: 400;
	}
	button {
		margin-top: 20px;
		width: 100px;
		height: 36px;
		background: $PRIMARY_YELLOW;
		border-radius: 4px;
		font-weight: 700;
		font-size: 14px;
	}
}
</style>
