import faqAPI from '@/services/api/faq'

const faq = {
	namespaced: true,
	state: {
		faqList: [],
		count: 0,
		filters: [],
	},
	mutations: {
		setFAQList: (state, payload) => {
			state.faqList = payload
		},
		setCount: (state, payload) => {
			state.count = payload
		},
		setFilters: (state, payload) => {
			state.filters = []
			payload.list.forEach(item => {
				state.filters.push({
					...item,
					category: item.code,
				})
			})
		},
	},
	actions: {
		lookupFAQList: async ({ commit }, params) => {
			const response = await faqAPI.lookupFAQList(params)
			commit('setFAQList', response.data.list)
			commit('setCount', response.data.count)
		},
		lookupFAQFilter: async ({ commit }) => {
			const response = await faqAPI.lookupFAQFilter()
			commit('setFilters', response.data)
		},
	},
}

export default faq
