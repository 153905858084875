<template>
	<div class="RequestProductDetail">
		<content-title :title="title" :statusClass="statusClass" :statusText="statusText" :datetime="infoDatetime" />
		<div class="content-wrapper">
			<content-notice :status="requestProduct.status" :list="statusNotice(requestProduct.status)" />
			<simple-info-box title="요청 정보" :list="productRequestInfo" @preview="onPreview" />
			<simple-info-box title="요청자 정보" :list="requesterInfo" />
			<estimate-table v-if="requestProduct.estimate" :list="requestProduct.estimate" />
		</div>

		<modal-image-preview :imageUrl="previewImageUrl" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import mixin from '@/mixins'
import date from '@/mixins/date'
import { Request } from '@/utils/define/ItemCode'
import ContentTitle from '@/components/content/ContentTitle'
import ContentNotice from '@/components/content/ContentNotice'
import SimpleInfoBox from '@/components/list/SimpleInfoBox'
import EstimateTable from '@/components/table/EstimateTable.vue'
import ModalImagePreview from '@/components/modal/ModalImagePreview.vue'

export default {
	name: 'RequestProductDetail',
	components: {
		ContentTitle,
		ContentNotice,
		SimpleInfoBox,
		EstimateTable,
		ModalImagePreview,
	},
	mixins: [mixin, date],
	data() {
		return {
			previewImageUrl: '',
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('requestProduct', ['requestProduct']),
		title() {
			return this.requestProduct?.data?.name
		},
		infoDatetime() {
			return `요청일: ${this.dateToString(this.requestProduct?.createDatetime)}`
		},
		productRequestInfo() {
			//상품요청 정보
			let list = []
			list.push({ name: '상품요청 번호', value: this.requestProduct?.no })
			list.push({ name: '상품요청 일시', value: this.datetimeToString(this.requestProduct?.createDatetime) })
			list.push({ name: '상품명', value: this.requestProduct?.data?.name, all: true })
			list.push({ name: '브랜드', value: this.isData(this.requestProduct?.data?.brandName) })
			list.push({ name: '제품번호', value: this.isData(this.requestProduct?.data?.productCode) })
			list.push({ name: '사이즈(용량)', value: this.requestProduct?.data?.volume })
			list.push({ name: '수량', value: this.isData(this.requestProduct?.data?.quantity) })
			list.push({ name: '추가요청사항', value: this.isData(this.requestProduct?.comment), all: true })
			list.push({ name: '이미지', list: this.requestProduct.attachments, type: 'preview', all: true })

			return list
		},
		requesterInfo() {
			//요청자 정보
			let list = []
			list.push({ name: '소속', value: this.requestProduct?.institute?.name, key: 'groupName' })
			list.push({ name: '이름', value: this.requestProduct?.user?.name, key: 'name' })
			list.push({ name: '연락처', value: this.isData(this.requestProduct?.user?.phone), key: 'phone' })
			list.push({ name: '이메일', value: this.isData(this.requestProduct?.user?.email), key: 'email' })

			return list
		},
		statusClass() {
			return Request.statusToClass(this.requestProduct.status)
		},
		statusText() {
			return Request.statusToString(this.requestProduct.status)
		},
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchRequestProduct()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('requestProduct', ['lookupRequestProduct']),
		async fetchRequestProduct() {
			await this.lookupRequestProduct({
				instituteId: this.instituteId,
				id: this.$route.params.id,
			})
		},
		statusNotice(status) {
			switch (status) {
				case Request.WAIT:
					return ['상품 요청을 <span>접수중</span>입니다. 접수완료 후 상품을 찾아드릴게요']
				case Request.ING:
					return ['<span>상품을 찾는 중<span>입니다. 요청하신 상품을 찾게 되면 알림으로 알려드려요']
				case Request.COMPLETE:
					return ['요청하신 상품을 찾았습니다! <span>견적서를 작성</span>하고 있어요. 조금만 기다려주세요']
				case Request.FAIL:
					return ['요청하신 상품을 찾지 못했어요. 더 나은 랩매니저 스토어가 되겠습니다.']
			}
		},
		onPreview(url) {
			this.previewImageUrl = url
			this.$bvModal.show('ModalImagePreview')
		},
	},
}
</script>

<style lang="scss" scoped>
.RequestProductDetail {
	.content-wrapper {
		> div {
			margin-bottom: 64px;
		}
		.content-notice {
			margin-bottom: 24px;
		}
	}
}
</style>
