import inquiryAPI from '@/services/api/inquiry'

const inquiryList = {
	namespaced: true,
	state: {
		inquiryList: [],
		count: 0,
		inquiryTypes: [],
		inquiry: {},
	},
	mutations: {
		setInquiryList: (state, payload) => {
			state.inquiryList = payload
		},
		setCount: (state, payload) => {
			state.count = payload
		},
		setInquiryTypes: (state, payload) => {
			state.inquiryTypes = payload
		},
		setInquiry: (state, payload) => {
			state.inquiry = payload
		},
	},
	actions: {
		lookupInquiryList: async ({ commit }, { instituteId, params }) => {
			const response = await inquiryAPI.lookupInquiryList(instituteId, params)
			commit('setInquiryList', response.data.list)
			commit('setCount', response.data.count)
		},
		lookupInquiryType: async ({ commit }, instituteId) => {
			const response = await inquiryAPI.lookupInquiryType(instituteId)
			commit('setInquiryTypes', response.data)
		},
		lookupInquiry: async ({ commit }, { instituteId, id }) => {
			const response = await inquiryAPI.lookupInquiry(instituteId, id)
			commit('setInquiry', response.data)
		},
		addInquiry: async (_, { instituteId, formData }) => {
			return await inquiryAPI.addInquiry(instituteId, formData)
		},
	},
}

export default inquiryList
