<template>
	<div class="status-counter-item" :class="{ active }" @click="onActive">
		<span class="item-title">{{ title }}</span>
		<span class="item-count">{{ count }}</span>
	</div>
</template>

<script>
export default {
	name: 'StatusCounterItem',
	props: {
		id: {
			type: [String, Number],
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		count: {
			type: [String, Number],
			required: true,
		},
		active: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		if (this.active) this.onActive()
	},
	methods: {
		onActive() {
			this.$emit('active', this.id)
		},
	},
}
</script>

<style lang="scss" scoped>
.status-counter-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 120px;
	height: 100px;
	background: $HOVER_BACKGROUND;
	border-radius: 16px;
	cursor: pointer;
	span {
		color: $COLOR_666;
	}
	.item-title {
		font-weight: 500;
		font-size: 0.875rem;
		color: $COLOR_999;
	}
	.item-count {
		font-weight: 400;
		font-size: 2rem;
		color: $COLOR_999;
	}
	&.active {
		background: $PRIMARY_BLACK;
		span {
			color: $PRIMARY_WHITE;
		}
		.item-count {
			font-weight: bold;
		}
	}
}
</style>
