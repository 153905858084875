<template>
	<div>
		<base-management title="비밀번호 변경">
			<div class="change-password-complete-wrapper">
				<div class="content">
					<p class="title">새로운 비밀번호로 변경이 완료되었습니다</p>
					<p class="description">로그인 되어 있는 다른기기를 모두 로그아웃 할까요?</p>
					<p class="description">
						비밀번호를 변경한 후 기존 비밀번호로 로그인 되어 있던 다른 기기와<br />
						브라우저를 모두 로그아웃하거나 새로운 비밀번호로 로그인 상태를 <br />
						유지할 수 있습니다
					</p>
					<div class="change-password-complete-footer">
						<button class="btn btn-gray sm" @click="onClickMaintain">로그인 상태 유지</button>
						<button class="btn btn-yellow sm" @click="onClickLogoutAll">모두 로그아웃</button>
					</div>
				</div>
				<div class="icon">
					<img src="@/assets/svg/check-list.svg" />
				</div>
			</div>
		</base-management>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import { mapActions } from 'vuex'

export default {
	name: 'ChangePasswordComplete',
	components: {
		BaseManagement,
	},
	methods: {
		...mapActions('user', ['logout']),
		onClickMaintain() {
			this.$router.push({
				name: PageName.MyPage.UserInfo,
			})
		},
		async onClickLogoutAll() {
			await this.logout()
			this.$router.push({
				name: PageName.Main,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.change-password-complete-wrapper {
	display: flex;
	justify-content: space-between;
	border: 1px solid $COLOR_DDD;
	border-radius: 5px;
	padding: 40px;
	.content {
		display: flex;
		flex-direction: column;
		gap: 24px;
		.title {
			font-size: 1.25rem;
			font-weight: 700;
		}
		.description {
			font-size: 1rem;
			font-weight: 500;
			color: $COLOR_666;
		}
	}
	.icon {
		align-self: flex-end;
	}
	.change-password-complete-footer {
		display: flex;
		justify-content: flex-start;
		gap: 24px;
		margin-top: 16px;
		.btn {
			font-size: 0.75rem;
			font-weight: 700;
			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #f2f3f4;
				color: black;
			}
		}
		.btn-yellow {
			background: #fec741;
			&:hover {
				background: #fec741 linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
				color: black;
			}
		}
	}
}
</style>
