<template>
	<base-management title="취소 / 반품 / 교환">
		<detail-search-box
			ref="detailSearchBox"
			class="search-box"
			title="상세검색"
			subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
			searchPlaceholder="상품명, 브랜드, 판매자 검색"
			:datePlaceholder="datePlaceholder"
			@searchTextList="searchTextList"
		/>
		<return-history-list ref="returnHistoryList" class="history-list" :searchKeyword="keyword" @page="getCurrentPage" />
	</base-management>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import DetailSearchBox from '@/views/my-page/order-delivery/order/components/search/DetailSearchBox.vue'
import ReturnHistoryList from '@/views/my-page/order-delivery/return/components/ReturnHistoryList.vue'

export default {
	name: 'Return',
	components: {
		BaseManagement,
		DetailSearchBox,
		ReturnHistoryList,
	},
	data() {
		return {
			searchKeyword: '',
			searchDate: [],
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapGetters('cs', ['keyword', 'currentPage']),
		...mapState('cs', ['params']),
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				this.clearSearchText()

				await this.fetchCsList()
				this.$refs.returnHistoryList.setPage(this.currentPage)
			},
			immediate: true,
		},
	},
	mounted() {
		if (this.params.keyword) this.$refs.detailSearchBox.changeKeyword(this.params.keyword)
		if (this.params.orderStartDatetime && this.params.orderEndDatetime)
			this.$refs.detailSearchBox.changeDate([this.params.orderStartDatetime, this.params.orderEndDatetime])
	},
	methods: {
		...mapActions('cs', ['lookupCsList']),
		...mapMutations('cs', ['setParamsText', 'setParamsPaging']),
		async fetchCsList() {
			const params = {
				offset: this.params.offset,
				length: this.params.length,
			}
			if (this.params.keyword) params.keyword = this.params.keyword
			if (this.params.orderStartDatetime) params.orderStartDatetime = this.params.orderStartDatetime
			if (this.params.orderEndDatetime) params.orderEndDatetime = this.params.orderEndDatetime

			await this.lookupCsList({
				instituteId: this.instituteId,
				params,
			})
		},
		async searchTextList(filter) {
			if (this.$_.isEmpty(filter)) {
				this.clearSearchText()
			} else {
				Object.entries(filter).forEach(([key, value]) => {
					if (key === '검색') {
						this.searchKeyword = value.length > 0 ? value[0].value : ''
						this.setParamsText({
							keyword: this.searchKeyword,
							orderStartDatetime: this.params.orderStartDatetime,
							orderEndDatetime: this.params.orderEndDatetime,
						})
					} else if (key === '기간 조회') {
						this.searchDate = value.length > 0 ? value[0].value : []
						this.setParamsText({
							keyword: this.params.keyword,
							orderStartDatetime: this.searchDate.length > 0 ? this.searchDate[0] : '',
							orderEndDatetime: this.searchDate.length > 0 ? this.searchDate[1] : '',
						})
					}
				})
			}
			await this.fetchCsList()
		},
		clearSearchText() {
			this.searchKeyword = ''
			this.searchDate = []
			this.setParamsText({ keyword: '', orderStartDatetime: '', orderEndDatetime: '' })
		},
		async getCurrentPage({ offset, length }) {
			this.setParamsPaging({ offset, length })
			await this.fetchCsList()

			window.scrollTo(0, document.querySelector('.return-history-list').offsetTop)
		},
	},
}
</script>

<style lang="scss" scoped>
.search-box {
	margin-bottom: 64px;
}
.history-list {
	margin-bottom: 64px;
}
</style>
