import cookies from 'vue-cookies'

const _baseAccountURL = process.env.VUE_APP_BASE_ACCOUNT_URL
const _baseURLExceptHttp = process.env.VUE_APP_BASE_URL_EXCEPT_HTTP
const _BaseURLSystem = process.env.VUE_APP_BASE_SYS_URL

const COOKIES_DOMAIN = location.hostname.includes(_baseURLExceptHttp) ? _baseURLExceptHttp : location.hostname

const TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY
const LAB_KEY = process.env.VUE_APP_LAB_KEY
// const FILTER_KEY = process.env.VUE_APP_FILTER_KEY;
// const STORAGE_ID_KEY = process.env.VUE_APP_STORAGE_ID_KEY;
// const STORAGE_NAME_KEY = process.env.VUE_APP_STORAGE_NAME_KEY;
const SERVICE_URL = process.env.VUE_APP_SERVICE_URL

cookies.config('365d', null, COOKIES_DOMAIN)

const isLogin = () => {
	return !cookies.isKey(TOKEN_KEY) ? false : true
}

const getAccountUrl = () => {
	return _baseAccountURL
}

const getServiceUrl = () => {
	return SERVICE_URL
}

const getSYSUrl = () => {
	return _BaseURLSystem
}

const setToken = token => {
	cookies.set(TOKEN_KEY, token, '365d', null, COOKIES_DOMAIN)
}

const getToken = () => {
	const path = location.pathname.slice(0, location.pathname.lastIndexOf('/'))
	if (path.length > 0 && path != '/') cookies.remove(TOKEN_KEY, path, COOKIES_DOMAIN)
	return cookies.get(TOKEN_KEY)
}

const logout = async () => {
	deleteLocalStorage()
	cookies.remove(TOKEN_KEY, null, COOKIES_DOMAIN)
	cookies.remove(TOKEN_KEY)
}
const deleteLocalStorage = () => {
	localStorage.removeItem('isNotSeeAgain')
}

const deleteAllCookies = () => {
	cookies.keys().forEach(key => {
		cookies.remove(key)
	})
	cookies.remove(TOKEN_KEY, null, COOKIES_DOMAIN)
	cookies.remove(LAB_KEY, null, COOKIES_DOMAIN)
}

const goToSSOMain = url => {
	location.href = `${_baseAccountURL}/lms/SignIn?redirect=${url ? encodeURIComponent(url) : window.location.origin}`
}

const goToSSOEmail = () => {
	location.href = `${_baseAccountURL}/pro/EmailAuth?redirect=${window.location.origin}&pro=true&token=${getToken()}`
}

const loginCheck = (from, to, next) => {
	if (isLogin()) {
		next()
	} else {
		// 중외몰 로그인 시스템으로 이동
		location.href = `${_baseAccountURL}/SignIn?redirect=${window.location.origin}&pro=true`
	}
}

export {
	isLogin,
	getAccountUrl,
	setToken,
	getToken,
	logout,
	deleteLocalStorage,
	deleteAllCookies,
	getServiceUrl,
	goToSSOMain,
	goToSSOEmail,
	COOKIES_DOMAIN,
	loginCheck,
	getSYSUrl,
}
