import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/modules/ParamMaker'

const endPoint = {
	lookupNopaidList: (instituteId, paramsObject) =>
		`institute/${instituteId}/order/item/nopaid/list?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupNopaidSummary: instituteId => `institute/${instituteId}/order/item/nopaid/summary`,
}

const nopaidAPI = {
	lookupNopaidList: (instituteId, paramsObject) => axiosInstance.get(endPoint.lookupNopaidList(instituteId, paramsObject)),
	lookupNopaidSummary: instituteId => axiosInstance.get(endPoint.lookupNopaidSummary(instituteId)),
}

export default nopaidAPI
