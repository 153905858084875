import axiosInstance from '@/services/axios'

const endPoint = {
	lookupWishBrandList: () => `wish/list/brand`,
	lookupWishSellerList: () => `wish/list/seller`,
	lookupWishCatalogList: (offset, length) => `wish/list/catalog?offset=${offset}&length=${length}`,
	updateWish: type => `wish/${type}`,
}

const wishAPI = {
	lookupWishBrandList: () => axiosInstance.get(endPoint.lookupWishBrandList()),
	lookupWishSellerList: () => axiosInstance.get(endPoint.lookupWishSellerList()),
	lookupWishCatalogList: (offset, length) => axiosInstance.get(endPoint.lookupWishCatalogList(offset, length)),
	updateWish: (type, body) => axiosInstance.post(endPoint.updateWish(type), body),
}

export default wishAPI
