<template>
	<search-row class="search-row" :title="title">
		<span v-if="text">{{ text }}</span>
		<jw-date-picker
			ref="datePicker"
			:datePlaceholder="datePlaceholder"
			:datePickerStyle="{ width: '230px' }"
			@changeDate="$emit('change', $event)"
		/>
	</search-row>
</template>

<script>
import SearchRow from '@/views/my-page/order-delivery/order/components/search/SearchRow.vue'
import JwDatePicker from '@/components/search/JwDatePicker.vue'

export default {
	name: 'SearchDate',
	components: {
		SearchRow,
		JwDatePicker,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		datePlaceholder: {
			type: String,
			default: '',
		},
		text: {
			type: String,
			default: '',
		},
	},
	methods: {
		reset() {
			this.$refs.datePicker.reset()
		},
		changeDate(date) {
			this.$refs.datePicker.changeDate(date)
		},
	},
}
</script>

<style lang="scss" scoped>
.search-row {
	span {
		width: 70px;
	}
}
</style>
