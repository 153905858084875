import noticeAPI from '@/services/api/notice'

const noticeList = {
	namespaced: true,
	state: {
		noticeList: [],
		count: 0,
		notice: {},
	},
	mutations: {
		setNoticeList: (state, payload) => {
			state.noticeList = payload
		},
		setCount: (state, payload) => {
			state.count = payload
		},
		setNotice: (state, payload) => {
			state.notice = payload
		},
	},
	actions: {
		lookupNoticeList: async ({ commit }, paramsObject) => {
			const response = await noticeAPI.lookupNoticeList(paramsObject)
			commit('setNoticeList', response.data.list)
			commit('setCount', response.data.count)
		},
		lookupNotice: async ({ commit }, id) => {
			const response = await noticeAPI.lookupNotice(id)
			commit('setNotice', response.data)
		},
	},
}

export default noticeList
