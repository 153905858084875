<template>
	<base-management title="최근 본 상품" subTitleName="최근 본 상품" subTitleContent="최근 본 상품은 6개월 이내에 조회한 상품만 노출됩니다">
		<div class="recent-view-wrapper">
			<template v-if="list.length > 0">
				<product-list class="boxwrap" :list="list" />
			</template>
			<template v-else>
				<div class="empty-list">
					<img src="@/assets/svg/empty-recent.svg" />
					<p>6개월 이내에 조회한 상품이 없습니다</p>
				</div>
			</template>
		</div>
	</base-management>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import cookies from 'vue-cookies'
import MixinScrolling from '@/mixins/scrolling'
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import ProductList from '@/components/list/ProductList.vue'

export default {
	name: 'RecentView',
	components: {
		BaseManagement,
		ProductList,
	},
	mixins: [MixinScrolling],
	data() {
		return {
			offset: 0,
			length: 10,
			list: [],
		}
	},
	computed: {
		...mapState('productInfo', ['recentlyProductInfoList']),
		...mapGetters('user', ['instituteId']),
		recentlyViewProductIds() {
			return cookies
				.get('recentlyViewProducts')
				.split(',')
				.filter(id => id)
				.reverse()
		},
	},
	watch: {
		isBottom(val) {
			if (val === true) {
				const offset = this.offset + this.length
				if (this.recentlyViewProductIds.length < offset) return

				this.offset = offset
				this.fetchRecentlyViewProducts()
			}
		},
	},
	mounted() {
		this.fetchRecentlyViewProducts()
	},
	methods: {
		...mapActions('productInfo', ['lookupRecentlyProductInfoList']),
		async fetchRecentlyViewProducts() {
			if (!cookies.get('recentlyViewProducts')) return

			const searchIds = this.recentlyViewProductIds.slice(this.offset, this.offset + this.length)
			if (searchIds.length) {
				await this.lookupRecentlyProductInfoList({
					paramsObject: { ids: searchIds, offset: 0, length: this.length, instituteId: this.instituteId },
				})

				if (this.recentlyProductInfoList.length > 0) {
					this.list = [...this.list, ...this.recentlyProductInfoList]
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.recent-view-wrapper {
	margin-bottom: 64px;
	.empty-list {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-items: center;
		gap: 24px;
		margin-top: 64px;
		> p {
			font-size: 1rem;
			font-weight: 500;
			color: $COLOR_999;
		}
	}
}
</style>
