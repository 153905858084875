<template>
	<b-modal id="ModalTaxBillPreview" centered size="lg" title="전자세금계산서 미리보기">
		<div class="tax-bill-preview-wrapper">
			<div>노란색 영역에 등록한 사업자등록증(결제처)의 정보가 기입됩니다.</div>
			<img src="@/assets/images/tax-bill-preview.png" />
		</div>
		<template #modal-footer>
			<div></div>
		</template>
	</b-modal>
</template>

<script>
export default {
	name: 'ModalTaxBillPreview',
}
</script>

<style lang="scss">
#ModalTaxBillPreview___BV_modal_outer_ {
	#ModalTaxBillPreview___BV_modal_header_ {
		&.modal-header {
			border-bottom: 1px solid $COLOR_EEE;
		}
	}
}
</style>

<style lang="scss" scoped>
.tax-bill-preview-wrapper {
	text-align: center;
	> div {
		padding: 9px;
		background: #fef9ec;
		border-radius: 5px;
		font-size: 0.875rem;
		font-weight: 500;
		margin-bottom: 20px;
	}
	> img {
		width: 760px;
		height: 520px;
	}
}
</style>
