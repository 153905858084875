<template>
	<base-management title="이벤트">
		<div class="event-management-detail-wrapper">
			<div class="event-management-detail-header">
				<h4>{{ event.title }}</h4>
				<span>{{ formatDate(event.startDatetime) }}</span>
			</div>
			<div class="event-management-detail-content">
				<div v-if="event.html" v-html="html"></div>
				<div v-else>{{ event.content }}</div>
			</div>
			<div class="event-management-detail-footer">
				<button class="btn btn-gray lg" @click="goList">목록 보기</button>
			</div>
		</div>
	</base-management>
</template>

<script>
import PageName from '@/utils/define/PageName'
import { mapActions, mapState } from 'vuex'
import mixin from '@/mixins/date'
import BaseManagement from '@/views/cs-center/components/BaseManagement.vue'

export default {
	name: 'EventManagementDetail',
	components: {
		BaseManagement,
	},
	mixins: [mixin],
	computed: {
		...mapState('event', ['event']),
		html() {
			return typeof this.event.html == 'string' ? this.event.html.replace(/\<p\>\<\/p\>/g, '<br>') : this.event.html
		},
	},
	created() {
		const hash = window.location.hash
		if (hash === '#scroll') {
			setTimeout(() => {
				window.scrollTo({
					top: 500,
					left: 0,
					behavior: 'smooth',
				})
			}, 1000)
		}
	},
	mounted() {
		this.fetchEvent()
	},
	beforeDestroy() {
		document.title = '요즘 랩실, 여기서 산다 | 랩매니저 스토어'
	},
	methods: {
		...mapActions('event', ['lookupEvent']),
		async fetchEvent() {
			await this.lookupEvent(this.$route.params.id)
			document.title = this.event.title
		},
		goList() {
			this.$router.push({
				name: PageName.CsCenter.EventManagement,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.event-management-detail-wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	.event-management-detail-header {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding-bottom: 16px;
		border-bottom: 1px solid $COLOR_EEE;
		> span {
			font-weight: 500;
			font-size: 0.875rem;
		}
	}
	.event-management-detail-content {
		display: flex;
		flex-direction: column;
		gap: 40px;
		padding-bottom: 40px;
		border-bottom: 1px solid $COLOR_EEE;
	}
	.event-management-detail-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 40px 0;
		.btn {
			width: 240px;
			height: 48px;
		}
	}
}
</style>
