<template>
	<div class="notification-wrapper" ref="scrollContainer" @scroll="handleScroll">
		<div class="notification-header">
			<span>알림</span>
			<div class="switch-wrapper">
				<p>읽지 않은 알림만 보기</p>
				<label class="switch">
					<input type="checkbox" :checked="loadParams.unreadOnly" @change="checkChange" />
					<span class="slider round"></span>
				</label>
				<img src="@/assets/svg/double-check.svg" @click="handleReadAll" />
			</div>
		</div>
		<ul v-if="count">
			<li v-for="noti in list" :key="noti.id" @click="handleRead(noti.id, noti.isNew, noti.type, noti.arg, noti.instituteId)">
				<img :src="notiImage(noti.type)" />
				<div class="content-wrap">
					<div class="title">
						<h6>{{ noti.title }}</h6>
						<span>{{ datetimeToString(noti.createDatetime) }}</span>
					</div>
					<p v-html="noti.contents"></p>
				</div>
				<div v-if="noti.isNew" class="red-dot"></div>
			</li>
		</ul>
		<div v-else class="empty-wrap">
			<p>새로운 알림이 없습니다.</p>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import date from '@/mixins/date'
// import MixinScrolling from '@/mixins/scrolling'
import throttle from 'lodash/throttle'
import PageName from '@/utils/define/PageName'

export default {
	name: 'NotificationList',
	components: {},
	props: {},
	mixins: [date],
	data() {
		return {
			list: [],
		}
	},
	computed: {
		...mapState('notification', ['notificationList', 'count', 'loadParams']),
		...mapState('user', ['institute']),
	},
	async mounted() {
		await this.setLoadParams({
			offset: 0,
			length: 10,
			unreadOnly: false,
		})
		this.list = [...this.notificationList]
	},
	methods: {
		...mapMutations('notification', ['setNotificationList']),
		...mapActions('notification', ['setLoadParams', 'notificationRead', 'notificationReadAll']),
		...mapActions('user', ['getUserInfo']),
		async checkChange() {
			this.$refs['scrollContainer'].scrollTo(0, 0)
			await this.setLoadParams({
				offset: 0,
				length: 10,
				unreadOnly: !this.loadParams.unreadOnly,
			})
			this.list = [...this.notificationList]
		},
		handleScroll: throttle(async function () {
			const scrollContainer = this.$refs.scrollContainer
			if (
				this.list.length > 0 &&
				this.list.length < this.count &&
				scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight
			) {
				await this.setLoadParams({
					offset: this.loadParams.offset + this.loadParams.length,
				})
				this.list = [...this.list, ...this.notificationList]
			}
		}, 50),
		notiImage(type) {
			switch (type) {
				case 'WELCOME':
					return require('@/assets/svg/icon/icon-signin.svg')
				case 'CARD_NEW':
				case 'ORDER_CARD_SUCCESS':
				case 'ORDER_CARD_CANCEL':
				case 'CARD_PAID':
				case 'PAYMENT_SUCCESS':
				case 'EBILL_DELAY':
				case 'EBILL_PAID':
				case 'EBILL_CANCEL':
				case 'LIMIT_SOON':
				case 'LIMIT_OVER':
					return require('@/assets/svg/icon/icon-card.svg')
				case 'ORDER_APPROVE':
				case 'ORDER_SUCCESS':
				case 'ORDER_CANCEL':
				case 'ORDER_REJECT':
				case 'ORDER_SHIPPING':
				case 'ORDER_SHIPPED':
				case 'ORDER_DELAY':
					return require('@/assets/svg/icon/icon-cart.svg')
				case 'BR_APPROVE':
				case 'BR_REJECT':
					return require('@/assets/svg/icon/icon-registration.svg')
				case 'RQ_AGENT_DONE':
				case 'RQ_NORMAL_DONE':
				case 'RQ_FAIL':
					return require('@/assets/svg/icon/icon-sadadream.svg')
			}
		},
		async handleRead(id, isNew, type, arg, instituteId) {
			if (!id) return
			if (isNew) {
				await this.notificationRead({ id: id })
			}
			switch (type) {
				case 'ORDER_CARD_SUCCESS':
				case 'ORDER_CARD_CANCEL':
				case 'ORDER_SUCCESS':
				case 'ORDER_CANCEL':
				case 'ORDER_REJECT':
				case 'ORDER_SHIPPING':
				case 'ORDER_SHIPPED':
				case 'ORDER_DELAY':
					this.onRouteOrderDetail(arg, instituteId)
					break
				case 'CARD_PAID':
				case 'EBILL_DELAY':
				case 'EBILL_PAID':
					this.onRoutePaymentDetail(arg, instituteId)
					break
				case 'LIMIT_SOON':
					this.onRouteUnPayment(instituteId)
					break
				case 'BR_APPROVE':
					this.onRouteMain(instituteId)
					break
				case 'BR_REJECT':
					this.onRouteBusinessRegistration(instituteId)
					break
				case 'RQ_AGENT_DONE':
				case 'RQ_NORMAL_DONE':
				case 'RQ_FAIL':
					this.onRouteSadadream(instituteId)
					break
				default:
					break
				// PAYMENT_SUCCESS
				// EBILL_CANCEL
				// ORDER_APPROVE
			}
			this.$emit('hide')
			return
		},
		async handleReadAll() {
			await this.notificationReadAll()
			this.list = [...this.notificationList]
			await this.getUserInfo()
		},
		onRouteOrderDetail(id, instituteId) {
			if (this.institute.id !== instituteId) location.href = `/mypage/order/${id}?instituteId=${instituteId}`
			else {
				this.$router
					.push({
						name: PageName.MyPage.OrderDetail,
						params: {
							id: id,
						},
					})
					.catch(() => {})
			}
		},
		onRoutePaymentDetail(id, instituteId) {
			if (this.institute.id !== instituteId) location.href = `/mypage/payment/${id}?instituteId=${instituteId}`
			else {
				this.$router
					.push({
						name: PageName.MyPage.PaymentHistoryDetail,
						params: {
							id: id,
						},
					})
					.catch(() => {})
			}
		},
		onRouteUnPayment(instituteId) {
			if (this.institute.id !== instituteId) location.href = `/mypage/unpayment?instituteId=${instituteId}`
			else {
				this.$router
					.push({
						name: PageName.MyPage.UnPaymentManagement,
					})
					.catch(() => {})
			}
		},
		onRouteMain(instituteId) {
			if (this.institute.id !== instituteId) location.href = `?instituteId=${instituteId}`
			else {
				this.$router
					.push({
						name: PageName.Main,
					})
					.catch(() => {})
			}
		},
		onRouteBusinessRegistration(instituteId) {
			if (this.institute.id !== instituteId) location.href = `/mypage/businessregistration?instituteId=${instituteId}`
			else {
				this.$router
					.push({
						name: PageName.MyPage.BusinessRegistrationManagement,
					})
					.catch(() => {})
			}
		},
		onRouteSadadream(instituteId) {
			if (this.institute.id !== instituteId) location.href = `/sadadream?instituteId=${instituteId}`
			else {
				this.$router
					.push({
						name: PageName.Sadadream.Main,
					})
					.catch(() => {})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.notification-wrapper::-webkit-scrollbar {
	display: none;
}
.notification-wrapper {
	border-radius: 20px;
	box-shadow: 1px 2px 16px 0px rgba(0, 0, 0, 0.1);
	position: absolute;
	width: 500px;
	max-height: 830px;
	top: 55px;
	right: 0;
	background-color: #fff;
	z-index: 1000;
	overflow: auto;
	.notification-header {
		height: 60px;
		border-bottom: 1px solid #eee;
		padding: 12px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: sticky;
		top: 0;
		z-index: 1001;
		background-color: #fff;
		& > span {
			font-size: 16px;
			font-weight: 600;
		}
		.switch-wrapper {
			display: flex;
			gap: 10px;
			justify-content: center;
			align-items: center;
			img {
				cursor: pointer;
			}
		}
	}
	ul {
		li {
			cursor: pointer;
			display: flex;
			gap: 10px;
			padding: 16px;
			align-items: center;
			.content-wrap {
				.title {
					display: flex;
					gap: 10px;
					h6 {
						font-size: 14px;
						font-weight: 600;
					}
					span {
						font-size: 12px;
						font-weight: 500;
						color: #999;
					}
				}
				& > p {
					width: 380px;
				}
			}
			&:hover {
				background-color: #f9f9f9;
			}
			.red-dot {
				background-color: #ff3126;
				width: 6px;
				height: 6px;
				border-radius: 6px;
				margin-left: auto;
			}
		}
	}
	.empty-wrap {
		height: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>
