<template>
	<nav class="my-page-nav-wrapper">
		<div class="my-page-nav-title">MY PAGE</div>
		<div class="item-wrapper">
			<div v-for="(menu, index) of menuList" :key="menu.name" class="my-page-menu-item">
				<div class="menu-title">{{ menu.name }}</div>
				<div v-for="item of menu.list" :key="item.name" class="menu-nav" :class="{ active: item.active }" @click="onClickNav(item)">
					{{ item.name }}
				</div>
				<hr v-if="index < menuList.length - 1" />
			</div>
		</div>
		<div class="service-center-info">
			<p class="info-name">{{ serviceCenterInfo.name }}</p>
			<p class="info-tel">{{ serviceCenterInfo.tel }}</p>
			<p class="info-time" v-html="serviceCenterInfo.time"></p>
		</div>
	</nav>
</template>

<script>
import PageName from '@/utils/define/PageName'
import { menuList } from '@/utils/define/my-page'

export default {
	name: 'MyPageNav',
	data() {
		return {
			serviceCenterInfo: {
				name: '고객센터',
				tel: '1533-3061',
				time: `<b">상담시간</b><br />평일 10:00 ~ 17:00<br />Off-time 12:00 ~ 13:00<br />(토/일/공휴일 휴무)`,
			},
			menuList,
		}
	},
	watch: {
		$route: {
			handler() {
				this.$nextTick(() => this.routeActiveMenu())
			},
			immediate: true,
		},
	},
	methods: {
		routeActiveMenu() {
			let activeMenu = null
			this.menuList.forEach(menu => {
				const findMenu = menu.list.find(item => item.component === this.$route.name)
				if (findMenu) activeMenu = findMenu
			})
			if (activeMenu) this.onClickNav(activeMenu)
		},
		onClickNav(menu) {
			this.menuList.forEach(menu => menu.list.forEach(item => (item.active = false)))
			menu.active = true
			this.$emit('active', menu)
		},
	},
}
</script>

<style lang="scss" scoped>
.my-page-nav-wrapper {
	.my-page-nav-title {
		font-size: 1.5rem;
		font-weight: 700;
		padding-bottom: 1rem;
		border-bottom: 1px solid $PRIMARY_BLACK;
	}
	.item-wrapper {
		margin: 1rem 0;
		border-bottom: 1px solid $PRIMARY_BLACK;
		.my-page-menu-item {
			&:last-child {
				.menu-nav:last-child {
					margin-bottom: 1rem;
				}
			}
			.menu-title {
				font-size: 1rem;
				font-weight: 700;
				margin-bottom: 16px;
			}
			.menu-nav {
				font-size: 0.875rem;
				font-weight: 500;
				color: $COLOR_999;
				margin-bottom: 8px;
				cursor: pointer;
				&:hover,
				&.active {
					color: $COLOR_333;
				}
			}
			hr {
				background: $COLOR_CCC;
			}
		}
	}
	.service-center-info {
		margin-bottom: 80px;
		.info-name {
			font-size: 0.75rem;
			font-weight: 700;
		}
		.info-tel {
			font-size: 1.5rem;
			font-weight: 700;
			margin-bottom: 10px;
		}
		.info-time {
			font-size: 0.75rem;
			font-weight: 500;
			color: $COLOR_999;
		}
	}
}
</style>
