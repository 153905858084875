<template>
	<div class="delivery-status-information-wrapper">
		<div class="information-title">배송 상태 안내</div>
		<div class="information-content">
			<status-list :list="statusList" />
			<div class="info">
				<div class="title">
					<img src="@/assets/svg/icon_warning_black.svg" />
					<span><b>취소/반품/교환 신청</b> 전 확인해주세요!</span>
				</div>
				<div class="content">
					<div>
						<span>취소요청</span>
						<ul>
							<li>
								<b>[승인 대기]</b> 상태에서는 시스템 상으로 주문 취소할 수 있으며, <b>[승인 완료]</b> 상태에서는 고객센터 1:1 문의를
								통해서 취소 가능여부를 확인해주셔야 합니다. 이 경우 상품에 따라 취소 수수료가 부과될 수 있습니다.
							</li>
							<li><b>[배송중]</b> 상태 이후 취소요청은 불가능하며 배송받으신 후 반품(환불)요청 해주셔야 합니다.</li>
						</ul>
					</div>
					<div>
						<span>교환/환불 요청</span>
						<ul>
							<li>
								상품에 문제가 있는 경우
								<ul>
									<li>
										상품 수령 후 3개월 이내, 그 사실을 알게 된 날부터 30일 이내에 교환 및 환불을 요청하실 수 있습니다. 요청시 상품
										상태에 대해 설명해주시거나 사진을 보내주시면 빠른 상담이 가능합니다.
									</li>
								</ul>
							</li>
							<li>
								단순 변심이나 주문 착오의 경우
								<ul>
									<li>상품 수령 후 7일 이내 요청 가능합니다.</li>
									<li>단순 변심으로 인한 교환/환불의 경우 고객님께서 배송비를 부담하셔야 합니다.</li>
								</ul>
							</li>
							<li>
								교환/환불이 불가한 경우
								<ul>
									<li>다음에 해당하는 교환/환불 요청은 처리가 어려울 수 있습니다.</li>
									<ul>
										<li>고객님의 주문에 따라 해외 주문-국내 입고되어 고객님께 배송된 상품의 경우</li>
										<li>고객님의 사용으로 인해 상품의 가치가 감소한 경우</li>
										<li>드라이아이스 포장 상품의 경우</li>
									</ul>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import StatusList from '@/views/my-page/order-delivery/order/components/StatusList.vue'

export default {
	name: 'DeliveryStatusInformation',
	components: {
		StatusList,
	},
	data() {
		return {
			statusList: [
				{
					title: '승인 대기',
					content: '판매자가 주문이 가능한<br /><b style="color: #111;">재고를 확인</b>하는 상태',
					image: require('@/assets/svg/check-stock.svg'),
				},
				{
					title: '승인 완료',
					content: '배송 시작전<br /><b style="color: #111;">출고를 준비</b>하는 상태',
					image: require('@/assets/svg/check-release.svg'),
				},
				{
					title: '배송중',
					content: '출고가 완료된 후<br /><b style="color: #111;">배송이 시작</b>된 상태',
					image: require('@/assets/svg/ing-delivery.svg'),
				},
				{
					title: '배송완료',
					content: '배송이 <b style="color: #111;">완료</b>된 상태',
					image: require('@/assets/svg/success-delivery.svg'),
				},
				{
					title: '구매확정',
					content: '배송완료 후 7일 후<br /><b style="color: #111;">자동</b>으로 <b style="color: #111;">구매확정</b>됩니다',
					image: require('@/assets/svg/fix-order.svg'),
				},
				{
					title: '주문취소',
					content: '<b style="color: #111;">주문취소</b>가<br /><b style="color: #111;">완료</b>된 상태입니다',
					image: require('@/assets/svg/cancel-order.svg'),
				},
			],
		}
	},
}
</script>

<style lang="scss" scoped>
.delivery-status-information-wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	.information-title {
		font-weight: 700;
		font-size: 0.875rem;
	}
	.information-content {
		display: flex;
		flex-direction: column;
		gap: 66px;
		padding: 20px;
		border: 1px solid $COLOR_EEE;
		border-radius: 5px;
		.info {
			display: flex;
			flex-direction: column;
			gap: 18px;
			.title {
				display: flex;
				align-items: center;
				gap: 4px;
				font-weight: 500;
				font-size: 0.875rem;
			}
			.content {
				display: flex;
				flex-direction: column;
				gap: 16px;
				padding: 20px;
				background: $LIST_BACKGROUND;
				border-radius: 5px;
				> div {
					display: flex;
					flex-direction: column;
					gap: 8px;
					span {
						font-weight: 700;
						font-size: 0.75rem;
					}
					ul {
						margin-left: 20px;
						li {
							font-weight: 500;
							font-size: 0.75rem;
							color: $COLOR_666;
							list-style-type: disc;
						}
					}
				}
			}
		}
	}
}
</style>
