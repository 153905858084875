import Vue from 'vue'
import Router from 'vue-router'
import RoutePath from '@/utils/define/RoutePath'
import PageName from '@/utils/define/PageName'
import store from '@/store/index'
import cookies from 'vue-cookies'
import { setToken } from '@/services/api/common'
Vue.use(Router)

var mainRoutes = []
var routes = [
	{
		path: RoutePath.Main,
		name: PageName.Main,
		props: true,
		component: () => import('@/views/main/Main.vue'),
	},
	{
		path: RoutePath.Error,
		name: PageName.Error,
		props: true,
		component: () => import('@/views/error/Error.vue'),
	},
	{
		path: RoutePath.RequiredOrderAllList,
		name: PageName.RequiredOrderAllList,
		props: true,
		component: () => import('@/views/main/RequiredOrderAllList.vue'),
	},
	{
		path: RoutePath.FrequentlyOrderList,
		name: PageName.FrequentlyOrderList,
		props: true,
		component: () => import('@/views/main/FrequentlyOrderList.vue'),
	},
	{
		path: RoutePath.List,
		name: PageName.List,
		props: true,
		component: () => import('@/views/list/List.vue'),
	},
	{
		path: RoutePath.Product,
		name: PageName.Product,
		props: true,
		component: () => import('@/views/product/Product.vue'),
	},
	{
		path: RoutePath.ProductSale,
		name: PageName.ProductSale,
		props: true,
		component: () => import('@/views/product/ProductSale.vue'),
	},
	{
		path: RoutePath.Detail,
		name: PageName.Detail,
		props: true,
		component: () => import('@/views/detail/ProductInfo.vue'),
	},
	{
		path: RoutePath.Search,
		name: PageName.Search,
		props: true,
		component: () => import('@/views/search/Search.vue'),
	},
	{
		path: RoutePath.SearchList,
		name: PageName.SearchList,
		props: true,
		component: () => import('@/views/search/Search.vue'),
	},
	{
		path: RoutePath.Cart,
		name: PageName.Cart,
		props: true,
		component: () => import('@/views/cart/Cart.vue'),
	},
	{
		path: RoutePath.Payment,
		name: PageName.Payment,
		props: true,
		component: () => import('@/views/payment/Payment.vue'),
	},
	{
		path: RoutePath.PaymentInicisCallback,
		name: PageName.PaymentInicisCallback,
		props: route => ({
			result: route.query.result,
			orderId: route.query.orderId,
			errorMessage: route.query.errorMessage,
		}),
		component: () => import('@/views/payment/PaymentInicisCallback.vue'),
	},
	{
		path: RoutePath.PaymentComplete,
		name: PageName.PaymentComplete,
		props: true,
		component: () => import('@/views/payment/PaymentComplete.vue'),
	},
	{
		path: RoutePath.Service,
		name: PageName.Service,
		props: true,
		component: () => import('@/views/service/Service.vue'),
	},
	{
		path: RoutePath.Inquiry,
		name: PageName.Inquiry,
		props: true,
		component: () => import('@/views/service/Inquiry.vue'),
	},
	{
		path: RoutePath.MyPage.Main,
		name: PageName.MyPage.Main,
		props: true,
		component: () => import('@/views/my-page/MyPage.vue'),
		children: [
			{
				path: RoutePath.MyPage.Order,
				name: PageName.MyPage.Order,
				props: true,
				component: () => import('@/views/my-page/order-delivery/order/Order.vue'),
			},
			{
				path: RoutePath.MyPage.OrderDetail,
				name: PageName.MyPage.OrderDetail,
				props: true,
				component: () => import('@/views/my-page/order-delivery/order/OrderDetail.vue'),
			},
			{
				path: RoutePath.MyPage.Return,
				name: PageName.MyPage.Return,
				props: true,
				component: () => import('@/views/my-page/order-delivery/return/Return.vue'),
			},
			{
				path: RoutePath.MyPage.ReturnDetail,
				name: PageName.MyPage.ReturnDetail,
				props: true,
				component: () => import('@/views/my-page/order-delivery/return/ReturnDetail.vue'),
			},
			{
				path: RoutePath.MyPage.UserInfo,
				name: PageName.MyPage.UserInfo,
				props: true,
				component: () => import('@/views/my-page/my-info/user-info/UserInfoManagement.vue'),
			},
			{
				path: RoutePath.MyPage.ChangePassword,
				name: PageName.MyPage.ChangePassword,
				props: true,
				component: () => import('@/views/my-page/my-info/user-info/ChangePassword.vue'),
			},
			{
				path: RoutePath.MyPage.ChangePasswordComplete,
				name: PageName.MyPage.ChangePasswordComplete,
				props: true,
				component: () => import('@/views/my-page/my-info/user-info/ChangePasswordComplete.vue'),
			},
			{
				path: RoutePath.MyPage.ShippingAddressManagement,
				name: PageName.MyPage.ShippingAddressManagement,
				props: true,
				component: () => import('@/views/my-page/my-info/shipping-address/ShippingAddressManagement.vue'),
			},
			{
				path: RoutePath.MyPage.BusinessRegistrationManagement,
				name: PageName.MyPage.BusinessRegistrationManagement,
				props: true,
				component: () => import('@/views/my-page/my-info/business-registration/BusinessRegistrationManagement.vue'),
			},
			{
				path: RoutePath.MyPage.EasyPaymentManagement,
				name: PageName.MyPage.EasyPaymentManagement,
				props: true,
				component: () => import('@/views/my-page/my-info/easy-payment/EasyPaymentManagement.vue'),
			},
			{
				path: RoutePath.MyPage.EstimateManagement,
				name: PageName.MyPage.EstimateManagement,
				props: true,
				component: () => import('@/views/my-page/estimate/Estimate.vue'),
			},
			{
				path: RoutePath.MyPage.EstimateDetail,
				name: PageName.MyPage.EstimateDetail,
				props: true,
				component: () => import('@/views/my-page/estimate/EstimateDetail.vue'),
			},
			{
				path: RoutePath.MyPage.RequestProductList,
				name: PageName.MyPage.RequestProductList,
				props: true,
				component: () => import('@/views/my-page/estimate/RequestProductList.vue'),
			},
			{
				path: RoutePath.MyPage.RequestProductDetail,
				name: PageName.MyPage.RequestProductDetail,
				props: true,
				component: () => import('@/views/my-page/estimate/RequestProductDetail.vue'),
			},
			{
				path: RoutePath.MyPage.PaymentHistory,
				name: PageName.MyPage.PaymentHistory,
				props: true,
				component: () => import('@/views/my-page/payment/PaymentHistory.vue'),
			},
			{
				path: RoutePath.MyPage.PaymentHistoryDetail,
				name: PageName.MyPage.PaymentHistoryDetail,
				props: true,
				component: () => import('@/views/my-page/payment/PaymentHistoryDetail.vue'),
			},
			{
				path: RoutePath.MyPage.UnPaymentManagement,
				name: PageName.MyPage.UnPaymentManagement,
				props: true,
				component: () => import('@/views/my-page/payment/UnPaymentManagement.vue'),
			},
			{
				path: RoutePath.MyPage.UnPaymentManagementDetail,
				name: PageName.MyPage.UnPaymentManagementDetail,
				props: true,
				component: () => import('@/views/my-page/payment/UnPaymentManagementDetail.vue'),
			},
			{
				path: RoutePath.MyPage.CompletePayment,
				name: PageName.MyPage.CompletePayment,
				props: true,
				component: () => import('@/views/my-page/payment/CompletePayment.vue'),
			},
			{
				path: RoutePath.MyPage.LabInfoManagement,
				name: PageName.MyPage.LabInfoManagement,
				props: true,
				component: () => import('@/views/my-page/my-info/lab/LabInfoManagement.vue'),
			},
			{
				path: RoutePath.MyPage.LabInfoEdit,
				name: PageName.MyPage.LabInfoEdit,
				props: true,
				component: () => import('@/views/my-page/my-info/lab/LabInfoEdit.vue'),
			},
			{
				path: RoutePath.MyPage.Wish,
				name: PageName.MyPage.Wish,
				props: true,
				component: () => import('@/views/my-page/activity-info/wish/Wish.vue'),
			},
			{
				path: RoutePath.MyPage.RecentView,
				name: PageName.MyPage.RecentView,
				props: true,
				component: () => import('@/views/my-page/activity-info/recent-view/RecentView.vue'),
			},
		],
	},
	{
		path: RoutePath.CsCenter.Main,
		name: PageName.CsCenter.Main,
		props: true,
		component: () => import('@/views/cs-center/CsCenter.vue'),
		children: [
			{
				path: RoutePath.CsCenter.InquiryManagement,
				name: PageName.CsCenter.InquiryManagement,
				props: true,
				component: () => import('@/views/cs-center/inquiry/InquiryManagement.vue'),
			},
			{
				path: RoutePath.CsCenter.InquiryManagementDetail,
				name: PageName.CsCenter.InquiryManagementDetail,
				props: true,
				component: () => import('@/views/cs-center/inquiry/InquiryManagementDetail.vue'),
			},
			{
				path: RoutePath.CsCenter.InquiryEdit,
				name: PageName.CsCenter.InquiryEdit,
				props: true,
				component: () => import('@/views/cs-center/inquiry/InquiryEdit.vue'),
			},
			{
				path: RoutePath.CsCenter.NoticeManagement,
				name: PageName.CsCenter.NoticeManagement,
				props: true,
				component: () => import('@/views/cs-center/notice/NoticeManagement.vue'),
			},
			{
				path: RoutePath.CsCenter.NoticeManagementDetail,
				name: PageName.CsCenter.NoticeManagementDetail,
				props: true,
				component: () => import('@/views/cs-center/notice/NoticeManagementDetail.vue'),
			},
			{
				path: RoutePath.CsCenter.EventManagement,
				name: PageName.CsCenter.EventManagement,
				props: true,
				component: () => import('@/views/cs-center/event/EventManagement.vue'),
			},
			{
				path: RoutePath.CsCenter.EventManagementDetail,
				name: PageName.CsCenter.EventManagementDetail,
				props: true,
				component: () => import('@/views/cs-center/event/EventManagementDetail.vue'),
			},
			{
				path: RoutePath.CsCenter.QuestionManagement,
				name: PageName.CsCenter.QuestionManagement,
				props: true,
				component: () => import('@/views/cs-center/question/QuestionManagement.vue'),
			},
		],
	},
	//견적서 (새창으로 열기)
	{
		path: RoutePath.EstimateSheet,
		name: PageName.EstimateSheet,
		props: true,
		component: () => import('@/views/my-page/estimate/EstimateSheet.vue'),
	},
	{
		path: RoutePath.EstimateExport,
		name: PageName.EstimateExport,
		props: true,
		component: () => import('@/views/my-page/estimate/EstimateExport.vue'),
	},
	{
		path: RoutePath.StartStore,
		name: PageName.StartStore,
		props: true,
		component: () => import('@/views/start/StartStore.vue'),
	},
	{
		path: RoutePath.Invitation,
		name: PageName.Invitation,
		props: true,
		component: () => import('@/views/invitation/Invitation.vue'),
	},
	{
		path: RoutePath.Sadadream.Main,
		name: PageName.Sadadream.Main,
		props: true,
		component: () => import('@/views/sadadream/Page.vue'),
	},
	{
		path: RoutePath.Sadadream.Detail,
		name: PageName.Sadadream.Detail,
		props: true,
		component: () => import('@/views/sadadream/Detail.vue'),
	},
	{
		path: RoutePath.Sadadream.OtherDetail,
		name: PageName.Sadadream.OtherDetail,
		props: true,
		component: () => import('@/views/sadadream/OtherDetail.vue'),
	},
	// 라우터 마지막에 위치
	// TODO: 옛날 URL로 들어오면 에러페이지로 가서, 일단 메인으로 이동
	{
		path: '*',
		redirect: RoutePath.Main,
		props: true,
	},
]
mainRoutes = mainRoutes.concat(routes)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: mainRoutes,
})

function hasQueryParams(route) {
	return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
	let isChanged = false

	if (to.query.isPro === 'true') {
		store.commit('main/setIsPro')
		delete to.query.isPro
		isChanged = true
	}
	if (to.query.proPageName) {
		store.commit('main/setProPageName', decodeURIComponent(to.query.proPageName))
		delete to.query.proPageName
		isChanged = true
	}
	if (to.query.instituteId) {
		cookies.set('currentInstituteId', to.query.instituteId)
		delete to.query.instituteId
		isChanged = true
	}
	if (to.query.token) {
		setToken(to.query.token)
		delete to.query.token
		isChanged = true
	}

	if (isChanged) {
		next({ path: to.path, query: to.query })
	} else {
		next()
	}
})

export default router
