<template>
	<b-modal id="ModalNoticeQrCode" ref="modal-notice-qr-code">
		<template #modal-title> QR code/Barcode로 물품찾기</template>
		<ul>
			<li>시약병에 부착된 라벨의 QR코드 혹은 바코드를 스캔해주세요.</li>
			<li>물품에 할당된 QR 스티커를 스캔해주세요.</li>
		</ul>
		<div class="imgbox">
			<img src="@/assets/images/notice-qrcode.png" />
		</div>
		<template #modal-footer="{ ok }">
			<button class="btn-hide" @click="ok">닫기</button>
		</template>
	</b-modal>
</template>

<script>
export default {
	name: 'ModalNoticeQrCode',
	methods: {
		hide() {
			this.$refs['modal-notice-qr-code'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
#ModalNoticeQrCode {
	ul {
		li {
			font-size: 1rem;
			font-weight: 400;
			position: relative;
			padding-left: 20px;
			&:before {
				position: absolute;
				top: 10px;
				left: 8px;
				border-radius: 8px;
				width: 4px;
				height: 4px;
				background-color: black;
				content: '';
			}
		}
	}
	.imgbox {
		width: 100%;
		margin-top: 20px;
	}
}
</style>
