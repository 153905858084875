<template>
	<div class="payment-product-info">
		<div class="image">
			<img v-if="!isEmptyImages" :src="product.images[0]" />
			<img v-else-if="!isEmptyBrandImage" :src="product.brand.image" />
			<img v-else src="@/assets/svg/empty_product.svg" alt="empty" />
		</div>
		<div class="content">
			<div class="seller-name" v-if="product.seller">
				<span @click="goLink">{{ `[${product.seller.name}]` }}</span>
			</div>
			<div class="name">
				<span @click="goLink">{{ product.name }}</span>
			</div>
			<div class="data">
				<span v-if="product.category" class="category" @click="goLink">{{ `카테고리: ${isData(categoryPathKorName)}` }}</span>
				<span class="brand" @click="goLink">{{ `브랜드: ${isData(product.brand && product.brand.name ? product.brand.name : null)}` }}</span>
				<span class="code" @click="goLink">
					{{ `제품번호: ${isData(product.code)}` }}
				</span>
				<span v-if="product.casno">{{ `CAS No: ${isData(product.casno)}` }}</span>
			</div>
			<div class="options">
				<span v-for="(option, index) in product.options" :key="`option_${index}`">
					{{ `${option.name}: ${option.value}` }}
				</span>
			</div>
			<div class="info" v-if="product.unitPrice">
				<span class="unit-price">{{ CommaNum(product.unitPrice) }}원</span>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import { getSYSUrl } from '@/services/api/common'

export default {
	name: 'PaymentProductInfo',
	props: {
		index: {
			type: Number,
			default: 0,
		},
		product: {
			type: Object,
			default: () => {},
		},
	},
	mixins: [mixin],
	computed: {
		isEmptyImages() {
			return this.$_.isEmpty(this.product.images)
		},
		isEmptyBrandImage() {
			return this.$_.isEmpty(this.product.brand.image)
		},
		categoryPathKorName() {
			const { category } = this.product
			const parents = category.parents
			return parents && parents.length > 0 ? `${parents[0]?.korName} / ${category.korName}` : category.korName
		},
	},
	methods: {
		getProduct() {
			return this.product
		},
		goLink(type) {
			// 판매자관
			// 상품상세
			// 브랜드관
			// 카테고리 상세
			// window.open(`${getSYSUrl()}/mall`, '_blank')
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-product-info {
	display: flex;
	.image {
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			border: 1px solid #eee;
			border-radius: 4px;
			width: 80px;
			height: 80px;
			object-fit: contain;
		}
	}
	.content {
		margin-left: 16px;
		font-size: 0.75rem;
		line-height: 18px;
		color: $COLOR_666;
		width: calc(100% - 100px);
		.seller-name {
			color: $COLOR_111;
			font-weight: 500;
			// span:hover {
			// 	text-decoration: underline;
			// 	cursor: pointer;
			// }
		}
		.name {
			font-size: 1rem;
			font-weight: 700;
			color: $COLOR_111;
			margin-bottom: 3px;
			// span:hover {
			// 	text-decoration: underline;
			// 	cursor: pointer;
			// }
		}
		.data,
		.options {
			font-size: 0.75rem;
			color: $COLOR_666;
			display: flex;
			align-items: center;
			@include ellipse;
			span + span::before {
				vertical-align: middle;
				margin-left: 6px;
				margin-right: 6px;
				display: inline-block;
				height: 12px;
				content: '';
				border-left: 1px solid #ddd;
			}

			.category,
			.brand,
			.code {
				// &:hover {
				// 	text-decoration: underline;
				// 	text-decoration-color: $COLOR_666;
				// 	cursor: pointer;
				// }
			}
		}
		.info {
			font-size: 0.875rem;
			font-weight: 500;
			color: $COLOR_111;
			margin-top: 5px;
			@include ellipse;
			span {
				display: inline-flex;
				align-items: center;
				img {
					margin-right: 5px;
				}
			}
			span + span::before {
				vertical-align: middle;
				margin-left: 6px;
				margin-right: 6px;
				display: inline-block;
				height: 12px;
				content: '';
				border-left: 1px solid #ddd;
			}
		}
	}
}
</style>
