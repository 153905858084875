<template>
	<div class="thumbnail-wrapper">
		<div class="first-thumbnail">
			<img :src="main" />
		</div>
		<div class="other-thumbnail-wrapper">
			<div class="other-thumbnail" v-for="(image, index) in thumbnails" :key="`thumbnail_${index}`" @click="changeThumbnail(image)">
				<img :src="image" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			main: null,
		}
	},
	computed: {
		...mapGetters('productInfo', ['thumbnails']),
	},
	watch: {
		thumbnails(val) {
			this.changeThumbnail(val[0])
		},
	},
	methods: {
		changeThumbnail(src) {
			this.main = src
		},
	},
}
</script>

<style lang="scss" scoped>
.thumbnail-wrapper {
	width: 520px;
	.first-thumbnail {
		width: 520px;
		height: 520px;
		margin-top: 10px;
		border: 1px solid #dddddd;
		border-radius: 5px;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	.other-thumbnail-wrapper {
		width: 100%;
		margin: 10px 10px 10px 0;
		display: flex;
		.other-thumbnail {
			width: 60px;
			height: 60px;
			border: 1px solid #eee;
			margin-right: 10px;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				cursor: pointer;
			}
		}
	}
}
</style>
