const PageName = {
	Main: 'main',
	Error: 'error',
	RequiredOrderAllList: 'RequiredOrderAllList',
	FrequentlyOrderList: 'FrequentlyOrderList',
	Home: 'home',
	RedirectToken: 'redirectToken',
	Detail: 'Detail',
	Product: 'Product',
	ProductSale: 'ProductSale',
	List: 'List',
	ListInfo: 'ListInfo',
	Inquiry: 'Inquiry',
	Service: 'Service',
	Search: 'Search',
	SearchList: 'SearchList',
	Cart: 'Cart',
	Payment: 'Payment',
	PaymentInicisCallback: 'PaymentInicisCallback',
	PaymentComplete: 'PaymentComplete',
	MyPage: {
		Main: 'MyPage',
		Order: 'Order',
		OrderDetail: 'OrderDetail',
		Return: 'Return',
		ReturnDetail: 'ReturnDetail',
		UserInfo: 'UserInfo',
		ChangePassword: 'ChangePassword',
		ChangePasswordComplete: 'ChangePasswordComplete',
		ShippingAddressManagement: 'ShippingAddressManagement',
		BusinessRegistrationManagement: 'BusinessRegistrationManagement',
		EasyPaymentManagement: 'EasyPaymentManagement',
		EstimateManagement: 'EstimateManagement',
		EstimateDetail: 'EstimateDetail',
		RequestProductList: 'RequestProductList',
		RequestProductDetail: 'RequestProductDetail',
		LabInfoManagement: 'LabInfoManagement',
		LabInfoEdit: 'LabInfoEdit',
		PaymentHistory: 'PaymentHistory',
		PaymentHistoryDetail: 'PaymentHistoryDetail',
		CompletePayment: 'CompletePayment',
		UnPaymentManagement: 'UnPaymentManagement',
		UnPaymentManagementDetail: 'UnPaymentManagementDetail',
		Wish: 'Wish',
		RecentView: 'RecentView',
	},
	CsCenter: {
		Main: 'CsCenter',
		InquiryManagement: 'InquiryManagement',
		InquiryManagementDetail: 'InquiryManagementDetail',
		InquiryEdit: 'InquiryEdit',
		NoticeManagement: 'NoticeManagement',
		NoticeManagementDetail: 'NoticeManagementDetail',
		EventManagement: 'EventManagement',
		EventManagementDetail: 'EventManagementDetail',
		QuestionManagement: 'QuestionManagement',
	},
	Sadadream: {
		Main: 'Sadadream',
		Detail: 'SadadreamDetail',
		OtherDetail: 'SadadreamOtherDetail',
	},
	EstimateSheet: 'EstimateSheet',
	EstimateExport: 'EstimateExport',
	StartStore: 'StartStore',
	Invitation: 'Invitation',
}

export default PageName
