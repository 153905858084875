import axiosInstance from '@/services/axios'

const endPoint = {
	lookupCartList: instituteId => `institute/${instituteId}/cart/list`,
	lookupNonMemberCartList: () => `/cart/list`,
	addCart: instituteId => `institute/${instituteId}/cart`,
	updateCart: instituteId => `institute/${instituteId}/cart`,
	deleteCart: instituteId => `institute/${instituteId}/cart`,
}

const cartAPI = {
	lookupCartList: (instituteId, params) => axiosInstance.get(endPoint.lookupCartList(instituteId), { params }),
	lookupNonMemberCartList: body => axiosInstance.post(endPoint.lookupNonMemberCartList(), body),
	addCart: (instituteId, body) => axiosInstance.post(endPoint.addCart(instituteId), body),
	updateCart: (instituteId, body) => axiosInstance.patch(endPoint.updateCart(instituteId), body),
	deleteCart: (instituteId, params) => axiosInstance.delete(endPoint.deleteCart(instituteId), { params }),
}

export default cartAPI
