<template>
	<div class="error-wrapper">
		<div class="header">
			<div>
				<img src="@/assets/svg/logo.svg" alt="" />
			</div>
			<div>
				<button class="btn" @click="goMain">랩매니저 스토어홈으로</button>
			</div>
		</div>
		<div class="content-wrapper">
			<div class="main-text">404 Error <img src="@/assets/svg/error/exclamation-mark-icon.svg" alt="" /></div>
			<div class="sub-text">요청하신 페이지를 찾을 수 없습니다. <br />입력하신 주소가 정확한지 다시 한번 확인해주세요.</div>
			<div class="button-wrapper">
				<button class="btn" @click="goPrev">이전 페이지로</button>
			</div>
			<div class="image-wrapper">
				<img src="@/assets/svg/error/error.svg" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'

export default {
	name: 'Error',
	methods: {
		goMain() {
			this.$router
				.push({
					name: PageName.Main,
				})
				.catch(() => {})
		},
		goPrev() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped>
.error-wrapper {
	width: 1080px;
	height: 850px;
	margin: 0 auto;
	.header {
		height: 80px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 0;
		button {
			height: 30px;
		}
	}
	.content-wrapper {
		margin-bottom: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.main-text {
			font-size: 3.5rem;
			font-weight: 700;
		}
		.sub-text {
			font-size: 0.75rem;
			font-weight: 500;
			color: $COLOR_666;
			text-align: center;
			margin-top: 5px;
		}
		.button-wrapper {
			margin-top: 20px;
			button {
				height: 30px;
			}
		}
		.image-wrapper {
			margin-top: 20px;
		}
	}
}
</style>
