<template>
	<b-dropdown variant="transparent" menu-class="option-menu" no-caret>
		<template v-slot:button-content>
			<div class="select-box">
				<span>{{ value }}</span>
				<img src="@/assets/svg/dropdown.svg" />
			</div>
		</template>
		<b-dropdown-item v-for="(item, index) of items" :key="index" :disabled="disabledFunc(item)" @click="onClickItem(item)">
			{{ item }}
		</b-dropdown-item>
	</b-dropdown>
</template>

<script>
export default {
	name: 'SelectInput',
	props: {
		value: {
			type: String,
			default: '',
		},
		items: {
			type: Array,
			default: () => [],
		},
		disabledFunc: {
			type: Function,
			default: () => {},
		},
	},
	methods: {
		onClickItem(value) {
			this.$emit('input', value)
			this.$emit('change', value)
		},
	},
}
</script>

<style lang="scss" scoped>
.select-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 300px;
	height: 40px;
	padding: 10px;
	border: 1px solid $COLOR_EEE;
	border-radius: 4px;
	font-size: 0.875rem;
	font-weight: 500;
}
</style>
