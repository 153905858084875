<template>
	<div class="attachment-form">
		<div class="title" v-if="title">
			<h6>{{ title }}</h6>
			<span class="required" v-if="required">*</span>
			<p class="subname" v-if="subname">{{ subname }}</p>
		</div>
		<div class="file-box" @drop="dropFile">
			<input class="drop-file" type="file" ref="drop-file" accept=".jpg, .pdf, .png" @change="changeFile" multiple />
			<div>
				<p>첨부할 파일을 마우스로 끌어오거나, 직접 선택해주세요</p>
				<button class="btn">파일 선택</button>
			</div>
		</div>
		<ul class="file-list">
			<li v-for="(item, index) in attachments" :key="`file_${index}`">
				<img class="icon" src="@/assets/svg/icon/icon-file.svg" />
				<p>{{ item.name }}</p>
				<img class="close" @click="deleteFile(index)" src="@/assets/svg/icon/icon-close.svg" />
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'AttachmentForm',
	props: {
		title: {
			type: String,
			default: '',
		},
		subname: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
	mounted() {
		this.attachments = this.list
	},
	data() {
		return {
			attachments: [],
		}
	},
	watch: {
		attachments(val) {
			this.$emit('change', val)
		},
	},
	methods: {
		changeFile() {
			const files = [...this.$refs['drop-file'].files]
			this.attachments = [...this.attachments, ...files]
		},
		dropFile(e) {
			e.preventDefault()
			this.$refs['drop-file'].files = e.dataTransfer.files
			this.changeFile()
		},
		deleteFile(index) {
			this.attachments.splice(index, 1)
		},
	},
}
</script>

<style lang="scss" scoped>
.attachment-form {
	width: 100%;
	.title {
		display: inline-flex;
		align-items: center;
		margin-bottom: 1rem;
		h6 {
			font-size: 1rem;
			font-weight: 700;
		}
		p {
			margin-left: 18px;
			font-size: 0.875rem;
			font-weight: 500;
			color: $COLOR_999;
		}
	}
	.file-box {
		width: 100%;
		height: 120px;
		border: 1px dashed $FONT_DISABLED;
		border-radius: 4px;
		position: relative;
		background-color: $LIST_BACKGROUND;
		display: flex;
		align-items: center;
		justify-content: center;
		input[type='file'] {
			width: 100%;
			height: inherit;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			cursor: pointer;
		}
		> div {
			font-size: 0.875rem;
			font-weight: 500;
			text-align: center;
			p {
				font-size: 0.875rem;
				color: $COLOR_999;
				margin-bottom: 1rem;
			}
			button {
				height: 40px;
				width: 90px;
			}
		}
	}
	.file-list {
		width: 100%;
		li {
			display: flex;
			align-items: center;
			height: 48px;
			border-radius: 4px;
			border: 1px solid $COLOR_DDD;
			padding: 0 8px;
			margin-top: 8px;
			.icon {
			}
			p {
				margin-left: 8px;
				font-size: 0.875rem;
				font-weight: 500;
			}
			.close {
				margin-left: auto;
				cursor: pointer;
			}
		}
	}
}
</style>
