import { render, staticRenderFns } from "./PaymentPrivacyPolicy.vue?vue&type=template&id=38b9f962&scoped=true&"
import script from "./PaymentPrivacyPolicy.vue?vue&type=script&lang=js&"
export * from "./PaymentPrivacyPolicy.vue?vue&type=script&lang=js&"
import style0 from "./PaymentPrivacyPolicy.vue?vue&type=style&index=0&id=38b9f962&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38b9f962",
  null
  
)

export default component.exports