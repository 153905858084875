<template>
	<b-modal
		:id="id"
		centered
		:title="title"
		:ok-title="okBtnText"
		ok-variant="warning"
		cancel-title="취소"
		cancel-variant="default"
		ref="modal-common"
		:size="size"
		button-size="lg"
		hide-header-close
		@ok="$emit('ok')"
	>
		<div class="msg-wrapper">
			<p v-html="content"></p>
			<div class="notice" v-if="notice" v-html="notice"></div>
		</div>
	</b-modal>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			default: 'modal-submit',
		},
		title: {
			type: String,
			default: '',
		},
		okBtnText: {
			type: String,
			default: '확인',
		},
		content: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: 'md',
		},
		notice: {
			type: String,
			default: '',
		},
	},
}
</script>

<style lang="scss">
#modal-submit {
	.modal-content {
		padding: 20px;
		.modal-header {
			font-size: 1.125rem;
			text-align: center;
			justify-content: center;
			padding-bottom: 0;
		}
		.modal-body {
			text-align: center;
			p {
				font-size: 0.875rem;
				font-weight: 400;
				color: #333;
			}
		}
		.modal-footer {
			flex-wrap: unset;
			button {
				height: 40px;
				font-size: 0.875rem;
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.notice {
	padding: 17px 0;
	background: $HOVER_BACKGROUND;
	border-radius: 10px;
	font-weight: 500;
	font-size: 0.75rem;
	color: $COLOR_333;
	margin-top: 24px;
}
</style>
