import estimateAPI from '@/services/api/estimate'

const estimates = {
	namespaced: true,
	state: {
		estimates: [],
		count: 0,
		estimate: {},
	},
	mutations: {
		setEstimates: (state, payload) => {
			state.estimates = payload
		},
		setCount: (state, payload) => {
			state.count = payload
		},
		setEstimate: (state, payload) => {
			state.estimate = payload
		},
	},
	actions: {
		lookupEstimateList: async ({ commit }, { instituteId, params }) => {
			const response = await estimateAPI.lookupEstimateList(instituteId, params)
			commit('setEstimates', response.data.list)
			commit('setCount', response.data.count)
		},
		lookupEstimate: async ({ commit }, { instituteId, id }) => {
			const response = await estimateAPI.lookupEstimate(instituteId, id)
			commit('setEstimate', response.data)
		},
		addEstimateByCart: async (_, { instituteId, body }) => {
			const response = await estimateAPI.addEstimateByCart(instituteId, body)
			return response.data
		},
		addEstimateByOrderItem: async (_, { instituteId, body }) => {
			const response = await estimateAPI.addEstimateByOrderItem(instituteId, body)
			return response.data
		},
		addEstimateToNonMember: async (_, body) => {
			const response = await estimateAPI.addEstimateToNonMember(body)
			return response.data
		},
		updateEstimate: async (_, { instituteId, body }) => {
			return await estimateAPI.updateEstimate(instituteId, body)
		},
		deleteEstimate: async (_, { instituteId, id }) => {
			return await estimateAPI.deleteEstimate(instituteId, id)
		},
		printEstimate: async (_, { instituteId, body }) => {
			const response = await estimateAPI.printEstimate(instituteId, body)
			return response.data.url
		},
	},
}

export default estimates
