<template>
	<div class="UnPaymentManagement">
		<content-title title="미결제 내역" :steps="steps" :activeStep="activeStep" />
		<div class="content-wrapper">
			<content-banner
				class="unpayment"
				:hasNopaidPrice="nopaidPrice > 0"
				:leftImg="banner.leftImg"
				:text="banner.text"
				btnText="결제 하기"
				@clickEv="goDetail"
			/>
			<div class="noPaid-explain-box">
				<p>• 주문 시점으로 부터 익월 말일까지 결제가 되지않을 경우 결제 기한이 초과되어 더 이상 물품을 구매할 수 없습니다.</p>
			</div>
			<detail-search-box
				class="search-box"
				title="상세검색"
				subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
				searchText=""
				:isSearchKeyword="false"
				:isSearchPrice="true"
				:datePlaceholder="datePlaceholder"
				@searchTextList="searchTextList"
			/>
			<payment-info-table
				:title="paymentTableTitle"
				:headers="unPaymentTableHeaders"
				:list="list"
				:showOrderInfo="true"
				:showCheckAll="true"
				checkable
				disabledTooltip="판매자 주문승인전 상품은 결제하실수 없어요!"
				noDataText="미결제 상품이 없습니다"
				@check="changeCheck"
				@checkAll="onCheckAll"
			/>
			<div class="btn-wrap bottom">
				<button class="btn btn-lg" @click="onClickWriteEstimate">총 {{ checkList.length }}개 견적 작성하기</button>
				<button class="btn btn-complete btn-lg" @click="goDetail">총 {{ checkList.length }}개 결제하기</button>
				<div v-if="isShowEstimateTooltip" class="estimate-tooltip">
					<div>
						<p>견적<span>이 작성되었습니다.</span></p>
						<img src="@/assets/svg/main/icon-close.svg" @click="isShowEstimateTooltip = false" />
					</div>
					<button class="btn btn-primary lg" @click="goEstimate">견적함으로 이동하기</button>
				</div>
			</div>
		</div>
		<modal-cancel :title="modalTitle" :content="modalContent" @ok="estimate" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ContentTitle from '@/components/content/ContentTitle'
import ContentBanner from '@/components/content/ContentBanner'
import DetailSearchBox from '@/views/my-page/order-delivery/order/components/search/DetailSearchBox'
import PaymentInfoTable from '@/views/my-page/payment/components/PaymentInfoTable'
import ModalCancel from '@/components/modal/ModalCancel'
import PageName from '@/utils/define/PageName'
import date from '@/mixins/date'
import mixin from '@/mixins'
import { ToastType } from '@/utils/define/ToastType'
import { menuList } from '@/utils/define/my-page'

export default {
	name: 'UnPaymentManagement',
	components: {
		ContentTitle,
		ContentBanner,
		PaymentInfoTable,
		DetailSearchBox,
		ModalCancel,
	},
	mixins: [date, mixin],
	data() {
		return {
			searchPrice: null,
			searchDate: [],
			activeStep: 1,
			steps: [
				{
					value: 1,
					text: '미결제 내역',
				},
				{
					value: 2,
					text: '결제',
				},
				{
					value: 3,
					text: '결제 완료',
				},
			],
			list: [],
			checkList: [],
			unPaymentTableHeaders: [
				{ text: '상품정보', width: '80%', key: 'info' },
				{ text: '미결제 금액(VAT 미포함)', width: '20%', key: 'price' },
			],
			estimateId: 0,
			isShowEstimateTooltip: false,
			modalTitle: '',
			modalContent: '',
			menuList,
		}
	},
	computed: {
		...mapState('institute', ['institute']),
		...mapGetters('user', ['instituteId']),
		...mapState('nopaid', ['nopaidList', 'count', 'nopaidPrice']),
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
		banner() {
			return {
				leftImg: require('@/assets/svg/content/left-unpayment-image.svg'),
				text: this.nopaidPriceText,
			}
		},
		nopaidPriceText() {
			if (this.nopaidPrice > 0) return `총 미결제 금액은 <b style="color: #FF5746;">${this.CommaNum(this.nopaidPrice)}</b> 원입니다`
			else return '미결제 금액이 없습니다.'
		},
		paymentTableTitle() {
			return `미결제 상품 (${this.list.length})`
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				await this.lookupNopaidSummary(this.instituteId)
				await this.fetchNopaidList()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('nopaid', ['lookupNopaidList', 'lookupNopaidSummary']),
		...mapActions('estimate', ['addEstimateByOrderItem']),
		async fetchNopaidList() {
			const params = {
				orderStartDatetime: this.searchDate.length > 0 ? this.searchDate[0] : '',
				orderEndDatetime: this.searchDate.length > 0 ? this.searchDate[1] : '',
			}
			if (this.searchPrice) params.capacity = this.searchPrice

			await this.lookupNopaidList({
				instituteId: this.instituteId,
				params,
			})

			this.list = this.$_.cloneDeep(this.nopaidList)
		},
		searchTextList(filter) {
			if (this.$_.isEmpty(filter)) {
				this.searchPrice = null
				this.searchDate = []
			} else {
				Object.entries(filter).forEach(([key, value]) => {
					if (key === '결제할 금액 선택') this.searchPrice = value.length > 0 ? value[0].value : null
					else if (key === '기간 조회') this.searchDate = value.length > 0 ? value[0].value : []
				})
			}
			this.fetchNopaidList()
		},
		changeCheck(list) {
			this.checkList = list
		},
		onCheckAll(checked) {
			this.list = this.list.map(item => {
				if (item.canPay) item.isChecked = checked
				return item
			})
		},
		onClickWriteEstimate() {
			if (this.$_.isEmpty(this.checkList)) return this.$root.toast('작성 실패', '상품을 1개 이상 선택해야 합니다.', ToastType.ERROR)

			this.modalTitle = '견적서 작성'
			this.modalContent = `선택한 미결제 상품으로 견적서를 작성하시겠습니까?`
			this.$bvModal.show('ModalCancel')
		},
		async estimate() {
			try {
				const response = await this.addEstimateByOrderItem({
					instituteId: this.instituteId,
					body: {
						orderItemIds: this.checkList.map(item => item.orderItemId),
					},
				})
				this.estimateId = response.estimateId
				this.isShowEstimateTooltip = true
			} catch (e) {
				this.$root.toast('작성 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
		goDetail() {
			if (this.$_.isEmpty(this.checkList)) return this.$root.toast('결제 실패', '상품을 1개 이상 선택해야 합니다.', ToastType.ERROR)

			this.$router.push({
				name: PageName.MyPage.UnPaymentManagementDetail,
				params: {
					step: 2,
				},
				query: {
					orderItemIds: JSON.stringify(this.checkList.map(item => item.orderItemId)),
				},
			})
		},
		goEstimate() {
			this.menuList.forEach(outer => {
				outer.list.forEach(inner => {
					if (inner.active) inner.active = false
					else if (inner.name === '견적함') inner.active = true
				})
			})
			this.$router.push({
				name: PageName.MyPage.EstimateDetail,
				params: {
					id: this.estimateId,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.detail-search-box {
	margin-top: 60px;
}
.payment-info-table {
	margin: 64px 0;
}
.noPaid-explain-box {
	background-color: #fef9ec;
	font-size: 15px;
	color: #333;
	padding: 16px;
	margin-top: 10px;
	border-radius: 12px;
}
.btn-wrap {
	margin-bottom: 80px;
	position: relative;
	button {
		&:first-child {
			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #f2f3f4;
				color: black;
			}
		}
	}
	.estimate-tooltip {
		width: 350px;
		height: 113px;
		position: absolute;
		top: -130px;
		left: 145px;
		padding: 20px 15px 15px 15px;
		border: solid 1px $COLOR_DDD;
		border-radius: 5px;
		background: $PRIMARY_WHITE;
		div {
			display: flex;
			justify-content: space-between;
			p {
				font-weight: 700;
				font-size: 16px;
				span {
					font-weight: 400;
				}
			}
			img {
				cursor: pointer;
			}
		}
		button {
			margin: 0;
			&.btn-primary {
				width: 100%;
				height: 40px;
				margin-top: 10px;
				background: $PRIMARY_YELLOW;
			}
		}
	}
	.estimate-tooltip:after {
		border-bottom: 0px solid transparent;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid $PRIMARY_WHITE;
		content: '';
		position: absolute;
		top: 110px;
		left: 160px;
		z-index: 1004;
	}
	.estimate-tooltip:before {
		border-bottom: 0px solid transparent;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid #ddd;
		content: '';
		position: absolute;
		top: 111px;
		left: 160px;
		z-index: 1003;
	}
}
</style>
