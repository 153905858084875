<template>
	<div class="common-date-picker">
		<p class="title" v-if="title">{{ title }}</p>
		<date-picker
			v-model="datetime"
			valueType="format"
			:range="isRange"
			:lang="datepickerLang"
			:disabled="disabled"
			:placeholder="placeholder"
			:class="{ range: isRange }"
			@change="$emit('change', $event)"
		/>
		<p class="content">{{ content }}</p>
	</div>
</template>

<script>
import date from '@/mixins/date'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
	name: 'CommonDatePicker',
	mixins: [date],
	props: {
		title: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		isRange: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: 'YYYY-MM-DD',
		},
	},
	components: {
		DatePicker,
	},
	data() {
		return {
			datetime: '',
		}
	},
	watch: {
		value(val) {
			if (!val) return

			this.datetime = val
		},
	},
}
</script>

<style lang="scss">
.common-date-picker {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	.title {
		font-weight: 400;
		margin-right: 8px;
	}
	.mx-datepicker {
		width: 130px;
		height: 30px;
		.mx-input-wrapper {
			height: inherit;
		}
		.mx-input {
			height: inherit;
			border: 1px solid $LINE_DIVIDER;
			border-radius: 4px;
			box-shadow: unset;
		}
		.mx-icon-calendar {
			width: 30px;
			height: 30px;
			background-color: $COMMON_BACKGROUND;
			right: 0;
			border-radius: 0 4px 4px 0;
			background-image: url(~@/assets/svg/icon/icon-calendar.svg);
			svg {
				display: none;
			}
		}
	}
	.content {
		margin-left: 20px;
		font-weight: 500;
	}
}
.mx-datepicker-popup {
	.mx-datepicker-content {
		.mx-datepicker-body {
			.mx-calender-header {
				* {
					&:hover {
						color: #fec741;
					}
				}
			}
			// * {
			// 	&:hover {
			// 		color: #fec741 !important;
			// 	}
			// }
			.mx-icon-double-left {
				&:hover {
					&:before {
						color: #fec741;
					}
					&:after {
						color: #fec741;
					}
				}
			}
			.mx-icon-left {
				&:hover {
					&:before {
						color: #fec741;
					}
					&:after {
						color: #fec741;
					}
				}
			}
			.mx-icon-double-right {
				&:hover {
					&:before {
						color: #fec741;
					}
					&:after {
						color: #fec741;
					}
				}
			}
			.mx-icon-right {
				&:hover {
					&:before {
						color: #fec741;
					}
					&:after {
						color: #fec741;
					}
				}
			}
		}
		.mx-btn {
			display: inline-block;
		}
		.mx-btn-text {
			&:hover {
				color: #fec741;
			}
		}
		.mx-table-date {
			.today {
				color: #fec741;
			}
		}
		.cell {
			&.active {
				background-color: #fec741;
			}
			&:hover {
				background-color: #fff3d6;
			}
			&.hover-in-range {
				background-color: #fff3d6;
			}
			&.in-range {
				background-color: #fff3d6;
			}
			&.not-current-month {
				pointer-events: none;
				background-color: rgba(0, 0, 0, 0.02);
			}
			&.today {
				background-color: #fff3d6;
			}
		}
	}
}
</style>
