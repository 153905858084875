<template>
	<div class="cart-product-total-price">
		<div class="title">{{ '주문 금액 합계:' }}</div>
		<div class="label">{{ '소비자가 합계' }}</div>
		<div class="value">{{ `${CommaNum(orderTotal.totalPrice)}원` }}</div>
		<img src="@/assets/svg/cart/minus.svg" />
		<div class="label">{{ '할인금액' }}</div>
		<div class="value red">{{ `${CommaNum(orderTotal.totalDiscountPrice)}원` }}</div>
		<img src="@/assets/svg/cart/plus.svg" />
		<div class="label">{{ '배송비' }}</div>
		<div class="value">{{ `${CommaNum(totalShippingPrice)}원` }}</div>
		<img src="@/assets/svg/cart/plus.svg" />
		<div class="label">{{ 'VAT(10%)' }}</div>
		<div class="value">{{ `${CommaNum(orderTotal.totalVat)}원` }}</div>
		<img src="@/assets/svg/cart/equal.svg" />
		<div class="value lg bold">{{ `${CommaNum(orderTotal.totalSellingPriceWithVat)}원` }}</div>
	</div>
</template>

<script>
import _ from 'lodash'
import mixin from '@/mixins'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import { isLogin } from '@/services/api/common'
import cookies from 'vue-cookies'

export default {
	name: 'CartProductTotalPrice',
	props: {
		productList: {
			type: Array,
			default: () => [],
		},
		estimateId: {
			type: Number,
			default: 0,
		},
		requestId: {
			type: [Number, String],
			default: 0,
		},
	},
	mixins: [mixin],
	data() {
		return {
			totalShippingPrice: 0, // 배송비
			list: [],
		}
	},
	watch: {
		list: _.debounce(function () {
			this.loadTotalPrice()
		}, 300),
		productList: {
			handler(newVal) {
				if (!newVal) return

				this.list = this.$_.cloneDeep(newVal)
			},
			deep: true,
		},
	},
	computed: {
		...mapState('order', ['orderTotal']),
		...mapState('delivery', ['basicAddress']),
		...mapGetters('user', ['instituteId']),
		currentAddress() {
			return cookies.get('currentAddress2') ?? this.basicAddress
		},
	},
	created() {
		this.init()
	},
	methods: {
		...mapMutations('order', ['setOrderTotal']),
		...mapActions('order', ['nonMemberOrderCalculate', 'orderCalculate']),
		init() {
			this.setOrderTotal({
				productList: [],
				totalPrice: 0,
				totalDiscountPrice: 0,
				totalVat: 0,
				totalSellingPriceWithVat: 0,
			})
		},
		setProductList(productList) {
			this.list = this.$_.cloneDeep(productList)
		},
		async loadTotalPrice() {
			try {
				if (this.$_.isEmpty(this.list)) {
					this.init()
					return
				}
				if (this.list.some(item => item.quantity == 0)) {
					return
				}

				const body = { list: this.list }
				if (this.currentAddress) body.deliveryId = this.currentAddress.id
				if (this.estimateId) body.estimateId = this.estimateId
				if (this.requestId) body.requestId = this.requestId

				if (!isLogin()) {
					await this.nonMemberOrderCalculate({ body })
				} else {
					await this.orderCalculate({ instituteId: this.instituteId, body })
				}
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.cart-product-total-price {
	width: 100%;
	height: 87px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $LIST_BACKGROUND;
	gap: 10px;
	.title {
		font-weight: 700;
		margin-right: 5px;
	}
	.label {
		color: $COLOR_666;
	}
	.value {
		&.red {
			color: #ff5746;
		}
		&.lg {
			font-size: 1.25rem;
		}
		&.bold {
			font-weight: 700;
		}
	}
}
</style>
