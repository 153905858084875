import categoryAPI from '@/services/api/category'

const category = {
	namespaced: true,
	state: {
		parentCategoryList: [],
		tags: [],
		categoryList: [],
	},
	getters: {
		getParentCategoryList: state => {
			return state.parentCategoryList?.map(item => {
				return {
					name: item.korName,
					value: item.code,
					type: 'category',
					node: 'parent',
				}
			})
		},
		getChildCategoryList: state => {
			return state.tags?.map(tag => {
				return {
					name: tag,
					value: tag,
					type: 'category',
					node: 'child',
				}
			})
		},
	},
	mutations: {
		setParentCategoryList: (state, payload) => {
			state.parentCategoryList = payload
		},
		setChildCategoryList: (state, payload) => {
			state.tags = payload
		},
		setCategoryList: (state, payload) => {
			state.categoryList = payload
		},
	},
	actions: {
		lookupParentCategoryList: async ({ commit }) => {
			const response = await categoryAPI.lookupParentCategoryList()
			commit('setParentCategoryList', response.data)
		},
		lookupChildCategoryList: async ({ commit }, parentCode) => {
			const response = await categoryAPI.lookupChildCategoryList(parentCode)
			commit('setChildCategoryList', response.data)
		},
		lookupCategoryList: async ({ commit }) => {
			const response = await categoryAPI.lookupCategoryList()
			commit('setCategoryList', response.data)
		},
	},
}

export default category
