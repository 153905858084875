<template>
	<div class="keyword-list" :class="{ isRecent: isRecent }">
		<div class="title">
			<p>{{ title }}</p>
			<button v-if="isRecent" @click="removeAllRecent">모두 삭제</button>
		</div>
		<ul class="list">
			<li v-for="(keyword, index) in list" :key="`keyword_${index}`">
				<img class="recent" :src="imageSrc" />
				<p @click.stop="clickItem($event, keyword.value)">{{ keyword.value }}</p>
				<img class="close" src="@/assets/svg/close_c.svg" v-if="isRecent" @click.stop="removeRecent($event, keyword)" />
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'KeywordList',
	props: {
		title: {
			type: String,
			default: '',
		},
		isRecent: {
			type: Boolean,
			default: true,
		},
		mode: {
			type: String,
			default: 'page',
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			reversedRecentKeywords: [],
		}
	},
	created() {
		this.reversedRecentKeywords = [...this.list]
		this.reversedRecentKeywords.reverse()
	},
	computed: {
		imageSrc() {
			if (this.isRecent) {
				return require('@/assets/svg/recent-keyword.svg')
			} else {
				return require('@/assets/svg/search_s.svg')
			}
		},
	},
	watch: {
		isRecent(value) {
			if (value) {
				this.reversedRecentKeywords = [...this.list]
				this.reversedRecentKeywords.reverse()
			}
		},
		recentKeywords(value) {
			this.reversedRecentKeywords = [...this.list]
			this.reversedRecentKeywords.reverse()
		},
	},
	methods: {
		clickItem(event, keyword) {
			this.$emit('clickItem', keyword)
		},
		removeRecent(event, keyword) {
			this.$emit('removeRecent', keyword)
		},
		removeAllRecent(event) {
			console.log('dddfsf')
			this.$emit('removeAllRecent')
		},
	},
}
</script>

<style lang="scss" scoped>
.keyword-list {
	position: absolute;
	width: 100%;
	top: 30px;
	left: 0;
	border: 1px solid $COLOR_CCC;
	border-radius: 4px;
	filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
	padding: 5px;
	background-color: #fff;
	z-index: 1;
	visibility: hidden;
	opacity: 0;
	&.active {
		opacity: 1;
		visibility: visible;
	}
	&.isRecent {
		ul {
			li {
				p {
					width: calc(100% - 70px);
				}
			}
		}
	}
	.title {
		padding: 0 10px;
		width: 100%;
		height: 25px;
		display: flex;
		align-items: flex-end;
		p {
			font-weight: 400;
			color: $COLOR_666;
			font-size: 0.75rem;
		}
		button {
			font-size: 0.75rem;
			font-weight: 700;
			margin-left: auto;
		}
	}
	ul::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	ul::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 6px;
		background-clip: padding-box;
		border: 2px solid transparent;
	}
	ul::-webkit-scrollbar-track {
		background-color: transparent;
		border-radius: 6px;
		box-shadow: inset 0px 0px 6px white;
	}
	ul {
		width: 100%;
		max-height: 300px;
		overflow-y: auto;
		li {
			padding: 5px;
			display: flex;
			align-items: flex-start;
			min-height: 30px;
			transition: all 0.3s;
			border-radius: 4px;
			margin-top: 5px;
			cursor: pointer;
			word-break: break-all;
			&:hover {
				background-color: #f2f2f2;
			}
			img {
				width: 24px;
				height: 24px;
				&.close {
					margin-left: auto;
				}
			}
			p {
				display: inline-block;
				font-size: 0.875rem;
				font-weight: 400;
				width: calc(100% - 24px);
				margin-left: 5px;
				padding-top: 1px;
			}
		}
	}
}
</style>
