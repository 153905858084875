<template>
	<div class="complete-notice">
		<div class="txtbox">
			<div class="title" v-if="title">
				<img v-if="titleImg" :src="titleImg" />
				<h6>{{ title }}</h6>
			</div>
			<p v-html="description"></p>
			<ul v-if="list.length > 0">
				<li v-for="(item, index) in list" :key="`content_${index}`">
					{{ item }}
				</li>
			</ul>
			<div class="btn-wrap">
				<button class="btn btn-gray" @click="$emit('leftBtnEv')" v-if="leftBtnText">{{ leftBtnText }}</button>
				<button class="btn btn-complete" @click="$emit('rightBtnEv')" v-if="rightBtnText">{{ rightBtnText }}</button>
			</div>
		</div>
		<div class="imgbox">
			<img v-if="noticeImg" :src="noticeImg" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'CompleteNotice',
	props: {
		title: {
			type: String,
			default: '',
		},
		titleImg: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		leftBtnText: {
			type: String,
			default: '',
		},
		rightBtnText: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
		noticeImg: {
			type: String,
			default: '',
		},
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.complete-notice {
	width: 100%;
	border-radius: 10px;
	border: 1px solid $LINE_DIVIDER;
	display: flex;
	align-items: flex-start;
	padding: 0 40px;
	height: fit-content;
	min-height: 320px;
	position: relative;
	.txtbox {
		margin-top: 40px;
		.title {
			display: inline-flex;
			margin-bottom: 24px;
			align-items: center;
			h6 {
				font-size: 1.25rem;
				font-weight: 700;
			}
			img {
				width: 32px;
				height: 32px;
				margin-right: 8px;
			}
		}
		p {
			font-size: 0.875rem;
			color: $COLOR_666;
			font-weight: 500;
		}
		ul {
			display: block;
			margin-top: 24px;
			li {
				display: flex;
				align-items: center;
				font-size: 0.875rem;
				font-weight: 500;
			}
		}
		.btn-wrap {
			position: absolute;
			bottom: 40px;
			left: 40px;
			display: flex;
			align-items: center;
			button {
				margin-right: 10px;
			}
		}
	}
	.imgbox {
		margin-left: auto;
		margin-right: 20px;
		margin-top: 12px;
	}
}
</style>
