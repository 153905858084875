import mainApi from '@/services/api/main'

const main = {
	namespaced: true,
	state: {
		banners: [],
		bestByCategory: [],
		mdPicks: [],
		isPro: false,
		proPageName: null,
	},
	getters: {},
	mutations: {
		setBanners: (state, payload) => {
			state.banners = payload
		},
		setBestByCategory: (state, payload) => {
			state.bestByCategory = payload
		},
		setMdPicks: (state, payload) => {
			state.mdPicks = payload
		},
		setIsPro: (state) => {
			state.isPro = true
		},
		setProPageName: (state, payload) => {
			state.proPageName = payload || null
		}
	},
	actions: {
		lookupBanners: async ({ commit }) => {
			const response = await mainApi.lookupBanners()
			commit('setBanners', response.data)
		},
		lookupBestByCategory: async ({ commit }, params) => {
			const response = await mainApi.lookupBestByCategory(params)
			commit('setBestByCategory', response.data)
		},
		lookupMdPicks: async ({ commit }, params) => {
			const response = await mainApi.lookupMdPicks(params)
			commit('setMdPicks', response.data)
		},
	},
}

export default main
