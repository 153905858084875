import { requestLogger } from '@/utils/logger';
// import { getNewLogin, removeNewLogin } from '@/utils/common.js';

const makeSearchGaForm = (form) => {
    if(!form.category_id) {
        delete form.category_id
    } 
    return form
}

export default {
    //1. 접속
    gaEventLogin(form) {
        try {
            gtag('event', `store_begin`, form);
            // if(getNewLogin()) {
            //     requestLogger(`store_login`, form);
            //     removeNewLogin();
            // }
        } catch(e) {
            console.error(e);
        }
    },
    //2. 주문
    gaEventOrder(form) {
        try {
            gtag('event', `store_order`, form);
            requestLogger(`store_order`, form);
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventOrderSuccess(form) {
        try {
            gtag('event', `store_order_success`, form);
            requestLogger(`store_order_success`, form);
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventOrderFail(form) {
        try {
            gtag('event', `store_order_fail`, form);
            requestLogger(`store_order_fail`, form);
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventOrderExit(form) {
        try {
            gtag('event', `store_order_exit`, form);
            requestLogger(`store_order_exit`, form);
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventPurchase(form) {
        try {
            gtag('event', 'purchase', form);
            gtag('event', 'conversion', {
                'send_to': 'AW-580645254/DCsCCLOot-sCEIbj75QC',
                'value': form.value,
                'currency': form.currency,
                'transaction_id': form.transaction_id
            });
            gtag('event', 'conversion', {
                'send_to': 'AW-355275254/jchLCOz8t-sCEPajtKkB',
                'value': form.value,
                'currency': form.currency,
                'transaction_id': form.transaction_id
            });
            requestLogger(`purchase`, form);
        } catch(e) {
            console.error(e);
        }
    },

    //3. 이벤트
    gaEventCart(form) {
        try {
            gtag('event', `add_to_cart`, form);
            gtag('event', 'conversion', {'send_to': 'AW-580645254/O7s_COLltOsCEIbj75QC'});
            gtag('event', 'conversion', {'send_to': 'AW-355275254/DxXvCPGmjusCEPajtKkB'});
            requestLogger(`add_to_cart`, form);
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventWish(form) {
        try {
            gtag('event', `add_to_wishlist`, form);
            requestLogger(`add_to_wishlist`, form);
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventProduct(form) {
        try {
            gtag('event', `store_product`, form);
            requestLogger(`store_product`, form);
        } catch(e) {
            console.error(e);            
        }
    },
    
    //!!!!!!!!!
    gaEventSearch(form) {
        try {
            gtag('event', `store_search`, makeSearchGaForm(form));
            requestLogger(`store_search`, makeSearchGaForm(form));
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventSearchSuccess(form) {
        try {
            gtag('event', `store_search_success`, makeSearchGaForm(form));
            requestLogger(`store_search_success`, makeSearchGaForm(form));
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventSearchFail(form) {
        try {
            gtag('event', `store_search_fail`, makeSearchGaForm(form));
            requestLogger(`store_search_fail`, makeSearchGaForm(form));
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventSearchExit(form) {
        try {
            gtag('event', `store_search_exit`, makeSearchGaForm(form));
            requestLogger(`store_search_exit`, makeSearchGaForm(form));
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventSearchSuccessAction(form) {
        try {
            gtag('event', `store_search_success_action`, makeSearchGaForm(form));
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventBeginSearchAction(form) {
        try {
            if(form.category_id) {
                delete form.category_id
            } 
            gtag('event', `store_begin_search_action`, form);
            requestLogger(`store_begin_search_action`, form);
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventViewItem(form) {
        try {
            gtag('event', `view_item`, form);
            requestLogger(`view_item`, form);
        } catch(e) {
            console.error(e);
        }
    },
    //5. 결제
    gaEventPrice(form) {
        try {
            gtag('event', `store_price`, form);
            requestLogger(`store_price`, form);
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventPriceSuccess(form) {
        try {
            gtag('event', `store_price_success`, form);
            requestLogger(`store_price_success`, form);
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventPriceFail(form) {
        try {
            gtag('event', `store_price_fail`, form);
            requestLogger(`store_price_fail`, form);
        } catch(e) {
            console.error(e);            
        }
    },
    gaEventPriceExit(form) {
        try {
            gtag('event', `store_price_exit`, form);
            requestLogger(`store_price_exit`, form);
        } catch(e) {
            console.error(e);            
        }
    },

    gaEventSendPageView(form) {
        try {
            gtag('event', `page_view`, form);
        } catch(e) {
            console.error(e);            
        }
    },
    getGaClientId() {
        return new Promise((resolve, reject) => {
            gtag('get', 'G-EPKGQKQHBY', 'client_id', (client_id)=>{
                resolve(client_id);                
            });
        });
    },
    gaEventSendJoin() {
        try {
            gtag('event', `store_join`, {'action_type': 'click'});
            gtag('event', 'conversion', {'send_to': 'AW-355275254/o-TFCMGNuOsCEPajtKkB'});
            gtag('event', 'conversion', {'send_to': 'AW-580645254/ye7KCN3Mt-sCEIbj75QC'});
        } catch(e) {
            console.error(e);
        }
    }
}
