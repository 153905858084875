export default {
	info: {
		id: 1,
		status: 'WAIT',
		name: 'Terahydrofuran',
		subname: '(YYMMDD-123456)',
		create_datetime: '2022-05-13T02:58:56.000Z',
		seller: {
			id: '1111',
			ceoName: '김건우',
			corpNo: '630-86-00874',
			corpName: '스마트잭',
			phone: '010-1234-1234',
			address: '서울 성동구 성수일로4가길 10, 3층',
		},
		assignee: {
			id: '0101010',
			name: '포다쿤',
			email: 'hyeonggon.kang@smartjackwp.com',
			phone: '010-1234-1234',
			group: {
				id: '3333',
				name: '스마트잭',
			},
		},
		recipient: {
			groupName: '스마트잭',
			name: '책임자 이름',
			phone: '010-1234-5678',
			email: '1234@naver.com',
		},
		request: {
			num: '20220404',
			create_datetime: '2022-05-13T02:58:56.000Z',
			product: {
				name: 'Spectrophotometer',
				productCode: '401764',
				volume: '1',
				volumeUnit: 'kg',
				count: 3,
				brand: {
					id: '브랜드 아이디',
					name: 'Sigma-Aldrich',
				},
			},
			content: '8월 초에 사용해야합니다 10개 구할수 있나요?',
		},
		payment: {
			means: 'TAX', // 결제 수단,
			method: 'LATER', // 결제 방법
		},
		memo: '메모필드입니다.',
	},
	list: [
		{
			id: 1,
			status: 'WAIT',
			estimateCode: 'YYMMDD-123456',
			name: 'Tetrahydrofuran',
			subname: 'anhydrous, 99.9%',
			seller: {
				id: 111,
				name: '스마트잭',
			},
			price: 220000,
			create_datetime: '2022-05-13T02:58:56.000Z',
			update_datetime: '2022-05-13T02:58:56.000Z',
		},
		{
			id: 2,
			status: 'CONFIRM',
			estimateCode: 'YYMMDD-123456',
			name: 'Tetrahydrofuran',
			subname: 'anhydrous, 99.9%',
			seller: {
				id: 111,
				name: '스마트잭',
			},
			price: 11000000,
			create_datetime: '2022-05-13T02:58:56.000Z',
			update_datetime: '2022-05-13T02:58:56.000Z',
		},
		{
			id: 3,
			status: 'COMPLETE',
			estimateCode: 'YYMMDD-123456',
			name: 'Tetrahydrofuran',
			subname: 'anhydrous, 99.9%',
			seller: {
				id: 111,
				name: '스마트잭',
			},
			price: 2222222222,
			create_datetime: '2022-05-13T02:58:56.000Z',
			update_datetime: '2022-05-13T02:58:56.000Z',
		},
	],
}
