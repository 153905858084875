import requestAPI from '@/services/api/request-product'

const requestProducts = {
	namespaced: true,
	state: {
		list: [],
		count: 0,
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
	},
	actions: {
		getOtherList: async ({ commit }, { instituteId, params }) => {
			const response = await requestAPI.getOtherList(instituteId, params)
			commit('setList', response.data)
		},
		getOtherDetail: async (_, { instituteId, id }) => {
			const response = await requestAPI.getOtherDetail(instituteId, { id })
			return response.data
		},
	},
}

export default requestProducts
