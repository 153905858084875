<template>
	<div class="InquiryEdit">
		<content-title :title="title" />
		<div v-if="!isComplete" class="content-wrapper">
			<content-banner text="랩매니저 스토어를 이용하면서 느끼신 불편사항이나 바라는 점을 알려주세요." :leftImg="leftImg" :rightImg="rightImg" />
			<span class="notice">*문의량이 많아 답변은 24시간 이상 소요될 수 있습니다.</span>
			<div class="form-box">
				<div class="flex">
					<select-form
						title="문의유형"
						placeholder="문의유형을 선택해주세요"
						required
						:options="inquiryTypes"
						:selectId="info.typeId"
						@change="info.typeId = $event.id"
					/>
					<select-item-with-btn-form
						title="주문 선택"
						placeholder="주문내역을 선택해 주세요"
						btnText="주문 선택하기"
						:selectItem="info.product"
						@clickEv="showModalOrder"
						@delete="info.product = {}"
					/>
				</div>
				<content-form
					title="문의내용"
					placeholder="문의내용을 작성해 주세요"
					required
					@change="info.content = $event"
					@byte="byte = $event"
				/>
				<attachment-form title="이미지 첨부" subname="*20Mb이내 / jpg, png, pdf만 가능" :list="info.images" @change="info.images = $event" />
			</div>
			<div class="btn-wrap bottom">
				<button class="btn-lg btn" @click="$router.go(-1)">취소</button>
				<button class="btn btn-lg btn-complete" @click="submit">작성하기</button>
			</div>
		</div>
		<complete-notice
			v-else
			title="고객님의 1:1문의가 작성 완료되었습니다"
			description="문의 작성하신 내용이 정상적으로 접수 완료 되었습니다<br>문의량이 많아 답변등록까지 24시간 이상이 소요될 수 있습니다"
			rightBtnText="목록으로"
			:noticeImg="noticeImg"
			@rightBtnEv="goList"
		/>

		<!--modals-->
		<modal-select-order title="문의하실 상품을 선택해 주세요" @select="selectItem" />
	</div>
</template>

<script>
import ContentTitle from '@/components/content/ContentTitle'
import ContentBanner from '@/components/content/ContentBanner'
import SelectForm from '@/components/form-item/SelectForm'
import SelectItemWithBtnForm from '@/components/form-item/SelectItemWithBtnForm'
import ContentForm from '@/components/form-item/ContentForm'
import AttachmentForm from '@/components/form-item/AttachmentForm'
import CompleteNotice from '@/components/content/CompleteNotice'
import PageName from '@/utils/define/PageName'
import ModalSelectOrder from '@/views/cs-center/modals/ModalSelectOrder'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
	name: 'InquiryEdit',
	components: {
		ContentTitle,
		ContentBanner,
		ContentForm,
		SelectForm,
		SelectItemWithBtnForm,
		AttachmentForm,
		CompleteNotice,
		ModalSelectOrder,
	},
	data() {
		return {
			leftImg: require('@/assets/svg/content/left-inquiry-image.svg'),
			rightImg: require('@/assets/svg/content/right-inquiry-image.svg'),
			noticeImg: require('@/assets/svg/content/postbox-image.svg'),
			info: {
				typeId: '',
				product: {},
				content: '',
				images: [],
			},
			byte: 0,
			valid: {
				typeId: true,
				content: true,
			},
			isComplete: false,
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('inquiry', ['inquiryTypes']),
		isEdit() {
			return this.$route.params.mode === 'edit'
		},
		title() {
			return this.isEdit ? '문의 수정하기' : '문의 작성하기'
		},
		product() {
			return this.$route.query.product ? JSON.parse(this.$route.query.product) : {}
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				this.info.product = this.product
				await this.fetchInquiryType()
				if (this.$route.query.product) {
					this.info.typeId = this.inquiryTypes[0].id
				}
			},
			immediate: true,
		},
	},
	mounted() {},
	methods: {
		...mapActions('inquiry', ['lookupInquiryType', 'addInquiry']),
		async fetchInquiryType() {
			await this.lookupInquiryType(this.instituteId)
		},
		validation() {
			let validKeys = Object.keys(this.valid)
			let isValid = true
			validKeys.forEach(key => {
				if (!this.info[key]) {
					this.valid[key] = false
					isValid = false
				}
			})

			return isValid
		},
		async submit() {
			if (!this.validation()) return this.$root.toast('작성 실패', '문의유형을 선택해주세요.', 'error')
			if (this.byte > 1000) return this.$root.toast('작성 실패', '문의내용은 1,000자 이하로 작성할 수 있습니다.', 'error')

			try {
				const formData = new FormData()
				formData.append('typeId', this.info.typeId)
				formData.append('content', this.info.content)
				this.info.images.forEach(item => {
					formData.append('images', item)
				})
				if (!this.$_.isEmpty(this.info.product)) formData.append('orderItemId', this.info.product.orderItemId)

				await this.addInquiry({
					instituteId: this.instituteId,
					formData,
				})
				this.isComplete = true
				window.scrollTo(0, 0)
			} catch (e) {
				this.$root.toast('작성 실패', e.response.data.msg, 'error')
			}
		},
		showModalOrder() {
			//주문 선택 modal
			this.$bvModal.show('ModalSelectOrder')
		},
		selectItem(item) {
			console.log(item)
			this.info.product = item
			this.$bvModal.hide('ModalSelectOrder')
		},
		goList() {
			this.$router.push({
				name: PageName.CsCenter.InquiryManagement,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.InquiryEdit {
	.notice {
		font-size: 0.75rem;
		font-weight: 500;
		color: $COLOR_999;
		margin-top: 14px;
	}
	.form-box {
		margin-top: 40px;
		.flex {
			margin-bottom: 40px;
			.select-form {
				width: 240px;
			}
			.select-item-with-btn-form {
				width: calc(100% - 264px);
				margin-left: auto;
			}
		}
		.attachment-form {
			margin-top: 40px;
		}
	}
	.bottom {
		margin: 64px 0;
	}
}
</style>
