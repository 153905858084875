export default {
	typeList: [
		{ name: '학교 소속 연구실', value: 'UNIVERSITY' },
		{ name: '기업 소속 연구실', value: 'ENTERPRISE' },
		{ name: '재판매업체(법인)', value: 'SELLER' },
		{ name: '일반 개인', value: 'PERSON' },
	],
	stepTitle(step, type) {
		switch (step) {
			case 1:
				return '고객님의 <b>소속 유형</b>을 선택해주세요.'
			case 2:
				return '<b>소속 정보</b>를 입력해주세요.'
			case 3:
				if (type == 'UNIVERSITY') {
					return '<b>교수님 성함</b>을 입력해주세요.'
				} else if (type == 'ENTERPRISE') {
					return '<b>구매 담당자 성함</b>을 입력해주세요.'
				} else if (type == 'SELLER') {
					return '<b>재판매업체(법인) 이름</b>을 입력해주세요.'
				}
			case 4:
				return '<b>사업자등록증</b>을 업로드해주세요.'
			case 5:
				if (type == 'PERSON') {
					return '<b>배송지 정보</b>를 입력해주세요.'
				} else {
					return '<b>배송지</b>를 입력해주세요.'
				}
			case 6:
				return `<b class="yellow"></b>에 소속된 <b>멤버를 초대</b>해 주세요.`
		}
	},
	errorMessage(step, type) {
		switch (step) {
			case 1:
				return '고객님의 소속 유형을 선택해주세요'
			case 2:
				return '소속 정보를 입력해주세요.'
			case 3:
				if (type == 'UNIVERSITY') {
					return '교수님 성함을 입력해주세요.'
				} else if (type == 'ENTERPRISE') {
					return '구매 담당자 성함을 입력해주세요.'
				} else if (type == 'SELLER') {
					return '재판매업체(법인) 이름을 입력해주세요.'
				}
			case 4:
				return '사업자등록증 별칭을 입력해주세요.'
			case 5:
				return '배송지 정보를 입력해주세요.'
		}
	},
	placeholder(key, type) {
		switch (key) {
			case 'name':
				return '예: 한국대학교 그린촉매연구실 or 한국제약 신약연구1팀'
			case 'managerName':
				if (type == 'SELLER') {
					return '예: 스마트잭'
				} else {
					return '예: 홍길동'
				}
			case 'certificationName':
				return '사업자등록증 별칭을 입력해주세요'
			case 'shippingName':
				if (type == 'UNIVERSITY') {
					return '학교명을 입력해주세요'
				} else {
					return '기업명을 입력해주세요'
				}
			case 'departmentName':
				if (type == 'UNIVERSITY') {
					return '학과명을 입력해주세요'
				} else {
					return '부서명을 입력해주세요'
				}
			case 'address':
				return '주소를 검색해주세요'
			case 'addressDetail':
				return '상세주소를 입력해주세요'
			case 'email':
				return '예: abc@abc.com, abc@smartjackwp.com<br>(쉼표, 엔터키, 스페이스바를 눌러 이메일 주소를 추가하세요)'
		}
	},
	infoKey(step) {
		switch (step) {
			case 1:
				return 'type'
			case 2:
				return 'name'
			case 3:
				return 'managerName'
			case 4:
				return 'certificationName'
		}
	},
}
