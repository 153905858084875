import axiosInstance from '@/services/axios'

const endPoint = {
	lookupRegistration: instituteId => `institute/${instituteId}/businessRegistration/list`,
	addRegistration: instituteId => `institute/${instituteId}/businessRegistration`,
	deleteRegistration: (instituteId, id) => `institute/${instituteId}/businessRegistration?businessRegistrationId=${id}`,
}

const businessRegistrationAPI = {
	lookupRegistration: instituteId => axiosInstance.get(endPoint.lookupRegistration(instituteId)),
	addRegistration: (instituteId, formData) =>
		axiosInstance.post(endPoint.addRegistration(instituteId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	deleteRegistration: (instituteId, id) => axiosInstance.delete(endPoint.deleteRegistration(instituteId, id)),
}

export default businessRegistrationAPI
