const table = {
	width: '100%',
	options: {
		selectColumnKey: 'productId',
		isCheckbox: true,
		table: {
			style: {
				borderRadius: '5px',
				border: '1px solid #ddd',
				marginTop: '50px',
			},
		},
		header: {
			style: {
				borderRadius: '5px 5px 0px 0px',
				color: '#666666',
				fontWeight: '700',
				fontSize: '14px',
				backgroundColor: '#f5f5f5',
			},
		},
		row: {
			style: {
				height: '80px',
			},

			click: {
				rowDataName: 'productId',
				keyName: 'value',
				clickEvent: () => {},
			},
		},
		columns: [
			{
				key: 'productCode',
				name: 'CAT No.',
				style: {
					width: '140px',
				},
				classes: [],
			},
			{
				key: 'productInfoName',
				name: '상품 이름',
				style: {
					width: '300px',
				},
				classes: [],
			},
			{
				key: 'productInfoOption',
				name: '사이즈',
				style: {
					width: '110px',
				},
				classes: [],
			},
			{
				key: 'productInfoDelivery',
				name: '재고 및 배송정보',
				style: {
					width: '200px',
				},
				classes: [],
			},
			{
				key: 'productInfoPrice',
				name: '단가',
				style: {
					width: '124px',
				},
				classes: [],
			},
			{
				key: 'selectedCount',
				name: '수량',
				style: {
					width: '135px',
				},
				classes: [],
			},
		],
		tableData: {
			productId: [
				{
					data: {
						id: 1333614,
					},
					value: 1333614,
				},
			],
			productCode: [
				{
					value: '270725-1L',
				},
			],
			productInfoName: [
				{
					value: 'Acetone',
				},
			],
			productInfoOption: [
				{
					value: '1L',
				},
			],
			productInfoDelivery: [
				{
					value: '바로 배송',
				},
			],
			productInfoPrice: [
				{
					value: '103,900원',
				},
			],
			selectedCount: [
				{
					value: 12,
				},
			],
		},
	},
}
export default table
