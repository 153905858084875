<template>
	<base-form-item class="input-form-item" :title="title" :required="required" :direction="direction" :description="description">
		<component
			:is="inputComponent"
			:ref="ref"
			:value="value"
			:placeholder="placeholder"
			:maxlength="maxlength"
			:min="min"
			:max="max"
			:disabled="disabled"
			:readonly="readonly"
			:autofocus="autofocus"
			:error="error"
			:errorMessage="errorMessage"
			:inputStyle="inputStyle"
			:postcode="postcode"
			:address="address"
			:addressDetail="addressDetail"
			@change="$emit('change', $event)"
			@input="$emit('input', $event)"
			@keypress="$emit('keypress', $event)"
			@keydown="$emit('keydown', $event)"
			@keyup="$emit('keyup', $event)"
			@search="$emit('searchAddress', $event)"
			@focus="$emit('focus', $event)"
			@blur="$emit('blur', $event)"
		/>
		<button v-if="button" class="btn" :class="{ disabled: buttonDisabled }" :disabled="buttonDisabled" @click="$emit('click', $event)">
			{{ button }}
		</button>
	</base-form-item>
</template>

<script>
import BaseFormItem from '@/components/form-item/BaseFormItem.vue'
import BaseInput from '@/components/input/BaseInput.vue'
import TextInput from '@/components/input/TextInput.vue'
import NumberInput from '@/components/input/NumberInput.vue'
import PasswordInput from '@/components/input/PasswordInput.vue'
import TelInput from '@/components/input/TelInput.vue'
import EmailInput from '@/components/input/EmailInput.vue'
import AddressInput from '@/components/input/AddressInput.vue'
import CardInput from '@/components/input/CardInput.vue'

export default {
	name: 'InputFormItem',
	components: {
		BaseFormItem,
	},
	mixins: [BaseFormItem, BaseInput],
	props: {
		type: {
			type: String,
			default: 'text',
		},
		button: {
			type: String,
			default: '',
		},
		buttonDisabled: {
			type: Boolean,
			default: false,
		},
		postcode: {
			type: String,
			default: '',
		},
		address: {
			type: String,
			default: '',
		},
		addressDetail: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			inputComponent: null,
			ref: `input-form-item-${Math.random()}`,
		}
	},
	created() {
		switch (this.type) {
			case 'text':
				this.inputComponent = TextInput
				break
			case 'number':
				this.inputComponent = NumberInput
				break
			case 'password':
				this.inputComponent = PasswordInput
				break
			case 'tel':
				this.inputComponent = TelInput
				break
			case 'email':
				this.inputComponent = EmailInput
				break
			case 'address':
				this.inputComponent = AddressInput
				break
			case 'card':
				this.inputComponent = CardInput
				break
		}
	},
	methods: {
		validate() {
			return this.$refs[this.ref].validate()
		},
	},
}
</script>

<style lang="scss" scoped>
.input-form-item {
	.btn {
		width: 89px;
		height: 40px;
		padding: 0;
		margin-left: 8px;
		font-size: 0.75rem;
		font-weight: 700;
		white-space: nowrap;
		background: #fec741;
		&.disabled {
			background: $LIST_BACKGROUND;
			color: $FONT_DISABLED;
		}
	}
}
</style>
