<template>
	<div class="more-info-button" @click="onClickBtn">
		<div class="icon" :class="{ hasBorder: hasBorder }">
			<slot></slot>
		</div>
		<div class="title-wrapper">
			<div class="title">{{ title }}</div>
			<div class="subTitle">{{ subTitle }}</div>
		</div>
		<div class="goto-button">보러가기 <img src="@/assets/svg/arrow_up_s.svg" /></div>
	</div>
</template>
<script>
export default {
	props: {
		hasBorder: {
			type: Boolean,
			default: false,
		},
		title: String,
		link: String,
		subTitle: String,
	},
	methods: {
		onClickBtn() {
			window.open(this.link)
		},
	},
}
</script>
<style lang="scss" scoped>
.more-info-button {
	display: flex;
	align-items: center;
	width: 530px;
	height: 64px;
	border: 1px solid #ddd;
	border-radius: 4px;
	background: white;
	margin-top: 10px;
	cursor: pointer;
	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 12px;
		width: 40px;
		height: 40px;
		&.hasBorder {
			border: 0.5px solid #ccc;
			border-radius: 5px;
		}
	}
	.title-wrapper {
		margin-left: 10px;
		.title {
			font-size: 14px;
			line-height: 20px;
			font-weight: 700;
			color: #111;
		}
		.subTitle {
			font-size: 12px;
			line-height: 17px;
			color: rgba(0, 0, 0, 0.6);
		}
	}
	.goto-button {
		margin-left: auto;
		margin-right: 12px;
		font-size: 14px;
		font-weight: 700;
		img {
			margin-left: 4px;
		}
	}
}
</style>
