<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade @close="cancel" hide-header-close>
		<template #modal-title>
			<div class="title">{{ title }}</div>
		</template>
		<template>
			<div class="content" v-html="content" v-if="noContent"></div>
			<slot></slot>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<button class="button-white" v-if="noCancel" @click="cancel">
					{{ cancelText }}
				</button>
				<button :class="`button-${variant}`" v-if="hasConfirm" @click="confirm">
					{{ confirmText }}
				</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
export default {
	name: 'ModalConfirm',
	components: {},
	props: {
		title: {
			type: String,
		},
		content: {
			type: String,
		},
		noCancel: {
			type: Boolean,
			default: true,
		},
		hasConfirm: {
			type: Boolean,
			default: true,
		},
		noContent: {
			type: Boolean,
			default: true,
		},
		confirmText: {
			type: String,
			default: '확인',
		},
		cancelText: {
			type: String,
			default: '취소',
		},
		variant: {
			type: String,
			default: 'primary',
		},
	},
	data() {
		return {
			confirmFn: null,
			cancelFn: null,
		}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		show(confirmFn = null, cancelFn = null) {
			if (confirmFn) this.confirmFn = confirmFn
			if (cancelFn) this.cancelFn = cancelFn
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		cancel() {
			this.$emit('cancel')
			if (this.cancelFn) this.cancelFn()
			this.hide()
		},
		confirm() {
			this.$emit('confirm')
			if (this.confirmFn) this.confirmFn()
			this.hide()
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .modal-content {
	box-shadow: 0px 2px 4px 0px #0000001a;
}

::v-deep .modal-dialog {
	max-width: 480px;

	.modal-content {
		background-color: white;
		// border-radius: 8px;
		border: 0;

		.form-wrap {
			padding: 20px;
			background: white;
			border: 1px solid #ccc;
			border-radius: 8px;
			gap: 20px;

			.form-header {
				font-weight: 700;
				font-size: 16px;

				.form-title {
				}

				.form-msg {
				}
			}
		}
	}
}

::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	padding: 0px 40px;
	border: 0;
	gap: 20px;
	overflow-x: hidden;

	.content {
		text-align: center;
		font-size: 14px;
		font-weight: 400;
	}
}

::v-deep .modal-header {
	padding: 40px;
	border: 0;
	justify-content: center;
}

::v-deep .modal-footer {
	padding: 40px;
	border: 0;

	.modal-footer-wrap {
		margin-top: 10px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;

		.footer-left,
		.footer-right {
			display: flex;
			gap: 10px;
		}

		button {
			border: 0;
			box-shadow: none;
			display: flex;
			height: auto;
			align-items: center;
			cursor: pointer;
			white-space: pre;
			min-width: 160px;
			height: 40px;
			padding: 5px 10px;
			font-size: 14px;
			font-weight: 700;
			border-radius: 5px;
			justify-content: center;
		}

		.button-white {
			background: white;
			border: 1px solid $LINE_DIVIDER;
			color: #111 !important;

			&:hover {
				background: $HOVER_BACKGROUND;
			}
		}

		.button-primary {
			background: $PRIMARY_YELLOW;

			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $PRIMARY_YELLOW, $PRIMARY_YELLOW);
			}
		}

		.button-warning {
			background: $PRIMARY_RED;
			color: white;

			&:hover {
				// background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $RED, $RED);
			}
		}

		.button-accept {
			background: $PRIMARY_GREEN;
			color: white;

			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $PRIMARY_GREEN, $PRIMARY_GREEN);
			}
		}

		.footer-right {
			margin-left: auto;
		}
	}
}

::v-deep .modal-title {
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;

	.title {
		color: #111 !important;
	}
}

::v-deep .underline {
	text-decoration: underline !important;
}
</style>
