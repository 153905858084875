import throttle from 'lodash/throttle'

export default {
	data() {
		return {
			scrollDom: window,
			top: 0,
			bottom: 0,
			lastScrollTop: 0,
			isSearchHide: false,
			scrollDirection: null,
			isBottom: false,
			scrollTop: 0,
			clientHeight: 0,
		}
	},
	beforeDestroy() {
		this.scrollDom.removeEventListener('scroll', this.onScroll)
	},
	mounted() {
		this.scrollDom.addEventListener('scroll', this.onScroll)
	},
	computed: {
		isHeaderSticky() {
			return this.scrollDirection == 'UP' && this.isSearchHide
		},
		isScrollBottom() {
			return this.scrollDirection == 'DOWN' && this.lastScrollTop > 92
		},
		isGoTop() {
			return Number(this.scrollTop + this.clientHeight) > 1024
		},
	},
	methods: {
		onScroll: throttle(function ({
			target: {
				documentElement: { scrollTop, clientHeight, scrollHeight },
			},
		}) {
			this.scrollTop = scrollTop
			this.clientHeight = clientHeight
			if (this.scrollTop > this.lastScrollTop) {
				this.scrollDirection = 'DOWN'
			} else {
				this.scrollDirection = 'UP'
			}
			this.isSearchHide = this.scrollTop > 40
			this.isBottom = scrollHeight - this.clientHeight - 100 < this.scrollTop && this.scrollTop > 0
			this.lastScrollTop = this.scrollTop
		}, 50),
		moveScrollTop() {
			this.$refs['scroll-container'].scrollTo(0, 0)
		},
		goScroll(scrollTop) {
			this.$refs['scroll-container'].scrollTop = scrollTop - 350
		},
		moveBeforeScroll() {
			this.goScroll(this.lastScrollTop + 350)
		},
		// // scroll event
		// scroll(event) {
		// 	console.log('event', event)
		// 	const top = document.documentElement.scrollTop
		// 	const clientHeight = document.documentElement.clientHeight
		// 	this.scrollTop = top
		// 	this.clientHeight = clientHeight
		// },
	},
}
