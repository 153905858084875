<template>
	<div>
		<base-management title="간편결제정보 관리" subTitleName="간편결제 카드 관리" buttonText="+ 새 카드 등록" @click="onClickAdd">
			<easy-payment-list :list="cards" @edit="onEditCard" @delete="onDeleteCard" />
		</base-management>

		<modal-easy-payment :title="modalTitle" :item="updateItem" @success="fetchCards" />
		<modal-cancel title="간편결제 카드 삭제" content="삭제된 간편결제 카드는 되돌릴 수 없습니다.<br/>정말로 삭제하시겠습니까?" @ok="onDelete" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import EasyPaymentList from '@/views/my-page/my-info/easy-payment/EasyPaymentList.vue'
import ModalEasyPayment from '@/views/my-page/my-info/easy-payment/modal/ModalEasyPayment.vue'
import ModalCancel from '@/components/modal/ModalCancel'

export default {
	name: 'EasyPaymentManagement',
	components: {
		BaseManagement,
		EasyPaymentList,
		ModalEasyPayment,
		ModalCancel,
	},
	data() {
		return {
			modalTitle: '',
			updateItem: {},
			deleteId: 0,
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('card', ['cards']),
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchCards()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('card', ['lookupCards', 'deleteCard']),
		async fetchCards() {
			await this.lookupCards(this.instituteId)
		},
		onClickAdd() {
			this.modalTitle = '카드 등록'
			this.updateItem = {}
			this.$bvModal.show('ModalEasyPayment')
		},
		onEditCard(item) {
			this.modalTitle = '카드 별칭 수정'
			this.updateItem = this.$_.cloneDeep(item)
			this.$bvModal.show('ModalEasyPayment')
		},
		onDeleteCard(id) {
			this.deleteId = id
			this.$bvModal.show('ModalCancel')
		},
		async onDelete() {
			try {
				await this.deleteCard({
					instituteId: this.instituteId,
					id: this.deleteId,
				})
				this.fetchCards()
				this.$root.toast('카드 삭제 성공', '해당 카드를 삭제했습니다.', ToastType.SUCCESS)
			} catch (e) {
				this.$root.toast('카드 삭제 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
