export const addressFinderOpen = window => {
	return new Promise(resolve => {
		window.daum.postcode.load(() => {
			/* eslint-disable */
			new daum.Postcode({
				oncomplete(data) {
					resolve(data);
				},
			}).open({
				popupName: 'KakaoPostCode',
				userLanguageType: 'K'
			});
		});
	});
};
