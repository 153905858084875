<template>
	<base-form-item :title="title" :required="required" :direction="direction">
		<button class="btn top" @click="$refs['file'].click()" style="display: flex; width: 80px">파일 선택</button>
		<div class="upload-form-item-wrapper">
			<div class="error-msg" v-if="error">{{ errorMessage }}</div>
			<div class="upload-area" @drop.prevent="dropFile" :class="{ error: error }">
				<input ref="file" type="file" @change="uploadFile" multiple />
				<img src="@/assets/svg/icon/icon-excel.svg" />
				<p>{{ description }}</p>
				<button class="btn btn-gray sm" @click="$refs['file'].click()">파일 선택</button>
			</div>
			<div v-if="files && files.length > 0" class="file-wrapper">
				<div v-for="(file, index) of files" :key="index" class="file">
					<div class="info">
						<img src="@/assets/svg/empty-doc.svg" />
						<span>{{ file.name }}</span>
					</div>
					<img src="@/assets/svg/close_c.svg" @click="onClickDeleteFile(file)" />
				</div>
			</div>
		</div>
	</base-form-item>
</template>

<script>
import BaseFormItem from '@/components/form-item/BaseFormItem.vue'

export default {
	name: 'UploadFileFormItem',
	props: {
		description: {
			type: String,
			default: '',
		},
		error: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
	components: {
		BaseFormItem,
	},
	extends: BaseFormItem,
	mounted() {
		this.files = this.list
	},
	data() {
		return {
			files: [],
		}
	},
	methods: {
		dropFile(e) {
			this.$refs['file'].files = e.dataTransfer.files
			this.files = [...this.files, ...this.$refs['file'].files]
			this.$refs['file'].value = ''

			this.$emit('upload', this.files)
		},
		uploadFile() {
			this.files = [...this.files, ...this.$refs['file'].files]
			this.$refs['file'].value = ''

			this.$emit('upload', this.files)
		},
		onClickDeleteFile(file) {
			this.files.splice(this.files.indexOf(file), 1)

			this.$emit('delete', this.files)
		},
	},
}
</script>

<style lang="scss" scoped>
.btn.top {
	margin-bottom: 1rem;
}

.error-msg {
	width: 100%;
	height: 40px;
	border-radius: 5px;
	background-color: $LIGHT_RED;
	font-size: 1rem;
	font-weight: 400;
	color: $PRIMARY_RED;
	display: flex;
	align-items: center;
	justify-content: center;
}

.upload-form-item-wrapper {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 10px;
	width: 100%;
	.upload-area {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 16px;
		height: 200px;
		background: white;
		border: 2px dashed $COLOR_DDD;
		border-radius: 4px;
		&.error {
			border: 2px dashed $PRIMARY_RED;
		}
		> p {
			color: black;
		}
		input[type='file'] {
			width: 100%;
			height: inherit;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			cursor: pointer;
		}
	}
	.file-wrapper {
		display: flex;
		flex-direction: column;
		gap: 8px;
		.file {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 12px 8px;
			border: 1px solid $COLOR_DDD;
			border-radius: 4px;
			.info {
				display: flex;
				align-items: center;
				gap: 8px;
			}
			> img {
				cursor: pointer;
			}
		}
	}
}
</style>
