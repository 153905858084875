<template>
	<div v-if="!isPerson" class="payment-receive-email">
		<div class="info-item">
			<div class="info-title">
				<div class="title">결제 증빙서류 수신 이메일 추가</div>
				<div class="subtitle">
					회계팀, 구매담당자 등 결제시 증빙에 필요한 서류(거래명세서, 견적서 외)를 수신할 담당자 이메일이 있다면 추가로 입력해주세요.
				</div>
			</div>
			<div class="info-content">
				<div>
					<div class="title">이메일</div>
					<div>
						<email-input ref="email" v-model="email" placeholder="이메일을 입력해주세요" :inputStyle="{ width: '320px' }"></email-input>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EmailInput from '@/components/input/EmailInput.vue'
import mixin from '@/mixins'
import { mapState } from 'vuex'

const INSTITUTE_TYPE = {
	PERSON: 'PERSON',
	UNIVERSITY: 'UNIVERSITY',
	ENTERPRISE: 'ENTERPRISE',
	SELLER: 'SELLER',
}

export default {
	name: 'PaymentReceiveEmail',
	components: {
		EmailInput,
	},
	mixins: [mixin],
	data() {
		return {
			email: '',
			isValid: true,
		}
	},
	computed: {
		...mapState('user', ['institute']),
		isPerson() {
			if (this.$_.isEmpty(this.institute)) return
			return this.institute.type.code === INSTITUTE_TYPE.PERSON
		},
	},
	methods: {
		getEmail() {
			return this.email
		},
		validate() {
			this.isValid = true
			if (!this.$refs['email'].validate()) this.isValid = false
			return this.isValid
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-receive-email {
	display: flex;
	flex-direction: column;
	gap: 64px;
	.info-item {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.info-title {
			display: flex;
			align-items: center;
			.title {
				font-weight: bold;
				font-size: 1.25rem;
			}
			.subtitle {
				height: 100%;
				font-size: 0.875rem;
				color: $COLOR_666;
				margin-left: 20px;
			}
		}
		.info-content {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 20px;
			border: 1px solid $COLOR_DDD;
			border-radius: 5px;
			> div {
				display: flex;
				align-items: center;
				> div {
					font-size: 0.875rem;
					font-weight: 500;
				}
				.title {
					width: 124px;
				}
			}
		}
	}
}
</style>
