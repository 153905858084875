import axios from 'axios'
import userAPI from '@/services/api/user'
import qs from 'qs'
import { goToSSOMain } from '@/services/api/common'

const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
	timeout: 100000,
})
axiosInstance.defaults.paramsSerializer = params => {
	return qs.stringify(params, { encode: false, arrayFormat: 'brackets' })
}
axiosInstance.defaults.headers.common['X-Platform'] = 'pc-web'

axiosInstance.interceptors.request.use(
	function (config) {
		return config
	},
	function (error) {
		return Promise.reject(error)
	}
)

axiosInstance.interceptors.response.use(
	function (response) {
		return response
	},
	function (error) {
		try {
			switch (error.response.status) {
				case 401:
					userAPI.logout()
					goToSSOMain()
					break
				case 404:
					console.warn(error.response.data.msg)
					break
				default:
					break
			}

			return Promise.reject(error)
		} catch (error) {
			return Promise.reject(error)
		}
	}
)

axiosInstance.setAuth = function (token) {
	axiosInstance.defaults.headers.common['Authorization'] = token
}

export default axiosInstance
