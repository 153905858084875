import axiosInstance from '@/services/axios'
import { logout } from '@/services/api/common'

const endPoint = {
	login: '/user/signin',
	logout: '/user/signout',
	getUserInfo: '/user',
	getInstitute: '/user/institute',
	updateUser: '/user',
	changePassword: '/user/password',
	checkPassword: '/user/password/check',
	verification: '/user/verification',
	access: '/user/access',
	point: '/user/point',
	pointTarget: '/user/point/checktarget',
	pointLog: '/user/point/log',
}

const userAPI = {
	login: params => {
		return axiosInstance.post(endPoint.login, params)
	},
	logout: () => {
		logout()
		return axiosInstance.post(endPoint.logout)
	},
	getUserInfo: () => {
		return axiosInstance.get(endPoint.getUserInfo)
	},
	getInstitute: () => {
		return axiosInstance.get(endPoint.getInstitute)
	},
	updateUser: formData => {
		return axiosInstance.patch(endPoint.updateUser, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	},
	changePassword: body => {
		return axiosInstance.patch(endPoint.changePassword, body)
	},
	checkPassword: body => {
		return axiosInstance.post(endPoint.checkPassword, body)
	},
	verification: body => {
		return axiosInstance.post(endPoint.verification, body)
	},
	access: body => {
		return axiosInstance.post(endPoint.access, body)
	},
	point: () => axiosInstance.get(endPoint.point),
	pointTarget: () => axiosInstance.get(endPoint.pointTarget),
	pointLog: params => axiosInstance.get(endPoint.pointLog, { params }),
	usePoint: body => axiosInstance.patch(endPoint.point, body),
}

export default userAPI
