<template>
	<div :class="mode">
		<div class="title" v-if="title">
			<h4>{{ title }}</h4>
			<button @click="selectRemoveItem">선택 삭제</button>
		</div>
		<div class="product-select-table">
			<div class="table-wrap">
				<table class="select-table">
					<table-header :allCheck="allCheck" :headers="headerList" :mode="mode" @change="changeAllChecked" />
					<!-- :disabled="mode == 'detail' && optionList.length == 1" -->
					<tbody>
						<table-item
							ref="table-item"
							v-for="(item, index) in optionList"
							:key="`${item.productId}-${item.id}-${index}`"
							:selectedList="selectedOptions"
							:index="index"
							:item="item"
							:allCheck="allCheck"
							:mode="mode"
							:headers="headerList"
							:countType="countType"
							@selected="changeSelected"
							@changeCount="changeCount"
							@remove="removeItem"
						/>
						<!-- :disabled="mode == 'detail' && optionList.length == 1" -->
					</tbody>
				</table>
			</div>
			<cart-product-total-price ref="cart-product-total-price"></cart-product-total-price>
		</div>
		<div class="button-wrapper">
			<!-- todo: 프로 연동 후 작업 -->
			<!-- <button class="btn-default btn-cart lg" @click="setCart">지출결의서 작성</button> -->
			<button v-if="mode.indexOf('detail') >= 0" class="btn-default btn-cart" @click="setCart">장바구니 담기</button>
			<button class="btn-order" @click="draftForm" :disabled="mode == 'cart' && selectedOptions.length == 0">주문하기</button>
			<div v-if="isShowCartTooltip" class="cart-tooltip">
				<div>
					<p>장바구니<span>에 담겼습니다.</span></p>
					<img src="@/assets/svg/main/icon-close.svg" @click="isShowCartTooltip = false" />
				</div>
				<button class="btn btn-primary btn-view-cart lg" @click="gotoCart">장바구니 보러가기</button>
			</div>
		</div>
	</div>
</template>

<script>
import CartProductTotalPrice from '@/views/cart/components/CartProductTotalPrice.vue'
import TableHeader from '@/components/table/TableHeader.vue'
import TableItem from '@/components/table/TableItem'
import mixin from '@/mixins'
import CartMixin from '@/mixins/cart'
import InstituteMixin from '@/mixins/institute'
import { mapGetters, mapActions } from 'vuex'
import { isLogin } from '@/services/api/common'
import { gaEventCart } from '@/utils/ga'

export default {
	name: 'ProductSelectTable',
	components: {
		TableItem,
		TableHeader,
		CartProductTotalPrice,
	},
	mixins: [mixin, CartMixin, InstituteMixin],
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		mode: {
			type: String,
			default: '',
		},
		headerList: {
			type: Array,
			default: () => [],
		},
		selectedList: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: '',
		},
		sellerIndex: {
			type: Number,
			default: 0,
		},
		countType: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			allCheck: false,
			selectedOptions: [],
			price: 0,
			optionList: [],
			checkList: [],
			isShowCartTooltip: false,
		}
	},
	watch: {
		selectedList: {
			handler(newVal) {
				if (this.mode == 'detail') {
					this.selectedOptions = newVal
				} else {
					// this.selectedOptions = JSON.parse(JSON.stringify(this.list))
				}
			},
			immediate: true,
		},
		list: {
			handler(newVal) {
				if (newVal.length <= 0) return

				this.optionList = JSON.parse(JSON.stringify(newVal))
			},
			immediate: true,
		},
	},
	computed: {
		...mapGetters('user', ['instituteId']),
	},
	methods: {
		...mapActions('cart', ['addCart']),
		...mapActions('user', ['getInstitute']),
		changeAllChecked(val) {
			this.allCheck = val
		},
		changeCount(id, quantity) {
			this.$emit('changeCount', id, quantity)

			const option = this.selectedOptions.find(option => option.id === id)
			if (!option) return

			option.quantity = quantity
			this.$emit('change', id, quantity)
			this.setProductTotalPrice()
		},
		changeSelected(select, index, item, quantity) {
			let selectItem = item
			selectItem.quantity = quantity
			if (select) {
				const findOption = this.selectedOptions.find(option => option.id === item.id)
				if (!findOption) {
					this.selectedOptions.push(selectItem)
					this.checkList.push(item.id)
				}
			} else {
				this.selectedOptions = this.selectedOptions.filter((el, index) => this.selectedOptions[index] !== item)
				this.checkList = this.checkList.filter(id => id !== item.id)
			}
			this.$emit('changeSelected', this.optionList[index].id, select)
			this.setProductTotalPrice()
		},
		removeItem(id) {
			this.$emit('remove', [id])
			this.optionList = this.optionList.filter(item => item.id !== id)
			this.changeSelectOptions([id])
			this.setProductTotalPrice()
		},
		selectRemoveItem() {
			this.$emit('remove', this.checkList)
			this.optionList = this.optionList.filter(item => {
				if (this.checkList.indexOf(item.id) === -1) return item
			})
			this.changeSelectOptions(this.checkList)
			this.setProductTotalPrice()
		},
		changeSelectOptions(ids) {
			let newSelectOptions = []
			this.optionList.forEach((item, index) => {
				if (this.selectedOptions.includes(item)) {
					newSelectOptions.push(item)
				}
			})
			this.selectedOptions = newSelectOptions
		},
		async setCart() {
			if (this.getChecked().length == 0) {
				this.$emit('showModalCancel', '옵션 선택 없음', '옵션을 선택하지 않았습니다. 옵션을 선택해주세요.')
				return
			}

			try {
				const productList = this.getChecked().map(({ productId, quantity }) => {
					return { productId, quantity }
				})

				if (!isLogin()) {
					this.setNonMemberCartProductList([...this.getNonMemberCartProductList(), ...productList])
				} else {
					await this.addCart({ instituteId: this.instituteId, body: { products: productList } })
					await gaEventCart(this.getChecked())
					await this.getInstitute()
					this.refreshCurrentInstitute()
				}
				this.isShowCartTooltip = true
			} catch (e) {
				console.error(e)
			}
		},
		async draftForm() {
			// const data = JSON.stringify(this.selectedOptions.map(item => item.id))
			// this.$emit('cartToDraft', data)
			// if (this.mode == 'detail') {
			// 	if (this.selectedList.length == 0) {
			// 		this.$bvModal.show('ModalCommon')
			// 	} else {
			// 		this.$emit('draftWriting')
			// 	}
			// } else {
			// 	// this.$emit('makeDraftForm')
			// 	this.$bvModal.show('ModalCompleteReport')
			// }

			this.$emit('order')
		},
		getChecked() {
			return this.$refs['table-item'].filter(component => component.checked === true).map(component => component.getItem())
		},
		setProductTotalPrice() {
			const productList = this.getChecked().map(({ productId, quantity }) => {
				return { productId, quantity }
			})

			if (this.$_.isEmpty(this.$refs['cart-product-total-price'])) return
			this.$refs['cart-product-total-price'].setProductList(productList)
		},
		gotoCart() {
			this.$router.push({
				name: 'Cart',
			})
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/select-table.scss';
.title {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	h4 {
		font-size: 1.25rem;
	}
	button {
		height: 36px;
		padding: 0 10px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-left: auto;
		font-size: 0.875rem;
		font-weight: 700;
		border-radius: 4px;
		background-color: $COMMON_BACKGROUND;
	}
}
.detailheader {
	.product-select-table {
		.table-wrap {
			max-height: 300px;
			overflow-y: auto;
		}
	}
}
.product-select-table {
	.select-table-header {
		.checkbox {
			margin-top: 4px;
			&:before {
				background-color: #f5f5f5;
			}
		}
	}
	.select-table-body {
		td {
			height: 80px;
		}
	}
}
.button-wrapper {
	width: 100%;
	text-align: right;
	margin: 20px 0;
	position: relative;
	button {
		text-align: center;
		margin-left: 10px;
	}
	.btn-cart,
	.btn-order {
		height: 48px;
		width: 226px;
		margin-left: 10px;
		font-weight: 700;
		font-size: 16px;
		border-radius: 5px;
	}
	.btn-cart {
		background: #fff;
		border: 1px solid #eeeeee;
		&:hover {
			background: #f5f5f5;
		}
	}
	.btn-order {
		background: $PRIMARY_YELLOW;
		&:hover {
			background: #ffb000;
		}
	}
	.cart-tooltip {
		padding: 20px 15px 15px 15px;
		width: 350px;
		height: 113px;
		top: -130px;
		left: 560px;
		border-radius: 5px;
		border: solid 1px #ddd;
		position: absolute;
		background: $PRIMARY_WHITE;
		div {
			display: flex;
			justify-content: space-between;
			img {
				cursor: pointer;
			}
		}
		p {
			font-weight: 700;
			font-size: 16px;
			span {
				font-weight: 400;
			}
		}
		.btn-view-cart {
			width: 100%;
			height: 40px;
			margin-top: 10px;
			background: $PRIMARY_YELLOW;
			margin-left: 0;
			&:hover {
				background: #fec741 linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
				color: black;
			}
		}
	}
	.cart-tooltip:after {
		border-bottom: 0px solid transparent;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid $PRIMARY_WHITE;
		content: '';
		position: absolute;
		top: 110px;
		left: 160px;
		z-index: 1004;
	}
	.cart-tooltip:before {
		border-bottom: 0px solid transparent;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid #ddd;
		content: '';
		position: absolute;
		top: 111px;
		left: 160px;
		z-index: 1003;
	}
}
</style>
