<template>
	<div class="search-box-wrapper">
		<b-dropdown variant="transparent" menu-class="option-menu" no-caret>
			<template v-slot:button-content>
				<div class="type">
					<span>{{ typeText[currentType] }}</span>
					<img src="@/assets/svg/dropdown.svg" />
				</div>
			</template>
			<b-dropdown-item v-for="(value, key) in typeText" :key="key" @click="currentType = key">{{ value }}</b-dropdown-item>
		</b-dropdown>
		<input
			class="searchbox-keyword"
			ref="keyword"
			:value="keyword"
			@input="inputKeyword"
			@keyup.enter="searchProduct({ value: keyword, type: currentType }, 0)"
			@focus="keywordFocus = true"
			placeholder="물품명, CAS No, 제품번호, 브랜드, 판매자로 검색해주세요"
		/>
		<div class="icon-wrapper">
			<img class="delete" v-if="keyword.length > 0" @click="keyword = ''" src="@/assets/svg/delete.svg" />
			<!-- <img class="qr" src="@/assets/svg/search-qr.svg" v-b-modal.ModalNoticeQrCode /> -->
			<!-- <div class="search" @click="searchProduct({ value: keyword, type: currentType }, 0)">
				<img src="@/assets/svg/search-white.svg" />
			</div> -->
		</div>
		<b-card v-if="!keyword" class="keyword-list" body-class="no-body" :class="{ active: keywordFocus && !isNoList }">
			<div class="title">
				<span>{{ title }}</span>
				<button v-if="!keyword" @click="removeRecentAllKeyword">모두 삭제</button>
			</div>
			<div class="line" v-for="(item, i) in keywordList" :key="i">
				<span class="keyword-type">
					<img v-if="item.isRecent" src="@/assets/svg/search-recent.svg" />
					<img v-else src="@/assets/svg/search-gray.svg" /> {{ keywordTypeText(item.type) }}</span
				>
				<span class="keyword-value" @click="searchProduct(item, 1)">{{ item.value }}</span>
				<span class="keyword-remove" v-if="item.isRecent" @click="setRemoveRecentKeyword(item)">
					<img src="@/assets/svg/delete.svg" />
				</span>
			</div>
		</b-card>
		<modal-notice-qr-code />
	</div>
</template>
<script>
import lodash from 'lodash'
import { mapActions, mapState } from 'vuex'
import ModalNoticeQrCode from './modal/ModalNoticeQrCode'
import PageName from '@/utils/define/PageName'
import loggerAPI from '@/services/api/logger'
const TYPE_TEXT = {
	ALL: '통합검색',
	NAME: '물품명',
	CASNO: 'CAS No',
	CATNO: '제품번호',
	BRAND: '브랜드',
	SELLER: '판매자',
}
export default {
	data() {
		return {
			keyword: '',
			currentType: 'ALL',
			keywordFocus: false,
			isFirstKeywordFocus: false,
		}
	},
	mounted() {},
	computed: {
		...mapState('search', ['keywordList']),
		...mapState('user', ['user']),
		typeText() {
			return TYPE_TEXT
		},
		isNoList() {
			return this.keywordList.length === 0
		},
		title() {
			return '최근 검색어'
			// todo: 추후에 추천 검색어 작업
			// if (this.keyword) {
			// 	return '검색어 추천'
			// } else {
			// 	return '최근 검색어'
			// }
		},
	},
	watch: {
		keyword() {
			this.updateInput()
		},
		keywordFocus() {
			if (this.keywordFocus) {
				this.getKeyword({ keyword: this.keyword, type: this.currentType })
			}
		},
		$route() {
			if (this.$route.query.keyword) {
				this.keyword = this.$route.query.keyword
				this.currentType = this.$route.query.type
				this.keywordFocus = false
			} else {
				this.keyword = ''
				this.currentType = 'ALL'
			}
		},
	},
	methods: {
		...mapActions('search', ['getKeyword', 'setRecentKeyword', 'removeRecentKeyword', 'removeRecentAllKeyword']),
		updateInput: lodash.debounce(function () {
			// if (!this.keywordFocus) this.keywordFocus = true
			this.getKeyword({ keyword: this.keyword, type: this.currentType })
		}, 350),
		blurKeyword: lodash.debounce(function () {
			this.keywordFocus = false
		}, 350),
		keywordTypeImage(isRecent) {
			if (isRecent) return '@/assets/svg/search-recent.svg'
			return '@/assets/svg/search-recent.svg'
		},
		keywordTypeText(type) {
			return TYPE_TEXT[type]
		},
		async searchProduct(keyword, recent) {
			//recent : 검색어 직접 입력하여 enter치거나 검색아이콘 누른 경우 0, 최근검색어에서 선택한 경우 1
			this.setRecentKeyword(keyword)
			this.keyword = keyword.value
			await loggerAPI.search({
				user_id: this.user.id,
				session_id: '',
				keyword: keyword.value,
				type: keyword.type || this.currentType,
				recent: recent,
			})
			await this.$router
				.push({
					name: PageName.SearchList,
					query: {
						keyword: this.keyword,
						type: keyword.type ? keyword.type : this.currentType,
					},
				})
				.catch(() => {})
		},
		inputKeyword(event) {
			this.keyword = event.target.value
		},
		setFocusToKeyword() {
			this.$refs['keyword'].focus()
		},
		setRemoveRecentKeyword(item) {
			this.removeRecentKeyword(item)
			this.getKeyword({ keyword: this.keyword, type: this.currentType })
		},
	},
	components: {
		ModalNoticeQrCode,
	},
}
</script>
<style lang="scss" scoped>
ul.dropdown-menu.option-menu.show {
	background-color: green;
	top: 5px !important;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	border: 0;
}
.search-box-wrapper {
	position: relative;
	display: flex;
	align-items: center;
	width: 500px;
	height: 48px;
	border: 2px solid $PRIMARY_YELLOW;
	border-radius: 16px;
	margin: auto;
	.dropdown {
		height: 100%;
		width: 122px;
		border-radius: 5px 0 0 5px;
		::v-deep .dropdown-toggle {
			height: 44px !important;
		}
		.type {
			font-size: 14px;
			font-weight: 400;
			color: #111;
			height: 24px;
			display: flex;
			align-items: center;
			padding-left: 7px;
			text-align: left;
			span {
				padding-right: 8px;
			}
			img {
				width: 8px;
				margin-left: auto;
				margin-right: 10px;
			}
			border-right: 1px solid #ddd;
		}
		.type::before {
			content: url(~@/assets/svg/search.svg);
			vertical-align: middle;
			margin-top: 4px;
			margin-right: 3px;
		}
		button {
			&:hover,
			&:focus {
				border: none !important;
			}
		}
	}
	input {
		font-size: 14px;
		font-weight: 400;
		border: none;
		box-shadow: none;
		width: 340px;
		height: 100%;
		padding-left: 8px;
	}
	.icon-wrapper {
		cursor: pointer;
		height: 100%;
		display: flex;
		align-items: center;
		margin-left: auto;
		.delete {
			// display: none;
			margin-right: 12px;
		}
		.qr {
			margin-right: 12px;
		}
		.search {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 100%;
			background-color: $PRIMARY_YELLOW;
		}
	}
	.keyword-list {
		z-index: 10;
		position: absolute;
		width: 380px;
		right: 0;
		max-height: 350px;
		top: 48px;
		overflow: auto;
		font-size: 14px;
		opacity: 0;
		visibility: hidden;
		&.active {
			opacity: 1;
			visibility: visible;
		}
		.title {
			width: 100%;
			height: 25px;
			align-items: flex-end;
			padding: 0 10px;
			display: flex;
			span {
				font-size: 0.75rem;
				font-weight: 400;
			}
			button {
				margin-left: auto;
				font-size: 0.75rem;
				font-weight: 700;
			}
		}
		.no-body {
			padding: 3px 0 10px;
			.line {
				display: flex;
				font-size: 12px;
				margin: 5px 5px 0;
				padding-left: 10px;
				padding-right: 10px;
				line-height: 30px;
				cursor: pointer;
				border-radius: 4px;
				&:hover {
					background-color: #f2f2f2;
				}
				.keyword-type {
					display: inline-block;
					width: 85px;
					img {
						width: 14px;
						margin-right: 10px;
					}
					color: #666;
				}
				.keyword-value {
					@include ellipse;
					color: black;
					display: inline-block;
					// padding-right: 20px;
					width: calc(100% - 110px);
				}
				.keyword-remove {
					position: absolute;
					right: 10px;
					img {
						width: 24px;
						padding: 4px;
					}
				}
			}
		}
	}
}
</style>
