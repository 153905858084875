<template>
	<section class="detailPage-wrapper wrapper" ref="detail-page-wrapper">
		<category-stack />
		<product-info-container
			:selectedList="selectedList"
			@changeList="changeList"
			@initList="initList"
			@draftWriting="draftWriting"
			@order="order"
			@showModalCancel="showModalCancel"
		/>
		<product-info-sticky-header
			:style="{ visibility: isHeaderSticky ? 'hidden' : 'visible' }"
			:activeProductInfoNav="activeProductInfoNav"
			@goScroll="goScroll"
		/>
		<product-info-fixed-header
			v-show="isHeaderSticky"
			:activeProductInfoNav="activeProductInfoNav"
			:headerList="headerList"
			:list="productList"
			@goScroll="goScroll"
			@changeCount="changeCount"
			@changeSelected="changeSelected"
			@draftWriting="draftWriting"
			@showModalCancel="showModalCancel"
			@order="order"
		/>
		<div ref="info-tab-header" class="data-header">상품 정보</div>
		<product-info-data ref="product-tab-header" id="product-tab" :informationList="informationList" />
		<br /><br />
		<product-select-table
			mode="detail"
			:headerList="headerList"
			:list="productList"
			countType="product"
			@changeCount="changeCount"
			@changeSelected="changeSelected"
			@draftWriting="draftWriting"
			@order="order"
			@showModalCancel="showModalCancel"
		/>
		<h4 ref="delivery-tab-header" class="data-header">배송 정보</h4>
		<info-list :informationList="deliveryInfo" />
		<h4 ref="return-tab-header" class="data-header">취소/반품/교환 정보</h4>
		<info-list :informationList="returnInfo" />
		<h4 ref="cs-tab-header" class="data-header">고객상담 정보</h4>
		<info-list :informationList="csInfo" />

		<!--modals-->
		<modal-draft-writing />
		<modal-cancel :title="modalCancelTitle" :content="modalCancelContent" :hideCancelBtn="true"></modal-cancel>
		<modal-payment-requirement @ok="$router.go()" />
		<modal-shipping-requirement @ok="$router.go()" />
	</section>
</template>

<script>
import throttle from 'lodash/throttle'
import table from '@/utils/define/productInfo/SelectedTable'
import CategoryStack from './components/CategoryStack'
import ProductInfoContainer from './components/ProductInfo'
import ProductInfoStickyHeader from './components/ProductInfoStickyHeader'
import ProductInfoFixedHeader from './components/ProductInfoFixedHeader'
import ProductInfoData from './components/ProductInfoData'
import ProductSelectTable from './components/ProductSelectTable'
import InfoList from '@/components/list/InfoList'
import cookies from 'vue-cookies'

import mixinInfo from '@/mixins/info'

import ModalDraftWriting from '@/components/modal/ModalDraftWriting'
import ModalCancel from '@/components/modal/ModalCancel'
import ModalPaymentRequirement from '@/views/payment/modal/ModalPaymentRequirement.vue'
import ModalShippingRequirement from '@/views/payment/modal/ModalShippingRequirement.vue'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import { getSYSUrl, isLogin, goToSSOMain } from '@/services/api/common'
import PageName from '@/utils/define/PageName'
import { ToastType } from '@/utils/define/ToastType'

import { gaEventViewItem } from '@/utils/ga'

const BLOCK_REASON = {
	PRODUCT_STOP: 'PRODUCT_STOP',
	PRODUCT_DELETE: 'PRODUCT_DELETE',
	SELLER_STOP: 'SELLER_STOP',
	PERSON_NOT_ALLOW: 'PERSON_NOT_ALLOW',
	INSTITUTE_BLOCK: 'INSTITUTE_BLOCK',
	SHAK_CANT_DELIVERY: 'SHAK_CANT_DELIVERY',
}

const INSTITUTE_TYPE = {
	PERSON: 'PERSON',
	UNIVERSITY: 'UNIVERSITY',
	ENTERPRISE: 'ENTERPRISE',
	SELLER: 'SELLER',
}

export default {
	components: {
		CategoryStack,
		ProductInfoContainer,
		ProductInfoStickyHeader,
		ProductInfoData,
		ProductSelectTable,
		ProductInfoFixedHeader,
		ModalDraftWriting,
		InfoList,
		ModalCancel,
		ModalPaymentRequirement,
		ModalShippingRequirement,
	},
	mixins: [mixinInfo],
	data() {
		return {
			BLOCK_REASON,
			productInfoId: this.$route.params.productInfoId,
			activeProductInfoNav: 'product-tab',
			isHeaderSticky: false,
			loadingHandler: null,
			selectedList: [],
			productList: [],
			modalCancelTitle: '',
			modalCancelContent: '',
		}
	},
	computed: {
		...mapState('delivery', ['basicAddress']),
		...mapState('productInfo', ['productInfo']),
		...mapState('institute', ['requirement']),
		...mapState('user', ['institute']),
		...mapGetters('user', ['instituteId']),
		...mapGetters('productInfo', ['shippingStatus', 'products']),
		table() {
			return table
		},
		routerWrapper() {
			// return document.body
			return this.$refs['detail-page-wrapper']
		},
		headerList() {
			let header = []
			header.push({ name: '옵션', value: 'option' })
			header.push({ name: '재고', value: 'stock' })
			header.push({ name: '출고정보', value: 'shipping' })
			header.push({ name: '판매 가격', value: 'price' })
			header.push({ name: '수량', value: 'quantity' })
			return header
		},
		//카테고리에 따라 내용이 바뀌어야 함
		informationList() {
			return !this.$_.isEmpty(this.productInfo) ? this.setInfoList(this.productInfo.category.code, this.productInfo) : []
		},
		//배송 정보
		deliveryInfo() {
			document.title = this.productInfo.name
			let list = []
			list.push({ title: '예상 출고일', value: this.shippingStatus, whole: true })
			if (this.productInfo.shippingDirectContact) {
				list.push({
					title: '개별 연락',
					value: '재고 확인 후 개별연락\n주문을 하시면, 판매자가 재고 및 예상 배송일을 확인하여 개별연락 드립니다.',
					whole: true,
				})
			}
			if (this.productInfo.shippingCanQuick) {
				list.push({ title: '퀵 서비스', value: '퀵 서비스 가능(착불)', whole: true })
			}
			if (this.productInfo.shippingInfo) {
				list.push({
					title: '배송 안내',
					value: this.productInfo.shippingInfo,
					whole: true,
				})
			}
			return list
		},
		//반품 정보
		returnInfo() {
			let list = []
			// if (this.productInfo.returnAddress) {
			// 	list.push({
			// 		title: '보내실 곳',
			// 		value: `${this.productInfo.returnAddress.address} ${this.productInfo.returnAddress.addressDetail}`,
			// 		whole: true,
			// 	})
			// }
			if (this.productInfo.returnInfo) {
				list.push({
					title: '취소/반품/교환 안내',
					value: this.productInfo.returnInfo,
					whole: true,
				})
			}
			return list
		},
		//CS 정보
		csInfo() {
			let list = []
			list.push({ title: '고객상담 연락처', value: this.productInfo.cs?.tel, whole: true })
			list.push({
				title: '상담 안내',
				value: this.productInfo.cs?.content,
				whole: true,
			})
			return list
		},
		impossibleOrderContent() {
			let title = ''
			const { blockReason } = this.productInfo
			switch (blockReason) {
				case BLOCK_REASON.PRODUCT_STOP:
				case BLOCK_REASON.PRODUCT_DELETE:
				case BLOCK_REASON.SELLER_STOP:
					title = '판매자에 의해 판매 중지된 상품입니다'
					break
				case BLOCK_REASON.PERSON_NOT_ALLOW:
					title = '유해화학물질 / 시약제품은 개인에게 판매하지 않습니다'
					break
				case BLOCK_REASON.SHAK_CANT_DELIVERY:
					title = '샥샥배송 가능한 지역이 아닙니다'
					break
			}

			return title
		},
		isPerson() {
			if (this.$_.isEmpty(this.institute)) return
			return this.institute.type.code === INSTITUTE_TYPE.PERSON
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				this.fetchProductInfo()
			},
			immediate: true,
		},
		products() {
			this.productList = this.$_.cloneDeep(
				this.products.map(item => {
					item.name = this.productInfo.name
					item.brand = this.productInfo.brand
					item.id = item.productId
					return item
				})
			)
		},
	},
	created() {
		this.loadingHandler = throttle(this.handleScroll, 100)
		window.addEventListener('scroll', this.loadingHandler)
		this.setRecentlyViewProducts()
	},
	beforeDestroy() {
		document.title = '요즘 랩실, 여기서 산다 | 랩매니저 스토어'
		window.removeEventListener('scroll', this.loadingHandler)
	},
	methods: {
		...mapMutations('order', ['setEstimateId', 'setSelectedProductList']),
		...mapActions('productInfo', ['lookupProductInfo']),
		...mapActions('institute', ['lookupRequirement']),
		setRecentlyViewProducts() {
			let recentlyViewProducts = cookies.get('recentlyViewProducts') ?? ''
			if (recentlyViewProducts.includes(this.productInfoId)) return
			recentlyViewProducts += `${this.productInfoId},`
			let strArray = recentlyViewProducts.split(',')
			if (strArray.length > 50) strArray.shift()
			recentlyViewProducts = strArray.join()
			cookies.set('recentlyViewProducts', recentlyViewProducts)
		},
		async fetchProductInfo() {
			try {
				const params = {
					productInfoId: this.productInfoId,
				}
				if (this.instituteId) params.instituteId = this.instituteId

				await this.lookupProductInfo(params)

				gaEventViewItem(this.productInfo)
			} catch (e) {
				this.$root.toast('상품 조회 실패', e.response.data.msg, 'error')
			}
		},
		handleScroll() {
			const y = document.documentElement.scrollTop
			if (y >= this.$refs['cs-tab-header'].offsetTop - 450) {
				this.activeProductInfoNav = 'cs-tab'
			} else if (y >= this.$refs['return-tab-header'].offsetTop - 300) {
				this.activeProductInfoNav = 'return-tab'
			} else if (y >= this.$refs['delivery-tab-header'].offsetTop - 300) {
				this.activeProductInfoNav = 'delivery-tab'
			} else {
				this.activeProductInfoNav = 'product-tab'
			}
			if (y >= this.$refs['info-tab-header'].offsetTop - 170) {
				this.isHeaderSticky = true
			} else {
				this.isHeaderSticky = false
			}
		},
		goScroll(href) {
			if (href === 'product-tab') document.documentElement.scrollTop = this.$refs['info-tab-header'].offsetTop - 170
			else document.documentElement.scrollTop = this.$refs[`${href}-header`].offsetTop - 300
		},
		initList(list) {
			this.changeList(list, true)
		},
		changeList(list, isInit) {
			this.selectedList = this.$_.cloneDeep(list)
			this.productList = this.productList.map(product => {
				const changeItem = list.find(item => item.id === product.id)
				if (changeItem) {
					return changeItem
				} else {
					product.quantity = 0
					return product
				}
			})
		},
		changeSelected(id, isSelect) {
			let changeProduct = null
			this.productList = this.productList.map(product => {
				if (product.id === id) {
					product.checked = isSelect
					changeProduct = product
				}
				return product
			})

			const findIndex = this.selectedList.findIndex(item => item.id === id)
			if (isSelect) {
				changeProduct.quantity = 1
				if (findIndex === -1) this.selectedList.push(changeProduct)
			} else {
				if (this.selectedList.length && this.selectedList[findIndex]) this.selectedList[findIndex].quantity = 0
				if (findIndex >= 0) this.selectedList.splice(findIndex, 1)
			}
		},
		changeCount(id, quantity) {
			let changeProduct = null
			this.productList = this.productList.map(product => {
				if (product.id === id) {
					product.quantity = quantity
					changeProduct = product
				}
				return product
			})

			const findIndex = this.selectedList.findIndex(item => item.id === changeProduct.id)
			if (changeProduct.quantity > 0) {
				if (findIndex === -1) {
					this.selectedList.push(changeProduct)
				} else {
					this.selectedList = this.selectedList.map((item, index) => {
						if (index === findIndex) return changeProduct
						return item
					})
				}
			} else {
				if (findIndex >= 0) this.selectedList.splice(findIndex, 1)
			}
		},
		draftWriting() {
			this.$bvModal.show('ModalDraftWriting')

			const purchaseItems = this.selectedList.map(item => {
				return {
					category: this.productInfo.category,
					brand: this.productInfo.brand,
					name: this.productInfo.name,
					code: this.productInfo.code,
					casno: this.productInfo.casno,
					price: item.price,
					quantity: item.quantity,
					categoryDisplayOption: item.options,
					images: this.productInfo.images.map(i => i.url),
					productId: item.id,
				}
			})
			const purchaseSeller = {
				name: this.productInfo.seller.name,
				contactName: this.productInfo.seller.contactName,
				contactPhone: this.productInfo.seller.contactPhone,
				email: this.productInfo.seller.email,
			}

			const data = JSON.stringify({ purchaseItems, purchaseSeller })
			this.$root.productToDraft(data)
		},
		showModalCancel(title, content) {
			this.modalCancelTitle = title
			this.modalCancelContent = content
			this.$bvModal.show('ModalCancel')
		},
		async order() {
			const { isBlock } = this.productInfo
			if (isBlock) {
				this.showModalCancel('주문 불가', this.impossibleOrderContent)
				return
			}

			if (this.selectedList.length == 0) {
				this.showModalCancel('옵션 선택 없음', '옵션을 선택하지 않았습니다. 옵션을 선택해주세요.')
				return
			}

			const productList = this.selectedList.map(({ productId, quantity }) => {
				return { productId, quantity }
			})

			if (!isLogin()) {
				goToSSOMain(location.href)
				return
			}
			await this.loadRequirement()

			if (this.requirement.canOrder) {
				this.setEstimateId()
				this.setSelectedProductList(productList)

				this.$router.push({
					name: PageName.Payment,
				})
			} else {
				if (this.isPerson) {
					this.$bvModal.show('ModalShippingRequirement')
				} else {
					this.$router.replace({
						hash: 'authentication',
					})
					this.$bvModal.show('ModalPaymentRequirement')
				}
			}
		},
		async loadRequirement() {
			if (!this.instituteId) return
			try {
				await this.lookupRequirement(this.instituteId)
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.detailPage-wrapper {
	width: 1080px;
	padding-bottom: 50px !important;
	.data-header {
		margin-top: 30px;
		font-size: 1.5rem;
		font-weight: 700;
	}
}
</style>
