<template>
	<b-modal
		ref="modal"
		:id="id"
		centered
		:title="title"
		ok-title="저장"
		ok-variant="warning"
		ok-only
		:ok-disabled="isDisabledBtn"
		button-size="lg"
		@show="onShow"
		@ok="onSave"
	>
		<div>
			<input-form-item
				v-model="info.name"
				direction="column"
				title="배송지명"
				placeholder="배송지명을 입력해주세요"
				:inputStyle="inputStyle"
				style="padding: 0"
				:autofocus="isRegist"
				required
			/>
			<input-form-item
				v-model="info.instituteName"
				direction="column"
				title="학교 / 기업명"
				placeholder="학교 / 기업명을 입력해주세요"
				:inputStyle="inputStyle"
				style="padding: 0"
				required
			/>
			<input-form-item
				v-model="info.groupName"
				direction="column"
				title="학과 / 부서명"
				placeholder="학과 / 부서명을 입력해주세요"
				:inputStyle="inputStyle"
				style="padding: 0"
				required
			/>
			<input-form-item
				:postcode="info.postcode"
				:address="info.address"
				:addressDetail="info.addressDetail"
				direction="column"
				type="address"
				title="주소"
				:inputStyle="inputStyle"
				@searchAddress="onSearchAddress"
				required
			/>
			<div class="chekbox-wrapper">
				<input v-model="info.isDefault" type="checkbox" id="save" name="save" class="checkbox" :disabled="item.isDefault === 1" />
				<label for="save">기본 배송지로 저장</label>
			</div>
		</div>
	</b-modal>
</template>

<script>
import InputFormItem from '@/components/form-item/InputFormItem.vue'
import { mapActions, mapGetters } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'ModalShippingAddress',
	components: {
		InputFormItem,
	},
	props: {
		title: {
			type: String,
			default: '배송지 등록',
		},
		item: {
			type: Object,
			default: () => ({}),
		},
		id: {
			type: String,
			default: 'ModalShippingAddress',
		},
	},
	data() {
		return {
			inputStyle: {
				width: '566px',
			},
			info: {},
			isDisabledBtn: true,
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		isRegist() {
			return this.$_.isEmpty(this.item)
		},
	},
	watch: {
		item(val) {
			this.info = this.$_.cloneDeep(val)
		},
		info: {
			handler() {
				this.isDisabledBtn =
					!this.info.name ||
					!this.info.instituteName ||
					!this.info.groupName ||
					!this.info.postcode ||
					!this.info.address ||
					!this.info.addressDetail
			},
			deep: true,
		},
	},
	methods: {
		...mapActions('delivery', ['addAddress', 'updateAddress']),
		init() {
			this.info = {
				id: 0,
				name: '',
				instituteName: '',
				groupName: '',
				postcode: '',
				address: '',
				addressDetail: '',
				isDefault: 0,
			}
			this.isDisabledBtn = true
		},
		onShow() {
			this.init()
		},
		onSearchAddress({ postcode, address, addressDetail }) {
			this.info.postcode = postcode
			this.info.address = address
			this.info.addressDetail = addressDetail
			this.info = this.$_.cloneDeep(this.info)
		},
		async onSave(e) {
			e.preventDefault()

			this.isRegist ? this.regist() : this.update()
			this.$emit('success')
		},
		async regist() {
			try {
				this.info.isDefault = !!this.info.isDefault
				await this.addAddress({
					instituteId: this.instituteId,
					body: this.info,
				})
				this.$emit('success')
				this.$refs.modal.hide()
				this.$root.toast('배송지 등록 성공', '새 배송지를 등록했습니다.', ToastType.SUCCESS)
			} catch (e) {
				this.$root.toast('배송지 등록 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
		async update() {
			try {
				await this.updateAddress({
					instituteId: this.instituteId,
					body: {
						...this.info,
						deliveryId: this.info.id,
						isDefault: !!this.info.isDefault,
					},
				})

				this.$emit('success', this.info)
				this.$refs.modal.hide()
				this.$root.toast('배송지 수정 성공', '배송지 정보를 수정했습니다.', ToastType.SUCCESS)
			} catch (e) {
				this.$root.toast('배송지 수정 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss">
#ModalShippingAddress___BV_modal_outer_ {
	.modal-dialog {
		max-width: 600px;
	}
	#ModalShippingAddress___BV_modal_header_ {
		&.modal-header {
			border-bottom: 1px solid $COLOR_EEE;
		}
	}
	#ModalShippingAddress___BV_modal_footer_ {
		&.modal-footer {
			border-top: 1px solid $COLOR_EEE;
			justify-content: center;
			.btn {
				width: 400px;
				height: 48px;
				background: $PRIMARY_YELLOW;
				&.disabled {
					background: $LIST_BACKGROUND;
					color: $COLOR_999;
					border: none;
				}
			}
		}
	}
}
#ModalAddAddress___BV_modal_outer_ {
	.modal-dialog {
		max-width: 600px;
	}
	#ModalAddAddress___BV_modal_header_ {
		&.modal-header {
			border-bottom: 1px solid $COLOR_EEE;
		}
	}
	#ModalAddAddress___BV_modal_footer_ {
		&.modal-footer {
			border-top: 1px solid $COLOR_EEE;
			justify-content: center;
			.btn {
				width: 400px;
				height: 48px;
				background: $PRIMARY_YELLOW;
				&.disabled {
					background: $LIST_BACKGROUND;
					color: $COLOR_999;
					border: none;
				}
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.chekbox-wrapper {
	display: flex;
	align-items: center;
	gap: 11px;
	margin-bottom: 40px;
	label {
		font-size: 0.875rem;
		font-weight: 500;
		color: $COLOR_999;
	}
}
</style>
