<template>
	<div class="search-item-box" @mouseleave="showKeyword = false">
		<input
			type="text"
			:placeholder="placeholder"
			:value="inputValue"
			@input="changeInput"
			@keyup.enter="search"
			ref="search-input"
			@focus="showKeyword = true"
		/>
		<div class="btn-wrap">
			<button class="close-btn" v-if="inputValue.length > 0" @click="resetSearch">
				<img src="@/assets/svg/close_c.svg" />
			</button>
			<button class="search-btn" @click="search">
				<img src="@/assets/svg/search.svg" />
			</button>
		</div>
		<keyword-list
			:class="{ active: showKeyword }"
			:title="keywordListTitle"
			:isRecent="isRecent"
			:list="productKeywordList"
			@clickItem="clickItem"
			@removeRecent="removeRecent"
			@removeAllRecent="removeRecentAll"
		/>
	</div>
</template>

<script>
import _ from 'lodash'
import KeywordList from '@/components/search/KeywordList.vue'
import { mapActions, mapState } from 'vuex'

export default {
	name: 'SearchItemBox',
	props: {
		placeholder: {
			type: String,
			default: '',
		},
	},
	components: {
		KeywordList,
	},
	computed: {
		...mapState('search', ['productKeywordList']),
		isRecent() {
			if (this.inputValue.length > 0) {
				return false
			} else {
				return true
			}
		},
		keywordListTitle() {
			if (this.isRecent) {
				return '최근 검색어'
			} else {
				return '검색어 추천'
			}
		},
	},
	watch: {
		inputValue() {
			this.getProductKeyword(this.inputValue)
		},
		showKeyword() {
			this.getProductKeyword(this.inputValue)
		},
	},
	data() {
		return {
			showKeyword: false,
			inputValue: '',
			recentKeywords: [],
		}
	},
	methods: {
		...mapActions('search', ['getProductKeyword', 'setProductRecentKeyword', 'removeProductRecentKeyword', 'removeProductRecentAllKeyword']),
		changeInput(event) {
			this.inputValue = event.target.value
		},
		clickItem(keyword) {
			this.inputValue = keyword
			this.showKeyword = false
			this.$emit('search', this.inputValue)
		},
		search() {
			this.showKeyword = false
			this.setProductRecentKeyword({ value: this.inputValue, isRecent: true })
			this.$emit('search', this.inputValue)
		},
		removeRecent(keyword) {
			this.removeProductRecentKeyword(keyword)
		},
		removeRecentAll() {
			this.removeProductRecentAllKeyword()
			this.showKeyword = false
		},
		resetSearch() {
			this.inputValue = ''
		},
	},
}
</script>

<style lang="scss" scoped>
.search-item-box {
	width: 250px;
	height: 30px;
	box-sizing: border-box;
	border: 1px solid $INPUT_BORDER;
	border-radius: 4px;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	position: relative;
	&:focus-within {
		border: 1px solid black;
	}
	&:hover {
		border: 1px solid black;
	}
	input {
		border: 0;
		height: 28px;
		width: 200px;
		font-size: 0.875rem;
		font-weight: 400;
		padding-left: 10px;
		border-radius: 4px 0 0 4px;
	}
	.btn-wrap {
		margin-left: auto;
		display: inline-flex;
		position: relative;
		button {
			width: 30px;
			height: 28px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			&.close-btn {
				left: -30px;
				position: absolute;
				top: 0;
			}
			&.search-btn {
				border-radius: 0px 4px 4px 0px;
				background-color: $COMMON_BACKGROUND;
			}
			&.scan-btn {
				img {
					width: 16px;
					height: 16px;
				}
			}
			img {
				width: 24px;
				height: 24px;
			}
		}
	}
}
</style>
