<template>
	<div class="order-box">
		<div class="title">
			<div>
				<p>주문일자</p>
				<b>{{ dateToString(order.createDatetime) }}</b>
			</div>
			<div>
				<p>주문번호</p>
				<b>{{ order.orderNo }}</b>
			</div>
		</div>
		<div class="box">
			<selectable-product-item
				v-for="(item, index) in order.items"
				:key="`product_${index}`"
				:item="convertProduct(item)"
				selectable
				@select="$emit('select', $event)"
			/>
		</div>
	</div>
</template>

<script>
import SelectableProductItem from '@/components/item/SelectableProductItem'
import date from '@/mixins/date'

export default {
	name: 'OrderBox',
	props: {
		order: {
			type: Object,
			default: () => ({}),
		},
	},
	components: {
		SelectableProductItem,
	},
	mixins: [date],
	methods: {
		convertProduct(item) {
			return {
				...item.product,
				orderItemId: item.orderItemId,
				price: item.unitPrice,
				quantity: item.quantity,
			}
		},
	},
}
</script>

<style lang="scss">
.order-box {
	width: 100%;
	border-radius: 5px;
	border: 1px solid $COLOR_DDD;
	overflow: hidden;
	.title {
		width: 100%;
		height: 60px;
		background-color: #f6f6f6;
		display: flex;
		align-items: center;
		padding: 0 20px;
		> div {
			display: inline-flex;
			align-items: center;
			position: relative;
			padding-right: 32px;
			font-size: 0.875rem;
			&:last-child {
				&:before {
					display: none;
				}
			}
			&:before {
				width: 1px;
				height: 14px;
				background-color: $COLOR_DDD;
				position: absolute;
				top: calc(50% - 7px);
				right: 1rem;
				content: '';
			}
			p {
				margin-right: 8px;
			}
		}
	}
	.selectable-product-item {
		border: 0;
		border-radius: 0;
		border-top: 1px solid $LINE_DIVIDER;
	}
}
</style>
