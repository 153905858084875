<template>
	<div class="detail-search-box">
		<search-title v-if="title" :title="title" :subTitle="subTitle" />
		<div v-if="search" class="search-box">
			<search-keyword
				v-if="isSearchKeyword"
				class="row"
				ref="keywordSearch"
				:title="keywordTitle"
				:keywordPlaceholder="searchPlaceholder"
				@search="searchKeyword"
			/>
			<search-date
				v-if="isSearchDate"
				class="row"
				ref="dateSearch"
				:title="dateTitle"
				:datePlaceholder="datePlaceholder"
				:text="searchText"
				@change="searchDate"
			/>
			<search-price v-if="isSearchPrice" class="row" ref="priceSearch" :title="priceTitle" :priceUnit="priceUnit" @search="searchPrice" />
			<filter-item-list v-if="textFilterList.length > 0" :filterList="textFilterList" @reset="resetTextFilter" @delete="deleteTextFilter" />
		</div>
		<div v-if="brandAndSeller" class="search-box">
			<search-check-list
				class="row"
				ref="searchCheckList"
				v-for="([key, value], index) of Object.entries(filters)"
				:key="index"
				:title="key"
				:list="value"
				:filterList="checkedFilterList"
				@change="changeCheckList(key, $event)"
			/>
			<filter-item-list
				v-if="checkedFilterList.length > 0"
				:filterList="checkedFilterList"
				@reset="resetCheckFilter"
				@delete="deleteCheckFilter"
			/>
		</div>
	</div>
</template>

<script>
import SearchTitle from '@/views/my-page/order-delivery/order/components/search/SearchTitle.vue'
import SearchKeyword from '@/views/my-page/order-delivery/order/components/search/SearchKeyword.vue'
import SearchDate from '@/views/my-page/order-delivery/order/components/search/SearchDate.vue'
import SearchPrice from '@/views/my-page/order-delivery/order/components/search/SearchPrice.vue'
import SearchCheckList from '@/views/my-page/order-delivery/order/components/search/SearchCheckList.vue'
import FilterItemList from '@/views/my-page/order-delivery/order/components/search/FilterItemList.vue'

export default {
	name: 'DetailSearchBox',
	components: {
		SearchTitle,
		SearchKeyword,
		SearchDate,
		SearchPrice,
		SearchCheckList,
		FilterItemList,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		subTitle: {
			type: String,
			default: '',
		},
		search: {
			type: Boolean,
			default: true,
		},
		brandAndSeller: {
			type: Boolean,
			default: false,
		},
		searchPlaceholder: {
			type: String,
			default: '',
		},
		datePlaceholder: {
			type: String,
			default: '',
		},
		filters: {
			type: Object,
			default: () => ({}),
		},
		isSearchKeyword: {
			type: Boolean,
			default: true,
		},
		isSearchDate: {
			type: Boolean,
			default: true,
		},
		isSearchPrice: {
			type: Boolean,
			default: false,
		},
		searchText: {
			type: String,
			default: '주문일',
		},
		priceTitle: {
			type: String,
			default: '결제할 금액 선택',
		},
		priceUnit: {
			type: String,
			default: '원',
		},
	},
	data() {
		return {
			keywordTitle: '검색',
			dateTitle: '기간 조회',
			textFiltered: {},
			textFilterList: [],
			checkFiltered: {},
			checkedFilterList: [],
		}
	},
	methods: {
		changeKeyword(keyword) {
			this.$refs.keywordSearch.changeInput({ target: { value: keyword } })
		},
		changeDate(date) {
			this.$refs.dateSearch.changeDate(date)
		},
		changeChecked(checkedList) {
			this.$refs.searchCheckList.forEach(component => {
				component.changeCheckedList(checkedList)
			})
		},
		searchKeyword(keyword) {
			if (!keyword) {
				this.textFiltered[this.keywordTitle] = []
				this.textFilterList = this.textFiltered[this.dateTitle] ?? []
			} else {
				this.textFiltered[this.keywordTitle] = [{ name: keyword, type: this.keywordTitle, value: keyword }]
				this.textFilterList = Object.values(this.textFiltered).reduce((acc, cur) => acc.concat(cur))
			}
			this.$emit('searchTextList', this.textFiltered)
		},
		searchDate(date) {
			this.textFiltered[this.dateTitle] = [{ name: `${date.join(' ~ ')}`, type: this.dateTitle, value: date }]
			this.textFilterList = Object.values(this.textFiltered).reduce((acc, cur) => acc.concat(cur))
			this.$emit('searchTextList', this.textFiltered)
		},
		searchPrice(price) {
			if (price === null || price === '') return
			this.textFiltered[this.priceTitle] = [{ name: price, type: this.priceTitle, value: price }]
			this.textFilterList = Object.values(this.textFiltered).reduce((acc, cur) => acc.concat(cur))
			this.$emit('searchTextList', this.textFiltered)
		},
		deleteTextFilter({ item, index }) {
			this.textFiltered[item.type] = this.textFiltered[item.type].filter(filter => filter.value !== item.value)
			this.textFilterList.splice(index, 1)
			this.$emit('searchTextList', this.textFiltered)

			if (item.type === this.keywordTitle) this.$refs.keywordSearch.reset()
			if (item.type === this.dateTitle) this.$refs.dateSearch.reset()
		},
		resetTextFilter() {
			this.textFiltered = {}
			this.textFilterList = []
			this.$emit('searchTextList', this.textFiltered)

			if (this.$refs.keywordSearch) this.$refs.keywordSearch.reset()
			if (this.$refs.dateSearch) this.$refs.dateSearch.reset()
		},
		changeCheckList(key, list) {
			this.checkFiltered[key] = list
			this.checkedFilterList = Object.values(this.checkFiltered).reduce((acc, cur) => acc.concat(cur))
			this.$emit('searchCheckList', this.checkFiltered)
		},
		deleteCheckFilter({ item, index }) {
			this.checkFiltered[item.type] = this.checkFiltered[item.type].filter(filter => filter.value !== item.value)
			this.checkedFilterList.splice(index, 1)
			this.$emit('searchCheckList', this.checkFiltered)
		},
		resetCheckFilter() {
			this.checkFiltered = {}
			this.checkedFilterList = []
			this.$emit('searchCheckList', this.checkFiltered)
		},
		clear() {
			this.textFiltered = {}
			this.textFilterList = []
			this.checkFiltered = {}
			this.checkedFilterList = []
			this.$refs.keywordSearch.changeInput({ target: { value: '' } })
		},
	},
}
</script>

<style lang="scss" scoped>
.detail-search-box {
	display: flex;
	flex-direction: column;
	gap: 15px;
	.search-box {
		.row {
			&:first-child {
				border-radius: 3px 3px 0 0;
			}
			&:last-child {
				border-radius: 0 0 3px 3px;
			}
		}
		.search-row {
			&:not(:last-child) {
				border-bottom: none;
			}
			span {
				width: 70px;
			}
		}
	}
}
</style>
