<template>
	<div class="order-table">
		<div class="order-headers">
			<div class="header" :class="header.key" v-for="(header, index) in headers" :key="`header_${index}`" :style="`width:${header.width}px`">
				{{ header.name }}
			</div>
		</div>
		<div class="order-body">
			<div class="row" v-for="(item, index) in list" :key="`order-item${index}`">
				<div class="column" v-for="column in headers" :key="column.key" :class="column.key" :style="`width:${column.width}px`">
					<!--정보 - name // TODO 데이터 나오는 대로 array -> object 로 변경-->
					<div v-if="column.key == 'seller'">{{ item[column.key][0].name }}</div>
					<!--제품 정보-->
					<div v-else-if="column.key == 'product'">
						<b>{{ item.name }}</b>
						<p v-html="setProductContent(item)"></p>
					</div>
					<!--가격 // TODO 판매자가 data 나오는대로 변경 필요-->
					<div v-else-if="column.key.indexOf('price') >= 0">{{ item.price.toLocaleString() }}원</div>
					<div v-else>{{ item[column.key] }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'OrderTable',
	props: {
		headers: {
			type: Array,
			default: () => [],
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
	computed: {},
	methods: {
		setProductContent(item) {
			let content = ''
			if (item.brand?.name) content += `<span>브랜드: ${item.brand.name}</span>`
			if (item.productCode) content += `<span>제품번호: ${item.productCode}</span>`
			if (item.volume) content += `<span>사이즈(용량): ${item.volume}${item.volumeUnit}</span>`
			return content
		},
		setValue(item) {
			if (item) {
				return item
			} else {
				return '-'
			}
		},
	},
}
</script>

<style lang="scss">
.order-table {
	border-top: 4px solid $LINE_DIVIDER;
	border-bottom: 4px solid $LINE_DIVIDER;
	padding: 8px 0;
	width: 100%;
	.order-headers {
		width: 100%;
		height: 40px;
		background-color: $LIST_BACKGROUND;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 5px;
		border: 1px solid $LINE_DIVIDER;
		.header {
			position: relative;
			font-size: 0.875rem;
			font-weight: 700;
			text-align: center;
			padding: 0 1rem;
			white-space: nowrap;
			&.product {
				text-align: left;
				padding-left: 1rem;
			}
			&:after {
				position: absolute;
				top: calc(50% - 10px);
				right: -8px;
				content: '';
				width: 1px;
				height: 20px;
				background-color: $LINE_DIVIDER;
			}
			&:last-child {
				text-align: right;
				padding-right: 1rem;
				&:after {
					display: none;
				}
			}
		}
	}
	.order-body {
		.row {
			width: 100%;
			display: flex;
			align-items: center;
			border-bottom: 1px solid $LINE_DIVIDER;
			height: 80px;
			margin: 0 0;
			.column {
				font-weight: 700;
				font-size: 0.875rem;
				text-align: center;
				margin: 0 6px;
				&.product {
					text-align: left;
					padding-left: 1rem;
					span {
						font-size: 0.75rem;
						font-weight: 400;
						color: $COLOR_666;
						padding: 0 7px;
						position: relative;
						&:before {
							position: absolute;
							top: calc(50% - 3px);
							left: 0;
							width: 1px;
							height: 10px;
							background-color: $COLOR_DDD;
							content: '';
						}
					}
				}
				&:last-child {
					text-align: right;
					padding-right: 1rem;
				}
			}
		}
	}
}
</style>
