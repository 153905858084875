<template>
	<b-modal
		ref="modal"
		id="ModalEasyPayment"
		centered
		:title="title"
		:ok-title="isRegist ? '완료' : '저장'"
		ok-variant="warning"
		ok-only
		:ok-disabled="isDisabledBtn"
		button-size="lg"
		@show="onShow"
		@ok="onSave"
	>
		<div class="image-text-wrapper">
			<img :src="image" />
			<div class="text-wrapper">
				<div>{{ info.name }}</div>
				<div>{{ displayCardNumber }}</div>
			</div>
		</div>
		<input-form-item
			v-model="info.name"
			direction="column"
			title="카드별칭"
			placeholder="카드별칭을 입력해주세요"
			description="카드를 쉽게 구분하도록 별칭을 입력할 수 있습니다 (최대 20자)"
			:maxlength="20"
			:inputStyle="{ width: '566px' }"
			:autofocus="isRegist"
		/>
		<template v-if="isRegist">
			<input-form-item v-model="info.cardNumber" type="card" direction="column" title="카드번호" placeholder="0000" />
			<div class="info-division-row">
				<input-form-item
					v-model="info.expirationDate"
					type="number"
					direction="column"
					title="유효기간 월/년"
					placeholder="MMYY"
					:maxlength="4"
					:inputStyle="{ width: '278px' }"
					style="flex: 1"
					@input="$forceUpdate()"
				/>
				<input-form-item
					v-model="info.cardPassword"
					type="password"
					direction="column"
					title="카드 비밀번호"
					placeholder="비밀번호 앞 2자리 숫자"
					:maxlength="2"
					:inputStyle="{ width: '278px' }"
					style="flex: 1"
				/>
			</div>
			<input-form-item
				v-model="info.registrationNumber"
				type="number"
				direction="column"
				title="사업자등록번호 또는 생년월일"
				description="카드의 종류에 따라 사업자 등록번호 10자리 또는 생년월일 6자리를 입력해주세요"
				placeholder="사업자등록번호(10자리) 또는 생년월일(6자리)"
				:maxlength="10"
				:inputStyle="{ width: '566px' }"
				@input="$forceUpdate()"
			/>
		</template>

		<modal-password-confirm @regist="onRegist" />
	</b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import InputFormItem from '@/components/form-item/InputFormItem.vue'
import ModalPasswordConfirm from '@/components/modal/ModalPasswordConfirm'

export default {
	name: 'ModalEasyPayment',
	components: {
		InputFormItem,
		ModalPasswordConfirm,
	},
	props: {
		title: {
			type: String,
			default: '카드 등록',
		},
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			info: {},
			isDisabledBtn: true,
		}
	},
	computed: {
		...mapGetters('card', ['imageUrl']),
		...mapGetters('user', ['instituteId']),
		isRegist() {
			return this.$_.isEmpty(this.item)
		},
		image() {
			return this.isRegist ? this.imageUrl ?? require('@/assets/svg/empty-card.svg') : this.info.information.frontImageUrl
		},
		displayCardNumber() {
			if (this.$_.isEmpty(this.info.cardNumber)) return
			if (Array.isArray(this.info.cardNumber)) {
				let secondNumber = ''
				if (this.info.cardNumber[1].length > 0) {
					for (let i = 0; i < this.info.cardNumber[1].length; i++) {
						secondNumber += '*'
					}
				}
				let thridNumber = ''
				if (this.info.cardNumber[2].length > 0) {
					for (let i = 0; i < this.info.cardNumber[2].length; i++) {
						thridNumber += '*'
					}
				}
				return `${this.info.cardNumber[0]} - ${secondNumber} - ${thridNumber} - ${this.info.cardNumber[3]}`
			} else {
				const cardNumber1 = this.info.cardNumber?.slice(0, 4) ?? '0000'
				const cardNumber4 = this.info.cardNumber?.slice(12, 16) ?? '0000'
				return `${cardNumber1} - **** - **** - ${cardNumber4}`
			}
		},
	},
	watch: {
		item(val) {
			this.info = this.$_.cloneDeep(val)
		},
		info: {
			handler() {
				if (!this.isRegist) {
					this.isDisabledBtn = !this.info.name
				} else {
					this.isDisabledBtn =
						!this.info.name ||
						!this.info.expirationDate ||
						this.info.expirationDate.length < 4 ||
						!this.info.cardPassword ||
						!this.info.registrationNumber ||
						!(this.info.registrationNumber.length === 6 || this.info.registrationNumber.length === 10) ||
						!this.info.cardNumber.join('') ||
						this.info.cardNumber.join('').length < 15
				}
			},
			deep: true,
		},
		'info.cardNumber': {
			async handler() {
				let cardNumber = this.info.cardNumber
				if (Array.isArray(this.info.cardNumber)) {
					cardNumber = this.info.cardNumber.join('')
				}
				if (cardNumber && cardNumber.length === 6) {
					await this.lookupCardInfo({
						instituteId: this.instituteId,
						bin: cardNumber,
					})
				} else if ((!cardNumber || cardNumber.length < 6) && this.imageUrl) {
					this.setCardInfo({})
				}
			},
		},
	},
	methods: {
		...mapMutations('card', ['setCardInfo']),
		...mapActions('card', ['lookupCardInfo', 'addCard', 'updateCard']),
		init() {
			this.setCardInfo({})

			this.info = {
				image: '',
				nickname: '',
				cardNumber: [],
				expirationDate: '',
				cardPassword: '',
				registrationNumber: '',
				paymentPassword: '',
			}
			this.isDisabledBtn = true
		},
		onShow() {
			this.init()
		},
		async onSave(e) {
			e.preventDefault()

			this.isRegist ? this.$bvModal.show('ModalPasswordConfirm') : this.update()
		},
		async update() {
			try {
				await this.updateCard({
					instituteId: this.instituteId,
					body: {
						cardId: this.info.id,
						name: this.info.name,
					},
				})
				this.$emit('success')
				this.$refs.modal.hide()
				this.$root.toast('카드 수정 성공', '카드 별칭 수정 완료했습니다.', ToastType.SUCCESS)
			} catch (e) {
				this.$root.toast('카드 수정 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
		async onRegist(password) {
			this.info.paymentPassword = password

			try {
				await this.addCard({
					instituteId: this.instituteId,
					body: {
						...this.info,
						cardNumber: this.info.cardNumber.join(''),
						expirationMonth: this.info.expirationDate.substr(0, 2),
						expirationYear: this.info.expirationDate.substr(2, 2),
					},
				})
				this.$emit('success')
				this.$refs.modal.hide()
				this.$root.darkToast('카드 등록이 완료되었습니다.')
			} catch (e) {
				this.$root.toast('카드 등록 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss">
#ModalEasyPayment___BV_modal_outer_ {
	.modal-dialog {
		max-width: 600px;
	}
	#ModalEasyPayment___BV_modal_header_ {
		&.modal-header {
			border-bottom: 1px solid $COLOR_EEE;
		}
	}
	#ModalEasyPayment___BV_modal_body_ {
		padding: 0;
		.item-title,
		.item-description,
		.item-content {
			padding: 0 1rem;
		}
		.info-division-row .item-wrapper {
			.item-title,
			.item-content {
				padding: 0 0 0 1rem;
			}
			&:last-child {
				.item-title,
				.item-content {
					padding: 0;
				}
			}
		}
	}
	#ModalEasyPayment___BV_modal_footer_ {
		&.modal-footer {
			border-top: 1px solid $COLOR_EEE;
			justify-content: center;
			.btn {
				width: 400px;
				height: 48px;
				background: $PRIMARY_YELLOW;
				&.disabled {
					background: $LIST_BACKGROUND;
					color: $COLOR_999;
					border: none;
				}
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.image-text-wrapper {
	position: relative;
	margin-top: 20px;
	text-align: center;
	cursor: default;
	img {
		width: 305px;
		height: 200px;
		border-radius: 6px;
	}
	.text-wrapper {
		position: absolute;
		bottom: 15px;
		left: 165px;
		font-size: 0.875rem;
		color: $PRIMARY_WHITE;
		font-weight: 500;
		text-align: left;
	}
}
.info-division-row {
	display: flex;
	gap: 7px;
}
</style>
