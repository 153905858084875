<template>
	<div class="required-product-wrapper">
		<hr v-if="index > 0" />
		<div class="contents">
			<img class="product-img" src="@/assets/svg/main/chemical.svg" />
			<div class="text-contents">
				<div>
					<p class="title">{{ product.title }}</p>
					<p class="subtext">카테고리 : {{ product.category }} | 사이즈(용량) : {{ product.size }}</p>
					<div class="caution-wrapper">
						<div>
							<img class="caution-mark" src="@/assets/svg/main/icon-expire.svg" />
							<p>{{ product.expiredDate > 0 ? `유효기간 D-${product.expiredDate}` : '유효기간만료' }}</p>
						</div>
						<div v-if="product.isUnderThreshold" style="width: 122px">
							<img class="caution-mark" src="@/assets/svg/main/icon-threshold.svg" />
							<p>안전재고량 이하</p>
						</div>
					</div>
				</div>
				<img class="close-btn" src="@/assets/svg/main/icon-close.svg" @click="$emit('showModalDelete', product)" />
			</div>
			<div class="button-wrapper">
				<button>같은상품 찾기</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RequiredProduct',
	components: {},
	props: {
		product: {
			type: Object,
			default: () => {},
		},
		index: {
			type: Number,
			default: 0,
		},
	},
	computed: {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.required-product-wrapper {
	hr {
		border: 1px solid $LINE_DIVIDER;
	}
	.product-img {
		width: 118px;
		height: 118px;
		border: 1px solid #eeeeee;
		border-radius: 5px;
		padding-top: 5px;
	}
	.contents {
		display: flex;
	}
	.text-contents {
		width: 830px;
		padding: 20px;
		display: flex;
		justify-content: space-between;
		.title {
			font-weight: 700;
			font-size: 16px;
			padding-bottom: 5px;
		}
		.subtext {
			font-weight: 400;
			font-size: 12px;
			color: #666666;
		}
		.caution-wrapper {
			display: flex;
			gap: 10px;
			padding-top: 5px;
			div {
				border-radius: 20px;
				width: 108px;
				height: 28px;
				background: #ff57461a;
				display: flex;
				align-items: center;
				.caution-mark {
					padding-left: 7px;
				}
				p {
					font-weight: 500;
					font-size: 12px;
					color: $PRIMARY_RED;
					padding-left: 3px;
				}
			}
		}
	}
	.close-btn {
		display: flex;
		align-self: flex-start;
		cursor: pointer;
	}
	.button-wrapper {
		border-left: solid 1px $LINE_DIVIDER;
		padding-left: 15px;
		display: flex;
		align-items: center;
		button {
			background: #f5f5f5;
			border-radius: 4px;
			width: 115px;
			height: 36px;
			font-weight: 700;
			font-size: 14px;
			&:hover {
				outline: solid 5px #fff5dd;
			}
		}
	}
}
</style>
