<template>
	<div class="info-box-wrapper">
		<div class="title" v-if="title">
			<h6>{{ title }}</h6>
			<p class="subtitle" v-if="subtitle">{{ subtitle }}</p>
			<div class="check-box" v-if="isCheck">
				<input id="check-box-value" class="common" type="checkbox" @change="changeValue" :disabled="checkItem.disabled" />
				<label for="check-box-value" :class="{ disabled: checkItem.disabled }">{{ checkItem.text }}</label>
			</div>
			<div class="right-btn-group" v-if="btnGroup">
				<button class="btn" :class="btn.class" v-for="(btn, index) in btnGroup" :key="`btn_${index}`" @click="$emit(`${btn.key}`)">
					{{ btn.text }}
				</button>
			</div>
		</div>
		<div class="info-box">
			<ul>
				<li v-for="(item, index) in list" :key="`info-box-item_${index}`" :class="{ all: item.all }">
					<template v-if="item.type === 'preview'">
						<template v-if="item.list && item.list.length > 0">
							<b>{{ item.name }}</b>
							<div class="preview">
								<img v-for="(image, idx) of item.list" :key="`file-item${idx}`" :src="image.url" @click="onClickImage(image.url)" />
							</div>
						</template>
					</template>
					<template v-else-if="item.list && item.list.length > 0">
						<b>{{ item.name }}</b>
						<div class="attachments">
							<a v-for="(file, idx) in item.list" :key="`file-item${idx}`" :href="file.url" download>
								<img src="@/assets/svg/icon/icon-file.svg" />
								<p>{{ file.name }}</p>
							</a>
						</div>
					</template>
					<template v-else-if="item.tooltip">
						<!--[상태: 입금대기] 일 경우-->
						<b>{{ item.name }}</b>
						<p :class="item.tooltip && item.class ? item.class : ''" v-b-tooltip.hover.top :title="item.tooltip">
							{{ item.value }}
						</p>
					</template>
					<template v-else>
						<!--common-->
						<b>{{ item.name }}</b>
						<p :class="item.class">{{ item.value }}</p>
					</template>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InfoBox',
	props: {
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
		checkItem: {
			type: Object,
			default: () => {},
		},
		btnGroup: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		isCheck() {
			return this.checkItem?.text.length > 0
		},
	},
	methods: {
		changeValue(e) {
			this.$emit('change', e.target.checked)
		},
		onClickImage(url) {
			this.$emit('preview', url)
		},
	},
}
</script>

<style lang="scss" scoped>
.info-box-wrapper {
	.title {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		h6 {
			font-size: 1.25rem;
			font-weight: 700;
		}
		.subtitle {
			display: inline-block;
			font-size: 0.75rem;
			font-weight: 500;
			color: $COLOR_333;
			margin-left: 1rem;
		}
		.check-box {
			display: flex;
			align-items: center;
			margin-left: 18px;
			> label {
				font-size: 0.875rem;
				font-weight: 500;
				&.disabled {
					color: $FONT_DISABLED;
				}
			}
		}
		.right-btn-group {
			margin-left: auto;
			.btn {
				margin-left: 10px;
				height: 30px;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #f2f3f4;
					color: black;
				}
			}
		}
	}
	.info-box {
		width: 100%;
		border-radius: 5px;
		border: 1px solid $LINE_DIVIDER;
		padding: 1rem 20px;
		ul {
			width: 100%;
			display: block;
			li {
				display: inline-flex;
				align-items: flex-start;
				font-size: 0.875rem;
				font-weight: 500;
				width: 50%;
				padding: 8px 0;
				&.all {
					width: 100%;
				}
				b {
					display: block;
					font-weight: inherit;
					width: 116px;
				}
				p {
					display: block;
					font-weight: inherit;
					width: calc(100% - 136px);
					&.complete {
						color: $PRIMARY_GREEN;
					}
					&.fail {
						color: $PRIMARY_RED;
					}
					&.common {
						width: unset;
						display: inline-block;
						position: relative;
						cursor: pointer;
						color: $COLOR_666;
						&:before {
							position: absolute;
							top: 3px;
							right: -20px;
							content: url(~@/assets/svg/icon/icon-status-wait.svg);
							width: 16px;
							height: 16px;
						}
					}
				}
				.preview {
					display: flex;
					gap: 10px;
					> img {
						width: 100px;
						height: 100px;
						border: 1px solid $COLOR_EEE;
						border-radius: 7px;
						cursor: pointer;
					}
				}
				.attachments {
					width: 100%;
					> a {
						width: 100%;
						height: 48px;
						border-radius: 4px;
						border: 1px solid $COLOR_DDD;
						display: flex;
						align-items: center;
						padding: 0 12px;
						margin-bottom: 1rem;
						background-color: white;
						transition: all 0.3s;
						&:hover {
							background-color: $HOVER_BACKGROUND;
						}
						&:last-child {
							margin-bottom: 0;
						}
						img {
							margin-right: 8px;
						}
						p {
							font-size: 0.875rem;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
}
</style>
