<template>
	<div class="business-registration-management-wrapper">
		<base-management
			title="사업자등록증(결제처) 관리"
			subTitleName="사업자등록증(결제처) 목록"
			subTitleContent="사업자등록증은 한 번만 등록해도 우리 연구실의 모든 멤버가 주문할 수 있어요."
			buttonText="+ 새 사업자등록증 등록"
			@click="onClickAdd"
		>
			<business-registration-list :list="businessRegistrations" @preview="onPreviewRegistration" @delete="onDeleteRegistration" />
		</base-management>
		<hr />
		<div class="business-registration-management-question">
			<p>사업자등록증은 왜 등록해야 하나요?</p>
			<ul>
				<li>사업자등록증은 전자세금계산서 발행과 연구기관 인증에 필요합니다.</li>
				<li>인증이 완료된 사업자등록증(결제처)만 전자세금계산서 결제 시 결제처로 선택할 수 있습니다.</li>
				<li>전자세금계산서로 결제 시 등록된 사업자등록증을 선택해 결제해보세요.</li>
				<li>
					환경부령으로 정한 화학물질 관리법 시행규칙 법 제 28조의 2제 1항에 따라 유해화학물질을 통신판매하는 경우, 사업자등록증, 본인 확인
					절차가 필요합니다.
				</li>
			</ul>
			<button class="btn btn-gray" @click="onClickTaxBillPreview">전자세금계산서 미리보기</button>
		</div>

		<modal-business-registration @success="fetchBusinessRegistration" />
		<modal-registration-preview :images="registrationPreviewImages" />
		<modal-tax-bill-preview />
		<modal-cancel
			title="사업자등록증(결제처) 삭제"
			content="삭제된 사업자등록증(결제처)는 되돌릴 수 없습니다.<br/>정말로 삭제하시겠습니까?"
			@ok="onDelete"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import BusinessRegistrationList from '@/views/my-page/my-info/business-registration/BusinessRegistrationList.vue'
import ModalBusinessRegistration from '@/views/my-page/my-info/business-registration/modal/ModalBusinessRegistration.vue'
import ModalRegistrationPreview from '@/views/my-page/my-info/business-registration/modal/ModalRegistrationPreview.vue'
import ModalTaxBillPreview from '@/views/my-page/my-info/business-registration/modal/ModalTaxBillPreview.vue'
import ModalCancel from '@/components/modal/ModalCancel'

export default {
	name: 'BusinessRegistrationManagement',
	components: {
		BaseManagement,
		BusinessRegistrationList,
		ModalBusinessRegistration,
		ModalRegistrationPreview,
		ModalTaxBillPreview,
		ModalCancel,
	},
	data() {
		return {
			deleteId: 0,
			registrationPreviewImages: [],
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('businessRegistration', ['businessRegistrations']),
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchBusinessRegistration()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('businessRegistration', ['lookupRegistration', 'deleteRegistration']),
		async fetchBusinessRegistration() {
			await this.lookupRegistration(this.instituteId)
		},
		onClickAdd() {
			this.$bvModal.show('ModalBusinessRegistration')
		},
		onClickTaxBillPreview() {
			this.$bvModal.show('ModalTaxBillPreview')
		},
		onPreviewRegistration(images) {
			if (!images?.length) return this.$root.toast('미리보기 실패', '사업자등록증 파일이 없습니다.', ToastType.ERROR)
			this.registrationPreviewImages = images
			this.$bvModal.show('ModalRegistrationPreview')
		},
		onDeleteRegistration(id) {
			this.deleteId = id
			this.$bvModal.show('ModalCancel')
		},
		async onDelete() {
			try {
				await this.deleteRegistration({
					instituteId: this.instituteId,
					id: this.deleteId,
				})
				await this.fetchBusinessRegistration()
				this.$root.toast('사업자등록증 삭제 성공', '해당 사업자등록증을 삭제했습니다.', ToastType.SUCCESS)
			} catch (e) {
				this.$root.toast('사업자등록증 삭제 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.business-registration-management-wrapper {
	margin-bottom: 64px;
	.business-registration-management-question {
		padding: 20px;
		background: $LIST_BACKGROUND;
		border-radius: 5px;
		> p {
			font-size: 0.75rem;
			font-weight: 700;
			margin-bottom: 8px;
		}
		> ul {
			margin-left: 22px;
			margin-bottom: 24px;
			li {
				font-size: 0.75rem;
				font-weight: 500;
				color: $COLOR_666;
				list-style-type: disc;
			}
		}
		> .btn {
			font-size: 0.75rem;
		}
	}
	hr {
		color: #bbb;
		margin: 4rem 0;
	}
}
</style>
