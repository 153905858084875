<template>
	<div>
		<div class="management-title">{{ title }}</div>
		<div v-if="subTitleName || subTitleContent" class="management-sub-title">
			<b v-if="subTitleName">{{ subTitleName }}</b>
			<span v-if="subTitleContent">{{ subTitleContent }}</span>
		</div>
		<div class="management-wrapper">
			<slot></slot>
		</div>
		<div v-if="buttonText" class="management-footer">
			<button class="btn btn-white lg" @click="$emit('click')">{{ buttonText }}</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseManagement',
	props: {
		title: {
			type: String,
			required: true,
		},
		subTitleName: {
			type: String,
			default: '',
		},
		subTitleContent: {
			type: String,
			default: '',
		},
		buttonText: {
			type: String,
			default: '',
		},
	},
}
</script>

<style lang="scss" scoped>
.management-title {
	font-size: 1.5rem;
	font-weight: 700;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid $PRIMARY_BLACK;
}
.management-sub-title {
	display: flex;
	align-items: center;
	gap: 16px;
	margin-bottom: 24px;
	> b {
		font-size: 1rem;
		font-weight: 700;
	}
	> span {
		font-size: 0.875rem;
		font-weight: 500;
		color: $COLOR_666;
	}
}
.management-footer {
	display: flex;
	justify-content: center;
	margin: 64px 0;
	.btn {
		width: 240px;
		height: 48px;
	}
}
</style>
