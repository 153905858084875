<template>
	<div class="status-counter-list">
		<template v-for="(item, index) of list">
			<status-counter-item :id="item.id" :title="item.title" :count="item.count" :active="item.active" @active="$emit('active', $event)" />
			<template v-if="index < list.length - 1">
				<img v-if="index === 0 || index === list.length - 2" src="@/assets/svg/line_separator.svg" />
				<img v-else src="@/assets/svg/arrow_up.svg" />
			</template>
		</template>
	</div>
</template>

<script>
import StatusCounterItem from '@/views/my-page/order-delivery/order/components/StatusCounterItem.vue'

export default {
	name: 'StatusCounterList',
	components: {
		StatusCounterItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
}
</script>

<style lang="scss" scoped>
.status-counter-list {
	display: flex;
	justify-content: space-between;
}
</style>
