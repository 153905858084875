<template>
	<div class="sadadream-wrapper">
		<div class="banner">
			<div class="wrapper">
				<!-- <div class="title">사다드림</div>
				<div class="description">여범님 디스크립션 추가 //무엇이든 사다드립니다~ 사다드림</div> -->
			</div>
		</div>
		<div class="wrapper" v-if="instituteId">
			<MyList />
			<OtherList />
		</div>
	</div>
</template>
<script>
import MyList from './components/MyList'
import OtherList from './components/OtherList'
import { mapGetters } from 'vuex'
import { goToSSOMain, isLogin } from '@/services/api/common'
export default {
	components: {
		MyList,
		OtherList,
	},
	computed: {
		...mapGetters('user', ['instituteId']),
	},
	data() {
		return {
			isLoginUser: false,
		}
	},
	watch: {
		userInfo: {
			handler(newVal) {
				if (newVal?.id) this.isLoginUser = true
				else this.isLoginUser = false
			},
			immediate: true,
		},
	},
	mounted() {
		if (!this.isLoginUser && !isLogin()) {
			this.$router.replace('/')
		}
	},
}
</script>
<style lang="scss" scoped>
.sadadream-wrapper {
	width: 100%;
	.wrapper {
		width: 1080px;
		margin: 0px auto;
		position: relative;
	}
	.banner {
		margin-top: 0px;
		width: 100%;
		height: 240px;
		background-color: #ffb000;
		.wrapper {
			background: url(~@/assets/images/img-sadadream-banner.png);
			background-size: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0px 20px;
			.title {
				font-size: 24px;
				font-weight: 700;
				color: #111;
			}
			.description {
				font-size: 14px;
				font-weight: 400;
				color: #111;
			}
		}
	}
}
</style>
