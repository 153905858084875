<template>
	<div class="app-menu" ref="app-menu">
		<div class="wrapper">
			<!-- <button
				class="all-category"
				@mouseover="$emit('toggleAllCategory', true)"
				@mouseleave="$emit('toggleAllCategory', false)"
				:class="{ active: isShowAllCategoryList }"
			>
				<img src="@/assets/svg/menu.svg" />
				<b>전체 카테고리</b>
			</button> -->
			<ul class="menu-wrap">
				<li class="menu lmp" @click="goPro()" id="goProButton">
					<img src="@/assets/svg/icon-lmp-favicon.svg" />
					랩매니저 Pro
				</li>
				<b-tooltip show :target="'goProButton'" triggers="none" placement="bottom">
					<img :src="proTooltipText" />
				</b-tooltip>
				<li class="menu sadadream" @click="goSadadream()">
					<img src="@/assets/svg/icon-sadadream.svg" />
					구해줘 랩스
				</li>
				<li
					class="menu"
					@mouseover="$emit('toggleAllCategory', true)"
					@mouseleave="$emit('toggleAllCategory', false)"
					:class="{ active: isShowAllCategoryList }"
				>
					카테고리
				</li>
				<li
					class="menu"
					v-for="(menu, index) in menuList"
					:key="`menu_${index}`"
					@click="goPage(menu.pageName, menu.category)"
					:class="{ active: menu.category === $route.params.category || isEventPage(menu) }"
				>
					{{ menu.name }}
				</li>
			</ul>
			<ul class="right-menu-wrap">
				<template v-if="!isLoginUser">
					<p @click="gotoLogin">회원가입하고 5%씩 할인받아 구매해보세요.</p>
					<span class="join" @click="gotoLogin">회원가입</span>
					<span class="join" @click="gotoLogin">로그인</span>
				</template>
				<template v-else>
					<p>후불결제 잔여한도:</p>
					<span
						@click="goPay"
						class="limit"
						:class="{ warning: overDueNoPaidPriceWithVat > 0 }"
						v-b-tooltip.hover.bottom.html="tootlipText"
					>
						<img v-if="overDueNoPaidPriceWithVat > 0" src="@/assets/svg/icon-round-warn.svg" />{{
							institute?.limitCredit?.toLocaleString()
						}}원 ▸
					</span>
				</template>
			</ul>
		</div>
		<modal-intro-pro ref="modal-intro-pro" />
		<ModalConfirm
			ref="ModalHideTooltip"
			title="랩매니저 Pro 팁 보지않기"
			content="랩매니저 Pro 관련 TIP을 숨기시겠습니까?"
			cancelText="닫기"
			confirmText="30일간 보지 않기"
			@confirm="hideTooltip"
		/>
		<!-- <modal-select-current-address /> -->
		<!-- <modal-shipping-address :id="'ModalAddAddress'" title="배송지 등록" @success="lookupAddressList(instituteId)" /> -->
	</div>
</template>

<script>
import menu from '@/utils/define/Menu'
import ModalSelectCurrentAddress from '@/components/modal/ModalSelectCurrentAddress.vue'
import ModalShippingAddress from '@/views/my-page/my-info/shipping-address/modal/ModalShippingAddress.vue'
import cookies from 'vue-cookies'
import { goToSSOMain, isLogin, getSYSUrl } from '@/services/api/common'
import { mapState, mapActions, mapGetters } from 'vuex'
import PageName from '@/utils/define/PageName'
import ModalIntroPro from '../modal/ModalIntroPro.vue'
import ModalConfirm from '../modal/ModalConfirm.vue'
import { ProTooltipText } from '@/utils/Common'
export default {
	name: 'AppMenu',
	props: {
		isShowAllCategoryList: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		ModalSelectCurrentAddress,
		ModalShippingAddress,
		ModalIntroPro,
		ModalConfirm,
	},
	data() {
		return {
			isLoginUser: false,
			showTooltip: !cookies.get('isGoProTooltipHide'),
			proTooltipText: ProTooltipText,
		}
	},
	computed: {
		...mapState('institute', ['institute']),
		...mapGetters('user', ['instituteId', 'userInfo']),
		...mapState('delivery', ['basicAddress']),
		// showTooltip() {
		// 	console.log('showTOoltip')
		// 	return !cookies.get('isGoProTooltipHide')
		// },
		menuList() {
			return menu
		},
		mainText() {
			if (!this.isLoginUser) return '회원가입'
			else {
				if (!this.$_.isEmpty(this.basicAddress)) return '변경'
				else return '배송지 등록'
			}
		},
		subtext() {
			let result = ''
			if (!this.isLoginUser) result = '회원가입하고 5%씩 할인받아 구매해보세요.'
			else {
				if (this.currentAddress && !this.$_.isEmpty(this.currentAddress)) {
					result = `${this.currentAddress.address}, ${this.currentAddress.addressDetail}`
				} else {
					result = '배송지를 등록하고 16만종의 시약을 주문해보세요'
				}
			}
			return result
		},
		currentAddress() {
			return cookies.get('currentAddress2') ?? this.basicAddress
		},
		overDueNoPaidPriceWithVat() {
			return this.institute?.overDueNoPaidPriceWithVat ?? 0
		},
		tootlipText() {
			if (this.overDueNoPaidPriceWithVat > 0)
				return '결제 기간이 초과된 주문이 있습니다. <br />해당 주문이 결제가 완료되어야 추가 주문이 가능합니다.'
			else return null
		},
		limitCredit() {
			return (this.institute?.limitCredit ?? 0) - (this.institute?.noPaidPriceWithVat ?? 0)
		},
	},
	watch: {
		instituteId: {
			async handler(newVal, oldVal) {
				if (!newVal) return
				if (newVal != oldVal && oldVal != null) cookies.remove('currentAddress2')
				await this.lookupAddressList(this.instituteId)
			},
			immediate: true,
		},
		userInfo: {
			handler(newVal) {
				if (newVal.id) this.isLoginUser = true
			},
			immediate: true,
		},
	},
	mounted() {
		if (cookies.get('isShowAddressToast') === 'true') {
			this.$root.toast('배송지 변경 완료', '배송지가 변경 되었습니다.', 'success')
			cookies.remove('isShowAddressToast')
		}
	},
	methods: {
		...mapActions('cart', ['lookupCartList']),
		...mapActions('delivery', ['lookupAddressList']),
		goPage(name, category) {
			this.$router
				.push({
					name: name,
					params: {
						category: category ? category : null,
					},
				})
				.catch(() => {})
		},
		gotoLogin() {
			if (!isLogin()) goToSSOMain()
			else {
				this.$bvModal.show('ModalSelectCurrentAddress')
			}
		},
		isEventPage(menu) {
			if (menu.category !== 'event') return false
			return location.pathname.includes('event')
		},
		goPay() {
			this.$router.push({
				name: PageName.MyPage.UnPaymentManagement,
				params: {
					step: 1,
				},
			})
		},
		goSadadream() {
			if (!isLogin()) goToSSOMain()
			else {
				this.$router.push({
					name: PageName.Sadadream.Main,
				})
			}
		},
		goPro() {
			if (isLogin() && cookies.get('introProModal_hide')) {
				let url = getSYSUrl()
				if (this.institute && this.institute.proInstituteId) {
					url += `/${this.institute.proInstituteId}/purchase/cargo?isStore=true`
				}
				location.href = url
			} else this.$refs['modal-intro-pro'].show()
		},
		hideTooltip() {
			cookies.set('isGoProTooltipHide', true, '30d')
			this.showTooltip = false
		},
	},
}
</script>

<style lang="scss" scoped>
@keyframes afterChangeColor {
	0%,
	100% {
		border-bottom-color: #1b085a;
	}
	50% {
		border-bottom-color: #371b94;
	}
}

@keyframes gradient {
	0%,
	100% {
		background-position: top 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
}

.tooltip {
	&.bs-tooltip-bottom {
		top: -10px !important;
		::v-deep .arrow::before {
			border-bottom-color: #633ee0 !important;
		}
		::v-deep .arrow::after {
			animation: afterChangeColor 2.4s ease-in infinite;
		}
	}
	&.bs-tooltip-top {
		top: 10px !important;
		::v-deep .arrow::before {
			border-top-color: #633ee0 !important;
		}
		::v-deep .arrow::after {
			animation: afterChangeColor 2.4s ease-in infinite;
		}
	}
}

::v-deep .tooltip-inner {
	background: linear-gradient(0deg, #552fd5, #371b94, #2c1088, #1b085a);
	background-size: 400% 400%;
	animation: gradient 2.4s ease-in infinite;
	color: #fff;
	border: 1px solid #755bcc;
	display: flex;
	gap: 5px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	padding: 9px;
	img {
		width: 100%;
	}
}
/* ::v-deep .tooltip-inner::after {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	animation: gradientChange 5s infinite alternate ease-in-out;
} */

.app-menu {
	width: 100%;
	height: 54px;
	box-sizing: initial;
	display: flex;
	align-items: center;
	border-top: 1px solid #f5f5f5;
	border-bottom: 1px solid #f5f5f5;
	.wrapper {
		display: flex;
		align-items: center;
		position: relative;
	}
	button {
		display: inline-flex;
		align-items: center;
		padding: 0 10px;
		height: 45px;
		background-color: white;
		transition: all 0.3s;
		margin-left: -10px;
		img {
			filter: brightness(0);
			margin-right: 5px;
		}
		b {
			display: inline-flex;
			align-items: center;
			height: inherit;
			font-weight: 700;
			font-size: 14px;
		}
		&:hover {
			background-color: $PRIMARY_YELLOW;
			border-radius: 5px 5px 0 0;
			color: $PRIMARY_BLACK;
		}
		&.active {
			background-color: $PRIMARY_YELLOW;
			border-radius: 5px 5px 0 0;
			color: $PRIMARY_BLACK;
		}
	}
	ul {
		display: inline-flex;
		align-items: center;
		height: 45px;
		&.right-menu-wrap {
			margin-left: auto;
			font-weight: 400;
			font-size: 14px;
			color: #333333;
			img {
				padding-right: 5px;
			}
			p {
				max-width: 300px;
				overflow: hidden;
				text-overflow: ellipsis;
				word-wrap: break-word;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
		span {
			cursor: pointer;
			margin-left: 10px;
			&.join {
				color: $PRIMARY_BLUE;
				text-decoration: underline;
				font-weight: 600;
			}
			&.limit {
				color: $PRIMARY_GREEN;
				font-weight: 600;
				&.warning {
					color: $PRIMARY_RED;
				}
				&:hover {
					text-decoration: underline;
				}
			}
		}
		li {
			position: relative;
			height: inherit;
			display: inline-flex;
			padding: 0 10px;
			align-items: center;
			cursor: pointer;
			font-size: 0.875rem;
			font-weight: 500;
			min-width: 64px;
			justify-content: center;
			font-size: 14px;
			gap: 6px;
			&.lmp {
				padding-left: 0px;
				padding-right: 15px;
			}
			&.sadadream {
				color: #ff8a00;
				padding-right: 15px;
			}
			&.lmp,
			&.sadadream {
				font-weight: 700;
				&::after {
					right: 0px;
					display: block;
					content: '';
					position: absolute;
					width: 1px;
					height: 16px;
					background-color: #ddd;
				}
			}
			&:hover,
			&.active {
				text-decoration: underline;
				font-weight: 600;
			}
		}
	}
}
</style>
