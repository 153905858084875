<template>
	<div class="required-order-wrapper">
		<div class="text-area">
			<p class="left-contents">보유물품중 유효기간이 만료(예정)했거나, 보유량이 적은 물품을 보여줍니다</p>
			<div class="right-contents" @click="viewAllRequiredProducts">
				<p>전체보기</p>
				<img src="@/assets/svg/main/viewAllBtn.svg" />
			</div>
		</div>
		<div class="contents-wrapper">
			<div class="count-view">
				<img src="@/assets/svg/main/icon-expire.svg" />
				<p>
					유효기간 만료(예정)
					<span>{{ list.expiredCnt }}</span
					>개
				</p>
				<img src="@/assets/svg/main/icon-threshold.svg" />
				<p>
					안전재고량 이하
					<span>{{ list.thresholdCnt }}</span
					>개
				</p>
			</div>
			<required-product
				v-for="(product, index) in list.products"
				:key="`product_${index}`"
				:product="product"
				:index="index"
				@showModalDelete="$emit('showModalDelete', product)"
			/>
		</div>
	</div>
</template>

<script>
import RequiredProduct from '@/views/main/components/RequiredProduct.vue'
import PageName from '@/utils/define/PageName'

export default {
	name: 'RequiredOrderList',
	components: {
		RequiredProduct,
	},
	props: {
		list: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		viewAllRequiredProducts() {
			this.$router.push({
				name: PageName.RequiredOrderAllList,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.required-order-wrapper {
	.text-area {
		display: flex;
		justify-content: space-between;
		padding: 10px 0 10px 0;
		.left-contents {
			font-weight: 500;
			font-size: 14px;
			color: #666666;
		}
		.right-contents {
			display: flex;
			align-items: center;
			cursor: pointer;
			p {
				font-weight: 500;
				font-size: 14px;
				padding-right: 5px;
				&:hover {
					text-decoration: underline;
				}
			}
			img {
				width: 15px;
				height: 15px;
			}
		}
	}
	.contents-wrapper {
		width: 100%;
		height: 100%;
		border: solid 1px $LINE_DIVIDER;
		border-radius: 10px;
		padding: 15px;
		.count-view {
			width: 100%;
			height: 40px;
			background-color: #f5f5f5;
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			img {
				padding: 10px;
			}
			p {
				font-weight: 400;
				font-size: 14px;
				padding-right: 10px;
				color: #333333;
			}
			span {
				font-weight: 700;
				font-size: 14px;
				padding: 0 3px 0 10px;
			}
		}
	}
}
</style>
