import axiosInstance from '@/services/axios'

const endPoint = {
	lookupBrandList: () => `brand/all`,
	lookupPopularBrandList: (offset, length) => `brand/popular?offset=${offset}&length=${length}`,
	lookupBrand: id => `brand?brandId=${id}`,
}

const brandAPI = {
	lookupBrandList: () => axiosInstance.get(endPoint.lookupBrandList()),
	lookupPopularBrandList: (offset, length) => axiosInstance.get(endPoint.lookupPopularBrandList(offset, length)),
	lookupBrand: id => axiosInstance.get(endPoint.lookupBrand(id)),
}

export default brandAPI
