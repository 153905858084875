<template>
	<base-management-list v-slot="{ item }" :list="list" emptyImageName="empty-identity" emptyMessage="등록된 사업자등록증(결제처)가 없습니다.">
		<business-registration-item :item="item" @preview="$emit('preview', $event)" @delete="$emit('delete', $event)" />
	</base-management-list>
</template>

<script>
import BaseManagementList from '@/views/my-page/components/BaseManagementList.vue'
import BusinessRegistrationItem from '@/views/my-page/my-info/business-registration/BusinessRegistrationItem.vue'

export default {
	name: 'BusinessRegistrationList',
	components: {
		BaseManagementList,
		BusinessRegistrationItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
}
</script>

<style lang="scss" scoped></style>
