<template>
	<div class="box-wrapper" @click="routeToBrandDetail">
		<img :src="item.image ? item.image : require('@/assets/images/no-image-small.jpg')" :style="{ width: item.image ? '' : '170px' }" />
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'

export default {
	name: 'MainBoxItem',
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		type: {
			type: String,
			default: '',
		},
	},
	methods: {
		routeToBrandDetail() {
			this.$router.push({
				name: PageName.Search,
				params: {
					category: this.type,
				},
				query: {
					id: this.item.id,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.box-wrapper {
	border: 1px solid #eeeeee;
	border-radius: 10px;
	width: 170px;
	height: 60px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	padding: 0 21px;
	position: relative;
	overflow: hidden;
	&:hover {
		outline: solid 5px #fff5dd;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	input[type='checkbox'] {
		position: absolute;
		bottom: 15px;
		right: 15px;
		&.wish {
			&:after {
				top: -10px;
				left: -10px;
			}
		}
	}
}
</style>
