import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import cookies from 'vue-cookies'

export default {
	data() {
		return {}
	},
	computed: {
		...mapGetters('user', ['instituteId', 'userInfo']),
		...mapState('user', ['institute', 'institutes']),
		...mapState('delivery', ['addressList', 'basicAddress']),
		...mapState('institute', ['institute']),
		...mapState('user', ['user']),
	},
	methods: {
		...mapMutations('user', ['setInstitute', 'setMyPageInfo']),
		...mapActions('delivery', ['lookupAddressList']),
		...mapActions('toast', ['showToast']),
		...mapActions('user', ['getInstitute']),

		async fetchAddressList(instituteId) {
			try {
				await this.lookupAddressList(instituteId)
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async refreshCurrentInstitute() {
			if (!this.instituteId) return
			await this.fetchInstitute()
			this.setMyPageInfo({
				...findInstitute,
				instituteName: findInstitute?.name,
				name: this.userInfo.name,
				image: this.userInfo.image,
			})
		},
		async setCurrentData(institute) {
			this.setInstitute(institute)
			cookies.set('currentInstitute2', JSON.stringify(institute))
			await this.fetchAddressList(institute.id)
			if (!cookies.get('currentAddress2') && !this.$_.isEmpty(this.basicAddress))
				cookies.set('currentAddress2', JSON.stringify(this.basicAddress))
		},
	},
}
