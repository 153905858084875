import axiosInstance from '@/services/axios'

const endPoint = {
	lookupAddressList: instituteId => `institute/${instituteId}/delivery/list`,
	addAddress: instituteId => `institute/${instituteId}/delivery`,
	updateAddress: instituteId => `institute/${instituteId}/delivery`,
	deleteAddress: (instituteId, id) => `institute/${instituteId}/delivery?deliveryId=${id}`,
}

const deliveryAPI = {
	lookupAddressList: instituteId => axiosInstance.get(endPoint.lookupAddressList(instituteId)),
	addAddress: (instituteId, body) => axiosInstance.post(endPoint.addAddress(instituteId), body),
	updateAddress: (instituteId, body) => axiosInstance.patch(endPoint.updateAddress(instituteId), body),
	deleteAddress: (instituteId, id) => axiosInstance.delete(endPoint.deleteAddress(instituteId, id)),
}

export default deliveryAPI
