<template>
	<base-form-item :title="title" :required="required" :direction="direction" :description="description">
		<img class="profile" :class="{ border: hasImage }" :src="profileImage" />
		<img class="camera" src="@/assets/svg/camera-circled.svg" @click="onClickCamera" />
		<input ref="image-file" type="file" accept=".jpg, .jpeg, .png" @change="uploadImage" hidden />
	</base-form-item>
</template>

<script>
import BaseFormItem from '@/components/form-item/BaseFormItem.vue'

export default {
	name: 'ImageFormItem',
	components: {
		BaseFormItem,
	},
	extends: BaseFormItem,
	props: {
		src: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			hasImage: false,
		}
	},
	computed: {
		profileImage() {
			if (this.src) this.hasImage = true
			return this.src ? this.src : require('@/assets/svg/empty-profile.svg')
		},
	},
	methods: {
		onClickCamera() {
			this.$refs['image-file'].click()
		},
		uploadImage(e) {
			const imageFile = e.target.files[0]
			this.profileImage = URL.createObjectURL(imageFile)
			this.$refs['image-file'].value = ''
			this.$emit('change', imageFile)
		},
	},
}
</script>

<style lang="scss" scoped>
.profile {
	width: 88px;
	height: 88px;
	border-radius: 140px;
}
.border {
	border: 2px solid $COLOR_EEE !important;
}
.camera {
	position: absolute;
	bottom: 0;
	left: 56px;
	cursor: pointer;
}
</style>
