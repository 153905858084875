<template>
	<b-modal id="ModalCommon" centered :title="title" ok-title="확인" ok-only ok-variant="primary" ref="modal-common" @ok="$emit('ok')">
		<div class="msg-wrapper">
			<p v-html="content"></p>
		</div>
	</b-modal>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
	},
	methods: {
		show() {
			this.$refs['modal-common'].show()
		},
		hide() {
			this.$refs['modal-common'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
.msg-wrapper {
	margin-top: -15px;
	p {
		font-size: 0.875rem;
		font-weight: 400;
	}
}
</style>
