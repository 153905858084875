<template>
	<div class="payment-info">
		<div class="info-item">
			<div class="info-title">
				<div class="title">결제 수단</div>
				<div class="checkbox-wrapper">
					<input type="checkbox" v-model="isCheck" id="paymentInfoCheckbox" class="common" />
					<label for="paymentInfoCheckbox">선택한 결제수단을 다음에도 사용</label>
				</div>
			</div>
			<div class="info-content">
				<div class="content">
					<template>
						<div class="card-wrapper">
							<div class="card-input-text">
								<div class="card-input">
									<input
										type="radio"
										id="card"
										class="common"
										name="paymentTypeList"
										:value="PAYMENT_METHOD.CARD"
										v-model="paymentMethod"
									/>
									<label for="card">카드 간편결제</label>
								</div>
								<div class="card-text">선택하신 <span class="black">카드정보 변경은 마이페이지 메뉴</span>에서 하실 수 있습니다.</div>
							</div>
							<payment-info-card
								ref="payment-info-card"
								v-if="paymentMethod === PAYMENT_METHOD.CARD"
								:isShowBusinessRegistration="true"
							></payment-info-card>
						</div>
						<hr />
						<div class="tax-wrapper">
							<div class="tax-input">
								<input
									type="radio"
									id="tax"
									class="common"
									name="paymentTypeList"
									:value="PAYMENT_METHOD.EBILL"
									v-model="paymentMethod"
								/>
								<label for="tax">계좌이체결제(세금계산서 발행)</label>
							</div>
							<div class="box" v-if="paymentMethod === PAYMENT_METHOD.EBILL">
								<div class="notice-box">
									<img src="@/assets/svg/icon/icon-caution.svg" />
									<p v-html="taxNotice"></p>
								</div>
								<drop-down
									class="drop-down"
									:value="businessRegistration"
									:list="filterBusinessRegistrations"
									@select="selectBusinessRegistration"
									:placeholder="`결제처를 선택해주세요`"
								/>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import PaymentInfoCard from '@/views/payment/components/PaymentInfoCard.vue'
import DropDown from '@/components/DropDown.vue'
import cookies from 'vue-cookies'
import { ToastType } from '@/utils/define/ToastType'

const PAYMENT_METHOD = {
	CARD: 'CARD',
	EBILL: 'EBILL',
}

export default {
	name: 'PaymentInfoWithTax',
	components: {
		PaymentInfoCard,
		DropDown,
	},
	data() {
		return {
			PAYMENT_METHOD,
			isCheck: false,
			paymentMethod: '',
			businessRegistration: {},
		}
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchBusinessRegistration()
				this.paymentMethod = cookies.get('unPaymentPaymentMethod') ?? PAYMENT_METHOD.CARD
			},
			immediate: true,
		},
		paymentMethod: {
			handler(newVal) {
				if (!newVal) return

				this.$emit('changePaymentMethod', this.paymentMethod)
			},
			immediate: true,
		},
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('businessRegistration', ['businessRegistrations']),
		taxNotice() {
			return '전자세금계산서 결제 후 <b>결제처에 서류를 전달</b>해주세요. 결제처에서 <b>입금처리</b>를 해야 <b>결제가 완료</b>됩니다.<br><b>매달 10일</b>이 지나면 이전달 결제한 세금계산서 취소는 <b>불가능</b>합니다'
		},
		filterBusinessRegistrations() {
			return this.businessRegistrations.filter(businessRegistration => ['APPROVE'].includes(businessRegistration.status))
		},
	},
	methods: {
		...mapActions('businessRegistration', ['lookupRegistration']),
		async fetchBusinessRegistration() {
			try {
				await this.lookupRegistration(this.instituteId)
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		selectBusinessRegistration(businessRegistration) {
			this.businessRegistration = businessRegistration
		},
		getPaymentInfo() {
			const paymentInfo = {
				paymentMethod: this.paymentMethod,
				isRememberPaymentMethod: this.isCheck,
			}
			if (!this.$_.isEmpty(this.$refs['payment-info-card'])) {
				const card = this.$refs['payment-info-card'].getCard()
				const businessRegistration = this.$refs['payment-info-card'].getBusinessRegistration()
				paymentInfo.cardId = !this.$_.isEmpty(card) ? card.id : null
				paymentInfo.businessRegistrationId = businessRegistration.id
			} else {
				paymentInfo.businessRegistrationId = this.businessRegistration.id
			}
			return paymentInfo
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-info {
	display: flex;
	flex-direction: column;
	.info-item {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.info-title {
			display: flex;
			.title {
				font-weight: bold;
				font-size: 1.25rem;
			}
			.checkbox-wrapper {
				display: flex;
				align-items: center;
				margin-left: 20px;
				label {
					font-size: 0.875rem;
					font-weight: 700;
					cursor: pointer;
				}
			}
		}
		.info-content {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 20px;
			border: 1px solid $COLOR_DDD;
			border-radius: 5px;
			.content {
				.card-wrapper {
					.card-input-text {
						display: flex;
						justify-content: space-between;
						font-weight: 500;
						align-items: center;
						margin-bottom: 20px;
						.card-text {
							color: $COLOR_666;
							font-size: 0.813rem;
							.black {
								color: $COLOR_111;
							}
						}
					}
				}
				.later-wrapper {
					.later-text {
						font-size: 0.875rem;
						font-weight: 500;
					}
					hr {
						color: $LINE_DIVIDER;
					}
				}
				.card-input,
				.tax-input,
				.later-input {
					display: flex;
					align-items: center;
				}

				hr {
					border: 1px solid $LINE_DIVIDER;
				}
				label {
					display: inline-block;
					font-weight: 500;
				}
			}
			.box {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 20px;
				background-color: $LIST_BACKGROUND;
				text-align: center;
				margin-top: 20px;
				border-radius: 5px;
				.notice-box {
					width: 100%;
					height: 88px;
					background-color: white;
					border: 1px solid $LINE_DIVIDER;
					border-radius: 5px;
					display: flex;
					align-items: center;
					padding: 0 20px;
					img {
						width: 48px;
						height: 48px;
					}
					p {
						margin-left: 48px;
					}
				}
				.drop-down {
					width: 480px;
					height: 40px;
					margin-top: 10px;
				}
			}
		}
	}
}
</style>
