<template>
	<div class="round-tab">
		<div
			class="tab"
			v-for="(tab, index) in list"
			:key="`tab_${index}`"
			:class="[{ error: error }, { active: tab.value == selectTab }]"
			@click="onClickTab(tab)"
		>
			<input type="radio" :value="tab.value" :id="`tab_${index}`" v-model="selectTab" />
			<label :for="`tab_${index}`">{{ tab.name }}</label>
		</div>
		<p class="error-msg" v-if="error" v-text="errorMessage"></p>
	</div>
</template>

<script>
export default {
	name: 'RoundTab',
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		value: {
			type: String,
			default: '',
		},
		error: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: '소속 유형을 선택해주세요',
		},
	},
	mounted() {
		this.selectTab = this.value
	},
	watch: {
		value(val) {
			this.selectTab = val
		},
	},
	data() {
		return {
			selectTab: '',
		}
	},
	methods: {
		onClickTab(tab) {
			this.selectTab = tab.value
			this.$emit('change', tab.value)
		},
	},
}
</script>

<style lang="scss" scoped>
.round-tab {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	.tab {
		display: inline-flex;
		align-items: center;
		padding: 0 25px;
		height: 48px;
		background-color: $HOVER_BACKGROUND;
		border: 1px solid $HOVER_BACKGROUND;
		border-radius: 40px;
		font-size: 1rem;
		font-weight: 700;
		position: relative;
		margin-right: 10px;
		cursor: pointer;
		&.error {
			border: 1px solid $PRIMARY_RED;
		}
		&.active {
			border: 1px solid $DEEP_YELLOW;
			color: $DEEP_YELLOW;
			background-color: $LIGHT_YELLOW;
		}
		input[type='radio'] {
			visibility: hidden;
			width: 0;
			height: 0;
			opacity: 0;
		}
		label {
			display: inline-block;
			cursor: pointer;
		}
	}
	.error-msg {
		width: 100%;
		font-size: 0.75rem;
		font-weight: 400;
		padding-left: 18px;
		display: block;
		background-image: url(~@/assets/svg/icon-login-error.svg);
		background-position: center left 0;
		color: $PRIMARY_RED;
		margin-top: 10px;
	}
}
</style>
