<template>
	<div class="lab-info-wrapper">
		<div class="lab-info-header">
			<span>소속 정보</span>
			<button class="btn btn-gray sm" @click="onClickEdit">수정</button>
		</div>
		<div class="lab-info-content">
			<div>
				<span class="title">소속</span>
				<span>{{ institute.name }}</span>
			</div>
			<div>
				<span class="title">소속 유형</span>
				<span>{{ institute.type ? institute.type.name : '' }}</span>
			</div>
			<div>
				<span class="title">{{ managerTitle }}</span>
				<span>{{ institute.managerName }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PageName from '@/utils/define/PageName'

export default {
	name: 'LabInfo',
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('institute', ['institute']),
		managerTitle() {
			if (this.institute.type?.code == 'ENTERPRISE') {
				return '구매 담당자'
			} else if (this.institute.type?.code == 'SELLER') {
				return '재판매업체(법인) 이름'
			} else {
				return '교수님'
			}
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				await this.fetchInstitute()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('institute', ['lookupInstitute']),
		async fetchInstitute() {
			await this.lookupInstitute(this.instituteId)
		},
		onClickEdit() {
			this.$router.push({
				name: PageName.MyPage.LabInfoEdit,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.lab-info-wrapper {
	display: flex;
	flex-direction: column;
	gap: 15px;
	.lab-info-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		> span {
			font-size: 1.25rem;
			font-weight: 700;
		}
	}
	.lab-info-content {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 20px;
		border: 1px solid $COLOR_DDD;
		border-radius: 5px;
		> div {
			display: flex;
			gap: 48px;
			> span {
				font-size: 0.875rem;
				font-weight: 500;
			}
			.title {
				width: 76px;
			}
		}
	}
}
</style>
