<template>
	<b-modal ref="modal" :id="id" :title="modalTitle" centered hide-footer @show="onShow">
		<div class="modal-password-confirm-wrapper">
			<div class="description">{{ description }}</div>
			<div class="password">
				<template v-if="step === 1">
					<img
						v-for="(password, index) of passwords"
						:key="index"
						:src="require(password || password === 0 ? '@/assets/svg/bullet-active.svg' : '@/assets/svg/bullet.svg')"
					/>
				</template>
				<template v-else>
					<img
						v-for="(password, index) of confirmPasswords"
						:key="index"
						:src="require(password || password === 0 ? '@/assets/svg/bullet-active.svg' : '@/assets/svg/bullet.svg')"
					/>
				</template>
			</div>
			<div class="keypad-wrapper">
				<div v-for="(row, index) in rows" :key="index" class="keypad-row">
					<template v-if="index < rows.length - 1">
						<div v-for="(col, idx) in row" :key="`${index}${idx}`" class="keypad-col" @click="onInput(col, currentPasswords)">
							<div class="keypad">{{ col }}</div>
						</div>
					</template>
					<template v-else>
						<div class="keypad-col" @click="onShuffle">
							<div class="keypad text">재배열</div>
						</div>
						<div v-for="(col, idx) in row" :key="`${index}${idx}`" class="keypad-col" @click="onInput(col, currentPasswords)">
							<div class="keypad">{{ col }}</div>
						</div>
						<div class="keypad-col" @click="onDelete(currentPasswords)">
							<img src="@/assets/svg/back.svg" />
						</div>
					</template>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
export default {
	name: 'ModalPasswordConfirm',
	props: {
		id: {
			type: String,
			default: 'ModalPasswordConfirm',
		},
		isPay: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			step: 1,
			passwords: new Array(6),
			confirmPasswords: new Array(6),
			keys: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
			rows: [],
		}
	},
	computed: {
		modalTitle() {
			return this.isPay ? '결제 비밀번호 입력' : this.step === 1 ? '결제 비밀번호 등록' : '결제 비밀번호 확인'
		},
		description() {
			return this.step === 1 ? '결제 비밀번호를 입력해주세요' : '결제 비밀번호를 한번 더 입력해주세요'
		},
		password() {
			return this.passwords.join('')
		},
		confirmPassword() {
			return this.confirmPasswords.join('')
		},
		currentPasswords() {
			return this.step === 1 ? this.passwords : this.confirmPasswords
		},
	},
	methods: {
		onShow() {
			this.init()
		},
		init() {
			this.step = 1
			this.passwords = new Array(6)
			this.confirmPasswords = new Array(6)
			this.onShuffle()
		},
		onShuffle() {
			this.shuffle(this.keys)
			this.rows = this.division(this.keys, 3)
		},
		onInput(key, array) {
			if (array.join('').length >= 6) return

			let index = 0
			for (let number of array) {
				if (!number && number !== 0) {
					array[index] = key
					this.$forceUpdate()

					if (index < 5) break
					if (!this.isPay) {
						if (this.step === 1) {
							this.step = 2
							this.onShuffle()
						} else if (this.step === 2) {
							if (this.password !== this.confirmPassword) {
								this.$root.toast('비밀번호 확인 실패', '결제 비밀번호와 확인 비밀번호가 일치하지 않습니다', 'error')
								this.init()
								return
							}
							this.$emit('regist', this.password)
							this.$refs.modal.hide()
						}
					} else {
						this.$emit('regist', this.password)
						this.$refs.modal.hide()
					}
				}
				index++
			}
		},
		onDelete(array) {
			if (array.join('').length <= 0) return

			let index = array.length - 1
			for (let i = index; i >= 0; i--) {
				if (!array[i] && array[i] !== 0) {
					index--
					continue
				}

				array[i] = undefined
				this.$forceUpdate()
				break
			}
		},
		shuffle(array) {
			for (let index = array.length - 1; index > 0; index--) {
				const randomPosition = Math.floor(Math.random() * (index + 1))

				const temporary = array[index]
				array[index] = array[randomPosition]
				array[randomPosition] = temporary
			}
		},
		division(array, count) {
			const temp = this.$_.cloneDeep(array)
			const length = temp.length
			const divide = Math.floor(length / count) + (Math.floor(length % count) > 0 ? 1 : 0)

			const result = []
			for (let i = 0; i < divide; i++) {
				result.push(temp.splice(0, count))
			}
			return result
		},
	},
}
</script>

<style lang="scss">
#ModalPasswordConfirm___BV_modal_outer_,
#ModalPasswordConfirm2___BV_modal_outer_,
#ModalPasswordConfirm3___BV_modal_outer_ {
	.modal-dialog {
		max-width: 335px;
	}
	#ModalPasswordConfirm___BV_modal_header_,
	#ModalPasswordConfirm2___BV_modal_header_,
	#ModalPasswordConfirm3___BV_modal_header_ {
		&.modal-header {
			border-bottom: 1px solid $COLOR_EEE;
			text-align: center;
		}
		.modal-title {
			width: 100%;
		}
	}
	#ModalPasswordConfirm___BV_modal_content_,
	#ModalPasswordConfirm2___BV_modal_content_,
	#ModalPasswordConfirm3___BV_modal_content_ {
		border-radius: 10px;
	}
}
</style>

<style lang="scss" scoped>
.modal-password-confirm-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 56px;
	padding: 48px 3px 16px 3px;
	.description {
		font-weight: 500;
		font-size: 0.875rem;
		color: $COLOR_999;
	}
	.password {
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
	.keypad-wrapper {
		display: flex;
		flex-direction: column;
		gap: 8px;
		.keypad-row {
			display: flex;
			align-items: center;
			justify-content: space-around;
			gap: 16px;
			width: 100%;
			.keypad-col {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 88px;
				height: 47px;
				cursor: pointer;
				&:hover {
					background: $LIST_BACKGROUND;
				}
				.keypad {
					font-weight: 500;
					font-size: 1.5rem;
					&.text {
						font-weight: 700;
						font-size: 0.75rem;
					}
				}
			}
		}
	}
}
</style>
