<template>
	<div class="floating-menu">
		<ul class="list">
			<li class="item" v-for="(item, index) in list" :key="`floating_${index}`" @click.stop="item.clickEv">
				{{ item.name }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'FloatingMenu',
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {}
	},
}
</script>

<style lang="scss">
.floating-menu {
	width: 200px;
	position: absolute;
	top: 0px;
	right: 0;
	z-index: 10;
	.list {
		width: 100%;
		padding: 5px;
		background-color: white;
		border: 1px solid $COLOR_CCC;
		border-radius: 5px;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
		li {
			display: flex;
			width: 100%;
			height: 30px;
			align-items: center;
			font-size: 0.875rem;
			font-weight: 400;
			background-color: white;
			transition: all 0.3s;
			padding-left: 10px;
			border-radius: 5px;
			&:hover {
				background-color: #f2f2f2;
			}
		}
	}
}
</style>
