<template>
	<div class="count-input-wrapper" :class="[{ lg: lg }, { count: optionCount }]">
		<input type="number" v-model.number="count" required @input="changeCount" :readonly="isReadOnly" />
		<button class="minus-btn" :disabled="isDisabled" @click="minusClickEvent">-</button>
		<button class="plus-btn" @click="plusClickEvent">+</button>
	</div>
</template>

<script>
const COUNT_TYPE = {
	PRODUCT: 'product',
}

export default {
	props: {
		lg: {
			default: false,
			type: Boolean,
		},
		stock: {
			type: Number,
			default: 0,
		},
		index: {
			type: Number,
			default: 0,
		},
		optionCount: {
			type: Number,
			default: 0,
		},
		type: {
			type: String,
			default: '',
		},
		isReadOnly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			count: 1,
			minCount: 0,
		}
	},
	computed: {
		isDisabled() {
			return this.count <= this.minCount
		},
	},
	watch: {
		count(val) {
			this.$emit('change', val, this.index)
		},
		optionCount: {
			handler(val) {
				this.count = val
				this.$emit('change', val, this.index)
			},
			immediate: true,
		},
	},
	created() {
		this.minCount = this.type === COUNT_TYPE.PRODUCT ? 0 : 1
	},
	methods: {
		minusClickEvent() {
			if (this.count > this.minCount) this.count--
		},
		plusClickEvent() {
			this.count++
		},
		changeCount(event) {
			if (!event.target.value || event.target.value <= 0) this.count = 0
			else this.count = parseInt(event.target.value)
		},
	},
}
</script>
<style lang="scss" scoped>
.count-input-wrapper {
	display: inline-flex;
	flex-direction: row;
	border: 1px solid #ddd;
	border-radius: 5px;
	padding: 4px;
	height: 30px;
	width: 90px;
	background: white;
	input {
		text-align: left;
		border: none;
		width: 35px;
		padding-left: 5px;
	}
	button {
		background-color: #f2f3f4;
		margin-left: auto;
		width: 20px;
		height: 20px;
		border: none;
		border-radius: 4px;
		background-repeat: no-repeat;
		background-position: center;
		text-indent: -9999px;
		background-size: 16px;
		cursor: pointer;
		transition: all 0.3s;
		&.minus-btn {
			background-image: url(~@/assets/svg/input-count-minus.svg);
		}
		&.plus-btn {
			background-image: url(~@/assets/svg/input-count-plus.svg);
		}
		&:disabled {
			background-color: rgba(242, 243, 244, 0.2);
			border: 1px solid #f2f3f4;
			border-radius: 4px;
			cursor: not-allowed;
			opacity: 0.3;
		}
	}
	&.lg {
		height: 40px;
		width: 120px;
		input {
			width: 40px;
		}
		button {
			width: 30px;
			height: 30px;
		}
	}
}
</style>
