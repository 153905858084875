<template>
	<div class="category-wrapper" @mouseover="$emit('toggleMouseOnCategory', true)" @mouseleave="$emit('toggleMouseOnCategory', false)">
		<div class="category-box">
			<ul v-for="(category, index) in list" :key="`category_${index}`">
				<p class="title" @click="onClickCategory(category)">{{ category.korName }}</p>
				<li v-for="(item, index) in category.tags" :key="`item${index}`">
					<p class="item" @click="onClickCategory(item, category)">{{ item }}</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import { mapState, mapActions } from 'vuex'

export default {
	name: 'AllCategoryBox',
	computed: {
		...mapState('category', ['categoryList']),
		list() {
			return this.categoryList
		},
	},
	data() {
		return {}
	},
	created() {
		this.loadMenuList()
	},
	methods: {
		...mapActions('category', ['lookupCategoryList']),
		async loadMenuList() {
			await this.lookupCategoryList()
		},
		onClickCategory(category, parent) {
			this.$router
				.push({
					name: PageName.SearchList,
					query: {
						classification: parent ? parent.code : category.code,
						category: parent ? category.code : null,
						tags: parent ? category : null,
					},
				})
				.catch(() => {})
		},
	},
}
</script>

<style lang="scss" scoped>
.category-wrapper {
	overflow: scroll;
	position: absolute;
	z-index: 2;
	top: 134px;
	background: $PRIMARY_WHITE;
	width: 100%;
	// height: 100%;
	border-top: 1px solid #f5f5f5;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	.category-box {
		height: 100%;
		width: 1080px;
		margin: 0 auto;
		display: flex;
		// height: 100%;
		ul {
			// height: 100%;
			width: 150px;
			border-left: solid 1px $LINE_DIVIDER;
			padding: 15px 10px 0 10px;
			font-weight: 700;
			font-size: 14px;
			li {
				font-weight: 500;
				font-size: 12px;
				color: #616163;
			}
		}
		.title {
			cursor: pointer;
			padding: 5px;
			border-radius: 5px;
			width: 132px;
			height: 28px;
			font-weight: 700;
			&:hover {
				background: #fff5dd;
			}
		}
		.item {
			cursor: pointer;
			padding: 5px 5px 0 5px;
			border-radius: 5px;
			height: 28px;
			&:hover {
				background: #fff5dd;
			}
		}
	}
}
</style>
