import cardAPI from '@/services/api/card'

const cards = {
	namespaced: true,
	state: {
		cards: [],
		cardInfo: {},
	},
	getters: {
		imageUrl: state => state.cardInfo.frontImageUrl,
	},
	mutations: {
		setCards: (state, payload) => {
			state.cards = payload
		},
		setCardInfo: (state, payload) => {
			state.cardInfo = payload
		},
	},
	actions: {
		lookupCards: async ({ commit }, instituteId) => {
			const response = await cardAPI.lookupCards(instituteId)
			commit('setCards', response.data)
		},
		lookupCardInfo: async ({ commit }, { instituteId, bin }) => {
			const response = await cardAPI.lookupCardInfo(instituteId, bin)
			commit('setCardInfo', response.data)
		},
		addCard: async (_, { instituteId, body }) => {
			return await cardAPI.addCard(instituteId, body)
		},
		updateCard: async (_, { instituteId, body }) => {
			return await cardAPI.updateCard(instituteId, body)
		},
		deleteCard: async (_, { instituteId, id }) => {
			return await cardAPI.deleteCard(instituteId, id)
		},
	},
}

export default cards
