<template>
	<b-modal
		ref="modal"
		id="ModalMemberInfoEdit"
		title="정보수정"
		ok-title="완료"
		ok-variant="warning"
		cancel-title="취소"
		cancel-variant="default"
		button-size="lg"
		centered
		@show="onShow"
		@ok="onSave"
	>
		<div class="modal-member-info-edit-wrapper">
			<div class="image-wrapper">
				<img :src="info.profileImageUrl ? info.profileImageUrl : require('@/assets/svg/empty-profile.svg')" />
			</div>
			<input-form-item v-model="info.email" type="email" direction="column" title="이메일(ID)" :inputStyle="inputStyle" />
			<input-form-item v-model="info.name" direction="column" title="이름" :inputStyle="inputStyle" required />
			<input-form-item v-model="info.phone" type="tel" direction="column" title="연락처" :inputStyle="inputStyle" />
		</div>
	</b-modal>
</template>

<script>
import InputFormItem from '@/components/form-item/InputFormItem.vue'
import { mapActions, mapGetters } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'ModalMemberInfoEdit',
	components: {
		InputFormItem,
	},
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			info: {},
			inputStyle: { width: '466px' },
			initPassword: 'qwer1234',
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
	},
	watch: {
		item(val) {
			this.info = this.$_.cloneDeep(val)
		},
	},
	methods: {
		...mapActions('member', ['updateMember']),
		init() {
			this.info = {
				id: 0,
				name: '',
				email: '',
				phone: '',
				profileImageUrl: '',
			}
		},
		onShow() {
			this.init()
		},
		async onSave(e) {
			e.preventDefault()

			try {
				await this.updateMember({
					instituteId: this.instituteId,
					body: {
						...this.info,
						memberId: this.info.id,
					},
				})
				this.$emit('success')
				this.$refs.modal.hide()
				this.$root.toast('수정 완료', '멤버 정보를 수정했습니다.', ToastType.SUCCESS)
			} catch (e) {
				this.$root.toast('수정 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss">
#ModalMemberInfoEdit___BV_modal_outer_ {
	.modal-dialog {
		max-width: 500px;
	}
	#ModalMemberInfoEdit___BV_modal_body_ {
		.btn {
			background: $HOVER_BACKGROUND;
			font-size: 0.875rem;
		}
	}
	#ModalMemberInfoEdit___BV_modal_footer_ {
		&.modal-footer {
			justify-content: center;
			gap: 8px;
			.btn {
				width: 160px;
				height: 40px;
				&.btn-warning {
					background: $PRIMARY_YELLOW;
				}
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.modal-member-info-edit-wrapper {
	display: flex;
	flex-direction: column;
	.image-wrapper {
		text-align: center;
		> img {
			width: 100px;
			height: 100px;
			border-radius: 100px;
		}
	}
}
</style>
