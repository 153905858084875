<template>
	<base-management title="자주 묻는 질문">
		<div class="question-management-wrapper">
			<div class="service-center-info">
				<div class="image-wrapper">
					<img src="@/assets/svg/icon-cs.svg" />
				</div>
				<div class="info-wrapper">
					<div class="title">랩매니저 스토어 고객센터</div>
					<div class="tel">1533-3061</div>
					<div class="working-time">상담시간 평일 10:00 ~ 17:00<br />Off-time 12:00 ~ 13:00 (토/일/공휴일 휴무)</div>
				</div>
			</div>
			<div class="search-category-list">
				<div v-for="filter of filters" :key="filter.category" class="search-category-item" @click="onClickCategory(filter.category, $event)">
					{{ filter.name }}
				</div>
			</div>
			<div class="question-management-list">
				<div v-for="question of list" :key="question.id" class="question-management-item">
					<div class="item-main" @click="onClickItem(question)">
						<div class="title-area">
							<b>Q</b>
							<span>{{ question.title }}</span>
						</div>
						<img v-show="question.active" src="@/assets/svg/arrow_up_b.svg" />
						<img v-show="!question.active" src="@/assets/svg/arrow_down_g.svg" />
					</div>
					<div v-show="question.active" class="item-content">
						<div class="title-area">
							<b>A</b>
						</div>
						<div class="content-area">
							<div class="title-area">
								<span v-html="question.html"></span>
							</div>
							<!-- TODO: 1:1 문의 미구현 주석 처리 by gangmin -->
							<!-- <div class="requestion">답변이 충분하지 않으셨나요?</div>
							<div>
								<button class="btn btn-white" @click="onClickInquiry">1:1 문의하기</button>
							</div> -->
						</div>
					</div>
				</div>
			</div>
			<common-pagenation ref="CommonPagenation" class="common-pagenation" :total="count" :isPerPage="false" @page="getCurrentPage" />
		</div>
	</base-management>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PageName from '@/utils/define/PageName'
import BaseManagement from '@/views/cs-center/components/BaseManagement.vue'
import CommonPagenation from '@/components/pagenation/CommonPagenation.vue'

export default {
	name: 'QuestionManagement',
	components: {
		BaseManagement,
		CommonPagenation,
	},
	data() {
		return {
			offset: 0,
			length: 10,
			list: [],
			categoryFilter: null,
		}
	},
	computed: {
		...mapState('faq', ['faqList', 'count', 'filters']),
	},
	async mounted() {
		await this.lookupFAQFilter()
		this.$refs.CommonPagenation.changeLength(10)
		this.onClickCategory(this.filters[0].category, { target: document.querySelector('.search-category-item') })
	},
	methods: {
		...mapActions('faq', ['lookupFAQList', 'lookupFAQFilter']),
		async fetchQuestionList() {
			const selectedFilter = this.filters.find(filter => {
				return filter.code === this.categoryFilter
			})
			await this.lookupFAQList({
				offset: this.offset,
				length: this.length,
				categoryIds: [selectedFilter.id],
			})

			this.list = this.faqList.map(item => {
				item.active = false
				return item
			})
		},
		onClickCategory(category, e) {
			document.querySelectorAll('.search-category-item').forEach(node => node.classList.remove('active'))
			e.target.classList.add('active')

			this.categoryFilter = category
			this.fetchQuestionList()
		},
		onClickItem(item) {
			this.list.forEach(question => {
				if (item != question) {
					question.active = false
				} else {
					item.active = !item.active
				}
			})
			this.$forceUpdate()
		},
		onClickInquiry() {
			this.$router.push({
				name: PageName.CsCenter.InquiryEdit,
				params: {
					mode: 'write',
				},
			})
		},
		getCurrentPage({ offset, length }) {
			this.offset = offset
			this.length = length
			this.fetchQuestionList()
		},
		convertHTML(value) {
			return typeof value.html == 'string' ? value.html.replace(/\<p\>\<\/p\>/g, '<br>') : value.html
		},
	},
}
</script>

<style lang="scss" scoped>
.question-management-wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-bottom: 64px;
	.service-center-info {
		display: flex;
		gap: 24px;
		align-items: center;
		padding: 20px;
		background: $PRIMARY_WHITE;
		border: 1px solid $COLOR_EEE;
		border-radius: 5px;
		.image-wrapper {
			width: 100px;
			height: 100px;
			border-radius: 100px;
		}
		.info-wrapper {
			display: flex;
			flex-direction: column;
			gap: 4px;
			.title {
				font-weight: 500;
				font-size: 0.875rem;
			}
			.tel {
				font-weight: 700;
				font-size: 1.5rem;
			}
			.working-time {
				font-weight: 500;
				font-size: 0.75rem;
				color: $COLOR_999;
				margin-top: 4px;
			}
		}
	}
	.search-category-list {
		display: flex;
		gap: 10px;
		flex-wrap: wrap;
		.search-category-item {
			display: flex;
			align-items: center;
			height: 30px;
			padding: 5px 16px;
			border-radius: 10px;
			font-weight: 500;
			font-size: 0.875rem;
			background: $LIST_BACKGROUND;
			color: $COLOR_666;
			cursor: pointer;
			white-space: nowrap;
			&:hover {
				background: $COLOR_EEE;
			}
			&.active {
				background: $COLOR_333;
				color: $PRIMARY_WHITE;
			}
		}
	}
	.question-management-list {
		display: flex;
		flex-direction: column;
		.question-management-item {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			border-top: 1px solid $COLOR_EEE;
			.item-main {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 74px;
				padding: 24px 16px;
				cursor: pointer;
				.title-area {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 8px;
					> b {
						font-weight: 600;
						font-size: 1.25rem;
						color: #faa400;
					}
					> span {
						font-weight: 500;
						font-size: 1rem;
					}
				}
			}
			.item-content {
				display: flex;
				gap: 10px;
				padding: 24px 16px;
				border-radius: 5px;
				background: $LIST_BACKGROUND;
				.title-area {
					display: flex;
					align-items: baseline;
					justify-content: center;
					gap: 8px;
					> b {
						font-weight: 600;
						font-size: 1.25rem;
						color: $COLOR_999;
						margin-top: -3px;
						display: inline-block;
					}
					> span {
						width: 100%;
						font-weight: 400;
						font-size: 0.875rem;
						margin-bottom: 8px;
						// TODO: 1:1 문의 미구현 주석 처리 by gangmin
						// border-bottom: 1px solid $COLOR_DDD;
						// padding-bottom: 24px;
					}
				}
				.content-area {
					display: flex;
					flex-direction: column;
					flex: 1;
					gap: 16px;
					.requestion {
						font-weight: 400;
						font-size: 0.875rem;
					}
					.btn {
						width: 120px;
						height: 40px;
						font-size: 0.875rem;
					}
				}
			}
		}
	}
	.common-pagenation {
		border-top: 1px solid $COLOR_EEE;
	}
}
</style>
