const Request = {
	WAIT: 'WAIT',
	ING: 'ING',
	COMPLETE: 'COMPLETE',
	FAIL: 'FAIL',
	statusToString: status => {
		switch (status) {
			case Request.WAIT:
				return '접수중'
			case Request.ING:
				return '상품 탐색중'
			case Request.COMPLETE:
				return '소싱 완료'
			case Request.FAIL:
				return '소싱 실패'
		}
	},
	statusToClass: status => {
		switch (status) {
			case Request.COMPLETE:
				return 'complete'
			case Request.FAIL:
				return 'fail'
			default:
				return 'common'
		}
	},
}

const Estimate = {
	UNREAD: 'UNREAD',
	READ: 'READ',
	ORDERED: 'ORDERED',
	statusToString: status => {
		switch (status) {
			case Estimate.UNREAD:
				return '미확인'
			case Estimate.READ:
				return '확인'
			case Estimate.ORDERED:
				return '주문완료'
		}
	},
	statusToClass: status => {
		switch (status) {
			case Estimate.ORDERED:
				return 'complete'
			case Estimate.READ:
				return 'confirm'
			default:
				return 'common'
		}
	},
}

const Inquiry = {
	WAIT: '답변대기',
	COMPLETE: '답변완료',
	statusToClass: status => {
		switch (status) {
			case Inquiry.COMPLETE:
				return 'complete'
			default:
				return 'common'
		}
	},
}

const Payment = {
	WAIT: 'WAIT',
	PAID: 'PAID',
	CANCEL: 'CANCEL',
	EBILL: 'EBILL',
	CARD: 'CARD',
	DIRECT_CARD: 'DIRECT_CARD',
	statusToString: status => {
		switch (status) {
			case Payment.WAIT:
				return '입금대기'
			case Payment.PAID:
				return '결제완료'
			case Payment.CANCEL:
				return '결제취소'
		}
	},
	statusToClass: status => {
		switch (status) {
			case Payment.PAID:
				return 'complete'
			case Payment.CANCEL:
				return 'fail'
			default:
				return 'common'
		}
	},
	methodToString: method => {
		switch (method) {
			case Payment.EBILL:
				return '전자세금계산서'
			case Payment.CARD:
				return '카드 간편결제'
			case Payment.DIRECT_CARD:
				return '일반결제'
		}
	},
}

export { Request, Estimate, Inquiry, Payment }
