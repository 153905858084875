import axiosInstance from '@/services/axios'

const endPoint = {
	lookupBanners: () => '/banner/list',
	lookupBestByCategory: () => '/productInfo/category-best',
	lookupMdPicks: () => '/productInfo/recommend',
}

const productAPI = {
	lookupBanners: () => axiosInstance.get(endPoint.lookupBanners()),
	lookupBestByCategory: params => axiosInstance.get(endPoint.lookupBestByCategory(), { params }),
	lookupMdPicks: params => axiosInstance.get(endPoint.lookupMdPicks(), { params }),
}

export default productAPI
