<template>
	<div class="start-form">
		<!--1. 소속 유형-->
		<round-tab
			v-if="progressStep == 1"
			:list="startDefine.typeList"
			:value="info[infoKey]"
			:error="!valid[infoKey]"
			@change="$emit('change', $event, 'type')"
		/>

		<!--step2. 소속 정보 // step3. 구매담당자 섬항(교수님 성함)-->
		<input-form-item
			v-else-if="progressStep <= 4"
			:value="info[infoKey]"
			type="text"
			:placeholder="startDefine.placeholder(infoKey)"
			:error="!valid[infoKey]"
			:errorMessage="errorMessage"
			:autofocus="autofocus"
			@input="$emit('change', $event, infoKey)"
		/>
		<!--step4. 사업자등록증 업로드-->
		<upload-file-form-item
			v-if="progressStep == 4"
			class="upload-file-form-item"
			description="사업자등록증 파일을 마우스로 끌어오거나, 직접 선택해 주세요"
			:error="!valid.certificationFiles"
			errorMessage="사업자등록증을 업로드해주세요."
			:list="info.certificationFiles"
			@upload="$emit('change', $event, 'certificationFiles')"
		/>
		<!--step5. 배송지 정보-->
		<shipping-info-form
			v-else-if="progressStep == 5"
			:info="info"
			:startDefine="startDefine"
			:error="!valid.shippingInfo"
			:errorMessage="errorMessage"
			@change="$emit('change', $event, 'shippingInfo')"
		/>
		<invite-member-form v-else-if="progressStep == 6" :createdInstituteId="createdInstituteId" />
	</div>
</template>

<script>
import RoundTab from '@/components/tab/RoundTab'
import InputFormItem from '@/components/form-item/InputFormItem'
import UploadFileFormItem from '@/components/form-item/UploadFileFormItem'
import ShippingInfoForm from '@/views/start/components/ShippingInfoForm'
import InviteMemberForm from '@/views/start/components/InviteMemberForm'

export default {
	name: 'StartForm',
	props: {
		progressStep: {
			type: Number,
			default: 0,
		},
		info: {
			type: Object,
			default: () => {},
		},
		valid: {
			type: Object,
			default: () => {},
		},
		infoKey: {
			type: String,
			default: '',
		},
		startDefine: {
			type: Object,
			default: () => {},
		},
		errorMessage: {
			type: String,
			default: '',
		},
		createdInstituteId: {
			type: String,
			default: '',
		},
	},
	components: {
		RoundTab,
		InputFormItem,
		UploadFileFormItem,
		ShippingInfoForm,
		InviteMemberForm,
	},
	computed: {},
	watch: {
		progressStep() {
			this.autofocus = false

			setTimeout(() => {
				this.autofocus = true
			}, 100)
		},
	},
	data() {
		return {
			autofocus: true,
		}
	},
	methods: {
		changeShippingInfo(val, key) {
			this.$emit('change', val, 'shippingInfo', key)
		},
	},
}
</script>

<style lang="scss">
.start-form {
	.input-form-item {
		.item-title {
			display: none;
		}
		.item-content,
		.base-input-wrapper,
		.base-input {
			width: 100%;
			height: 48px;
			font-weight: 400;
		}
		.base-input {
			font-size: 1rem;
			&:focus {
				border: 1px solid black;
			}
		}
	}
	.upload-file-form-item {
		flex-wrap: wrap;
		.item-title {
			display: block;
		}
		.item-content {
			flex-wrap: wrap;
		}
	}
}
</style>
