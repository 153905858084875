const ProTooltipTextList = [
	require('@/assets/images/tooltip/text_01.png'),
	require('@/assets/images/tooltip/text_02.png'),
	require('@/assets/images/tooltip/text_03.png'),
	require('@/assets/images/tooltip/text_04.png'),
	require('@/assets/images/tooltip/text_05.png'),
	require('@/assets/images/tooltip/text_06.png'),
]

const ProTooltipText = ProTooltipTextList[Math.floor(Math.random() * ProTooltipTextList.length)]

const OrderCompleteBannerImageList = [
	{
		id: 0,
		image: require('@/assets/images/mypage_banner_01.png'),
	},
	{
		id: 1,
		image: require('@/assets/images/mypage_banner_02.png'),
	},
	{
		id: 2,
		image: require('@/assets/images/mypage_banner_03.png'),
	},
	{
		id: 3,
		image: require('@/assets/images/mypage_banner_04.png'),
	},
]

const OrderCompleteBannerImageObj = OrderCompleteBannerImageList[Math.floor(Math.random() * OrderCompleteBannerImageList.length)]

const MypageBannerImageList = [
	{
		id: 0,
		image: require('@/assets/images/mypage_banner_01.png'),
	},
	{
		id: 1,
		image: require('@/assets/images/mypage_banner_02.png'),
	},
	{
		id: 2,
		image: require('@/assets/images/mypage_banner_03.png'),
	},
	{
		id: 3,
		image: require('@/assets/images/mypage_banner_04.png'),
	},
]

const MypageBannerImageObj = MypageBannerImageList[Math.floor(Math.random() * MypageBannerImageList.length)]

export { ProTooltipText, OrderCompleteBannerImageObj, MypageBannerImageObj }
