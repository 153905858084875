import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/modules/ParamMaker'

const endPoint = {
	lookupEventList: paramsObject => `event/list?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupEvent: id => `event?eventId=${id}`,
}

const eventAPI = {
	lookupEventList: paramsObject => axiosInstance.get(endPoint.lookupEventList(paramsObject)),
	lookupEvent: id => axiosInstance.get(endPoint.lookupEvent(id)),
}

export default eventAPI
