<template>
	<div class="filter-box">
		<p class="keyword" v-if="keyword" v-html="searchResult"></p>
		<div class="filter-detail-wrap">
			<filter-detail-box
				v-if="list.filterCategoryList"
				title="카테고리"
				type="category"
				:list="list.filterCategoryList"
				:filtered="filtered"
				:selected="selected"
				@selectCategory="selectCategory"
				@checkCategory="checkCategory"
			/>
			<filter-detail-box
				v-if="isSizeFilterList"
				title="사이즈"
				type="volume"
				:list="list.filterSizeList"
				:filtered="filtered"
				@checkedList="checkedList"
			/>
			<filter-detail-box
				v-if="isSellerFilterList"
				title="판매자"
				type="seller"
				:list="list.filterSellerList"
				:filtered="filtered"
				@checkedList="checkedList"
			/>
			<filter-detail-box
				v-if="isBrandFilterList"
				title="브랜드"
				type="brand"
				:list="list.filterBrandList"
				:filtered="filtered"
				@checkedList="checkedList"
			/>
			<div class="checked-item" v-if="filtered.length > 0">
				<div class="header">
					<button @click="resetFilter">전체해제</button>
				</div>
				<div class="checked-content">
					<span v-for="(item, index) in filtered" :key="`filter_${index}`"
						>{{ item.name }}<button class="delete-btn" @click="deleteFilter(item, index)"></button
					></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FilterDetailBox from '@/components/search/FilterDetailBox.vue'
import { mapMutations, mapState } from 'vuex'

export default {
	name: 'FilterBox',
	props: {
		keyword: {
			type: String,
			default: '',
		},
		list: {
			type: Object,
			default: () => [],
		},
		listLength: {
			type: Number,
			default: 0,
		},
		selected: {
			type: Object,
			default: () => {},
		},
		mode: {
			type: String,
			default: '',
		},
	},
	components: {
		FilterDetailBox,
	},
	computed: {
		searchResult() {
			return `<b>"${this.keyword}"</b>에 대한 검색 ${this.listLength}개 결과입니다.`
		},
		isSizeFilterList() {
			return this.list?.filterSizeList?.length > 0
		},
		isBrandFilterList() {
			return this.list?.filterBrandList?.length > 0 && (this.mode === 'seller' || this.mode === 'list')
		},
		isSellerFilterList() {
			return this.list?.filterSellerList?.length > 0 && (this.mode === 'brand' || this.mode === 'list')
		},
	},
	watch: {
		filtered(val) {
			if (this.init) return
			this.$emit('setSelectedFilter', val)
		},
	},
	data() {
		return {
			init: true,
			filtered: [], //체크박스 리스트 필터된 array
			filterList: {}, //체크박스 중 checked 된 리스트
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.init = false
		})
	},
	methods: {
		//필터 리스트에 checked list 추가
		checkedList(list, type) {
			this.filterList[type] = list
			let filterValues = Object.values(this.filterList)
			let sumArray = []
			filterValues.forEach(values => {
				values.forEach(filter => {
					sumArray.push(filter)
				})
			})
			this.filtered = sumArray
		},
		selectCategory(category) {
			if (category) {
				if (category.node === 'parent') {
					this.filterList.category = [category]
					this.filterList.tag = []
					if (this.filtered.some(item => item.node === 'child')) {
						// 뒤로가기 or parent 선택
						this.filtered = this.filtered.filter(el => el.type !== 'category' && el.type !== 'tag')
						this.filtered.push(category)
					} else if (!this.filtered.some(item => item.node === 'parent')) {
						this.filtered = this.filtered.filter(el => el.type !== 'category' && el.type !== 'tag')
						this.filtered.push(category)
					}
				} else {
					this.filterList.category.push(category)
					this.filtered.push(category)
				}
			} else {
				// 카테고리 초기화했을때만
				this.filterList.category = []
				this.filterList.tag = []
				this.filtered = this.filtered.filter(item => item.type !== 'category' && item.type !== 'tag')
			}
		},
		//필터 개별 아이템 삭제
		deleteFilter(item, index) {
			let categoryFilter = this.filterList[item.type]

			categoryFilter.forEach((filter, idx) => {
				if (filter.value == item.value) {
					this.filterList[item.type].splice(idx, 1)
				}
			})

			this.filtered.splice(index, 1)
		},
		//필터 초기화
		resetFilter() {
			this.filterList = {}
			this.filtered = []
		},
		checkCategory(list) {
			this.filterList.tag = []
			this.filtered = this.filtered.filter(filter => filter.type !== 'tag')

			list.forEach(tag => {
				const filter = {
					name: tag,
					value: tag,
					type: 'tag',
				}
				this.filterList.tag.push(filter)
				this.filtered.push(filter)
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.filter-box {
	.keyword {
		font-size: 0.75rem;
		font-weight: 400;
		margin-bottom: 15px;
	}
	.filter-detail-wrap {
		width: 100%;
		border: 1px solid $COLOR_DDD;
		border-radius: 4px;
		overflow: hidden;
		margin-bottom: 20px;
		.filter-detail-box {
			&:nth-last-child(1) {
				border-bottom: 0;
			}
		}
	}
	.checked-item {
		display: flex;
		align-items: flex-start;
		padding: 15px 0 5px;
		border-top: 1px solid $LINE_DIVIDER;
		.header {
			height: 20px;
			border-right: 1px solid $COLOR_DDD;
			padding-left: 12px;
			width: 100px;
			display: inline-flex;
			align-items: center;
			button {
				height: 20px;
				background-image: url(~@/assets/svg/reset.svg);
				background-size: 16px;
				background-position: center right 0;
				background-repeat: no-repeat;
				font-size: 0.875rem;
				font-weight: 700;
				padding-right: 20px;
				margin-right: 10px;
			}
		}
		.checked-content {
			width: calc(100% - 100px);
			padding: 0 15px;
			text-align: left;
			margin-top: -5px;
			margin-bottom: 5px;
			overflow-y: auto;
			span {
				display: inline-flex;
				align-items: center;
				height: 30px;
				font-size: 0.875rem;
				color: #faa400;
				font-weight: 700;
				margin-right: 15px;
				button {
					margin-left: 4px;
					width: 16px;
					height: 16px;
					background-image: url(~@/assets/svg/close.svg);
					background-size: 16px;
					background-position: center right 0;
					background-repeat: no-repeat;
					cursor: pointer;
				}
			}
		}
	}
}
</style>
