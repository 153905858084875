<template>
	<div class="selectable-product-item">
		<div class="imgbox">
			<img :src="item.image ? item.image : require('@/assets/svg/empty_product.svg')" />
		</div>
		<div class="txtbox">
			<h6 v-if="item.seller">[{{ item.seller.name }}]</h6>
			<h5>{{ item.name }}</h5>
			<p v-html="productContent"></p>
			<div>
				<b>{{ CommaNum(item.orderItemUnitPrice || item.orderItemUnitPrice == 0 ? item.orderItemUnitPrice : item.price) }}원</b>
				<b>수량 {{ item.quantity }}개</b>
			</div>
			<div v-if="showOrderInfo" class="order-info">
				<span @click="onClickOrderInfo">주문번호: {{ item.orderNo }} / {{ item.orderer.name }}</span>
				<img src="@/assets/svg/next-arrow.svg" @click="onClickOrderInfo" />
			</div>
		</div>
		<div class="right-btn-group" v-if="selectable">
			<button class="btn btn-white" @click="$emit('select', item)">선택하기</button>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import PageName from '@/utils/define/PageName'

export default {
	name: 'SelectableProductItem',
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
		selectable: {
			type: Boolean,
			default: false,
		},
		showOrderInfo: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [mixin],
	computed: {
		productContent() {
			let content = ''
			if (this.item.category?.code) content = `<span>카테고리: ${this.categoryValue}</span>`
			if (this.item.brand?.name) content += `<span>브랜드: ${this.item.brand.name}</span>`
			if (this.item.code) content += `<span>제품번호: ${this.item.code}</span>`
			if (this.item.options) content += `<br/><span>사이즈(용량): ${this.item.options.map(option => option.value).join(', ')}</span>`
			return content
		},
		categoryValue() {
			let str = ''
			if (this.item.category.parents) {
				this.item.category.parents.forEach(item => {
					str += item.korName.concat('/')
				})
			}
			return str.concat(this.item.category.korName)
		},
	},
	methods: {
		onClickOrderInfo() {
			this.$router
				.push({
					name: PageName.MyPage.OrderDetail,
					params: {
						id: this.item.orderId,
					},
				})
				.catch(() => {})
		},
	},
}
</script>

<style lang="scss">
.selectable-product-item {
	width: 100%;
	border-radius: 5px;
	border: 1px solid $COLOR_DDD;
	padding: 20px;
	display: flex;
	align-items: center;
	.imgbox {
		min-width: 80px;
		width: 80px;
		height: 80px;
		border-radius: 4px;
		border: 1px solid $LINE_DIVIDER;
		overflow: hidden;
		margin-right: 16px;
	}
	.txtbox {
		h6 {
			font-size: 0.75rem;
			font-weight: 500;
			margin-bottom: 2px;
		}
		h5 {
			font-size: 1rem;
			font-weight: 700;
		}
		p {
			font-size: 0.75rem;
			font-weight: 400;
			color: $COLOR_666;
			margin-top: 3px;
			span {
				position: relative;
				padding: 0 7px;
				&:first-child {
					padding-left: 0px;
					&:before {
						display: none;
					}
				}
				&:before {
					position: absolute;
					top: calc(50% - 5px);
					left: 0px;
					content: '';
					background-color: #d9d9d9;
					width: 1px;
					height: 10px;
				}
			}
		}
		b {
			display: inline-block;
			margin-top: 6px;
			font-size: 0.875rem;
			font-weight: 500;
			position: relative;
			padding-right: 14px;
			&:nth-last-child(2) {
				&:before {
					display: none;
				}
			}
			&:before {
				position: absolute;
				top: calc(50% - 5px);
				left: -7px;
				content: '';
				background-color: #d9d9d9;
				width: 1px;
				height: 10px;
			}
		}
		.order-info {
			display: flex;
			align-items: center;
			margin-top: 16px;
			font-weight: 500;
			font-size: 0.75rem;
			color: $PRIMARY_BLACK;
			> span,
			> img {
				cursor: pointer;
			}
			> img {
				width: 17px;
				height: 17px;
			}
		}
	}
	.right-btn-group {
		button {
			font-size: 0.875rem;
			font-weight: 700;
			width: 120px;
			height: 40px;
		}
	}
}
</style>
