import ParamMaker from '@/utils/modules/ParamMaker'
import axiosInstance from '@/services/axios'

const endPoint = {
	lookupOrderList: (instituteId, params) => `institute/${instituteId}/order/list?${ParamMaker.setParamUsingObject(params)}`,
	lookupOrderSummary: instituteId => `institute/${instituteId}/order/list/summary`,
	lookupOrderFilter: (instituteId, status) => `institute/${instituteId}/order/list/filter?status=${status}`,
	lookupOrder: (instituteId, id) => `institute/${instituteId}/order?orderId=${id}`,
	order: instituteId => `institute/${instituteId}/order`,
	orderCalculate: instituteId => `institute/${instituteId}/order/calculate`,
	nonMemberOrderCalculate: () => `/order/calculate`,
	orderCancelRequest: instituteId => `institute/${instituteId}/order/item/cancel-request`,
	orderCancel: instituteId => `institute/${instituteId}/order/item/cancel`,
	orderPrint: instituteId => `institute/${instituteId}/order/print`,
	lookupInicisCard: instituteId => `institute/${instituteId}/payment/inicis/order`,
}

const orderAPI = {
	lookupOrderList: (instituteId, params) => axiosInstance.get(endPoint.lookupOrderList(instituteId, params)),
	lookupOrderSummary: instituteId => axiosInstance.get(endPoint.lookupOrderSummary(instituteId)),
	lookupOrderFilter: (instituteId, status) => axiosInstance.get(endPoint.lookupOrderFilter(instituteId, status)),
	lookupOrder: (instituteId, id) => axiosInstance.get(endPoint.lookupOrder(instituteId, id)),
	order: (instituteId, body) => axiosInstance.post(endPoint.order(instituteId), body),
	orderCalculate: (instituteId, body) => axiosInstance.post(endPoint.orderCalculate(instituteId), body),
	nonMemberOrderCalculate: body => axiosInstance.post(endPoint.nonMemberOrderCalculate(), body),
	orderCancelRequest: (instituteId, body) => axiosInstance.patch(endPoint.orderCancelRequest(instituteId), body),
	orderCancel: (instituteId, body) => axiosInstance.patch(endPoint.orderCancel(instituteId), body),
	orderPrint: (instituteId, body) => axiosInstance.post(endPoint.orderPrint(instituteId), body),
	lookupInicisCard: (instituteId, body) => axiosInstance.post(endPoint.lookupInicisCard(instituteId), body),
}

export default orderAPI
