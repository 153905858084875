<template>
	<div v-if="shouldRender" id="app" @click="hide">
		<app-header ref="app-header" v-show="!isOnlyRouterView" />
		<router-view class="router-view" ref="router-container" />
		<back-to-pro v-show="isShowBackToPro" />
		<toast-list />
		<app-footer v-show="!isOnlyRouterView" />
	</div>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import BackToPro from '@/components/BackToPro'
import AppFooter from '@/components/AppFooter'
import Mixin from '@/mixins'
import MixinSession from '@/mixins/session'
import MixinScrolling from '@/mixins/scrolling'

import ToastList from '@/components/toast/ToastList.vue'
import { mapActions, mapState } from 'vuex'
import { isLogin } from '@/services/api/common'

import PageName from '@/utils/define/PageName'

export default {
	name: 'App',
	components: {
		AppHeader,
		BackToPro,
		AppFooter,
		ToastList,
	},
	mixins: [Mixin, MixinSession, MixinScrolling],
	watch: {
		async $route() {
			if (isLogin() && !this.$route.hash) {
				await this.refreshCurrentInstitute()
			}

			window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
		},
	},
	created() {
		if (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()) && (window.innerWidth < 500 || window.innerHeight < 500)) {
			this.shouldRender = false

			let href = location.href
			location.href = href.replace('://', '://m-')
		}
		this.setToast()
	},
	data() {
		return {
			hideHeaderPaths: ['/startStore', '/estimate/export', '/error', '/invitation', '/mobileintroduce'],
			shouldRender: true,
		}
	},
	computed: {
		...mapState('main', ['isPro']),
		isOnlyRouterView() {
			if (this.hideHeaderPaths.indexOf(this.$route.path) >= 0) {
				return true
			} else {
				return false
			}
		},
		isShowBackToPro() {
			return this.isPro
		},
	},
	methods: {
		...mapActions('toast', ['showToast']),
		...mapActions('user', ['getInstitute']),
		// setToast() {
		// 	this.$root.toast = (title, content, type) => {
		// 		const payload = {
		// 			type: type,
		// 			message: {
		// 				title: title,
		// 				content: content,
		// 			},
		// 		}
		// 		this.showToast(payload)
		// 	}
		// },
	},
}
</script>

<style lang="scss" scoped>
.router-view {
	// min-height: 80vh;
}
</style>
