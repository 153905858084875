import sellerAPI from '@/services/api/seller'

const sellers = {
	namespaced: true,
	state: {
		sellerList: {},
		popularSellerList: [],
		seller: {},
	},
	getters: {
		getSellerList: state => state.sellerList,
		getPopularSellerList: state => state.popularSellerList,
	},
	mutations: {
		setSellerList: (state, payload) => {
			state.sellerList = payload
		},
		setPopularSellerList: (state, payload) => {
			state.popularSellerList = payload
		},
		setSeller: (state, payload) => {
			state.seller = payload
		},
	},
	actions: {
		lookupSellerList: async ({ commit }) => {
			const response = await sellerAPI.lookupSellerList()
			commit('setSellerList', response.data)
		},
		lookupPopularSellerList: async ({ commit }, params) => {
			const { offset, length } = params
			const response = await sellerAPI.lookupPopularSellerList(offset, length)
			commit('setPopularSellerList', response.data)
		},
		lookupSeller: async ({ commit }, id) => {
			const response = await sellerAPI.lookupSeller(id)
			commit('setSeller', response.data)
		},
	},
}

export default sellers
