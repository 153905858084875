<template>
	<tr class="table-item" :class="mode">
		<td v-if="mode.length > 0" :class="{ check: allCheck }">
			<input type="checkbox" class="checkbox" v-model="checked" :disabled="disabled" />
		</td>
		<td v-for="(header, index) in headers" :key="`table-item_${index}`" :class="header.value" @click="mode ? '' : goProductDetail()">
			<div v-if="header.value === 'option'">
				<b>{{ item.sku }}</b>
				<p>{{ options }}</p>
			</div>
			<div v-else-if="header.value == 'stock'">
				<b>{{ item.stock ? `${item.stock}개` : '-' }}</b>
			</div>
			<div v-else-if="header.value == 'shipping'">
				<b>무료배송</b>
				<div v-if="productInfo.shippingDayPolicy === 'DEFAULT' && productInfo.shippingDay">
					<p class="sub-text">약 {{ productInfo.shippingDay }}일 이내 출고예정</p>
				</div>
				<div v-else-if="productInfo.shippingDayPolicy === 'SHAK'">
					<p class="sub-text">{{ productInfo.shippingDay === 0 ? '지금 주문하면 오늘 도착!' : '지금 주문하면 내일 오전 도착!' }}</p>
				</div>
				<!-- @@TODO: pna 작업 후 개발 필요 -->
				<!-- <div v-else-if="productInfo.shippingDayPolicy === 'SIGMA'">
				</div> -->
				<div v-else>
					<p class="sub-text">판매자 문의 필요</p>
				</div>
			</div>
			<div v-else-if="header.value === 'quantity'">
				<count-input :optionCount="item.quantity" :stock="item.stock" :type="countType" @change="changeCount" />
			</div>
			<div v-else-if="header.value == 'price'">
				<p :class="{ lowest: lowest == item.sellingUnitPrice }">{{ CommaNum(item.sellingUnitPrice) }}{{ '원' }}</p>
			</div>
			<div v-else-if="header.value == 'name'">{{ item.name }}</div>
			<div v-else-if="header.value == 'unitPrice'">
				<b>{{ CommaNum(item.sellingUnitPrice) }}{{ '원' }}</b>
			</div>
			<div v-else-if="header.value == 'quantity'">
				<count-input :optionCount="item.quantity" :stock="item.stock" :type="countType" @change="changeCount" />
			</div>
			<div v-else-if="header.value == 'productPrice'">
				<h5>{{ CommaNum(item.price * item.quantity) }}{{ '원' }}</h5>
				<p v-if="item.discountPrice">{{ CommaNum(item.discountPrice * item.quantity) }}원</p>
			</div>
			<div v-else-if="header.value == 'close'">
				<button class="delete" @click="removeItem">
					<img src="@/assets/svg/delete-cart.svg" />
				</button>
			</div>

			<!-- seller table -->
			<div v-else-if="header.value == 'seller'">
				<div class="imgbox">
					<img :src="item.seller.image" />
				</div>
			</div>
			<div v-if="header.value === 'sellerOption'">
				<p>{{ options }}</p>
			</div>
			<div v-else-if="header.value == 'sku'">
				{{ item.sku }}
			</div>
			<div v-else-if="header.value == 'sellerStock'">
				<b>{{ item.stock ? `${item.stock}개` : '-' }}</b>
			</div>
			<div :class="{ lowest: lowest == item.price }" v-else-if="header.value === 'sellerPrice'">
				<p>{{ CommaNum(item.price) }}{{ '원' }}</p>
			</div>
			<div v-else-if="header.value == 'sellerShipping'">
				<img v-if="item.shippingDayPolicy === 'SHAK'" src="@/assets/svg/main/express.svg" />
				<b>무료배송</b>
				<div v-if="item.shippingDayPolicy === 'SHAK'" class="sack-info">
					<img src="@/assets/svg/icon_watch.svg" />
					<p class="sack-text">{{ item.shippingDay === 0 ? '오늘 도착' : '내일 도착' }}</p>
				</div>
				<p v-else-if="item.shippingDay" class="sup-text">약 {{ item.shippingDay }}일 이내 출고예정</p>
			</div>
		</td>
	</tr>
</template>

<script>
import mixin from '@/mixins'
import PageName from '@/utils/define/PageName'
import CountInput from '@/components/CountInput'
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'SellerTableItem',
	components: {
		CountInput,
	},
	mixins: [mixin],
	props: {
		headers: {
			type: Array,
			default: () => [],
		},
		item: {
			type: Object,
			default: () => {},
		},
		lowest: {
			type: Number,
			default: 0,
		},
		quick: {
			type: Number,
			default: 0,
		},
		allCheck: {
			type: Boolean,
			default: false,
		},
		mode: {
			type: String,
			default: '',
		},
		index: {
			type: Number,
			default: 0,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		selectedList: {
			type: Array,
			default: () => [],
		},
		countType: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			seller: {
				thumbnail: 'https://sjack-lab-manager.s3.ap-northeast-2.amazonaws.com/store/assets/seller_image/SjLogo_1024x110.png',
				name: '스마트잭',
			},
			checked: false,
			quantity: 0,
		}
	},
	computed: {
		...mapGetters('productInfo', ['shippingStatus']),
		...mapState('productInfo', ['productInfo']),
		categoryValue() {
			let str = ''
			this.item.category.parents.forEach(item => {
				str += item.korName.concat('/')
			})
			str = str.concat(this.item.category.korName)
			return str
		},
		options() {
			let result = ''
			this.item.options.forEach((option, index) => {
				result += `${index > 0 ? ' | ' : ''}${option.name}: ${option.value}`
			})
			return result
		},
	},
	watch: {
		checked(val) {
			this.$emit('selected', val, this.index, this.item, this.quantity)
		},
		allCheck(val) {
			this.checked = this.allCheck
		},
		item() {
			this.checked = this.item.checked ?? false
		},
	},
	methods: {
		getValueByKey(item, key) {
			return item.options.find(option => option.key === key).value
		},
		isShippingPrice(price) {
			if (price > 0) {
				return `${this.CommaNum(price)}원`
			} else {
				return '무료'
			}
		},
		isShipDate(date) {
			if (date) {
				return `${this.formatDate(date)} 도착 예정`
			} else {
				return '도착 예정 정보 없음'
			}
		},
		goProductDetail() {
			this.$router.push({
				name: PageName.Detail,
				params: {
					category: this.$route.params.category,
					productInfoId: this.item.productInfoId,
				},
				query: {
					productId: this.item.productId,
				},
			})
		},
		changeCount(val) {
			if (val === 0) this.checked = false
			else this.checked = true

			this.quantity = val
			this.$emit('changeCount', this.item.id, val)
		},
		removeItem() {
			this.$emit('remove', this.item.id)
		},
		getItem() {
			return this.item
		},
	},
}
</script>

<style lang="scss" scoped>
.table-item {
	border-bottom: 1px solid $LINE_DIVIDER;
	cursor: pointer;
	.sub-text {
		font-weight: 400;
		font-size: 12px;
		color: #666;
	}
	&.detail {
		height: 80px;
		td {
			&:first-child {
				padding-left: 24px;
				.checkbox {
					margin-top: 4px;
				}
			}
		}
	}
	&.detailheader {
		height: 80px;
		td {
			&:first-child {
				padding-left: 24px;
				.checkbox {
					margin-top: 4px;
				}
			}
		}
	}
	&.cart {
		td {
			padding: 20px 8px;
			position: relative;
			cursor: default;
			&:first-child {
				padding-left: 22px;
				&:after {
					display: none;
				}
				.checkbox {
					margin-top: 4px;
				}
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
			&:after {
				width: 1px;
				height: 90px;
				background-color: $LINE_DIVIDER;
				content: '';
				right: 0px;
				top: 50%;
				transform: translateY(-50%);
				position: absolute;
			}
			&.count {
				div {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			&.productPrice {
				div {
					text-align: center;
				}
			}
		}
	}
	td {
		min-width: 15%;
		padding: 10px 8px;
		&:first-child {
			padding-left: 28px;
		}
		&.sellerPrice {
			font-size: 14px;
		}
		&.sellerShipping {
			font-size: 14px;
			p {
				font-size: 12px;
			}
			img {
				margin-right: 10px;
			}
			.sack-info {
				display: flex;
				.sack-text {
					font-weight: 700;
					font-size: 12px;
					color: $PRIMARY_RED;
				}
			}
		}
		&.quantity {
			width: 100px;
		}
		&.price {
			width: 120px;
		}
		&.shipping {
			width: 200px;
		}
		&.seller {
			width: 150px;
			div {
				display: inline-flex;
				align-items: center;
			}
			.imgbox {
				width: 120px;
				height: 40px;
				border-radius: 4px;
				border: 1px solid $LINE_DIVIDER;
				overflow: hidden;
				padding: 0 10px;
				margin-right: 10px;
				img {
					width: 100%;
					height: inherit;
					object-fit: contain;
				}
			}
		}
		&.sku {
			font-size: 14px;
		}
		&.stock {
			width: 120px;
			div {
				display: block;
				b {
					font-size: 0.875rem;
					font-weight: 700;
					margin-bottom: 2px;
				}
				p {
					height: 20px;
					font-size: 0.75rem;
					font-weight: 400;
				}
			}
		}
		&.unitPrice {
			span {
				font-size: 0.875rem;
				display: block;
				color: $COLOR_999;
				text-decoration: line-through;
			}
		}
		&.productPrice {
			div {
				h5 {
					font-size: 1rem;
					font-weight: 700;
				}
				p {
					font-weight: 400;
					font-size: 0.875rem;
					color: $COLOR_999;
					text-decoration: line-through;
				}
			}
		}
		&.close {
			div {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		&.productInfo {
			> div {
				display: flex;
				align-items: center;
				position: relative;

				.imgbox {
					width: 80px;
					height: 80px;
					border-radius: 4px;
					border: 1px solid $LINE_DIVIDER;
					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
				.txtbox {
					margin-left: 16px;
					h5 {
						font-size: 1rem;
						font-weight: 700;
						margin-bottom: 2px;
					}
					p {
						font-size: 0.75rem;
						font-weight: 400;
						color: $COLOR_666;
						span {
							font-size: inherit;
							font-weight: inherit;
							color: inherit;
							display: inline-block;
							padding-right: 12px;
							position: relative;
							&:after {
								width: 1px;
								height: 10px;
								background-color: $COLOR_DDD;
								top: 4px;
								right: 5px;
								content: '';
								position: absolute;
							}
							&:last-child {
								&:after {
									display: none;
								}
							}
						}
					}
					.flex {
						display: flex;
						align-items: center;
						margin-top: 10px;
						b {
							font-size: 0.875rem;
							font-weight: 500;
							margin-right: 4px;
							position: relative;
							padding-right: 12px;
							&:after {
								width: 1px;
								height: 12px;
								top: 5px;
								right: 4px;
								content: '';
								position: absolute;
								background-color: $COLOR_DDD;
							}
							&:last-child {
								&:after {
									display: none;
								}
								// padding-left: 12px;
								// &:after {
								// 	width: 1px;
								// 	height: 12px;
								// 	top: 5px;
								// 	left: 4px;
								// 	content: '';
								// 	position: absolute;
								// 	background-color: $COLOR_DDD;
								// }
							}
						}
						p {
							font-size: 0.875rem;
							font-weight: 400;
							color: $COLOR_999;
							text-decoration: line-through;
						}
						img {
							margin-right: 3px;
						}
					}
				}
			}
		}
		p {
			font-size: 0.875rem;
			font-weight: 400;
			&.quick {
				padding-left: 17px;
				background-image: url(~@/assets/svg/time-red.svg);
				background-repeat: no-repeat;
				background-position: center left 0;
				color: $PRIMARY_RED;
				font-weight: bold !important;
			}
			&.noDate {
				color: $COLOR_999;
			}
		}
		span {
			font-size: 10px;
			font-weight: 400;
			display: block;
			color: #333;
		}
	}
	.lowest {
		padding-left: 14px;
		background-image: url(~@/assets/svg/low-price.svg);
		background-repeat: no-repeat;
		background-position: center left 0;
		background-size: 12px;
		color: $PRIMARY_RED;
		font-weight: 700;
	}
}
</style>
