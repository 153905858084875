<template>
	<div class="member-management-wrapper">
		<div class="member-management-title">멤버 관리({{ count }}명)</div>
		<div class="member-management-search">
			<div>
				<search-box keywordPlaceholder="이름, 연락처로 검색" @search="onSearch" />
			</div>
			<div v-if="!isPerson" class="btn-area">
				<button class="btn btn-gray sm" @click="onClickInviteMember">
					<img src="@/assets/svg/invite.svg" />
					멤버 초대
				</button>
			</div>
		</div>
		<div class="member-management-table">
			<table>
				<colgroup>
					<col width="auto" />
					<col width="30%" />
				</colgroup>
				<thead>
					<tr>
						<th>멤버 정보</th>
						<th>연락처</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="member of members" :key="member.id">
						<td>
							<div class="info-col">
								<img :src="member.profileImageUrl ? member.profileImageUrl : require('@/assets/svg/empty-profile.svg')" />
								<div>
									<p>{{ member.name }}</p>
									<span>{{ member.email }}</span>
								</div>
							</div>
						</td>
						<td>{{ convertTel(member.phone) }}</td>
					</tr>
				</tbody>
			</table>
			<common-pagenation v-show="count" ref="CommonPagenation" :total="count" :isPerPage="false" @page="getCurrentPage"></common-pagenation>
		</div>

		<modal-invite-member />
		<modal-member-info-edit :item="updateMember" @success="fetchMembers" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import SearchBox from '@/components/search/SearchBox.vue'
import CommonPagenation from '@/components/pagenation/CommonPagenation.vue'
import ModalInviteMember from '@/views/my-page/my-info/lab/modal/ModalInviteMember.vue'
import ModalMemberInfoEdit from '@/views/my-page/my-info/lab/modal/ModalMemberInfoEdit.vue'
import ModalCancel from '@/components/modal/ModalCancel'

export default {
	name: 'MemberManagement',
	components: {
		SearchBox,
		CommonPagenation,
		ModalInviteMember,
		ModalMemberInfoEdit,
		ModalCancel,
	},
	data() {
		return {
			offset: 0,
			length: 20,
			selectedMembers: [],
			updateMember: {},
			keyword: '',
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('user', ['institute']),
		...mapState('member', ['members', 'count']),
		isPerson() {
			return this.institute.type?.code === 'PERSON'
		},
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				await this.fetchMembers()
				if (this.$refs.CommonPagenation) this.$refs.CommonPagenation.changeLength(this.length)
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('member', ['lookupMemberList']),
		async fetchMembers() {
			await this.lookupMemberList({
				instituteId: this.instituteId,
				params: {
					offset: this.offset,
					length: this.length,
					keyword: this.keyword,
				},
			})
		},
		onSearch(keyword) {
			this.keyword = keyword
			this.fetchMembers()
		},
		onChangeCheckboxAll(e) {
			const changeEvent = new Event('change')
			const allCheckboxEl = document.querySelectorAll('.select-member')
			allCheckboxEl.forEach(el => {
				el.checked = e.target.checked
				el.dispatchEvent(changeEvent)
			})
		},
		onChangeSelectMember(member, e) {
			if (e.target.checked) {
				this.selectedMembers.push(member)
			} else {
				const memberIndex = this.selectedMembers.findIndex(data => data.id === member.id)
				this.selectedMembers.splice(memberIndex, 1)
			}
		},
		onClickInviteMember() {
			this.$bvModal.show('ModalInviteMember')
		},
		onClickMoreBtn(member) {
			this.updateMember = this.$_.cloneDeep(member)
			this.$bvModal.show('ModalMemberInfoEdit')
		},
		getCurrentPage({ offset, length }) {
			this.offset = offset
			this.length = length
			this.fetchMembers()
		},
		convertTel(value) {
			return value.replace(/\D+/g, '').replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3')
		},
	},
}
</script>

<style lang="scss">
.member-management-wrapper .member-management-table .Table__pagination {
	border-top: none;
}
</style>

<style lang="scss" scoped>
.member-management-wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	.member-management-title {
		font-size: 1.25rem;
		font-weight: 700;
	}
	.member-management-search {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.btn-area {
			display: flex;
			gap: 10px;
			.btn {
				display: flex;
				align-items: center;
			}
		}
	}
	.member-management-table {
		display: flex;
		flex-direction: column;
		gap: 24px;
		table {
			width: 100%;
			border-collapse: separate;
			border-spacing: 0;
			border: 1px solid $LINE_DIVIDER;
			border-radius: 5px;
			thead {
				tr {
					height: 40px;
					background: $LIST_BACKGROUND;
					th {
						font-size: 0.875rem;
						font-weight: 700;
						border-bottom: 1px solid $LINE_DIVIDER;
						padding: 0 24px;
					}
				}
			}
			tbody {
				tr {
					height: 70px;
					&:not(:last-child) {
						td {
							border-bottom: 1px solid $LINE_DIVIDER;
						}
					}
					td {
						font-size: 0.875rem;
						padding: 0 24px;
						.info-col {
							display: flex;
							align-items: center;
							gap: 10px;
							img {
								width: 40px;
								height: 40px;
								border-radius: 8px;
							}
							> div {
								display: flex;
								flex-direction: column;
								gap: 2px;
								p {
									font-size: 1rem;
									font-weight: 700;
								}
								span {
									font-size: 0.75rem;
									color: $COLOR_999;
								}
							}
						}
						.more {
							cursor: pointer;
						}
					}
				}
			}
		}
		.center {
			text-align: center;
		}
		.checkbox {
			vertical-align: middle;
		}
	}
}
</style>
