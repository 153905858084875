<template>
	<b-modal
		ref="modal"
		id="ModalSadadreamRequest"
		centered
		title="상품 요청하기"
		cancel-title="취소"
		ok-title="완료"
		ok-variant="warning"
		button-size="lg"
		@ok="complete"
	>
		<div class="modal-wrapper">
			<div class="top-contents">
				<div>
					<input-form-item
						v-model="name"
						direction="column"
						title="상품명"
						placeholder="상품명 입력"
						style="padding: 0; height: 91px"
						:inputStyle="inputStyle"
						:error="!valid.name"
						:errorMessage="errorMessageName"
						required
						@input="valid.name = true"
					/>
					<input-form-item
						v-model="productCode"
						direction="column"
						title="제품번호"
						placeholder="제품번호 입력"
						:inputStyle="inputStyle"
						style="padding: 0; height: 91px"
					/>
					<input-form-item
						v-model="volume"
						direction="column"
						title="사이즈(용량)"
						placeholder="사이즈(용량) 입력"
						style="padding: 0; height: 91px"
						:inputStyle="inputStyle"
						:error="!valid.volume"
						:errorMessage="errorMessageSize"
						required
						@input="valid.volume = true"
					/>
				</div>
				<div style="position: relative">
					<input-form-item
						v-model="brandName"
						direction="column"
						title="제조사(브랜드)"
						placeholder="제조사(브랜드) 입력"
						:inputStyle="inputStyle"
						style="padding: 0; height: 91px"
					/>
					<input-form-item
						v-model="casno"
						direction="column"
						title="CAS No"
						placeholder="CAS No 입력"
						:inputStyle="inputStyle"
						style="padding: 0; height: 91px"
					/>
					<p>수량</p>
					<input v-model="quantity" type="number" />
					<img src="@/assets/svg/icon_minus.svg" @click="quantity -= 1" />
					<img src="@/assets/svg/icon_plus.svg" @click="quantity += 1" />
				</div>
			</div>
			<div class="bottom-contents">
				<p>요청사항</p>
				<textarea
					v-model="comment"
					:placeholder="`추가 요청사항을 입력해주세요.\nEx) 제품 희망 견적가, 명확한 제품 소싱을 위한 추가 정보 등`"
				/>
				<p>이미지</p>
				<div class="image-wrapper">
					<img src="@/assets/svg/image_add_btn.svg" @click="onClickAddImage" />
					<image-box
						v-for="(image, index) in attachments"
						:key="`imageBox_${index}`"
						:image="getImageUrl(image)"
						:index="index"
						@deleteImage="deleteImage"
					/>
					<input ref="image-file" multiple type="file" accept=".jpg, .jpeg, .png" @change="uploadImage" hidden />
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import InputFormItem from '@/components/form-item/InputFormItem.vue'
import CountInput from '@/components/CountInput'
import ImageBox from '@/views/search/components/ImageBox.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'ModalSadadreamRequest',
	components: {
		InputFormItem,
		CountInput,
		ImageBox,
	},
	data() {
		return {
			inputStyle: {
				width: '300px',
				height: '30px',
			},
			name: '',
			brandName: '',
			productCode: '',
			casno: '',
			volume: '',
			comment: '',
			quantity: 1,
			attachments: [],
			valid: {
				name: true,
				volume: true,
			},
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		errorMessageName() {
			return '상품명을 입력해주세요'
		},
		errorMessageSize() {
			return '사이즈(용량)을 입력해주세요'
		},
	},
	watch: {
		quantity() {
			if (this.quantity === 0) this.quantity = 1
		},
	},
	methods: {
		...mapActions('requestProduct', ['addRequestProduct']),
		show(product = null) {
			this.$refs['modal'].show()
			this.initModal(product)
		},
		hide() {
			this.$refs['modal'].hide()
		},
		initModal(product = null) {
			this.name = product?.name ?? ''
			this.brandName = product?.brand?.name ?? ''
			this.productCode = product?.code ?? ''
			this.casno = product?.casno ?? ''
			this.volume = product?.options[0]?.value ?? ''
			this.comment = ''
			this.quantity = product?.quantity ?? 1
			this.attachments = []
			this.valid = {
				name: true,
				volume: true,
			}
		},
		async complete(e) {
			e.preventDefault()

			if (!this.name) this.valid.name = false
			if (!this.volume) this.valid.volume = false

			if (this.valid.name && this.valid.volume && this.quantity > 0) {
				try {
					const formData = new FormData()
					formData.append('name', this.name)
					formData.append('volume', this.volume)
					formData.append('quantity', this.quantity)
					formData.append('brandName', this.brandName)
					formData.append('productCode', this.productCode)
					formData.append('casno', this.casno)
					formData.append('comment', this.comment)
					this.attachments.forEach(item => {
						formData.append('attachments', item)
					})

					await this.addRequestProduct({
						instituteId: this.instituteId,
						formData,
					})
					this.$emit('success')
					this.$refs.modal.hide()
				} catch (e) {
					this.$root.toast('요청 실패', e.response.data.msg, 'error')
				}
			}
		},
		onClickAddImage() {
			this.$refs['image-file'].click()
		},
		uploadImage(e) {
			const images = e.target.files
			if (this.attachments.length + images.length > 5) {
				this.$root.toast('이미지 추가', '이미지는 최대 5장까지 첨부 할 수 있습니다.', 'error')
			} else {
				this.attachments = [...this.attachments, ...images]
			}
			this.$refs['image-file'].value = ''
		},
		getImageUrl(image) {
			return URL.createObjectURL(image)
		},
		deleteImage(index) {
			this.attachments.splice(index, 1)
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.modal-dialog {
		max-width: 700px;
	}
	.modal-body {
		background: $LIST_BACKGROUND;
	}
	.modal-header {
		background: $LIST_BACKGROUND;
	}
	.modal-footer {
		background: $LIST_BACKGROUND;
		justify-content: right;
		padding-top: 0;
		.btn {
			width: 100px;
			height: 30px;
			background: $PRIMARY_YELLOW;
			&:first-child {
				background: #fff;
				border: solid 1px $LINE_DIVIDER;
				color: $PRIMARY_BLACK;
			}
			&.disabled {
				background: $LIST_BACKGROUND;
				color: $COLOR_999;
			}
		}
	}
}
.modal-wrapper {
	padding: 10px 20px 30px 20px;
	background: $PRIMARY_WHITE;
	border: 1px solid #eeeeee;
	border-radius: 8px;
	width: 660px;
	.top-contents {
		display: flex;
		justify-content: space-between;
		p {
			font-weight: 500;
			line-height: 40px;
		}
		img {
			cursor: pointer;
			position: absolute;
			top: 227px;
			right: 30px;
			&:last-child {
				right: 7px;
			}
		}
		input {
			border: 1px solid #eee;
			border-radius: 5px;
			width: 100%;
			height: 30px;
			padding: 10px;
			font-size: 14px;
		}
	}
	.bottom-contents {
		p {
			font-weight: 500;
			line-height: 40px;
		}
		textarea {
			width: 100%;
			height: 100px;
			font-size: 14px;
			border: 1px solid #eee;
			border-radius: 5px;
			padding: 10px 15px;
			resize: none;
		}
		img {
			cursor: pointer;
		}
		.image-wrapper {
			display: flex;
			gap: 15px;
		}
	}
}
.text-area {
	height: 40px;
	margin-top: 20px;
	background: $PRIMARY_WHITE;
	border: 1px solid #eeeeee;
	border-radius: 8px;
	font-weight: 400;
	font-size: 14px;
	color: $PRIMARY_RED;
	text-align: center;
	padding-top: 8px;
}
</style>
