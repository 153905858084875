<template>
	<div class="search-row">
		<div class="title">{{ title }}</div>
		<div class="content" :style="contentStyle">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SearchRow',
	props: {
		title: {
			type: String,
			default: '',
		},
		contentStyle: {
			type: Object,
			default: () => {},
		},
	},
}
</script>

<style lang="scss" scoped>
.search-row {
	display: flex;
	align-items: stretch;
	max-width: 100%;
	min-height: 50px;
	border: 1px solid $COLOR_EEE;
	margin: 0;
	.title {
		width: 200px;
		padding: 15px 12px;
		background: $LIST_BACKGROUND;
		border-right: 1px solid $COLOR_EEE;
		font-weight: bold;
		font-size: 0.875rem;
		color: $COLOR_666;
	}
	.content {
		display: flex;
		align-items: center;
		width: calc(100% - 200px);
		padding: 0 20px;
		font-size: 0.875rem;
	}
}
</style>
