<template>
	<base-management title="찜 목록" subTitleName="찜 목록" subTitleContent="마음에 드는 상품 / 브랜드 / 판매자를 찜한 목록입니다">
		<template v-if="getWishBrandList.length || getWishSellerList.length || count">
			<div class="wish-wrapper">
				<brand-list
					v-if="getWishBrandList.length"
					class="boxwrap"
					title="찜한 브랜드"
					buttonText="브랜드 전체보기"
					:list="getWishBrandList"
					type="brand"
					@showAll="goPage('brand')"
				/>
				<brand-list
					v-if="getWishSellerList.length"
					class="boxwrap"
					title="찜한 판매자"
					buttonText="판매자 전체보기"
					:list="getWishSellerList"
					type="seller"
					@showAll="goPage('seller')"
				/>
				<product-list
					v-if="count"
					class="boxwrap product-list"
					title="찜한 상품"
					:list="catalogList"
					:isShowProductList="isShowProductList"
					@changeWishValue="loadList"
				/>
				<loading v-if="isLoading" keyword="찜한 상품 불러오는 중" />
			</div>
		</template>
		<template v-else>
			<div class="empty-list">
				<img src="@/assets/svg/empty-wish.svg" />
				<p>아직 찜한 상품/브랜드/판매자가 없습니다</p>
			</div>
		</template>
	</base-management>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PageName from '@/utils/define/PageName'
import BaseManagement from '@/views/my-page/components/BaseManagement.vue'
import BrandList from '@/components/list/BrandList.vue'
import ProductList from '@/components/list/ProductList.vue'
import MixinScrolling from '@/mixins/scrolling'
import Loading from '@/components/Loading.vue'

export default {
	name: 'Wish',
	components: {
		BaseManagement,
		BrandList,
		ProductList,
		Loading,
	},
	mixins: [MixinScrolling],
	data() {
		return {
			offset: 0,
			length: 10,
			wishBrandList: [],
			wishSellerList: [],
			reloadComplete: false,
			isLoading: true,
		}
	},
	computed: {
		...mapState('wish', ['count']),
		...mapGetters('wish', ['getWishBrandList', 'getWishSellerList', 'getWishCatalogList']),
		catalogList() {
			return this.getWishCatalogList
		},
		isShowProductList() {
			return this.reloadComplete
		},
	},
	watch: {
		async isBottom(val) {
			if (val === true) {
				const offset = this.offset + this.length
				if (this.count < offset) return

				this.offset = offset
				const params = {
					offset: this.offset,
					length: this.length,
				}
				await this.lookupWishCatalogList(params)
				if (this.getWishCatalogList.length > 0) {
					this.catalogList = [...this.catalogList, ...this.getWishCatalogList]
				}
			}
		},
	},
	created() {
		this.loadList()
	},
	methods: {
		...mapActions('wish', ['lookupWishBrandList', 'lookupWishSellerList', 'lookupWishCatalogList']),
		async loadList() {
			this.isLoading = true
			this.reloadComplete = false
			const params = {
				offset: this.offset,
				length: this.length,
			}
			await this.lookupWishBrandList()
			await this.lookupWishSellerList()
			await this.lookupWishCatalogList(params)
			this.isLoading = false
			this.reloadComplete = true
		},
		goPage(category) {
			this.$router.push({
				name: PageName.List,
				params: { category: category },
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.wish-wrapper {
	display: flex;
	flex-direction: column;
	gap: 50px;
	.product-list {
		margin-bottom: 64px;
	}
}
.empty-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	gap: 24px;
	margin-top: 64px;
	> p {
		font-size: 1rem;
		font-weight: 500;
		color: $COLOR_999;
	}
}
</style>
