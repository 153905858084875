<template>
	<div class="productInfo-header-wrapper">
		<div class="product-wrapper wrapper">
			<img class="thumbnail" :src="thumbnail" />
			<div class="product-info">
				<div class="name">{{ productInfo.name }}</div>
				<div class="price">{{ CommaNum(minPrice) }}원 ~</div>
			</div>
			<button class="btn btn-primary lg btn-purchase order" v-if="!isOptionTableToggle" @click="isOptionTableToggle = true">주문하기</button>
			<button class="btn btn-close" v-else @click="isOptionTableToggle = false"></button>
		</div>
		<div class="product-select-table wrapper" :class="{ active: isOptionTableToggle }">
			<product-select-table
				mode="detail"
				:headerList="headerList"
				:list="list"
				countType="product"
				@changeCount="changeCount"
				@changeSelected="changeSelected"
				@draftWriting="$emit('draftWriting')"
				@order="$emit('order')"
				@showModalCancel="(title, content) => this.$emit('showModalCancel', title, content)"
			/>
		</div>
		<div class="button-wrapper">
			<div class="productInfo-header-item" :class="{ active: isProductTab }" @click="goScroll('product-tab')">
				<div>상품 정보</div>
			</div>
			<div class="productInfo-header-item" :class="{ active: isDeliveryTab }" @click="goScroll('delivery-tab')">
				<div>배송 정보</div>
			</div>
			<div class="productInfo-header-item" :class="{ active: isReturnTab }" @click="goScroll('return-tab')">
				<div>취소/반품/교환 정보</div>
			</div>
			<div class="productInfo-header-item" :class="{ active: isCsTab }" @click="goScroll('cs-tab')">
				<div>고객상담 정보</div>
			</div>
		</div>
	</div>
</template>

<script>
import ProductSelectTable from './ProductSelectTable'
import mixin from '@/mixins'
import { mapGetters, mapState } from 'vuex'

export default {
	mixins: [mixin],
	props: {
		activeProductInfoNav: {
			default: 'product-tab',
			type: String,
		},
		list: {
			type: Array,
			default: () => [],
		},
		headerList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isOptionTableToggle: false,
		}
	},
	computed: {
		...mapState('productInfo', ['productInfo']),
		...mapGetters('productInfo', ['thumbnails', 'minPrice']),
		isProductTab() {
			return this.activeProductInfoNav == 'product-tab'
		},
		isDeliveryTab() {
			return this.activeProductInfoNav == 'delivery-tab'
		},
		isReturnTab() {
			return this.activeProductInfoNav == 'return-tab'
		},
		isCsTab() {
			return this.activeProductInfoNav == 'cs-tab'
		},
		thumbnail() {
			return this.thumbnails.length > 0 ? this.thumbnails[0] : null
		},
	},
	components: {
		ProductSelectTable,
	},
	methods: {
		goScroll(href) {
			this.$emit('goScroll', href)
		},
		changeCount(id, quantity) {
			this.$emit('changeCount', id, quantity)
		},
		changeSelected(id, isSelect) {
			this.$emit('changeSelected', id, isSelect)
		},
	},
}
</script>

<style lang="scss" scoped>
.productInfo-header-wrapper {
	background: white;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	border-bottom: 1px solid #191414;
	z-index: 100;
	align-items: end;
	.product-wrapper {
		display: flex;
		align-items: center;
		height: 100px;
		.thumbnail {
			width: 60px;
			height: 60px;
			border: 1px solid #ddd;
			border-radius: 5px;
		}
		.product-info {
			margin-left: 16px;
			.name {
				font-size: 16px;
				font-weight: 700;
				color: black;
				line-height: 24px;
			}
			.price {
				font-size: 24px;
				font-weight: 700;
				color: #ff5746;
				line-height: 36px;
			}
			.order {
				background-color: $PRIMARY_YELLOW !important;
				color: black;
			}
		}
		.btn-purchase,
		.btn-close {
			margin-left: auto;
		}
		.btn-close {
			width: 30px;
			height: 30px;
			background: url(~@/assets/svg/close-lg.svg);
			background-repeat: no-repeat;
			background-position: center;
		}
	}
	.product-select-table {
		opacity: 0;
		height: 0;
		visibility: hidden;
		&.active {
			opacity: 1;
			height: 100%;
			visibility: visible;
		}
		& > .button-wrapper {
			margin-top: 20px;
			margin-bottom: 20px;
			display: flex;
			justify-content: flex-end;
			& > * {
				margin-left: 10px;
			}
		}
	}
	& > .button-wrapper {
		display: flex;
		justify-content: center;
		.productInfo-header-item {
			display: flex;
			width: 270px;
			height: 48px;
			font-size: 16px;
			font-weight: 700;
			color: rgba(0, 0, 0, 0.6);
			background-color: #fafafc;
			align-items: center;
			cursor: pointer;
			div {
				width: 100%;
				height: 24px;
				text-align: center;
				border-right: 1px solid #ddd;
			}
			&:last-of-type > div {
				border-right: 0px;
			}
			&.active {
				background: #191914;
				color: white;
				& > div {
					border-right: 0px;
				}
			}
		}
	}
}
</style>
