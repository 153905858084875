<template>
	<div class="productInfo-important-wrapper">
		<div class="seller-info">
			{{ productInfo.seller.name }}
			<img src="@/assets/svg/icon-house.svg" />
		</div>
		<div class="productInfo-name">
			{{ productInfo.name }}
		</div>
		<div class="productInfo-subname">
			{{ productInfo.subname }}
		</div>
		<div class="productInfo-price">
			<span v-if="productInfo.discountPercent" class="discount">{{ roundNum(productInfo.discountPercent) }}%</span>
			{{ CommaNum(productInfo.minSellingUnitPrice) }}
			<span>원</span>
			<span v-if="productOptions.length > 1">~</span>
			<span v-if="productInfo.discountPercent" class="original-price">{{ CommaNum(productInfo.minUnitPrice) }}원</span>
		</div>
		<div class="productInfo-detail">
			<div class="detail-title">상품정보</div>
			<div class="detail-content">
				<p>
					카테고리 :
					{{ `${category.korName}${category.parents && category.parents.length > 0 ? '/' + category.parents[0].korName : ''}` }}
				</p>
				<p>제품번호 : {{ isData(productInfo.code) }}</p>
				<p>제조사/브랜드 : {{ isData(brand.name) }}</p>
				<p v-if="productInfo.casno">CAS No : {{ isData(productInfo.casno) }}</p>
			</div>
		</div>
		<div class="productInfo-detail">
			<div class="detail-title">배송정보</div>
			<div class="detail-content">
				<p style="font-weight: 700"><img src="@/assets/svg/icon-won.svg" />무료배송</p>
				<p v-if="productInfo.shippingDayPolicy === 'DEFAULT' && productInfo.shippingDay">
					<img src="@/assets/svg/icon-truck.svg" /><b>{{ productInfo.shippingDay }}일 이내</b> 출고 예정
				</p>
				<p v-else-if="productInfo.shippingDayPolicy === 'SHAK'">
					<img style="margin-right: 7px" src="@/assets/svg/icon-thunder-truck.svg" /><img src="@/assets/svg/shackshack-text.svg" />
					{{ productInfo.shippingDay === 0 ? '지금 주문하면 오늘 도착!' : '지금 주문하면 내일 오전 도착!' }}
				</p>
				<!-- @@TODO: pna 작업 후 개발 필요 -->
				<!-- <div v-else-if="productInfo.shippingDayPolicy === 'SIGMA'">
					<p><img src="@/assets/svg/icon-truck.svg" /><b>당일 출고</b> 예정 (Sigma-Aldrich 실시간 제공)</p>
					<p class="caution">
						<img src="@/assets/svg/icon-login-error.svg" />
						출고정보는 제조사 재고상황에 따라 변경될 수 있습니다.
					</p>
				</div> -->
				<p v-else><img src="@/assets/svg/icon-truck.svg" />판매자 문의 필요</p>
				<p v-if="productInfo.shippingDirectContact || productInfo.shippingCanQuick">
					<img src="@/assets/svg/icon-phone2.svg" />
					<span v-if="productInfo.shippingDirectContact">재고 확인 후 <b>개별연락</b></span>
					<span v-if="productInfo.shippingCanQuick">퀵 서비스 가능</span>
				</p>
			</div>
		</div>
		<select-option v-if="productOptions.length > 0" :options="productOptions" :selectedOptions="selectedOptions" @changeList="changeList" />
		<div v-if="productInfo.isBlock" class="person-error-content">
			<div>
				<img src="@/assets/svg/icon/icon-person-error.svg" alt="" />
			</div>
			<div class="text">{{ impossibleOrderContent }}</div>
		</div>
		<div class="productInfo-total-price">
			<div>금액 합계</div>
			<div>{{ CommaNum(allPrice) }}원</div>
		</div>
		<div class="buy-button-wrapper">
			<input :checked="productInfo.isWish" class="wish lg block" type="checkbox" @change="onChangeWish" />
			<button class="btn btn-default btn-cart lg" @click="setCart">장바구니 담기</button>
			<button class="btn btn-primary btn-order lg" @click="order">주문하기</button>
			<div v-if="isShowCartTooltip" class="cart-tooltip">
				<div>
					<p>장바구니<span>에 담겼습니다.</span></p>
					<img src="@/assets/svg/main/icon-close.svg" @click="isShowCartTooltip = false" />
				</div>
				<button class="btn btn-primary btn-view-cart lg" @click="gotoCart">장바구니 보러가기</button>
			</div>
		</div>
	</div>
</template>

<script>
import CountInput from '@/components/CountInput'
import SelectOption from './ProductSelectOption'
import mixin from '@/mixins'
import CartMixin from '@/mixins/cart'
import InstituteMixin from '@/mixins/institute'
import { isLogin, goToSSOMain } from '@/services/api/common'
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import { gaEventCart } from '@/utils/ga'

const BLOCK_REASON = {
	PRODUCT_STOP: 'PRODUCT_STOP',
	PRODUCT_DELETE: 'PRODUCT_DELETE',
	SELLER_STOP: 'SELLER_STOP',
	PERSON_NOT_ALLOW: 'PERSON_NOT_ALLOW',
	INSTITUTE_BLOCK: 'INSTITUTE_BLOCK',
	SHAK_CANT_DELIVERY: 'SHAK_CANT_DELIVERY',
}

export default {
	components: {
		CountInput,
		SelectOption,
	},
	mixins: [mixin, CartMixin, InstituteMixin],
	props: {
		selectedList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			productOptions: [],
			isShowCartTooltip: false,
		}
	},
	computed: {
		...mapState('productInfo', ['productInfo']),
		...mapGetters('productInfo', ['category', 'brand', 'products', 'minPrice', 'shippingStatusHTML']),
		...mapGetters('user', ['instituteId']),
		allCount() {
			return this.selectedList.reduce((acc, cur) => acc + cur.quantity, 0)
		},
		allPrice() {
			return this.selectedList.reduce((acc, cur) => acc + cur.sellingUnitPrice * cur.quantity, 0)
		},
		selectedOptions() {
			return this.selectedList.filter((element, index) => this.selectedList.indexOf(element) === index)
		},
		impossibleOrderContent() {
			let title = ''
			const { blockReason } = this.productInfo
			switch (blockReason) {
				case BLOCK_REASON.PRODUCT_STOP:
				case BLOCK_REASON.PRODUCT_DELETE:
				case BLOCK_REASON.SELLER_STOP:
					title = '판매자에 의해 판매 중지된 상품입니다'
					break
				case BLOCK_REASON.PERSON_NOT_ALLOW:
					title = '유해화학물질 / 시약제품은 개인에게 판매하지 않습니다'
					break
				case BLOCK_REASON.SHAK_CANT_DELIVERY:
					title = '샥샥배송 가능한 지역이 아닙니다'
					break
			}

			return title
		},
	},
	watch: {
		productInfo: {
			handler() {
				this.setProductOptions()
			},
		},
	},
	methods: {
		...mapMutations('productInfo', ['setIsWish']),
		...mapActions('cart', ['addCart']),
		...mapActions('user', ['getInstitute']),
		onChangeWish(e) {
			if (isLogin()) {
				const isWish = e.target.checked
				this.changeWish(this.productInfo.productInfoId, isWish, 'productInfo')
				this.setIsWish(isWish)
			} else {
				goToSSOMain(location.href)
			}
		},
		changeList(list) {
			this.$emit('changeList', list)
		},
		setProductOptions() {
			let list = []
			if (this.products.length > 0) {
				this.products.forEach(item => {
					let object = this.$_.cloneDeep(item)
					// object.quantity = 1

					list.push(object)
				})

				this.productOptions = list
				// const selectOption = this.$route.query.options
				// const findOption = this.productOptions.find(product => JSON.stringify(product.options) === selectOption)
				// if (findOption) this.$emit('initList', [findOption])
				// else if (this.productOptions.length === 1) this.$emit('initList', this.productOptions)
			}
		},
		async setCart() {
			if (this.selectedList.length == 0) {
				this.$emit('showModalCancel', '옵션 선택 없음', '옵션을 선택하지 않았습니다. 옵션을 선택해주세요.')
				return
			}

			try {
				const productList = this.selectedList.map(({ productId, quantity }) => {
					return { productId, quantity }
				})

				if (!isLogin()) {
					this.setNonMemberCartProductList([...this.getNonMemberCartProductList(), ...productList])
				} else {
					await this.addCart({ instituteId: this.instituteId, body: { products: productList } })
					await gaEventCart(this.selectedList)
					await this.getInstitute()
					this.refreshCurrentInstitute()
				}
				this.isShowCartTooltip = true
			} catch (e) {
				console.error(e)
			}
		},
		order() {
			if (this.selectedList.length == 0) {
				this.$emit('showModalCancel', '옵션 선택 없음', '옵션을 선택하지 않았습니다. 옵션을 선택해주세요.')
				return
			}

			this.$emit('order')
		},
		gotoCart() {
			this.$router.push({
				name: 'Cart',
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.productInfo-important-wrapper {
	width: 100%;
	.seller-info {
		font-weight: 500;
		font-size: 16px;
		color: #666;
		img {
			margin-bottom: 3px;
		}
	}
	.productInfo-name {
		font-weight: 700;
		font-size: 24px;
		color: #111;
	}
	.productInfo-subname {
		font-size: 14px;
		font-weight: 400;
		color: #333;
		line-height: 20px;
	}
	.productInfo-price {
		margin-top: 14px;
		font-weight: 700;
		font-size: 28px;
		line-height: 36px;
		.discount {
			font-weight: 700;
			font-size: 28px;
			color: $PRIMARY_RED;
			margin-right: 10px;
		}
		.original-price {
			font-weight: 400;
			font-size: 16px;
			color: #666;
			margin-left: 10px;
			text-decoration: line-through;
		}
		span {
			font-weight: 700;
			font-size: 16px;
		}
	}
	.productInfo-detail {
		display: flex;
		border-top: solid 1px $LINE_DIVIDER;
		margin-top: 20px;
		padding-top: 10px;
		.detail-title {
			font-weight: 700;
			font-size: 14px;
		}
		.detail-content {
			padding-left: 50px;
			p {
				font-weight: 400;
				font-size: 14px;
				padding-bottom: 5px;
			}
			.caution {
				color: $PRIMARY_RED;
				display: flex;
				img {
					margin-right: 10px;
				}
			}
		}
	}
	.productInfo-delivery {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin: 15px 0 30px 0;
		& > * {
			font-size: 14px;
			line-height: 20px;
			vertical-align: top;
			display: inline-block;
		}
		img {
			width: 16px;
			height: 16px;
		}
		.delivery-wrapper {
			display: flex;
			align-items: center;
			gap: 6px;
		}
		.delivery-info {
			margin-left: 8px;
		}
	}
	.productInfo-detail-hr {
		margin-top: 20px;
		margin-bottom: 20px;
		border: 1px solid #ddd;
	}
	.person-error-content {
		height: 48px;
		background-color: rgba(255, 87, 70, 0.1);
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
		.text {
			color: #ff5746;
			font-size: 0.875rem;
			margin-left: 10px;
		}
	}
	.productInfo-total-price {
		display: flex;
		margin-top: 18px;
		font-size: 16px;
		font-weight: 400;
		align-items: center;
		& > *:first-child {
			height: 24px;
			font-weight: 700;
		}
		& > *:last-child {
			margin-left: auto;
			font-size: 28px;
			font-weight: 700;
		}
	}
	.buy-button-wrapper {
		height: 48px;
		margin-top: 30px;
		display: flex;
		align-items: center;
		position: relative;
		.btn-like {
			height: 48px;
			width: 48px;
		}
		.btn-cart,
		.btn-order {
			height: 48px;
			width: 226px;
			margin-left: 10px;
			font-weight: 700;
			font-size: 16px;
		}
		.btn-cart {
			background: #fff;
			border: 1px solid #eeeeee;
			border-radius: 5px;
			&:hover {
				background: #f5f5f5;
			}
		}
		.btn-order {
			background: $PRIMARY_YELLOW;
			&:hover {
				background: #ffb000;
			}
		}
		.cart-tooltip {
			padding: 20px 15px 15px 15px;
			width: 350px;
			height: 113px;
			top: -130px;
			border-radius: 5px;
			border: solid 1px #ddd;
			position: absolute;
			background: $PRIMARY_WHITE;
			div {
				display: flex;
				justify-content: space-between;
				img {
					cursor: pointer;
				}
			}
			p {
				font-weight: 700;
				font-size: 16px;
				span {
					font-weight: 400;
				}
			}
			.btn-view-cart {
				width: 100%;
				height: 40px;
				margin-top: 10px;
				background: $PRIMARY_YELLOW;
				&:hover {
					background: #fec741 linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
					color: black;
				}
			}
		}
		.cart-tooltip:after {
			border-bottom: 0px solid transparent;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 8px solid $PRIMARY_WHITE;
			content: '';
			position: absolute;
			top: 110px;
			left: 160px;
			z-index: 1004;
		}
		.cart-tooltip:before {
			border-bottom: 0px solid transparent;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 8px solid #ddd;
			content: '';
			position: absolute;
			top: 111px;
			left: 160px;
			z-index: 1003;
		}
	}
}
</style>
