<template>
	<div class="estimate-item">
		<div class="estimate-title">
			<span>연결된 견적서</span>
		</div>
		<div class="estimate-table">
			<table>
				<thead>
					<tr>
						<th>&nbsp;</th>
						<th>
							<div>상태</div>
						</th>
						<th>
							<div>견적 만료일</div>
						</th>
						<th><div>작성자</div></th>
						<th><div>견적번호</div></th>
						<th><div>상품정보</div></th>
						<th><div>견적금액</div></th>
						<th><div>담당자</div></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) of list" :key="index">
						<td></td>
						<td>
							<div class="status">{{ item.status }}</div>
						</td>
						<td>{{ item.date }}</td>
						<td>{{ item.writer }}</td>
						<td>{{ item.number }}</td>
						<td>{{ item.info }}</td>
						<td>{{ CommaNum(item.price) }}원</td>
						<td>
							<div class="manager">
								<span>{{ item.manager.name }}</span>
								<p>{{ item.manager.department }}</p>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'

export default {
	name: 'EstimateTable',
	mixins: [mixin],
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
}
</script>

<style lang="scss" scoped>
.estimate-item {
	display: flex;
	flex-direction: column;
	gap: 15px;
	.estimate-title {
		display: flex;
		justify-content: space-between;
		span {
			font-weight: bold;
			font-size: 1.25rem;
		}
		button {
			display: flex;
			align-items: center;
			font-weight: 700;
			font-size: 0.75rem;
		}
	}
	.info-content {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 20px;
		border: 1px solid $COLOR_DDD;
		border-radius: 5px;
		> div {
			display: flex;
			> span {
				font-size: 0.875rem;
				font-weight: 500;
			}
			.title {
				width: 124px;
			}
		}
		.between {
			justify-content: space-between;
			.total {
				font-size: 1rem;
				font-weight: 700;
			}
		}
	}
	.estimate-table {
		table {
			width: 100%;
			border-collapse: separate;
			border-spacing: 0;
			border: 1px solid $COLOR_DDD;
			border-radius: 5px;
			thead {
				tr {
					height: 40px;
					background: $PRIMARY_WHITE;
					th {
						font-size: 0.875rem;
						font-weight: 700;
						color: $COLOR_666;
						border-bottom: 1px solid $COLOR_DDD;
						> div {
							padding-left: 7px;
							border-left: 1px solid $COLOR_DDD;
						}
					}
				}
			}
			tbody {
				tr {
					height: 60px;
					&:not(:last-child) {
						td {
							border-bottom: 1px solid $LINE_DIVIDER;
						}
					}
					td {
						font-size: 0.875rem;
						padding-left: 7px;
						.status {
							padding: 1px 5px;
							background: $COLOR_EEE;
							border-radius: 5px;
							font-weight: 700;
							font-size: 0.75rem;
							text-align: center;
						}
						.manager {
							> span {
								font-weight: 700;
								font-size: 0.875rem;
							}
							> p {
								font-size: 0.75rem;
								color: $COLOR_666;
							}
						}
					}
				}
			}
		}
	}
}
</style>
