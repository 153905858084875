<template>
	<b-modal id="ModalSelectOrder" ref="modal" :title="title" size="lg" centered hide-footer>
		<order-box v-for="(order, index) in list" :key="`order_${index}`" :order="order" @select="$emit('select', $event)" />
		<div v-if="list.length < orderCount" class="btn-wrap">
			<button class="btn btn-lg btn-white" @click="moreList">주문내역 더보기</button>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import OrderBox from '@/components/list/OrderBox'

export default {
	name: 'ModalSelectOrder',
	components: {
		OrderBox,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			offset: 0,
			length: 3,
			list: [],
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('order', ['orders', 'orderCount']),
	},
	watch: {
		instituteId: {
			async handler(newVal) {
				if (!newVal) return

				await this.fetchOrderList()
				this.list = this.$_.cloneDeep(this.orders)
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('order', ['lookupOrderList']),
		async fetchOrderList() {
			await this.lookupOrderList({
				instituteId: this.instituteId,
				params: {
					offset: this.offset,
					length: this.length,
					status: 'ALL',
				},
			})
		},
		async moreList() {
			this.offset = this.offset + this.length

			await this.fetchOrderList()
			this.list = [...this.list, ...this.orders]
		},
	},
}
</script>

<style lang="scss">
#ModalSelectOrder {
	.modal-header {
		.modal-title {
			font-size: 1.5rem;
			font-weight: 700;
		}
	}
	.modal-body {
		max-height: 80vh;
		overflow-y: auto;
		.order-box {
			margin-bottom: 40px;
		}
		.btn-wrap {
			margin-bottom: 20px;
			.btn {
				font-size: 0.875rem;
				width: 400px;
			}
		}
	}
}
</style>
