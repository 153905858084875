<template>
	<b-modal id="ModalDraftWriting" centered hide-header hide-footer ref="modal">
		<div class="msg-wrapper">
			<h1>지출결의서 작성중</h1>
			<p>작성중인 지출결의서 상신을 완료해주세요.</p>
			<img src="@/assets/images/draft-writing.png" />
			<button class="btn lg btn-primary-white" @click="hide">작성 취소</button>
		</div>
	</b-modal>
</template>
<script>
export default {
	props: {},
	methods: {
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
.msg-wrapper {
	padding: 60px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		font-size: 2rem;
		font-weight: 700;
		margin-bottom: 15px;
	}
	p {
		font-size: 16px;
		font-weight: 400;
	}
	.btn {
		width: 200px;
	}
}
</style>
