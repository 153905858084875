<template>
	<div class="EstimateDetail">
		<content-title :title="info.productName" :subtitle="`(${info.no})`" :datetime="infoDatetime" />
		<div class="content-wrapper">
			<div class="flex">
				<common-date-picker title="견적일" :value="info.estimateDatetime" content="견적유효기간: 견적일로부터 30일" @change="changeDate" />
				<div class="right-btn-group">
					<button class="btn btn-common" @click="deleteEstimate">삭제</button>
					<button class="btn btn-info" @click="exportPdf">PDF 출력</button>
					<button class="btn btn-complete" @click="onClickOrder" :disabled="isCompleted">
						{{ btnText }}
					</button>
				</div>
			</div>
			<simple-input-form title="수신자 정보" :list="recipientInfo" @save="changeRecipientInfo" />
			<simple-info-box title="공급자 정보" :list="sellerInfo" />
			<simple-info-box title="담당자 정보" :list="assigneeInfo" :checkItem="checkItem" @change="includeManager = $event" />

			<estimate-product-info-table :estimate="estimate" />

			<simple-info-box v-if="isProductRequest" title="상품요청 정보" :list="productRequestInfo" />
			<simple-textarea-form title="메모" content="*해당 메모는 견적서와 함께 출력되는 정보입니다" :value="info.memo" @save="onSaveMemo" />
			<div v-if="!isCompleted" class="btn-wrapper">
				<button class="btn btn-primary lg" @click="onClickOrder">주문하기</button>
			</div>
		</div>

		<modal-cancel title="견적서 삭제" content="삭제된 견적서는 다시 볼 수 없습니다.<br>견적서를 삭제 하시겠습니까?" @ok="onDelete" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import date from '@/mixins/date'
import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'
import { Estimate } from '@/utils/define/ItemCode'
import ContentTitle from '@/components/content/ContentTitle'
import SimpleInfoBox from '@/components/list/SimpleInfoBox'
import SimpleInputForm from '@/components/form-item/SimpleInputForm'
import SimpleTextareaForm from '@/components/form-item/SimpleTextareaForm'
import CommonDatePicker from '@/components/form-item/CommonDatePicker'
import EstimateProductInfoTable from '@/views/my-page/estimate/EstimateProductInfoTable'
import ModalCancel from '@/components/modal/ModalCancel'

export default {
	name: 'EstimateDetail',
	components: {
		ContentTitle,
		SimpleInfoBox,
		SimpleInputForm,
		SimpleTextareaForm,
		CommonDatePicker,
		EstimateProductInfoTable,
		ModalCancel,
	},
	mixins: [date],
	data() {
		return {
			info: {},
			includeManager: false,
		}
	},
	computed: {
		...mapGetters('user', ['instituteId']),
		...mapState('estimate', ['estimate']),
		infoDatetime() {
			return `견적생성일시: ${this.datetimeToString(this.info.createDatetime)}`
		},
		sellerInfo() {
			//공급자 정보
			let list = []
			list.push({ name: '사업자 등록번호', value: this.info?.distributor?.corpNo, all: true })
			list.push({ name: '소속', value: this.info?.distributor?.name })
			list.push({ name: '이름', value: this.info?.distributor?.ceoName })
			list.push({ name: '연락처', value: this.info?.distributor?.contactPhone, all: true })
			list.push({ name: '사업장 주소', value: this.info?.distributor?.address, all: true })

			return list
		},
		assigneeInfo() {
			//담당자 정보
			let list = []
			list.push({ name: '소속', value: this.info?.manager?.instituteName })
			list.push({ name: '이름', value: this.info?.manager?.name })
			list.push({ name: '연락처', value: this.info?.manager?.phone })
			list.push({ name: '이메일', value: this.info?.manager?.email })

			return list
		},
		productRequestInfo() {
			//상품요청 정보
			let list = []
			list.push({ name: '상품요청 번호', value: this.info?.request?.no })
			list.push({ name: '상품요청 일시', value: this.datetimeToString(this.info?.request?.createDatetime) })
			list.push({ name: '상품명', value: this.info?.request?.data.name, all: true })
			list.push({ name: '브랜드', value: this.info?.request?.data.brandName })
			list.push({ name: '제품번호', value: this.info?.request?.data.productCode })
			list.push({ name: '수량', value: this.info?.request?.data.quantity })
			list.push({ name: '추가요청사항', value: this.info?.request?.comment, all: true })

			return list
		},
		checkItem() {
			let obj = {}
			obj.text = '견적서에 포함하기'
			obj.value = this.includeManager
			return obj
		},
		recipientInfo() {
			//수신자 정보
			let list = []
			list.push({ name: '소속', value: this.info?.recipient?.instituteName, key: 'instituteName' })
			list.push({ name: '이름', value: this.info?.recipient?.name, key: 'name' })
			list.push({ name: '연락처', value: this.info?.recipient?.phone, key: 'phone' })
			list.push({ name: '이메일', value: this.info?.recipient?.email, key: 'email' })

			return list
		},
		btnText() {
			if (!this.info?.canOrder) return '주문불가'
			else if (this.info?.status?.code === Estimate.ORDERED) return '주문완료'
			else return '주문하기'
		},
		isCompleted() {
			return !this.info?.canOrder || this.info?.status?.code === Estimate.ORDERED
		},
		isProductRequest() {
			return !this.$_.isEmpty(this.info?.request)
		},
	},
	watch: {
		instituteId: {
			handler(newVal) {
				if (!newVal) return

				this.fetchEstimate()
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('estimate', ['lookupEstimate', 'updateEstimate', 'deleteEstimate', 'printEstimate']),
		...mapMutations('order', ['setEstimateId', 'setSelectedProductList']),
		async fetchEstimate() {
			await this.lookupEstimate({
				instituteId: this.instituteId,
				id: this.$route.params.id,
			})

			this.info = this.$_.cloneDeep(this.estimate)
		},
		async update(name) {
			try {
				await this.updateEstimate({
					instituteId: this.instituteId,
					body: {
						id: this.info.id,
						recipientName: this.info.recipient.name,
						recipientPhone: this.info.recipient.phone,
						recipientEmail: this.info.recipient.email,
						recipientInstituteName: this.info.recipient.instituteName,
						memo: this.info.memo,
						estimateDatetime: this.info.estimateDatetime,
					},
				})
				this.$root.toast('수정 완료', `${name}을(를) 수정 완료했습니다.`, ToastType.SUCCESS)
			} catch (e) {
				this.$root.toast('수정 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
		changeRecipientInfo(list) {
			list.forEach(item => {
				this.info.recipient[item.key] = item.value
			})
			this.update('수신자 정보')
		},
		changeDate(date) {
			this.info.estimateDatetime = date
			this.update('견적일')
		},
		onSaveMemo(text) {
			this.info.memo = text
			this.update('메모')
		},
		onClickOrder() {
			this.setEstimateId(this.estimate.id)
			this.setSelectedProductList(
				this.info.products.map(({ productId, quantity }) => {
					return { productId, quantity }
				})
			)

			this.$router.push({
				name: PageName.Payment,
			})
		},
		deleteEstimate() {
			this.$bvModal.show('ModalCancel')
		},
		async onDelete() {
			try {
				await this.deleteEstimate({
					instituteId: this.instituteId,
					id: this.info.id,
				})

				this.$root.toast('견적서 삭제 완료', '견적서가 삭제되었습니다', 'success')
				this.$router.go(-1)
			} catch (e) {
				this.$root.toast('삭제 실패', e.response.data.msg, 'error')
			}
		},
		async exportPdf() {
			const url = await this.printEstimate({
				instituteId: this.instituteId,
				body: {
					id: this.info.id,
					includeManager: this.includeManager,
				},
			})
			window.open(url, '_blank')
		},
	},
}
</script>

<style lang="scss" scoped>
.EstimateDetail {
	.right-btn-group {
		button {
			margin-left: 10px;
			&:last-child {
				margin-left: 32px;
				position: relative;
				&:before {
					width: 1px;
					height: 10px;
					content: '';
					position: absolute;
					top: 13px;
					left: -16px;
					background-color: $LINE_DIVIDER;
				}
			}
		}
	}
	.content-wrapper {
		> div {
			margin-bottom: 64px;
			&.flex {
				margin-bottom: 48px;
			}
		}
		.btn-wrapper {
			width: 100%;
			text-align: center;
			.btn {
				width: 400px;
				height: 48px;
				background: $PRIMARY_YELLOW;
				&:hover {
					background: #fec741 linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
					color: black;
				}
			}
		}
	}
}
</style>
