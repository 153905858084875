import axiosInstance from '@/services/axios'

const endPoint = {
	getNotificationList: () => `/notification/list`,
	notificationRead: () => `/notification/read`,
	notificationReadAll: () => `/notification/readall`,
}

const notificationAPI = {
	getNotificationList: params => axiosInstance.get(endPoint.getNotificationList(), { params }),
	notificationRead: body => axiosInstance.patch(endPoint.notificationRead(), body),
	notificationReadAll: () => axiosInstance.patch(endPoint.notificationReadAll()),
}

export default notificationAPI
